import Util from "../../../../Util";
import { ACTION_CREATE } from "../../../../global/const";
import { dateFormatCustom } from "../../../../utils/dateUtils";

export const findQualityControlCheck = (activity, qualityControlChecks) => {
  if (!activity) {
    return null;
  }

  const results = qualityControlChecks
    .map((qualityControlCheck) => {
      return qualityControlCheck[`${activity.type}-${activity.id}`] ?? null;
    })
    .filter((qualityControlCheck) => qualityControlCheck !== null);

  if (results.length > 0) {
    return results[0];
  }

  return null;
};

export const replaceQualityControlCheck = (
  dataQualityControlCheck,
  qualityControlChecks
) => {
  if (!dataQualityControlCheck) {
    return qualityControlChecks;
  }

  const { workflowActivityModel = null } = dataQualityControlCheck;

  if (!workflowActivityModel) {
    return qualityControlChecks;
  }

  const { id, type } = workflowActivityModel;

  return qualityControlChecks.map((qualityControlCheck) => {
    if (Object.keys(qualityControlCheck).includes(`${type}-${id}`)) {
      qualityControlCheck[`${type}-${id}`] = dataQualityControlCheck;

      return qualityControlCheck;
    }

    return qualityControlCheck;
  });
};

export const prepareDataToHistory = (qualityControlCheckLogs) => {
  if (!qualityControlCheckLogs) {
    return [];
  }

  let processCustomerLogs = [];

  qualityControlCheckLogs.forEach((qualityControlCheckLog) => {
    const { id, action } = qualityControlCheckLog;

    if (action === ACTION_CREATE) {
      processCustomerLogs.push(
        createExtructureDataToHistory(id, qualityControlCheckLog)
      );
    }

    if (action !== ACTION_CREATE) {
      const items = chageDataToHistory(qualityControlCheckLog, id);

      processCustomerLogs = processCustomerLogs.concat(items);
    }
  });

  return processCustomerLogs;
};

export const createExtructureDataToHistory = (
  index,
  qualityControlCheckLog,
  otherData = null
) => {
  const { action, created, em, qccm = null } = qualityControlCheckLog;

  let model = "";
  let activityId = "";

  if (qccm) {
    const { wam = null } = qccm;

    model = wam?.type ? wam?.type : "";
    activityId = wam?.id ? wam?.id : "";
  }

  let nameEmployee = "";
  let oldValue = otherData?.oldValue ?? "";
  let newValue = otherData?.newValue ?? "";

  if (otherData?.field === "completedById") {
    nameEmployee = em?.name ?? "";

    oldValue = oldValue !== "null" ? nameEmployee : '""';
    newValue = newValue !== "null" ? nameEmployee : '""';
  }

  if (otherData?.field === "completedOn") {
    oldValue =
      oldValue !== "null"
        ? dateFormatCustom(oldValue, "DD-MMM-YYYY hh:mm A")
        : '""';
    newValue =
      newValue !== "null"
        ? dateFormatCustom(newValue, "DD-MMM-YYYY hh:mm A")
        : '""';
  }

  const name = em?.name ?? "";

  return {
    name: name,
    model: model,
    activityId: activityId,
    id: `${index}`,
    action: action,
    created: created,
    field: otherData?.field ?? "",
    oldValue: oldValue,
    newValue: newValue,
  };
};

export const chageDataToHistory = (qualityControlCheckLog, index) => {
  let histories = [];

  const { oldInformation = {}, newInformation = {} } = qualityControlCheckLog;
  let oldInformationJson = Util.changeStringToArray(
    Util.changeArrayToString(oldInformation)
  );
  let newInformationJson = Util.changeStringToArray(
    Util.changeArrayToString(newInformation)
  );

  let oldQuestionsJson = Util.changeStringToArray(oldInformationJson.questions);
  let newQuestionsJson = Util.changeStringToArray(newInformationJson.questions);

  oldInformationJson = deleteFields(oldInformationJson, [
    "questions",
    "created",
  ]);
  newInformationJson = deleteFields(newInformationJson, [
    "questions",
    "created",
  ]);

  const OldInformationKeys = Object.keys(oldInformationJson);

  OldInformationKeys.forEach((key) => {
    if (oldInformationJson[key] !== newInformationJson[key]) {
      const otherData = {
        field: key,
        oldValue: Util.changeArrayToString(oldInformationJson[key]),
        newValue: Util.changeArrayToString(newInformationJson[key]),
      };

      histories.push(
        createExtructureDataToHistory(
          `${index}-${key}`,
          qualityControlCheckLog,
          otherData
        )
      );
    }
  });

  oldQuestionsJson.forEach((item, key) => {
    let oldQuestions = item.questions;
    let newQuestions = newQuestionsJson[key].questions;

    oldQuestions.forEach((question, key) => {
      let oldComments = question.comments;
      let newComments = newQuestions[key].comments;

      if (question?.value !== newQuestions[key]?.value) {
        const otherData = {
          field: question?.label ?? "",
          oldValue: Util.changeArrayToString(question?.value) ?? "",
          newValue: Util.changeArrayToString(newQuestions[key]?.value) ?? "",
        };

        histories.push(
          createExtructureDataToHistory(
            `${index}-${otherData.field}`,
            qualityControlCheckLog,
            otherData
          )
        );
      }

      oldComments.forEach((comment, key) => {
        if (comment?.message !== newComments[key]?.message) {
          const field = `comment ${newComments[key]?.fieldName}`;
          const otherData = {
            field: field ?? "",
            oldValue: Util.changeArrayToString(comment?.message) ?? "",
            newValue: Util.changeArrayToString(newComments[key]?.message) ?? "",
          };

          histories.push(
            createExtructureDataToHistory(
              `${index}-${otherData.field}`,
              qualityControlCheckLog,
              otherData
            )
          );
        }
      });
    });
  });

  return histories;
};

export const deleteFields = (data, items) => {
  items.forEach((item) => {
    delete data[item];
  });

  return data;
};
