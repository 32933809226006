import React, { Component } from "react";
import axios from "axios";
import Auth from "../../Auth";
import LoadingLayer from "../LoadingLayer";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as globalConst from "../../global/const";
import BackIcon from "../../image/backArrow.png";
import Util from "../../Util";
import { sprintf } from "sprintf-js";

// SCSS
import "../../css/sales/sales.scss";
import withNavigation from "../../Router/WithNavigation";

class LoadInvoices extends Component {
  constructor(props) {
    super(props);
    const isAuth = Auth.getIsAuth();
    if (isAuth === false) {
      const { navigate } = this.props;

      navigate("/");
    }
    this.state = {
      excelFile: null,
      results: [],
      loading_excel: false,
    };

    this.myInputFileRef = React.createRef();
  }

  cleanInputFileValue = () => {
    this.myInputFileRef.current.value = null;
  };

  async chooseFile(e) {
    try {
      e.preventDefault();

      this.setState({
        loading_excel: true,
        results: [],
      });

      let promise = {};

      if (e.target.files) {
        const reader = new FileReader();

        promise = new Promise((resolve, reject) => {
          let fileJson = [];

          reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            fileJson = XLSX.utils.sheet_to_json(worksheet);

            resolve(fileJson);
          };

          reader.readAsArrayBuffer(e.target.files[0]);

          reader.onerror = (error) => {
            reject(error);
          };
        });
      }

      let response = await promise.then();

      let chunks = Util.chunk(response, 100);

      await Promise.all(
        chunks.map(async (items) => {
          return this.getAndUpdateRows(items);
        })
      );

      this.setState({
        loading_excel: false,
        excelFile: null,
      });

      this.cleanInputFileValue();
    } catch (e) {
      console.error(e);

      this.setState({
        loading_excel: false,
      });

      alert("Select correct Excel file");
    }
  }

  getAndUpdateRows = async (items) => {
    const url = sprintf(
      "%s%s",
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN"),
      globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_LOAD_INVOICE
    );

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    };

    await axios
      .post(url, items, config)
      .then((response) => {
        let data = response.data.value;
        var results = this.state.results;

        results = results.concat(data);

        this.setState({
          results: results,
        });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }
      });
  };

  render() {
    return (
      <div id="LoadInvoices_component">
        {this.state.loading_excel ? <LoadingLayer /> : null}

        <div className="container_sales">
          <div className="list_and_information">
            <div className="row">
              <div className="sales_list load_invoices_list">
                <div className="header">
                  <div className="row header_tools">
                    <div className="">
                      <div className="col-12">
                        <div className="link_to_dashboard">
                          <Link to="/dashboard">
                            <img src={BackIcon} alt="" />
                          </Link>
                        </div>
                        <h2 className="db-top-heading">LOAD INVOICES</h2>
                      </div>
                      <div className="col-12">
                        <div className="row">
                          <div>
                            This section allows the update of the fields "Xero
                            Code MMDIA", "Xero Code NOVA" and "Accounting
                            Status". Please attach the excel file with the
                            information required and click "Update"
                          </div>
                          <div className="col-12 col-left">
                            <div className="sheetType_selection">
                              <div className="input-group mt-2">
                                <input
                                  ref={this.myInputFileRef}
                                  accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  type="file"
                                  className="form-control upload-file"
                                  aria-label="Upload"
                                  onClick={(e) => {
                                    e.target.value = null;
                                  }}
                                  onChange={(e) => {
                                    const file = e;
                                    this.setState({
                                      excelFile: file,
                                    });
                                  }}
                                />
                                <button
                                  className="btn btn-default update-btn"
                                  type="button"
                                  onClick={() => {
                                    this.chooseFile(this.state.excelFile);
                                  }}
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-left">
                            <a
                              className="formatLink"
                              href={this.getUrlTemplateDownload()}
                            >
                              <FontAwesomeIcon icon={faFileExcel} /> Format
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-container">
                  {this.state.results.length === 0 ? null : (
                    <table className="table table-striped table-sm table-hover">
                      <thead className="thead-dark">
                        <tr>
                          <th>Online order number</th>
                          <th>Name</th>
                          <th>Diamond Certificate</th>
                          <th>Xero Code MMDIA</th>
                          <th>Accounting Status</th>
                          <th>Activity</th>
                          <th>Unique Id</th>
                          <th>Stock Number</th>
                          <th>MMDIA Invoice</th>
                          <th>Cost</th>
                          <th>Invoice status</th>
                          <th>Melees MMDIA Invoice</th>
                          <th>Melees Invoice status</th>
                          <th>Result</th>
                        </tr>
                      </thead>

                      {this.renderRows()}
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getUrlTemplateDownload = () => {
    return sprintf(
      "%s%s%s",
      Util.getStorageParameter("REACT_APP_NOVITA_DOCS_DOMAIN"),
      "/uploads/",
      "sales_invoices_template_v5.xlsx"
    );
  };

  renderRows = () => {
    return (
      <tbody>
        {this.state.results.map((row, index) => (
          <tr key={index}>
            <td>{row.onlineOrderNumber}</td>
            <td>{row.name}</td>
            <td>{row.diamondCertificate}</td>
            <td>{row.xeroCodeMmdia}</td>
            <td>{row.accountingStatus}</td>
            <td>{row.type}</td>
            <td>{row.activityId}</td>
            <td>{row.stockNumber}</td>
            <td>{row.mmdiaInvoice}</td>
            <td>{row.cost}</td>
            <td>{row.invoiceStatus}</td>
            <td>{row.meleesMmdiaInvoice}</td>
            <td>{row.meleesInvoiceStatus}</td>
            <td className={row.result}>{row.resultText}</td>
          </tr>
        ))}
      </tbody>
    );
  };
}

export default withNavigation(LoadInvoices);
