import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { dateFormatCustom } from "../../../utils/dateUtils";
import FormDatePicker from "../../Form/FormDatePicker";

const ResultAnalysisDatePaidFilter = ({ onResponse }) => {
  const [sendData, setSendData] = useState(false);
  const [selectedMinDate, setSelectedMinDate] = useState();
  const [selectedMaxDate, setSelectedMaxDate] = useState();
  const [requiredInputs, setRequiredInputs] = useState(false);

  const handleMinDateChange = async (date) => {
    setSelectedMinDate(null);

    if (date) {
      date.setHours(0, 0, 0, 0);
      setSelectedMinDate(date);
      setRequiredInputs(true);
    }

    setSendData(true);
  };

  const handleMaxDateChange = async (date) => {
    setSelectedMaxDate(null);

    if (date) {
      date.setHours(23, 59, 59, 999);
      setSelectedMaxDate(date);
      setRequiredInputs(true);
    }

    setSendData(true);
  };

  const responseDate = useCallback(() => {
    onResponse({
      startDatePaid: dateFormatCustom(selectedMinDate),
      endDatePaid: dateFormatCustom(selectedMaxDate),
    });
  }, [onResponse, selectedMinDate, selectedMaxDate]);

  useEffect(() => {
    if (!selectedMinDate && !selectedMaxDate) {
      setRequiredInputs(false);
    }

    if (sendData) {
      responseDate();
      setSendData(false);
    }
  }, [selectedMinDate, selectedMaxDate, sendData, responseDate]);

  return (
    <div>
      <Row className="align-items-end">
        <Col md={6}>
          <FormDatePicker
            label="Paid date form"
            nameInput="startDate"
            value={selectedMinDate ?? ""}
            onResponse={handleMinDateChange}
            dateFormat="MM/dd/yyyy"
            columnsSize={"12"}
            maxDate={selectedMaxDate}
            required={requiredInputs}
          />
        </Col>
        <Col md={6}>
          <FormDatePicker
            label="Paid date To"
            nameInput="endDate"
            value={selectedMaxDate ?? ""}
            onResponse={handleMaxDateChange}
            columnsSize={"12"}
            dateFormat="MM/dd/yyyy"
            required={requiredInputs}
          />
        </Col>
      </Row>
    </div>
  );
};

ResultAnalysisDatePaidFilter.propTypes = {
  onResponse: PropTypes.func.isRequired,
};

export default ResultAnalysisDatePaidFilter;
