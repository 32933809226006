import moment from "moment";
import {
  faTrash,
  faInfoCircle,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSaleCustomerRelation,
  updateCustomerSalesRelation,
} from "../../../../store/slices/customerSlice";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import SaleSearch from "../../../dashboard/SaleSearch";
import Auth from "../../../../Auth";
import { dateFormatCustom } from "../../../../utils/dateUtils";
import ShowSaleConsecutive from "../../../sales/Acctions/ShowSaleConsecutive";

export default function CustomerSales({ customer }) {
  const accessInfo = Auth.canAccessRolesAndLeader("rolesAccessCRMSale");

  const { status } = useSelector((state) => state.customers);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [saleToDelete, setSaleToDelete] = useState(null);
  const [showAddSales, setShowAddSales] = useState(false);
  const [showAddSalesConfimation, setshowAddSalesConfimation] = useState(false);
  const [selectedSale, setselectedSale] = useState(null);

  const dispatch = useDispatch();

  const onRemove = () => {
    dispatch(
      deleteSaleCustomerRelation({
        customerId: customer.id,
        saleId: saleToDelete.saleId,
      })
    );
    setShowConfirmation(false);
  };

  const handleSelectSale = (sale) => {
    setshowAddSalesConfimation(true);
    setselectedSale(sale);
  };

  const onLinkSales = () => {
    setshowAddSalesConfimation(false);
    dispatch(
      updateCustomerSalesRelation({
        customerId: customer.id,
        saleId: selectedSale.id,
      })
    );
  };

  const renderBTNRemove = (sale) => {
    if (!accessInfo) {
      return "";
    }

    return (
      <Button
        variant=""
        onClick={(e) => {
          e.stopPropagation();
          setSaleToDelete(sale);
          setShowConfirmation(true);
        }}
      >
        <FontAwesomeIcon icon={faTrash} title="Remove Sale" />
      </Button>
    );
  };

  const openSales = (sale) => {
    if (accessInfo) {
      window.open(`/sales?order-ref=${sale.sale.onlineOrderNumber}`);
    }
  };

  const buildSales = () => {
    return customer.customerSales.map((sale) => {
      return (
        <tr key={sale.id} onClick={() => openSales(sale)}>
          <td>{dateFormatCustom(sale.sale.saleDate, "DD MMM, YYYY")}</td>
          <td>
            {sale.sale.onlineOrderNumber}{" "}
            <ShowSaleConsecutive sale={sale.sale} />
          </td>
          <td>{sale.sale.saleType}</td>
          <td>{sale.sale.status}</td>
          <td>{sale.sale.totalIncGst}</td>
          <td>
            {status === "API_STATUS_PENDING" ? (
              <div className="spinner-border"></div>
            ) : (
              renderBTNRemove(sale)
            )}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="general_information customer-details-card">
      <h2
        style={{ height: "42px" }}
        className="d-flex flex-row justify-content-between align-items-center"
      >
        <p className="align-self-center mt-3">Sales</p>
        {accessInfo && (
          <button
            onClick={() => {
              setShowAddSales(true);
            }}
            className="btn btn-default align-self-center"
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        )}
      </h2>
      <div className="customer-details-container p-2">
        <table className="table table-striped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Date of Sale</th>
              <th scope="col">Order No.</th>
              <th scope="col">Type</th>
              <th scope="col">Status</th>
              <th scope="col">Total (Inc. GST)</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody id="sales_list_information">{buildSales()}</tbody>
        </table>
      </div>
      <Modal
        show={showConfirmation}
        onHide={() => {
          setSaleToDelete(null);
          setShowConfirmation(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Sale Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this sale link?</p>
          <div className="flex-row">
            <Button
              className="mt-2 float-right"
              variant="secondary"
              onClick={() => {
                setSaleToDelete(null);
                setShowConfirmation(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={onRemove}
              className="ms-2 mt-2 float-right"
              variant="primary"
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showAddSalesConfimation}
        onHide={() => {
          setSaleToDelete(null);
          setshowAddSalesConfimation(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Link Sale</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to link this sale?</p>
          <div className="flex-row">
            <Button
              className="mt-2 float-right"
              variant="secondary"
              onClick={() => {
                setshowAddSalesConfimation(false);
                setselectedSale(null);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={onLinkSales}
              className="ms-2 mt-2 float-right"
              variant="primary"
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {showAddSales && (
        <SaleSearch
          closeComponent={() => {
            setShowAddSales(false);
          }}
          setSelectedSale={handleSelectSale}
        />
      )}
    </div>
  );
}
