import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { convertUtcToTimezone } from "../../../utils/dateUtils";
import { useDispatch, useSelector } from "react-redux";
import { fetchQualityControlLogs } from "../../../store/slices";
import { prepareDataToHistory } from "../saleWorkflow/QualityControl/Actions";
import { ACTION_CREATE } from "../../../global/const";
import Util from "../../../Util";

const QualityControlCheckLogHistory = ({ sale }) => {
  const dispatch = useDispatch();
  const { id } = sale;
  const { dataQualityControlCheckLogs } = useSelector(
    (state) => state.qualityControlCheck
  );

  const [qualityControlCheckLogs, setQualityControlCheckLogs] = useState(
    dataQualityControlCheckLogs ?? []
  );

  const qualityControlChecks = prepareDataToHistory(
    dataQualityControlCheckLogs
  );

  useEffect(() => {
    dispatch(
      fetchQualityControlLogs({
        id: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    if (
      dataQualityControlCheckLogs &&
      JSON.stringify(qualityControlChecks) !==
        JSON.stringify(qualityControlCheckLogs)
    ) {
      setQualityControlCheckLogs(qualityControlChecks);
    }
  }, [
    dataQualityControlCheckLogs,
    qualityControlCheckLogs,
    qualityControlChecks,
  ]);

  const renderCreate = (qualityControlCheckLog) => {
    const { created, name } = qualityControlCheckLog;

    return (
      <>
        <Col xs={6} lg={6}>
          <span>
            {convertUtcToTimezone(
              created,
              "DD MMM, YYYY hh:mm:ss A",
              Util.getStorageParameter("REACT_APP_TIMEZONE")
            )}
          </span>
          <br />
          <b>Created by: </b>
          <br />
          {name ?? ""}
        </Col>
        <Col xs={6} lg={6}>
          <span>Quality control check created.</span>
        </Col>
      </>
    );
  };

  const renderChanged = (qualityControlCheckLog) => {
    const { created, name, field, newValue, oldValue, model, activityId } =
      qualityControlCheckLog;

    return (
      <>
        <Col xs={6} lg={6}>
          <span>{model && `${model} ID: ${activityId}`}</span>
          <br />
          <span>
            {convertUtcToTimezone(
              created,
              "DD MMM, YYYY hh:mm:ss A",
              Util.getStorageParameter("REACT_APP_TIMEZONE")
            )}
          </span>
          <br />
          <b>Changed by: </b>
          <br />
          {name ?? ""}
        </Col>
        <Col xs={6} lg={6} className="hidden-elements">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={"tooltip-top"}>{field}</Tooltip>}
          >
            <span className="text-secondary" style={{ cursor: "pointer" }}>
              {field}
            </span>
          </OverlayTrigger>

          {" changed from "}
          <span className="text-primary">{oldValue ?? '""'}</span>
          {" to "}
          <span className="text-success">{newValue ?? '""'}</span>
        </Col>
      </>
    );
  };

  const renderRow = (qualityControlCheck) => {
    const { id, action } = qualityControlCheck;
    return (
      <Row key={id}>
        {action === ACTION_CREATE && renderCreate(qualityControlCheck)}
        {action !== ACTION_CREATE && renderChanged(qualityControlCheck)}
      </Row>
    );
  };

  return (
    <div id="SaleDetailSalesLog_component">
      <Col className="sale_data">
        <h2 className="heading">HISTORY QCC</h2>
        <div className="container mb-4">
          {qualityControlCheckLogs.map((qualityControlCheck) =>
            renderRow(qualityControlCheck)
          )}
        </div>
      </Col>
    </div>
  );
};

QualityControlCheckLogHistory.propTypes = {
  sale: PropTypes.any.isRequired,
};

export default QualityControlCheckLogHistory;
