import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { BTN_QUALITY_CONTROL_CHECK_PENDING } from "../../../../global/const";

const QualityControlPending = ({
  qualityControlCheck,
  btnsDisable,
  openModalEdit,
}) => {
  if (!qualityControlCheck) {
    return "";
  }

  const disabledBTNPending = btnsDisable.includes(
    BTN_QUALITY_CONTROL_CHECK_PENDING
  );

  const { approved = null } = qualityControlCheck;

  if (approved !== null) {
    return "";
  }

  const openEdit = (event) => {
    event.preventDefault();

    openModalEdit(true, true);
  };

  return (
    <>
      <span>Quality Control Check: </span>
      <Link
        to=""
        className={
          disabledBTNPending
            ? "disabled-btn bg-warning text-dark qcc-title-inprocess"
            : "bg-warning text-dark qcc-title-inprocess"
        }
        onClick={(event) => openEdit(event)}
      >
        In Process
      </Link>
    </>
  );
};

QualityControlPending.propTypes = {
  qualityControlCheck: PropTypes.object,
  btnsDisable: PropTypes.array,
  activity: PropTypes.object,
};

QualityControlPending.defaultProps = {
  qualityControlCheck: null,
  btnsDisable: [],
};

export default QualityControlPending;
