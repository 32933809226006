import React from "react";
import PropTypes from "prop-types";
import { dateFormatCustom } from "../../../utils/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  STATUS_REPORT_COMPLETED,
  STATUS_REPORT_FAILED,
  STATUS_REPORT_IN_PROCES,
} from "../../../global/const";

const ReportRow = ({ report }) => {
  const {
    id,
    reference,
    status,
    url,
    created,
    parameters,
    employeeModel = null,
  } = report;

  const { startDate, endDate } = parameters;

  const mapColorStatus = () => {
    let classColor = "";

    switch (status.toUpperCase()) {
      case STATUS_REPORT_IN_PROCES.toUpperCase():
        classColor = "text-primary";
        break;
      case STATUS_REPORT_COMPLETED.toUpperCase():
        classColor = "text-success";
        break;
      case STATUS_REPORT_FAILED.toUpperCase():
        classColor = "text-danger";
        break;

      default:
        break;
    }

    return classColor;
  };

  const getByEmployee = () => {
    if (!employeeModel) {
      return "";
    }

    const { name } = employeeModel;

    if (name) {
      return ` by ${name}`;
    }

    return "";
  };

  return (
    <tr>
      <td>{id}</td>
      <td>
        {dateFormatCustom(created, "DD MMM, YYYY HH:mm")}{" "}
        {employeeModel && getByEmployee()}
      </td>
      <td>
        {reference} {startDate && dateFormatCustom(startDate, "DD MMM, YYYY")}{" "}
        {endDate && " to "}
        {endDate && dateFormatCustom(endDate, "DD MMM, YYYY")}
      </td>
      <td>
        <span className={mapColorStatus()}>{status}</span>
      </td>
      <td className="center-element">
        {url && (
          <a href={url} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faDownload} />
          </a>
        )}

        {!url && "-"}
      </td>
    </tr>
  );
};

ReportRow.propTypes = {
  report: PropTypes.object.isRequired,
};

export default ReportRow;
