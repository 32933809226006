import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAppointmentsFromString,
  removeAppointmentCustomizedLists,
} from "../../../store/slices";
import AppointmentsListHeader from "./header";
import axiosServices from "../../../axios";
import { formatAppointmentCreationDate } from "../../../utils/dateUtils";

export const fetchSheet = async (id) => {
  const response = await axiosServices.get(
    `/api/v1/customers/customized-lists/export-list/${id}`,
    {
      responseType: "arraybuffer", // Tell axios to expect a binary file
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
    }
  );
  return response.data;
};

export default function CustomizedList({
  list,
  filterChanged,
  selectedAppointment,
  onSelected,
  extractEmployeeNames,
}) {
  const dispatch = useDispatch();
  const { listAppointments } = useSelector(
    (state) => state.appointmentsCustomizedLists
  );

  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    dispatch(fetchAppointmentsFromString({ id: list.id }));
  }, [dispatch, list]);

  useEffect(() => {
    if (listAppointments[list.id]) {
      setAppointments(
        listAppointments[list.id].map((c) => {
          return { ...c, selected: true };
        })
      );
    }
  }, [list.id, listAppointments]);

  const onResponse = (listId, order) => {
    if (listAppointments[listId]) {
      let list = listAppointments[listId];

      const sortArray = (key, order) => {
        const compareFunction = (a, b) => {
          if (order === "asc") {
            if (a[key] < b[key]) return -1;
            if (a[key] > b[key]) return 1;
          } else if (order === "desc") {
            if (a[key] > b[key]) return -1;
            if (a[key] < b[key]) return 1;
          }
          return 0;
        };

        const sortedArray = [...list].sort(compareFunction);

        setAppointments(sortedArray);
      };

      sortArray("created", order);
    }
  };

  const statusAsSlug = (status) => {
    let status_as_slug = "";
    if (typeof status === "string" || status instanceof String) {
      status = status.toLowerCase();
      if (status === "cancelled") {
        status_as_slug = "cancelled";
      } else if (status === "customer decided to buy") {
        status_as_slug = "decided_to_buy";
      } else if (status === "customer decided not to buy") {
        status_as_slug = "decided_not_to_buy";
      } else if (status === "following up") {
        status_as_slug = "following_up";
      }

      status_as_slug = "status_coloured_box " + status_as_slug;
    }
    return status_as_slug;
  };

  return (
    <InfiniteScroll
      dataLength={list.customerAppointmentIds.length}
      next={() => {}}
      hasMore={false}
      height={"calc(100vh - 100px)"}
    >
      <table
        className="table table-striped table-sm table-hover"
        id="table_sales"
      >
        <AppointmentsListHeader
          onFilterChange={filterChanged}
          isPersonalTab={true}
          listId={list.id}
          onResponseList={onResponse}
        />
        <tbody>
          {appointments &&
            appointments.map((appointment) => {
              let customer = appointment.customer;
              return (
                <tr
                  className={`customer_row ${
                    selectedAppointment?.id === appointment.id
                      ? "highlighted_row"
                      : ""
                  }`}
                  key={appointment.id}
                  onClick={(e) => {
                    onSelected(appointment);
                  }}
                >
                  <td>
                    <div className="form-check custom-from-check">
                      <Form.Check
                        type="checkbox"
                        name="filterEmptyName"
                        id={"filterEmptyName"}
                        className="advanced_filter_empty_customer_name"
                        defaultChecked={appointment.selected}
                        onChange={(e) => {
                          e.stopPropagation();
                          // find appointment in appointments and update selection status
                          setAppointments(
                            appointments.map((c) => {
                              if (c.id === appointment.id) {
                                return { ...c, selected: e.target.checked };
                              }
                              return c;
                            })
                          );
                          dispatch(
                            removeAppointmentCustomizedLists({
                              listId: list.id,
                              customerAppointmentId: appointment.id,
                            })
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td>{formatAppointmentCreationDate(appointment.appointmentDate)}</td>
                  <td>{appointment.type}</td>              
                  <td>{customer.name}</td>
                  <td>{customer.email}</td>
                  <td>{customer.phone}</td>
                  <td>
                    {
                      appointment.status ?
                      (
                        <>
                          <div className={statusAsSlug(appointment.status)}/>
                          {appointment.status}
                        </>
                      ) : ''
                    }
                  </td>
                  <td>{extractEmployeeNames(appointment)}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </InfiniteScroll>
  );
}
