import React, { Component } from "react";
import { Row, Col, Table, Alert } from "react-bootstrap";
import axios from "axios";
import * as globalConst from "../../../global/const";
import Auth from "../../../Auth";
import { sprintf } from "sprintf-js";
import moment from "moment";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmRemoveFavoriteFilters from "./ConfirmRemoveFavoriteFilters";
import Util from "../../../Util";

const CancelToken = axios.CancelToken;
let cancel;

class ListFavouriteFilter extends Component {
  constructor(props) {
    super();

    this.state = {
      loadingFilters: true,
      favouriteFilters: [],
      errorMessage: "",
      showConfirmRemove: false,
    };

    this.filter_params = {
      page: 1,
      max_results: 10,
      order_by: "id",
      order_type: "DESC",
    };

    // eslint-disable-next-line no-new-object
    this.favouriteFilters = new Object();

    this.removeItem = null;
    this.getFavouriteFilter();
  }

  getFavouriteFilter = async () => {
    if (cancel !== undefined) {
      cancel();
    }

    const url = sprintf(
      "%s%s",
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN"),
      globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_FAVOURITE_FILTER_LIST
    );

    let user = Auth.getAuthenticatedUser();

    this.filter_params.employeeId = user.employeeModel.id;

    return axios
      .get(url, {
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
        params: this.filter_params, //this params are sent as query. In post requests they should go as body params in the following block
        headers: { Authorization: `Bearer ${Auth.getToken()}` },
      })
      .then((response) => {
        this.favouriteFilters = Object.assign([], response.data.value ?? []);

        this.setState({
          loadingFilters: false,
        });
      })
      .catch((error) => {
        this.favouriteFilters = [];

        if (axios.isCancel(error)) {
          return;
        }

        this.setState({
          loadingFilters: false,
        });
      });
  };

  render() {
    return (
      <Row lg="12" className="overflow-hidden">
        {this.state.errorMessage.length > 0 ? (
          <Col lg="12">
            <Alert
              variant="danger"
              onClose={(e) => this.hiddenErrorMessageAlert()}
              dismissible
            >
              <p>{this.state.errorMessage}</p>
            </Alert>
          </Col>
        ) : null}

        <Col lg="12">
          {!this.state.loadingFilters ? (
            this.rendeTable()
          ) : (
            <div className="center-element mt-2">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
        </Col>
      </Row>
    );
  }

  rendeTable = () => {
    return (
      <div className="mt-2">
        <Table striped bordered hover className="overflow-hidden">
          <thead>
            <tr>
              <th>Name</th>
              <th>Creation date</th>
              <th>Apply</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>{this.renderRow()}</tbody>
        </Table>
        {this.state.showConfirmRemove ? (
          <ConfirmRemoveFavoriteFilters
            showModal={this.state.showConfirmRemove}
            item={this.removeItem}
            parentConfirmRemoveItem={this.confirmRemoveItem}
          />
        ) : null}
      </div>
    );
  };

  renderRow = () => {
    return this.favouriteFilters.map((item) => {
      return (
        <tr key={item.id}>
          <td>{item.name}</td>
          <td>{<span>{this.showDate(item)}</span>}</td>
          <td>
            <button
              style={{ zIndex: "9999" }}
              onClick={(e) => {
                this.selectFavouriteFilter(item);
              }}
              className="btn btn-primary btn-novita-square-pink"
            >
              <FontAwesomeIcon icon={faCheck} />
            </button>
          </td>
          <td>
            <a
              onClick={(e) => {
                this.showConfirmRemoveItem(item);
              }}
              className="btn btn-outline-danger"
            >
              X
            </a>
          </td>
        </tr>
      );
    });
  };

  showDate = (item) => {
    return moment(item.created, "YYYY/MM/DD").format("DD/MM/YYYY");
  };

  selectFavouriteFilter = (item) => {
    let favouriteFilter = Object.assign([], item.favouriteFilter);

    this.props.parentApplyFavouriteFilter(favouriteFilter);
  };

  showConfirmRemoveItem = (item) => {
    this.removeItem = item;

    this.setState({
      showConfirmRemove: true,
    });
  };

  confirmRemoveItem = (value) => {
    this.setState({
      showConfirmRemove: false,
    });

    if (value && this.removeItem) {
      this.deleteItem(this.removeItem);
    }
  };

  deleteItem = (item) => {
    if (cancel !== undefined) {
      cancel();
    }

    const url = sprintf(
      "%s%s/%s",
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN"),
      globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_FAVOURITE_FILTER,
      item.id
    );

    this.setState({
      loadingFilters: true,
    });

    let user = Auth.getAuthenticatedUser();

    this.filter_params.employeeId = user.employeeModel.id;

    axios
      .delete(url, {
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
        headers: { Authorization: `Bearer ${Auth.getToken()}` },
      })
      .then((response) => {
        this.getFavouriteFilter();
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }

        if (error.response.status === 404) {
          this.setState({
            errorMessage: sprintf("%s", "Record not found"),
          });
        }

        this.setState({
          loadingFilters: false,
        });
      });
  };

  hiddenErrorMessageAlert = () => {
    this.setState({
      errorMessage: "",
    });
  };
}

export default ListFavouriteFilter;
