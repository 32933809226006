import React from "react";
import PropTypes from "prop-types";
import Util from "../../../Util";

const BAND_WIDTH = "Band width:";

const ShowElementTag = ({ tags }) => {
  const tagsArray = Util.changeStringToArray(tags);

  if (!tags) {
    return "";
  }

  const valueIsOnlyNumber = (value) => {
    const cleanedValue = value.replace(new RegExp(BAND_WIDTH, "g"), "").trim();

    return /^-?\d+(\.\d+)?$/.test(cleanedValue)
      ? `${BAND_WIDTH} ${cleanedValue} mm`
      : value;
  };

  return (
    <ul>
      {tagsArray.map((tag, key) => {
        if (tag.value.includes(BAND_WIDTH)) {
          tag.value = valueIsOnlyNumber(tag.value);
        }

        return (
          <li
            key={key}
            dangerouslySetInnerHTML={{
              __html: Util.textIsUrl(tag.value),
            }}
          ></li>
        );
      })}
    </ul>
  );
};

ShowElementTag.propTypes = {
  tags: PropTypes.string.isRequired,
};

export default ShowElementTag;
