import { Form, FormLabel, Button, Row, Col, Card } from "react-bootstrap";
import React, { Component } from "react";
import LoadingLayer from "../../LoadingLayer";
import FlashMessages from "../../FlashMessages";
import Auth from "../../../Auth";
import axios from "axios";
import { Link } from "react-router-dom";
import Util from "../../../Util";
import * as globalConst from "../../../global/const";
import SaleEditDiamondSearch from "./SaleEditDiamondSearch";
import SaleEditProductReferenceSearch from "./SaleEditProductReferenceSearch";

// SCSS
import "../../../css/sales/sale_edit.scss";
import { findTypeOfClawStandard } from "./SaleFindTypeClaw";
import LoadingSpinnerLayer from "../../LoadingSpinnerLayer";
import { getEngagementRingPictureChangeClawMetal } from "../Information/EngagementRingPictureChangeClawMetal";
import {
  getDiamondCertificate,
  getDiamondCertificateMerge,
  getDiamondGdOwnKey,
  getDiamondLocationCityName,
} from "./Data/FindDataDiamondCertificate";
import MergeSearchSaleDiamond from "./MergeSaleDiamond/MergeSearchSaleDiamond";
import { SearchValuesSales } from "./MergeSaleDiamond/Acctions/SearchSales";
import { saleIsReturn } from "./Acctions/SaleRetutn";
import FormSelectCustomer from "../../Form/FormSelectCustomer";
import AddElementTag from "../CustomInputs/AddElementTag";
import FormInputCheckbox from "../../Form/FormInputCheckbox";

class SaleProductEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sale: props.sale,
      workflowSales: props.workflowSales ?? [],
      diamondDetails: props.sale.diamondDetails,
      gdOwnKey: props.sale.gdOwnKey,
      diamondLocation: props.sale.diamondLocation,
      diamondDetailsDisabled: true,
      ringPreference: props.sale.ringPreference,
      clawType: props.sale.clawType,
      engraving: props.sale.engraving,
      metal: props.sale.metal,
      clawMetal: props.sale.clawMetal,
      ringSize: props.sale.ringSize,
      jewelleryType: props.sale.jewelleryType,
      orderType: props.sale.orderType,
      saleType: props.sale.saleType,
      ownReferenceModified: props.sale.ownReferenceModified,
      descriptionModified: props.sale.descriptionModified ?? "",
      productDetails: props.sale.productDetails,
      manufacturingSalesRepValidated: props.sale.manufacturingSalesRepValidated,
      notes: props.sale.notes,
      error_messages: [],
      warning_messages: [],
      success_messages: [],
      updating_sale: false,
      loading_ring_reference: false,
      fields_no_updated: true,
      show_diamond_search_modal: false,
      show_product_reference_search_modal: false,
      typeAndClaw: {
        metalClaw: "",
        metalClawStandard: "",
        typeOfClaw: "",
      },
      loadDataStandard: true,
      disabledFieldInForm: false,
      showMergeSaleDiamond: false,
      saleMerge: {
        id: null,
        diamondDetails: null,
        gdOwnKey: null,
      },
      inTransitTo: props.sale.inTransitTo,
      requestedToOverseasProvider: props.sale.requestedToOverseasProvider,
      confirmedFromOverseasProvider: props.sale.confirmedFromOverseasProvider,
      engravingFontName: props.sale.engravingFontName ?? "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.editSale = this.editSale.bind(this);
    this.fetchNewJewelleryReference =
      this.fetchNewJewelleryReference.bind(this);
    this.resetDiamondDetails = this.resetDiamondDetails.bind(this);
    this.resetRingPreference = this.resetRingPreference.bind(this);

    this.loadListInformation();
  }

  async componentDidMount() {
    this.setDataStandard();

    this.getWorkflowData();
  }

  getWorkflowData = async () => {
    const { workflowSales = [] } = this.state;

    let status = null;

    workflowSales.map((workflowSale) => {
      workflowSale.workflowActivityModels.map((workflowActivity) => {
        if (
          workflowActivity.type === "Manufacturing" &&
          workflowSale.status &&
          workflowSale.status !==
            globalConst.REACT_APP_WORKFLOW_SALE_STATUS_CANCELLED
        ) {
          status = workflowSale.status;
        }

        return null;
      });

      return null;
    });

    if (status) {
      this.getDisabledFieldInForm();
    }
  };

  getDisabledFieldInForm = () => {
    const disabledFieldInForm = !Auth.canEditField("rolesEditFormSales");

    this.setState({
      disabledFieldInForm: disabledFieldInForm,
    });
  };

  async componentDidUpdate(props, state) {
    if (state.ringPreference !== this.state.ringPreference) {
      await this.setDataStandard();

      this.changeMetalClaw(this.state.typeAndClaw.metalClaw);
    }
  }

  setDataStandard = async () => {
    this.setState({
      loadDataStandard: true,
    });

    const { saleType } = this.state.sale;
    const { ringPreference } = this.state;

    const response = await findTypeOfClawStandard(ringPreference, saleType);

    this.setState({
      typeAndClaw: {
        metalClawStandard: response.metalClawStandard,
        metalClaw: response.metalClaw,
        typeOfClaw: response.typeOfClaw,
      },
      loadDataStandard: response.loadDataStandard,
    });
  };

  changeMetalClaw = (metalClaw) => {
    this.setState({
      clawMetal: metalClaw,
    });
  };

  loadListInformation = () => {
    this.listProductType = Util.getStorageParameter(
      "REACT_APP_PRODUCT_TYPE"
    ).split(",");
    this.listProductTypeOrder = Util.getStorageParameter(
      "REACT_APP_PRODUCT_TYPE_ORDER"
    ).split(",");
    this.listProductTypeClaw = Util.getStorageParameter(
      "REACT_APP_PRODUCT_TYPE_CLAW"
    ).split(",");
    this.listProductMetal = Util.getStorageParameter(
      "REACT_APP_PRODUCT_METAL"
    ).split(",");

    const isWedding = Util.wordInArray(this.state.sale.saleType, ["wedding"]);

    let engravingFrontData = globalConst.LIST_ENGRAVING_FONT_NO_WEDDING;

    if (
      isWedding &&
      this.state.sale.ringPreference === globalConst.REACT_APP_REFERENCE_AG_WBJ
    ) {
      engravingFrontData = globalConst.LIST_ENGRAVING_FONT_WEDDING;
    }

    this.engravingFrontList = [];

    engravingFrontData.forEach((engravingFront) => {
      this.engravingFrontList.push({
        engravingFontName: engravingFront,
        id: engravingFront,
      });
    });
  };

  handleChange(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.value;

    if (event.target.value !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("product", true);
    }

    this.setState(state);
  }

  handleChangeCheckbox(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.checked;

    if (event.target.checked !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("product", true);
    }
    this.setState(state);
  }

  getFindEngamentRing = async (value) => {
    this.setState({
      loading_ring_reference: true,
    });

    const { sale } = this.state;

    const product = await getEngagementRingPictureChangeClawMetal(sale, value);

    this.setState({
      loading_ring_reference: false,
      ownReferenceModified: false,
    });

    if (!product) {
      this.setState({
        ownReferenceModified: true,
      });

      return;
    }

    const { reference } = product;

    this.setState({
      ringPreference: reference,
    });
  };

  async handleChangeSelect(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.value;

    if (field_name === "metal") {
      if (
        (event.target.value === "18ct Yellow Gold" ||
          event.target.value === "18ct White Gold" ||
          event.target.value === "18ct Rose Gold" ||
          event.target.value === "Platinum") &&
        this.state.ringPreference !== "Custom"
      ) {
        this.fetchNewJewelleryReference(
          this.state.ringPreference,
          event.target.value
        );
      } else {
        state["ownReferenceModified"] = true;
      }
    }

    if (field_name === "clawMetal") {
      this.getFindEngamentRing(event.target.value);
    }

    if (event.target.value !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("product", true);
    }

    this.setState(state);
  }

  renderIsStandarTypeOfClaw = (value) => {
    const { typeOfClaw } = this.state.typeAndClaw;

    if (!typeOfClaw) {
      return value;
    }

    if (value.toLocaleUpperCase() === typeOfClaw.toLocaleUpperCase()) {
      return `${value} (standard)`;
    }

    return value;
  };

  renderIsStandarMetalClaw = (value) => {
    const { metalClawStandard } = this.state.typeAndClaw;

    if (!metalClawStandard) {
      return value;
    }

    if (value.toLocaleUpperCase() === metalClawStandard.toLocaleUpperCase()) {
      return `${value} (standard)`;
    }

    return value;
  };

  onChangeSelect = (value, name) => {
    if (!value) {
      return;
    }

    const data = JSON.parse(value);

    this.setState({
      [name]: data?.[name] ?? "",
      fields_no_updated: false,
    });

    this.props.handleTabChange("product", true);
  };

  handleChangeInput = async (value, name) => {
    this.setState({
      [name]: value,
      fields_no_updated: false,
    });

    this.props.handleTabChange("product", true);
  };

  responseTags = (tags) => {
    this.setState({
      descriptionModified: Util.changeArrayToString(tags),
      fields_no_updated: false,
    });

    this.props.handleTabChange("product", true);
  };

  disabledOrderConfirmBySales = () => {
    const isSales = Auth.canEditField("rolesIsSales");
    const isCosts = Auth.canEditField("rolesIsCosts");

    if (
      this.state.manufacturingSalesRepValidated &&
      this.state.fields_no_updated &&
      (isSales || isCosts)
    ) {
      return true;
    }

    return false;
  };

  validDisabledFieldInForm = (activeForRolCost = false) => {
    const validUsers = Auth.canEditField("rolesEditFormSales");

    if (validUsers) {
      return false;
    }

    if (this.state.disabledFieldInForm) {
      return true;
    }

    if (this.disabledOrderConfirmBySales()) {
      return true;
    }

    const isCost = Auth.canEditField("rolesIsCosts", true);

    if (!activeForRolCost && isCost) {
      return true;
    }

    return false;
  };

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    const { sale } = this.state;

    const showMergeSale = Auth.canAccessRolesAndLeader(
      "rolesEditDiamonsOperations",
      true
    );

    const isSaleReturn = saleIsReturn(this.state.sale);

    return (
      <div id="SaleProductEdit_component">
        {this.state.updating_sale === true ? <LoadingLayer /> : ""}
        {this.state.loading_ring_reference === true ? <LoadingLayer /> : ""}
        {
          <FlashMessages
            error_messages={this.state.error_messages}
            warning_messages={this.state.warning_messages}
            success_messages={this.state.success_messages}
          />
        }
        {this.state.show_diamond_search_modal === true ? (
          <SaleEditDiamondSearch
            closeComponent={this.closeDiamondSearchPopup}
          />
        ) : null}
        {this.state.show_product_reference_search_modal === true ? (
          <SaleEditProductReferenceSearch
            closeComponent={this.closeProductReferenceSearchPopup}
          />
        ) : null}
        <Form>
          <Form.Group className="form-group">
            {Util.wordInArray(sale.saleType, [
              "engagement ring",
              "jewellery",
              "diamond",
              "wedding band",
            ]) === true && (
              <Card>
                <Card.Body>
                  <Row>
                    <Col xs={10} lg={10}>
                      <Form.Group className="form-group diamond-cert">
                        <FormLabel>
                          Diamond cert #, Shape ct Colour Clarity
                        </FormLabel>
                        <Form.Control
                          type="text"
                          name="diamondDetails"
                          value={
                            this.state.diamondDetails === null
                              ? ""
                              : this.state.diamondDetails
                          }
                          onChange={this.handleChange}
                          disabled={this.state.diamondDetailsDisabled}
                        />
                        {!isSaleReturn && (
                          <button
                            type="button"
                            className="react-datepicker__close-icon product-close-icon"
                            aria-label="Close"
                            tabIndex="-1"
                            onClick={this.resetDiamondDetails}
                            disabled={this.validDisabledFieldInForm()}
                          ></button>
                        )}
                      </Form.Group>
                      {showMergeSale ? (
                        <div className="row">
                          <Link to="" onClick={(e) => this.openSearchSales(e)}>
                            Merge diamond from sale
                          </Link>
                        </div>
                      ) : null}
                    </Col>

                    <Col xs={12} lg={2}>
                      {!isSaleReturn && (
                        <button
                          type="button"
                          className="btn btn-primary btn-black margin-top-32"
                          aria-label="Close"
                          tabIndex="-1"
                          onClick={this.openDiamondSearchPopup}
                          disabled={this.validDisabledFieldInForm()}
                        >
                          Search
                        </button>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )}

            {Util.wordInArray(sale.saleType, [
              "engagement ring",
              "jewellery",
              "diamond",
              "wedding band",
            ]) === true && (
              <Card className="mt-2">
                <Card.Body>
                  <Card.Title>Diamond status</Card.Title>
                  <Row>
                    <Col lg={4}>
                      <Form.Group className="form-group">
                        <FormLabel>Diamond Location</FormLabel>
                        <Form.Control
                          type="text"
                          name="diamondLocation"
                          value={this.state.diamondLocation ?? ""}
                          onChange={this.handleChange}
                          disabled={this.validDisabledFieldInForm(true)}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={4}>
                      <Form.Group className="form-group">
                        <FormLabel>In transit to</FormLabel>
                        <Form.Control
                          type="text"
                          name="inTransitTo"
                          value={this.state.inTransitTo ?? ""}
                          onChange={this.handleChange}
                          disabled={this.validDisabledFieldInForm(true)}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={4}>
                      <Form.Group className="form-group mt-3">
                        <Form.Check
                          type="checkbox"
                          name="requestedToOverseasProvider"
                          label="Requested to overseas provider"
                          checked={
                            this.state.requestedToOverseasProvider === true
                              ? true
                              : false
                          }
                          onChange={this.handleChangeCheckbox}
                          disabled={this.validDisabledFieldInForm(true)}
                        />
                        <Form.Check
                          type="checkbox"
                          name="confirmedFromOverseasProvider"
                          label="Confirmed from overseas provider"
                          checked={
                            this.state.confirmedFromOverseasProvider === true
                              ? true
                              : false
                          }
                          onChange={this.handleChangeCheckbox}
                          disabled={this.validDisabledFieldInForm(true)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )}

            <Card className="mt-2">
              <Card.Body>
                <Row>
                  <Col lg={6}>
                    <FormLabel className="d-block">Type of Order</FormLabel>
                    <Form.Select
                      disabled={this.validDisabledFieldInForm()}
                      onChange={(e) => this.handleChangeSelect(e)}
                      value={this.state.orderType ?? ""}
                      name="orderType"
                      required={true}
                    >
                      <option value=""></option>
                      {this.listProductTypeOrder.map((listTypeOrder) => {
                        return (
                          <option key={listTypeOrder} value={listTypeOrder}>
                            {listTypeOrder}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Col>
                  <Col lg={6}>
                    {Util.wordInArray(sale.saleType, ["jewellery"]) ===
                      true && (
                      <Form.Group className="form-group">
                        <FormLabel>Type of Jewellery</FormLabel>
                        <Form.Control
                          disabled={this.validDisabledFieldInForm()}
                          type="text"
                          name="jewelleryType"
                          value={this.state.jewelleryType ?? ""}
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <FormLabel className="d-block">Type of Product</FormLabel>
                    <Form.Select
                      disabled={this.validDisabledFieldInForm()}
                      onChange={(e) => this.handleChangeSelect(e)}
                      value={this.state.saleType ?? ""}
                      name="saleType"
                      required={true}
                    >
                      <option value=""></option>
                      {this.listProductType.map((listType) => {
                        return (
                          <option
                            key={listType}
                            value={listType}
                            disabled={
                              isSaleReturn &&
                              listType ===
                                globalConst.REACT_APP_SALE_TYPE_LOOSE_DIAMOND
                            }
                          >
                            {listType}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group product-reference">
                      <FormLabel>Product's reference</FormLabel>
                      <Form.Control
                        disabled={this.validDisabledFieldInForm()}
                        type="text"
                        name="ringPreference"
                        value={this.state.ringPreference ?? ""}
                        onChange={this.handleChange}
                        onClick={this.openProductReferenceSearchPopup}
                      />
                      <button
                        type="button"
                        className="react-datepicker__close-icon product-close-icon"
                        aria-label="Close"
                        tabIndex="-1"
                        disabled={this.validDisabledFieldInForm()}
                        onClick={this.resetRingPreference}
                      ></button>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card className="mt-2">
              <Card.Body>
                <Row>
                  <Col lg={6}>
                    <Form.Check
                      disabled={this.validDisabledFieldInForm()}
                      type="checkbox"
                      name="ownReferenceModified"
                      label="Customisation"
                      checked={
                        this.state.ownReferenceModified === true ? true : false
                      }
                      onChange={this.handleChangeCheckbox}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    {this.state.ownReferenceModified && (
                      <div className="mb-2">
                        <AddElementTag
                          tags={this.state.descriptionModified ?? ""}
                          responseTags={this.responseTags}
                          disabled={this.validDisabledFieldInForm()}
                        />
                      </div>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    {Util.wordInArray(sale.saleType, ["wedding"]) === true &&
                      sale.ringPreference ===
                        globalConst.REACT_APP_REFERENCE_AG_WBJ && (
                        <Form.Group className="form-group">
                          <FormLabel>Product detail</FormLabel>
                          <Form.Control
                            as="textarea"
                            name="productDetails"
                            value={this.state.productDetails ?? ""}
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                      )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card className="mt-2">
              <Card.Body>
                <Row>
                  <Col lg={6}>
                    {Util.wordInArray(sale.saleType, ["engagement ring"]) ===
                      true && (
                      <Form.Group className="form-group">
                        <Row>
                          <Col lg="8">
                            <FormLabel>Type of Claw</FormLabel>
                          </Col>
                          <Col lg="4">
                            {this.state.loadDataStandard === true ? (
                              <LoadingSpinnerLayer />
                            ) : null}
                          </Col>
                        </Row>

                        <Form.Select
                          disabled={this.validDisabledFieldInForm()}
                          onChange={(e) => this.handleChangeSelect(e)}
                          value={this.state.clawType ?? ""}
                          name="clawType"
                          required={true}
                        >
                          <option value=""></option>
                          {this.listProductTypeClaw.map((listTypeClaw) => {
                            return (
                              <option key={listTypeClaw} value={listTypeClaw}>
                                {this.renderIsStandarTypeOfClaw(listTypeClaw)}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    )}
                  </Col>
                  <Col lg={6}>
                    {Util.wordInArray(sale.saleType, ["engagement ring"]) ===
                      true && (
                      <Form.Group className="form-group">
                        <Row>
                          <Col lg="8">
                            <FormLabel>Claw metal</FormLabel>
                          </Col>
                          <Col lg="4">
                            {this.state.loadDataStandard === true ? (
                              <LoadingSpinnerLayer />
                            ) : null}
                          </Col>
                        </Row>
                        <Form.Select
                          disabled={this.validDisabledFieldInForm()}
                          onChange={(e) => this.handleChangeSelect(e)}
                          value={this.state.clawMetal ?? ""}
                          name="clawMetal"
                          required={true}
                        >
                          <option value=""></option>
                          {this.listProductMetal.map((listMetal) => {
                            return (
                              <option key={listMetal} value={listMetal}>
                                {this.renderIsStandarMetalClaw(listMetal)}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Form.Group className="form-group">
                      <FormLabel className="d-block">Band Metal</FormLabel>
                      <Form.Select
                        disabled={this.validDisabledFieldInForm()}
                        onChange={(e) => this.handleChangeSelect(e)}
                        value={this.state.metal ?? ""}
                        name="metal"
                        required={true}
                      >
                        <option value=""></option>
                        {this.listProductMetal.map((listMetal) => {
                          return (
                            <option key={listMetal} value={listMetal}>
                              {listMetal}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    {Util.wordInArray(sale.saleType, [
                      "engagement ring",
                      "wedding",
                    ]) === true && (
                      <Form.Group className="form-group">
                        <FormLabel>Ring Size</FormLabel>
                        <Form.Control
                          disabled={this.validDisabledFieldInForm()}
                          type="text"
                          name="ringSize"
                          value={this.state.ringSize ?? ""}
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    )}
                  </Col>
                </Row>

                <Row>
                  {Util.wordInArray(sale.saleType, [
                    "engagement ring",
                    "wedding",
                  ]) === true && (
                    <>
                      <Col lg={6}>
                        <Form.Group className="form-group">
                          <FormLabel>Engraving</FormLabel>
                          <Form.Control
                            disabled={this.validDisabledFieldInForm()}
                            type="text"
                            name="engraving"
                            value={this.state.engraving ?? ""}
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <FormSelectCustomer
                          label="Engraving Font"
                          nameInput="engravingFontName"
                          items={this.engravingFrontList}
                          keyValue={"id"}
                          value={this.state.engravingFontName ?? ""}
                          onResponse={this.onChangeSelect}
                          columnsSize={12}
                          disabled={this.validDisabledFieldInForm()}
                        />
                      </Col>
                    </>
                  )}
                </Row>

                <Row>
                  <Col lg={6}>
                    <FormInputCheckbox
                      typeView="checkbox"
                      type="checkbox"
                      label="Order confirmed by sales rep to start manufacturing"
                      nameInput="manufacturingSalesRepValidated"
                      checked={
                        this.state.manufacturingSalesRepValidated ?? false
                      }
                      required={true}
                      onResponse={this.handleChangeInput}
                      disabled={this.validDisabledFieldInForm()}
                    />
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group">
                      <FormLabel>Customer's Notes</FormLabel>
                      <Form.Control
                        type="text"
                        name="notes"
                        value={this.state.notes ?? ""}
                        onChange={this.handleChange}
                        disabled={this.validDisabledFieldInForm()}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Form.Group>

          <Button
            type="submit"
            onClick={this.editSale}
            disabled={
              this.state.updating_sale ||
              this.state.fields_no_updated ||
              this.state.loading_ring_reference
            }
          >
            Save Product Information
          </Button>
        </Form>
        {this.showBtnMergeSaleDiamond() ? (
          <MergeSearchSaleDiamond
            sale={sale}
            onClose={this.closeSearchSale.bind(this)}
          />
        ) : null}
      </div>
    );
  }

  showBtnMergeSaleDiamond = () => {
    if (
      this.state.showMergeSaleDiamond &&
      Auth.canAccessRolesAndLeader("rolesEditDiamonsOperations")
    ) {
      return true;
    }

    return false;
  };

  openSearchSales = (event) => {
    event.preventDefault();

    this.setState({
      showMergeSaleDiamond: true,
    });
  };

  closeSearchSale = (sale) => {
    this.setState({
      showMergeSaleDiamond: false,
    });

    if (!sale) {
      return null;
    }

    const diamondDetailsOrigin = this.state.sale.diamondDetails;
    const gdOwnKey = this.state.sale.gdOwnKey;

    const diamondDetails = getDiamondCertificateMerge(
      sale,
      diamondDetailsOrigin ?? ""
    );
    const diamondGdOwnKey = getDiamondGdOwnKey(
      {
        reference: sale.gdOwnKey,
      },
      gdOwnKey ?? ""
    );

    const costs = SearchValuesSales(sale);

    this.setState({
      diamondDetails: diamondDetails,
      gdOwnKey: diamondGdOwnKey,
      fields_no_updated: false,
      saleMerge: {
        id: sale.id,
        diamondDetails: sale.diamondDetails,
        gdOwnKey: sale.gdOwnKey,
        soldPrice: costs.soldPrice ?? null,
        discountPercentage: costs.discountPercentage ?? null,
        discountPrice: costs.discountPrice ?? null,
      },
    });
  };

  openDiamondSearchPopup = () => {
    this.setState({
      show_diamond_search_modal: true,
    });
  };

  closeDiamondSearchPopup = (diamond) => {
    if (Object.keys(diamond).length > 0) {
      const { diamondLocation, diamondDetails, gdOwnKey } = this.state;

      const cityName = getDiamondLocationCityName(
        diamond,
        diamondLocation ?? ""
      );
      const diamondCertificate = getDiamondCertificate(
        diamond,
        diamondDetails ?? ""
      );
      const diamondGdOwnKey = getDiamondGdOwnKey(diamond, gdOwnKey ?? "");

      this.setState({
        show_diamond_search_modal: false,
        diamondDetailsDisabled: true,
        fields_no_updated: false,
        diamondDetails: diamondCertificate,
        diamondLocation: cityName,
        gdOwnKey: diamondGdOwnKey,
      });

      this.props.handleTabChange("product", true);
    } else {
      this.setState({
        show_diamond_search_modal: false,
      });
    }
  };

  openProductReferenceSearchPopup = () => {
    this.setState({
      show_product_reference_search_modal: true,
    });
  };

  closeProductReferenceSearchPopup = (selected_product_reference) => {
    if (
      selected_product_reference !== null &&
      typeof selected_product_reference !== "undefined"
    ) {
      this.setState({
        show_product_reference_search_modal: false,
        ringPreference: selected_product_reference,
        fields_no_updated: false,
      });

      this.props.handleTabChange("product", true);
    } else {
      this.setState({
        show_product_reference_search_modal: false,
      });
    }
  };

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  static getDerivedStateFromProps(props) {
    return {
      sale: props.sale,
    };
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************

  axioRequest = null;
  editSale(event) {
    event.preventDefault();
    this.setState({
      updating_sale: true,
    });
    const user = Auth.getAuthenticatedUser();
    const sale = this.props.sale;
    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else if (sale === null || sale === undefined) {
      this.setState({
        error_messages: [
          "Sale identification error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_SALES_EDIT;
      url = url.replace("{id}", sale.id);

      const params = new URLSearchParams();

      params.append("diamondDetails", this.state.diamondDetails);
      params.append("gdOwnKey", this.state.gdOwnKey);
      params.append("diamondLocation", this.state.diamondLocation);
      params.append(
        "inTransitTo",
        this.state.inTransitTo ? this.state.inTransitTo : ""
      );
      params.append(
        "requestedToOverseasProvider",
        this.state.requestedToOverseasProvider
          ? this.state.requestedToOverseasProvider
          : "null"
      );
      params.append(
        "confirmedFromOverseasProvider",
        this.state.confirmedFromOverseasProvider
          ? this.state.confirmedFromOverseasProvider
          : "null"
      );
      params.append("ringPreference", this.state.ringPreference);
      params.append("clawType", this.state.clawType);
      params.append("engraving", this.state.engraving);
      params.append("metal", this.state.metal);
      params.append("clawMetal", this.state.clawMetal);
      params.append("ringSize", this.state.ringSize);
      params.append("jewelleryType", this.state.jewelleryType);
      params.append("orderType", this.state.orderType);

      if (this.state.orderType === "Express") {
        params.append("expressJobRequired", true);
        params.append("includesReadyToShip", false);
      } else if (this.state.orderType === "Ready to Ship") {
        params.append("expressJobRequired", false);
        params.append("includesReadyToShip", true);
      } else {
        params.append("expressJobRequired", false);
        params.append("includesReadyToShip", false);
      }

      params.append("saleType", this.state.saleType);
      params.append(
        "ownReferenceModified",
        this.state.ownReferenceModified
          ? this.state.ownReferenceModified
          : "null"
      );
      params.append(
        "productDetails",
        this.state.productDetails ? this.state.productDetails : ""
      );
      if (this.state.descriptionModified) {
        params.append("descriptionModified", this.state.descriptionModified);
      }
      params.append(
        "manufacturingSalesRepValidated",
        this.state.manufacturingSalesRepValidated
          ? this.state.manufacturingSalesRepValidated
          : "null"
      );
      params.append("notes", this.state.notes ? this.state.notes : "");
      params.append(
        "engravingFontName",
        this.state.engravingFontName ? this.state.engravingFontName : ""
      );

      const { saleMerge } = this.state;

      if (saleMerge.id) {
        params.append("saleMerge", JSON.stringify(saleMerge));
      }

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      axios
        .put(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              updating_sale: false,
              success_messages: ["Sale updated"],
              fields_no_updated: true,
            });
            this.props.handleTabChange("product", false);
            this.props.saleUpdated(data.result, true);
          } else {
            this.setState({
              updating_sale: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else {
            this.setState({
              updating_sale: true,
            });
          }
          //console.log((err);
          return null;
        });
    }
  }

  ajaxRequest = null;
  fetchNewJewelleryReference(oldReference, newMetal) {
    if (
      oldReference !== "" &&
      oldReference !== null &&
      oldReference !== undefined
    ) {
      this.setState({
        loading_ring_reference: true,
      });

      if (this.ajaxRequest !== null) {
        this.ajaxRequest.cancel();
      }

      this.ajaxRequest = axios.CancelToken.source();

      const url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_PRODUCT_LIST;

      const parameters = {
        reference: oldReference,
        metal: newMetal,
      };
      axios
        .get(
          url,
          {
            params: parameters,
            headers: { Authorization: `Bearer ${Auth.getToken()}` },
          },
          {
            cancelToken: this.ajaxRequest.token,
          }
        )
        .then((api_response) => {
          this.setState({
            loading_ring_reference: false,
          });

          const result = api_response.data.result;
          const items = result.rows;
          if (Array.isArray(items) === true && items.length > 0) {
            const product = items[0];
            this.setState({
              ringPreference: product.reference,
              ownReferenceModified: false,
            });
          } else {
            this.setState({
              ownReferenceModified: true,
            });
          }
        })
        .catch((err) => {
          this.setState({
            loading_ring_reference: false,
          });

          if (err.response) {
            if ("status" in err.response && err.response.status === 401) {
              console.log("User not authorised. Please reload the page");
            } else {
              console.log(
                "There was a problem processing the information. Please reload this page and try again."
              );
            }
          } else if (err.request) {
            console.log(
              "There was a problem with your request. Please reload this page and try again."
            );
          }
          console.log(err);
        });
    } else {
    }
  }

  resetDiamondDetails() {
    this.setState({
      diamondDetails: "",
      gdOwnKey: "",
      fields_no_updated: false,
    });
  }

  resetRingPreference() {
    this.setState({
      ringPreference: "",
    });
  }
}

export default SaleProductEdit;
