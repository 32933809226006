import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ResultAnalysisDateFilter from "../components/result_analysis_date_filter";
import SalesRepSelect from "../components/sales_rep_select";
import { dateFormatCustom } from "../../../utils/dateUtils";
import Auth from "../../../Auth";
import {
  fetchReportRequest,
  fetchResultAnalysis,
  fetchResultAnalysisDetails,
  fetchResultAnalysisDetailsPerEmployee,
  fetchResultCommissionsAnalysis,
  loadingRepots,
  setDownloadExcel,
  setDownloadExcelReport,
} from "../../../store/slices";
import { useDispatch, useSelector } from "react-redux";
import { DownloadField } from "../../actions/ExportReportSales";
import {
  CONST_CUSTOMER,
  DOWNLOAD_CHOICE_REPORTS,
} from "../../../store/constants";
import {
  fetchAppointmentsReportExcel,
  fetchCommissionsReportExcel,
  fetchResultAppointmentsEfficiency,
} from "./FecthReports";
import ResultAnalysisDatePaidFilter from "../components/resultAnalysisDatePaidFilter";
import Util from "../../../Util";
import GenerateReportOptions from "../components/GenerateReportOptions";

const ALL = "";

const REPORTS = [{ value: "workflowReport", name: "Workflow report" }];

const InputsFormSearch = () => {
  const { downloadExcel, downloadExcelNameReport } = useSelector(
    (state) => state.reports
  );

  const [validated, setValidated] = useState(false);
  const [findFirst, setFindFirst] = useState(true);

  const date = new Date();
  const user = Auth.getAuthenticatedUser();
  const { name } = user.employeeModel;
  const leader = Auth.canAccessRolesAndLeader("rolesEditReports", true);
  const roleAccounting = Auth.canAccessRolesAndLeader("accountingStatus");

  const dispatch = useDispatch();

  const { selectedReport } = useSelector((state) => state.reports);
  const [employee, setEmployee] = useState(
    leader || roleAccounting ? ALL : name
  );

  const [report, setReport] = useState("");

  const [startDate, setStartDate] = useState(
    `${dateFormatCustom(date, "YYYY")}-${dateFormatCustom(date, "MM")}-01`
  );
  const [endDate, setEndDate] = useState(dateFormatCustom(date));

  const [startDatePaid, setStartDatePaid] = useState();
  const [endDatePaid, setEndDatePaid] = useState();

  const [requiredDate, setRequiredDate] = useState(true);

  const onResponseDate = async (response) => {
    const { startDate, endDate } = response;

    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onResponseDatePaid = async (response) => {
    const { startDatePaid, endDatePaid } = response;

    setStartDatePaid(startDatePaid);
    setEndDatePaid(endDatePaid);

    if (startDatePaid || endDatePaid) {
      setRequiredDate(false);
    }

    if (!startDatePaid && !endDatePaid) {
      setRequiredDate(true);
    }
  };

  const onResponseEmployee = async (employee) => {
    setEmployee(employee);
  };

  const onResponseReport = async (report) => {
    setReport(report);
  };

  const onGenerateReport = useCallback(async () => {
    dispatch(loadingRepots(true));

    if (CONST_CUSTOMER.REPORTS.resultSales === selectedReport) {
      await dispatch(
        fetchResultAnalysis({
          startDate: startDate,
          endDate: endDate,
          employee: employee,
        })
      );
    }

    if (CONST_CUSTOMER.REPORTS.resultAppointments === selectedReport) {
      await dispatch(
        fetchResultAnalysisDetails({
          startDate: startDate,
          endDate: endDate,
          employee: employee,
        })
      );

      await dispatch(
        fetchResultAnalysisDetailsPerEmployee({
          startDate: startDate,
          endDate: endDate,
          employee: employee,
        })
      );
    }

    if (
      [
        CONST_CUSTOMER.REPORTS.resultCommissionsSales,
        CONST_CUSTOMER.REPORTS.resultCommissionsReviews,
      ].includes(selectedReport)
    ) {
      await dispatch(
        fetchResultCommissionsAnalysis({
          startDate: startDate,
          endDate: endDate,
          startDatePaid: startDatePaid,
          endDatePaid: endDatePaid,
          employee: employee,
          typeOfReport:
            selectedReport === CONST_CUSTOMER.REPORTS.resultCommissionsSales
              ? "sales"
              : "reviews",
        })
      );
    }

    if (CONST_CUSTOMER.REPORTS.reportRequest === selectedReport && report) {
      await dispatch(
        fetchReportRequest({
          startDate: startDate,
          endDate: endDate,
          report: report,
          parameters: {
            startDate: startDate,
            endDate: endDate,
          },
        })
      );
    }

    dispatch(loadingRepots(false));
  }, [
    dispatch,
    employee,
    endDate,
    endDatePaid,
    report,
    selectedReport,
    startDate,
    startDatePaid,
  ]);

  useEffect(() => {
    if (findFirst) {
      onGenerateReport();

      const loadReport = async () => {
        await Util.timeAwait(100);
        setFindFirst(false);
      };

      loadReport();
    }
  }, [findFirst, onGenerateReport]);

  useEffect(() => {
    if (
      downloadExcel &&
      CONST_CUSTOMER.REPORTS.resultAppointments === selectedReport
    ) {
      fetchAppointmentsReportExcel({
        startDate: startDate,
        endDate: endDate,
        employee: employee,
      }).then((data) => {
        DownloadField(data, "appointments");
        dispatch(setDownloadExcel(false));
      });
    }
    if (
      downloadExcel &&
      [
        CONST_CUSTOMER.REPORTS.resultCommissionsSales,
        CONST_CUSTOMER.REPORTS.resultCommissionsReviews,
      ].includes(selectedReport)
    ) {
      fetchCommissionsReportExcel({
        startDate: startDate,
        endDate: endDate,
        startDatePaid: startDatePaid,
        endDatePaid: endDatePaid,
        employee: employee,
        typeOfReport:
          selectedReport === CONST_CUSTOMER.REPORTS.resultCommissionsSales
            ? "sales"
            : "reviews",
      }).then((data) => {
        let reportFilename =
          selectedReport === CONST_CUSTOMER.REPORTS.resultCommissionsReviews
            ? "commissions-sales"
            : "commissions-reviews";
        DownloadField(data, reportFilename);
        dispatch(setDownloadExcel(false));
      });
    }

    if (downloadExcelNameReport) {
      switch (downloadExcelNameReport) {
        case DOWNLOAD_CHOICE_REPORTS[0]:
          fetchResultAppointmentsEfficiency({
            startDate: startDate,
            endDate: endDate,
            employee: employee,
          }).then((data) => {
            DownloadField(data, "appointments-efficiency");
            dispatch(setDownloadExcel(""));
          });
          break;
        default:
          break;
      }
      dispatch(setDownloadExcelReport(""));
    }
  }, [
    dispatch,
    employee,
    endDate,
    selectedReport,
    startDate,
    downloadExcel,
    downloadExcelNameReport,
    startDatePaid,
    endDatePaid,
  ]);

  const handledSubmit = (event) => {
    event.preventDefault();

    setValidated(false);
    const form = event.currentTarget;
    const check = form.checkValidity();

    if (!check) {
      setValidated(true);
      return;
    }

    onGenerateReport();
  };

  return (
    <Card>
      <Card.Body>
        <Form validated={validated} noValidate onSubmit={handledSubmit}>
          <Row>
            <Col xs={12} lg={10}>
              <Row>
                <Col xs={12} lg={8}>
                  <ResultAnalysisDateFilter
                    onResponse={onResponseDate}
                    required={requiredDate}
                  />
                </Col>
                {selectedReport !== CONST_CUSTOMER.REPORTS.reportRequest && (
                  <Col xs={12} lg={4}>
                    <SalesRepSelect onResponse={onResponseEmployee} />
                  </Col>
                )}
                {selectedReport === CONST_CUSTOMER.REPORTS.reportRequest && (
                  <Col xs={12} lg={4}>
                    <GenerateReportOptions
                      onResponse={onResponseReport}
                      reports={REPORTS}
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col xs={12} lg={8}>
                  {(selectedReport ===
                    CONST_CUSTOMER.REPORTS.resultCommissionsSales ||
                    selectedReport ===
                      CONST_CUSTOMER.REPORTS.resultCommissionsReviews) && (
                    <ResultAnalysisDatePaidFilter
                      onResponse={onResponseDatePaid}
                    />
                  )}
                </Col>
              </Row>
            </Col>
            {selectedReport !== CONST_CUSTOMER.REPORTS.reportRequest && (
              <Col xs={12} lg={2}>
                <Button className="float-center mt-4" type="submit">
                  Filter
                </Button>
              </Col>
            )}
            {selectedReport === CONST_CUSTOMER.REPORTS.reportRequest && (
              <Col xs={12} lg={2}>
                <Button className="float-center mt-4" type="submit">
                  Generate
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

InputsFormSearch.propTypes = {
  nameReport: PropTypes.string,
};

export default InputsFormSearch;
