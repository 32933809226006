import React, { Component } from "react";
import Auth from "../../../Auth";
import axios from "axios";
import * as globalConst from "../../../global/const";

// SCSS
import "../../../css/sales/sale_information.scss";
import { NavLink } from "react-bootstrap";
import Util from "../../../Util";

class PostSaleInformationRequest extends Component {
  constructor(props) {
    super();
    this.state = {
      sale: props.sale,
      previousPostSaleServices: 0,
    };
    this.getSaleCount = this.getSaleCount.bind(this);
  }

  openCustomer = (id) => {
    window.open(`/customers?customer-ref=${id}`);
  };

  render() {
    const sale = this.state.sale;

    let typeOfJob = "";
    if (sale.typeOfJob === "resize_startrack") {
      typeOfJob = "Resize Startrack";
    }
    if (sale.typeOfJob === "resize_showroom") {
      typeOfJob = "Resize in Showroom";
    }
    if (sale.typeOfJob === "fixing_startrack") {
      typeOfJob = "Fixing in Startrack";
    }
    if (sale.typeOfJob === "fixing_showroom") {
      typeOfJob = "Fixing in Showroom";
    } else {
      typeOfJob = sale.typeOfJob;
    }

    let created = null;
    if (sale.created !== null && sale.created !== "") {
      created = new Date(sale.created).toLocaleString("default", {
        day: "numeric",
        month: "short",
        year: "numeric",
        timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE"),
      });
      created = created.slice(0, -5) + ", " + created.slice(-4);
    }

    let datePickupRequested = null;
    if (sale.datePickupRequested !== null && sale.datePickupRequested !== "") {
      datePickupRequested = new Date(sale.datePickupRequested).toLocaleString(
        "default",
        {
          day: "numeric",
          month: "short",
          year: "numeric",
          timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE"),
        }
      );
      datePickupRequested =
        datePickupRequested.slice(0, -5) + ", " + datePickupRequested.slice(-4);
    }

    let previousPostSaleServicesHighlight = "";
    if (this.state.previousPostSaleServices > 0) {
      previousPostSaleServicesHighlight = "redText";
    }

    return (
      <div className="sale_information_subcontainer">
        <div className="container">
          <div className="row">
            <div className="col-sm table_title">
              <span className="table_title">Type of Job</span>
            </div>
            <div className="col-sm">{typeOfJob}</div>
          </div>
          <div className="row">
            <div className="col-sm table_title">
              <span className="table_title">Name</span>
            </div>
            <div className="col-sm">{sale.name}</div>
          </div>
          <div className="row">
            <div className="col-sm table_title">
              <span className="table_title">Phone</span>
            </div>
            <div className="col-sm">{sale.phone}</div>
          </div>
          <div className="row">
            <div className="col-sm table_title">
              <span className="table_title">Email</span>
            </div>
            <div className="col-sm">
              {sale?.email && (
                <NavLink
                  onClick={() => this.openCustomer(sale.email)}
                  className="link-primary"
                >
                  {sale.email}
                </NavLink>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm table_title">
              <span className="table_title">Request Method</span>
            </div>
            <div className="col-sm">{sale.requestMethod}</div>
          </div>
          <div className="row">
            <div className="col-sm table_title">
              <span className="table_title">Pickup address</span>
            </div>
            <div
              className="col-sm"
              dangerouslySetInnerHTML={{ __html: sale.pickupAddress }}
            ></div>
          </div>
          <div className="row">
            <div className="col-sm table_title">
              <span className="table_title">Drop off address</span>
            </div>
            <div
              className="col-sm"
              dangerouslySetInnerHTML={{ __html: sale.dropOffAddress }}
            ></div>
          </div>
          <div className="row">
            <div className="col-sm table_title">
              <span className="table_title">Request Description</span>
            </div>
            <div className="col-sm">{sale.requestDescription}</div>
          </div>
          <div className="row">
            <div className="col-sm table_title">
              <span className="table_title">Created</span>
            </div>
            <div className="col-sm">{created}</div>
          </div>
          <div className="row">
            <div className="col-sm table_title">
              <span className="table_title">
                Pick up tracking # and Booking Number
              </span>
            </div>
            <div className="col-sm">{sale.trackingBookingNumber}</div>
          </div>
          <div className="row">
            <div className="col-sm table_title">
              <span className="table_title">Pick up date requested</span>
            </div>
            <div className="col-sm">{datePickupRequested}</div>
          </div>
          <div className="row">
            <div className="col-sm table_title">
              <span className="table_title">
                Post Sales Services related to the same order
              </span>
            </div>
            <div className={"col-sm " + previousPostSaleServicesHighlight}>
              {this.state.previousPostSaleServices}
            </div>
          </div>
        </div>
      </div>
    );
  }

  ajaxRequest = null;
  getSaleCount(saleId) {
    //AJAX CONTROLS
    //cancel previous ajax request if exists
    if (this.ajaxRequest !== null) {
      this.ajaxRequest.cancel();
    }
    // creates a new token for upcomming ajax (overwrite the previous one)
    this.ajaxRequest = axios.CancelToken.source();
    //FINISH AJAX CONTROLS

    const params = new URLSearchParams();
    params.append("salesId", saleId);

    const url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_POST_SALE_SERVICES_LIST;
    axios
      .get(
        url,
        {
          params: params, //this params are sent as query. In post requests they should go as body params in the following block
          headers: { Authorization: `Bearer ${Auth.getToken()}` },
        },
        {
          cancelToken: this.ajaxRequest.token,
        }
      )
      .then((api_response) => {
        const result = api_response.data.result;

        if (saleId === null) {
          this.setState({
            previousPostSaleServices: 0,
          });
        } else {
          this.setState({
            previousPostSaleServices: result.count,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          if ("status" in err.response && err.response.status === 401) {
            alert("User not authorised. Please reload the page");
          } else {
            alert(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }
          this.setState({
            loading_page: false,
          });
        } else if (err.request) {
          alert(
            "There was a problem with your request. Please reload this page and try again."
          );
          this.setState({
            loading_page: false,
          });
        } else {
          //error not detected. Request could have been cancelled and it's waiting for second
          //request to finish
          this.setState({
            loading_page: true,
          });
        }
        console.log(err);
      });
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  componentDidMount() {
    const sale = this.props.sale;

    if ("salesId" in sale) {
      this.getSaleCount(sale.salesId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sale !== this.props.sale) {
      const sale = this.props.sale;

      if ("salesId" in sale) {
        this.getSaleCount(sale.salesId);
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    return {
      sale: props.sale,
    };
  }
  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default PostSaleInformationRequest;
