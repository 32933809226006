import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import {
  findWorkflowActivityDelivering,
  findWorkflowActivityOnlyDelivered,
} from "./WorkflowSaleActions";
import {
  convertUtcToTimezone,
  dateFormatCustom,
} from "../../../../utils/dateUtils";
import Util from "../../../../Util";

const WorkflowSaleTitles = ({ provider, saleWorkflowReturn, workflowSale }) => {
  if (!workflowSale) {
    return "";
  }

  const { providerGivenDate, providerExpectedDate } = workflowSale;

  const colorStatus = () => {
    const { status } = workflowSale;
    let workflowStatusClass = "";

    switch (status) {
      case "In transit":
        workflowStatusClass = "inTransit";
        break;
      case "Collected/Completed":
        workflowStatusClass = "completed";
        break;
      case "Cancelled":
        workflowStatusClass = "cancelled";
        break;
      default:
        break;
    }

    return workflowStatusClass;
  };

  const isOnlydelivering = findWorkflowActivityOnlyDelivered(workflowSale);

  const { status, created } = workflowSale;
  let { collectedCompletedDate } = workflowSale;

  const renderDateOthers = () => {
    return (
      <>
        <p>Date given to provider: {providerGivenDate}</p>
        <p>Expected date from provider: {providerExpectedDate}</p>
      </>
    );
  };

  const renderDateReturn = () => {
    return (
      <>
        <p>Date received from customer: {providerGivenDate}</p>
      </>
    );
  };

  const renderDateDelivering = () => {
    const deliverign = findWorkflowActivityDelivering(workflowSale);

    let { workflowDeliveringToCustomerModel = {} } = deliverign;

    if (!workflowDeliveringToCustomerModel) {
      workflowDeliveringToCustomerModel = {};
    }

    const {
      deliveringPickedUpInShowroom,
      deliveringPickedUpInShowroomDate,
      deliveredToCustomer,
      deliveredToCustomerDate,
    } = workflowDeliveringToCustomerModel;

    let dateCompletedOn = null;

    if (deliveringPickedUpInShowroom && deliveringPickedUpInShowroomDate) {
      dateCompletedOn = deliveringPickedUpInShowroomDate;
    }

    if (deliveredToCustomer && deliveredToCustomerDate) {
      dateCompletedOn = deliveredToCustomerDate;
    }

    return (
      <>
        <p>Created on: {dateFormatCustom(created, "DD MMM, YYYY")}</p>
        <p>
          Completed on:{" "}
          {dateCompletedOn && dateFormatCustom(dateCompletedOn, "DD MMM, YYYY")}
        </p>
      </>
    );
  };

  return (
    <Col xs={9} lg={9}>
      <p>Provider: {provider}</p>

      {saleWorkflowReturn && renderDateReturn()}
      {isOnlydelivering && renderDateDelivering()}
      {!saleWorkflowReturn && !isOnlydelivering && renderDateOthers()}

      <p>
        Status:
        <span className={colorStatus()}>
          {status}
          {collectedCompletedDate && (
            <span>
              &nbsp;-&nbsp;
              {convertUtcToTimezone(
                collectedCompletedDate,
                "YYYY-MM-DD h:mm:ss",
                Util.getStorageParameter("REACT_APP_TIMEZONE")
              )}
            </span>
          )}
        </span>
      </p>
    </Col>
  );
};

WorkflowSaleTitles.propTypes = {
  provider: PropTypes.string.isRequired,
  saleWorkflowReturn: PropTypes.bool.isRequired,
  workflowSale: PropTypes.any.isRequired,
};

export default WorkflowSaleTitles;
