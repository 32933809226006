import {
  Form,
  FormLabel,
  Button,
  Card,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import React, { Component } from "react";
import LoadingLayer from "../../LoadingLayer";
import FlashMessages from "../../FlashMessages";
import Auth from "../../../Auth";
import axios from "axios";
import * as globalConst from "../../../global/const";

// SCSS
import "../../../css/sales/sale_edit.scss";
import SaleDiamondCost from "./Cost/SaleDiamond/SaleDiamondCost";
import {
  ExistSaleDiamonds,
  GetSaleDiamonds,
} from "../detail/Actions/GetSaleDiamonds";
import { CalculateValueDiamondTotal } from "./Acctions/SaleDiamondCostCalculate";
import Util from "../../../Util";
import { saleIsReturn } from "./Acctions/SaleRetutn";
import LoadingSpinnerLayer from "../../LoadingSpinnerLayer";
import { getInformationWorkflowReturn } from "./Acctions/WorkflowReturn";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormSelectMultiple from "../../Form/FormSelectMultiple";

class SaleAccountsEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sale: props.sale,
      billingInformation: props.sale.billingInformation,
      paymentIdVerification: props.sale.paymentIdVerification,
      orderCurrency: props.sale.orderCurrency,
      pricesIncludeGst: props.sale.pricesIncludeGst,
      totalDiamondWogst: props.sale.totalDiamondWogst,
      totalSettingWogst: props.sale.totalSettingWogst,
      cadPayment: props.sale.cadPayment,
      amountAlreadyPaid: props.sale.amountAlreadyPaid,
      expressManufacturingWogst: props.sale.expressManufacturingWogst,
      engravingWogst: props.sale.engravingWogst,
      totalIncGst: props.sale.totalIncGst,
      balance: props.sale.balance,
      paysByDeposit: props.sale.paysByDeposit,
      paymentMethod: props.sale.paymentMethod,
      invoice: props.sale.invoice,
      xeroCode: props.sale.xeroCode,
      invoiceValueLocalCurrency: props.sale.invoiceValueLocalCurrency,
      promotionsDiscount: props.sale.promotionsDiscount,
      promotionsOfferedToCustomer: props.sale.promotionsOfferedToCustomer,
      voucherCode: props.sale.voucherCode,
      voucherPrice: props.sale.voucherPrice,
      discountPercentage: props.sale.discountPercentage,
      discountValue: props.sale.discountValue,
      isAccounting: props.isAccounting ? true : false,
      taxPercentage: props.sale.taxPercentage ?? 0,
      error_messages: [],
      warning_messages: [],
      success_messages: [],
      updating_sale: false,
      fields_no_updated: props.fieldsNoUpdated ? false : true,
      validated: false,
      valueCostsGeneratedReturn: 0,
      descriptionCostsGeneratedReturn: "",
      loadingValueCostsGeneratedReturn: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.editSale = this.editSale.bind(this);

    this.loadListInformation();

    this.isSaleReturn = saleIsReturn(this.state.sale);

    this.saleDiamonds = [];
  }

  loadListInformation = () => {
    this.listAccountCurrency =
      Util.getStorageParameter("REACT_APP_ACCOUNTS_CURRENCY").split(",");
    this.listAccountPaymentMethod =
      Util.getStorageParameter("REACT_APP_ACCOUNTS_PAYMENT_METHOD").split(",");
    this.listAccountInvoiceStatus =
      Util.getStorageParameter("REACT_APP_INVOICE_STATUS").split(",");
  };

  async handleChange(event) {
    const { value, name } = event.target;

    if (value !== this.state[name]) {
      await this.setState({
        fields_no_updated: false,
      });

      this.props.handleTabChange("accounts", true);
    }

    await this.setState({
      [name]: value,
    });
  }

  handleChangeCalculate = async (event) => {
    await this.handleChange(event);

    await this.calculateTotals();
  };

  handleChangeJewellery = async (event) => {
    await this.handleChange(event);

    await this.handleChangeDiscountPercentage(event);
  };

  handleChangeDiscountPercentage = async (event) => {
    await this.handleChange(event);

    const { totalSettingWogst, discountPercentage } = this.state;

    const total =
      (parseFloat(Number(totalSettingWogst)) *
        parseFloat(Number(discountPercentage))) /
      100;

    await this.setState({
      discountValue: Util.numberDecimal(total),
    });

    await this.calculateTotals();
  };

  handleChangeDiscount = async (event) => {
    await this.handleChange(event);

    const { totalSettingWogst, discountValue } = this.state;

    const total =
      (parseFloat(Number(discountValue)) * 100) /
      parseFloat(Number(totalSettingWogst));

    await this.setState({
      discountPercentage: Util.numberDecimal(total),
    });

    await this.calculateTotals();
  };

  handleChangeCheckbox(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.checked;
    if (event.target.checked !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("accounts", true);
    }
    this.setState(state);
  }

  changeSelectMultiple = (selected, name) => {
    if (selected === this.state[name]) {
      return;
    }

    this.setState({
      [name]: selected,
      fields_no_updated: false,
    });

    this.props.handleTabChange("accounts", true);
  };

  handleChangeSelect(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.value;
    if (field_name === "promotionsDiscount") {
      if (event.target.value === "Yes") {
        state[field_name] = true;
      } else if (event.target.value === "No") {
        state[field_name] = false;
      } else {
        state[field_name] = null;
      }
    }
    if (event.target.value !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("accounts", true);
    }
    this.setState(state);
  }

  renderTooltip = () => (
    <Tooltip id="button-tooltip">
      {this.state.descriptionCostsGeneratedReturn.replaceAll(",", ", ")}
    </Tooltip>
  );

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    let promotionsDiscount = "";
    if (this.state.promotionsDiscount === true) {
      promotionsDiscount = "Yes";
    } else if (this.state.promotionsDiscount === false) {
      promotionsDiscount = "No";
    }

    const existSaleDiamonds = ExistSaleDiamonds(this.state.sale);

    this.saleDiamonds = [];

    if (existSaleDiamonds) {
      const response = GetSaleDiamonds(this.state.sale);

      this.saleDiamonds = response.saleDiamondModels;
    }

    return (
      <div id="SaleAccountsEdit_component">
        {this.state.updating_sale === true ? <LoadingLayer /> : ""}
        {
          <FlashMessages
            error_messages={this.state.error_messages}
            warning_messages={this.state.warning_messages}
            success_messages={this.state.success_messages}
          />
        }
        <Form
          noValidate
          validated={this.state.validated}
          onSubmit={this.validateFieldsAndSave}
        >
          <Card>
            <Card.Body>
              <Card.Title>General Information</Card.Title>
              <Row>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>Billing Information</FormLabel>
                    <Form.Control
                      required={false}
                      type="text"
                      name="billingInformation"
                      value={this.state.billingInformation ?? ""}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>ID and Payment Verification</FormLabel>
                    <Form.Control
                      type="text"
                      name="paymentIdVerification"
                      value={this.state.paymentIdVerification ?? ""}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>Order Currency</FormLabel>
                    <Form.Select
                      onChange={(e) => this.handleChangeSelect(e)}
                      value={this.state.orderCurrency ?? ""}
                      name="orderCurrency"
                    >
                      <option value=""></option>
                      {this.listAccountCurrency.map((listCurrency) => {
                        return (
                          <option key={listCurrency} value={listCurrency}>
                            {listCurrency}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>Customer pays by deposit</FormLabel>
                    <select
                      name="paysByDeposit"
                      className="select-box w-100 ml-0"
                      value={this.state.paysByDeposit ?? ""}
                      onChange={(e) => this.handleChangeSelect(e)}
                    >
                      <option value=""></option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </Form.Group>
                </Col>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>Payment Method</FormLabel>

                    <FormSelectMultiple
                      options={this.listAccountPaymentMethod}
                      onChange={this.changeSelectMultiple}
                      nameField="paymentMethod"
                      data={this.state.paymentMethod ?? ""}
                      labelKey={"name"}
                    />
                  </Form.Group>
                </Col>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>Invoice Status</FormLabel>
                    <Form.Select
                      onChange={(e) => this.handleChangeSelect(e)}
                      value={this.state.invoice ?? ""}
                      name="invoice"
                    >
                      <option value=""></option>
                      {this.listAccountInvoiceStatus.map(
                        (listInvoiceStatus) => {
                          return (
                            <option
                              key={listInvoiceStatus}
                              value={listInvoiceStatus}
                            >
                              {listInvoiceStatus}
                            </option>
                          );
                        }
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>Promotions / Discount</FormLabel>
                    <select
                      name="promotionsDiscount"
                      className="select-box w-100 ml-0"
                      value={promotionsDiscount}
                      onChange={(e) => this.handleChangeSelect(e)}
                    >
                      <option value=""></option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </Form.Group>
                </Col>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>
                      Please select promotion offered to the customer
                    </FormLabel>
                    <Form.Control
                      type="text"
                      name="promotionsOfferedToCustomer"
                      value={this.state.promotionsOfferedToCustomer ?? ""}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>Voucher Code</FormLabel>
                    <Form.Control
                      type="text"
                      name="voucherCode"
                      value={this.state.voucherCode ?? ""}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>Voucher Price $$$</FormLabel>
                    <Form.Control
                      type="number"
                      name="voucherPrice"
                      value={this.state.voucherPrice ?? ""}
                      onChange={this.handleChangeCalculate}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <SaleDiamondCost
            diamonds={this.saleDiamonds}
            sale={this.state.sale}
            onResponse={this.calculateTotalPrices.bind(this)}
          />

          <Card className="mt-2">
            <Card.Body>
              <Card.Title>Prices Jewellery</Card.Title>
              <Row>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>
                      Total Jewellery{" "}
                      <Form.Text muted>
                        (Total price Jewellery&nbsp;
                        <b>
                          {Util.numberDecimal(
                            this.state.totalSettingWogst -
                              this.state.discountValue
                          )}
                        </b>
                        )
                      </Form.Text>
                    </FormLabel>
                    <Form.Control
                      type="number"
                      name="totalSettingWogst"
                      value={this.state.totalSettingWogst ?? ""}
                      onChange={this.handleChangeJewellery}
                    />
                  </Form.Group>
                </Col>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>Discount Jewellery %</FormLabel>
                    <Form.Control
                      type="number"
                      name="discountPercentage"
                      value={this.state.discountPercentage ?? ""}
                      onChange={this.handleChangeDiscountPercentage}
                    />
                  </Form.Group>
                </Col>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>Discount Price</FormLabel>
                    <Form.Control
                      type="number"
                      name="discountValue"
                      value={this.state.discountValue ?? ""}
                      onChange={this.handleChangeDiscount}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="mt-2">
            <Card.Body>
              <Card.Title>Prices</Card.Title>
              <Row>
                <Col sm="6" lg="6">
                  <Form.Group className="form-group">
                    <FormLabel>Tax percentage</FormLabel>
                    <Form.Control
                      type="number"
                      name="taxPercentage"
                      value={this.state.taxPercentage ?? ""}
                      onChange={this.handleChange}
                      required
                      min={0}
                      max={100}
                    />
                    <Form.Control.Feedback type="invalid">
                      Tax percentage is required and value is 0 to 100
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>Total Diamond(s)</FormLabel>
                    <Form.Control
                      type="number"
                      name="totalDiamondWogst"
                      disabled={existSaleDiamonds}
                      value={this.state.totalDiamondWogst ?? ""}
                      onChange={this.handleChangeCalculate}
                    />
                  </Form.Group>
                </Col>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>Total price Jewellery</FormLabel>
                    <Form.Control
                      type="number"
                      name="totalSettingWogst"
                      disabled={true}
                      value={
                        Util.numberDecimal(
                          this.state.totalSettingWogst -
                            this.state.discountValue
                        ) ?? ""
                      }
                    />
                  </Form.Group>
                </Col>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>Express Manufacturing</FormLabel>
                    <Form.Control
                      type="number"
                      name="expressManufacturingWogst"
                      value={this.state.expressManufacturingWogst ?? ""}
                      onChange={this.handleChangeCalculate}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>Engraving</FormLabel>
                    <Form.Control
                      type="number"
                      name="engravingWogst"
                      value={this.state.engravingWogst ?? ""}
                      onChange={this.handleChangeCalculate}
                    />
                  </Form.Group>
                </Col>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>CAD payment</FormLabel>
                    <Form.Control
                      type="number"
                      name="cadPayment"
                      value={this.state.cadPayment ?? ""}
                      onChange={this.handleChangeCalculate}
                    />
                  </Form.Group>
                </Col>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>Already paid by customer</FormLabel>
                    <Form.Control
                      type="number"
                      name="amountAlreadyPaid"
                      value={this.state.amountAlreadyPaid ?? ""}
                      onChange={this.handleChangeCalculate}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {this.isSaleReturn && (
                  <Col sm="12" lg="4">
                    <Form.Group className="form-group">
                      <FormLabel>
                        Costs generated by return{" "}
                        {this.state.loadingValueCostsGeneratedReturn && (
                          <LoadingSpinnerLayer />
                        )}
                        {this.state.descriptionCostsGeneratedReturn && (
                          <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 300 }}
                            overlay={this.renderTooltip()}
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </OverlayTrigger>
                        )}
                      </FormLabel>
                      <Form.Control
                        name="valueCostsGeneratedReturn"
                        value={this.state.valueCostsGeneratedReturn ?? 0}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                )}
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>Total Invoice</FormLabel>
                    <Form.Control
                      type="text"
                      name="totalIncGst"
                      value={this.state.totalIncGst ?? ""}
                      disabled={true}
                    />
                  </Form.Group>
                </Col>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>Balance</FormLabel>
                    <Form.Control
                      type="text"
                      name="balance"
                      value={this.state.balance ?? ""}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>Xero Code</FormLabel>
                    <Form.Control
                      type="text"
                      name="xeroCode"
                      value={this.state.xeroCode ?? ""}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col sm="12" lg="4">
                  <Form.Group className="form-group">
                    <FormLabel>
                      Invoice Value {Util.getStorageParameter("REACT_APP_CURRENCY")}
                    </FormLabel>
                    <Form.Control
                      type="text"
                      name="invoiceValueLocalCurrency"
                      value={this.state.invoiceValueLocalCurrency ?? ""}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Button
            className="mt-2"
            type="submit"
            disabled={this.state.updating_sale || this.state.fields_no_updated}
          >
            Save Accounts Information
          </Button>
        </Form>
      </div>
    );
  }

  validateFieldsAndSave = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    const check = form.checkValidity();

    if (!check) {
      this.setState({
        validated: true,
      });

      return;
    }

    this.editSale(event);
  };

  calculateTotalPrices = async (diamonds = [], setValue = true) => {
    this.saleDiamonds = diamonds;
    const result = CalculateValueDiamondTotal(this.saleDiamonds);

    const { totalDiamondWogst } = this.state.sale;

    let resultTotal = result > 0 ? result : totalDiamondWogst;

    if (setValue && result === 0) {
      resultTotal = result;
    }

    if (resultTotal !== totalDiamondWogst) {
      this.setState({
        fields_no_updated: false,
      });
    }

    await this.setState({
      totalDiamondWogst: Util.numberDecimal(resultTotal),
    });

    if (setValue) {
      await this.setState({
        fields_no_updated: false,
      });
    }

    await this.calculateTotals();

    if (setValue) {
      this.props.handleTabChange("accounts", true);
    }
  };

  calculateTotals = async () => {
    const {
      totalDiamondWogst,
      totalSettingWogst,
      cadPayment,
      expressManufacturingWogst,
      engravingWogst,
      voucherPrice,
      discountValue,
      amountAlreadyPaid,
      valueCostsGeneratedReturn,
    } = this.state;

    const addValue = [
      totalDiamondWogst,
      totalSettingWogst,
      cadPayment,
      expressManufacturingWogst,
      engravingWogst,
      valueCostsGeneratedReturn,
    ].reduce((a, b) => {
      let valueA = a ? a.toString().replace(",", "") : 0;
      let valueB = b ? b.toString().replace(",", "") : 0;

      return parseFloat(Number(valueA)) + parseFloat(Number(valueB));
    });

    const removeValue = [voucherPrice, discountValue, amountAlreadyPaid].reduce(
      (a, b) => {
        let valueA = a ? a.toString().replace(",", "") : 0;
        let valueB = b ? b.toString().replace(",", "") : 0;

        return parseFloat(Number(valueA)) + parseFloat(Number(valueB));
      }
    );

    const total = parseFloat(addValue) - parseFloat(removeValue);

    await this.setState({
      totalIncGst: Util.numberDecimal(total),
    });
  };

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  static getDerivedStateFromProps(props, state) {
    const amountAlreadyPaid = props?.sale?.amountAlreadyPaid;

    if (amountAlreadyPaid && state.amountAlreadyPaid === null) {
      return {
        amountAlreadyPaid: amountAlreadyPaid,
        sale: props.sale,
      };
    }

    return {
      sale: props.sale,
    };
  }

  findInformationWorkflowReturn = async () => {
    this.setState({ loadingValueCostsGeneratedReturn: true });

    const workflowReturn = await getInformationWorkflowReturn(this.state.sale);

    const valueCostsGeneratedReturn =
      workflowReturn.stepTwoQuestions.valueCostsGeneratedReturn;

    const descriptionCostsGeneratedReturn =
      workflowReturn.stepTwoQuestions.descriptionCostsGeneratedReturn;

    this.setState({
      loadingValueCostsGeneratedReturn: false,
      valueCostsGeneratedReturn: valueCostsGeneratedReturn ?? 0,
      descriptionCostsGeneratedReturn: descriptionCostsGeneratedReturn ?? "",
    });
  };

  async componentDidMount() {
    let saleDiamondData = [];

    if (ExistSaleDiamonds(this.state.sale)) {
      const response = GetSaleDiamonds(this.state.sale);

      const { saleDiamondModels } = response;

      saleDiamondData = saleDiamondModels;
    }

    if (this.isSaleReturn) {
      await this.findInformationWorkflowReturn(this.state.sale);
    }

    this.calculateTotalPrices(saleDiamondData, false);
    this.calculateTotals();
  }

  componentDidUpdate(nextProps, nextState) {
    if (
      !nextState.amountAlreadyPaid &&
      nextState.amountAlreadyPaid !== this.state.amountAlreadyPaid
    ) {
      this.calculateTotals();

      this.setState({
        fields_no_updated: false,
      });
    }
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************

  axioRequest = null;
  editSale(event) {
    event.preventDefault();
    this.setState({
      updating_sale: true,
    });
    const user = Auth.getAuthenticatedUser();
    const sale = this.props.sale;
    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else if (sale === null || sale === undefined) {
      this.setState({
        error_messages: [
          "Sale identification error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_SALES_EDIT;
      url = url.replace("{id}", sale.id);
      const params = new URLSearchParams();
      params.append("billingInformation", this.state.billingInformation);
      params.append("paymentIdVerification", this.state.paymentIdVerification);
      params.append("orderCurrency", this.state.orderCurrency);
      params.append("pricesIncludeGst", this.state.pricesIncludeGst);
      params.append("totalDiamondWogst", this.state.totalDiamondWogst);
      params.append("totalSettingWogst", this.state.totalSettingWogst);
      params.append("cadPayment", this.state.cadPayment);
      params.append(
        "expressManufacturingWogst",
        this.state.expressManufacturingWogst
      );
      params.append("engravingWogst", this.state.engravingWogst);
      params.append("totalIncGst", this.state.totalIncGst);
      params.append("balance", this.state.balance);
      if (this.state.paysByDeposit === "") {
        params.append("paysByDeposit", null);
      } else {
        params.append("paysByDeposit", this.state.paysByDeposit);
      }
      params.append("paymentMethod", this.state.paymentMethod);
      params.append("invoice", this.state.invoice);
      params.append("xeroCode", this.state.xeroCode);
      params.append(
        "invoiceValueLocalCurrency",
        this.state.invoiceValueLocalCurrency
      );
      params.append("promotionsDiscount", this.state.promotionsDiscount);
      params.append(
        "promotionsOfferedToCustomer",
        this.state.promotionsOfferedToCustomer
      );
      params.append("voucherCode", this.state.voucherCode);
      params.append("voucherPrice", this.state.voucherPrice);
      params.append("discountPercentage", this.state.discountPercentage);
      params.append("discountValue", this.state.discountValue);
      params.append("amountAlreadyPaid", this.state.amountAlreadyPaid ?? null);
      params.append("taxPercentage", this.state.taxPercentage ?? 0);

      const saleDiamonds = this.saleDiamonds.map((diamond) => {
        const { id, soldPrice, discountPercentage, discountPrice } = diamond;

        return {
          id: id,
          soldPrice: parseFloat(soldPrice),
          discountPercentage: parseFloat(discountPercentage),
          discountPrice: parseFloat(discountPrice),
        };
      });

      params.append(
        "saleDiamonds",
        saleDiamonds.length ? JSON.stringify(saleDiamonds) : null
      );

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      axios
        .put(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              updating_sale: false,
              success_messages: ["Sale updated"],
              fields_no_updated: true,
            });

            this.props.handleTabChange("accounts", false);
            this.props.saleUpdated(data.result);
          } else {
            this.setState({
              updating_sale: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else {
            this.setState({
              updating_sale: true,
            });
          }

          return null;
        });
    }
  }
}

export default SaleAccountsEdit;
