import React, { useEffect, useState } from "react";
import CustomerComment from "./customer-comment";
import Util from "../../../../Util";

const CustomerCommentsList = ({ comments }) => {
  const [selectedTopic, setSelectedTopic] = useState("all");
  const [filteredComments, setFilteredComments] = useState();

  const listShowTopics = Util.getStorageParameter("REACT_APP_TOPIC_CUSTOMER").split(",");

  useEffect(() => {
    if (selectedTopic === "all") {
      return setFilteredComments(comments);
    } else if (comments) {
      setFilteredComments(comments.filter((c) => c.topic === selectedTopic));
    }
  }, [comments, selectedTopic]);

  const handleTopicFilter = (e) => {
    setSelectedTopic(e.target.value);
    if (e.target.value === "all") return setFilteredComments(comments);
    setFilteredComments(comments.filter((c) => c.topic === e.target.value));
  };

  return (
    <div id="SaleCommentsList_component">
      <form>
        <div className="form-group mb-2 text-right">
          <label className="box-label">Show topic(s):</label>
          <select
            className="select-box"
            value={selectedTopic}
            onChange={handleTopicFilter}
          >
            <option value="all">All</option>
            {listShowTopics.map((listShowTopic) => {
              return (
                <option key={listShowTopic} value={listShowTopic}>
                  {listShowTopic}
                </option>
              );
            })}
          </select>
        </div>
        {!filteredComments ? (
          <div className="form-group">
            <span className="mt-2 no-comments-section">No comments yet</span>
          </div>
        ) : (
          filteredComments.map((comment) => (
            <CustomerComment key={comment.id} comment={comment} />
          ))
        )}
      </form>
    </div>
  );
};

export default CustomerCommentsList;
