import React, { Component } from 'react';
import { Modal, Button, Form, FormLabel, Alert } from 'react-bootstrap';
import axios from 'axios';
import * as globalConst from '../../../global/const';
import Auth from '../../../Auth';
import { sprintf } from "sprintf-js";
import Util from '../../../Util';

const CancelToken = axios.CancelToken;
let cancel;

class CreateFavouriteFilter extends Component {

    constructor(props) {
        super();

        this.state = {
            removeMultiple: props.removeMultiple,
            showModal: props.showModal,
            fields: props.fields,
            createFavouriteFilter: props.createFavouriteFilter,
            name: '',
            errorMessage: ''
        }

    }

    render() {
        return (
            <Modal
                show={this.state.showModal}
                centered
            >
                <Modal.Header>
                    Create favourite filter
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.createFavouriteFilter ?
                            (
                                <div>
                                    <Form noValidate validated={this.state.validated}>
                                        {
                                            this.state.errorMessage.length > 0 ?
                                                (
                                                    <Alert variant="danger" onClose={(e) => this.hiddenErrorMessageAlert()} dismissible>
                                                        <p>
                                                            {this.state.errorMessage}
                                                        </p>
                                                    </Alert>
                                                ) :
                                                (null)
                                        }

                                        <Form.Group controlId="validationName">
                                            <FormLabel>Name favourite filter</FormLabel>
                                            <Form.Control
                                                autoComplete="off"
                                                type="text"
                                                name="name"
                                                value={this.state.name === null ? '' : this.state.name}
                                                onChange={(e) => this.handleChange(e)}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                The name can’t be empty
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form>


                                    <Button
                                        onClick={() => this.saveCreateFavourite(true)}
                                        className="m-2"
                                        variant="outline-primary">
                                        Create
                                    </Button>
                                    <Button
                                        onClick={() => this.cancelCreateFavourite(false)}
                                        variant="outline-secondary"
                                        className='m-2'>
                                        Cancel
                                    </Button>
                                </div>
                            ) :
                            (
                                <div className='center-element'>
                                    To create the favourite filter it is necessary to
                                    have one or more fields selected
                                </div>
                            )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.cancelCreateFavourite(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    hiddenErrorMessageAlert = () => {
        this.setState({
            errorMessage: ''
        })
    }

    handleChange = (event) => {
        const field_name = event.target.name

        let state = {}

        state[field_name] = event.target.value

        this.setState(state)
    }

    cancelCreateFavourite = (value) => {
        this.props.responseCancelCreateFavourite(value);
    }

    saveCreateFavourite = () => {
        if (this.state.name.length <= 0) {
            this.setState({
                validated: true
            })

            return
        }

        this.props.parentIsLoading(true)
        if (cancel !== undefined) {
            cancel();
        }

        const url = sprintf(
            '%s%s',
            Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN"),
            globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_FAVOURITE_FILTER
        )

        let data = {
            name: this.state.name,
            favouriteFilter: this.state.fields
        }

        const config = {
            cancelToken: new CancelToken((c) => {
                cancel = c;
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Auth.getToken()}`
            }
        }

        axios.post(
            url,
            data,
            config
        ).then(response => {
            this.props.parentUpdateList()

            this.props.parentIsLoading(false)

            this.setState({
                showModal: false
            })
        }).catch((error) => {
            if (axios.isCancel(error)) {
                return;
            }

            if (error.response.status === 400) {
                this.setState({
                    errorMessage: sprintf(
                        '%s',
                        'An error occurred creating the favourite filter, please contact an administrator'
                    )
                })
            }

            if (error.response.status === 401) {
                this.setState({
                    errorMessage: sprintf(
                        '%s',
                        'Not authorised.'
                    )
                })
            }

            if (error.response.status === 428) {
                this.setState({
                    errorMessage: error.response.data.error
                })
            }

            this.props.parentIsLoading(false)
        })
    }
}

export default CreateFavouriteFilter;