import { Form, FormLabel, Button, Card, Row, Col } from "react-bootstrap";
import React, { Component } from "react";
import LoadingLayer from "../../LoadingLayer";
import FlashMessages from "../../FlashMessages";
import Auth from "../../../Auth";
import axios from "axios";
import DateField from "../formComponents/DateField";
import Util from "../../../Util";
import * as globalConst from "../../../global/const";

// SCSS
import "../../../css/sales/sale_edit.scss";
import {
  STATUS_COLLECTED_SHIPPED,
  STATUS_PENDING_APPROVAL,
  STATUS_RETURNED,
} from "../../../store/constants";
import FormInputCheckbox from "../../Form/FormInputCheckbox";
import { Typeahead } from "react-bootstrap-typeahead";
import { FindJewelleyByContactName } from "../../actions/JewelleryFind";
import FormInputTextarea from "../../Form/FormInputTextarea";
import { findMostRecentProvider } from "./Acctions/WorflowSale";
import FieldMultipleSpecialRequest from "./Form/FieldMultipleSpecialRequest";

class SaleProductionEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sale: props.sale,
      workflowSales: props.workflowSales ?? [],
      manufacturingLocation: props.sale.manufacturingLocation,
      customerNeedsOn: props.sale.customerNeedsOn,
      urgentDateRequestedByCustomer: props.sale.urgentDateRequestedByCustomer,
      dateWeNeedBy: props.sale.dateWeNeedBy,
      manufacturingOperationsValidated:
        props.sale.manufacturingOperationsValidated ?? "",
      manufacturingProviderId: props.sale.manufacturingProviderId ?? "",
      manufacturingProviderName: "",
      manufacturingProviderChange: false,
      manufacturingSpecialRequests:
        props.sale.manufacturingSpecialRequests ?? "",
      manufacturingGeneralComments:
        props.sale.manufacturingGeneralComments ?? "",
      providers: [],
      provider: [],
      status: props.sale.status,
      notes: props.sale.notes,
      error_messages: [],
      warning_messages: [],
      success_messages: [],
      updating_sale: false,
      fields_no_updated: true,
      showProvider: true,
      mostRecentProvider: {
        id: null,
        name: null,
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.editSale = this.editSale.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);

    this.setLists();
  }

  getJewellers = async () => {
    this.setState({
      showProvider: false,
    });

    const jewellers = await FindJewelleyByContactName(
      this.state.manufacturingProviderId
    );

    this.setState({
      providers: jewellers?.items ?? [],
      provider: jewellers?.defaultProvider ?? [],
      showProvider: true,
    });
  };

  setLists = () => {
    this.listOrderStatus = [];

    if (Util.getStorageParameter("REACT_APP_SALES_ORDER_STATUS_LIST")) {
      this.listOrderStatus =
        Util.getStorageParameter("REACT_APP_SALES_ORDER_STATUS_LIST").split(",");
    }
  };

  handleChange(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.value;
    if (event.target.value !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("production", true);
    }
    this.setState(state);
  }

  handleChangeCheckbox(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.checked;
    if (event.target.checked !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("production", true);
    }
    this.setState(state);
  }

  handleChangeSelect(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.value;
    if (event.target.value !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("production", true);
    }
    this.setState(state);
  }

  handleChangeDateField = (date, field_name) => {
    const state = {};
    if (date !== null && date !== "") {
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      state[field_name] = year + "-" + month + "-" + day;
    } else {
      state[field_name] = null;
    }
    if (date !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("production", true);
    }
    this.setState(state);
  };

  disabledStatusIsReturn = (status) => {
    if (
      status !== STATUS_PENDING_APPROVAL &&
      !Auth.canAccessRolesAndLeader("rolesEditStatusReturn", true)
    ) {
      return false;
    }

    if (Auth.canAccessRolesAndLeader("rolesEditStatusReturn", true)) {
      return false;
    }

    return true;
  };

  handleChangeInput = async (value, name) => {
    this.setState({
      [name]: value,
      fields_no_updated: false,
    });

    this.props.handleTabChange("production", true);
  };

  handleChangeProvider = (value) => {
    if (value.length <= 0) {
      this.setState({
        manufacturingProviderId: null,
        manufacturingProviderName: null,
        manufacturingProviderChange: true,
        fields_no_updated: false,
      });

      this.props.handleTabChange("production", true);

      return;
    }

    this.setState({
      manufacturingProviderId: value[0].id,
      manufacturingProviderName: value[0]?.contactName,
      manufacturingProviderChange: true,
      fields_no_updated: false,
    });

    this.props.handleTabChange("production", true);
  };

  onChangeSelect = (selected, name) => {
    if (selected === this.state[name]) {
      return;
    }

    this.setState({
      [name]: selected,
      fields_no_updated: false,
    });

    this.props.handleTabChange("production", true);
  };

  disabledFieldsNoIsOperations = () => {
    const isRoleValid = Auth.canAccessRolesAndLeader(
      "rolesEditqualityControlCheck",
      true
    );

    if (isRoleValid) {
      return false;
    }

    return true;
  };

  showManufacturingProviderIsDiferent = () => {
    if (
      this.state.manufacturingProviderChange &&
      this.state.manufacturingProviderId &&
      this.state.mostRecentProvider?.id !== this.state.manufacturingProviderId
    ) {
      return (
        <span className="text-danger">
          The selected provider "{this.state.manufacturingProviderName}" does
          not match the provider "{this.state.mostRecentProvider?.name}"
          selected in the manufacturing activity
        </span>
      );
    }

    return "";
  };

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    const sale = this.state.sale;

    let customerNeedsOnDate = "";
    if (sale.customerNeedsOn !== null) {
      const customerNeedsOn_string = sale.customerNeedsOn.substring(0, 10);
      customerNeedsOnDate = new Date(customerNeedsOn_string);
      if (customerNeedsOnDate === "Invalid Date") {
        customerNeedsOnDate = "";
      }
    }

    let dateWeNeedByDate = "";
    if (sale.dateWeNeedBy !== null) {
      const dateWeNeedBy_string = sale.dateWeNeedBy.substring(0, 10);
      dateWeNeedByDate = new Date(dateWeNeedBy_string);
      if (dateWeNeedByDate === "Invalid Date") {
        dateWeNeedByDate = "";
      }
    }

    let current_status = "";
    if (
      typeof this.state.status === "string" ||
      this.state.status instanceof String
    ) {
      current_status = this.state.status;
    }

    return (
      <div id="SaleProductionEdit_component">
        {this.state.updating_sale === true ? <LoadingLayer /> : ""}
        {
          <FlashMessages
            error_messages={this.state.error_messages}
            warning_messages={this.state.warning_messages}
            success_messages={this.state.success_messages}
          />
        }
        <Form>
          <Form.Group className="form-group">
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="form-group">
                      <FormLabel>Order Status</FormLabel>
                      <Form.Select
                        onChange={this.handleChangeSelect}
                        value={current_status}
                        name="status"
                        disabled={this.disabledStatusIsReturn(current_status)}
                      >
                        <option value=""></option>
                        {this.listOrderStatus.map((orderStatus) => {
                          return (
                            <option
                              disabled={
                                orderStatus === STATUS_COLLECTED_SHIPPED ||
                                orderStatus === STATUS_RETURNED ||
                                orderStatus === STATUS_PENDING_APPROVAL
                              }
                              key={orderStatus}
                              value={orderStatus}
                            >
                              {orderStatus}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    {Util.wordInArray(sale.saleType, [
                      "engagement ring",
                      "jewellery",
                      "wedding",
                    ]) === true && (
                      <Form.Group className="form-group">
                        <FormLabel>Manufacturing Location</FormLabel>
                        <Form.Control
                          type="text"
                          name="manufacturingLocation"
                          value={this.state.manufacturingLocation ?? ""}
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <Form.Group className="form-group">
                      <FormLabel>Date customer needs by</FormLabel>
                      <DateField
                        handleChangeDateField={this.handleChangeDateField}
                        field_name="customerNeedsOn"
                        currentDate={customerNeedsOnDate}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group">
                      <FormLabel>Date we need by</FormLabel>
                      <DateField
                        handleChangeDateField={this.handleChangeDateField}
                        field_name="dateWeNeedBy"
                        currentDate={dateWeNeedByDate}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    {this.validShowUrgenDate() && (
                      <div className="col-12">
                        <Form.Group className="form-group">
                          <Form.Check
                            type="checkbox"
                            name="urgentDateRequestedByCustomer"
                            label="Urgent Date - requested by Customer"
                            checked={
                              this.state.urgentDateRequestedByCustomer === true
                                ? true
                                : false
                            }
                            onChange={this.handleChangeCheckbox}
                          />
                        </Form.Group>
                      </div>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card className="mt-2">
              <Card.Header>
                Instructions for the manufacturing provider
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col lg={6}>
                    <Form.Group>
                      {this.state.showProvider && (
                        <>
                          <FormLabel className="d-block">
                            Manufacturing Provider{" "}
                            {this.showManufacturingProviderIsDiferent()}
                          </FormLabel>
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="nameComplete"
                            options={this.state.providers}
                            placeholder="Choose a provider..."
                            defaultSelected={this.state.provider}
                            onChange={(e) => this.handleChangeProvider(e)}
                            disabled={this.disabledFieldsNoIsOperations()}
                          />
                        </>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <FieldMultipleSpecialRequest
                      onChange={this.handleChangeSpecialRequestsResponse}
                      nameField="manufacturingSpecialRequests"
                      labelKey={"value"}
                      disabled={this.disabledFieldsNoIsOperations()}
                      label="Special requests"
                      value={this.state.manufacturingSpecialRequests ?? ""}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <FormInputTextarea
                      label="General comments"
                      nameInput="manufacturingGeneralComments"
                      value={this.state.manufacturingGeneralComments ?? ""}
                      required={true}
                      onResponse={this.handleChangeInput}
                      disabled={this.disabledFieldsNoIsOperations()}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <FormInputCheckbox
                      typeView="checkbox"
                      type="checkbox"
                      label="Order confirmed by operations to start manufacturing"
                      nameInput="manufacturingOperationsValidated"
                      checked={
                        this.state.manufacturingOperationsValidated ?? false
                      }
                      required={true}
                      onResponse={this.handleChangeInput}
                      disabled={this.disabledFieldsNoIsOperations()}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Form.Group>

          <Button
            type="submit"
            onClick={this.editSale}
            disabled={this.state.updating_sale || this.state.fields_no_updated}
          >
            Save Production Information
          </Button>
        </Form>
      </div>
    );
  }

  handleChangeSpecialRequestsResponse = (name, value) => {
    if (value === this.state[name]) {
      return "";
    }

    this.setState({
      [name]: value,
      fields_no_updated: false,
    });

    this.props.handleTabChange("production", true);
  };

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  static getDerivedStateFromProps(props, state) {
    return {
      sale: props.sale,
    };
  }

  componentDidMount() {
    this.getJewellers();

    this.findWorkflowSales(this.state.workflowSales);
  }

  findWorkflowSales = async (workflowSales) => {
    if (!workflowSales) {
      return null;
    }

    const mostRecentProvider = await findMostRecentProvider(workflowSales);

    this.setState({
      mostRecentProvider: mostRecentProvider,
    });
  };

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************

  axioRequest = null;
  editSale(event) {
    event.preventDefault();
    this.setState({
      updating_sale: true,
    });
    const user = Auth.getAuthenticatedUser();
    const sale = this.props.sale;
    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else if (sale === null || sale === undefined) {
      this.setState({
        error_messages: [
          "Sale identification error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_SALES_EDIT;
      url = url.replace("{id}", sale.id);
      const params = new URLSearchParams();

      let updateManufacturingProviderId = false;
      if (sale.manufacturingProviderId !== this.state.manufacturingProviderId) {
        updateManufacturingProviderId = true;
      }

      params.append("manufacturingLocation", this.state.manufacturingLocation);
      params.append("customerNeedsOn", this.state.customerNeedsOn);
      params.append(
        "urgentDateRequestedByCustomer",
        this.state.urgentDateRequestedByCustomer
      );
      params.append("dateWeNeedBy", this.state.dateWeNeedBy);
      params.append("status", this.state.status);

      params.append(
        "manufacturingOperationsValidated",
        this.state.manufacturingOperationsValidated
          ? this.state.manufacturingOperationsValidated
          : "null"
      );
      params.append(
        "manufacturingProviderId",
        this.state.manufacturingProviderId
          ? this.state.manufacturingProviderId
          : "null"
      );
      params.append(
        "manufacturingSpecialRequests",
        this.state.manufacturingSpecialRequests
          ? this.state.manufacturingSpecialRequests
          : ""
      );
      params.append(
        "manufacturingGeneralComments",
        this.state.manufacturingGeneralComments
          ? this.state.manufacturingGeneralComments
          : ""
      );

      if (this.state.status === "Collected / Sent") {
        params.append("sheetType", "FINALISED");
      }

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      axios
        .put(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              updating_sale: false,
              success_messages: ["Sale updated"],
              fields_no_updated: true,
              manufacturingProviderChange: false,
            });
            this.props.handleTabChange("production", false);
            this.props.saleUpdated(data.result);

            if (updateManufacturingProviderId) {
              this.props.updateWorkflowSale(updateManufacturingProviderId);
            }
          } else {
            this.setState({
              updating_sale: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else {
            this.setState({
              updating_sale: true,
            });
          }

          return null;
        });
    }
  }

  validShowUrgenDate = () => {
    return Auth.canAccessRolesAndLeader(
      "urgent_date_requested_by_customer",
      true
    );
  };
}

export default SaleProductionEdit;
