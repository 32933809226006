import {
    Button,
    Card,
    Col,
    ListGroup,
    Row,
    Table,
} from "react-bootstrap";
import { dateFormatCustom } from "../../../utils/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Link } from "react-router-dom";
import Auth from "../../../Auth";
import { AddFollowUps } from "./add-follow-ups";

export default function AppointmentFollowUpsCard({ appointment }) {
    const [showAddFollowup, setshowAddFollowup] = useState(false);
    const [selectedFollowup, setSelectedFollowup] = useState(null);
    const accessFollowUp = Auth.canAccessRolesAndLeader("rolesAccessCRMCreate");

    const handleEditFollowUp = (followUp, event) => {
        event.stopPropagation();
        setSelectedFollowup(followUp);
        setshowAddFollowup(true);
    };

    const buildAppointments = () => {
        return (
            appointment.customerFollowUps.length > 0 && (
                <tr className="SaleComment_component">
                    <td colSpan={2}>
                        <ListGroup>
                            {appointment.customerFollowUps &&
                                appointment.customerFollowUps.length > 0 &&
                                appointment.customerFollowUps.map(
                                    (followUp) => {
                                        return (
                                            <ListGroup.Item key={followUp.id}>
                                                <Row>
                                                    <Col lg="12">
                                                        {accessFollowUp && (
                                                            <Link
                                                                to="#"
                                                                className="btn btn-novita-pink-outline"
                                                                onClick={(event) =>
                                                                    handleEditFollowUp(
                                                                        followUp,
                                                                        event
                                                                    )
                                                                }
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faPencilAlt}
                                                                />
                                                            </Link>
                                                        )}
                                                        &nbsp;
                                                        {dateFormatCustom(
                                                            followUp.date,
                                                            "DD MMM, YYYY"
                                                        )}
                                                        {followUp.employee?.name
                                                            ? " - " + followUp.employee?.name
                                                            : ""}
                                                    </Col>
                                                    <Col lg="12">
                                                        <Card className="mt-2">
                                                            <Card.Header>Notes:</Card.Header>
                                                            <Card.Body>
                                                                {followUp.notes}
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        );
                                    }
                                )}
                        </ListGroup>
                    </td>
                </tr>
            )
        )
    }

    return (
        <div className="general_information customer-details-card">
            <h2
                style={{ height: "42px" }}
                className="d-flex flex-row justify-content-between align-items-center"
            >
                <p className="align-self-center mt-3">Follow ups</p>
                {accessFollowUp && (
                    <Button
                        variant=""
                        className="btn btn-default"
                        onClick={() => setshowAddFollowup(true)}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                )}
            </h2>
            <div className="customer-details-container p-2">
                <Table striped bordered>
                    <tbody>
                        {buildAppointments()}
                    </tbody>
                </Table>
            </div>
            {showAddFollowup && (
                <AddFollowUps
                    show={showAddFollowup}
                    onHide={() => {
                        setshowAddFollowup(false);
                        setSelectedFollowup(null);
                    }}
                    appointment={appointment}
                    editingFollowup={selectedFollowup}
                />
            )}
        </div>
    );
}