import React, { Component } from "react";
import Auth from "../../../Auth";
import * as globalConst from "../../../global/const";
import { Col, Card } from "react-bootstrap";
import iconPlay from "../../../image/icon_play.svg";
import iconPdf from "../../../image/icon_pdf.svg";

import "../../../css/sales/sale_detail.scss";

import path from "path-browserify";

class SaleMultimediaRow extends Component {
  constructor(props) {
    super();
    this.state = {
      media: props.media,
      selected_media: props.selected_media,
    };
  }

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    var access_to_sales_multimedia_delete = Auth.isAuthorised(
      "post_sales_multimedia_delete"
    );
    var access_to_sales_multimedia_edit = Auth.isAuthorised(
      "post_sales_multimedia_edit"
    );
    var URL = globalConst.AWS_S3_ACCESS_POINT_URL_POST_SALE_SERVICE;

    const media = this.state.media;
    var mediaIcon = null;
    var isVideo = false;
    var isPDF = false;
    if (path.extname(media.filename) === ".mp4") {
      mediaIcon = iconPlay;
      isVideo = true;
    } else if (path.extname(media.filename) === ".pdf") {
      mediaIcon = iconPdf;
      isPDF = true;
    } else {
      mediaIcon = URL + media.filename;
      isVideo = false;
    }
    return (
      <Col>
        <Card className="mb-2">
          {isPDF ? (
            <Card.Link href={URL + media.filename} target="_blank">
              <Card.Img
                className="mt-2 sales_media_img"
                variant="top"
                src={mediaIcon}
                loading="lazy"
              />
            </Card.Link>
          ) : (
            <Card.Link
              href={"#"}
              onClick={() => {
                this.props.mediaPreview(URL + media.filename, isVideo);
              }}
            >
              <Card.Img
                className="mt-2 sales_media_img"
                variant="top"
                src={mediaIcon}
                loading="lazy"
              />
            </Card.Link>
          )}
          <Card.Body>
            <Card.Text className="sales_media_desc">
              {media.description}
            </Card.Text>
            {access_to_sales_multimedia_delete === true && (
              <Card.Link
                href={"#"}
                onClick={() => {
                  this.props.removeFromList(media);
                }}
              >
                Delete
              </Card.Link>
            )}
            {access_to_sales_multimedia_edit === true && (
              <Card.Link
                href={"#"}
                onClick={() => {
                  this.props.editFromList(media);
                }}
              >
                Edit
              </Card.Link>
            )}
          </Card.Body>
        </Card>
      </Col>
    );
  }
  //*****************************************
  //RENDER
  //*****************************************

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  static getDerivedStateFromProps(props, state) {
    return {
      media: props.media,
      selected_media: props.selected_media,
    };
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}
export default SaleMultimediaRow;
