import axios from "axios";
import Auth from "../../../../Auth";
import * as globalConst from "../../../../global/const";
import Util from "../../../../Util";

export const SendUpdateWorkflowReturn = async (
  activity,
  workflowReturnModel
) => {
  if (!workflowReturnModel) {
    return;
  }

  let url =
    Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
    globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_EDIT;

  url = url.replace("{id}", activity.id);

  const params = new URLSearchParams();
  params.append("workflowReturnId", workflowReturnModel.id ?? "");
  params.append("reason", workflowReturnModel.stepOneQuestions.reason ?? "");
  params.append(
    "stepOneQuestions",
    JSON.stringify(workflowReturnModel.stepOneQuestions) ?? ""
  );
  params.append(
    "stepTwoQuestions",
    JSON.stringify(workflowReturnModel.stepTwoQuestions) ?? ""
  );
  params.append(
    "stepThreeQuestions",
    JSON.stringify(workflowReturnModel.stepThreeQuestions) ?? ""
  );

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${Auth.getToken()}`,
    },
  };

  return await axios
    .put(url, params, config)
    .then((response) => {
      const data = response.data;

      return data;
    })
    .catch((err) => {
      let response = [];
      if ("status" in err.response && err.response.status === 401) {
        return (response["error"] = ["Not authorised."]);
      }

      if (err.request) {
        return (response["error"] = [
          "There was a problem processing the information. Please reload this page and try again.",
        ]);
      }

      return (response["error"] = [
        "There was a problem processing the information. Please reload this page and try again.",
      ]);
    });
};
