import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Auth from "../../../Auth";
import * as globalConst from "../../../global/const";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

// SCSS
import "../../../css/sales/sale_information.scss";
import WorkflowActivityTitle from "./WorkflowActivityTitle";
import { getWorkflowSale } from "../edit/Workflow/WorkflowSaleConsult";
import WorkflowSaleTitles from "./Actions/WorkflowSaleTitles";

class SaleWorkflowActivities extends Component {
  last_rendered_sale = null;

  constructor(props) {
    super();
    this.state = {
      sale: props.sale,
      btnsDisable: props.btnsDisable ?? [],
      workflowSales: [],
      loadWorkflowSales: false,
      openWorkflowEditionComponent: props.openWorkflowEditionComponent,
      openWorkflowModal: props.openWorkflowModal,
      clearWorkflowSales: true,
    };

    this.clearWorkflowSales = true;
    this.changeQualityControl = false;
    this.editSale = this.editSale.bind(this);
    this.resetWorkflowList = this.resetWorkflowList.bind(this);

    this.loadGetWorkflowSales = true;
  }

  componentWillUnmount = () => {
    this.loadGetWorkflowSales = false;
  };

  resetWorkflowList() {
    this.getWorkflowSales();
  }

  reloadWorkflowList = () => {
    if (!this.changeQualityControl) {
      this.clearWorkflowSales = true;
      this.changeQualityControl = true;
      this.resetWorkflowList();
      this.clearWorkflowSales = false;
    }

    setTimeout(() => {
      this.changeQualityControl = false;
    }, 5000);
  };

  changeBtnDisabled = (value) => {
    this.props.disabledActionsBotons(value);
  };

  editSale() {
    this.state.openEditionComponent(this.state.sale);
  }

  render() {
    const disabledBTNCreate = this.state.btnsDisable.includes(
      globalConst.BTN_CREATE_WORKFLOW
    );

    const disabledBTNEdit = this.state.btnsDisable.includes(
      globalConst.BTN_EDIT_WORKFLOW_ACTIVITY
    );

    return (
      <div className="workflow-activities">
        <div className="information">
          <div className="details-top-heading">
            <div className="order-no-section">
              <h1>Workflow Activities</h1>
            </div>
          </div>
          {this.state.workflowSales.length > 0
            ? this.state.workflowSales.map((workflowSale, index) => {
                let workflowActivityModels =
                  workflowSale.workflowActivityModels;

                let saleWorkflowManufacturing = false;
                let saleWorkflowResizing = false;
                let saleWorkflowSetting = false;
                let saleWorkflowEngraving = false;
                let saleWorkflowDeliveringToCustomer = false;
                let saleWorkflowCAD = false;
                let saleWorkflowFixing = false;
                let saleWorkflowPhotos = false;
                let saleWorkflowPolishing = false;
                let saleWorkflowValuation = false;
                let saleWorkflowReturn = false;

                const qualityControlChecks = workflowActivityModels.map(
                  (activity) => {
                    return {
                      [`${activity.type}-${activity.id}`]:
                        activity.qualityControlCheckModel,
                    };
                  }
                );

                workflowActivityModels.map((activity) => {
                  switch (activity.type) {
                    case "Manufacturing":
                      saleWorkflowManufacturing = activity;
                      break;
                    case "Resizing":
                      saleWorkflowResizing = activity;
                      break;
                    case "Setting":
                      saleWorkflowSetting = activity;
                      break;
                    case "Engraving":
                      saleWorkflowEngraving = activity;
                      break;
                    case "Delivering to customer":
                      saleWorkflowDeliveringToCustomer = activity;
                      break;
                    case "CAD":
                      saleWorkflowCAD = activity;
                      break;
                    case "Fixing":
                      saleWorkflowFixing = activity;
                      break;
                    case "Photos":
                      saleWorkflowPhotos = activity;
                      break;
                    case "Polish":
                      saleWorkflowPolishing = activity;
                      break;
                    case "Return":
                      saleWorkflowReturn = activity;
                      break;
                    case "Appraisal":
                    case "Valuation":
                      activity.type = "Appraisal";
                      saleWorkflowValuation = activity;
                      break;
                    default:
                      break;
                  }
                  return null;
                });

                const provider = this.getNameJeweller(workflowSale);

                return (
                  <div className="workflow-activity-card" key={index}>
                    <div className="row">
                      {workflowSale && !this.state.loadWorkflowSales && (
                        <WorkflowSaleTitles
                          provider={provider ?? ""}
                          saleWorkflowReturn={saleWorkflowReturn ? true : false}
                          workflowSale={workflowSale}
                        />
                      )}

                      <div className="col-3">
                        {Auth.canEditField("rolesInteractiveWorkflow") ? (
                          <Link
                            to=""
                            className={disabledBTNEdit ? "disabled-btn" : ""}
                            onClick={(e) => {
                              e.preventDefault();
                              if (disabledBTNEdit) {
                                return;
                              }

                              this.editWorkflow(workflowSale);
                            }}
                          >
                            Edit
                          </Link>
                        ) : null}
                      </div>
                    </div>

                    {saleWorkflowManufacturing &&
                      !this.state.loadWorkflowSales && (
                        <WorkflowActivityTitle
                          activity={saleWorkflowManufacturing}
                          qualityControlChecks={qualityControlChecks ?? []}
                          btnsDisable={this.state.btnsDisable ?? []}
                          onReloadSale={this.reloadWorkflowList}
                          changeBtnDisabled={this.changeBtnDisabled}
                        />
                      )}
                    {saleWorkflowResizing && !this.state.loadWorkflowSales && (
                      <WorkflowActivityTitle
                        activity={saleWorkflowResizing}
                        qualityControlChecks={qualityControlChecks ?? []}
                        btnsDisable={this.state.btnsDisable ?? []}
                        onReloadSale={this.reloadWorkflowList}
                        changeBtnDisabled={this.changeBtnDisabled}
                      />
                    )}
                    {saleWorkflowSetting && !this.state.loadWorkflowSales && (
                      <WorkflowActivityTitle
                        activity={saleWorkflowSetting}
                        qualityControlChecks={qualityControlChecks ?? []}
                        btnsDisable={this.state.btnsDisable ?? []}
                        onReloadSale={this.reloadWorkflowList}
                        changeBtnDisabled={this.changeBtnDisabled}
                      />
                    )}
                    {saleWorkflowEngraving && !this.state.loadWorkflowSales && (
                      <WorkflowActivityTitle
                        activity={saleWorkflowEngraving}
                        qualityControlChecks={qualityControlChecks ?? []}
                        btnsDisable={this.state.btnsDisable ?? []}
                        onReloadSale={this.reloadWorkflowList}
                        changeBtnDisabled={this.changeBtnDisabled}
                      />
                    )}
                    {saleWorkflowDeliveringToCustomer &&
                      !this.state.loadWorkflowSales && (
                        <WorkflowActivityTitle
                          activity={saleWorkflowDeliveringToCustomer}
                          qualityControlChecks={qualityControlChecks ?? []}
                          btnsDisable={this.state.btnsDisable ?? []}
                          onReloadSale={this.reloadWorkflowList}
                          changeBtnDisabled={this.changeBtnDisabled}
                        />
                      )}
                    {saleWorkflowCAD && !this.state.loadWorkflowSales && (
                      <WorkflowActivityTitle
                        activity={saleWorkflowCAD}
                        qualityControlChecks={qualityControlChecks ?? []}
                        btnsDisable={this.state.btnsDisable ?? []}
                        onReloadSale={this.reloadWorkflowList}
                        changeBtnDisabled={this.changeBtnDisabled}
                      />
                    )}
                    {saleWorkflowFixing && !this.state.loadWorkflowSales && (
                      <WorkflowActivityTitle
                        activity={saleWorkflowFixing}
                        qualityControlChecks={qualityControlChecks ?? []}
                        btnsDisable={this.state.btnsDisable ?? []}
                        onReloadSale={this.reloadWorkflowList}
                        changeBtnDisabled={this.changeBtnDisabled}
                      />
                    )}
                    {saleWorkflowPhotos && !this.state.loadWorkflowSales && (
                      <WorkflowActivityTitle
                        activity={saleWorkflowPhotos}
                        qualityControlChecks={qualityControlChecks ?? []}
                        btnsDisable={this.state.btnsDisable ?? []}
                        onReloadSale={this.reloadWorkflowList}
                        changeBtnDisabled={this.changeBtnDisabled}
                      />
                    )}
                    {saleWorkflowPolishing && !this.state.loadWorkflowSales && (
                      <WorkflowActivityTitle
                        activity={saleWorkflowPolishing}
                        qualityControlChecks={qualityControlChecks ?? []}
                        btnsDisable={this.state.btnsDisable ?? []}
                        onReloadSale={this.reloadWorkflowList}
                        changeBtnDisabled={this.changeBtnDisabled}
                      />
                    )}
                    {saleWorkflowValuation && !this.state.loadWorkflowSales && (
                      <WorkflowActivityTitle
                        activity={saleWorkflowValuation}
                        qualityControlChecks={qualityControlChecks ?? []}
                        btnsDisable={this.state.btnsDisable ?? []}
                        onReloadSale={this.reloadWorkflowList}
                        changeBtnDisabled={this.changeBtnDisabled}
                      />
                    )}
                    {saleWorkflowReturn && !this.state.loadWorkflowSales && (
                      <WorkflowActivityTitle
                        activity={saleWorkflowReturn}
                        qualityControlChecks={qualityControlChecks ?? []}
                        btnsDisable={this.state.btnsDisable ?? []}
                        onReloadSale={this.reloadWorkflowList}
                        changeBtnDisabled={this.changeBtnDisabled}
                      />
                    )}
                  </div>
                );
              })
            : ""}

          <div>
            {Auth.canEditField("rolesInteractiveWorkflow") ? (
              <Link
                to=""
                className={disabledBTNCreate ? "disabled-btn" : ""}
                onClick={(e) => {
                  e.preventDefault();
                  if (disabledBTNCreate) {
                    return;
                  }

                  this.state.openWorkflowModal(e);
                }}
              >
                <FontAwesomeIcon icon={faPlus} /> add workflow activity
              </Link>
            ) : null}
          </div>

          {this.state.loadWorkflowSales ? (
            <div className="center-element m-5">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  editWorkflow(workflowSale) {
    this.state.openWorkflowEditionComponent(workflowSale);
  }

  async getWorkflowSales() {
    if (this.clearWorkflowSales) {
      this.setState({
        workflowSales: [],
      });
    }

    this.setState({
      loadWorkflowSales: true,
    });

    const workflowSales = await getWorkflowSale(this.state.sale);

    this.setState({
      workflowSales: workflowSales ?? [],
      loadWorkflowSales: false,
    });
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  componentDidUpdate = (nextProps) => {
    this.clearWorkflowSales = true;

    if (nextProps.sale.id === this.state.sale.id) {
      this.clearWorkflowSales = false;
    }

    if (this.state.workflowSales !== null) {
      this.last_rendered_workflow_sales = this.state.workflowSales;
    }
  };
  
  getNameJeweller = (workflowSale) => {
    const jeweller = workflowSale.jewellerModel;

    if (jeweller) {
      return jeweller.name + " (" + jeweller.contactName + ")";
    }

    return "";
  };

  static getDerivedStateFromProps(props, state) {
    //called right before rendering the first time or before shouldComponentUpdate in case it was already rendered
    //return values for the state

    return {
      sale: props.sale,
      btnsDisable: props.btnsDisable,
    };
  }
  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default SaleWorkflowActivities;
