import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, FormLabel, Row } from "react-bootstrap";
import Util from "../../../Util";
import FindSaleExchange from "./FindSaleExchange";

const AddNewExchange = ({ onResponse }) => {
  const [showAddSales, setShowAddSales] = useState(false);
  const [saleOriginal, setSaleOriginal] = useState(null);

  const [amountAlreadyPaid, setAmountAlreadyPaid] = useState(0);

  const handleSelectSale = (sale) => {
    setSaleOriginal(sale);

    const { id, totalSettingWogst, totalDiamondWogst, discountValue, balance } =
      sale;

    const valueSum = [totalSettingWogst, totalDiamondWogst].reduce((a, b) => {
      let valueA = a ? a.toString().replace(",", "") : 0;
      let valueB = b ? b.toString().replace(",", "") : 0;

      return parseFloat(Number(valueA)) + parseFloat(Number(valueB));
    });

    const valueRest = [discountValue, balance].reduce((a, b) => {
      let valueA = a ? a.toString().replace(",", "") : 0;
      let valueB = b ? b.toString().replace(",", "") : 0;

      return parseFloat(Number(valueA)) + parseFloat(Number(valueB));
    });

    const amountAlreadyPaid = Util.numberDecimal(valueSum - valueRest);

    onResponse(amountAlreadyPaid, "amountAlreadyPaid");
    onResponse(id, "originalSaleId");

    setAmountAlreadyPaid(amountAlreadyPaid);
  };

  return (
    <>
      <Row>
        <Col sm="12" lg="12">
          <Form.Text muted>
            {!saleOriginal && "Is this order linked to a return?"}
            <b>
              {saleOriginal
                ? "From a return " + saleOriginal.onlineOrderNumber
                : ""}
            </b>
          </Form.Text>
          {showAddSales && (
            <FindSaleExchange
              closeComponent={() => {
                setShowAddSales(false);
              }}
              setSelectedSale={handleSelectSale}
            />
          )}
        </Col>
        <Col sm="12" lg="12">
          <Button
            className="save-information-btn btn btn-primary"
            onClick={() => setShowAddSales(true)}
          >
            Find Original order
          </Button>
        </Col>
      </Row>

      {saleOriginal && (
        <Row className="mt-1">
          <Col sm="12" lg="4">
            <Form.Group className="form-group">
              <FormLabel>Already paid by customer</FormLabel>
              <Form.Control
                type="number"
                name="amountAlreadyPaid"
                value={amountAlreadyPaid ?? ""}
                disabled={true}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
    </>
  );
};

AddNewExchange.propTypes = {
  onResponse: PropTypes.func.isRequired,
};

export default AddNewExchange;
