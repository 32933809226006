import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
    Card,
    Col,
    Form,
    FormLabel,
    Row
} from 'react-bootstrap'
import * as globalConst from '../../../global/const';
import DateField from '../formComponents/DateField';
import Util from '../../../Util';

const SaleCreateProductData = props => {
    const CUSTOM = 'Custom'
    const { saleData, onResponse } = props
    const [sale, setSale] = useState(saleData)

    const listProductType = Util.getStorageParameter("REACT_APP_PRODUCT_TYPE_CREATE").split(',')
    const listProductMetal = Util.getStorageParameter("REACT_APP_PRODUCT_METAL").split(',')
    const listProductTypeOrder = Util.getStorageParameter("REACT_APP_PRODUCT_TYPE_ORDER").split(',')

    const handleChange = (e) => {
        const data = sale

        const { value, name } = e.target

        data[name] = value

        setSale(prevState => ({
            ...prevState,
            [name]: value
        }))

        onResponse(value, name)
    }

    const handleChangeDateField = (date, name) => {
        let value = null

        if (date !== null && date !== '') {
            const day = ('0' + date.getDate()).slice(-2)
            const month = ('0' + (date.getMonth() + 1)).slice(-2)
            const year = date.getFullYear()
            value = year + '-' + month + '-' + day
        }

        setSale(prevState => ({
            ...prevState,
            [name]: value
        }))

        onResponse(value, name)
    }

    return (
        <Card className='mt-2'>
            <Card.Body>
                <Card.Title>
                    Product Information &nbsp;

                    <Form.Text muted>
                        (The default value for the Product's Reference is <b>{CUSTOM}</b>)
                    </Form.Text>
                </Card.Title>
                <Row>
                    <Col sm="12" lg="4">
                        <Form.Group className="form-group">
                            <FormLabel>Date of Sale</FormLabel>
                            <DateField
                                handleChangeDateField={handleChangeDateField}
                                field_name='saleDate'
                                currentDate={sale.saleDate}
                                showTimeSelect={true}
                                dateFormat='dd/MM/yyyy HH:mm'
                                isClearable={false}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm="12" lg="4">
                        <Form.Group className="form-group">
                            <FormLabel>Setting Status</FormLabel>
                            <Form.Control
                                type="text"
                                name="settingStatus"
                                value={sale.settingStatus ?? ''}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm="12" lg="4">
                        <Form.Group className="form-group">
                            <FormLabel>Type of Product</FormLabel>
                            <Form.Select
                                onChange={(e) => handleChange(e)}
                                value={sale.saleType ?? ''}
                                name="saleType"
                                required={true}
                            >
                                <option value=""></option>
                                {
                                    listProductType.map(listType => {
                                        return (
                                            <option
                                                key={listType}
                                                value={listType}>
                                                {listType}
                                            </option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                {
                    sale.saleType === globalConst.REACT_APP_SALE_TYPE_WEDDING_BANDS ?
                        <Card className='mt-2'>
                            <Card.Body>
                                <Card.Title>
                                    {globalConst.REACT_APP_SALE_TYPE_WEDDING_BANDS} data
                                </Card.Title>
                                <Row>
                                    <Col sm="12" lg="4">
                                        <Form.Group className="form-group">
                                            <FormLabel>Product’s reference</FormLabel>
                                            <Form.Control
                                                type="text"
                                                name="ringPreference"
                                                placeholder={CUSTOM}
                                                value={sale.ringPreference ?? ''}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm="12" lg="4">
                                        <Form.Group className="form-group">
                                            <FormLabel>Engraving</FormLabel>
                                            <Form.Control
                                                type="text"
                                                name="engraving"
                                                value={sale.engraving ?? ''}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm="12" lg="4">
                                        <Form.Group className="form-group">
                                            <FormLabel>Band Metal</FormLabel>
                                            <Form.Select
                                                onChange={(e) => handleChange(e)}
                                                value={sale.metal ?? ''}
                                                name="metal"
                                            >
                                                <option value=""></option>
                                                {
                                                    listProductMetal.map(listMetal => {
                                                        return (
                                                            <option
                                                                key={listMetal}
                                                                value={listMetal}>
                                                                {listMetal}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" lg="4">
                                        <Form.Group className="form-group">
                                            <FormLabel>Ring Size</FormLabel>
                                            <Form.Control
                                                type="text"
                                                name="ringSize"
                                                value={sale.ringSize ?? ''}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm="12" lg="4">
                                        <Form.Group className="form-group">
                                            <FormLabel>Type of Order</FormLabel>
                                            <Form.Select
                                                onChange={(e) => handleChange(e)}
                                                value={sale.orderType ?? ''}
                                                name="orderType"
                                            >
                                                <option value=""></option>
                                                {
                                                    listProductTypeOrder.map(listTypeOrder => {
                                                        return (
                                                            <option
                                                                key={listTypeOrder}
                                                                value={listTypeOrder}>
                                                                {listTypeOrder}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        :
                        null
                }
                {
                    sale.saleType === globalConst.REACT_APP_SALE_TYPE_JEWELLERY ?
                        <Card className='mt-2'>
                            <Card.Body>
                                <Card.Title>
                                    {globalConst.REACT_APP_SALE_TYPE_JEWELLERY} data
                                </Card.Title>
                                <Row>
                                    <Col sm="12" lg="4">
                                        <Form.Group className="form-group">
                                            <FormLabel>Band Metal</FormLabel>
                                            <Form.Control
                                                type="text"
                                                name="metal"
                                                value={sale.metal ?? ''}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm="12" lg="4">
                                        <Form.Group className="form-group">
                                            <FormLabel>Type of Order</FormLabel>
                                            <Form.Control
                                                type="text"
                                                name="orderType"
                                                value={sale.orderType ?? ''}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        : null
                }

            </Card.Body>
        </Card>
    )
}

SaleCreateProductData.propTypes = {
    saleData: PropTypes.any.isRequired,
    onResponse: PropTypes.func.isRequired
}

export default SaleCreateProductData