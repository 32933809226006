import axios from "axios";
import { sprintf } from "sprintf-js";
import * as globalConst from "../../global/const";
import Auth from "../../Auth";
import Util from "../../Util";

const source = axios.CancelToken.source();

export const FindEmployeSales = async () => {
  try {
    const url = sprintf(
      "%s%s",
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN"),
      globalConst.REACT_APP_NOVITAERP_API_EMPLOYEE_LIST
    );

    const parameters = {
      roles: "ROLE_SALES",
    };

    return await axios
      .get(url, {
        params: parameters,
        cancelToken: source.token,
        headers: { Authorization: `Bearer ${Auth.getToken()}` },
      })
      .then((response) => {
        const { data } = response;
        const { result } = data;
        const { rows } = result;

        if (Array.isArray(rows) === true && rows.length > 0) {
          return rows;
        }

        return [];
      })
      .catch((error) => {
        return [];
      });
  } catch (error) {
    return [];
  }
};
