import Util from "../../../Util";
import { dateFormatCustom } from "../../../utils/dateUtils";

export default function AppointmentCard({ appointment }) {

  const extractEmployeeNames = (appointment) => {
    if (!appointment) {
      return "";
    }

    const { customerEmployees } = appointment;

    if (!customerEmployees || !Array.isArray(customerEmployees)) {
      return "";
    }

    const employees = [];

    customerEmployees.forEach((customerEmployee) => {
      employees.push(customerEmployee?.employee?.name ?? "");
    });

    const uniqueArray = employees.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    return uniqueArray.join(" / ");
  };

  const statusAsSlug = (status) => {
    let status_as_slug = "";
    if (typeof status === "string" || status instanceof String) {
      status = status.toLowerCase();
      if (status === "cancelled") {
        status_as_slug = "cancelled";
      } else if (status === "customer decided to buy") {
        status_as_slug = "decided_to_buy";
      } else if (status === "customer decided not to buy") {
        status_as_slug = "decided_not_to_buy";
      } else if (status === "following up") {
        status_as_slug = "following_up";
      }

      status_as_slug = "status_coloured_box " + status_as_slug;
    }
    return status_as_slug;
  };

  const messageTimezone = Util.getStorageParameter("REACT_APP_MESSAGE_TIMEZONE");
  const buildMiddleRow = (name, value) => {
    return (
      <div key={name} className="row border-bottom">
        <div className="p-1 col-sm table_title border-right ">
          <span className="table_title">{name}</span>
        </div>
        <div className="p-1 col-sm">
          {value}
        </div>
      </div>
    );
  };

  return (
    <div className="general_information customer-details-card">
      <h2>Appointment</h2>
      <div className="customer-details-container p-2">
        <div className="container">
          <div key={'Date'} className="row border-bottom">
            <div className="p-1 col-sm table_title border-right ">
              <span className="table_title">Date</span>
            </div>
            <div className="p-1 col-sm">
              {dateFormatCustom(
                appointment.appointmentDate,
                "DD MMM, YYYY HH:mm"
              )}
              &nbsp;<span className="text-danger">({messageTimezone})</span>
            </div>
          </div>
          {buildMiddleRow("Sale’s representative(s)", extractEmployeeNames(appointment))}
          <div key={'Status'} className="row border-bottom">
            <div className="p-1 col-sm table_title border-right ">
              <span className="table_title">Status</span>
            </div>
            <div className="p-1 col-sm">
              <div className={statusAsSlug(appointment?.status)} />{appointment?.status}
            </div>
          </div>
          {buildMiddleRow("Showroom city", appointment?.showroomCity)}
          {buildMiddleRow("Looking for", appointment?.lookingFor)}
          {buildMiddleRow("Who came", appointment?.whoCame)}
          {buildMiddleRow("Notes", appointment?.notes)}
          {buildMiddleRow("Appointment Number", appointment?.appointmentNumber)}
          {buildMiddleRow("Booking request method", appointment?.bookingRequestMethod)}
          {buildMiddleRow("Type", appointment?.type)}
          {buildMiddleRow("Is Zoom", (appointment?.isZoom === true) ? 'Yes' : 'No')}
        </div>
      </div>
    </div>
  );
}
