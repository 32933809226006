/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setCreatedSort, setMaxDate, setMinDate } from "../../../store/slices";
import moment from "moment";
import PropTypes from "prop-types";
import Util from "../../../Util";

const VALUE_ALL = "ALL";

const CustomersListHeader = ({
  selectedList,
  onFilterChange,
  hasFilters,
  handleCheckAll,
  handleListChange,
  isPersonalTab,
  listId,
  onResponseList,
}) => {
  const url = window.location.href;
  const parametrosURL = new URL(url).searchParams;
  const customerRef = parametrosURL.get("customer-ref");

  const dispatch = useDispatch();
  const { filterColumns } = useSelector((state) => state.customers);
  const { minCreatedDate, maxCreatedDate, createdSort } = useSelector(
    (state) => state.customers
  );
  const listAppoinmentTypes =
    Util.getStorageParameter("REACT_APP_TYPE_CUSTOMER_APPOINMENT").split(",");

  const [order, setOrder] = useState("desc");
  const [listAppoinmentType, setListAppoinmentType] = useState("");

  const { lists } = useSelector((state) => state.customerCustomizedLists);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = useCallback(
    debounce(
      (text, columnName, returnEmptyValues) =>
        onFilterChange(text, columnName, returnEmptyValues),
      300
    ),
    [filterColumns]
  );

  const onChangeSelect = (event, columnName) => {
    const { value } = event.target;

    setListAppoinmentType(value);

    handler(event, columnName, false);
  };

  useEffect(() => {
    if (!listAppoinmentType) {
      let listAppoinmentType = listAppoinmentTypes[1];

      if (customerRef) {
        listAppoinmentType = VALUE_ALL;
      }

      setListAppoinmentType(listAppoinmentType);
    }
  }, [customerRef, listAppoinmentType, listAppoinmentTypes]);

  const handleDateSelection = (date, columnName) => {
    // add a check that max date is less than min date
    if (columnName === "min") {
      if (maxCreatedDate && moment(maxCreatedDate).isBefore(date)) {
        alert("Min date must be smaller than max date");
      } else if (date === null) {
        dispatch(setMinDate(null));
      } else {
        const minDate = moment(date, "YYYY/DD/MM").format(
          "YYYY-MM-DD 00:00:00"
        );
        dispatch(setMinDate(minDate ? minDate : null));
      }
    } else {
      if (date === null) {
        dispatch(setMaxDate(null));
        return;
      }

      const maxDate = moment(date, "YYYY/DD/MM").format("YYYY-MM-DD 23:59:59");

      dispatch(setMaxDate(maxDate ? maxDate : null));
    }
  };

  const buildFilter = (columnName) => {
    if (columnName === "created") {
      return (
        <div className="dateFilter">
          <DatePicker
            selected={minCreatedDate ? new Date(minCreatedDate) : null}
            onChange={(date) => handleDateSelection(date, "min")}
            dateFormat="dd/MM/yyyy"
            isClearable
            closeOnScroll={true}
            placeholderText="From"
          />
          <DatePicker
            selected={maxCreatedDate ? new Date(maxCreatedDate) : null}
            onChange={(date) => handleDateSelection(date, "max")}
            dateFormat="dd/MM/yyyy"
            isClearable
            closeOnScroll={true}
            placeholderText="To"
          />
        </div>
      );
    } else if (columnName === "salesrep") {
      return (
        <div>
          <input
            type="text"
            onChange={(e) => handler(e, columnName, false)}
            className="advanced_filter_model_name"
          />
          <div className="form-check custom-from-check">
            <Form.Check
              type="checkbox"
              name="filterEmptyName"
              id={`filter${columnName}`}
              className="advanced_filter_empty_model_name"
              onChange={(e) => handler(e, columnName, e.target.checked)}
            />
            <span className="empty-text">Empty</span>
          </div>
        </div>
      );
    } else if (columnName === "type") {
      return (
        <div>
          <select
            name="type"
            onChange={(e) => onChangeSelect(e, columnName)}
            className="advanced_filter_model_type"
            value={listAppoinmentType}
          >
            <option value={VALUE_ALL}>All</option>
            {listAppoinmentTypes.map((listAppoinmentType) => {
              return (
                <option key={listAppoinmentType} value={listAppoinmentType}>
                  {listAppoinmentType}
                </option>
              );
            })}
          </select>
        </div>
      );
    }

    return (
      <div>
        <input
          type="text"
          onChange={(e) => handler(e, columnName, false)}
          className="advanced_filter_model_name"
        />
        <div className="form-check custom-from-check">
          <Form.Check
            type="checkbox"
            name="filterEmptyName"
            id={`filter${columnName}`}
            className="advanced_filter_empty_model_name"
            onChange={(e) => handler(e, columnName, e.target.checked)}
          />
          <span className="empty-text">Empty</span>
        </div>
      </div>
    );
  };

  const orderListPersonal = (value) => {
    setOrder(value);

    if (onResponseList) {
      onResponseList(listId, value);
    }
  };

  return (
    <thead className="thead-dark">
      <tr key={"customers-list"} className="titles">
        <th className="addToMyList">My List</th>
        {!isPersonalTab ? (
          <th
            className="created"
            onClick={(e) => {
              dispatch(setCreatedSort(createdSort === "desc" ? "asc" : "desc"));
            }}
          >
            Created {createdSort === "desc" ? " ▼" : " ▲"}
          </th>
        ) : (
          <th
            className="created"
            onClick={(e) => {
              orderListPersonal(order === "desc" ? "asc" : "desc");
            }}
          >
            Created {order === "desc" ? " ▼" : " ▲"}
          </th>
        )}
        <th className="name">Name</th>
        <th className="email">Email</th>
        <th className="phone">Phone</th>
        <th className="city">City</th>
        <th className="saleRepName">Sales Representative</th>
      </tr>
      {hasFilters && (
        <tr className="filters">
          <th className="sheetType_selection">
            <select
              className="select-box"
              value={selectedList}
              onChange={(e) => {
                handleListChange(e);
              }}
            >
              <option value=""></option>
              {lists.map((row) => (
                <option key={row.id} value={row.name}>
                  {row.name}
                </option>
              ))}
            </select>
            <div className="">
              <a
                href="#"
                className="formatLink check-all-link"
                onClick={(e) => {
                  handleCheckAll(e);
                }}
              >
                Check all
              </a>
            </div>
          </th>
          <th className="created">{buildFilter("created")}</th>
          <th className="name">{buildFilter("name")}</th>
          <th className="email">{buildFilter("email")}</th>
          <th className="phone">{buildFilter("phone")}</th>
          <th className="city">{buildFilter("city")}</th>
          <th className="salesrep">{buildFilter("salesrep")}</th>
        </tr>
      )}
    </thead>
  );
};

CustomersListHeader.propTypes = {
  isPersonalTab: PropTypes.bool,
  listId: PropTypes.number,
  onResponseList: PropTypes.func,
};

CustomersListHeader.defaultProps = {
  isPersonalTab: false,
  listId: 0,
};

export default CustomersListHeader;
