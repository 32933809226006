import React from "react";
import { Row } from "react-bootstrap";
import HeaderLoadCommissions from "./Header/HeaderLoadCommissions";
import BodySaleCommissions from "./Body/BodySaleCommissions";

const LoadCommissions = () => {
  return (
    <Row>
      <HeaderLoadCommissions title="Load commissions" returnUrl="/dashboard" />

      <BodySaleCommissions />
    </Row>
  );
};

LoadCommissions.propTypes = {};

export default LoadCommissions;
