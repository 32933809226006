import axios from "axios";
import { sprintf } from "sprintf-js";
import * as globalConst from "../../../../global/const";
import Auth from "../../../../Auth";
import Util from "../../../../Util";

const source = axios.CancelToken.source();

export const updateSaleCommission = async (saleCommission) => {
  try {
    const url = sprintf(
      "%s%s",
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN"),
      globalConst.REACT_APP_NOVITAERP_API_SALE_COMMISSIONS
    );

    const params = {
      id: saleCommission.id,
      saleId: saleCommission.saleId,
      employeeId: saleCommission.employeeId,
      paidDate: saleCommission.paidDate,
      commissionPercentage: parseFloat(saleCommission.commissionPercentage),
      commissionValue: saleCommission.commissionValue,
      employeeCommissionRateId: saleCommission.employeeCommissionRateId,
      commissionBase: saleCommission.commissionBase,
    };

    const config = {
      cancelToken: source.token,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    };

    return await axios
      .put(url, params, config)
      .then((response) => {
        const { data } = response;
        const { value } = data;

        if (value) {
          return value;
        }

        return null;
      })
      .catch((err) => {
        const { response } = err;
        const { data } = response;

        return data;
      });
  } catch (error) {
    return null;
  }
};
