import React, { Component } from 'react';
import Auth from '../../Auth';
import axios from 'axios';
import { Link } from 'react-router-dom';
import * as globalConst from '../../global/const';
import Util from '../../Util';

class Tasks extends Component {
    constructor(props) {
        super(props)
        this.state = {
            task: props.task,
            taskDoneConfirmation: false
        }
    }

    render() {
        const task = this.state.task
        let created_date = null
        let due_date = null
        let assigned_by = "--Unassigned--"
        let assigned_to = "--Unassigned--"
        let finished_by = "--Unassigned--"
        let status = ""
        let taskOnlineOrderNumber = ""
        let taskCustomerName = ""

        let dueDateClass = ""

        if (task !== null) {

            if (task.assignedByModel !== null) {
                assigned_by = task.assignedByModel.name
            }

            if (task.employeeModel !== null) {
                assigned_to = task.employeeModel.name
            }

            if (task.finishedByModel !== null) {
                finished_by = task.finishedByModel.name
            }

            if (task.employeeTaskStatusModel !== null) {
                status = task.employeeTaskStatusModel.name
            }

            if (task.dueDate !== null && task.dueDate !== '') {
                due_date = new Date(task.dueDate);

                if (due_date < new Date()) {
                    dueDateClass = "due-date-warning"
                }
            }
        }

        if (task.created !== null && task.created !== '') {
            created_date = new Date(task.created);
        }

        if (task.salesEmployeeTaskModels.length > 0) {
            if ('onlineOrderNumber' in task.salesEmployeeTaskModels[0].salesModel) {
                taskOnlineOrderNumber = task.salesEmployeeTaskModels[0].salesModel.onlineOrderNumber
            }
            if ('name' in task.salesEmployeeTaskModels[0].salesModel) {
                taskCustomerName = task.salesEmployeeTaskModels[0].salesModel.name
            }
        }

        let employeeTaskStatusId
        if (task) {
            employeeTaskStatusId = task.employeeTaskStatusId
        }

        return (
            <div className='col-12'>
                <div className="card task-card">
                    <div className="card-body p-0">
                        <h2 className="card-title">
                            {
                                (task.salesEmployeeTaskModels.length > 0) ? (
                                    <Link to={`/sales?order-ref=${taskOnlineOrderNumber}`}>
                                        Sale #{taskOnlineOrderNumber}
                                    </Link>
                                ) : (
                                    ''
                                )
                            }
                        </h2>
                        <span className="card-text mb-1">
                            {
                                (task.salesEmployeeTaskModels.length > 0) ? (
                                    <p className='customer-name'>
                                        <Link to={`/sales?order-ref=${taskOnlineOrderNumber}`}>
                                            Customer: <b>{taskCustomerName}</b>
                                        </Link>
                                    </p>
                                ) : (
                                    ''
                                )
                            }

                            Description:<b>  {!!task ? task.description : "--No description--"} </b>
                            <br />
                            Assigned by:<b>  {assigned_by}</b>
                            <br />
                            Assigned to:<b>  {assigned_to}</b>
                            <br />
                            Finished by:<b>  {finished_by}</b>
                            <br />
                            Status:<b> {status}</b>
                            <br />
                            Created:<b>  {created_date.toLocaleString('default', { day: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") })} {created_date.toLocaleString('default', { month: 'short', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") })}, {created_date.toLocaleString('default', { year: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") })} - {created_date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") })}</b>
                            <br />
                            <span className={dueDateClass}>Due date:<b>  {due_date.toLocaleString('default', { day: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") })} {due_date.toLocaleString('default', { month: 'short', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") })}, {due_date.toLocaleString('default', { year: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") })} - {due_date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") })}</b></span>
                        </span>
                        <div className='button-section'>
                            {
                                (employeeTaskStatusId === 2) ? (

                                    (!this.state.taskDoneConfirmation) ? (
                                        <button onClick={() => {
                                            this.setTaskDoneConfirmation(true)
                                        }
                                        } className='btn btn-default btn-done'>Done</button>
                                    ) : (
                                        <div>
                                            <p className='confirm-text'><b>Are you sure?</b></p>
                                            <button className="btn confirmationLinks yes border-right" onClick={() => {
                                                this.finishTask(task.id)
                                            }
                                            }>Yes</button>
                                            <button className="btn confirmationLinks no" onClick={() => {
                                                this.setTaskDoneConfirmation(false)
                                            }
                                            }>No</button>
                                        </div>
                                    )

                                ) : (
                                    <p className="card-text p-2 mt-2 alert-primary">Task completed</p>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    setTaskDoneConfirmation(status) {
        this.setState({
            taskDoneConfirmation: status
        })
    }

    finishTask(employeeTaskId) {
        let url = Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") + globalConst.REACT_APP_NOVITAERP_API_EMPLOYEE_TASK_EDIT
        url = url.replace("{id}", employeeTaskId)
        const params = new URLSearchParams()
        params.append('employeeTaskStatusId', 5) //completed

        let config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Bearer ${Auth.getToken()}`
            }
        }

        axios.put(
            url,
            params,
            config
        )
            .then(
                api_response => {
                    const data = api_response.data
                    if (data !== undefined && data.code === 200) {
                        let oldState = this.state.task
                        oldState.employeeTaskStatusId = 5
                        this.setState(
                            {
                                task: oldState
                            }
                        )
                        this.setTaskDoneConfirmation(false)
                        this.props.resetFiltersTasks()
                        this.props.getEmployeeTaskList()
                    }
                    else {
                        alert('error')
                    }
                }
            )
            .catch(
                err => {
                    if (err.response) {
                        if ('status' in err.response && err.response.status === 401) {
                            alert('User not authorised. Please reload the page');
                        }
                        else {
                            alert('There was a problem processing the information. Please reload this page and try again.');
                        }
                    } else if (err.request) {
                        alert('There was a problem with your request. Please reload this page and try again.');
                    } else {
                        //error not detected. Request could have been cancelled and it's waiting for second
                        //request to finish
                    }
                    //console.log(err);
                    return null;
                }
            )
    }

    componentDidMount() {

    }
}

export default Tasks;