import { useSelector, useDispatch } from "react-redux";
import {
    clear,
    createAppointmentsCustomizedList,
    deleteAppointmentsCustomizedList,
    fetchAppointmentCustomizedLists,
    fetchAppointments,
    resetAppointments,
    searchAppointments,
} from "../../../store/slices";
import { useCallback, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import AppointmentsToolbar from "./toolbar";
import axios from "axios";
import {
    APPOINTMENTS_LIST_TYPE_DEFAULT,
    APPOINTMENTS_LIST_TYPE_SEARCH,
} from "../../../store/constants";
import DefaultList from "./default-list";
import CustomizedList from "./customized-list";
import Auth from "../../../Auth";
import AccessNotFound from "../../Alerts/AccessNotFound";

const INPUT_GENERAL_SEARCH = "generalSearch";
const REMOVE_EMAIL = "email";

export default function AppointmentsList({ onSelected, selected }) {
    const access = Auth.canAccessRolesAndLeader("rolesAccessCRMList");

    const {
        appointments,
        error,
        totalAppointments,
        appointmentListType,
        searchResults,
        filterColumns,
        filterResultsCount,
    } = useSelector((state) => state.appointments);
    const url = window.location.href;
    const parametrosURL = new URL(url).searchParams;
    const appointmentRef = parametrosURL.get("appointment-ref");

    const [notSearchAppointment, setNotSearchAppointment] = useState(false);

    const { lists } = useSelector(
        (state) => state.appointmentsCustomizedLists
    );

    const { minCreatedDate, maxCreatedDate, filterText, createdSort } =
        useSelector((state) => state.appointments);

    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState("appointments");
    const [cancelToken, setCancelToken] = useState(null);
    const [advanceFilterActive, setAdvanceFilterActive] = useState(false);
    const [listProps, setListProps] = useState({
        maxResults: 100,
        page: 1,
    });

    const nextPage = () => {
        setListProps({ ...listProps, page: listProps.page + 1 });
    };

    const onNextPageFilter = () => {
        listProps.page++;

        dispatch(
            searchAppointments({
                columns: filterColumns,
                filterText: filterText,
                cancelToken,
                minDate: minCreatedDate,
                maxDate: maxCreatedDate,
                createdSort,
                page: listProps.page,
                maxResults: 100,
                advanceFilter: advanceFilterActive,
            })
        );
    };

    useEffect(() => {
        dispatch(fetchAppointmentCustomizedLists());
    }, [dispatch]);

    const callFetchAppointments = useCallback(async () => {
        if (cancelToken) {
            cancelToken.cancel();
            setCancelToken(axios.CancelToken.source());
        }

        if (!appointmentRef) {
            dispatch(fetchAppointments({ ...listProps, cancelToken, createdSort }));
        }
    }, [cancelToken, createdSort, appointmentRef, dispatch, listProps]);

    useEffect(() => {
        if (listProps.page > 1) {
            callFetchAppointments(listProps);
        }
    }, [callFetchAppointments, dispatch, listProps]);

    useEffect(() => {
        return () => {
            dispatch(clear());
        };
    }, [dispatch]);

    useEffect(() => {
        if (appointmentListType === APPOINTMENTS_LIST_TYPE_DEFAULT) {
          //remove all Appointments
          dispatch(resetAppointments());
          callFetchAppointments(listProps);
        } else {
          if (cancelToken) {
            cancelToken.cancel();
            setCancelToken(axios.CancelToken.source());
          }
    
          const newFilterColumns = getNewFiltersOther();
          listProps.page = 1;
    
          dispatch(
            searchAppointments({
              columns: newFilterColumns,
              filterText: filterText,
              cancelToken,
              minDate: minCreatedDate,
              maxDate: maxCreatedDate,
              createdSort,
              page: 1,
              maxResults: 100,
              advanceFilter: advanceFilterActive,
            })
          );
        }
      }, [createdSort]);

    useEffect(() => {
        if (minCreatedDate === 0 && maxCreatedDate === 0) {
          // we are not initialized yet
          return;
        }
    
        if (minCreatedDate || maxCreatedDate) {
          listProps.page = 1;
          const newFilterColumns = getNewFiltersOther();
    
          dispatch(
            searchAppointments({
              columns: newFilterColumns,
              filterText: filterText,
              cancelToken,
              minDate: minCreatedDate,
              maxDate: maxCreatedDate,
              createdSort,
              advanceFilter: advanceFilterActive,
            })
          );
        } else {
          searchAgain(filterColumns, filterText);
        }
      }, [minCreatedDate, maxCreatedDate]);

    useEffect(() => {
        if (appointmentRef && !notSearchAppointment) {
          const newFilterColumns = [
            {
              columnName: "type",
              filterText: "ALL",
              returnEmptyValues: "false",
            },
            {
              columnName: "email",
              filterText: appointmentRef,
              returnEmptyValues: "false",
            },
          ];
    
          dispatch(
            searchAppointments({
              columns: newFilterColumns,
              filterText: filterText,
              cancelToken,
              minDate: minCreatedDate,
              maxDate: maxCreatedDate,
              createdSort,
              advanceFilter: advanceFilterActive,
            })
          );
        }
      }, [
        advanceFilterActive,
        cancelToken,
        createdSort,
        appointmentRef,
        dispatch,
        filterText,
        maxCreatedDate,
        minCreatedDate,
        notSearchAppointment,
      ]);

    const extractEmployeeNames = (appointment) => {
        if (!appointment) {
            return "";
        }

        const { customerEmployees } = appointment;

        if (!customerEmployees || !Array.isArray(customerEmployees)) {
            return "";
        }

        const employees = [];

        customerEmployees.forEach((customerEmployee) => {
            employees.push(customerEmployee?.employee?.name ?? "");
        });

        const uniqueArray = employees.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });

        return uniqueArray.join(" / ");
    };

    const filterChanged = (e, columnName, returnEmptyValues) => {
        listProps.page = 1;

        let targetText = e.target.value;
        if (targetText === "") {
            // check if there are any other values in the filterColumns
            if (filterColumns.length <= 0) {
                //result normal results
                searchAgain([], filterText);
            } else {
                const newFilterColumns = filterColumns.filter(
                    (e) => e.columnName !== columnName
                );
                if (newFilterColumns.length <= 0) {
                    //back to normal
                    searchAgain([], filterText);
                } else {
                    if (cancelToken) {
                        cancelToken.cancel();
                        setCancelToken(axios.CancelToken.source());
                    }

                    dispatch(
                        searchAppointments({
                            columns: newFilterColumns,
                            filterText: filterText,
                            cancelToken,
                            minDate: minCreatedDate,
                            maxDate: maxCreatedDate,
                            createdSort,
                            advanceFilter: advanceFilterActive,
                        })
                    );
                }
            }
        } else {
            if (cancelToken) {
                cancelToken.cancel();
                setCancelToken(axios.CancelToken.source());
            }
            if (returnEmptyValues) {
                const newFilterColumns = getNewFilters(columnName, "", "true");

                dispatch(
                    searchAppointments({
                        columns: newFilterColumns,
                        filterText: filterText,
                        cancelToken,
                        minDate: minCreatedDate,
                        maxDate: maxCreatedDate,
                        createdSort,
                        advanceFilter: advanceFilterActive,
                    })
                );
            } else {
                if (e.target.type === "checkbox") {
                    //find if there is already a filter for this column
                    const newFilterColumns = filterColumns.filter(
                        (e) => e.columnName !== columnName
                    );
                    if (newFilterColumns.length <= 0) {
                        //reset search
                        searchAgain(newFilterColumns, filterText);
                    } else {
                        dispatch(
                            searchAppointments({
                                columns: newFilterColumns,
                                filterText,
                                cancelToken,
                                minDate: minCreatedDate,
                                maxDate: maxCreatedDate,
                                createdSort,
                                advanceFilter: advanceFilterActive,
                            })
                        );
                    }
                } else {
                    const newFilterColumns = getNewFilters(
                        columnName,
                        targetText,
                        "false"
                    );

                    dispatch(
                        searchAppointments({
                            columns: newFilterColumns,
                            filterText,
                            cancelToken,
                            minDate: minCreatedDate,
                            maxDate: maxCreatedDate,
                            createdSort,
                            advanceFilter: advanceFilterActive,
                        })
                    );
                }
            }
        }
    };

    const getNewFilters = (columnName, targetText, returnEmptyValues) => {
        const newFilterColumns = filterColumns.filter(
            (e) => e.columnName !== columnName
        );

        newFilterColumns.push({
            columnName,
            filterText: targetText,
            returnEmptyValues,
        });

        const findFilterType = newFilterColumns.filter(
            (e) => e.columnName === "type"
        );

        if (findFilterType.length <= 0) {
            newFilterColumns.push({
                columnName: "type",
                filterText: "1 Hour",
                returnEmptyValues: "false",
            });
        }

        return newFilterColumns;
    };

    const getNewFiltersOther = () => {
        let newFilterColumns = Object.assign([], filterColumns);

        if (newFilterColumns.length <= 0) {
            newFilterColumns.push({
                columnName: "type",
                filterText: "1 Hour",
                returnEmptyValues: "false",
            });
        }

        return newFilterColumns;
    };

    const searchFilterChanged = (e) => {
        listProps.page = 1;
        let targetText = e.target.value;

        const { name } = e.target;

        let newFilterColumns = getNewFiltersOther();

        if (name === INPUT_GENERAL_SEARCH) {
            setNotSearchAppointment(true);

            if (appointmentRef) {
                newFilterColumns = newFilterColumns.filter(
                    (newFilterColumn) => newFilterColumn.columnName !== REMOVE_EMAIL
                );
            }
        }

        if (targetText === "") {
            searchAgain(filterColumns, targetText);
        } else {
            if (cancelToken) {
                cancelToken.cancel();
                setCancelToken(axios.CancelToken.source());
            }

            dispatch(
                searchAppointments({
                    columns: newFilterColumns,
                    filterText: targetText,
                    cancelToken,
                    minDate: minCreatedDate,
                    maxDate: maxCreatedDate,
                    createdSort,
                    advanceFilter: advanceFilterActive,
                })
            );
        }
    };

    const searchAgain = (newFilters, newFilterText) => {
        listProps.page = 1;
        if (!newFilters) {
            newFilters = [];
        }

        if (cancelToken) {
            cancelToken.cancel();
            setCancelToken(axios.CancelToken.source());
        }

        if (
            newFilters.length <= 0 &&
            (!newFilterText || newFilterText === "") &&
            (minCreatedDate === null || minCreatedDate === 0) &&
            (maxCreatedDate === null || maxCreatedDate === 0)
        ) {
            //reset search
            dispatch(
                searchAppointments({
                    columns: newFilters,
                    filterText: newFilterText,
                    cancelToken,
                    minDate: minCreatedDate,
                    maxDate: maxCreatedDate,
                    createdSort,
                    advanceFilter: advanceFilterActive,
                })
            );
        } else {
            dispatch(
                searchAppointments({
                    columns: newFilters,
                    filterText: newFilterText,
                    cancelToken,
                    minDate: minCreatedDate,
                    maxDate: maxCreatedDate,
                    createdSort,
                    advanceFilter: advanceFilterActive,
                })
            );
        }
    };

    const hasMore = () => {
        switch (appointmentListType) {
            case APPOINTMENTS_LIST_TYPE_DEFAULT:
                return appointments.length < totalAppointments;
            case APPOINTMENTS_LIST_TYPE_SEARCH:
                return searchResults.length < totalAppointments;
            default:
                return false;
        }
    };

    const appointmentsCount = () => {
        switch (appointmentListType) {
            case APPOINTMENTS_LIST_TYPE_DEFAULT:
                return totalAppointments;
            case APPOINTMENTS_LIST_TYPE_SEARCH:
                return filterResultsCount;
            default:
                return 0;
        }
    };

    const getAppointments = () => {
        switch (appointmentListType) {
            case APPOINTMENTS_LIST_TYPE_DEFAULT:
                return appointments;
            case APPOINTMENTS_LIST_TYPE_SEARCH:
                return searchResults;
            default:
                return [];
        }
    };

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    const handleCreateList = (listName) => {
        if (listName.length <= 0) {
            alert("Please enter a list name");
            return;
        }
        dispatch(createAppointmentsCustomizedList({ name: listName }));
    };

    const handleDeleteList = (listName) => {
        if (activeTab.localeCompare("appointments") === 0) {
            return;
        }

        if (activeTab.length <= 0) {
            alert("Please select a list to delete");
            return;
        }

        dispatch(deleteAppointmentsCustomizedList({ name: activeTab }));
        setActiveTab("appointments");
    };

    if (!access) {
        return <AccessNotFound />;
    }

    return (
        <div>
            <AppointmentsToolbar
                handleCreateList={handleCreateList}
                handleDeleteList={handleDeleteList}
                onFilterChange={searchFilterChanged}
                showSearch={activeTab === "appointments"}
                searchGeneralLoad={appointmentRef}
                tabs={lists ?? []}
            />
            {error && (
                <div className="alert alert-danger" role="alert">
                    {error.toString()}
                </div>
            )}
            <Tabs
                defaultActiveKey="appointments"
                activeKey={activeTab}
                id="appointmentsListTab"
                className="border-none"
                onSelect={handleTabChange}
            >
                <Tab eventKey="appointments" title={`All Results (${appointmentsCount()})`}>
                    <DefaultList
                        appointmentListType={appointmentListType}
                        appointments={getAppointments()}
                        filterChanged={filterChanged}
                        hasMore={hasMore()}
                        loadedCount={getAppointments().length}
                        onNextPage={nextPage}
                        onNextPageFilter={onNextPageFilter}
                        onSelected={onSelected}
                        selectedAppointment={selected}
                        extractEmployeeNames={extractEmployeeNames}
                    />
                </Tab>
                {lists.map((e) => (
                    <Tab
                        key={e.id}
                        eventKey={e.name}
                        title={`${e.name} (${e.customerAppointmentIds.length})`}
                    >
                        <CustomizedList
                            list={e}
                            filterChanged={filterChanged}
                            selectedAppointment={selected}
                            onSelected={onSelected}
                            extractEmployeeNames={extractEmployeeNames}
                        />
                    </Tab>
                ))}
            </Tabs>
        </div>
    );
}