import React, { Component } from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";
import SaleGeneralEdit from "./SaleGeneralEdit";
import SaleProductEdit from "./SaleProductEdit";
import SaleProductionEdit from "./SaleProductionEdit";
import SaleAccountsEdit from "./SaleAccountsEdit";
import SaleSalesMarketingEdit from "./SaleSalesMarketingEdit";
import SaleEditCloseConfirmation from "./SaleEditCloseConfirmation";
import Auth from "../../../Auth";
import axios from "axios";
import * as globalConst from "../../../global/const";

// SCSS
import "../../../css/sales/sale_edit.scss";
import "../../../css/modal.scss";
import {
  getOnlineOrderNumberparent,
  saleIsReturn,
} from "./Acctions/SaleRetutn";
import Util from "../../../Util";
import ShowSaleConsecutive from "../Acctions/ShowSaleConsecutive";
import { getWorkflowSale } from "./Workflow/WorkflowSaleConsult";

const BTNS_DISABLES = [
  globalConst.BTN_DUPLICATE_SALE,
  globalConst.BTN_EDIT_WORKFLOW_ACTIVITY,
  globalConst.BTN_CREATE_WORKFLOW,
  globalConst.BTN_QUALITY_CONTROL_CHECK_START,
  globalConst.BTN_QUALITY_CONTROL_CHECK_PENDING,
  globalConst.BTN_QUALITY_CONTROL_CHECK_APPROVED,
  globalConst.BTN_QUALITY_CONTROL_CHECK_REJECTED,
];

class SaleEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sale: props.sale,
      showPanelInformation: props.showPanelInformation ?? false,
      edited_sale: null,
      pending_changes_accounts: false,
      pending_changes_costs: false,
      pending_changes_general: false,
      pending_changes_product: false,
      pending_changes_production: false,
      pending_changes_marketing: false,
      show_close_edit_confirmation: false,
      workflowSales: null,
      loadWorkflowSales: false,
      showAccounts: true,
    };
    this.updateData = false;
    this.saleProductionEdit = React.createRef();

    this.closeEditionComponent = this.closeEditionComponent.bind(this);
    this.closeEditionComponentWithoutChanges =
      this.closeEditionComponentWithoutChanges.bind(this);
    this.saleUpdated = this.saleUpdated.bind(this);
    this.generateEditionLog = this.generateEditionLog.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.closeEditCloseConfirmation =
      this.closeEditCloseConfirmation.bind(this);

    this.saleDiamonsChanges = false;
  }

  closeEditCloseConfirmation() {
    this.setState({
      show_close_edit_confirmation: false,
    });
  }

  closeEditionComponent() {
    if (this.validIschange()) {
      this.openEditCloseConfirmationModal();
      return;
    }

    this.props.disabledActionsBotons([]);
    this.props.closeEditionComponent(this.state.edited_sale, this.updateData);
  }

  closeEditionComponentWithoutChanges() {
    this.props.disabledActionsBotons([]);
    this.props.closeEditionComponent(this.state.edited_sale, this.updateData);
  }

  validIschange = () => {
    if (
      this.state.pending_changes_accounts ||
      this.state.pending_changes_costs ||
      this.state.pending_changes_general ||
      this.state.pending_changes_marketing ||
      this.state.pending_changes_product ||
      this.state.pending_changes_production
    ) {
      return true;
    }

    return false;
  };

  updateWorkflowSale = (value) => {
    if (value) {
      this.props.updateWorkflowSaleInformation(value);
    }
  };

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    var access_to_general = Auth.isAuthorised("sales_edit_general");
    var access_to_product = Auth.isAuthorised("sales_edit_product");
    var access_to_production = Auth.isAuthorised("sales_edit_production");
    var access_to_account = Auth.isAuthorised("sales_edit_accounts");
    var access_to_sales_and_marketing = Auth.isAuthorised(
      "sales_edit_sales_and_marketing"
    );

    return (
      <div>
        {this.state.show_close_edit_confirmation === true ? (
          <SaleEditCloseConfirmation
            closeEditCloseConfirmation={this.closeEditCloseConfirmation}
            closeEditionComponent={this.closeEditionComponent}
            closeEditionComponentWithoutChanges={
              this.closeEditionComponentWithoutChanges
            }
          />
        ) : null}

        <Modal
          show={true}
          onHide={this.closeEditionComponent}
          dialogClassName="modal-90w"
          backdrop="static"
          backdropClassName={
            this.state.showPanelInformation
              ? "modal-black-show-informacion-panel"
              : ""
          }
          className={
            this.state.showPanelInformation
              ? "modal-show-informacion-panel"
              : ""
          }
        >
          <Modal.Header closeButton>
            Edit Sale {this.state.sale.onlineOrderNumber}{" "}
            <ShowSaleConsecutive
              sale={this.state.sale}
              breackSpace={false}
              labelSmall={true}
            />
            {saleIsReturn(this.state.sale) && (
              <>
                - This order was created from a return{" "}
                {getOnlineOrderNumberparent(this.state.sale)}
              </>
            )}
          </Modal.Header>
          <Modal.Body>
            <div id="SaleEdit_component">
              <Tabs id="SaleEditTabs" className="mb-3">
                {access_to_general === true ? (
                  <Tab
                    eventKey="general"
                    title="General"
                    tabClassName={
                      this.state.pending_changes_general
                        ? "pending-changes"
                        : null
                    }
                  >
                    <SaleGeneralEdit
                      sale={this.state.sale}
                      saleUpdated={this.saleUpdated}
                      saleChangeTax={this.saleChangeTax}
                      handleTabChange={this.handleTabChange}
                      onRef={(ref) => (this.saleGeneralEdit = ref)}
                    />
                  </Tab>
                ) : null}
                {access_to_product === true ? (
                  <Tab
                    eventKey="product"
                    title="Product"
                    tabClassName={
                      this.state.pending_changes_product
                        ? "pending-changes"
                        : null
                    }
                  >
                    {this.state.loadWorkflowSales && (
                      <SaleProductEdit
                        sale={this.state.sale}
                        workflowSales={this.state.workflowSales ?? []}
                        saleUpdated={this.saleUpdated}
                        handleTabChange={this.handleTabChange}
                      />
                    )}
                  </Tab>
                ) : null}
                {access_to_production === true ? (
                  <Tab
                    eventKey="production"
                    title="Production"
                    tabClassName={
                      this.state.pending_changes_production
                        ? "pending-changes"
                        : null
                    }
                  >
                    {this.state.loadWorkflowSales && (
                      <SaleProductionEdit
                        sale={this.state.sale}
                        workflowSales={this.state.workflowSales}
                        saleUpdated={this.saleUpdated}
                        handleTabChange={this.handleTabChange}
                        ref={this.saleProductionEdit}
                        updateWorkflowSale={this.updateWorkflowSale}
                      />
                    )}
                  </Tab>
                ) : null}
                {access_to_sales_and_marketing === true ? (
                  <Tab
                    eventKey="sales-marketing"
                    title="Sales and Marketing"
                    tabClassName={
                      this.state.pending_changes_marketing
                        ? "pending-changes"
                        : null
                    }
                  >
                    <SaleSalesMarketingEdit
                      sale={this.state.sale}
                      saleUpdated={this.saleUpdated}
                      handleTabChange={this.handleTabChange}
                    />
                  </Tab>
                ) : null}
                {access_to_account === true ? (
                  <Tab
                    eventKey="accounts"
                    title="Accounts"
                    tabClassName={
                      this.state.pending_changes_accounts
                        ? "pending-changes"
                        : null
                    }
                  >
                    {this.renderTabAccounts()}
                  </Tab>
                ) : null}
              </Tabs>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  renderTabAccounts = () => {
    return (
      <>
        {this.state.showAccounts ? (
          <SaleAccountsEdit
            sale={this.state.sale}
            saleUpdated={this.saleUpdated}
            handleTabChange={this.handleTabChange}
            fieldsNoUpdated={this.state.pending_changes_accounts}
          />
        ) : null}
      </>
    );
  };

  openEditCloseConfirmationModal() {
    this.setState({
      show_close_edit_confirmation: true,
    });
  }

  handleTabChange(tab, status) {
    if (status) {
      this.updateData = true;
    }

    switch (tab) {
      case "accounts":
        this.setState({
          pending_changes_accounts: status,
        });
        break;
      case "costs":
        this.setState({
          pending_changes_costs: status,
        });
        break;
      case "general":
        this.setState({
          pending_changes_general: status,
        });
        break;
      case "product":
        this.setState({
          pending_changes_product: status,
        });
        break;
      case "production":
        this.setState({
          pending_changes_production: status,
        });
        break;
      case "marketing":
        this.setState({
          pending_changes_marketing: status,
        });
        break;
      default:
        break;
    }
  }

  saleChangeTax = async (name, value) => {
    const sale = this.state.sale;

    await this.setState({
      showAccounts: false,
    });

    if (name === globalConst.FIELD_SHIP_CUSTOMER_ADRRESS && value) {
      sale["taxPercentage"] = 0;

      if (this.state.sale.shippingCountry) {
        sale["taxPercentage"] = Util.getPercentage(
          this.state.sale.shippingCountry
        );
      }
    }

    if (name === globalConst.FIELD_PICK_UP_AT_SHOWROOM && value) {
      sale["taxPercentage"] = Util.getPercentage(null);
    }

    if (name === globalConst.FIELD_COUNTRY && value) {
      sale["taxPercentage"] = Util.getPercentage(value);
    }

    await this.setState({
      sale: sale,
      edited_sale: sale,
      pending_changes_accounts: true,
      showAccounts: true,
    });
  };

  saleUpdated(edited_sale, validChange = false) {
    if (Auth.isAuthorised("sales_edit_general")) {
      this.saleGeneralEdit.updateGeneralTabSheetType(edited_sale.sheetType);
    }

    const { sale } = this.state;

    let changsAccounts = false;

    const saleDiamondOlsd = JSON.stringify(sale.saleDiamondModels);
    const saleDiamondNews = JSON.stringify(edited_sale.saleDiamondModels);

    if (saleDiamondOlsd !== saleDiamondNews && validChange) {
      this.setState({
        showAccounts: false,
      });

      changsAccounts = true;
    }

    if (
      !this.state.sale.amountAlreadyPaid &&
      this.state.sale.amountAlreadyPaid !== edited_sale.amountAlreadyPaid
    ) {
      changsAccounts = true;
    }

    this.setState({
      sale: edited_sale,
      edited_sale: edited_sale,
      pending_changes_accounts: changsAccounts,
      showAccounts: true,
    });

    this.props.updateSaleInformation(edited_sale);
    this.generateEditionLog(edited_sale);
  }

  axioRequest = null;
  last_sale_reported_for_log = null;
  generateEditionLog(edited_sale) {
    if (this.axioRequest !== null) {
      this.axioRequest.cancel();
    }

    this.axioRequest = axios.CancelToken.source();

    let sale_before_update = this.last_sale_reported_for_log;
    if (sale_before_update === null) {
      sale_before_update = this.props.sale;
    }

    const url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_SALES_LOG_NEW;
    const params = new URLSearchParams();

    const user = Auth.getAuthenticatedUser();
    params.append("salesId", sale_before_update.id);
    params.append("userId", user.id);
    params.append("oldInformation", JSON.stringify(sale_before_update));
    params.append("newInformation", JSON.stringify(edited_sale));
    params.append("action", "EDIT");

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    };
    axios
      .post(url, params, config)
      .then((response) => {
        const data = response.data;
        if (data !== undefined && data.code === 200) {
          console.log("sales_log stored");
        } else {
          console.log("Error storing sales_log");
        }
        this.axioRequest = null;
      })
      .catch((err) => {
        console.log("Error storing sales_log.");
        if (err.response) {
          if ("status" in err.response && err.response.status === 401) {
            console.log("Not authorised.");
          } else {
            console.log(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }
        } else if (err.request) {
          console.log(
            "There was a problem processing the information. Please reload this page and try again."
          );
        }

        return null;
      });
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  static getDerivedStateFromProps(props, state) {
    let edited_sale = state.edited_sale;
    if (edited_sale !== null && edited_sale.id !== props.sale.id) {
      edited_sale = null;
    }
    return {
      edited_sale: edited_sale,
    };
  }

  componentDidMount() {
    if (this.props.showPanelInformation) {
      this.props.disabledActionsBotons(BTNS_DISABLES);
    }

    this.findWorkflowSales(this.state.sale);
  }

  findWorkflowSales = async (sale) => {
    if (!sale) {
      this.setState({
        workflowSales: [],
        loadWorkflowSales: true,
      });

      return null;
    }

    const workflowSales = await getWorkflowSale(sale);

    this.setState({
      workflowSales: workflowSales ?? [],
      loadWorkflowSales: true,
    });
  };

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default SaleEdit;
