export const findUserGoogleAuthenticatorSecret = (data) => {
  if (!data) {
    return null;
  }

  const user = data?.user ?? {};

  if (user?.googleAuthenticatorSecret) {
    return user?.googleAuthenticatorSecret;
  }

  return null;
};

export const findUserTwoFactorAuthenticatorValidated = (data) => {
  if (!data) {
    return true;
  }

  const user = data?.user ?? {};

  if (user?.twoFactorAuthenticatorValidated) {
    return user?.twoFactorAuthenticatorValidated;
  }

  return null;
};
