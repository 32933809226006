import moment from "moment-timezone";

export function formatDate(dateString) {
  // Remove the 'Z' from the date string and parse it
  const dateWithoutTimezone = moment(
    dateString.slice(0, -1),
    "YYYY-MM-DDTHH:mm:ss.SSS"
  );
  // If you want the output as a string, use the format method
  return dateWithoutTimezone.format("DD-MM-YYYY h:mm A");
}

export function formatCustomerCreationDate(dateString) {
  if (!dateString) {
    return "";
  }
  //input format 2023-11-28
  //output format 28 Dec, 2023
  const dateWithoutTimezone = moment(dateString, "YYYY-MM-DD");
  return dateWithoutTimezone.format("DD MMM, YYYY");
}

export function dateFormatCustom(date, format = "YYYY-MM-DD") {
  if (!date) {
    return "";
  }

  try {
    const dateFormat = new Date(moment(date, "YYYY/MM/DD HH:mm:ss"));

    return moment(dateFormat).format(format);
  } catch (error) {
    console.log(error);
    return date;
  }
}

export function convertUtcToTimezone(
  utcDateString,
  format = "YYYY-MM-DD",
  timezone
) {
  if (!utcDateString) {
    return "";
  }

  try {
    // Convert UTC to Sydney time
    return moment.utc(utcDateString).tz(timezone).format(format);
  } catch (error) {
    console.log(error);
    return utcDateString;
  }
}

export function formatAppointmentCreationDate(dateString) {
  if (!dateString) {
    return "";
  }
  //input format 2023-11-28
  //output format 28 Dec, 2023
  const dateWithoutTimezone = moment(dateString, "YYYY-MM-DD");
  return dateWithoutTimezone.format("DD MMM, YYYY");
}

export const getYearMonthAndLastDay = (date) => {
  let year = date.year();

  let month = date.month();

  let lastDayOfMonth = date.endOf("month").date();

  return {
    year,
    month: month,
    day: lastDayOfMonth,
  };
};
