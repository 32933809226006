import React from "react";
import PropTypes from "prop-types";
import { NavLink, Row, Table } from "react-bootstrap";
import ShowSaleConsecutive from "../../Acctions/ShowSaleConsecutive";
import { dateFormatCustom } from "../../../../utils/dateUtils";

const JewelleryRingRequestTableResponse = ({ sales }) => {
  const openSales = (id) => {
    window.open(`/sales?order-ref=${id}`);
  };

  const renderMessage = (response) => {
    const errors = response?.errors ?? [];

    return (
      <>
        <span
          className={response.code === 200 ? "text-success" : "text-danger"}
        >
          {response.message}
        </span>

        {errors.length > 0 && (
          <ul>
            {errors.map((error, index) => {
              return <li key={index}>{error}</li>;
            })}
          </ul>
        )}
      </>
    );
  };

  return (
    <div
      className="overflow-scroll"
      style={{
        minHeight: "20%",
        maxHeight: 500,
      }}
    >
      <Table striped bordered hover className id="table_sales">
        <thead className="thead-dark">
          <tr className="titles">
            <th className="saleDate">Date of sale</th>
            <th className="saleOrderNumber">Order No.</th>
            <th className="saleType">Type</th>
            <th className="saleStatus">Status</th>
            <th className="statusProcess">Process status</th>
            <th className="saleShow"></th>
          </tr>
        </thead>
        <tbody>
          {sales.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  {dateFormatCustom(item.sale.saleDate, "DD MMM, YYYY HH:mm")}
                </td>
                <td>
                  {item.sale.onlineOrderNumber}
                  <ShowSaleConsecutive sale={item.sale} />
                </td>
                <td>{item.sale.saleType}</td>
                <td>{item.sale.status}</td>
                <td>{renderMessage(item)}</td>
                <td>
                  {item.code !== 200 && (
                    <NavLink
                      onClick={() => openSales(item.sale.onlineOrderNumber)}
                      className="link-primary"
                    >
                      Show more
                    </NavLink>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

JewelleryRingRequestTableResponse.propTypes = {
  sales: PropTypes.array.isRequired,
};

JewelleryRingRequestTableResponse.defaultProps = {
  sales: [],
};

export default JewelleryRingRequestTableResponse;
