import React, { useState } from "react";
import PropTypes from "prop-types";
import QualityControlStart from "./QualityControlStart";
import QualityControlPending from "./QualityControlPending";
import QualityControlApproved from "./QualityControlApproved";
import QualityControlRejected from "./QualityControlRejected";
import { useDispatch, useSelector } from "react-redux";
import {
  setBtnDisabled,
  setChangeBtns,
  setReloadSale,
} from "../../../../store/slices";
import EditQualityControlCheck from "./Edit/EditQualityControlCkeck";
import {
  BTN_CREATE_WORKFLOW,
  BTN_DUPLICATE_SALE,
  BTN_EDIT_SALE,
  BTN_EDIT_WORKFLOW_ACTIVITY,
  BTN_QUALITY_CONTROL_CHECK_APPROVED,
  BTN_QUALITY_CONTROL_CHECK_PENDING,
  BTN_QUALITY_CONTROL_CHECK_REJECTED,
  BTN_QUALITY_CONTROL_CHECK_START,
} from "../../../../global/const";

const BTNS_DISABLES = [
  BTN_EDIT_SALE,
  BTN_DUPLICATE_SALE,
  BTN_EDIT_WORKFLOW_ACTIVITY,
  BTN_CREATE_WORKFLOW,
  BTN_QUALITY_CONTROL_CHECK_START,
  BTN_QUALITY_CONTROL_CHECK_PENDING,
  BTN_QUALITY_CONTROL_CHECK_APPROVED,
  BTN_QUALITY_CONTROL_CHECK_REJECTED,
];

const QualityControlCheck = ({
  qualityControlCheck,
  activity,
  btnsDisable,
}) => {
  const dispatch = useDispatch();

  const [showEditPending, setShowEditPending] = useState(false);

  const { status, statusEdit } = useSelector(
    (state) => state.qualityControlCheck
  );

  if (!activity) {
    return "";
  }

  const onResponse = (value) => {
    setShowEditPending(value);

    if (status || statusEdit) {
      dispatch(setReloadSale(true));
    }

    setBTNDisabled(value);
  };

  const openModalEdit = (value) => {
    setShowEditPending(value);

    setBTNDisabled(value);
  };

  const setBTNDisabled = (value) => {
    if (value) {
      dispatch(setBtnDisabled(BTNS_DISABLES));
    }

    if (!value) {
      dispatch(setBtnDisabled([]));
    }

    dispatch(setChangeBtns(true));
  };

  const openEditModalStart = (value) => {
    if (value) {
      setShowEditPending(true);
    }
  };

  return (
    <>
      {showEditPending && qualityControlCheck && (
        <EditQualityControlCheck
          qualityControlCheck={qualityControlCheck ?? null}
          showPanelInformation={true}
          onResponse={onResponse}
          activity={activity}
        />
      )}

      <QualityControlStart
        activity={activity ?? null}
        qualityControlCheck={qualityControlCheck}
        btnsDisable={btnsDisable ?? []}
        openEditModal={openEditModalStart}
      />

      {qualityControlCheck && (
        <QualityControlPending
          qualityControlCheck={qualityControlCheck ?? null}
          btnsDisable={btnsDisable ?? []}
          activity={activity}
          openModalEdit={openModalEdit}
        />
      )}

      {qualityControlCheck && (
        <QualityControlApproved
          qualityControlCheck={qualityControlCheck ?? null}
          btnsDisable={btnsDisable ?? []}
          activity={activity}
          openModalEdit={openModalEdit}
        />
      )}

      {qualityControlCheck && (
        <QualityControlRejected
          qualityControlCheck={qualityControlCheck ?? null}
          btnsDisable={btnsDisable ?? []}
          activity={activity}
          openModalEdit={openModalEdit}
        />
      )}
    </>
  );
};

QualityControlCheck.propTypes = {
  qualityControlCheck: PropTypes.object,
  activity: PropTypes.object.isRequired,
  btnsDisable: PropTypes.array,
};

QualityControlCheck.defaultProps = {
  qualityControlCheck: null,
  btnsDisable: [],
};

export default QualityControlCheck;
