import React, { Component } from "react";
import { Form } from "react-bootstrap";
import Util from "../../Util";
import ShowSaleConsecutive from "../sales/Acctions/ShowSaleConsecutive";

class SaleRow extends Component {
  constructor(props) {
    super();
    this.state = {
      accounting: props.accounting,
      selected_accounting: props.selected_accounting,
      addToMyList: props.addToMyList,
      currentTab: props.currentTab,
    };
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
  }

  handleChangeCheckbox(event) {
    this.removeSaleFromCustomisedList();
  }

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    const accounting = this.state.accounting;

    let saleDate = null;
    if (accounting.created !== null && accounting.created !== "") {
      saleDate = new Date(accounting.created).toLocaleString("default", {
        day: "numeric",
        month: "short",
        year: "numeric",
        timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE"),
      });
      saleDate = saleDate.slice(0, -5) + ", " + saleDate.slice(-4);
    }

    let row_class = "sale_row";
    if (this.state.highlight_row === true) {
      row_class = "sale_row highlighted_row";
    }

    if (
      this.state.selected_accounting !== null &&
      this.state.selected_accounting !== undefined &&
      accounting !== null &&
      accounting.id === this.state.selected_accounting.id
    ) {
      row_class = row_class + " highlighted_row";
    }

    let typeOfJob = "";
    if (
      accounting.typeOfService === "Post Sale Service" &&
      accounting.postSaleServiceModel !== null
    ) {
      typeOfJob = accounting.postSaleServiceModel.typeOfJob;
    }

    let name = "";
    if (accounting.salesModel !== null) {
      name = accounting.salesModel.name;
    }
    if (accounting.postSaleServiceModel !== null) {
      name = accounting.postSaleServiceModel.name;
    }

    let saleType = "";
    if (accounting.salesModel !== null) {
      saleType = accounting.salesModel.saleType;
    }
    if (accounting.postSaleServiceModel !== null) {
      if (accounting.postSaleServiceModel.salesModel !== null) {
        saleType = accounting.postSaleServiceModel.salesModel.saleType;
      }
    }

    let diamondDetails = "";
    if (accounting.salesModel !== null) {
      diamondDetails = accounting.salesModel.diamondDetails;
    }
    if (accounting.postSaleServiceModel !== null) {
      if (accounting.postSaleServiceModel.salesModel !== null) {
        diamondDetails =
          accounting.postSaleServiceModel.salesModel.diamondDetails;
      }
    }

    let ringPreference = "";
    if (accounting.salesModel !== null) {
      ringPreference = accounting.salesModel.ringPreference;
    }
    if (accounting.postSaleServiceModel !== null) {
      if (accounting.postSaleServiceModel.salesModel !== null) {
        ringPreference =
          accounting.postSaleServiceModel.salesModel.ringPreference;
      }
    }

    let orderCurrency = "";
    if (accounting.salesModel !== null) {
      orderCurrency = accounting.salesModel.orderCurrency;
    }
    if (accounting.postSaleServiceModel !== null) {
      if (accounting.postSaleServiceModel.salesModel !== null) {
        orderCurrency =
          accounting.postSaleServiceModel.salesModel.orderCurrency;
      }
    }

    let invoice = "";
    if (accounting.salesModel !== null) {
      invoice = accounting.salesModel.invoice;
    }
    if (accounting.postSaleServiceModel !== null) {
      invoice = "Now";
    }

    let totalIncGst = "";
    if (accounting.salesModel !== null) {
      totalIncGst = Util.formatCurrency(accounting.salesModel.totalIncGst);
    }
    if (accounting.postSaleServiceModel !== null) {
      totalIncGst = Util.formatCurrency(
        accounting.postSaleServiceModel.priceToCharge
      );
    }

    let balance = "";
    if (accounting.salesModel !== null) {
      balance = Util.formatCurrency(accounting.salesModel.balance);
    }
    if (accounting.postSaleServiceModel !== null) {
      balance = Util.formatCurrency(accounting.postSaleServiceModel.balance);
    }

    let xeroCode = "";
    if (accounting.salesModel !== null) {
      xeroCode = accounting.salesModel.xeroCode;
    }
    if (accounting.postSaleServiceModel !== null) {
      xeroCode = accounting.postSaleServiceModel.xeroCode;
    }

    let onlineOrderNumber = "";
    if (accounting.salesModel !== null) {
      onlineOrderNumber = accounting.salesModel.onlineOrderNumber;
    }
    if (accounting.postSaleServiceModel !== null) {
      if (accounting.postSaleServiceModel.salesModel !== null) {
        onlineOrderNumber =
          accounting.postSaleServiceModel.salesModel.onlineOrderNumber;
      }
    }

    let paymentMethod = "";
    if (accounting.salesModel !== null) {
      paymentMethod = accounting.salesModel.paymentMethod;
    }
    if (accounting.postSaleServiceModel !== null) {
      paymentMethod = accounting.postSaleServiceModel.paymentMethod;
    }

    let generalCostStatus = "";
    if (accounting.salesModel !== null) {
      generalCostStatus = accounting.salesModel.generalCostStatus;
    }

    const sale = accounting?.postSaleServiceModel?.salesModel
      ? accounting?.postSaleServiceModel?.salesModel
      : accounting?.salesModel;

    return (
      <tr
        className={row_class}
        onClick={() => this.props.handleRowSelection(this.props.index)}
      >
        <td className="saleDate">
          {saleDate !== null ? <div className="saleDate">{saleDate}</div> : ""}
        </td>
        <td className="addToMyList" onClick={(e) => e.stopPropagation()}>
          <Form.Check
            type="checkbox"
            name="addToMyList"
            id={"addToMyList" + this.props.index}
            checked={this.state.addToMyList === true ? true : false}
            onChange={this.handleChangeCheckbox}
          />
        </td>
        <td className="name">{name}</td>
        <td className="typeOfService">
          {accounting.typeOfService === "Post Sale Service"
            ? "Post Sale Service - " + typeOfJob
            : "Sale"}
        </td>
        <td className="saleType">{saleType}</td>
        <td className="diamondDetails">{diamondDetails}</td>
        <td className="ringPreference">{ringPreference}</td>
        <td className="orderCurrency">{orderCurrency}</td>
        <td className="invoice">{invoice}</td>
        <td className="totalIncGst">{totalIncGst}</td>
        <td className="balance">{balance}</td>
        <td className="xeroCode">{xeroCode}</td>
        <td className="onlineOrderNumber">
          {onlineOrderNumber}
          <ShowSaleConsecutive sale={sale} />
        </td>
        <td className="paymentMethod">{paymentMethod}</td>
        <td className="generalCostStatus">{generalCostStatus}</td>
      </tr>
    );
  }

  highlightRow() {
    this.setState({
      highlight_row: !this.state.highlight_row,
    });
  }

  removeSaleFromCustomisedList() {
    let saleId = this.state.accounting.id;
    this.props.removeSaleFromCustomisedList(saleId);
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  static getDerivedStateFromProps(props, state) {
    return {
      accounting: props.accounting,
      selected_accounting: props.selected_accounting,
      addToMyList: props.addToMyList,
      currentTab: props.currentTab,
    };
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default SaleRow;
