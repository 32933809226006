import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Table } from "react-bootstrap";
import { REACT_APP_WORKFLOW_TYPE_MANUFACTURING } from "../../../global/const";

const ShowDataStock = ({ workflowSales }) => {
  const [activities, setActivities] = useState([]);
  const [loadActivity, setLoadActivity] = useState(true);

  useEffect(() => {
    let workflowActivityes = [];

    workflowSales.forEach((workflowSale) => {
      const { workflowActivityModels = [] } = workflowSale;

      workflowActivityModels.forEach((workflowActivity) => {
        if (
          workflowActivity?.type === REACT_APP_WORKFLOW_TYPE_MANUFACTURING &&
          workflowActivity?.stockNumber
        ) {
          workflowActivityes.push(workflowActivity);
        }
      });
    });

    setActivities(workflowActivityes);
    setLoadActivity(false);
  }, [activities.length, loadActivity, workflowSales]);

  const renderRow = (activities) => {
    return (
      <>
        {activities.map((activity) => {
          return (
            <tr key={activity.id}>
              <td>{activity.stockNumber}</td>
              <td>{activity.originalLocation}</td>
              <td>{activity.stockCategory}</td>
            </tr>
          );
        })}
      </>
    );
  };

  return (
    <>
      {activities.length > 0 && (
        <Row>
          <b className="center-element">Stock information</b>
          <Table striped bordered hover>
            <thead className="thead-dark">
              <tr className="titles">
                <th>Number</th>
                <th>Location</th>
                <th>Category</th>
              </tr>
            </thead>
            {workflowSales.length > 0 && <tbody>{renderRow(activities)}</tbody>}
          </Table>
        </Row>
      )}
    </>
  );
};

ShowDataStock.propTypes = {
  workflowSales: PropTypes.array.isRequired,
};

ShowDataStock.defaultProps = {
  workflowSales: [],
};

export default ShowDataStock;
