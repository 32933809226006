import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { Modal, Card, Button, ProgressBar, Row, Col, Form, FormLabel, Table } from "react-bootstrap";
import { removeWorkflowCadFile, selectWorkFlowCad, updateFollowUpWorkflowCad } from "../../../../../store/slices/workflowCadSlice";
import { API_STATUS_PENDING } from "../../../../../store/constants";
import Auth from "../../../../../Auth";
import { ALLOWED_FILE_TYPES, AWS_S3_ACCESS_POINT_URL_CAD_FILES } from "../../../../../global/const";
import DocumentIcon from "../../../../../image/file-storage.png";
import Util from "../../../../../Util";

const FollowupRequest = ({ onClose }) => {

    const { status, progress, selectedWorkflowCad } = useSelector((state) => state.workflowCad);
    const [cadFiles, setCadFiles] = useState([]);
    const [jewellerStatus, setJewellerStatus] = useState(selectedWorkflowCad?.jewellerStatus || '');
    const [salesRepStatus, setSalesRepStates] = useState(selectedWorkflowCad?.salesRepStatus || '');
    const [isFormValid, setIsFormValid] = useState(false);
    const [fileIdsToRemove, setFileIdsToRemove] = useState([]);
    const jewellerStatusOptions = Util.getStorageParameter("REACT_APP_CAD_JEWELLER_STATUS").split(",");
    const salesRepStatusOptions = Util.getStorageParameter("REACT_APP_CAD_SALES_REP_STATUS").split(",");

    const hasFollowupStatusAccess = Auth.canAccessRolesAndLeader("rolesAccessCADFollowupStatus");
    const hasJewellerStatusAccess = Auth.canAccessRolesAndLeader("rolesAccessCADFollowupJewellerStatus");
    const hasSalesResStatusAccess = Auth.canAccessRolesAndLeader("rolesAccessCADFollowupSaleResStatus");

    const dispatch = useDispatch();

    useEffect(() => {
        // Validate form: Ensure at least one status change or file upload
        const isStatusChanged = jewellerStatus !== selectedWorkflowCad?.jewellerStatus ||
            salesRepStatus !== selectedWorkflowCad?.salesRepStatus;
        const isFileUploaded = cadFiles && cadFiles.length > 0;
        const isFileIdsToRemove = fileIdsToRemove && fileIdsToRemove.length > 0;
        setIsFormValid(isStatusChanged || isFileUploaded || isFileIdsToRemove);
    }, [jewellerStatus, salesRepStatus, cadFiles, selectedWorkflowCad, fileIdsToRemove]);

    const handleRemoveFileByIndex = (indexToRemove) => {
        setCadFiles((prevCadFiles) =>
            prevCadFiles.filter((_, index) => index !== indexToRemove)
        );
    };

    const handleAddButtonClick = () => {
        document.getElementById('cadFiles').click();
    };

    const handleFileUpload = (event) => {
        const selectedFiles = Array.from(event.target.files);
        if (selectedFiles.length > 0) {
            setCadFiles(prevCadFiles => [...prevCadFiles, ...selectedFiles]);
        }
    };

    const handleClose = () => {
        setFileIdsToRemove([]);
        dispatch(selectWorkFlowCad(null));
        onClose();
    };

    const createFormData = (additionalFields) => {
        const formData = new FormData();

        cadFiles.forEach(cadFile => formData.append('cadFiles[]', cadFile));
        formData.append('fileIdsToRemove', Util.changeArrayToString(fileIdsToRemove));

        // Append additional fields to formData
        Object.entries(additionalFields).forEach(([key, value]) => {
            formData.append(key, value);
        });

        return formData;
    };

    const handleFollowUpButtonClick = async (event) => {
        event.preventDefault();

        if (!isFormValid) {
            return;
        }

        const formData = createFormData({
            jewellerStatus: jewellerStatus,
            salesRepStatus: salesRepStatus
        });

        dispatch(updateFollowUpWorkflowCad({ data: formData, id: selectedWorkflowCad.id })).then(handleClose);
    }

    const handleRemoveFileById = (fileId) => {
        setFileIdsToRemove(prevFileIdsToRemove => [...prevFileIdsToRemove, fileId]);
        dispatch(removeWorkflowCadFile(fileId));
    };

    const isValidImage = (url) => {
        // Check if the URL ends with a valid image extension
        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', '.webp'];
        return imageExtensions.some((ext) => url.toLowerCase().endsWith(ext));
    };

    const renderFileRow = (cadFile, index, isUploaded = false) => (
        <tr key={index}>
            <td>
                <img
                    className="rounded-circle"
                    src={
                        isUploaded
                            ? !isValidImage(cadFile.filename)
                                ? DocumentIcon
                                : `${AWS_S3_ACCESS_POINT_URL_CAD_FILES}${cadFile.filename}`
                            : cadFile.type.startsWith('image/')
                                ? URL.createObjectURL(cadFile)
                                : DocumentIcon
                    }
                    alt={`Upload Preview ${index + 1}`}
                />
            </td>
            <td>
                <a href={isUploaded ? `${AWS_S3_ACCESS_POINT_URL_CAD_FILES}${cadFile.filename}` : URL.createObjectURL(cadFile)} target="_blank" rel="noopener noreferrer">
                    Click to open
                </a>
            </td>
            <td>
                <button
                    className="btn btn-default btn-round"
                    onClick={() => isUploaded ? handleRemoveFileById(cadFile.id) : handleRemoveFileByIndex(index)}
                >
                    <FontAwesomeIcon icon={faEraser} />
                </button>
            </td>
        </tr>
    );

    return (
        <Modal
            show={true}
            onHide={handleClose}
            dialogClassName="modal-90w"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    FOLLOW UP REQUEST
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Col id={'SaleWorkflowEditCAD_request_component'}>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col lg={6} className={'div-border-right-solid-2'}>
                                    <Row>
                                        {hasJewellerStatusAccess &&
                                            <Col lg={12}>
                                                <Form.Group className="form-group">
                                                    <FormLabel>Jeweller Status</FormLabel>
                                                    <Form.Select
                                                        onChange={(event) => { setJewellerStatus(event.target.value) }}
                                                        value={jewellerStatus}
                                                        name="jewellerStatus"
                                                        required
                                                    >
                                                        {jewellerStatusOptions.map((statusOption) => {
                                                            return (
                                                                <option
                                                                    key={statusOption}
                                                                    value={statusOption}
                                                                >
                                                                    {statusOption}
                                                                </option>
                                                            );
                                                        })}
                                                    </Form.Select>
                                                </Form.Group>
                                                <hr />
                                            </Col>
                                        }
                                        {hasFollowupStatusAccess &&
                                            <>
                                                <Row>
                                                    <Col xs={10} lg={10}>
                                                        <strong>Upload CAD</strong>
                                                    </Col>
                                                    {/* Add Button */}
                                                    <Col xs={2} lg={2}>
                                                        <Button
                                                            variant="primary"
                                                            onClick={handleAddButtonClick}
                                                        >
                                                            Add
                                                        </Button>
                                                        <Form.Control
                                                            type="file"
                                                            name="cadFiles"
                                                            id="cadFiles"
                                                            multiple
                                                            accept={ALLOWED_FILE_TYPES}
                                                            style={{ display: 'none' }}
                                                            onChange={handleFileUpload}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Col xs={12} lg={12} className="mt-2">
                                                    <Table striped bordered hover size="sm" className="text-center" id="cad_files_list_table">
                                                        <tbody>
                                                            {cadFiles.length > 0 && cadFiles.map((cadFile, index) => renderFileRow(cadFile, index))}

                                                            {selectedWorkflowCad?.workflowCadFileModels.length > 0 && selectedWorkflowCad?.workflowCadFileModels.filter(file => file.type === 'cad').map((cadFile, index) => renderFileRow(cadFile, index, true))}

                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                </Col>
                                <Col lg={6}>
                                    {hasSalesResStatusAccess &&
                                        <Form.Group className="form-group">
                                            <FormLabel>Sales rep Status</FormLabel>
                                            <Form.Select
                                                onChange={(event) => { setSalesRepStates(event.target.value) }}
                                                value={salesRepStatus}
                                                name="salesRepStatus"
                                                required
                                            >
                                                {salesRepStatusOptions.map((statusOption) => {
                                                    return (
                                                        <option
                                                            key={statusOption}
                                                            value={statusOption}
                                                        >
                                                            {statusOption}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    }
                                </Col>
                            </Row>

                            {status === API_STATUS_PENDING && <ProgressBar className="mt-2" animated now={progress} />}
                            <hr />
                            <Row className={'float-right'}>
                                <Col lg={12} className={'mt-2'}>
                                    <Button
                                        variant="primary"
                                        className="request-cad-btn"
                                        onClick={handleFollowUpButtonClick}
                                        disabled={!isFormValid}
                                    >
                                        UPDATE
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Modal.Body>
        </Modal>
    );
}
export default FollowupRequest;