import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import { ALLOWED_FILE_TYPES, AWS_S3_ACCESS_POINT_URL_CAD_FILES } from "../../../../../global/const";
import { removeWorkflowCadFile } from "../../../../../store/slices/workflowCadSlice";
import DocumentIcon from "../../../../../image/file-storage.png";

const AddFiles = ({ cadFiles, setCadFiles, setFileIdsToRemove }) => {

    const { selectedWorkflowCad } = useSelector((state) => state.workflowCad);
    const dispatch = useDispatch();

    const handleFileUpload = (event) => {
        const selectedFiles = Array.from(event.target.files);
        if (selectedFiles.length > 0) {
            setCadFiles(prevCadFiles => [...prevCadFiles, ...selectedFiles]);
        }
    };

    const handleAddButtonClick = () => {
        document.getElementById('uploadFiles').click();
    };

    const handleRemoveFileByIndex = (indexToRemove) => {
        setCadFiles((prevCadFiles) =>
            prevCadFiles.filter((_, index) => index !== indexToRemove)
        );
    };

    const handleRemoveFileById = (fileId) => {
        setFileIdsToRemove(prevFileIdsToRemove => [...prevFileIdsToRemove, fileId]);
        dispatch(removeWorkflowCadFile(fileId));
    };

    const renderFileRow = (cadFile, index, isUploaded = false) => (
        <tr key={index}>
            <td>
                <img
                    className="rounded-circle"
                    src={
                        isUploaded 
                            ? cadFile.type === 'document' 
                                ? DocumentIcon 
                                : `${AWS_S3_ACCESS_POINT_URL_CAD_FILES}${cadFile.filename}` 
                            : cadFile.type.startsWith('image/') 
                                ? URL.createObjectURL(cadFile) 
                                : DocumentIcon
                    }
                    alt={`Upload Preview ${index + 1}`}
                />
            </td>
            <td>
                <a href={isUploaded ? `${AWS_S3_ACCESS_POINT_URL_CAD_FILES}${cadFile.filename}` : URL.createObjectURL(cadFile)} target="_blank" rel="noopener noreferrer">
                    Click to open
                </a>
            </td>
            <td>
                <button
                    className="btn btn-default btn-round"
                    onClick={() => isUploaded ? handleRemoveFileById(cadFile.id) : handleRemoveFileByIndex(index)}
                >
                    <FontAwesomeIcon icon={faEraser} />
                </button>
            </td>
        </tr>
    );

    return (
        <>
            <Row>
                <Col xs={12} lg={8}>
                    <br />
                    <strong>Pictures/Documents</strong>
                </Col>
                {/* Add Button */}
                <Col xs={12} lg={4}>
                    <br />
                    <Button
                        variant="primary"
                        onClick={handleAddButtonClick}
                    >
                        Add
                    </Button>
                    <Form.Control
                        required={true}
                        type="file"
                        name="uploadFiles"
                        id="uploadFiles"
                        multiple
                        accept={ALLOWED_FILE_TYPES}
                        style={{ display: 'none' }}
                        onChange={handleFileUpload}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={12} className="mt-2">
                    <Table striped bordered hover size="sm" className="text-center" id="cad_files_list_table">
                        <tbody>
                            {cadFiles.length > 0 && cadFiles.map((cadFile, index) => renderFileRow(cadFile, index))}
                            
                            {selectedWorkflowCad?.workflowCadFileModels.length > 0 && selectedWorkflowCad?.workflowCadFileModels.filter(file => file.type !== 'cad').map((cadFile, index) => renderFileRow(cadFile, index, true))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}
export default AddFiles;