import axios from "axios";
import { sprintf } from "sprintf-js";
import * as globalConst from "../../../global/const";
import Auth from "../../../Auth";
import Util from "../../../Util";

const source = axios.CancelToken.source();

export const getEngagementRingPicture = async (sale) => {
  try {
    const { saleType, ringPreference } = sale;

    if (!Util.wordInArray(saleType, ["engagement ring"])) {
      return null;
    }

    if (ringPreference === "Custom") {
      return null;
    }

    const url = sprintf(
      "%s%s",
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN"),
      globalConst.REACT_APP_NOVITAERP_API_PRODUCT_ENGAGEMENT_RING_AVALIABLE_CATALOG
    );

    return await axios
      .get(url, {
        params: {
          ringPreference: ringPreference,
        },
        cancelToken: source.token,
        headers: { Authorization: `Bearer ${Auth.getToken()}` },
      })
      .then((apiResponse) => {
        const { data } = apiResponse;
        const { value } = data;

        return value;
      })
      .catch((err) => {
        return null;
      });
  } catch (error) {
    return null;
  }
};
