import React, { Component } from "react";
import { Modal, Button, Form, FormLabel, Alert, Card } from "react-bootstrap";
import DateField from "./formComponents/DateField";
import Auth from "../../Auth";
import axios from "axios";
import LoadingLayer from "../LoadingLayer";
import FlashMessages from "../FlashMessages";
import * as globalConst from "../../global/const";
import { Typeahead } from "react-bootstrap-typeahead";

// SCSS
import "../../css/sales/sale_create.scss";
import "../../css/modal.scss";
import { validateWorkflowActivityDelivering } from "./saleWorkflow/Actions/ValidateWorkflowActivityDeliveringCustomer";
import { getWorkflowSale } from "./edit/Workflow/WorkflowSaleConsult";
import AlertMessage from "../Utils/AlertMessage";
import { STATUS_COLLECTED_SHIPPED } from "../../store/constants";
import { FindJewelleyByContactName } from "../actions/JewelleryFind";
import Util from "../../Util";

const BTNS_DISABLES = [
  globalConst.BTN_EDIT_SALE,
  globalConst.BTN_DUPLICATE_SALE,
  globalConst.BTN_EDIT_WORKFLOW_ACTIVITY,
  globalConst.BTN_QUALITY_CONTROL_CHECK_START,
  globalConst.BTN_QUALITY_CONTROL_CHECK_PENDING,
  globalConst.BTN_QUALITY_CONTROL_CHECK_APPROVED,
  globalConst.BTN_QUALITY_CONTROL_CHECK_REJECTED,
];

class SaleWorkflow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSale: props.selectedSale,
      showPanelInformation: props.showPanelInformation,
      providerId: 0,
      newProvider: "",
      workflowActivitiesCAD: "",
      workflowActivitiesDeliveringToCustomer: "",
      workflowActivitiesEngraving: "",
      workflowActivitiesFixing: "",
      workflowActivitiesManufacturing: "",
      workflowActivitiesPhotos: "",
      workflowActivitiesPolish: "",
      workflowActivitiesResizing: "",
      workflowActivitiesSetting: "",
      workflowActivitiesValuation: "",
      workflowActivitiesReturn: "",
      expectedDate: "",
      givenDate: "",
      creating_workflow_sale: false,
      errorFormWorkflowActivities: false,
      showFormProvider: false,
      showOtherForm: false,
      providers: [],
      provider: [],
      disabledSave: true,
      workflowSales: [],
      validActivityDelivery: {
        showMessage: false,
        valid: false,
        message: "",
      },
    };

    this.closeEditionComponent = this.closeEditionComponent.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleChangeDateField = this.handleChangeDateField.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.startWorkflowProcess = this.startWorkflowProcess.bind(this);
  }

  closeEditionComponent() {
    this.props.disabledActionsBotons([]);

    this.props.closeEditionComponent();
  }

  closeModal() {
    this.props.disabledActionsBotons([]);

    this.props.closeWorkflowModal();

    this.props.closeEditionComponent();
  }

  handleChange(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.value;
    this.setState(state);
  }

  handleChangeCheckbox(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.checked;
    this.setState(state);

    setTimeout(() => {
      this.showFormProviderValidate();

      this.showOtherFormValidate();
    }, 100);
  }

  handleChangeDateField = (date, field_name) => {
    const state = {};
    if (date !== null && date !== "") {
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      state[field_name] = year + "-" + month + "-" + day;
    } else {
      state[field_name] = null;
    }
    this.setState(state);
  };

  handleChangeSelect(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.value;

    this.setState(state);
  }

  handleChangeProvider(event) {
    if (event.length <= 0) {
      this.setState({
        providerId: 0,
      });

      return;
    }

    this.setState({
      providerId: event[0].id,
    });
  }

  showFormProviderValidate = () => {
    this.setState({
      showFormProvider: false,
    });

    if (
      this.state.workflowActivitiesCAD === true ||
      this.state.workflowActivitiesEngraving === true ||
      this.state.workflowActivitiesFixing === true ||
      this.state.workflowActivitiesManufacturing === true ||
      this.state.workflowActivitiesPhotos === true ||
      this.state.workflowActivitiesPolish === true ||
      this.state.workflowActivitiesResizing === true ||
      this.state.workflowActivitiesSetting === true ||
      this.state.workflowActivitiesValuatio === true
    ) {
      this.setState({
        showFormProvider: true,
      });
    }
  };

  showOtherFormValidate = () => {
    this.setState({
      showOtherForm: false,
    });

    if (
      this.state.workflowActivitiesCAD === true ||
      this.state.workflowActivitiesEngraving === true ||
      this.state.workflowActivitiesFixing === true ||
      this.state.workflowActivitiesManufacturing === true ||
      this.state.workflowActivitiesPhotos === true ||
      this.state.workflowActivitiesPolish === true ||
      this.state.workflowActivitiesResizing === true ||
      this.state.workflowActivitiesSetting === true ||
      this.state.workflowActivitiesValuatio === true ||
      this.state.workflowActivitiesReturn === true
    ) {
      this.setState({
        showOtherForm: true,
      });
    }
  };

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    return (
      <div id="SaleEditionRequirementModal_component">
        <Modal
          show={true}
          onHide={this.closeModal}
          dialogClassName="modal-90w"
          backdrop="static"
          backdropClassName={
            this.state.showPanelInformation
              ? "modal-black-show-informacion-panel"
              : ""
          }
          className={
            this.state.showPanelInformation
              ? "modal-show-informacion-panel"
              : ""
          }
        >
          <Modal.Header closeButton>Workflow</Modal.Header>
          <Modal.Body>
            {this.state.creating_workflow_sale === true ? <LoadingLayer /> : ""}
            {
              <FlashMessages
                error_messages={this.state.error_messages}
                warning_messages={this.state.warning_messages}
                success_messages={this.state.success_messages}
              />
            }
            <Form>
              <Card className="mb-2">
                <Card.Body>
                  <div className="col-12">
                    <FormLabel>Workflow activities</FormLabel>
                    {this.state.errorFormWorkflowActivities === true ? (
                      <Alert key="warning" variant="warning">
                        The Workflow activities must have one or more sub
                        activities selected
                      </Alert>
                    ) : null}

                    {this.state.validActivityDelivery.showMessage ? (
                      <AlertMessage
                        type={"warning"}
                        message={this.state.validActivityDelivery.message}
                        autoClose={true}
                      />
                    ) : null}

                    <Form.Group className="form-group">
                      <Form.Check
                        type="checkbox"
                        name="workflowActivitiesCAD"
                        label="CAD"
                        checked={
                          this.state.workflowActivitiesCAD === true
                            ? true
                            : false
                        }
                        disabled={this.state.workflowActivitiesReturn}
                        onChange={this.handleChangeCheckbox}
                      />
                      <Form.Check
                        type="checkbox"
                        name="workflowActivitiesManufacturing"
                        label="Manufacturing"
                        checked={
                          this.state.workflowActivitiesManufacturing === true
                            ? true
                            : false
                        }
                        disabled={this.state.workflowActivitiesReturn}
                        onChange={this.handleChangeCheckbox}
                      />
                      <Form.Check
                        type="checkbox"
                        name="workflowActivitiesResizing"
                        label="Resizing"
                        checked={
                          this.state.workflowActivitiesResizing === true
                            ? true
                            : false
                        }
                        disabled={this.state.workflowActivitiesReturn}
                        onChange={this.handleChangeCheckbox}
                      />
                      <Form.Check
                        type="checkbox"
                        name="workflowActivitiesSetting"
                        label="Setting"
                        checked={
                          this.state.workflowActivitiesSetting === true
                            ? true
                            : false
                        }
                        disabled={this.state.workflowActivitiesReturn}
                        onChange={this.handleChangeCheckbox}
                      />
                      <Form.Check
                        type="checkbox"
                        name="workflowActivitiesEngraving"
                        label="Engraving"
                        checked={
                          this.state.workflowActivitiesEngraving === true
                            ? true
                            : false
                        }
                        disabled={this.state.workflowActivitiesReturn}
                        onChange={this.handleChangeCheckbox}
                      />
                      <Form.Check
                        type="checkbox"
                        name="workflowActivitiesValuation"
                        label="Appraisal"
                        checked={
                          this.state.workflowActivitiesValuation === true
                            ? true
                            : false
                        }
                        disabled={this.state.workflowActivitiesReturn}
                        onChange={this.handleChangeCheckbox}
                      />
                      <Form.Check
                        type="checkbox"
                        name="workflowActivitiesPolish"
                        label="Polish"
                        checked={
                          this.state.workflowActivitiesPolish === true
                            ? true
                            : false
                        }
                        disabled={this.state.workflowActivitiesReturn}
                        onChange={this.handleChangeCheckbox}
                      />
                      <Form.Check
                        type="checkbox"
                        name="workflowActivitiesFixing"
                        label="Fixing"
                        checked={
                          this.state.workflowActivitiesFixing === true
                            ? true
                            : false
                        }
                        disabled={this.state.workflowActivitiesReturn}
                        onChange={this.handleChangeCheckbox}
                      />
                      <Form.Check
                        type="checkbox"
                        name="workflowActivitiesPhotos"
                        label="Photos"
                        checked={
                          this.state.workflowActivitiesPhotos === true
                            ? true
                            : false
                        }
                        disabled={this.state.workflowActivitiesReturn}
                        onChange={this.handleChangeCheckbox}
                      />
                      {this.state.selectedSale.status ===
                        STATUS_COLLECTED_SHIPPED && (
                        <Form.Check
                          type="checkbox"
                          name="workflowActivitiesReturn"
                          label="Return"
                          checked={
                            this.state.workflowActivitiesReturn === true
                              ? true
                              : false
                          }
                          disabled={this.disabledReturn()}
                          onChange={this.handleChangeCheckbox}
                        />
                      )}
                      <Form.Check
                        type="checkbox"
                        name="workflowActivitiesDeliveringToCustomer"
                        label="Delivering to customer"
                        checked={
                          this.state.workflowActivitiesDeliveringToCustomer ===
                          true
                            ? true
                            : false
                        }
                        disabled={this.state.workflowActivitiesReturn}
                        onChange={this.handleChangeCheckbox}
                      />
                    </Form.Group>
                  </div>
                </Card.Body>
              </Card>

              {this.state.showOtherForm ? (
                <Card className="mb-2">
                  <Card.Body>
                    {this.state.showFormProvider && (
                      <div className="col-12">
                        <FormLabel className="d-block">Provider</FormLabel>
                        <Form.Group>
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="nameComplete"
                            options={this.state.providers}
                            placeholder="Choose a provider..."
                            defaultSelected={this.state.provider}
                            onChange={(e) => this.handleChangeProvider(e)}
                          />
                        </Form.Group>
                      </div>
                    )}

                    <div className="col-12">
                      <Form.Group className="form-group">
                        <FormLabel>
                          {this.state.workflowActivitiesReturn
                            ? "Date received from customer"
                            : "Date given to provider"}
                        </FormLabel>
                        <DateField
                          handleChangeDateField={this.handleChangeDateField}
                          field_name="givenDate"
                          currentDate={this.state.givenDate}
                        />
                      </Form.Group>
                    </div>

                    {this.state.showFormProvider && (
                      <div className="col-12">
                        <Form.Group className="form-group">
                          <FormLabel>Expected date from provider</FormLabel>
                          <DateField
                            handleChangeDateField={this.handleChangeDateField}
                            field_name="expectedDate"
                            currentDate={this.state.expectedDate}
                          />
                        </Form.Group>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              ) : null}

              <Button
                disabled={this.state.disabledSave}
                type="button"
                className="btn btn-default confirm-btn"
                onClick={this.startWorkflowProcess}
              >
                Start workflow process
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  disabledReturn = () => {
    if (
      this.state.workflowActivitiesCAD ||
      this.state.workflowActivitiesDeliveringToCustomer ||
      this.state.workflowActivitiesEngraving ||
      this.state.workflowActivitiesFixing ||
      this.state.workflowActivitiesManufacturing ||
      this.state.workflowActivitiesPhotos ||
      this.state.workflowActivitiesPolish ||
      this.state.workflowActivitiesResizing ||
      this.state.workflowActivitiesSetting ||
      this.state.workflowActivitiesValuation ||
      this.state.workflowActivitiesDeliveringToCustomer
    ) {
      return true;
    }

    return false;
  };

  startWorkflowProcess = async () => {
    this.setState({
      validActivityDelivery: {
        showMessage: false,
        valid: true,
        message: "",
      },
    });

    const {
      workflowActivitiesDeliveringToCustomer,
      selectedSale,
      workflowSales,
    } = this.state;

    const response = validateWorkflowActivityDelivering(
      workflowActivitiesDeliveringToCustomer,
      selectedSale,
      workflowSales
    );

    this.setDataValidActivityDelivery(false);

    if (response.valid) {
      return this.createWorkflowSale();
    }

    this.setDataValidActivityDelivery(true, response.message, response.valid);
  };

  setDataValidActivityDelivery = async (
    showMessage = true,
    message = "",
    valid = ""
  ) => {
    await Util.timeAwait(10);

    this.setState({
      validActivityDelivery: {
        showMessage: showMessage,
        valid: valid,
        message: message,
      },
    });
  };

  validSubActivity() {
    if (
      !this.state.workflowActivitiesCAD &&
      !this.state.workflowActivitiesDeliveringToCustomer &&
      !this.state.workflowActivitiesEngraving &&
      !this.state.workflowActivitiesFixing &&
      !this.state.workflowActivitiesManufacturing &&
      !this.state.workflowActivitiesPhotos &&
      !this.state.workflowActivitiesPolish &&
      !this.state.workflowActivitiesResizing &&
      !this.state.workflowActivitiesSetting &&
      !this.state.workflowActivitiesValuation &&
      !this.state.workflowActivitiesReturn
    ) {
      this.setState({
        errorFormWorkflowActivities: true,
      });
      return false;
    }

    this.setState({
      errorFormWorkflowActivities: false,
    });
    return true;
  }

  axioRequest = null;
  createWorkflowSale() {
    if (!this.validSubActivity()) {
      return true;
    }

    this.setState({
      creating_workflow_sale: true,
      error_messages: [],
    });
    const user = Auth.getAuthenticatedUser();
    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_SALE_CREATE;
      const params = new URLSearchParams();
      params.append("saleId", this.state.selectedSale.id);
      params.append(
        "workflowActivitiesManufacturing",
        this.state.workflowActivitiesManufacturing ?? false
      );

      if (this.state.providerId !== 0) {
        params.append("providerId", this.state.providerId);
      }

      params.append("providerExpectedDate", this.state.expectedDate);
      params.append("providerGivenDate", this.state.givenDate);

      params.append(
        "status",
        this.state.workflowActivitiesReturn ? "" : "In progress"
      );

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      axios
        .post(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              success_messages: ["Workflow sale created"],
              error_messages: [],
            });

            var created_workflow_sale = data.result;
            if (this.state.workflowActivitiesCAD) {
              this.createWorkflowActivity(created_workflow_sale.id, "CAD");
            }
            if (this.state.workflowActivitiesDeliveringToCustomer) {
              this.createWorkflowActivity(
                created_workflow_sale.id,
                "Delivering to customer"
              );
            }
            if (this.state.workflowActivitiesEngraving) {
              this.createWorkflowActivity(
                created_workflow_sale.id,
                "Engraving"
              );
            }
            if (this.state.workflowActivitiesFixing) {
              this.createWorkflowActivity(created_workflow_sale.id, "Fixing");
            }
            if (this.state.workflowActivitiesManufacturing) {
              this.createWorkflowActivity(
                created_workflow_sale.id,
                "Manufacturing"
              );
            }
            if (this.state.workflowActivitiesPhotos) {
              this.createWorkflowActivity(created_workflow_sale.id, "Photos");
            }
            if (this.state.workflowActivitiesPolish) {
              this.createWorkflowActivity(created_workflow_sale.id, "Polish");
            }
            if (this.state.workflowActivitiesResizing) {
              this.createWorkflowActivity(created_workflow_sale.id, "Resizing");
            }
            if (this.state.workflowActivitiesSetting) {
              this.createWorkflowActivity(created_workflow_sale.id, "Setting");
            }
            if (this.state.workflowActivitiesReturn) {
              this.createWorkflowActivity(created_workflow_sale.id, "Return");
            }
            if (this.state.workflowActivitiesValuation) {
              this.createWorkflowActivity(
                created_workflow_sale.id,
                "Appraisal"
              );
            }
          } else {
            this.setState({
              creating_workflow_sale: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              creating_workflow_sale: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              creating_workflow_sale: false,
              error_messages: error_messages,
            });
          } else {
            this.setState({
              creating_workflow_sale: true,
            });
          }

          return null;
        });
    }
  }

  axioRequest = null;
  createWorkflowActivity(workflowSaleId, type) {
    const user = Auth.getAuthenticatedUser();
    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      alert("Authentication error. Reload the page and try again.");
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_CREATE;
      const params = new URLSearchParams();
      params.append("workflowSaleId", workflowSaleId);
      params.append("type", type);
      params.append("readyToPayToProvider", false);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      axios
        .post(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              creating_workflow_sale: false,
            });

            this.closeModal();
          } else {
            console.log(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            if ("status" in err.response && err.response.status === 401) {
              alert("Not authorised.");
            } else {
              console.log(
                "There was a problem processing the information. Please reload this page and try again."
              );
            }
          } else if (err.request) {
            console.log(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }

          return null;
        });
    }
  }

  getJewellers = async () => {
    const jewellers = await FindJewelleyByContactName(this.state.providerId);

    this.setState({
      providers: jewellers?.items ?? [],
      provider: jewellers?.defaultProvider ?? [],
    });
  };

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  async componentDidMount() {
    //execute once the first time
    const workflowSales = await getWorkflowSale(this.state.selectedSale);

    this.setState({
      workflowSales: workflowSales,
      disabledSave: false,
    });

    if (this.props.showPanelInformation) {
      this.props.disabledActionsBotons(BTNS_DISABLES);
    }

    this.getJewellers();
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default SaleWorkflow;
