import React, { Component } from 'react';
import { Form } from "react-bootstrap"
import Util from '../../../../Util';

import '../../../../css/sales/sale_edit.scss'
import DividerDashed from '../../../Divider/DividerDashed';

const SHOW_INPUT_PROVIDER = [
    'salesModel',
    'saleDiamond',
    'workflowMetalModel',
    'workflowMeleeModel'
]

const SALE_MODEL = 'salesModel'
const SALE_DIAMOND_MODEL = 'saleDiamondModel'
const EMPTY = 'empty'
const TOTAL_COST = 'totalCost'
const DIAMOND_COST_CONCEPT = 'Diamond Cost'

class SaleCostsEditWorkflowRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            costs: props.costs
        }

        this.changes = []

        this.listProviders = Util.getStorageParameter("REACT_APP_PROVIDERS").split(',')
        this.listAccountingStatus = Util.getStorageParameter("REACT_APP_ACCOUNTING_STATUS").split(',')
    }

    //*****************************************
    //RENDER
    //*****************************************
    render() {
        return (
            this.state.costs.map((cost, index) => {
                this.changes[index] = false

                return this.renderRows(cost, index)
            })
        )
    }

    renderRows = (cost, index) => {
        let row = null

        this.changes[index] = false

        if (cost.model === EMPTY && cost.show) {
            row = this.renderEmptyTR(cost)
        }

        if (cost.model === TOTAL_COST && cost.show) {
            row = this.renderTotalTR(cost)
        }

        if (cost.model !== TOTAL_COST && cost.model !== EMPTY && cost.show) {            
            if (cost.concept === DIAMOND_COST_CONCEPT && this.props.sale.diamondDetails === null) {
                row = null
            } else {
                row = this.renderGeneralTR(cost, index)
            }
        }

        return row
    }

    renderEmptyTR = (cost) => {
        return (
            <tr key={cost.id + cost.model}>
                <td colSpan={9}>
                    <DividerDashed/>
                </td>
            </tr>
        )
    }

    renderGeneralTR = (cost, index) => {
        return (
            <tr key={cost.id + cost.model}>
                <td>
                    {cost.concept}
                </td>
                <td>
                    <span
                        dangerouslySetInnerHTML={
                            { __html: cost.details }
                        }>
                    </span>
                </td>
                <td>
                    {
                        (cost.model === SALE_MODEL || cost.model === SALE_DIAMOND_MODEL) ?
                            (
                                this.renderFormNumber(cost, index, 'cost')
                            ) :
                            (
                                <span>
                                    {cost.cost}
                                </span>
                            )
                    }
                </td>
                <td>
                    {
                        (
                            SHOW_INPUT_PROVIDER.includes(cost.model)
                        ) ?
                            (
                                this.renderProvider(cost, index)
                            ) :
                            (
                                <span>
                                    {cost.providerDetail}
                                </span>
                            )
                    }
                </td>
                <td>
                    {
                        (
                            SHOW_INPUT_PROVIDER.includes(cost.model)
                        ) ?
                            (
                                this.renderFormText(cost, index, 'providerInvoiceNumber')
                            ) :
                            (
                                <span>
                                    {cost.providerInvoiceNumber}
                                </span>
                            )
                    }
                </td>
                <td>
                    {
                        this.renderFormText(cost, index, 'internalInvoice')
                    }
                </td>
                <td>
                    {
                        cost.novaInvoice
                    }
                </td>
                <td>
                    {
                        this.renderFormSelectAccountingStatus(cost, index, 'accountingStatus')
                    }
                </td>
                {
                    this.renderCostReady(cost)
                }
            </tr >
        )
    }

    renderTotalTR = (cost) => {
        return (
            <tr key={cost.id + cost.model}>
                <td>
                    {cost.concept}
                </td>
                <td></td>
                <td className='text-align-end'>
                    {
                        Util.formatCurrency(cost.cost)
                    }
                </td>
                <td colSpan={6}></td>
            </tr>
        )
    }

    renderProvider = (cost, index) => {
        if (cost.providerDetail.length > 3 && !this.changes[index]) {
            return cost.providerDetail
        }

        if (cost.model === SALE_MODEL || cost.model === SALE_DIAMOND_MODEL) {
            return this.renderFormText(cost, index, 'provider')
        }

        return this.renderFormSelect(cost, index, 'provider')
    }

    renderFormText = (cost, index, name) => {
        return (
            <Form.Group className="form-group">
                <Form.Control
                    type="text"
                    name={name}
                    value={cost[name] ?? ''}
                    onChange={(e) => this.handleChange(e, index)}
                />
            </Form.Group>
        )
    }

    renderFormSelectAccountingStatus = (cost, index, name) => {
        return (
            <Form.Group>
                <Form.Select
                    name={name}
                    value={cost[name] ?? ''}
                    onChange={(e) => this.handleChange(e, index)}
                >
                    <option value=""></option>
                    {
                        this.listAccountingStatus.map(accountingStatus => {
                            return (
                                <option
                                    key={accountingStatus}
                                    value={accountingStatus}>
                                    {accountingStatus}
                                </option>
                            )
                        })
                    }
                </Form.Select>
            </Form.Group>
        )
    }

    renderFormNumber = (cost, index, name) => {
        return (
            <Form.Group className="form-group">
                <Form.Control
                    type="text"
                    name={name}
                    value={cost[name] ?? ''}
                    onChange={(e => { this.handleChangeNumber(e, index) })}
                />
            </Form.Group>
        )
    }

    renderFormSelect = (cost, index, name) => {
        return (
            <Form.Group>
                <Form.Select
                    onChange={(e) => this.handleChange(e, index)}
                    value={cost[name] ?? ''}
                    name={name}
                    required={true}
                >
                    <option value=""></option>
                    {
                        this.listProviders.map(provider => {
                            return (
                                <option
                                    key={provider}
                                    value={provider}>
                                    {provider}
                                </option>
                            )
                        })
                    }
                </Form.Select>
            </Form.Group>
        )
    }

    renderCostReady = (cost) => {
        if (cost.model === SALE_MODEL || cost.model === SALE_DIAMOND_MODEL) {
            return (
                <td>N/A</td>
            )
        }

        if (cost.costReady) {
            return (
                <td>
                    Yes
                </td>
            )
        }

        return (
            <td className='cost-ready-not'>
                No
            </td>
        )
    }

    handleChangeNumber = (event, index) => {
        let costs = this.state.costs
        let name = event.target.name
        let value = event.target.value.replace(/[^\d.]/g, '')

        costs[index][name] = value
        this.changes[index] = true

        this.activeSaveButton(false)

        this.setState({
            costs: costs
        })
    }

    handleChange = (event, index) => {
        let costs = this.state.costs
        let name = event.target.name
        let value = event.target.value

        costs[index][name] = value
        this.changes[index] = true

        this.activeSaveButton(false)

        this.setState({
            costs: costs
        })
        
        if (name === 'accountingStatus') {
            this.props.updateGeneralCostStatus(name);
        }
    }

    activeSaveButton = (active = true) => {
        this.props.parentActiveButton(active)

        this.props.parentResponseData(this.state.costs)
    }

    static getDerivedStateFromProps(props, state) {
        return{
            costs: props.costs,
        }
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

}

export default SaleCostsEditWorkflowRow;