import {
  Form,
  FormLabel,
  Button,
  Alert,
  Col,
  Card,
  Row,
} from "react-bootstrap";
import React, { Component } from "react";
import LoadingLayer from "../../LoadingLayer";
import FlashMessages from "../../FlashMessages";
import Auth from "../../../Auth";
import axios from "axios";
import DateField from "../formComponents/DateField";
import * as globalConst from "../../../global/const";
import moment from "moment";

// SCSS
import "../../../css/sales/sale_edit.scss";
import { FindEmployeSales } from "../../actions/EmployeFind";
import LoadingSpinnerLayer from "../../LoadingSpinnerLayer";
import {
  employeeIsPaidDate,
  isEmployeeRemoved,
} from "../../actions/ActionEmployeSale";
import FormSelectMultiple from "../../Form/FormSelectMultiple";
import {
  validateCommissionWasPaid,
  validateCommissionWasPaidReviews,
} from "./Acctions/SaleCommission";
import FormSelectCustomer from "../../Form/FormSelectCustomer";
import Util from "../../../Util";
import FormDatePicker from "../../Form/FormDatePicker";
import FormInputCheckbox from "../../Form/FormInputCheckbox";
import FieldSaleURLReviewLink from "./Form/FieldSaleURLReviewLink";

const REVIEW_REQUEST_STATUS_SEND = "Sent";
const REVIEW_STATUS_PENDIENG = "Pending";
const REVIEW_STATUS_RECEIVED = "Received";
const NAME_REVIEW_REQUEST_INPUT = "customerLeaveTrustpilotReview";
const NAME_REVIEW_STATUS_INPUT = "customerReviewStatus";
const ERROR_GENERAL_MESSAGE =
  "There was a problem processing the information. Please reload this page and try again.";
const ERROR_REQUEST_MESSAGE =
  "There was a problem with your request. Please reload this page and try again.";

class SaleSalesMarketingEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sale: props.sale,
      soldFrom: props.sale.soldFrom ?? "",
      city: props.sale.city ?? "",
      salesRepList: [],
      showCustomerReviewStatus: true,
      customerReviewStatus: props.sale.customerReviewStatus ?? null,
      customerReviewDate: props.sale.customerReviewDate ?? null,
      customerReviewUrl: props.sale.customerReviewUrl ?? null,
      customerReviewScreenshotUploaded:
        props.sale.customerReviewScreenshotUploaded ?? false,
      salesRepName: props.sale.salesRepName ?? "",
      websiteSalesRep: props.sale.websiteSalesRep ?? "",
      reviewRequestDateSent: props.sale.reviewRequestDateSent,
      customerLeaveTrustpilotReview: props.sale.customerLeaveTrustpilotReview,
      howYouFindAboutUs: props.sale.howYouFindAboutUs,
      error_messages: [],
      warning_messages: [],
      success_messages: [],
      updating_sale: false,
      fields_no_updated: true,
      sales_rep_modified: false,
      loadDataEmployee: true,
      employeeSales: [],
      disabledSaleRepNameMultiple: false,
      showErrorRemoveEmployee: false,
      messageErrorRemoveEmployee: "",
      validated: false,
    };

    this.salesRepNameSave = this.state.salesRepName;
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.editSale = this.editSale.bind(this);
    this.getSalesRepresentatives = this.getSalesRepresentatives.bind(this);
    this.createNewTaskSalesRep = this.createNewTaskSalesRep.bind(this);

    this.loadListInformation();
  }

  loadListInformation = () => {
    this.listReviewStatus =
      Util.getStorageParameter("REACT_APP_MARKETING_REVIEW_STATUS").split(",");

    this.soldFromList = Util.getStorageParameter("REACT_APP_LIST_SOLD_FORM").split(",");

    const reviewStatusLists =
      Util.getStorageParameter("REACT_APP_LIST_STATUS_REVIEW").split(",");

    this.reviewStatusLists = [
      {
        value: "",
        customerReviewStatus: "",
      },
    ];

    reviewStatusLists.forEach((reviewStatusList) => {
      this.reviewStatusLists.push({
        value: reviewStatusList,
        customerReviewStatus: reviewStatusList,
      });
    });
  };

  handleChange(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.value;
    if (event.target.value !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("marketing", true);
    }

    if (field_name === NAME_REVIEW_REQUEST_INPUT) {
      this.changeCustomerReviewStatus(state[field_name]);
    }

    this.setState(state);
  }

  changeCustomerReviewStatus = async (value) => {
    if (this.state.customerReviewStatus) {
      return;
    }

    this.setState({
      showCustomerReviewStatus: false,
    });

    this.setReviewStatus("");

    if (value === REVIEW_REQUEST_STATUS_SEND) {
      this.setReviewStatus(REVIEW_STATUS_PENDIENG);
    }

    await Util.timeAwait();

    this.setState({
      showCustomerReviewStatus: true,
    });
  };

  handleChangeSelect(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.value;
    if (event.target.value !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("marketing", true);
    }

    if (field_name === "salesRepName") {
      state["sales_rep_modified"] = true;
    }

    this.setState(state);
  }

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    const sale = this.state.sale;

    const commissionWasPaid = validateCommissionWasPaid(sale);
    const commissionWasPaidReviews = validateCommissionWasPaidReviews(sale);

    let reviewRequestDateSent_date = "";

    if (sale.reviewRequestDateSent) {
      const reviewRequestDateSent_string = sale.reviewRequestDateSent.substring(
        0,
        10
      );

      reviewRequestDateSent_date = new Date(
        moment(reviewRequestDateSent_string)
      );

      if (isNaN(reviewRequestDateSent_date)) {
        reviewRequestDateSent_date = "";
      }
    }

    return (
      <div id="SaleSalesMarketingEdit_component">
        {this.state.showErrorRemoveEmployee ? (
          <Alert
            variant="warning"
            onClose={() => {
              this.setState({
                showErrorRemoveEmployee: false,
              });
            }}
            dismissible
          >
            <p>{this.state.messageErrorRemoveEmployee}</p>
          </Alert>
        ) : null}
        {this.state.updating_sale === true ? <LoadingLayer /> : ""}
        {
          <FlashMessages
            error_messages={this.state.error_messages}
            warning_messages={this.state.warning_messages}
            success_messages={this.state.success_messages}
          />
        }
        <Form
          noValidate
          validated={this.state.validated}
          onSubmit={this.editSale}
        >
          <div className="row">
            <div className="col-6">
              <Form.Group className="form-group">
                <FormLabel>Sold from</FormLabel>

                <FormSelectMultiple
                  options={this.soldFromList}
                  onChange={this.changeSelectMultiple}
                  nameField="soldFrom"
                  data={this.state.soldFrom ?? ""}
                  labelKey={"name"}
                />
              </Form.Group>
            </div>
            <div className="col-6">
              <Form.Group className="form-group">
                <FormLabel>Selling City</FormLabel>
                <Form.Control
                  type="text"
                  name="city"
                  value={this.state.city === null ? "" : this.state.city}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </div>
            <div className="col-6">
              <Form.Group className="form-group">
                <FormLabel>Sales Rep Name</FormLabel>
                {this.state.loadDataEmployee ? (
                  <LoadingSpinnerLayer />
                ) : (
                  <FormSelectMultiple
                    options={this.state.employeeSales}
                    onChange={this.changeSaleRapName}
                    nameField="salesRepName"
                    data={this.state.salesRepName ?? ""}
                    labelKey={"name"}
                    disabled={
                      this.state.disabledSaleRepNameMultiple ||
                      commissionWasPaid
                    }
                  />
                )}
              </Form.Group>
            </div>
            <div className="col-6">
              <Form.Group className="form-group">
                <FormLabel>How did you find about us?</FormLabel>
                <Form.Control
                  type="text"
                  name="howYouFindAboutUs"
                  value={
                    this.state.howYouFindAboutUs === null
                      ? ""
                      : this.state.howYouFindAboutUs
                  }
                  onChange={this.handleChange}
                />
              </Form.Group>
            </div>
            <div className="col-6">
              <Form.Group className="form-group">
                <FormLabel>Sales rep as reported in website</FormLabel>
                <Form.Control
                  type="text"
                  name="websiteSalesRep"
                  value={this.state.websiteSalesRep ?? ""}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </div>

            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>Customer review information</Card.Title>
                    <Row>
                      <Col xs={6} lg={6}>
                        <Form.Group className="form-group">
                          <FormLabel>Review request status</FormLabel>
                          <Form.Select
                            onChange={(e) => this.handleChange(e)}
                            value={
                              this.state.customerLeaveTrustpilotReview ?? ""
                            }
                            name="customerLeaveTrustpilotReview"
                          >
                            <option value=""></option>
                            {this.listReviewStatus.map((listReviewStatus) => {
                              return (
                                <option
                                  key={listReviewStatus}
                                  value={listReviewStatus}
                                >
                                  {listReviewStatus}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col xs={6} lg={6}>
                        <Form.Group className="form-group">
                          <FormLabel>Review request sent on</FormLabel>
                          <DateField
                            handleChangeDateField={this.handleChangeDateField}
                            field_name="reviewRequestDateSent"
                            currentDate={reviewRequestDateSent_date}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="mt-1">
                      <Col xs={6} lg={6}>
                        {this.state.showCustomerReviewStatus && (
                          <FormSelectCustomer
                            label="Review Status"
                            nameInput="customerReviewStatus"
                            items={this.reviewStatusLists}
                            keyValue={"value"}
                            value={this.state.customerReviewStatus ?? ""}
                            columnsSize={12}
                            onResponse={this.onChangeSelect}
                            showOptionEmpty={false}
                            disabled={commissionWasPaidReviews}
                          />
                        )}
                      </Col>
                      <Col xs={6} lg={6}>
                        {this.state.customerReviewStatus ===
                          REVIEW_STATUS_RECEIVED && (
                          <FormDatePicker
                            label="Review received from customer on"
                            nameInput="customerReviewDate"
                            value={this.state.customerReviewDate ?? ""}
                            onResponse={this.handleChangeInput}
                            required={true}
                            columnsSize={12}
                            disabled={commissionWasPaidReviews}
                          />
                        )}
                      </Col>
                    </Row>

                    <Row className="mt-1">
                      <Col xs={6} lg={6}>
                        {this.state.customerReviewStatus ===
                          REVIEW_STATUS_RECEIVED && (
                          <FieldSaleURLReviewLink
                            urls={this.state.customerReviewUrl ?? ""}
                            commissionWasPaid={commissionWasPaidReviews}
                            onResponse={this.changeReviewURL}
                          />
                        )}
                      </Col>
                      <Col xs={6} lg={6}>
                        {this.state.customerReviewStatus ===
                          REVIEW_STATUS_RECEIVED && (
                          <FormInputCheckbox
                            typeView="checkbox"
                            type="checkbox"
                            label="Screenshot uploaded"
                            nameInput="customerReviewScreenshotUploaded"
                            checked={
                              this.state.customerReviewScreenshotUploaded ??
                              false
                            }
                            required={true}
                            onResponse={this.handleChangeInput}
                            disabled={commissionWasPaidReviews}
                          />
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>

          <Button
            className="mt-1"
            type="submit"
            disabled={this.state.updating_sale || this.state.fields_no_updated}
          >
            Save Sales and Marketing Information
          </Button>
        </Form>
      </div>
    );
  }

  changeSelectMultiple = (selected, name) => {
    if (selected === this.state[name]) {
      return;
    }

    this.setState({
      [name]: selected,
      fields_no_updated: false,
    });

    this.props.handleTabChange("marketing", true);
  };

  handleChangeInput = async (value, name) => {
    this.setState({
      [name]: value,
      fields_no_updated: false,
    });

    this.props.handleTabChange("marketing", true);
  };

  changeReviewURL = (selected) => {
    if (selected === this.state.customerReviewUrl) {
      return;
    }

    this.setState({
      customerReviewUrl: selected,
      fields_no_updated: false,
    });

    this.props.handleTabChange("marketing", true);
  };

  changeSaleRapName = async (selected, name) => {
    if (selected === this.state.salesRepName) {
      return;
    }

    const removes = isEmployeeRemoved(this.salesRepNameSave, selected);

    if (removes.length > 0) {
      this.setState({
        disabledSaleRepNameMultiple: true,
        loadDataEmployee: true,
        showErrorRemoveEmployee: false,
        messageErrorRemoveEmployee: "",
      });

      const isPaidDate = await employeeIsPaidDate(
        this.state.sale.id,
        removes[0],
        globalConst.SALE_COMMISSION_TYPE_SALES
      );

      if (isPaidDate) {
        this.setState({
          [name]: this.state.salesRepName,
          disabledSaleRepNameMultiple: false,
          loadDataEmployee: false,
          showErrorRemoveEmployee: true,
          messageErrorRemoveEmployee: `Cannot delete the employee ${removes[0]} since the paid date was already confirmed`,
        });

        return;
      }
    }

    this.setState({
      [name]: selected,
      fields_no_updated: false,
      disabledSaleRepNameMultiple: false,
      loadDataEmployee: false,
    });

    this.props.handleTabChange("marketing", true);
  };

  onChangeSelect = (value, name) => {
    if (!value) {
      return;
    }

    const data = JSON.parse(value);

    this.setState({
      [name]: data?.value ?? "",
      fields_no_updated: false,
    });

    if (name === NAME_REVIEW_STATUS_INPUT && value !== REVIEW_STATUS_RECEIVED) {
      this.setState({
        customerReviewDate: null,
        customerReviewUrl: "",
        customerReviewScreenshotUploaded: false,
      });
    }

    this.props.handleTabChange("marketing", true);
  };

  handleChangeDateField = (date, field_name) => {
    const state = {};

    if (date !== null && date !== "") {
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      state[field_name] = year + "-" + month + "-" + day;

      this.setReviewRequestStatus();
    } else {
      state[field_name] = null;
    }

    if (date !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("marketing", true);
    }

    this.setState(state);
  };

  setReviewRequestStatus = () => {
    this.setState({
      customerLeaveTrustpilotReview: REVIEW_REQUEST_STATUS_SEND,
    });
  };

  setReviewStatus = (value) => {
    this.setState({
      customerReviewStatus: value,
    });
  };

  getEmployeeSales = async () => {
    const employees = await FindEmployeSales();

    const employeesArray = employees.map((employee) => {
      return employee.name;
    });

    this.setState({
      employeeSales: employeesArray,
      loadDataEmployee: false,
    });
  };

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  static getDerivedStateFromProps(props, state) {
    //called right before rendering the first time or before shouldComponentUpdate in case it was already rendered
    //return values for the state
    return {
      sale: props.sale,
    };
  }

  componentDidMount() {
    //execute once the first time
    this.getSalesRepresentatives();
    this.getEmployeeSales();
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************

  axioRequest = null;
  editSale(event) {
    event.preventDefault();

    const form = event.currentTarget;
    const check = form.checkValidity();

    if (!check) {
      this.setState({
        validated: true,
      });

      return;
    }

    this.setState({
      updating_sale: true,
    });
    const user = Auth.getAuthenticatedUser();
    const sale = this.props.sale;
    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else if (sale === null || sale === undefined) {
      this.setState({
        error_messages: [
          "Sale identification error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_SALES_EDIT;
      url = url.replace("{id}", sale.id);
      const params = new URLSearchParams();
      params.append("soldFrom", this.state.soldFrom);
      params.append("city", this.state.city);
      params.append("salesRepName", this.state.salesRepName);
      params.append("websiteSalesRep", this.state.websiteSalesRep);
      params.append("reviewRequestDateSent", this.state.reviewRequestDateSent);
      params.append(
        "customerLeaveTrustpilotReview",
        this.state.customerLeaveTrustpilotReview
      );
      params.append("howYouFindAboutUs", this.state.howYouFindAboutUs);

      params.append(
        "customerReviewStatus",
        this.state.customerReviewStatus ?? null
      );
      params.append(
        "customerReviewDate",
        this.state.customerReviewDate ?? null
      );
      params.append("customerReviewUrl", this.state.customerReviewUrl ?? null);
      params.append(
        "customerReviewScreenshotUploaded",
        this.state.customerReviewScreenshotUploaded ?? null
      );

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      axios
        .put(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              updating_sale: false,
              success_messages: ["Sale updated"],
              fields_no_updated: true,
            });
            this.props.handleTabChange("marketing", false);
            this.props.saleUpdated(data.result);

            this.salesRepNameSave = this.state.salesRepName;

            if (this.state.sales_rep_modified) {
              this.createNewTaskSalesRep();
            }
          } else {
            this.setState({
              updating_sale: false,
              error_messages: [ERROR_GENERAL_MESSAGE],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            }
            if ("status" in err.response && err.response.status === 400) {
              const { response } = err;
              const { data } = response;
              const { error } = data;

              error_messages = [error ?? ERROR_GENERAL_MESSAGE];
            } else {
              error_messages = [ERROR_GENERAL_MESSAGE];
            }
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [ERROR_GENERAL_MESSAGE];
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else {
            this.setState({
              updating_sale: true,
            });
          }
          return null;
        });
    }
  }

  getSalesRepresentatives() {
    let url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_EMPLOYEE_LIST;
    const params = new URLSearchParams();
    params.append("sales_rep", true);
    params.append("order_by", "name");
    params.append("order_type", "ASC");
    params.append("roles", "ROLE_SALES");

    axios
      .get(url, {
        params: params,
        headers: { Authorization: `Bearer ${Auth.getToken()}` },
      })
      .then((response) => {
        const data = response.data;
        if (data !== undefined && data.code === 200) {
          let salesRep = data.result.rows;
          this.setState({
            salesRepList: salesRep,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log(err);
        }
        this.setState({
          loading_excel: false,
        });
        return null;
      });
  }

  createNewTaskSalesRep() {
    try {
      let assignedById;
      const user = Auth.getAuthenticatedUser();
      if ("employeeModel" in user) {
        if (user.employeeModel !== null) {
          assignedById = user.employeeModel.id;
        }
      }

      let employeeId = this.state.salesRepList.filter(
        (saleRep) => saleRep.name === this.state.salesRepName
      )[0].id;
      const dueDate = new Date();
      dueDate.setDate(dueDate.getDate() + 7);
      const dueDateString = dueDate.toISOString().substring(0, 10);

      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_EMPLOYEE_TASK_CREATE;

      let params = new URLSearchParams();
      params.append("employeeId", employeeId);
      params.append(
        "description",
        "New sale #" + this.state.sale.onlineOrderNumber
      );
      params.append("employeeTaskStatusId", 2); //Assigned
      params.append("dueDate", dueDateString);
      params.append("assignedById", assignedById);

      let config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      axios
        .post(url, params, config)
        .then((api_response) => {
          const data = api_response.data;
          if (data !== undefined && data.code === 200) {
            this.createSalesEmployeeTask(data.result.id);
          } else {
            alert("error");
            return null;
          }
        })
        .catch((err) => {
          if (err.response) {
            if ("status" in err.response && err.response.status === 401) {
              alert("User not authorised. Please reload the page");
            } else {
              alert(ERROR_GENERAL_MESSAGE);
            }
          } else if (err.request) {
            alert(ERROR_REQUEST_MESSAGE);
          }
          return null;
        });
    } catch (e) {
      console.log(e);
      alert("There was a problem with the creation of the new task");
    }
  }

  createSalesEmployeeTask(employee_task_id) {
    let url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_SALES_EMPLOYEE_TASK_CREATE;

    if (url) {
      //let sale_edition_id = this.props.sale_edition_assign_task_selected_id;

      let params = new URLSearchParams();
      params.append("salesId", this.state.sale.id);
      params.append("employeeTaskId", employee_task_id);

      let config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      axios
        .post(url, params, config)
        .then((api_response) => {
          const data = api_response.data;
          if (data !== undefined && data.code === 200) {
            //this.updateSaleEditionRequirement();
            window.location.reload();
          } else {
            alert("error");
          }
        })
        .catch((err) => {
          if (err.response) {
            if ("status" in err.response && err.response.status === 401) {
              alert("User not authorised. Please reload the page");
            } else {
              alert(ERROR_GENERAL_MESSAGE);
            }
          } else if (err.request) {
            alert(ERROR_REQUEST_MESSAGE);
          }
          return null;
        });
    }
  }
}

export default SaleSalesMarketingEdit;
