import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import FormSelectCustomer from "../../../../Form/FormSelectCustomer";
import FormInputText from "../../../../Form/FormInputText";
import Util from "../../../../../Util";
import { Typeahead } from "react-bootstrap-typeahead";

const AddElementTag = ({customisationTypesItems, setCustomisationTypesItems}) => {
    const [customisationType, setCustomisationType] = useState(null);
    const [customisationSubType, setCustomisationSubType] = useState(null);
    const [otherText, setOtherText] = useState(null);
    const [customisationSubTypesList, setCustomisationSubTypesList] = useState([]);
    const [addItem, setAddItem] = useState(false);
    const [textType, setTextType] = useState(false);
    const [showCustomisationSubTypes, setShowCustomisationSubTypes] = useState(true);
    const [disabledAddElement, setDisabledAddElement] = useState(true);

    const customisationTypesList = JSON.parse(
        Util.getStorageParameter("REACT_APP_CUSTOMISATION_TYPES_OPTIONS")
    );

    const onChangeCustomisationType = (value) => {
        if (!value) {
            handleClear();
            return;
        }
        const data = JSON.parse(value);
        const { id, name, textType } = data;

        if (name === customisationType) {
            return;
        }

        setCustomisationType(name);
        setTextType(textType);
        setShowCustomisationSubTypes(!textType);

        if (!textType) {
            const subTypes = getCustomisationSubTypesOptions(id);
            setCustomisationSubTypesList(subTypes);
            
            // Check if the current subType is 'Other' and set textType accordingly
            if (customisationSubType === 'Other') {
                setTextType(true);
            }
        } else {
            setCustomisationSubType(null);
            setCustomisationSubTypesList([]);
        }
    };

    const onChangeCustomisationSubType = (value) => {
        setDisabledAddElement(true);
        if (!value) {
            setCustomisationSubType(null);
            return;
        }
        const data = JSON.parse(value);
        const { name, id } = data;

        if (name === customisationSubType) {
            return;
        }
        setCustomisationSubType(name);
        setTextType(id === 'other');
        setOtherText(null);

        if (id !== 'other') {
            setDisabledAddElement(false);
        }
    };

    const getCustomisationSubTypesOptions = (id) => {
        const optionsMapping = {
            band_metal: Util.getStorageParameter("REACT_APP_CUSTOMISATION_SUB_TYPES_OPTIONS_BAND_METAL"),
            claw_setting_metal: Util.getStorageParameter("REACT_APP_CUSTOMISATION_SUB_TYPES_OPTIONS_CLAW_SETTING_METAL"),
            claw_style: Util.getStorageParameter("REACT_APP_CUSTOMISATION_SUB_TYPES_OPTIONS_CLAW_STYLE"),
            claw_type: Util.getStorageParameter("REACT_APP_CUSTOMISATION_SUB_TYPES_OPTIONS_CLAW_TYPE"),
            profile: Util.getStorageParameter("REACT_APP_CUSTOMISATION_SUB_TYPES_OPTIONS_PROFILE"),
            prongs_quantity: Util.getStorageParameter("REACT_APP_CUSTOMISATION_SUB_TYPES_OPTIONS_PRONGS"),
            ring_style: Util.getStorageParameter("REACT_APP_CUSTOMISATION_SUB_TYPES_OPTIONS_RING_STYLE"),
            side_diamonds: Util.getStorageParameter("REACT_APP_CUSTOMISATION_SUB_TYPES_OPTIONS_SIDE_DIAMONDS"),
        };

        const option = optionsMapping[id];
        return option ? JSON.parse(option) : [];
    }

    const onChangeInput = (value) => {
        setDisabledAddElement(true);
        if (value === otherText) {
            return "";
        }
        setOtherText(value);
        if (value) {
            setDisabledAddElement(false);
        }
    };

    const addElement = () => {
        setAddItem(true);
    };    

    useEffect(() => {
        if (!addItem) return;
        let subTypeValue = (otherText || customisationSubType);
        const type = customisationType === 'Reference URL' ? 'url' : 'text';

        const value = `${customisationType}: ${subTypeValue}, type:${type}`;
        const data = { value };

        const isDuplicate = customisationTypesItems.some(item => item.value === value);

        if (!isDuplicate) {
            const newItems = [...customisationTypesItems, data];
            setCustomisationTypesItems(newItems);
        }
        handleClear();
    }, [addItem]);

    const handleClear = () => {
        setCustomisationType(null);
        setCustomisationSubType(null);
        setCustomisationSubTypesList([]);
        setOtherText(null);
        setTextType(false);
        setAddItem(false);
        setDisabledAddElement(true);
        setShowCustomisationSubTypes(true);
    }

    const handleResponse = (selected) => {
        setCustomisationTypesItems(selected);
    };

    const renderTags = () => {
        return (
            <Row className="mt-3">
                <Typeahead
                    id={"tags"}
                    labelKey={"value"}
                    multiple
                    onChange={handleResponse}
                    options={[]}
                    selected={customisationTypesItems}
                    open={false}
                    renderToken={(option, props, idx) => {
                        const { onRemove } = props;
                        const value = option.value.replace(/,\s*type:\w+$/, '');
                        return (
                            <div className="rbt-token rbt-token-removeable" key={idx}>
                                <div
                                    className="rbt-token-label"
                                    dangerouslySetInnerHTML={{
                                        __html: Util.textIsUrl(value),
                                    }}
                                ></div>
                                <button
                                    aria-label="Remove"
                                    className="close btn-close rbt-close rbt-token-remove-button"
                                    type="button"
                                    onClick={() => onRemove(option)}
                                >
                                    <span aria-hidden="true" className="rbt-close-content">&times;</span>
                                </button>
                            </div>
                        );
                    }}
                />
            </Row>
        );
    }

    return (
        <>
            <Row>
                {/* Customisation Type Dropdown */}
                <Col xs={12} lg={(customisationSubType === 'Other') ? 3 : 5}>
                    {!addItem && (
                        <FormSelectCustomer
                            label=""
                            nameInput="name"
                            items={customisationTypesList}
                            keyValue={"id"}
                            value={customisationType ?? ""}
                            onResponse={onChangeCustomisationType}
                            columnsSize={12}
                        />)}
                </Col>

                {/* Customisation SubType Dropdown */}
                {!addItem && showCustomisationSubTypes &&
                    <Col xs={12} lg={(customisationSubType === 'Other') ? 3 : 5}>
                        <FormSelectCustomer
                            label=""
                            nameInput="name"
                            items={customisationSubTypesList}
                            keyValue={"id"}
                            value={customisationSubType ?? ""}
                            onResponse={onChangeCustomisationSubType}
                            columnsSize={12}
                            disabled={!customisationType}
                        />
                    </Col>
                }

                {/* Text Input for 'Other' SubType */}
                {textType &&
                    <Col xs={12} lg={(customisationSubType === 'Other') ? 4 : 5}>
                        <FormInputText
                            label=""
                            nameInput="otherText"
                            value={otherText ?? ""}
                            onResponse={onChangeInput}
                            columnsSize={12}
                        />
                    </Col>
                }

                {/* Add Button */}
                <Col xs={12} lg={2}>
                    <br />
                    <Button
                        variant="primary"
                        onClick={addElement}
                        disabled={disabledAddElement}
                    >
                        Add
                    </Button>
                </Col>
            </Row>
            {renderTags()}
        </>
    )
}
export default AddElementTag;