import axiosServices from "../../../axios";

export const fetchAppointmentsReportExcel = async (args) => {
  const { startDate, endDate, employee } = args;
  try {
    const response = await axiosServices.post(
      `/api/v1/reports/resultAnalysisDetails/per-employee`,
      {
        startDate: startDate,
        endDate: endDate,
        employee: employee,
        downloadExcel: true,
      },
      {
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const fetchCommissionsReportExcel = async (args) => {
  const {
    startDate,
    endDate,
    startDatePaid,
    endDatePaid,
    employee,
    typeOfReport,
  } = args;
  try {
    const response = await axiosServices.post(
      `/api/v1/reports/resultCommissionsAnalysis`,
      {
        startDate: startDate,
        endDate: endDate,
        startDatePaid: startDatePaid,
        endDatePaid: endDatePaid,
        employee: employee,
        typeOfReport: typeOfReport,
        downloadExcel: true,
      },
      {
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const fetchResultAppointmentsEfficiency = async (args) => {
  const { startDate, endDate, employee } = args;
  try {
    const response = await axiosServices.post(
      `/api/v1/reports/result-analysis-details/excel`,
      {
        startDate: startDate,
        endDate: endDate,
        employee: employee,
      },
      {
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      }
    );

    return response.data;
  } catch (error) {
    return [];
  }
};
