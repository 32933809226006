import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  API_STATUS_PENDING,
  APPOINTMENTS_LIST_TYPE_DEFAULT,
  APPOINTMENTS_LIST_TYPE_SEARCH,
} from "../../../store/constants";
import {
  addAppointmentCustomizedLists,
  batchAddAppointmentCustomizedLists,
  removeAppointmentCustomizedLists,
} from "../../../store/slices";
import AppointmentsListHeader from "./header";
import LoadingLayer from "../../LoadingLayer";
import { formatAppointmentCreationDate } from "../../../utils/dateUtils";

export default function DefaultList({
  loadedCount,
  hasMore,
  onNextPage,
  onNextPageFilter,
  appointmentListType,
  filterChanged,
  appointments,
  selectedAppointment,
  onSelected,
  extractEmployeeNames,
}) {
  const dispatch = useDispatch();
  const [selectedList, setSelectedList] = useState("");
  const [selectedAppointmentIds, setSelectedAppointmentIds] = useState([]);

  const { listAppointments, lists, status } = useSelector(
    (state) => state.appointmentsCustomizedLists
  );

  const handleCheckAll = (e) => {
    // Check list type
    if (selectedList.length <= 0) {
      alert(
        "You need to select a personalised list from the dropdown to add elements to it."
      );
      return;
    }

    const ccs = [...appointments.map((e) => e.id)];

    const selected = getSelectedList();
    if (selected) {
      dispatch(
        batchAddAppointmentCustomizedLists({
          listId: selected.id,
          customerAppointmentIds: ccs,
        })
      );
    } else {
      setSelectedAppointmentIds(ccs);
    }
  };

  const getSelectedList = () => {
    const selected = lists.filter((e) => e.name === selectedList);
    if (selected.length > 0) {
      return selected[0];
    }
    return null;
  };

  useEffect(() => {
    const selected = getSelectedList();
    if (selected) {
      const ccs = listAppointments[selected.id];
      setSelectedAppointmentIds(ccs.map((e) => e.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList, lists, listAppointments]);

  const handleListChange = (e) => {
    setSelectedAppointmentIds([]);
    setSelectedList(e.target.value);
  };

  const handleAppointmentSelectChange = (appointment, e) => {
    const selected = getSelectedList();
    if (e.target.checked) {
      if (selected) {
        dispatch(
          addAppointmentCustomizedLists({
            listId: selected.id,
            customerAppointmentId: appointment.id,
          })
        );
      } else {
        setSelectedAppointmentIds((selectedAppointmentIds) => [
          ...selectedAppointmentIds,
          appointment.id,
        ]);
      }
    } else {
      if (selected) {
        dispatch(
          removeAppointmentCustomizedLists({
            listId: selected.id,
            customerAppointmentId: appointment.id,
          })
        );
      } else {
        setSelectedAppointmentIds((selectedAppointmentIds) => [
          ...selectedAppointmentIds.filter((e) => e !== appointment.id),
        ]);
      }
    }
  };

  const statusAsSlug = (status) => {
    let status_as_slug = "";
    if (typeof status === "string" || status instanceof String) {
      status = status.toLowerCase();
      if (status === "cancelled") {
        status_as_slug = "cancelled";
      } else if (status === "customer decided to buy") {
        status_as_slug = "decided_to_buy";
      } else if (status === "customer decided not to buy") {
        status_as_slug = "decided_not_to_buy";
      } else if (status === "following up") {
        status_as_slug = "following_up";
      }

      status_as_slug = "status_coloured_box " + status_as_slug;
    }
    return status_as_slug;
  };

  return (
    <InfiniteScroll
      dataLength={loadedCount}
      next={() => {
        if (appointmentListType === APPOINTMENTS_LIST_TYPE_DEFAULT) {
          onNextPage();
        }
        if (appointmentListType === APPOINTMENTS_LIST_TYPE_SEARCH) {
          onNextPageFilter();
        }
      }}
      hasMore={hasMore}
      height={"calc(110vh - 200px)"}
    >
      {status === API_STATUS_PENDING && <LoadingLayer />}
      <table
        className="table table-striped table-sm table-hover overflow-scroll"
        id="table_sales"
      >
        <AppointmentsListHeader
          selectedList={selectedList}
          handleCheckAll={handleCheckAll}
          handleListChange={handleListChange}
          hasFilters={true}
          onFilterChange={filterChanged}
        />
        <tbody className="overflow-scroll">
          {appointments.map((appointment, index) => {
            let customer = appointment.customer;
            return (
              <tr
                className={`customer_row ${
                  selectedAppointment?.id === appointment.id ? "highlighted_row" : ""
                }`}
                key={appointment.id}
                onClick={() => onSelected(appointment)}
              >
                <td>
                  <div className="form-check custom-from-check">
                    <input
                      type="checkbox"
                      id="customer_check"
                      name="customer_check"
                      className="advanced_filter_empty_customer_name"
                      checked={selectedAppointmentIds.includes(appointment.id)}
                      onChange={(e) => {
                        handleAppointmentSelectChange(appointment, e);
                      }}
                    />
                  </div>
                </td>
                <td>{formatAppointmentCreationDate(appointment.appointmentDate)}</td>
                <td>{appointment.type}</td>
                <td>{customer.name}</td>
                <td>{customer.email}</td>
                <td>{customer.phone}</td>
                <td>
                  {
                    appointment.status ?
                    (
                      <>
                        <div className={statusAsSlug(appointment.status)}/>
                        {appointment.status}
                      </>
                    ) : ''
                  }
                </td>
                <td>{extractEmployeeNames(appointment)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </InfiniteScroll>
  );
}
