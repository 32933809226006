import React, { Component } from "react";
import Auth from "../../../Auth";
import Util from "../../../Util";

const MANUFACTURING = "Manufacturing";

class SaleDetailAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sale: props.sale,
      workflowSales: props.workflowSales ?? [],
      jewellerInvoiceNumber: "",
      settingCostPrice: "",
      melesCost: "",
      melesReference: "",
    };
  }

  componentDidMount() {
    this.showSaleAccountingInfo();
  }

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    const sale = this.state.sale;
    var access_to_accounting_columns = Auth.isAuthorised(
      "sales_accounting_columns"
    );
    let paysByDeposit;
    if ("paysByDeposit" in sale) {
      if (sale.paysByDeposit === true) {
        paysByDeposit = "Yes";
      } else if (sale.paysByDeposit === false) {
        paysByDeposit = "No";
      } else if (sale.paysByDeposit === null) {
        paysByDeposit = "Not specified";
      }
    }

    let promotionsDiscount;
    if (sale.promotionsDiscount === true) {
      promotionsDiscount = "Yes";
    } else if (sale.promotionsDiscount === false) {
      promotionsDiscount = "No";
    } else {
      promotionsDiscount = "";
    }

    let pricesIncludeGst;
    let labelIncGst;
    let labelWOGST;
    if ("pricesIncludeGst" in sale) {
      if (sale.pricesIncludeGst === true) {
        pricesIncludeGst = "Yes";
      } else if (sale.pricesIncludeGst === false) {
        pricesIncludeGst = "No";
      } else {
        labelIncGst = ` (Inc ${Util.getStorageParameter("REACT_APP_TAX_NAME")})`;
        labelWOGST = " (WOGST)";
      }
    }

    return (
      <div id="SaleDetailAccounts_component">
        <div className="sale_data">
          <h2 className="heading">ACCOUNTS</h2>
          <div className="container mb-4">
            <div className="row">
              <div className="col-sm bold">Prices</div>
            </div>
            <div className="row">
              <div className="col-sm">
                The following prices include {Util.getStorageParameter("REACT_APP_TAX_NAME")}
              </div>
              <div className="col-sm">{pricesIncludeGst}</div>
            </div>
            <div className="row">
              <div className="col-sm">Total Diamond(s){labelWOGST}</div>
              <div className="col-sm">
                {Util.formatCurrency(sale.totalDiamondWogst)}
              </div>
            </div>
            <div className="row">
              <div className="col-sm">Total Jewellery{labelWOGST}</div>
              <div className="col-sm">
                {Util.formatCurrency(sale.totalSettingWogst)}
              </div>
            </div>
            <div className="row">
              <div className="col-sm">Express Manufacturing{labelWOGST}</div>
              <div className="col-sm">
                {Util.formatCurrency(sale.expressManufacturingWogst)}
              </div>
            </div>
            <div className="row">
              <div className="col-sm">Engraving{labelWOGST}</div>
              <div className="col-sm">
                {Util.formatCurrency(sale.engravingWogst)}
              </div>
            </div>
            <div className="row">
              <div className="col-sm">CAD payment</div>
              <div className="col-sm">
                {Util.formatCurrency(sale.cadPayment)}
              </div>
            </div>
            <div className="row">
              <div className="col-sm">Total Invoice{labelIncGst}</div>
              <div className="col-sm">
                {Util.formatCurrency(sale.totalIncGst)}
              </div>
            </div>
            <div className="row">
              <div className="col-sm">Balance</div>
              <div className="col-sm">{Util.formatCurrency(sale.balance)}</div>
            </div>
            <div className="row">
              <div className="col-sm">Xero Code</div>
              <div className="col-sm">{sale.xeroCode}</div>
            </div>
            <div className="row">
              <div className="col-sm">
                Invoice Value {Util.getStorageParameter("REACT_APP_CURRENCY")}
              </div>
              <div className="col-sm">
                {Util.formatCurrency(sale.invoiceValueLocalCurrency)}
              </div>
            </div>
            <div className="row">
              <div className="col-sm">
                <br></br>
              </div>
              <div className="col-sm"></div>
            </div>

            <div className="row">
              <div className="col-sm">Billing Information</div>
              <div
                dangerouslySetInnerHTML={{ __html: sale.billingInformation }}
              ></div>
            </div>
            <div className="row">
              <div className="col-sm">ID and Payment Verification</div>
              <div className="col-sm">{sale.paymentIdVerification}</div>
            </div>
            <div className="row">
              <div className="col-sm">Order Currency</div>
              <div className="col-sm">{sale.orderCurrency}</div>
            </div>
            <div className="row">
              <div className="col-sm">Customer pays by deposit</div>
              <div className="col-sm">{paysByDeposit}</div>
            </div>
            <div className="row">
              <div className="col-sm">Payment Method</div>
              <div className="col-sm">{sale.paymentMethod}</div>
            </div>
            <div className="row">
              <div className="col-sm">Invoice Status</div>
              <div className="col-sm">{sale.invoice}</div>
            </div>
            <div className="row">
              <div className="col-sm">Jeweller Invoice Number</div>
              <div className="col-sm">{this.state.jewellerInvoiceNumber}</div>
            </div>
            <div className="row">
              <div className="col-sm">Meles Cost</div>
              <div className="col-sm">
                {Util.formatCurrency(this.state.melesCost)}
              </div>
            </div>
            <div className="row">
              <div className="col-sm">Meles Reference</div>
              <div className="col-sm">{this.state.melesReference}</div>
            </div>
            <div className="row">
              <div className="col-sm">Setting Cost Price</div>
              <div className="col-sm">
                {Util.formatCurrency(this.state.settingCostPrice)}
              </div>
            </div>

            <div className="row">
              <div className="col-sm">Selling Price to Nova</div>
              <div className="col-sm">
                {Util.formatCurrency(sale.sellingPriceNova)}
              </div>
            </div>
            <div className="row">
              <div className="col-sm">Internal Invoice</div>
              <div className="col-sm">{sale.xeroCodeMmdia}</div>
            </div>
            <div className="row">
              <div className="col-sm">Nova Invoice</div>
              <div className="col-sm">{sale.xeroCodeNova}</div>
            </div>
            {access_to_accounting_columns === true ? (
              <div className="row">
                <div className="col-sm">Accounting Status</div>
                <div className="col-sm">{sale.accountingStatus}</div>
              </div>
            ) : null}
            <div className="row">
              <div className="col-sm">Promotions / Discount</div>
              <div className="col-sm">{promotionsDiscount}</div>
            </div>
            <div className="row">
              <div className="col-sm">
                Please select promotion offered to the customer
              </div>
              <div className="col-sm">{sale.promotionsOfferedtoCustomer}</div>
            </div>
            <div className="row">
              <div className="col-sm">Voucher Code</div>
              <div className="col-sm">{sale.voucherCode}</div>
            </div>
            <div className="row">
              <div className="col-sm">Voucher Price $$$</div>
              <div className="col-sm">{sale.voucherPrice}</div>
            </div>
            <div className="row">
              <div className="col-sm">Discount %</div>
              <div className="col-sm">{sale.discountPercentage}</div>
            </div>
            <div className="row">
              <div className="col-sm">Discount Price</div>
              <div className="col-sm">{sale.discountValue}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  showSaleAccountingInfo() {
    let jewellerInvoiceNumber = "";
    let settingCostPrice = "";
    let melesCost = "";
    let melesReference = "";

    this.state.workflowSales.map(function (workflowSale) {
      if (workflowSale.hasOwnProperty("workflowActivityModels")) {
        workflowSale.workflowActivityModels.map(function (workflowActivity) {
          if (workflowActivity?.type === MANUFACTURING) {
            jewellerInvoiceNumber = workflowActivity?.invoiceNumber;
            settingCostPrice = workflowActivity?.cost;
            if (workflowActivity.hasOwnProperty("workflowMelesModels")) {
              melesCost = workflowActivity.workflowMelesModels[0]?.melesCost;
              melesReference =
                workflowActivity.workflowMelesModels[0]?.melesReference;
            }
          }
          return "";
        });
      }
      return "";
    });

    this.setState({
      jewellerInvoiceNumber: jewellerInvoiceNumber,
      settingCostPrice: settingCostPrice,
      melesCost: melesCost,
      melesReference: melesReference,
    });
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default SaleDetailAccounts;
