import { sprintf } from "sprintf-js";
import Util from "../../../../Util";

export const getDiamondLocationCityName = (
    diamond,
    diamondLocation
) => {
    if (!diamond) {
        return diamondLocation
    }

    let diamond_location_city_name = diamond.location ?? ''

    let listShowroomCity = Util.getStorageParameter("REACT_APP_SHOWROOM_CITY_OPTIONS").split(',')

    let restult = listShowroomCity.find((city) => city === diamond_location_city_name);

    if (!restult) {
        diamond_location_city_name = diamond.provider_name
    }

    return diamond_location_city_name ?? diamondLocation
}

const addText = (
    value = '',
    addText
) => {
    if (!value) {
        return addText.trim()
    }

    return value += `, ${addText.trim()}`
}

export const getDiamondCertificate = (
    diamond,
    diamondCertificate
) => {
    if (!diamond) {
        return diamondCertificate
    }

    const {
        certificate_number,
        shape_name,
        carat,
        color_name,
        clarity_name,
        cut_name
    } = diamond

    let diamondDetails = sprintf(
        '%s %s %s %s %s %s',
        certificate_number ? certificate_number.trim() : '',
        shape_name ? shape_name.trim() : '',
        carat ? carat.trim() : '',
        color_name ? color_name.trim() : '',
        clarity_name ? clarity_name.trim() : '',
        cut_name ? cut_name.trim() : ''
    )

    diamondDetails = diamondDetails.trim()

    let position = diamondCertificate.search(diamondDetails);

    if (position <= -1) {
        return addText(diamondCertificate, diamondDetails)
    }

    return diamondCertificate
}

export const getDiamondGdOwnKey = (
    diamond,
    diamondGdOwnKey
) => {
    if (!diamond) {
        return diamondGdOwnKey
    }

    const { reference } = diamond

    let position = diamondGdOwnKey.search(reference);

    if (position <= -1) {
        return addText(diamondGdOwnKey, reference)
    }

    return diamondGdOwnKey
}

export const getDiamondCertificateMerge = (
    sale,
    diamondCertificate
) => {
    let { diamondDetails } = sale
    
    if (!diamondCertificate) {
        return diamondDetails
    }

    diamondDetails = diamondDetails.trim()

    let position = diamondCertificate.search(diamondDetails);

    if (position <= -1) {
        return addText(diamondCertificate, diamondDetails)
    }

    return diamondCertificate
}