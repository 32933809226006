import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import Util from "../../../../Util";
import ShowSaleConsecutive from "../../Acctions/ShowSaleConsecutive";

const RowSaleExchange = ({ sale, activeSelect }) => {
  const { id, onlineOrderNumber, name, saleType, ringPreference, status } =
    sale;

  const [selectSale, setSelectSale] = useState({ id: 0 });

  const handleSelectSale = (sale) => {
    setSelectSale(sale);

    activeSelect(sale);
  };

  return (
    <tr
      className={
        selectSale.id === id
          ? "add-task-button highlighted_row"
          : "add-task-button"
      }
      onClick={() => handleSelectSale(sale)}
    >
      <td className="onlineOrderNumber">
        {onlineOrderNumber}
        <ShowSaleConsecutive sale={sale} />
      </td>
      <td className="name">{name}</td>
      <td className="orderStatus">
        <Col className={Util.statusAsSlug(status)}></Col>
        {status}
      </td>
      <td className="saleType">{saleType}</td>
      <td className="ringPreference">{ringPreference}</td>
    </tr>
  );
};

RowSaleExchange.propTypes = {
  sale: PropTypes.object.isRequired,
  activeSelect: PropTypes.func.isRequired,
};

export default RowSaleExchange;
