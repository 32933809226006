import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Auth from "../../Auth";
import BagIcon from "../../image/Sales.png";
import AccountsIcon from "../../image/accounts.png";
import PostSaleIcon from "../../image/postsale.png";
import DocumentIcon from "../../image/file-storage.png";
import { Col } from "react-bootstrap";

class Centerdashboard extends Component {
  constructor(props) {
    super();
  }

  render() {
    var access_to_all_sales = Auth.isAuthorised("dashboard_modules_all_sales");
    var access_to_costs = Auth.isAuthorised("dashboard_modules_costs");
    var access_to_post_sales = Auth.isAuthorised(
      "dashboard_modules_post_sales"
    );
    var access_to_accounting = Auth.isAuthorised(
      "dashboard_modules_accounting"
    );

    const accessCRM = Auth.canAccessRolesAndLeader("rolesAccessCRMList");

    const leadersSales = Auth.canAccessRolesAndLeader("rolesEditReports", true);

    const roleSales = Auth.canAccessRolesAndLeader("rolesIsSales");
    const roleAccounting = Auth.canAccessRolesAndLeader("accountingStatus");

    return (
      <section className="center-section">
        {access_to_all_sales === true ? (
          <div className="menu-card">
            <div className="card-left orders-card-left">
              <div className="card-left-section">
                <span className="card-icon">
                  <img src={BagIcon} alt="" className="" />
                </span>
                <span className="card-name">SALES</span>
              </div>
            </div>

            <div className="card-right">
              <div className="card-right-section">
                <div className="card-link-div">
                  <span className="card-link-name">Show list</span>
                  <Link to="/sales" className="card-link-icon order-icon">
                    <FontAwesomeIcon icon={faBars} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {access_to_costs === true ? (
          <div className="menu-card">
            <div className="card-left customer-card-left">
              <div className="card-left-section">
                <span className="card-icon">
                  <img src={AccountsIcon} alt="" />
                </span>
                <span className="card-name">Costs</span>
              </div>
            </div>
            <div className="card-right">
              <div className="card-right-section">
                <div className="card-link-div">
                  <span className="card-link-name">Load invoices</span>
                  <Link
                    to="/costs/load_invoices"
                    className="card-link-icon customer-icon"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Link>
                </div>
                <div className="card-link-div">
                  <span className="card-link-name">Show list</span>
                  <Link
                    to="/costs/show_costs"
                    className="card-link-icon order-icon"
                  >
                    <FontAwesomeIcon icon={faBars} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {access_to_post_sales === true ? (
          <div className="menu-card">
            <div className="card-left user-card-left">
              <div className="card-left-section">
                <span className="card-icon">
                  <img src={PostSaleIcon} alt="" />
                </span>
                <span className="card-name">Post Sale Services</span>
              </div>
            </div>
            <div className="card-right">
              <div className="card-right-section">
                <div className="card-link-div">
                  <span className="card-link-name">Show list</span>
                  <Link
                    to="/post_sale_services"
                    className="card-link-icon user-icon"
                  >
                    <FontAwesomeIcon icon={faBars} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {access_to_accounting === true ? (
          <div className="menu-card">
            <div className="card-left user-card-left">
              <div className="card-left-section">
                <span className="card-icon">
                  <img src={AccountsIcon} alt="" />
                </span>
                <span className="card-name">Accounting</span>
              </div>
            </div>
            <div className="card-right">
              <div className="card-right-section">
                <Col className="card-link-div">
                  <span className="card-link-name">Load commissions</span>
                  <Link
                    to="/accounting/load-commissions"
                    className="card-link-icon customer-icon"
                  >
                    <FontAwesomeIcon icon={faUpload} />
                  </Link>
                </Col>
                <div className="card-link-div">
                  <span className="card-link-name">Show list</span>
                  <Link to="/accounting" className="card-link-icon user-icon">
                    <FontAwesomeIcon icon={faBars} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {accessCRM && (
          <div className="menu-card">
            <div className="card-left user-card-left">
              <div className="card-left-section">
                <span className="card-icon">
                  <img src={PostSaleIcon} alt="" />
                </span>
                <span className="card-name">CRM</span>
              </div>
            </div>
            <div className="card-right">
              <div className="card-right-section">
                <Col className="card-link-div">
                  <span className="card-link-name">Appointments</span>
                  <Link
                    to="/customer-appointments"
                    className="card-link-icon user-icon"
                  >
                    <FontAwesomeIcon icon={faBars} />
                  </Link>
                </Col>
                <Col className="card-link-div">
                  <span className="card-link-name">Customers / Leads</span>
                  <Link
                    to="/customers"
                    className="card-link-icon customer-icon"
                  >
                    <FontAwesomeIcon icon={faBars} />
                  </Link>
                </Col>
              </div>
            </div>
          </div>
        )}
        {(leadersSales || roleSales || roleAccounting) && (
          <div className="menu-card">
            <div className="card-left user-card-left">
              <div className="card-left-section">
                <span className="card-icon">
                  <img src={PostSaleIcon} alt="" />
                </span>
                <span className="card-name">Reports</span>
              </div>
            </div>
            <div className="card-right">
              <div className="card-right-section">
                <div className="card-link-div">
                  <span className="card-link-name">Show list</span>
                  <Link to="/reports" className="card-link-icon order-icon">
                    <FontAwesomeIcon icon={faBars} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="menu-card">
          <div className="card-left user-card-left">
            <div className="card-left-section">
              <span className="card-icon">
                <img src={DocumentIcon} alt="" />
              </span>
              <span className="card-name">Documentation</span>
            </div>
          </div>
          <div className="card-right">
            <div className="card-right-section">
              <div className="card-link-div">
                <span className="card-link-name">Show list</span>
                <Link to="/documentation" className="card-link-icon user-icon">
                  <FontAwesomeIcon icon={faBars} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Centerdashboard;
