import React, { Component } from "react";
import { Tabs, Tab, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import MyListTab from "./MyListTab";
import SaleRow from "./SaleRow";
import SaleInformation from "./saleInformation/SaleInformation";
import SaleInformationModal from "./saleInformation/SaleInformationModal";
import InfiniteScroll from "react-infinite-scroll-component";
import Auth from "../../Auth";
import LoadingLayer from "../LoadingLayer";
import { Link } from "react-router-dom";
import DateFilter from "./DateFilter";
import DateCustomerNeedsByFilter from "./DateCustomerNeedsByFilter";
import SaleEdit from "./edit/SaleEdit";
import SaleWorkflowEdit from "./saleWorkflow/edit/SaleWorkflowEdit";
import SaleCreate from "./create/SaleCreate";
import SaleWorkflow from "./SaleWorkflow";
import { Form } from "react-bootstrap";
import {
  faPlus,
  faDownload,
  faEraser,
  faClose,
  faListCheck,
  faShareFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as globalConst from "../../global/const";
import SaleRemoveMyListModal from "./SaleRemoveMyListModal";
import BackIcon from "../../image/backArrow.png";
import SearchIcon from "../../image/pink_search.png";
import AddToMyListModal from "./AddToMyListModal";
import ConfirmRemoveCustomListModal from "./ConfirmRemoveCustomListModal";
import EditSettingStatusModal from "./EditSettingStatusModal";
import { sprintf } from "sprintf-js";
import moment from "moment";
import { debounce } from "lodash";

// SCSS
import "../../css/sales/sales.scss";
import Notifications from "../../Notifications";
import AdvancedFilter from "../advanced_filter/AdvancedFilter";
import {
  generateSalesExcelAdvancedFilterPersonalTab,
  generateSalesExcelAdvancedFilterResults,
  generateWorkflowCostsReportAdvancedFilterPersonalTab,
  generateWorkflowCostsReportAdvancedFilterResults,
} from "./Acctions/ExportReports";
import UpdateWorkflowSaleMultiple from "./saleWorkflow/UpdateWorkflowSaleMultiple";
import Util from "../../Util";
import withNavigation from "../../Router/WithNavigation";
import JewelleryRingRequestMultiple from "./saleWorkflow/JewelleryRingRequestMultiple";

// import { Switch, Route } from 'react-router-dom';

const EXPORT_SALES_REPORT = "Sales report";
const EXPORT_WORKFLOW_COST = "Workflow Costs";
const ORDER_MODEL = "salesModel";
const GENERAL_SEARCH = "general_search";
const TAB_GENERAL = "results";

class SalesList extends Component {
  constructor(props) {
    super(props);
    const isAuth = Auth.getIsAuth();
    if (isAuth === false) {
      const { navigate } = this.props;

      navigate("/");
    }

    var addToMyListSelectedSales = [];
    addToMyListSelectedSales = [];

    const order_ref = new URLSearchParams(window.location.search).get(
      "order-ref"
    );

    this.state = {
      sales: [],
      loading_page: false,
      selected_sale: null,
      selected_sale_index: null,
      total_results: 0,
      api_has_more: false,
      sheetType: "ACTIVE",
      sale_to_edit: null,
      workflow_to_edit: null,
      show_create_sale_modal: false,
      show_workflow_modal: false,
      show_information_sale_modal: false,
      show_remove_my_list_modal: false,
      show_confirm_remove_custom_list_modal: false,
      show_edit_setting_status_modal: false,
      show_workflow_panel: false,
      show_quality_check_panel: false,
      addToMyListSelectedSales: addToMyListSelectedSales,
      currentTab: "results",
      currentTabCount: 0,
      loading_excel: false,
      loading_sales_excel: false,
      addToMyListSelectedSalesSortedBy: "",
      addToMyListSelectedSalesSortedType: "DESC",
      search: order_ref !== null ? order_ref : "",
      myListTabs: [],
      newTabName: "",
      show_add_to_my_list_modal: false,
      selectedTabToAdd: null,
      selectedTab: "",
      checkedSales: [],
      selected_sale_information: null,
      resetInformation: false,
      exportReport: "",
      dataForAdvancedFilter: [],
      dataForAdvancedFilterOrder: {
        orderBy: "saleDate",
        orderType: "DESC",
        modelOrder: ORDER_MODEL,
      },
      advancedFilterActive: false,
      dataAdvancedFilter: [],
      pageAdvancedFilter: 1,
      disabledSearch: true,
      saleDuplicate: null,
      showUpdateMultiple: false,
      btnsDisable: [],
      showPanelInformation: false,
      showSendMultipleToGD: false,
    };

    if (order_ref) {
      this.state.sheetType = "All";
    }

    this.advancedFilterRef = React.createRef();
    this.saleInformation = React.createRef();

    this.resetFilters();
    this.filter_params["generalSearch"] = order_ref;
    if (this.filter_params["generalSearch"] === null) {
      this.filter_params["generalSearch"] = "";
    }

    this.openEditionComponent = this.openEditionComponent.bind(this);
    this.openWorkflowEditionComponent =
      this.openWorkflowEditionComponent.bind(this);
    this.openWorkflowModal = this.openWorkflowModal.bind(this);
    this.openWorkflowPanel = this.openWorkflowPanel.bind(this);
    this.closeEditionComponent = this.closeEditionComponent.bind(this);
    this.closeCreateComponent = this.closeCreateComponent.bind(this);
    this.closeInformationComponent = this.closeInformationComponent.bind(this);
    this.closeSaleRemoveMyListModal =
      this.closeSaleRemoveMyListModal.bind(this);
    this.closeConfirmRemoveCustomListModal =
      this.closeConfirmRemoveCustomListModal.bind(this);
    this.addMyListTab = this.addMyListTab.bind(this);
    this.removeCurrentMyListTab = this.removeCurrentMyListTab.bind(this);
    this.editSettingStatusCustomisedList =
      this.editSettingStatusCustomisedList.bind(this);
    this.handleNewTabNameChange = this.handleNewTabNameChange.bind(this);
    this.openAddToMyListModal = this.openAddToMyListModal.bind(this);
    this.closeAddToMyListModal = this.closeAddToMyListModal.bind(this);
    this.setTabToAdd = this.setTabToAdd.bind(this);
    this.saleIsChecked = this.saleIsChecked.bind(this);
    this.removeSaleFromCustomisedList =
      this.removeSaleFromCustomisedList.bind(this);
    this.handleSelectMyListTab = this.handleSelectMyListTab.bind(this);
    this.fetchSaleDetailWithId = this.fetchSaleDetailWithId.bind(this);
    this.handleExportReport = this.handleExportReport.bind(this);
    this.downloadReport = this.downloadReport.bind(this);
    this.updateSaleForList = this.updateSaleForList.bind(this);

    this.loadInfinitScroll = true;

    this.debouncedHandleFilterChange = debounce(this.handleFilterChange, 300);
  }

  filter_params = {};
  selectedTab = "";

  resetFilters = () => {
    this.filter_params = {
      page: 1,
      max_results: 100,
      order_by: "saleDate",
      order_type: "DESC",
      sheetType: this.state.sheetType,
      saleDate: "",
      id: this.filter_params["id"],
      onlineOrderNumber: this.filter_params["onlineOrderNumber"],
      xeroCode: this.filter_params["xeroCode"],
      name: this.filter_params["name"],
      phone: this.filter_params["phone"],
      email: this.filter_params["email"],
      dateCustomerNeedsBy: this.filter_params["dateCustomerNeedsBy"],
      orderType: this.filter_params["orderType"],
      saleType: this.filter_params["saleType"],
      ringPreference: this.filter_params["ringPreference"],
      jeweller: this.filter_params["jeweller"],
      manufacturingLocation: this.filter_params["manufacturingLocation"],
      settingStatus: this.filter_params["settingStatus"],
      diamondLocation: this.filter_params["diamondLocation"],
      paymentMethod: this.filter_params["paymentMethod"],
      accountingStatus: this.filter_params["accountingStatus"],
      balance: this.filter_params["balance"],
      totalIncGst: this.filter_params["totalIncGst"],
      diamondDetails: this.filter_params["diamondDetails"],
      orderCurrency: this.filter_params["orderCurrency"],
      invoice: this.filter_params["invoice"],
      generalSearch: this.filter_params["generalSearch"],
      saleMinDate: this.filter_params["saleMinDate"],
      saleMaxDate: this.filter_params["saleMaxDate"],
      customerNeedsOnMax: this.filter_params["customerNeedsOnMax"],
      customerNeedsOnMin: this.filter_params["customerNeedsOnMin"],
    };
    Object.keys(this.filter_params).forEach((key) =>
      this.filter_params[key] === undefined
        ? delete this.filter_params[key]
        : {}
    );
  };

  fetchMoreData = () => {
    this.setState({
      pageAdvancedFilter: this.state.pageAdvancedFilter + 1,
    });
  };

  fetchSalesAdvancedFilter = () => {
    const advancedFilter = this.advancedFilterRef.current;
    if (advancedFilter) {
      this.advancedFilterRef.current.searchAdvancedFilter(
        false,
        false,
        this.state.sheetType
      );
    }
  };

  clearFilters = () => {
    window.location = window.location.pathname;
  };

  disabledActionsBotons = (btns) => {
    this.setState({
      showPanelInformation: false,
      btnsDisable: btns,
    });
  };

  render() {
    var access_to_create_sale = Auth.isAuthorised("sales_create");
    var access_to_accounting_columns = Auth.isAuthorised(
      "sales_accounting_columns"
    );

    const showMultiple = Auth.canAccessRolesAndLeader(
      "rolesAccessEditMultiple",
      false
    );

    const showMultipleSendGD = Auth.canAccessRolesAndLeader(
      "rolesAccessEditMultiple",
      false
    );

    const showBottonAction = parseInt(
      Util.getStorageParameter("REACT_APP_ACTIVE_REQUEST_JEWELLERY") ?? 0
    );

    return (
      <div id="SalesList_component">
        {this.state.show_remove_my_list_modal === true ? (
          <SaleRemoveMyListModal
            closeSaleRemoveMyListModal={this.closeSaleRemoveMyListModal}
            uncheckAllMyList={this.uncheckAllMyList}
          />
        ) : null}
        {this.state.show_confirm_remove_custom_list_modal === true ? (
          <ConfirmRemoveCustomListModal
            closeConfirmRemoveCustomListModal={
              this.closeConfirmRemoveCustomListModal
            }
            listName={this.state.currentTab}
            removeCurrentMyListTab={this.removeCurrentMyListTab}
          />
        ) : null}
        {this.state.show_edit_setting_status_modal === true ? (
          <EditSettingStatusModal
            closeEditSettingStatusModal={this.closeEditSettingStatusModal}
            listCount={this.state.currentTabCount}
            editSettingStatusCustomisedList={
              this.editSettingStatusCustomisedList
            }
          />
        ) : null}
        {this.state.sale_to_edit !== null ? (
          <SaleEdit
            sale={this.state.sale_to_edit}
            showPanelInformation={true}
            closeEditionComponent={this.closeEditionComponent}
            selected_sale_index={this.state.selected_sale_index}
            openEditCloseConfirmationModal={this.openEditCloseConfirmationModal}
            disabledActionsBotons={this.disabledActionsBotons}
            updateSaleInformation={this.updateSaleInformation}
            updateWorkflowSaleInformation={this.updateWorkflowSaleInformation}
          />
        ) : null}
        {this.state.workflow_to_edit !== null ? (
          <SaleWorkflowEdit
            showPanelInformation={true}
            sale={this.state.selected_sale}
            saleWorkflow={this.state.workflow_to_edit}
            closeEditionComponent={this.closeWorkflowEditionComponent}
            selected_sale_index={this.state.selected_sale_index}
            openEditCloseConfirmationModal={this.openEditCloseConfirmationModal}
            parentResetInformation={this.handleResetInformationCallback}
            disabledActionsBotons={this.disabledActionsBotons}
            updateWorkflowSaleInformation={this.updateWorkflowSaleInformation}
            updateSaleInformation={this.updateSaleInformation}
            updateSaleForList={this.updateSaleForList}
          />
        ) : null}
        {this.state.show_create_sale_modal === true ? (
          <SaleCreate
            showPanelInformation={this.state.showPanelInformation}
            closeCreateComponent={this.closeCreateComponent}
            sale={this.state.saleDuplicate}
            disabledActionsBotons={this.disabledActionsBotons}
          />
        ) : null}
        {this.state.show_information_sale_modal === true ? (
          <SaleInformationModal
            selected_sale={this.state.selected_sale}
            openEditionComponent={this.openEditionComponent}
            openWorkflowModal={this.openWorkflowModal}
            openWorkflowPanel={this.openWorkflowPanel}
            closeInformationComponent={this.closeInformationComponent}
          />
        ) : null}
        {this.state.show_workflow_modal === true ? (
          <SaleWorkflow
            showPanelInformation={true}
            closeEditionComponent={this.closeEditionComponent}
            closeWorkflowModal={this.closeWorkflowModal}
            selectedSale={this.state.selected_sale}
            disabledActionsBotons={this.disabledActionsBotons}
          />
        ) : null}
        {this.state.show_add_to_my_list_modal === true ? (
          <AddToMyListModal
            closeAddToMyListModal={this.closeAddToMyListModal}
            setTabToAdd={this.setTabToAdd}
            myListTabs={this.state.myListTabs}
          />
        ) : null}
        <div className="container_sales">
          {this.state.loading_page === true ? <LoadingLayer /> : ""}
          {this.state.loading_sales_excel === true ? <LoadingLayer /> : ""}
          {this.state.loading_excel === true ? <LoadingLayer /> : ""}
          <div className="list_and_information">
            <div className="row">
              <div className="sales_list">
                <div className="header">
                  <div className="row header_tools">
                    <div className="">
                      <div className="col-12">
                        <div className="link_to_dashboard">
                          <Link to="/dashboard">
                            <img height="50px" src={BackIcon} alt="" />
                          </Link>
                        </div>
                        <div className="db-top-heading">
                          <h2 className="sales-title">SALES</h2>
                          <Notifications />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Row className="add_new_sale row-sales-controls">
                  <Col xs={12} lg={4}>
                    <Row className="customised-sales-controls">
                      <Col className="input-group add-new-tab">
                        <input
                          type="text"
                          placeholder="Add new tab"
                          className="form-control box-bg"
                          value={this.state.newTabName}
                          onChange={this.handleNewTabNameChange}
                        />
                      </Col>
                      <Col>
                        <button
                          className="btn btn-default"
                          onClick={this.addMyListTab}
                          disabled={
                            this.state.myListTabs.length >=
                            globalConst.TOTAL_PERSONAL_TABS
                              ? true
                              : false
                          }
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                        <button
                          className="btn btn-default"
                          onClick={this.openConfirmRemoveCustomListModal}
                        >
                          <FontAwesomeIcon icon={faEraser} />
                        </button>
                        {this.state.currentTab !== "results" &&
                          showMultiple && (
                            <Button
                              onClick={this.showUpdateMultipleWorkflowSale}
                            >
                              <FontAwesomeIcon icon={faListCheck} />
                            </Button>
                          )}

                        {this.state.showUpdateMultiple && (
                          <UpdateWorkflowSaleMultiple
                            currentTab={this.state.currentTab}
                            listTabs={this.state.myListTabs}
                            onResponse={this.onResponseUpdateMultiple}
                          />
                        )}

                        {this.state.currentTab !== "results" &&
                          showMultipleSendGD &&
                          showBottonAction === 1 && (
                            <Button onClick={this.showSendMultipleToGD}>
                              <FontAwesomeIcon icon={faShareFromSquare} />
                            </Button>
                          )}

                        {this.state.showSendMultipleToGD && (
                          <JewelleryRingRequestMultiple
                            currentTab={this.state.currentTab}
                            listTabs={this.state.myListTabs}
                            onResponse={this.onResponseSendMultipleToGD}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} lg={4}>
                    {this.state.currentTab === "results" && (
                      <Row className="sheetType_selection">
                        <Col xs={12} sm={12} md={12} lg={4}>
                          <button
                            type="button"
                            className="btn btn-primary btn-novitadimonds"
                            onClick={(e) => this.clearFilters()}
                          >
                            <FontAwesomeIcon icon={faClose} /> Clear filters
                          </button>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={8}>
                          <span className="box-label">List type:</span>
                          <select
                            value={this.state.sheetType}
                            onChange={(e) => this.handleSheetTypeChange(e)}
                            className="select-box"
                          >
                            <option value="All">All</option>
                            <option value="ACTIVE">Current Responses</option>
                            <option value="FINALISED">Finished Jobs</option>
                          </select>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col xs={12} lg={4}>
                    {this.state.currentTab === "results" && (
                      <>
                        <Row className="general_searcj">
                          <div className="input-group">
                            <input
                              type="text"
                              placeholder="Search"
                              className="form-control box-bg advanced_filter_salesModel_general_search"
                              value={this.state.search ?? ""}
                              onChange={(e) =>
                                this.handleFilterChangeGeneralFilter(e, {
                                  name: "general_search",
                                  model: "salesModel",
                                })
                              }
                            />
                            <div className="input-group-append">
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                <img src={SearchIcon} alt="" />
                              </span>
                            </div>
                          </div>
                        </Row>

                        {access_to_create_sale === true ? (
                          <button
                            className="btn btn-default"
                            onClick={this.openCreateComponent}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </button>
                        ) : null}

                        <AdvancedFilter
                          ref={this.advancedFilterRef}
                          parentResultsAdvancedFilter={
                            this.resultsAdvancedFilter
                          }
                          parentFiltersAdvancedFilter={
                            this.filtersAdvancedFilter
                          }
                          parentFiltersExportAdvancedFilter={
                            this.filtersExportAdvancedFilter
                          }
                          parentDataForAdvancedFilter={
                            this.parentDataForAdvancedFilter
                          }
                          parentIsLoading={this.parentIsLoading}
                          pageAdvancedFilter={this.state.pageAdvancedFilter}
                          dataForAdvancedFilter={
                            this.state.dataForAdvancedFilter
                          }
                          sheetType={this.state.sheetType}
                          disabledSearch={this.state.disabledSearch}
                          autoSearchAdvanced={this.state.autoSearchAdvanced}
                          dataForAdvancedFilterOrder={
                            this.state.dataForAdvancedFilterOrder
                          }
                        />
                      </>
                    )}
                    <Row
                      className={
                        this.state.currentTab === "results"
                          ? "mt-1 ps-2"
                          : "ps-2"
                      }
                    >
                      <Col xs={6} lg={10}>
                        <Form.Group>
                          <Form.Select
                            value={this.state.exportReport}
                            onChange={(e) => this.handleExportReport(e)}
                            className="select-box"
                          >
                            <option value=""></option>
                            <option value={EXPORT_SALES_REPORT}>
                              {EXPORT_SALES_REPORT}
                            </option>
                            {Auth.canEditField("rolesExportWorkflowCost") ? (
                              <option value={EXPORT_WORKFLOW_COST}>
                                {EXPORT_WORKFLOW_COST}
                              </option>
                            ) : null}
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col xs={6} lg={2}>
                        <button
                          className="btn btn-default"
                          onClick={this.downloadReport}
                          disabled={this.state.exportReport === ""}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Tabs
                  defaultActiveKey="results"
                  activeKey={this.state.currentTab}
                  id="SalesListTabs"
                  className={"border-none"}
                  onSelect={this.handleSelect}
                >
                  <Tab
                    eventKey="results"
                    title={"All Results (" + this.state.total_results + ")"}
                  >
                    <InfiniteScroll
                      dataLength={this.state.sales.length}
                      next={this.fetchMoreData}
                      hasMore={this.state.api_has_more}
                      height={"calc(100vh - 150px)"}
                    >
                      <table
                        className="table table-striped table-sm table-hover"
                        id="table_sales"
                      >
                        <thead className="thead-dark">
                          {access_to_accounting_columns === true ? (
                            <tr className="titles">
                              <th
                                className="saleDate"
                                onClick={this.sortDateOfSale}
                              >
                                Date of Sale
                                {this.filter_params.order_by === "saleDate"
                                  ? this.filter_params.order_type === "DESC"
                                    ? " ▼"
                                    : " ▲"
                                  : null}
                              </th>
                              <th className="addToMyList">My List</th>
                              <th className="name">Name</th>
                              <th className="saleType">Type of Product</th>
                              <th className="diamondInfo">Diamond Info</th>
                              <th className="ringPreference">Product's Ref.</th>
                              <th className="orderCurrency">Currency</th>
                              <th className="invoice">Invoice Status</th>
                              <th className="totalIncGst">
                                Total Invoice (Inc{" "}
                                {Util.getStorageParameter("REACT_APP_TAX_NAME")}
                                )
                              </th>
                              <th className="balance">Balance</th>
                              <th className="xeroCode">Xero Code</th>
                              <th className="onlineOrderNumber">Online Ref.</th>
                              <th className="paymentMethod">Payment Method</th>
                              <th className="accountingStatus">
                                Accounting Status
                              </th>
                            </tr>
                          ) : (
                            <tr className="titles">
                              <th
                                className="saleDate"
                                onClick={this.sortDateOfSale}
                              >
                                Date of Sale
                                {this.filter_params.order_by === "saleDate"
                                  ? this.filter_params.order_type === "DESC"
                                    ? " ▼"
                                    : " ▲"
                                  : null}
                              </th>
                              <th className="addToMyList">My List</th>
                              <th className="onlineOrderNumber">
                                Order Number
                              </th>
                              <th className="name">Name</th>
                              <th className="status">Order Status</th>
                              <th className="orderTypeCol">Type of Order</th>
                              <th
                                className="dateCustomerNeedsBy"
                                onClick={this.sortDateCustomerNeedsBy}
                              >
                                Date Customer Needs by
                                {this.filter_params.order_type === "DESC"
                                  ? " ▼"
                                  : " ▲"}
                              </th>
                              <th className="workflowStatus">
                                Workflow status
                              </th>
                              <th className="saleType">Type of Product</th>
                              <th className="ringPreference">Product's Ref.</th>
                              <th className="jeweller">Jeweller</th>
                              <th className="diamondLocation">
                                Diamond Location
                              </th>
                              <th className="manufacturingLocation">
                                Manufacturing Location
                              </th>
                            </tr>
                          )}
                          {access_to_accounting_columns === true ? (
                            <tr className="filters">
                              <th className="saleDate advanced_filter_salesModel_sale_date">
                                <DateFilter
                                  handleSaleMinDateFilter={
                                    this.handleSaleMinDateFilter
                                  }
                                  handleSaleMaxDateFilter={
                                    this.handleSaleMaxDateFilter
                                  }
                                />
                              </th>
                              <th className="addToMyList">
                                <select
                                  value={this.state.selectedTab}
                                  onChange={(e) =>
                                    this.handleSelectMyListTab(e)
                                  }
                                  className="select-box"
                                >
                                  <option value=""></option>
                                  {this.state.myListTabs.map((row, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={!!row.name ? row.name : ""}
                                      >
                                        {!!row.name ? row.name : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                                <div className="">
                                  <Link
                                    to=""
                                    className="formatLink check-all-link"
                                    onClick={(e) => this.checkAll(e)}
                                  >
                                    Check all
                                  </Link>
                                </div>
                              </th>
                              <th className="name">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "name",
                                      model: "salesModel",
                                    })
                                  }
                                  className="advanced_filter_salesModel_name"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptyName"
                                    id={"filterEmptyName"}
                                    className="advanced_filter_empty_salesModel_name"
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "name",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                              <th className="saleType">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "sale_type",
                                      model: "salesModel",
                                    })
                                  }
                                  className="advanced_filter_salesModel_sale_type"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptySaleType"
                                    id={"filterEmptySaleType"}
                                    className="advanced_filter_empty_salesModel_sale_type"
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "sale_type",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                              <th className="diamondDetails">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "diamond_details",
                                      model: "salesModel",
                                    })
                                  }
                                  className="advanced_filter_salesModel_diamond_details"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptyDiamondDetails"
                                    id={"filterEmptyDiamondDetails"}
                                    className="advanced_filter_empty_salesModel_diamond_details"
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "diamond_details",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                              <th className="ringPreference">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "ring_preference",
                                      model: "salesModel",
                                    })
                                  }
                                  className="advanced_filter_salesModel_ring_preference"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptyRingPreference"
                                    id={"filterEmptyRingPreference"}
                                    className="advanced_filter_empty_salesModel_ring_preference"
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "ring_preference",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                              <th className="orderCurrency">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "order_currency",
                                      model: "salesModel",
                                    })
                                  }
                                  className="advanced_filter_salesModel_order_currency"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptyOrderCurrency"
                                    id={"filterEmptyOrderCurrency"}
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "order_currency",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                    className="advanced_filter_empty_salesModel_order_currency"
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                              <th className="invoice">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "invoice",
                                      model: "salesModel",
                                    })
                                  }
                                  className="advanced_filter_salesModel_invoice"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptyInvoice"
                                    id={"filterEmptyInvoice"}
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "invoice",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                    className="advanced_filter_empty_salesModel_invoice"
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                              <th className="totalIncGst">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "total_inc_gst",
                                      model: "salesModel",
                                    })
                                  }
                                  className="advanced_filter_salesModel_total_inc_gst"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptyTotalIncGst"
                                    id={"filterEmptyTotalIncGst"}
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "total_inc_gst",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                    className="advanced_filter_empty_salesModel_total_inc_gst"
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                              <th className="balance">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "balance",
                                      model: "salesModel",
                                    })
                                  }
                                  className="advanced_filter_salesModel_balance"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptyBalance"
                                    id={"filterEmptyBalance"}
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "balance",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                    className="advanced_filter_empty_salesModel_balance"
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                              <th className="xeroCode">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "xero_code",
                                      model: "salesModel",
                                    })
                                  }
                                  className="advanced_filter_salesModel_xero_code"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptyXeroCode"
                                    id={"filterEmptyXeroCode"}
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "xero_code",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                    className="advanced_filter_empty_salesModel_xero_code"
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                              <th className="onlineOrderNumber">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "online_order_number",
                                      model: "salesModel",
                                    })
                                  }
                                  className="advanced_filter_salesModel_online_order_number"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptyOnlineOrderNumber"
                                    id={"filterEmptyOnlineOrderNumber"}
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "online_order_number",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                    className="advanced_filter_empty_salesModel_online_order_number"
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                              <th className="paymentMethod">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "payment_method",
                                      model: "salesModel",
                                    })
                                  }
                                  className="advanced_filter_salesModel_payment_method"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptyPaymentMethod"
                                    id={"filterEmptyPaymentMethod"}
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "payment_method",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                    className="advanced_filter_empty_salesModel_payment_method"
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                              <th className="accountingStatus">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "accounting_status",
                                      model: "salesModel",
                                    })
                                  }
                                  className="advanced_filter_salesModel_accounting_status"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptyAccountingStatus"
                                    id={"filterEmptyAccountingStatus"}
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "accounting_status",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                    className="advanced_filter_empty_salesModel_accounting_status"
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                            </tr>
                          ) : (
                            <tr className="filters">
                              <th className="saleDate advanced_filter_salesModel_sale_date">
                                <DateFilter
                                  handleSaleMinDateFilter={
                                    this.handleSaleMinDateFilter
                                  }
                                  handleSaleMaxDateFilter={
                                    this.handleSaleMaxDateFilter
                                  }
                                />
                              </th>
                              <th className="addToMyList">
                                <select
                                  value={this.state.selectedTab}
                                  onChange={(e) =>
                                    this.handleSelectMyListTab(e)
                                  }
                                  className="select-box"
                                >
                                  <option value=""></option>
                                  {this.state.myListTabs.map((row, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={!!row.name ? row.name : ""}
                                      >
                                        {!!row.name ? row.name : ""}
                                      </option>
                                    );
                                  })}
                                </select>
                                <div className="">
                                  <Link
                                    to=""
                                    className="formatLink check-all-link"
                                    onClick={(e) => this.checkAll(e)}
                                  >
                                    Check all
                                  </Link>
                                </div>
                              </th>
                              <th className="onlineOrderNumber">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "online_order_number",
                                      model: "salesModel",
                                    })
                                  }
                                  className="advanced_filter_salesModel_online_order_number"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptyonlineOrderNumber"
                                    id={"filterEmptyonlineOrderNumber"}
                                    className="advanced_filter_empty_salesModel_online_order_number"
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "online_order_number",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                              <th className="name">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "name",
                                      model: "salesModel",
                                    })
                                  }
                                  className="advanced_filter_salesModel_name"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptyName"
                                    id={"filterEmptyName"}
                                    className="advanced_filter_empty_salesModel_name"
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "name",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                              <th className="status">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "status",
                                      model: "salesModel",
                                    })
                                  }
                                  className="advanced_filter_salesModel_status"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    className="input-section advanced_filter_empty_salesModel_status"
                                    type="checkbox"
                                    name="filterEmptyStatus"
                                    id={"filterEmptyStatus"}
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "status",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                              <th className="orderType">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "order_type",
                                      model: "salesModel",
                                    })
                                  }
                                  className="advanced_filter_salesModel_order_type"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptyOrderType"
                                    id={"filterEmptyOrderType"}
                                    className="advanced_filter_empty_salesModel_order_type"
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "order_type",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                              <th className="dateCustomerNeedsBy advanced_filter_salesModel_customer_needs_on">
                                <DateCustomerNeedsByFilter
                                  handleMinDateCustomerNeedsByFilter={
                                    this.handleMinDateCustomerNeedsByFilter
                                  }
                                  handleMaxDateCustomerNeedsByFilter={
                                    this.handleMaxDateCustomerNeedsByFilter
                                  }
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptyCustomerNeedsOn"
                                    id={"filterEmptyCustomerNeedsOn"}
                                    className="advanced_filter_empty_salesModel_customer_needs_on"
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "customer_needs_on",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterShowUrgentDates"
                                    id={"filterShowUrgentDates"}
                                    className="advanced_filter_salesModel_urgent_date_requested_by_customer"
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "urgent_date_requested_by_customer",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                  />
                                  <span className="empty-text">
                                    Show urgent dates
                                  </span>
                                </div>
                              </th>
                              <th className="workflowStatus">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "status",
                                      model: "workflowSaleModel",
                                    })
                                  }
                                  className="advanced_filter_workflowSaleModel_status"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptyworkflowStatus"
                                    id={"filterEmptyworkflowStatus"}
                                    className="advanced_filter_empty_workflowSaleModel_status"
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "status",
                                        model: "workflowSaleModel",
                                        type: "checkbox",
                                      })
                                    }
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                              <th className="saleType">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "sale_type",
                                      model: "salesModel",
                                    })
                                  }
                                  className="advanced_filter_salesModel_sale_type"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptySaleType"
                                    id={"filterEmptySaleType"}
                                    className="advanced_filter_empty_salesModel_sale_type"
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "sale_type",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                              <th className="ringPreference">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "ring_preference",
                                      model: "salesModel",
                                    })
                                  }
                                  className="advanced_filter_salesModel_ring_preference"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptyRingPreference"
                                    id={"filterEmptyRingPreference"}
                                    className="advanced_filter_empty_salesModel_ring_preference"
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "ring_preference",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                              <th className="jeweller">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "contact_name",
                                      model: "jewellerModel",
                                    })
                                  }
                                  className="advanced_filter_jewellerModel_contact_name"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptyJeweller"
                                    id={"filterEmptyJeweller"}
                                    className="advanced_filter_empty_jewellerModel_contact_name"
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "contact_name",
                                        model: "jewellerModel",
                                        type: "checkbox",
                                      })
                                    }
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                              <th className="diamondLocation">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "diamond_location",
                                      model: "salesModel",
                                    })
                                  }
                                  className="advanced_filter_salesModel_diamond_location"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptyDiamondLocation"
                                    id={"filterEmptyDiamondLocation"}
                                    className="advanced_filter_empty_salesModel_diamond_location"
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "diamond_location",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                              <th className="manufacturingLocation">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    this.debouncedHandleFilterChange(e, {
                                      name: "manufacturing_location",
                                      model: "salesModel",
                                    })
                                  }
                                  className="advanced_filter_salesModel_manufacturing_location"
                                />
                                <div className="form-check custom-from-check">
                                  <Form.Check
                                    type="checkbox"
                                    name="filterEmptyManufacturingLocation"
                                    id={"filterEmptyManufacturingLocation"}
                                    className="advanced_filter_empty_salesModel_manufacturing_location"
                                    onChange={(e) =>
                                      this.debouncedHandleFilterChange(e, {
                                        name: "manufacturing_location",
                                        model: "salesModel",
                                        type: "checkbox",
                                      })
                                    }
                                  />
                                  <span className="empty-text">Empty</span>
                                </div>
                              </th>
                            </tr>
                          )}
                        </thead>
                        <tbody>
                          {this.state.sales.map((sale, index) => {
                            let isChecked = this.saleIsChecked(sale.id);
                            return (
                              <SaleRow
                                key={sale.id}
                                sale={sale}
                                index={index}
                                handleRowSelection={this.handleRowSelection}
                                handleChangeCheckbox={this.handleChangeCheckbox}
                                selected_sale={this.state.selected_sale}
                                addToMyList={isChecked}
                                currentTab={this.state.currentTab}
                                selectedTabToAdd={this.state.selectedTabToAdd}
                              />
                            );
                          })}
                        </tbody>
                      </table>
                    </InfiniteScroll>
                  </Tab>
                  {this.state.myListTabs.map((tab, index) => {
                    let tabCheckedSales = this.state.myListTabs.filter(
                      function (item) {
                        return item.name === tab.name;
                      }
                    );
                    let tabLength = 0;
                    try {
                      if (typeof tabCheckedSales[0] !== "undefined") {
                        if (
                          typeof tabCheckedSales[0].salesIds !== "undefined"
                        ) {
                          if (tabCheckedSales[0].salesIds !== null) {
                            tabLength = JSON.parse(
                              tabCheckedSales[0].salesIds
                            ).length;
                          }
                        }
                      }
                    } catch (e) {}
                    return (
                      <Tab
                        eventKey={tab.name}
                        title={tab.name + " (" + tabLength + ")"}
                        key={"tab" + index}
                      >
                        <MyListTab
                          checkedSales={tabCheckedSales}
                          key={tab.name}
                          index={index}
                          name={tab.name}
                          selected_sale={this.state.selected_sale}
                          removeSaleFromCustomisedList={
                            this.removeSaleFromCustomisedList
                          }
                          fetchSaleDetailWithId={this.fetchSaleDetailWithId}
                          parentSaleInformationCustomizeMyList={
                            this.saleInformationCustomizeMyList
                          }
                        />
                      </Tab>
                    );
                  })}
                </Tabs>
              </div>
              {!this.state.show_workflow_panel &&
              !this.state.show_quality_check_panel ? (
                <SaleInformation
                  btnsDisable={this.state.btnsDisable ?? []}
                  sale={this.state.selected_sale_information}
                  closeEditionComponent={this.closeEditionComponent}
                  openEditionComponent={this.openEditionComponent}
                  openWorkflowModal={this.openWorkflowModal}
                  openWorkflowPanel={this.openWorkflowPanel}
                  openCreateSaleClone={this.openCreateSaleClone}
                  openWorkflowEditionComponent={
                    this.openWorkflowEditionComponent
                  }
                  ref={this.saleInformation}
                  disabledActionsBotons={this.disabledActionsBotons}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  openCreateSaleClone = (sale, showPanel = false) => {
    this.setState({
      show_create_sale_modal: true,
      showPanelInformation: showPanel,
      saleDuplicate: sale,
    });
  };

  resultsAdvancedFilter = (data) => {
    this.setState({
      advancedFilterActive: true,
    });

    const result = data.response.data;
    const page = result.page;

    const items = result.value;

    let list = [];

    if (page === 1) {
      list = items;
      window.scrollTo({ top: 0 });
      this.setState({
        pageAdvancedFilter: 1,
      });
    } else {
      let current_list = [...this.state.sales];
      list = current_list.concat(items);

      const unique = list.filter((sale, index) => {
        return index === list.findIndex((item) => sale.id === item.id);
      });

      list = unique;
    }

    this.setState({
      sales: list,
      loading_page: false,
      total_results: result.count,
      api_has_more: result.amountResults === 100 ? true : false,
    });

    this.loadInfinitScroll = true;
  };

  filtersAdvancedFilter = (filters) => {
    try {
      filters.map((field) => {
        if (field.name === GENERAL_SEARCH) {
          this.setState({
            search: field.value,
          });
        }

        let classField = "";
        let classFieldCheckbox = "";

        classField = sprintf("advanced_filter_%s_%s", field.model, field.name);

        classFieldCheckbox = sprintf(
          "advanced_filter_empty_%s_%s",
          field.model,
          field.name
        );

        let element;
        let elementChecbox;

        if (field.typeField === "date") {
          element = document.getElementsByClassName(classField);

          if (element.length > 0) {
            let dateMin = element[0].getElementsByClassName("min_date_picker");
            let dateMax = element[0].getElementsByClassName("max_date_picker");

            let valuesDates = field.valueNew
              ? field.valueNew.split(",")
              : field.value.split(",");

            setTimeout(() => {
              if (valuesDates[0]) {
                dateMin[0].value = moment(valuesDates[0], "YYYY/DD/MM").format(
                  "DD/MM/YYYY"
                );
              }

              if (valuesDates[1]) {
                dateMax[0].value = moment(valuesDates[1], "YYYY/DD/MM").format(
                  "DD/MM/YYYY"
                );
              }
            }, 10);
          }
        } else if (field.typeField === "select") {
          let elementDiv = document.getElementsByClassName(classField);
          if (elementDiv.length > 0) {
            let elementChecbox =
              elementDiv[0].getElementsByClassName("form-check-input");

            if (elementChecbox.length > 0) {
              elementChecbox[0].checked = field.valueNew ?? field.value;
            }
          }
        } else {
          element = document.getElementsByClassName(classField);

          if (element.length > 0) {
            element[0].value = field.valueNew ?? field.value;
          }
        }

        elementChecbox = document.getElementsByClassName(classFieldCheckbox);

        if (elementChecbox.length > 0) {
          elementChecbox =
            elementChecbox[0].getElementsByClassName("form-check-input");
          if (elementChecbox.length > 0) {
            elementChecbox[0].checked = field.isEmpty;
          }
        }

        return null;
      });

      this.setState({
        dataSaleDateMinDate: new Date(),
      });
    } catch (error) {
      console.log(error);
    }
  };

  parentDataForAdvancedFilter = (filters) => {
    this.setState({
      dataForAdvancedFilter: filters,
    });
  };

  filtersExportAdvancedFilter = (filters) => {
    this.setState({
      dataAdvancedFilter: filters,
    });
  };

  parentIsLoading = (value) => {
    this.setState({
      loading_page: value,
    });
  };

  handleExportReport = (event) => {
    this.setState({
      exportReport: event.target.value,
    });
  };

  downloadReport = () => {
    if (this.state.exportReport === EXPORT_SALES_REPORT) {
      this.generateSalesExcelAdvancedFilter();
    }

    if (this.state.exportReport === EXPORT_WORKFLOW_COST) {
      this.generateWorkflowCostsReportAdvancedFilter();
    }
  };

  handleResetInformationCallback = (saleWorflowEditData) => {
    this.setState({
      resetInformation: saleWorflowEditData,
    });
  };

  sortDateOfSale = () => {
    if (typeof this.filter_params.order_type !== "undefined") {
      if (this.filter_params.order_type === "DESC") {
        this.filter_params.order_type = "ASC";
      } else {
        this.filter_params.order_type = "DESC";
      }
    }

    this.filter_params.page = 1;
    this.filter_params.max_results = 100;
    this.filter_params.order_by = "saleDate";

    let dataForAdvancedFilterOrder = {
      orderBy: this.filter_params.order_by,
      orderType: this.filter_params.order_type,
      modelOrder: ORDER_MODEL,
    };

    this.setState(
      {
        dataForAdvancedFilterOrder: dataForAdvancedFilterOrder,
        pageAdvancedFilter: 1,
      },
      () => {
        setTimeout(() => {
          this.fetchSalesAdvancedFilter();
        }, 50);
      }
    );
  };

  sortDateOfSaleMyList = () => {
    let addToMyListSelectedSales = this.state.addToMyListSelectedSales;
    let addToMyListSelectedSalesSortedType =
      this.state.addToMyListSelectedSalesSortedType;

    if (addToMyListSelectedSalesSortedType === "DESC") {
      addToMyListSelectedSalesSortedType = "ASC";
      addToMyListSelectedSales.sort(function (a, b) {
        var keyA = new Date(a.saleDate);
        var keyB = new Date(b.saleDate);

        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;

        return 0;
      });
    } else {
      addToMyListSelectedSalesSortedType = "DESC";
      addToMyListSelectedSales.sort(function (a, b) {
        var keyA = new Date(a.saleDate);
        var keyB = new Date(b.saleDate);

        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;

        return 0;
      });
    }

    this.setState({
      addToMyListSelectedSales: addToMyListSelectedSales,
      addToMyListSelectedSalesSortedType: addToMyListSelectedSalesSortedType,
      addToMyListSelectedSalesSortedBy: "saleDate",
    });
  };

  sortDateCustomerNeedsBy = async () => {
    if (typeof this.filter_params.order_type !== "undefined") {
      if (this.filter_params.order_type === "DESC") {
        this.filter_params.order_type = "ASC";
      } else {
        this.filter_params.order_type = "DESC";
      }
    }
    this.filter_params.page = 1;
    this.filter_params.max_results = 100;
    this.filter_params.order_by = "customerNeedsOn";

    let dataForAdvancedFilterOrder = {
      orderBy: this.filter_params.order_by,
      orderType: this.filter_params.order_type,
      modelOrder: ORDER_MODEL,
    };

    await this.setState(
      {
        dataForAdvancedFilterOrder: dataForAdvancedFilterOrder,
        pageAdvancedFilter: 1,
      },
      () => {
        setTimeout(() => {
          this.fetchSalesAdvancedFilter();
        }, 50);
      }
    );
  };

  sortDateCustomerNeedsByMyList = () => {
    let addToMyListSelectedSales = this.state.addToMyListSelectedSales;
    let addToMyListSelectedSalesSortedType =
      this.state.addToMyListSelectedSalesSortedType;

    if (addToMyListSelectedSalesSortedType === "DESC") {
      addToMyListSelectedSalesSortedType = "ASC";
      addToMyListSelectedSales.sort(function (a, b) {
        var keyA = new Date(a.customerNeedsOn);
        var keyB = new Date(b.customerNeedsOn);

        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;

        return 0;
      });
    } else {
      addToMyListSelectedSalesSortedType = "DESC";
      addToMyListSelectedSales.sort(function (a, b) {
        var keyA = new Date(a.customerNeedsOn);
        var keyB = new Date(b.customerNeedsOn);

        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;

        return 0;
      });
    }

    this.setState({
      addToMyListSelectedSales: addToMyListSelectedSales,
      addToMyListSelectedSalesSortedType: addToMyListSelectedSalesSortedType,
      addToMyListSelectedSalesSortedBy: "customerNeedsOn",
    });
  };

  closeEditionComponent = (edited_sale, ischange = true) => {
    if (!ischange) {
      this.setState({
        sale_to_edit: null,
      });

      return;
    }

    if (edited_sale === null && edited_sale === undefined) {
      return;
    }

    if (this.state.currentTab === "results") {
      this.fetchSalesAdvancedFilter();
    }

    const sales_list = this.state.sales;
    let selected_sale = this.state.selected_sale;

    let results = [];

    if (edited_sale !== null && edited_sale !== undefined) {
      results = sales_list.map((item) => {
        if (item.id === edited_sale.id) {
          item = edited_sale;
        }

        return item;
      });

      selected_sale = edited_sale;
    }

    for (var i = 0; i < this.state.addToMyListSelectedSales.length; i++) {
      if (edited_sale) {
        if (this.state.addToMyListSelectedSales[i].id === edited_sale.id) {
          const tempAddToMyList = this.state.addToMyListSelectedSales;
          tempAddToMyList[i] = edited_sale;
          let tempIds = [];
          Object.keys(tempAddToMyList).forEach((index) => {
            tempIds.push(tempAddToMyList[index].id);
          });
          this.setState({
            addToMyListSelectedSales: tempAddToMyList,
          });
          break;
        }
      } else {
        break;
      }
    }

    this.setState(
      {
        myListTabs: [],
      },
      this.getcustomisedList()
    );

    if (selected_sale) {
      this.setState({
        sale_to_edit: null,
        sales: results,
        selected_sale: selected_sale,
        show_workflow_panel: false,
        selected_sale_information: selected_sale,
      });
    }
  };

  updateSaleInformation = (sale) => {
    if (!sale) {
      return null;
    }

    this.setState({
      selected_sale_information: sale,
    });
  };

  updateSaleForList = (index, newSale) => {
    this.setState((prevState) => {
      const updatedSales = [...prevState.sales];
      updatedSales[index] = newSale;
      return {
        sales: updatedSales,
        sale_to_edit: null,
      };
    });
  };

  updateWorkflowSaleInformation = (update) => {
    if (!update) {
      return null;
    }

    this.saleInformation.current.changeToWorkflowTab();
  };

  openEditionComponent = (sale_to_edit) => {
    this.setState({
      sale_to_edit: sale_to_edit,
    });
  };

  closeWorkflowEditionComponent = (isChange = false) => {
    this.setState({
      workflow_to_edit: null,
    });

    this.saleInformation.current.changeToWorkflowTab();

    if (isChange) {
      let currentSale = this.saleInformation.current.state.sale;

      this.closeEditionComponent(currentSale ?? null, isChange);

      setTimeout(() => {
        if (!currentSale) {
          return;
        }

        let sale = this.state.sales.filter(
          (sale) => sale.id === currentSale.id
        );

        if (sale.length > 0) {
          this.setState({
            selectedSale: sale[0],
            selected_sale_information: sale[0],
          });
        }
      }, 3000);
    }
  };

  openWorkflowEditionComponent = (workflow_to_edit) => {
    this.setState({
      workflow_to_edit: workflow_to_edit,
    });
  };

  closeWorkflowModal = () => {
    this.setState({
      show_workflow_modal: false,
    });
    this.saleInformation.current.changeToWorkflowTab();
  };

  openWorkflowModal = () => {
    this.setState({
      show_workflow_modal: true,
    });
  };

  closeWorkflowPanel = () => {
    this.setState({
      show_workflow_panel: false,
    });
  };

  openWorkflowPanel = () => {
    this.setState({
      show_workflow_panel: true,
    });
  };

  closeQualityCheckPanel = () => {
    this.setState({
      show_quality_check_panel: false,
    });
  };

  closeCreateComponent = () => {
    this.setState({
      show_create_sale_modal: false,
      saleDuplicate: null,
    });
  };

  openCreateComponent = () => {
    this.setState({
      show_create_sale_modal: true,
    });
  };

  closeInformationComponent = () => {
    this.setState({
      show_information_sale_modal: false,
    });
  };

  openInformationComponent = () => {
    this.setState({
      show_information_sale_modal: true,
    });
  };

  closeSaleRemoveMyListModal = () => {
    this.setState({
      show_remove_my_list_modal: false,
    });
  };

  openSaleRemoveMyListModal = () => {
    this.setState({
      show_remove_my_list_modal: true,
    });
  };

  closeConfirmRemoveCustomListModal = () => {
    this.setState({
      show_confirm_remove_custom_list_modal: false,
    });
  };

  openConfirmRemoveCustomListModal = () => {
    if (this.state.currentTab === "results") {
      alert("To delete a list, first choose the “Tab” you want to delete.");
    } else {
      this.setState({
        show_confirm_remove_custom_list_modal: true,
      });
    }
  };

  showUpdateMultipleWorkflowSale = () => {
    this.setState({
      showUpdateMultiple: true,
    });
  };

  onResponseSendMultipleToGD = (value) => {
    this.setState({
      showSendMultipleToGD: value,
    });
  };

  showSendMultipleToGD = () => {
    this.setState({
      showSendMultipleToGD: true,
    });
  };

  onResponseUpdateMultiple = async (value, reload) => {
    if (reload) {
      this.setState(
        {
          myListTabs: [],
          selected_sale_information: null,
        },
        this.getcustomisedList()
      );

      if (this.state.selected_sale) {
        this.setState({
          show_workflow_panel: true,
          show_quality_check_panel: true,
          selected_sale_information: null,
          selected_sale: null,
          selected_sale_index: null,
        });

        await Util.timeAwait(1);

        this.setState({
          show_workflow_panel: false,
          show_quality_check_panel: false,
        });
      }
    }

    this.setState({
      showUpdateMultiple: value,
    });
  };

  closeEditSettingStatusModal = () => {
    this.setState({
      show_edit_setting_status_modal: false,
    });
  };

  addMyListTab() {
    this.createcustomisedList();
  }

  removeCurrentMyListTab() {
    this.removecustomisedList();
    this.closeConfirmRemoveCustomListModal();
  }

  async editSettingStatusCustomisedList() {
    let currentTab = this.state.currentTab;
    let tabCheckedSales = this.state.myListTabs.filter(function (item) {
      return item.name === currentTab;
    });
    try {
      let checkedSales = JSON.parse(tabCheckedSales[0].salesIds);
      await Promise.all(
        checkedSales.map((saleId) => this.editSaleSettingStatus(saleId))
      );
      this.closeEditSettingStatusModal();
      this.setState(
        {
          myListTabs: [],
        },
        this.getcustomisedList()
      );
    } catch (e) {
      alert("Error when choosing checked sales");
    }
  }

  axioRequest = null;
  editSaleSettingStatus(saleId) {
    this.setState({
      loading_page: true,
    });
    const user = Auth.getAuthenticatedUser();
    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else if (saleId === null || saleId === undefined) {
      this.setState({
        error_messages: [
          "Sale identification error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_SALES_EDIT_STATUS_WORKFLOW;
      url = url.replace("{id}", saleId);
      const params = new URLSearchParams();

      params.append("workflowStatus", "In transit");

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      axios
        .put(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            if (data.status === "found") {
              this.setState({
                loading_page: false,
              });
            } else {
              if ("onlineOrderNumber" in data.result) {
                alert(
                  "The record " +
                    data.result.onlineOrderNumber +
                    " has not started the workflow yet"
                );
              }
            }
          } else {
            this.setState({
              loading_page: false,
            });
            alert(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              loading_page: false,
            });
            alert(JSON.stringify(error_messages));
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              loading_page: false,
            });
            alert(JSON.stringify(error_messages));
          } else {
            this.setState({
              loading_page: true,
            });
          }
          return null;
        });
    }
  }

  createcustomisedList() {
    const user = Auth.getAuthenticatedUser();

    let url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_CUSTOMISED_LIST_CREATE;
    const params = new URLSearchParams();
    params.append("name", this.state.newTabName);
    params.append("employeeId", user.employeeModel.id);
    params.append("listType", "sales");

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    };

    axios
      .post(url, params, config)
      .then((response) => {
        const data = response.data;
        if (data !== undefined && data.code === 200) {
          console.log("Customised sale created");
          this.getcustomisedList();
          this.setState({
            newTabName: "",
          });
        } else {
          console.log(
            "There was a problem processing the information. Please reload this page and try again."
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          if (err.response.data.error === "No name defined") {
            alert("No name defined");
          }
          if (err.response.data.error === "Tab name already exists") {
            alert("Tab name already exists");
          }
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log(err);
        }
        return null;
      });
  }

  removecustomisedList() {
    const user = Auth.getAuthenticatedUser();

    let url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_CUSTOMISED_LIST_DELETE;
    const params = new URLSearchParams();
    params.append("name", this.state.currentTab);
    params.append("employeeId", user.employeeModel.id);

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    };

    axios
      .put(url, params, config)
      .then((response) => {
        const data = response.data;
        if (data !== undefined && data.code === 200) {
          console.log("Customised sale deleted");
          this.getcustomisedList();
          this.setState({
            currentTab: "results",
          });
        } else {
          console.log(
            "There was a problem processing the information. Please reload this page and try again."
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log(err);
        }
        this.setState({
          loading_excel: false,
        });
        return null;
      });
  }

  removeSaleFromCustomisedList(checkedSales, page = 1) {
    if (page === 0) {
      page = this.state.pageAdvancedFilter ?? 1;
    }

    if (
      this.state.currentTab !== "results" ||
      (this.state.currentTab === "results" &&
        this.state.selectedTabToAdd !== null &&
        this.state.selectedTabToAdd !== "")
    ) {
      let newSalesIds = checkedSales;
      let tabName;
      if (this.state.currentTab !== "results") {
        tabName = this.state.currentTab;
      } else {
        tabName = this.state.selectedTabToAdd;
      }

      const user = Auth.getAuthenticatedUser();

      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_CUSTOMISED_LIST_UPDATE;
      const params = new URLSearchParams();
      params.append("name", tabName);
      params.append("employeeId", user.employeeModel.id);
      params.append("salesIds", JSON.stringify(newSalesIds));

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      axios
        .put(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.getcustomisedList();
            this.resetFilters();
            this.setState(
              {
                pageAdvancedFilter: page,
              },
              this.getCheckedSales(null, true)
            );
          } else {
            console.log(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
          } else if (err.request) {
            console.log(err.request);
          } else {
            console.log(err);
          }
          return null;
        });
    }
  }

  generateWorkflowCostsReportAdvancedFilter = async () => {
    this.setState({
      loading_sales_excel: true,
    });

    if (this.state.currentTab === TAB_GENERAL) {
      await generateWorkflowCostsReportAdvancedFilterResults(
        this.state.dataAdvancedFilter
      );
    }

    if (this.state.currentTab !== TAB_GENERAL) {
      let tabCheckedSales = this.state.myListTabs.filter((myList) => {
        return myList.name === this.state.currentTab;
      });

      await generateWorkflowCostsReportAdvancedFilterPersonalTab(
        tabCheckedSales
      );
    }

    this.setState({
      loading_sales_excel: false,
    });
  };

  generateSalesExcelAdvancedFilter = async () => {
    this.setState({
      loading_sales_excel: true,
    });

    if (this.state.currentTab === TAB_GENERAL) {
      await generateSalesExcelAdvancedFilterResults(
        this.state.dataAdvancedFilter
      );
    }

    if (this.state.currentTab !== TAB_GENERAL) {
      let tabCheckedSales = this.state.myListTabs.filter((myList) => {
        return myList.name === this.state.currentTab;
      });

      await generateSalesExcelAdvancedFilterPersonalTab(tabCheckedSales);
    }

    this.setState({
      loading_sales_excel: false,
    });
  };

  handleSaleMinDateFilter = (date) => {
    if (date !== null && date !== "") {
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      this.filter_params.saleMinDate = year + "-" + month + "-" + day;
    } else {
      this.filter_params.saleMinDate = null;
    }

    this.setDataForAdvancedFilter({
      name: "sale_date",
      model: "salesModel",
      type: "date",
      value: this.filter_params.saleMinDate,
    });

    this.resetSaleInformation();
  };

  handleSaleMaxDateFilter = (date) => {
    this.filter_params.page = 1;
    if (date !== null && date !== "") {
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      this.filter_params.saleMaxDate = year + "-" + month + "-" + day;
    } else {
      this.filter_params.saleMaxDate = null;
    }

    this.setDataForAdvancedFilter({
      name: "sale_date",
      model: "salesModel",
      type: "date",
      value: this.filter_params.saleMaxDate,
    });

    this.resetSaleInformation();
  };

  handleMinDateCustomerNeedsByFilter = (date) => {
    if (date !== null && date !== "") {
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      this.filter_params.customerNeedsOnMin = year + "-" + month + "-" + day;
      //this.filter_params.order_by = 'customerNeedsOn'
    } else {
      this.filter_params.customerNeedsOnMin = null;
      //this.filter_params.order_by = 'saleDate'
    }

    this.resetSaleInformation();

    this.setDataForAdvancedFilter({
      name: "customer_needs_on",
      model: "salesModel",
      type: "date",
    });
  };

  handleMaxDateCustomerNeedsByFilter = (date) => {
    this.filter_params.page = 1;
    if (date !== null && date !== "") {
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      this.filter_params.customerNeedsOnMax = year + "-" + month + "-" + day;
      //this.filter_params.order_by = 'customerNeedsOn'
    } else {
      this.filter_params.customerNeedsOnMax = null;
      //this.filter_params.order_by = 'saleDate'
    }

    this.resetSaleInformation();

    this.setDataForAdvancedFilter({
      name: "customer_needs_on",
      model: "salesModel",
      type: "date",
    });
  };

  checkAll = (e) => {
    e.preventDefault();

    if (
      this.state.selectedTabToAdd === null ||
      this.state.selectedTabToAdd === ""
    ) {
      e.preventDefault();
      alert(
        "You need to select a personalised list from the dropdown to add elements to it."
      );
    } else {
      let newCheckedSales = [];
      this.state.sales.map((el) => newCheckedSales.push(el.id));
      this.setState(
        {
          checkedSales: newCheckedSales,
          myListTabs: [],
        },
        this.removeSaleFromCustomisedList(
          newCheckedSales,
          this.state.pageAdvancedFilter
        )
      );
    }
  };

  handleFilterChangeGeneralFilter = async (event, dataAdvancedFilter) => {
    const { value } = event.target;

    await this.setState({
      search: value,
    });

    this.debouncedHandleFilterChange(event, dataAdvancedFilter);
  };

  handleFilterChange = (event, dataAdvancedFilter = null) => {
    const { value, checked } = event.target;

    dataAdvancedFilter.value = value;
    dataAdvancedFilter.checked = checked;

    this.setDataForAdvancedFilter(dataAdvancedFilter);

    this.resetSaleInformation();
  };

  setDataForAdvancedFilter = async (dataAdvancedFilter) => {
    try {
      this.loadInfinitScroll = false;
      if (!dataAdvancedFilter) {
        return;
      }

      let dataForFilter = {
        name: "",
        model: "",
        value: "",
        isEmpty: false,
        isSelected: true,
      };

      let dataForAdvancedFilter = this.state.dataForAdvancedFilter;

      let data = dataForAdvancedFilter.filter(
        (item) =>
          item.name === dataAdvancedFilter.name &&
          item.model === dataAdvancedFilter.model
      );

      dataForFilter.name = dataAdvancedFilter.name;
      dataForFilter.model = dataAdvancedFilter.model;

      let elementCheckbox = {};

      let classFieldCheckbox = sprintf(
        "advanced_filter_empty_%s_%s",
        dataAdvancedFilter.model,
        dataAdvancedFilter.name
      );

      let elementDiv = document.getElementsByClassName(classFieldCheckbox);
      elementCheckbox =
        elementDiv[0]?.getElementsByClassName("form-check-input");

      let dates = {};

      if (dataAdvancedFilter.type === "date") {
        let classField = sprintf(
          "advanced_filter_%s_%s",
          dataAdvancedFilter.model,
          dataAdvancedFilter.name
        );

        let elementTH = document.getElementsByClassName(classField);

        dates = await this.getValueDatePicker(elementTH);
      }

      if (dataAdvancedFilter.type !== "date") {
        dataForFilter.value = dataAdvancedFilter.value;

        if (dataAdvancedFilter.type === "checkbox") {
          dataForFilter.value = "";
        }
      } else {
        if (dates.minDatePicker && dates.maxDatePicker) {
          dataForFilter.value = sprintf(
            "%s,%s",
            dates.minDatePicker
              ? moment(dates.minDatePicker, "DD/MM/YYYY").format("YYYY/DD/MM")
              : "",
            dates.maxDatePicker
              ? moment(dates.maxDatePicker, "DD/MM/YYYY").format("YYYY/DD/MM")
              : ""
          );
        } else {
          dataForFilter.value = "";
        }
      }

      if (
        dataAdvancedFilter.name === "urgent_date_requested_by_customer" &&
        dataAdvancedFilter.checked === true
      ) {
        dataForFilter.value = dataAdvancedFilter.checked;
      }

      dataForFilter.isSelected = true;
      dataForFilter.isEmpty =
        elementCheckbox !== undefined ? elementCheckbox[0].checked : "";

      if (data.length > 0) {
        dataForAdvancedFilter = dataForAdvancedFilter.map((item) => {
          if (
            item.name === dataAdvancedFilter.name &&
            item.model === dataAdvancedFilter.model
          ) {
            item = dataForFilter;
          }
          return item;
        });
      } else {
        dataForAdvancedFilter.push(dataForFilter);
      }

      await this.setState(
        {
          pageAdvancedFilter: 1,
          dataForAdvancedFilter: dataForAdvancedFilter,
          disabledSearch: dataForAdvancedFilter.length > 0 ? false : true,
        },
        () => {
          setTimeout(() => {
            this.fetchSalesAdvancedFilter();
          }, 50);
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  getValueDatePicker = async (elementTH) => {
    let minDatePicker = await elementTH[0].getElementsByClassName(
      "min_date_picker"
    );
    let maxDatePicker = await elementTH[0].getElementsByClassName(
      "max_date_picker"
    );

    return {
      minDatePicker: minDatePicker[0].value,
      maxDatePicker: maxDatePicker[0].value,
    };
  };

  resetSaleInformation = () => {
    setTimeout(() => {
      this.setState({
        show_workflow_panel: true,
        highlight_row: false,
      });
    }, 1);

    setTimeout(() => {
      this.setState({
        show_workflow_panel: false,
        selected_sale: null,
        selected_sale_information: null,
      });
    }, 2);
  };

  handleNewTabNameChange(e) {
    this.setState({
      newTabName: e.target.value,
    });
  }

  uncheckAllMyList = () => {
    try {
      for (var i = 0; i < this.state.addToMyListSelectedSales.length; i++) {
        if (this.state.addToMyListSelectedSales[i] !== null) {
          if (
            typeof this.refs[
              "saleRow" + this.state.addToMyListSelectedSales[i].id
            ] !== "undefined"
          ) {
            this.refs[
              "saleRow" + this.state.addToMyListSelectedSales[i].id
            ].setState({
              addToMyList: false,
            });
          }
        }
      }
      //Cookies.set('addToMyListSelectedSalesId', []);
      this.setState({
        addToMyListSelectedSales: [],
        show_remove_my_list_modal: false,
      });
    } catch (err) {}
  };

  handleSheetTypeChange = (event) => {
    this.setState(
      {
        sheetType: event.target.value,
      },
      () => {
        setTimeout(() => {
          this.fetchSalesAdvancedFilter();
        }, 50);
      }
    );
  };

  handleRowSelection = (index) => {
    const sale = this.state.sales[index];
    const sale_id = sale.id;

    this.saleInformation.current.reset();

    this.fetchSaleDetailWithId(sale_id, index);

    if (window.innerWidth <= 600) {
      this.openInformationComponent();
    }
  };

  saleInformationCustomizeMyList = () => {
    this.saleInformation.current.reset();
  };

  handleRowSelectionMyList = (index) => {
    const sale = this.state.addToMyListSelectedSales[index];
    const sale_id = sale.id;
    this.fetchSaleDetailWithId(sale_id, index);

    if (window.innerWidth <= 600) {
      this.openInformationComponent();
    }
  };

  handleChangeCheckbox = (index, state) => {
    const sale = this.state.sales[index];
    const sale_id = sale.id;
    var newCheckedSales = this.state.checkedSales;

    if (this.state.checkedSales.includes(sale_id)) {
      newCheckedSales = newCheckedSales.filter(function (e) {
        return e !== sale_id;
      });
    } else {
      newCheckedSales.push(sale_id);
    }

    let page = this.state.pageAdvancedFilter;
    if (page <= 0) {
      page = 1;
    }

    this.setState(
      {
        checkedSales: newCheckedSales,
        myListTabs: [],
      },
      this.removeSaleFromCustomisedList(newCheckedSales, page)
    );
  };

  handleChangeCheckboxMyList = (index, state) => {
    const sale = this.state.addToMyListSelectedSales[index];
    const sale_id = sale.id;

    let temp = this.state.addToMyListSelectedSales.filter(function (
      addToMyListSale
    ) {
      return addToMyListSale.id !== sale_id;
    });
    this.setState({
      addToMyListSelectedSales: temp,
    });

    let tempIds = [];

    Object.keys(temp).forEach((index) => {
      tempIds.push(temp[index].id);
    });

    try {
      this.refs["saleRow" + sale_id].setState({
        addToMyList: false,
      });

      setTimeout(
        function () {
          this.handleAddToMyList(sale.id);
        }.bind(this),
        5000
      );
    } catch (err) {}

    setTimeout(
      function () {
        this.handleAddToMyList(sale.id);
      }.bind(this),
      5000
    );
  };

  handleSelect = (key) => {
    let tabCheckedSales = this.state.myListTabs.filter(function (item) {
      return item.name === key;
    });
    let tabLength = 0;
    try {
      if (typeof tabCheckedSales[0] !== "undefined") {
        if (typeof tabCheckedSales[0].salesIds !== "undefined") {
          if (tabCheckedSales[0].salesIds !== null) {
            tabLength = JSON.parse(tabCheckedSales[0].salesIds).length;
          }
        }
      }
    } catch (e) {}

    this.setState({
      currentTab: key,
      currentTabCount: tabLength,
    });
  };

  handleSelectMyListTab = (event) => {
    this.selectedTab = event.target.value;
    this.setState({
      selectedTab: event.target.value,
    });
    this.setTabToAdd(event.target.value);
  };

  handleAddToMyList = (sale_id) => {
    for (var i = 0; i < this.state.addToMyListSelectedSales.length; i++) {
      if (this.state.addToMyListSelectedSales[i] !== null) {
        if (this.state.addToMyListSelectedSales[i].id === sale_id) {
          return true;
        }
      }
    }
    return false;
  };

  openAddToMyListModal() {
    this.setState({
      show_add_to_my_list_modal: true,
    });
  }

  closeAddToMyListModal() {
    this.setState({
      show_add_to_my_list_modal: false,
    });
  }

  setTabToAdd(selectedTab) {
    this.setState({
      selectedTabToAdd: selectedTab,
    });

    if (selectedTab === "" || selectedTab === null) {
      this.resetFilters();
      this.setState(
        {
          pageAdvancedFilter: 1,
          checkedSales: [],
        },
        () => {
          setTimeout(() => {
            this.fetchSalesAdvancedFilter();
          }, 50);
        }
      );
    } else {
      this.resetFilters();
      this.setState(
        {
          checkedSales: [],
        },
        this.getCheckedSales(selectedTab, true)
      );
    }
  }

  getcustomisedList() {
    const user = Auth.getAuthenticatedUser();

    let url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_CUSTOMISED_LIST_LIST;
    const params = new URLSearchParams();
    params.append("employeeId", user.employeeModel.id);
    params.append("listType", "sales");

    axios
      .get(url, {
        params: params,
        headers: { Authorization: `Bearer ${Auth.getToken()}` },
      })
      .then((response) => {
        const data = response.data;
        if (data !== undefined && data.code === 200) {
          var tabs = [];
          data.result.rows.map((tab) => {
            tabs.push({
              name: tab.name,
              salesIds: tab.salesIds,
              created: tab.created,
            });
            return null;
          });
          tabs.sort((a, b) => a.created.localeCompare(b.created));
          this.setState({
            myListTabs: tabs,
          });
        } else {
          console.log(
            "There was a problem processing the information. Please reload this page and try again."
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log(err);
        }
        this.setState({
          loading_excel: false,
        });
        return null;
      });
  }

  getCheckedSales(selectedTab = null, fetchSales = false) {
    var tabName = selectedTab;

    if (tabName === null) {
      tabName = this.state.selectedTabToAdd;
    }

    if (tabName !== null) {
      const user = Auth.getAuthenticatedUser();

      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_CUSTOMISED_LIST_DETAIL;
      const params = new URLSearchParams();
      params.append("name", tabName);
      params.append("employeeId", user.employeeModel.id);

      axios
        .get(url, {
          params: params,
          headers: { Authorization: `Bearer ${Auth.getToken()}` },
        })
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            if ("salesIds" in data.result) {
              this.resetFilters();
              if (Array.isArray(JSON.parse(data.result.salesIds))) {
                var checkedSales = JSON.parse(data.result.salesIds);
                this.setState(
                  {
                    checkedSales: checkedSales,
                  },
                  fetchSales
                    ? () => {
                        setTimeout(() => {
                          this.fetchSalesAdvancedFilter();
                        }, 50);
                      }
                    : null
                );
              } else {
                this.setState(
                  {
                    checkedSales: [],
                  },
                  fetchSales
                    ? () => {
                        setTimeout(() => {
                          this.fetchSalesAdvancedFilter();
                        }, 50);
                      }
                    : null
                );
              }
            }
          } else {
            console.log(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
          } else if (err.request) {
            console.log(err.request);
          } else {
            console.log(err);
          }
          this.setState({
            loading_excel: false,
          });
          return null;
        });
    }
  }

  saleIsChecked(saleId) {
    try {
      return this.state.checkedSales.includes(saleId);
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  ajaxRequestForDetail = null;
  fetchSaleDetailWithId(sale_id, selected_sale_index) {
    this.setState({
      loading_page: true,
    });
    if (this.ajaxRequestForDetail !== null) {
      this.ajaxRequestForDetail.cancel();
    }

    this.ajaxRequestForDetail = axios.CancelToken.source();

    let url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_SALES_DETAIL;
    url = url.replace("{id}", sale_id);

    axios
      .get(
        url,
        {
          headers: { Authorization: `Bearer ${Auth.getToken()}` },
        },
        {
          cancelToken: this.ajaxRequestForDetail.token,
        }
      )
      .then((api_response) => {
        const selected_sale = api_response.data.result;
        this.setState({
          selected_sale: selected_sale,
          selected_sale_index: selected_sale_index,
          loading_page: false,
          selected_sale_information: selected_sale,
        });
      })
      .catch((err) => {
        if (err.response) {
          if ("status" in err.response && err.response.status === 401) {
            alert("User not authorised. Please reload the page");
          } else {
            alert(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }
          this.setState({
            loading_page: false,
          });
        } else if (err.request) {
          alert(
            "There was a problem with your request. Please reload this page and try again."
          );
          this.setState({
            loading_page: false,
          });
        } else {
          this.setState({
            loading_page: true,
          });
        }
        console.log(err);
        return null;
      });
  }

  getTabCheckedSale(tabName) {
    return tabName;
  }

  async fetchCheckedSales() {
    var addToMyListSelectedSales = [];
    let tempAddToMyListIds = {};

    Object.keys(tempAddToMyListIds).forEach((index) => {
      if (this.ajaxRequestForDetail !== null) {
        this.ajaxRequestForDetail.cancel();
      }

      this.ajaxRequestForDetail = axios.CancelToken.source();

      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_SALES_DETAIL;
      url = url.replace("{id}", tempAddToMyListIds[index]);

      axios
        .get(
          url,
          {
            headers: { Authorization: `Bearer ${Auth.getToken()}` },
          },
          {
            cancelToken: this.ajaxRequestForDetail.token,
          }
        )
        .then((api_response) => {
          let saleTemp = api_response.data.result;
          addToMyListSelectedSales.push(saleTemp);
          this.setState({
            addToMyListSelectedSales: addToMyListSelectedSales,
          });
        })
        .catch((err) => {
          if (err.response) {
            if ("status" in err.response && err.response.status === 401) {
              alert("User not authorised. Please reload the page");
            } else {
              alert(
                "There was a problem processing the information. Please reload this page and try again."
              );
            }
            this.setState({
              loading_page: false,
            });
          } else if (err.request) {
            alert(
              "There was a problem with your request. Please reload this page and try again."
            );
            this.setState({
              loading_page: false,
            });
          } else {
            this.setState({
              loading_page: true,
            });
          }
          console.log(err);
          return null;
        });
    });
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  componentDidMount() {
    //execute once the first time
    this.fetchSalesAdvancedFilter();
    this.getcustomisedList();
    this.getCheckedSales();

    if (this.state.search) {
      this.debouncedHandleFilterChange(
        {
          target: {
            value: this.state.search,
            checked: null,
          },
        },
        {
          name: GENERAL_SEARCH,
          model: "salesModel",
        }
      );
    }
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default withNavigation(SalesList);
