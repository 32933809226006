import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Form, Row } from "react-bootstrap";
import FormInputCheckbox from "../../../../Form/FormInputCheckbox";
import { useDispatch } from "react-redux";
import { updateQuestions } from "../../../../../store/slices";

const FormQuestionQualityControl = ({
  question,
  index,
  groupIndex,
  editForm,
}) => {
  const dispatch = useDispatch();

  const data = JSON.parse(JSON.stringify(question));
  const { label, fieldName, value, comments } = question;

  const [comment, setComment] = useState(comments[0]?.message ?? "");
  const [inputCheck, setInputCheck] = useState(value ?? false);

  const handleChangeInput = (value) => {
    data["value"] = value;

    setInputCheck(value);

    dispatch(updateQuestions({ data: data, groupIndex, index }));
  };

  const changeComment = (event) => {
    const { value } = event.target;

    data.comments[0].message = value;

    setComment(value);

    dispatch(updateQuestions({ data: data, groupIndex, index }));
  };

  return (
    <Row lg={12} xs={12}>
      <Col lg={6} xs={6}>
        <FormInputCheckbox
          typeView="checkbox"
          type="checkbox"
          label={label}
          nameInput={fieldName}
          checked={inputCheck ?? false}
          required={false}
          onResponse={handleChangeInput}
          disabled={editForm}
        />
      </Col>
      <Col lg={6} xs={6}>
        <Form.Group className="form-group">
          <Form.Control
            required={false}
            type="text"
            name={comments[0].fieldName}
            value={comment ?? ""}
            onChange={changeComment}
            disabled={editForm}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

FormQuestionQualityControl.propTypes = {
  question: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  groupIndex: PropTypes.number.isRequired,
  editForm: PropTypes.bool,
};

FormQuestionQualityControl.defaultProps = {
  editForm: true,
};

export default FormQuestionQualityControl;
