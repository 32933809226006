import React, { Component } from "react";

// SCSS
import "../../../css/sales/sale_information.scss";
import Util from "../../../Util";
import { NavLink } from "react-bootstrap";
import { convertUtcToTimezone } from "../../../utils/dateUtils";

class SaleInformationGeneral extends Component {
  constructor(props) {
    super();
    this.state = {
      sale: props.sale,
    };
  }

  openCustomer = (id) => {
    window.open(`/customers?customer-ref=${id}`);
  };

  render() {
    const sale = this.state.sale;
    let saleDate = "";

    if (sale.saleDate) {
      saleDate = convertUtcToTimezone(
        sale.saleDate,
        "DD MMM, YYYY HH:mm",
        Util.getStorageParameter("REACT_APP_TIMEZONE")
      );
    }

    return (
      <div className="sale_information_subcontainer">
        <div className="container">
          <div className="row">
            <div className="col-sm border-right bold">Unique #</div>
            <div className="col-sm">{sale.id}</div>
          </div>
          <div className="row">
            <div className="col-sm border-right bold">To be delivered to</div>
            <div className="col-sm">
              <div
                dangerouslySetInnerHTML={{
                  __html: Util.getShippingAddressFromSale(sale),
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm border-right bold">Date of Sale</div>
            <div className="col-sm border-right">
              {saleDate !== null ? (
                <div className="saleDate">{saleDate}</div>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-sm border-right bold">Name</div>
            <div className="col-sm border-right">{sale.name}</div>
          </div>
          <div className="row">
            <div className="col-sm border-right bold">Phone</div>
            <div className="col-sm border-right">{sale.phone}</div>
          </div>
          <div className="row">
            <div className="col-sm border-right pb-2 bold">Email</div>
            <div className="col-sm border-right pb-2">
              {sale?.email && (
                <NavLink
                  onClick={() => this.openCustomer(sale.email)}
                  className="link-primary"
                >
                  {sale.email}
                </NavLink>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  static getDerivedStateFromProps(props, state) {
    return {
      sale: props.sale,
    };
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default SaleInformationGeneral;
