import React, { Component } from 'react';
import { Form, FormLabel } from 'react-bootstrap';
import ChangeCostreadyActivity from './ChangeCostreadyActivity';

// SCSS
import '../../../../css/sales/sale_edit.scss'
import Util from '../../../../Util';

class SaleWorkflowEditValuation extends Component {

    constructor(props) {
        super();
        this.state = {
            activity: props.activity,
            cost: props.activity.cost,
            invoiceNumber: props.activity.invoiceNumber,
            invoiceTo: props.activity.invoiceTo ?? '',
            readyToPayToProvider: props.activity.readyToPayToProvider,
            showModalCostready: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this)
        this.handleChangeSelect = this.handleChangeSelect.bind(this)

        this.listInvoicesTo = Util.getStorageParameter("REACT_APP_PROVIDERS").split(',')
    }

    handleChangeNumber = (event) => {
        let state = {}
        let { value } = event.target
        const { name } = event.target

        value = value.replace(/[^\d.]/g, '')

        state[name] = value

        this.setState(state)
    }

    handleChange(event) {
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
        this.setState(state)
    }

    handleChangeCheckbox(event) {
        const field_name = event.target.name
        let state = {}

        if (field_name === 'readyToPayToProvider' && event.target.checked) {
            this.showModalCostready(true)
        } else {
            state[field_name] = event.target.checked
            this.setState(state)
        }
    }

    handleChangeSelect(event) {
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
        this.setState(state)
    }

    render() {
        return (
            <div className='sale_information_subcontainer'>
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <Form.Group className="form-group">
                                <FormLabel>Cost</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="cost"
                                    value={this.state.cost === null ? '' : this.state.cost}
                                    onChange={this.handleChangeNumber}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group className="form-group">
                                <FormLabel>Invoice number</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="invoiceNumber"
                                    value={this.state.invoiceNumber === null ? '' : this.state.invoiceNumber}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-12">
                            <Form.Group>
                                <FormLabel className="d-block">Invoice to</FormLabel>
                                <Form.Select
                                    name="invoiceTo"
                                    value={this.state.invoiceTo}
                                    onChange={(e) => this.handleChangeSelect(e)}
                                >
                                    <option value=""></option>
                                    {
                                        this.listInvoicesTo.map(invoiceTo => {
                                            return (
                                                <option
                                                    key={invoiceTo}
                                                    value={invoiceTo}>
                                                    {invoiceTo}
                                                </option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="col-12">
                            <Form.Check
                                type="checkbox"
                                name="readyToPayToProvider"
                                label="Cost ready"
                                disabled={this.state.readyToPayToProvider}
                                checked={this.state.readyToPayToProvider === true ? true : false}
                                onChange={this.handleChangeCheckbox}
                            />
                        </div>
                    </div>
                </div>

                {
                    (this.state.showModalCostready) ?
                        <ChangeCostreadyActivity
                            showModalCostready={this.state.showModalCostready}
                            parentResponseCostReady={this.responseCostReady}
                            cost={this.state.cost}
                        />
                        :
                        (null)
                }
            </div>
        )
    }

    showModalCostready = (value) => {
        this.setState({
            showModalCostready: value
        })
    }

    responseCostReady = (value) => {
        this.setState({
            readyToPayToProvider: value
        })

        this.showModalCostready(false)
    }
}

export default SaleWorkflowEditValuation;