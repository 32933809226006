import { useSelector, useDispatch } from "react-redux";
import {
  clearCustomers,
  createCustomizedList,
  deleteCustomizedList,
  fetchCustomerCustomizedLists,
  fetchCustomers,
  resetCustomers,
  searchCustomers,
  setDownloadCSV,
} from "../../../store/slices";
import { useCallback, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import CustomersToolbar from "./toolbar";
import axios from "axios";
import {
  CUSTOMERS_LIST_TYPE_DEFAULT,
  CUSTOMERS_LIST_TYPE_SEARCH,
} from "../../../store/constants";
import DefaultList from "./default-list";
import CustomizedList, { fetchSheet } from "./customized-list";
import { sprintf } from "sprintf-js";
import moment from "moment";
import axiosServices from "../../../axios";
import { DownloadField } from "../../actions/ExportReportSales";
import Auth from "../../../Auth";
import AccessNotFound from "../../Alerts/AccessNotFound";

const INPUT_GENERAL_SEARCH = "generalSearch";
const REMOVE_EMAIL = "email";

export const fetchCustomersCSV = async (args) => {
  const {
    columns,
    filterText,
    cancelToken,
    returnEmptyValues,
    minDate,
    maxDate,
    createdSort,
    page,
    maxResults,
  } = args;
  try {
    const response = await axiosServices.post(
      `/api/v1/customers/advanced-filter`,
      {
        columns: columns,
        text: filterText,
        returnEmptyValues: returnEmptyValues,
        minDate: minDate === 0 ? undefined : minDate,
        maxDate: maxDate === 0 ? undefined : maxDate,
        createdSort: createdSort,
        exportCSV: "true",
        page: page ?? 1,
        maxResults: maxResults ?? 100,
      },
      {
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        cancelToken: cancelToken?.token,
        responseType: "arraybuffer",
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export default function CustomersList({ onSelected, selected }) {
  const access = Auth.canAccessRolesAndLeader("rolesAccessCRMList");

  const {
    customers,
    error,
    totalCustomers,
    customerListType,
    searchResults,
    filterColumns,
    filterResultsCount,
  } = useSelector((state) => state.customers);

  const url = window.location.href;
  const parametrosURL = new URL(url).searchParams;
  const customerRef = parametrosURL.get("customer-ref");

  const [notSearchCustomer, setNotSearchCustomer] = useState(false);

  const { lists, downloadCSV } = useSelector(
    (state) => state.customerCustomizedLists
  );

  const { minCreatedDate, maxCreatedDate, filterText, createdSort } =
    useSelector((state) => state.customers);

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("customers");

  const [cancelToken, setCancelToken] = useState(null);
  const [listProps, setListProps] = useState({
    maxResults: 100,
    page: 1,
    listType: "all",
  });

  const nextPage = () => {
    setListProps({ ...listProps, page: listProps.page + 1 });
  };

  const onNextPageFilter = () => {
    listProps.page++;

    dispatch(
      searchCustomers({
        columns: filterColumns,
        filterText: filterText,
        cancelToken,
        minDate: minCreatedDate,
        maxDate: maxCreatedDate,
        createdSort,
        page: listProps.page,
        maxResults: 100,
      })
    );
  };

  useEffect(() => {
    dispatch(fetchCustomerCustomizedLists());
  }, [dispatch]);

  const callFetchCustomers = useCallback(async () => {
    if (cancelToken) {
      cancelToken.cancel();
      setCancelToken(axios.CancelToken.source());
    }

    if (!customerRef) {
      dispatch(fetchCustomers({ ...listProps, cancelToken, createdSort }));
    }
  }, [cancelToken, createdSort, customerRef, dispatch, listProps]);

  useEffect(() => {
    if (listProps.page > 1) {
      callFetchCustomers(listProps);
    }
  }, [callFetchCustomers, dispatch, listProps]);

  useEffect(() => {
    return () => {
      dispatch(clearCustomers());
    };
  }, [dispatch]);

  useEffect(() => {
    if (customerListType === CUSTOMERS_LIST_TYPE_DEFAULT) {
      //remove all customers
      dispatch(resetCustomers());
      callFetchCustomers(listProps);
    } else {
      if (cancelToken) {
        cancelToken.cancel();
        setCancelToken(axios.CancelToken.source());
      }

      const newFilterColumns = getNewFiltersOther();
      listProps.page = 1;

      dispatch(
        searchCustomers({
          columns: newFilterColumns,
          filterText: filterText,
          cancelToken,
          minDate: minCreatedDate,
          maxDate: maxCreatedDate,
          createdSort,
          page: 1,
          maxResults: 100,
        })
      );
    }
  }, [createdSort]);

  useEffect(() => {
    if (downloadCSV) {
      const list = lists.filter((e) => e.name === activeTab);
      if (list.length > 0) {
        fetchSheet(list[0].id).then((data) => {
          const nameFile = sprintf(
            "%s %s.xlsx",
            "customlist",
            moment(new Date()).format("YYYY-DD-MM")
          );
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", nameFile);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          dispatch(setDownloadCSV(false));
        });
      } else {
        const newFilterColumns = getNewFiltersOther();
        fetchCustomersCSV({
          columns: newFilterColumns,
          filterText: filterText,
          cancelToken,
          minDate: minCreatedDate,
          maxDate: maxCreatedDate,
          createdSort,
        }).then((data) => {
          DownloadField(data, "CRM");
          dispatch(setDownloadCSV(false));
        });
      }
    }
  }, [
    activeTab,
    cancelToken,
    createdSort,
    dispatch,
    downloadCSV,
    filterColumns,
    filterText,
    lists,
    maxCreatedDate,
    minCreatedDate,
  ]);

  useEffect(() => {
    if (minCreatedDate === 0 && maxCreatedDate === 0) {
      // we are not initialized yet
      return;
    }

    if (minCreatedDate || maxCreatedDate) {
      listProps.page = 1;
      const newFilterColumns = getNewFiltersOther();

      dispatch(
        searchCustomers({
          columns: newFilterColumns,
          filterText: filterText,
          cancelToken,
          minDate: minCreatedDate,
          maxDate: maxCreatedDate,
          createdSort,
        })
      );
    } else {
      searchAgain(filterColumns, filterText);
    }
  }, [minCreatedDate, maxCreatedDate]);

  useEffect(() => {
    if (customerRef && !notSearchCustomer) {
      const newFilterColumns = [
        {
          columnName: "listType",
          filterText: "all",
          returnEmptyValues: "false",
        },
        {
          columnName: "email",
          filterText: customerRef,
          returnEmptyValues: "false",
        },
      ];

      dispatch(
        searchCustomers({
          columns: newFilterColumns,
          filterText: filterText,
          cancelToken,
          minDate: minCreatedDate,
          maxDate: maxCreatedDate,
          createdSort,
        })
      );
    }
  }, [
    cancelToken,
    createdSort,
    customerRef,
    dispatch,
    filterText,
    maxCreatedDate,
    minCreatedDate,
    notSearchCustomer,
  ]);

  const extractEmployeeNames = (customer) => {
    if (!customer) {
      return "";
    }

    const { customerAppointments } = customer;

    if (!customerAppointments || !Array.isArray(customerAppointments)) {
      return "";
    }

    const employees = [];

    customerAppointments.forEach((customerAppointment) => {
      customerAppointment.customerEmployees.forEach((customerEmployee) => {
        employees.push(customerEmployee.employee.name);
      });
    });

    const uniqueArray = employees.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    return uniqueArray.join(" / ");
  };

  const filterChanged = (e, columnName, returnEmptyValues) => {
    listProps.page = 1;

    let targetText = e.target.value;
    if (targetText === "") {
      // check if there are any other values in the filterColumns
      if (filterColumns.length <= 0) {
        //result normal results
        searchAgain([], filterText);
      } else {
        const newFilterColumns = filterColumns.filter(
          (e) => e.columnName !== columnName
        );
        if (newFilterColumns.length <= 0) {
          //back to normal
          searchAgain([], filterText);
        } else {
          if (cancelToken) {
            cancelToken.cancel();
            setCancelToken(axios.CancelToken.source());
          }

          dispatch(
            searchCustomers({
              columns: newFilterColumns,
              filterText: filterText,
              cancelToken,
              minDate: minCreatedDate,
              maxDate: maxCreatedDate,
              createdSort,
            })
          );
        }
      }
    } else {
      if (cancelToken) {
        cancelToken.cancel();
        setCancelToken(axios.CancelToken.source());
      }
      if (returnEmptyValues) {
        const newFilterColumns = getNewFilters(columnName, "", "true");

        dispatch(
          searchCustomers({
            columns: newFilterColumns,
            filterText: filterText,
            cancelToken,
            minDate: minCreatedDate,
            maxDate: maxCreatedDate,
            createdSort,
          })
        );
      } else {
        if (e.target.type === "checkbox") {
          //find if there is already a filter for this column
          const newFilterColumns = filterColumns.filter(
            (e) => e.columnName !== columnName
          );
          if (newFilterColumns.length <= 0) {
            //reset search
            searchAgain(newFilterColumns, filterText);
          } else {
            dispatch(
              searchCustomers({
                columns: newFilterColumns,
                filterText,
                cancelToken,
                minDate: minCreatedDate,
                maxDate: maxCreatedDate,
                createdSort,
              })
            );
          }
        } else {
          const newFilterColumns = getNewFilters(
            columnName,
            targetText,
            "false"
          );

          dispatch(
            searchCustomers({
              columns: newFilterColumns,
              filterText,
              cancelToken,
              minDate: minCreatedDate,
              maxDate: maxCreatedDate,
              createdSort,
            })
          );
        }
      }
    }
  };

  const getNewFilters = (columnName, targetText, returnEmptyValues) => {
    const newFilterColumns = filterColumns.filter(
      (e) => e.columnName !== columnName
    );

    newFilterColumns.push({
      columnName,
      filterText: targetText,
      returnEmptyValues,
    });

    return newFilterColumns;
  };

  const getNewFiltersOther = () => {
    let newFilterColumns = Object.assign([], filterColumns);

    return newFilterColumns;
  };

  const searchFilterChanged = (e) => {
    listProps.page = 1;
    let targetText = e.target.value;

    const { name } = e.target;

    let newFilterColumns = getNewFiltersOther();

    if (name === INPUT_GENERAL_SEARCH) {
      setNotSearchCustomer(true);

      if (customerRef) {
        newFilterColumns = newFilterColumns.filter(
          (newFilterColumn) => newFilterColumn.columnName !== REMOVE_EMAIL
        );
      }
    }

    if (targetText === "") {
      searchAgain(filterColumns, targetText);
    } else {
      if (cancelToken) {
        cancelToken.cancel();
        setCancelToken(axios.CancelToken.source());
      }

      dispatch(
        searchCustomers({
          columns: newFilterColumns,
          filterText: targetText,
          cancelToken,
          minDate: minCreatedDate,
          maxDate: maxCreatedDate,
          createdSort,
        })
      );
    }
  };

  const searchAgain = (newFilters, newFilterText) => {
    listProps.page = 1;
    if (!newFilters) {
      newFilters = [];
    }

    if (cancelToken) {
      cancelToken.cancel();
      setCancelToken(axios.CancelToken.source());
    }

    if (
      newFilters.length <= 0 &&
      (!newFilterText || newFilterText === "") &&
      (minCreatedDate === null || minCreatedDate === 0) &&
      (maxCreatedDate === null || maxCreatedDate === 0)
    ) {
      //reset search
      dispatch(
        searchCustomers({
          columns: newFilters,
          filterText: newFilterText,
          cancelToken,
          minDate: minCreatedDate,
          maxDate: maxCreatedDate,
          createdSort,
        })
      );
    } else {
      dispatch(
        searchCustomers({
          columns: newFilters,
          filterText: newFilterText,
          cancelToken,
          minDate: minCreatedDate,
          maxDate: maxCreatedDate,
          createdSort,
        })
      );
    }
  };

  const listTypeChanged = (listType) => {
    listProps.page = 1;
    const newFilterColumns = getNewFilters("listType", listType, "false");

    dispatch(
      searchCustomers({
        columns: newFilterColumns,
        filterText: filterText,
        cancelToken,
        minDate: minCreatedDate,
        maxDate: maxCreatedDate,
        createdSort,
        page: 1,
      })
    );
  };

  const hasMore = () => {
    switch (customerListType) {
      case CUSTOMERS_LIST_TYPE_DEFAULT:
        return customers.length < totalCustomers;
      case CUSTOMERS_LIST_TYPE_SEARCH:
        return searchResults.length < totalCustomers;
      default:
        return false;
    }
  };

  const customersCount = () => {
    switch (customerListType) {
      case CUSTOMERS_LIST_TYPE_DEFAULT:
        return totalCustomers;
      case CUSTOMERS_LIST_TYPE_SEARCH:
        return filterResultsCount;
      default:
        return 0;
    }
  };

  const getCustomers = () => {
    switch (customerListType) {
      case CUSTOMERS_LIST_TYPE_DEFAULT:
        return customers;
      case CUSTOMERS_LIST_TYPE_SEARCH:
        return searchResults;
      default:
        return [];
    }
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handleCreateList = (listName) => {
    if (listName.length <= 0) {
      alert("Please enter a list name");
      return;
    }
    dispatch(createCustomizedList({ name: listName }));
  };

  const handleDeleteList = (listName) => {
    if (activeTab.localeCompare("customers") === 0) {
      return;
    }

    if (activeTab.length <= 0) {
      alert("Please select a list to delete");
      return;
    }

    dispatch(deleteCustomizedList({ name: activeTab }));
    setActiveTab("customers");
  };

  if (!access) {
    return <AccessNotFound />;
  }

  return (
    <div>
      <CustomersToolbar
        handleCreateList={handleCreateList}
        handleDeleteList={handleDeleteList}
        onFilterChange={searchFilterChanged}
        listType={listProps.listType}
        onListTypeChanged={listTypeChanged}
        showSearch={activeTab === "customers"}
        onSelected={onSelected}
        searchGeneralLoad={customerRef}
        tabs={lists ?? []}
      />
      {error && (
        <div className="alert alert-danger" role="alert">
          {error.toString()}
        </div>
      )}
      <Tabs
        defaultActiveKey="customers"
        activeKey={activeTab}
        id="customersListTab"
        className="border-none"
        onSelect={handleTabChange}
      >
        <Tab eventKey="customers" title={`All Results (${customersCount()})`}>
          <DefaultList
            customerListType={customerListType}
            customers={getCustomers()}
            filterChanged={filterChanged}
            hasMore={hasMore()}
            loadedCount={getCustomers().length}
            onNextPage={nextPage}
            onNextPageFilter={onNextPageFilter}
            onSelected={onSelected}
            selectedCustomer={selected}
            extractEmployeeNames={extractEmployeeNames}
          />
        </Tab>
        {lists.map((e) => (
          <Tab
            key={e.id}
            eventKey={e.name}
            title={`${e.name} (${e.customerIds.length})`}
          >
            <CustomizedList
              list={e}
              filterChanged={filterChanged}
              selectedCustomer={selected}
              onSelected={onSelected}
              extractEmployeeNames={extractEmployeeNames}
            />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}
