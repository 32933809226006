import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typeahead } from "react-bootstrap-typeahead";

const FormSelectMultiple = ({
  options,
  nameField,
  onChange,
  data,
  labelKey,
  placeholder,
  allowNew,
  disabled,
  newSelectionPrefix,
}) => {
  disabled = disabled ?? false;

  let defaultData = data.length > 0 ? data.split(",") : [];
  defaultData = defaultData.map((data) => {
    return data.trim();
  });

  const [multiSelections, setMultiSelections] = useState(defaultData);

  const validSelected = (selected) => {
    if (selected.length === 0) {
      return "";
    }

    const selectedData = [];

    selected.forEach((item) => {
      if (item?.customOption) {
        selectedData.push(item.name);
      } else {
        selectedData.push(item);
      }
    });

    return selectedData.join(",");
  };

  const handleResponse = (selected) => {
    setMultiSelections(selected);

    const selectedStr = validSelected(selected);

    onChange(selectedStr, nameField);
  };

  return (
    <Typeahead
      allowNew={allowNew}
      id={nameField}
      labelKey={labelKey}
      multiple
      onChange={handleResponse}
      options={options}
      placeholder={placeholder}
      selected={multiSelections}
      disabled={disabled}
      newSelectionPrefix={newSelectionPrefix}
    />
  );
};

FormSelectMultiple.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  nameField: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  allowNew: PropTypes.bool,
  newSelectionPrefix: PropTypes.string,
};

FormSelectMultiple.defaultProps = {
  placeholder: "Choose option...",
  allowNew: false,
  newSelectionPrefix: "New selection: ",
};

export default FormSelectMultiple;
