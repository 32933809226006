import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import {
  dateFormatCustom,
  getYearMonthAndLastDay,
} from "../../../utils/dateUtils";
import FormDatePicker from "../../Form/FormDatePicker";
import moment from "moment";

const ResultAnalysisDateFilter = ({ onResponse, required }) => {
  const yearMonthAndLastDay = getYearMonthAndLastDay(moment());

  const defaultMinDate = moment().set({
    year: yearMonthAndLastDay.year,
    month: yearMonthAndLastDay.month,
    date: 1,
  });

  const defaultMaxDate = moment();

  defaultMinDate.set({ hour: 0, minute: 0, second: 0 });
  defaultMaxDate.set({ hour: 23, minute: 59, second: 59 });

  const [sendData, setSendData] = useState(defaultMinDate);
  const [selectedMinDate, setSelectedMinDate] = useState(defaultMinDate);
  const [selectedMaxDate, setSelectedMaxDate] = useState(defaultMaxDate);

  const handleMinDateChange = async (date) => {
    setSelectedMinDate(null);

    if (date) {
      date.setHours(0, 0, 0, 0);
      setSelectedMinDate(date);
    }

    setSendData(true);
  };

  const handleMaxDateChange = async (date) => {
    setSelectedMaxDate(null);

    if (date) {
      date.setHours(23, 59, 59, 999);
      setSelectedMaxDate(date);
    }

    setSendData(true);
  };

  const responseDate = useCallback(() => {
    onResponse({
      startDate: dateFormatCustom(selectedMinDate),
      endDate: dateFormatCustom(selectedMaxDate),
    });
  }, [onResponse, selectedMinDate, selectedMaxDate]);

  useEffect(() => {
    if (sendData) {
      responseDate();
      setSendData(false);
    }
  }, [sendData, responseDate]);

  return (
    <div>
      <Row className="align-items-end">
        <Col md={6}>
          <FormDatePicker
            label="Date form"
            nameInput="startDate"
            value={selectedMinDate ?? ""}
            onResponse={handleMinDateChange}
            dateFormat="MM/dd/yyyy"
            columnsSize={"12"}
            maxDate={selectedMaxDate}
            required={required}
          />
        </Col>
        <Col md={6}>
          <FormDatePicker
            label="Date To"
            nameInput="endDate"
            value={selectedMaxDate ?? ""}
            onResponse={handleMaxDateChange}
            columnsSize={"12"}
            dateFormat="MM/dd/yyyy"
            required={required}
          />
        </Col>
      </Row>
    </div>
  );
};

ResultAnalysisDateFilter.propTypes = {
  onResponse: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
};

ResultAnalysisDateFilter.defaultProps = {
  required: true,
};

export default ResultAnalysisDateFilter;
