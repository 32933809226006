import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import ReportRow from "../ReportRow";
import { useDispatch, useSelector } from "react-redux";
import { fetchReportRequestList } from "../../../../store/slices";
import { API_STATUS_PENDING } from "../../../../store/constants";
import LoadingSpinnerLayer from "../../../LoadingSpinnerLayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";

const ReportRequest = () => {
  const { reportRequestList, status } = useSelector((state) => state.reports);
  const dispatch = useDispatch();

  const [reports, setReports] = useState({ items: [], count: 0 });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadReports = async () => {
      setLoading(false);
      await dispatch(
        fetchReportRequestList({
          page: 1,
          maxResult: 200,
        })
      );
    };

    if (loading && reports.items.length <= 0) {
      loadReports();
    }
  }, [dispatch, loading, reports.items.length]);

  useEffect(() => {
    if (JSON.stringify(reportRequestList) !== JSON.stringify(reports)) {
      setReports(reportRequestList ?? { items: [], count: 0 });
    }
  }, [reportRequestList, reports]);

  const loadReports = async () => {
    setLoading(true);

    await dispatch(
      fetchReportRequestList({
        page: 1,
        maxResult: 200,
      })
    );
    setLoading(false);
  };

  return (
    <Card className="mt-2">
      <Card.Body>
        <Card.Title>
          <Row lg={12}>
            <Col xs={12} lg={11}>
              Workflow report
            </Col>
            <Col xs={12} lg={1}>
              <FontAwesomeIcon
                icon={faRefresh}
                onClick={loadReports}
                className="add-task-button"
              />
            </Col>
          </Row>
        </Card.Title>

        {status === API_STATUS_PENDING && <LoadingSpinnerLayer />}

        <Row className="table-container">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Id</th>
                <th>Create</th>
                <th>Reference</th>
                <th>Status</th>
                <th>URL</th>
              </tr>
            </thead>
            <tbody>
              {reports?.items.map((report, key) => {
                return <ReportRow key={key} report={report} />;
              })}
            </tbody>
          </Table>
        </Row>
      </Card.Body>
    </Card>
  );
};

ReportRequest.propTypes = {};

export default ReportRequest;
