import { REACT_APP_WORKFLOW_TYPE_MANUFACTURING } from "../../../../global/const";

export const findMostRecentProvider = async (workflowSales) => {
  if (!workflowSales) {
    return { id: null, name: null };
  }

  let workflowActivities = [];

  workflowSales.forEach((workflowSale) => {
    workflowSale.workflowActivityModels.forEach((workflowActivity) => {
      workflowActivity.jeweller = workflowSale?.jewellerModel;

      workflowActivities.push(workflowActivity);
    });
  });

  if (workflowActivities.length <= 0) {
    return { id: null, name: null };
  }

  let mostRecentactivity = workflowActivities.filter(
    (workflowActivity) =>
      workflowActivity.type === REACT_APP_WORKFLOW_TYPE_MANUFACTURING
  );

  if (mostRecentactivity.length <= 0) {
    return { id: null, name: null };
  }

  mostRecentactivity = mostRecentactivity.reduce((latest, workflowActivity) => {
    return new Date(workflowActivity.created) > new Date(latest.created)
      ? workflowActivity
      : latest;
  });

  if (mostRecentactivity) {
    return {
      id: mostRecentactivity?.jeweller?.id ?? null,
      name: mostRecentactivity?.jeweller?.contactName ?? null,
    };
  }

  return { id: null, name: null };
};
