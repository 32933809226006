import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import BackIcon from "../../../../image/backArrow.png";

const HeaderLoadCommissions = (props) => {
  const { title, returnUrl } = props;

  return (
    <Col className="header" xs={12} lg={12}>
      <Row className="header_tools">
        <Col>
          <div className="col-12">
            <div className="link_to_dashboard">
              <Link to={returnUrl}>
                <img height="50px" src={BackIcon} alt="" />
              </Link>
            </div>
            <div className="db-top-heading">
              <h2 className="sales-title">{title}</h2>
            </div>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

HeaderLoadCommissions.propTypes = {
  title: PropTypes.string.isRequired,
  returnUrl: PropTypes.string.isRequired,
};

export default HeaderLoadCommissions;
