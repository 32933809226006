import React, { Component } from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";
import PostSaleRequestEdit from "./PostSaleRequestEdit";
import PostSaleBackOfficeEdit from "./PostSaleBackOfficeEdit";
import PostSaleAccountingEdit from "./PostSaleAccountingEdit";
import PostSaleEditCloseConfirmation from "./PostSaleEditCloseConfirmation";
import Auth from "../../../Auth";
import axios from "axios";
import * as globalConst from "../../../global/const";

// SCSS
import "../../../css/sales/sale_edit.scss";
import ShowSaleConsecutive from "../../sales/Acctions/ShowSaleConsecutive";
import Util from "../../../Util";

class PostSaleEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sale: props.sale,
      oldSale: null,
      edited_sale: null,
      pending_changes_request: false,
      pending_changes_back_office: false,
      pending_changes_accounting: false,
      show_close_edit_confirmation: false,
      saleDate: new Date(),
      updateData: false,
    };
    this.closeEditionComponent = this.closeEditionComponent.bind(this);
    this.closeEditionComponentWithoutChanges =
      this.closeEditionComponentWithoutChanges.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.saleUpdated = this.saleUpdated.bind(this);
    this.generateEditionLog = this.generateEditionLog.bind(this);
    this.closeEditCloseConfirmation =
      this.closeEditCloseConfirmation.bind(this);
    this.updateJewellers = this.updateJewellers.bind(this);
  }

  closeEditCloseConfirmation() {
    this.setState({
      show_close_edit_confirmation: false,
    });
  }

  closeEditionComponent() {
    if (
      this.state.pending_changes_accounting ||
      this.state.pending_changes_back_office ||
      this.state.pending_changes_request
    ) {
      this.openEditCloseConfirmationModal();
    } else {
      this.props.closeEditionComponent(
        this.state.edited_sale,
        this.props.selected_sale_index,
        this.state.updateData
      );
      this.fetchPostSaleDetailWithId();
    }
  }

  closeEditionComponentWithoutChanges() {
    this.props.closeEditionComponent(
      this.state.edited_sale,
      this.props.selected_sale_index,
      this.state.updateData
    );
    this.fetchPostSaleDetailWithId();
  }

  openEditCloseConfirmationModal() {
    this.setState({
      show_close_edit_confirmation: true,
    });
  }

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    var access_to_request = Auth.isAuthorised("post_sales_edit_request");
    var access_to_back_office = Auth.isAuthorised(
      "post_sales_edit_back_office"
    );
    var access_to_accounting = Auth.isAuthorised("post_sales_edit_accounting");

    return (
      <div>
        {this.state.show_close_edit_confirmation === true ? (
          <PostSaleEditCloseConfirmation
            closeEditCloseConfirmation={this.closeEditCloseConfirmation}
            closeEditionComponent={this.closeEditionComponent}
            closeEditionComponentWithoutChanges={
              this.closeEditionComponentWithoutChanges
            }
          />
        ) : null}
        <Modal
          show={true}
          onHide={this.closeEditionComponent}
          dialogClassName="modal-90w"
          backdrop="static"
        >
          <Modal.Header closeButton>
            Edit Post Sale {this.state.sale?.salesModel?.onlineOrderNumber}{" "}
            <ShowSaleConsecutive
              sale={this.state.sale?.salesModel}
              breackSpace={false}
              labelSmall={true}
            />
          </Modal.Header>
          <Modal.Body>
            <div id="SaleEdit_component">
              <Tabs id="SaleEditTabs" className="mb-3">
                {access_to_request === true ? (
                  <Tab
                    eventKey="request"
                    title="Request"
                    tabClassName={
                      this.state.pending_changes_request
                        ? "pending-changes"
                        : null
                    }
                  >
                    <PostSaleRequestEdit
                      sale={this.state.sale}
                      saleUpdated={this.saleUpdated}
                      handleTabChange={this.handleTabChange}
                    />
                  </Tab>
                ) : null}
                {access_to_back_office === true ? (
                  <Tab
                    eventKey="backOffice"
                    title="Back Office Information"
                    tabClassName={
                      this.state.pending_changes_back_office
                        ? "pending-changes"
                        : null
                    }
                  >
                    <PostSaleBackOfficeEdit
                      sale={this.state.sale}
                      saleUpdated={this.saleUpdated}
                      handleTabChange={this.handleTabChange}
                      updateJewellers={this.updateJewellers}
                    />
                  </Tab>
                ) : null}
                {access_to_accounting === true ? (
                  <Tab
                    eventKey="accounting"
                    title="Accounting"
                    tabClassName={
                      this.state.pending_changes_accounting
                        ? "pending-changes"
                        : null
                    }
                  >
                    <PostSaleAccountingEdit
                      sale={this.state.sale}
                      saleUpdated={this.saleUpdated}
                      handleTabChange={this.handleTabChange}
                      updateJewellers={this.updateJewellers}
                    />
                  </Tab>
                ) : null}
              </Tabs>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  //Generate log after editing element
  axioRequest = null;
  last_sale_reported_for_log = null;
  generateEditionLog(edited_sale) {
    //AJAX CONTROLS
    //cancel previous ajax request if exists
    if (this.axioRequest !== null) {
      this.axioRequest.cancel();
    }
    // creates a new token for upcomming ajax (overwrite the previous one)
    this.axioRequest = axios.CancelToken.source();

    let sale_before_update = this.last_sale_reported_for_log;
    if (sale_before_update === null) {
      sale_before_update = this.state.oldSale;
    }

    delete sale_before_update["salesModel"];
    delete edited_sale["salesModel"];

    const url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_POST_SALE_LOG_NEW;
    const params = new URLSearchParams();
    const user = Auth.getAuthenticatedUser();
    let saleId = this.state.sale.id;
    params.append("postSaleServiceId", saleId);
    params.append("employeeId", user.employeeModel.id);
    params.append("oldInformation", JSON.stringify(sale_before_update));
    params.append("newInformation", JSON.stringify(edited_sale));
    params.append("action", "EDIT");

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    };
    axios
      .post(url, params, config)
      .then((response) => {
        const data = response.data;
        if (data !== undefined && data.code === 200) {
          console.log("sales_log stored");
        } else {
          console.log("Error storing sales_log");
        }
        this.axioRequest = null;
      })
      .catch((err) => {
        console.log("Error storing sales_log.");
        if (err.response) {
          if ("status" in err.response && err.response.status === 401) {
            console.log("Not authorised.");
          } else {
            console.log(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }
        } else if (err.request) {
          console.log(
            "There was a problem processing the information. Please reload this page and try again."
          );
        } else {
          //error not detected. Request could have been cancelled and it's waiting for second
          //request to finish
        }
        //console.log((err);
        return null;
      });
  }

  fetchPostSaleDetailWithId() {
    this.props.fetchPostSaleDetailWithId(
      this.state.sale.id,
      this.props.selected_sale_index,
      this.state.updateData
    );
  }

  saleUpdated(edited_sale) {
    this.setState({
      edited_sale: edited_sale,
    });
    this.generateEditionLog(edited_sale);
  }

  handleTabChange(tab, status) {
    if (status) {
      this.state.updateData = true;
    }

    switch (tab) {
      case "request":
        this.setState({
          pending_changes_request: status,
        });
        break;
      case "back_office":
        this.setState({
          pending_changes_back_office: status,
        });
        break;
      case "accounting":
        this.setState({
          pending_changes_accounting: status,
        });
        break;
      default:
        break;
    }
  }

  updateJewellers(new_jewellers) {
    var editedSale = JSON.parse(JSON.stringify(this.state.sale));
    editedSale["jewellers"] = new_jewellers;

    this.setState({
      sale: editedSale,
    });
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  /*static getDerivedStateFromProps(props, state) {
        //called right before rendering the first time or before shouldComponentUpdate in case it was already rendered
        //return values for the state
        let edited_sale = state.edited_sale
        if(edited_sale !== null && edited_sale.id !== props.sale.id){
            edited_sale = null
        }
        return{
            sale: props.sale,
            edited_sale : edited_sale
        }
    }*/

  componentDidMount() {
    this.setState({
      oldSale: this.props.oldSale,
    });
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default PostSaleEdit;
