import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import {
    addFollowup,
    updateFollowup,
} from "../../../store/slices/customerSlice";
import { fetchAppointment } from "../../../store/slices";
import { resetSearch, selectAppointment } from "../../../store/slices/appointmentsSlice";

export const AddFollowUps = ({
    show,
    onHide,
    appointment,
    editingFollowup,
}) => {
    const dispatch = useDispatch();
    const [followup, setFollowup] = useState({
        notes: "",
        date: new Date(),
    });
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        if (editingFollowup) {
            setFollowup(editingFollowup);
        }
    }, [editingFollowup]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setValidated(false);
        const form = e.currentTarget;
        const check = form.checkValidity();

        if (!check) {
            setValidated(true);
            return;
        }

        if (editingFollowup) {
            dispatch(
                updateFollowup({
                    ...followup,
                    appointmentId: appointment.id,
                    customerId: appointment.customerId,
                    id: editingFollowup.id,
                    date: followup.date,
                })
            );
        } else {
            dispatch(
                addFollowup({
                    customerId: appointment.customerId,
                    appointmentId: appointment.id,
                    ...followup,
                })
            );
            dispatch(resetSearch());
            dispatch(selectAppointment(appointment));
        }
        await dispatch(await fetchAppointment(appointment));
    };

    return (
        <Modal
            show={show}
            onHide={() => {
                onHide();
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {editingFollowup ? "Edit Followup" : "Add Follow Up"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} validated={validated} noValidate>
                    <Form.Group className="mt-2" controlId="customerName">
                        <Form.Label>Notes *</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="name"
                            value={followup.notes}
                            onChange={(e) => {
                                setFollowup({ ...followup, notes: e.target.value });
                            }}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="customerFirstAppointmentDate">
                        <Form.Label>Followup Sent On:</Form.Label>
                        <DatePicker
                            selected={new Date(followup.date)}
                            onChange={(date) => setFollowup({ ...followup, date: date })}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            name="followupDate"
                        />
                    </Form.Group>
                    <Button className="mt-2" variant="primary" type="submit">
                        {editingFollowup ? "Update Followup" : "Add Follow Up"}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
