import React, { Component } from "react";
import axios from "axios";

import SaleDetailGeneralInfo from "./SaleDetailGeneralInfo";
import SaleDetailProduct from "./SaleDetailProduct";
import SaleDetailProduction from "./SaleDetailProduction";
import SaleDetailAccounts from "./SaleDetailAccounts";
import SaleDetailCosts from "./SaleDetailCosts";
import SaleDetailSalesMarketing from "./SaleDetailSalesMarketing";
import SaleDetailLog from "./SaleDetailLog";
import Auth from "../../../Auth";
import LoadingLayer from "../../LoadingLayer";
import * as globalConst from "../../../global/const";
import { sprintf } from "sprintf-js";

// SCSS
import "../../../css/sales/sale_detail.scss";
import MultimediaList from "../saleMultimedia/MultimediaList";
import { Row } from "react-bootstrap";
import SaleDetailSaleCommissions from "./SaleDetailSaleCommissions";
import ShowSaleConsecutive from "../Acctions/ShowSaleConsecutive";
import QualityControlCheckLogHistory from "./QualityControlCheckLogHistory";
import withNavigation from "../../../Router/WithNavigation";
import Util from "../../../Util";

class SaleDetail extends Component {
  ajaxRequest = null;

  constructor(props) {
    super(props);
    const isAuth = Auth.getIsAuth();
    if (isAuth === false) {
      const { navigate } = this.props;

      navigate("/");
    }

    const { params } = this.props;

    this.state = {
      sale: null,
      workflowSales: [],
      loading_page: true,
      url_params: params,
      loading_log: true,
      sales_logs: null,
      sales_logs_workflow: null,
      error_loading_log: false,
      showWorkflows: false,
    };
  }

  ajaxRequestForDetail = null;
  fetchSaleDetailWithId(sale_id) {
    //AJAX CONTROLS
    //cancel previous ajax request if exists
    if (this.ajaxRequestForDetail !== null) {
      this.ajaxRequestForDetail.cancel();
    }
    // creates a new token for upcomming ajax (overwrite the previous one)
    this.ajaxRequestForDetail = axios.CancelToken.source();
    //FINISH AJAX CONTROLS
    let url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_SALES_DETAIL;
    url = url.replace("{id}", sale_id);

    axios
      .get(
        url,
        {
          headers: { Authorization: `Bearer ${Auth.getToken()}` },
        },
        {
          cancelToken: this.ajaxRequestForDetail.token,
        }
      )
      .then((api_response) => {
        const sale = api_response.data.result;
        this.setState({
          sale: sale,
          loading_page: false,
        });
        this.fetchSalesLogs(sale);
        this.findWorkflowStatus(sale);
      })
      .catch((err) => {
        if (err.response) {
          if ("status" in err.response) {
            if (err.response.status === 401) {
              alert("User not authorised.");
            } else if (err.response.status === 404) {
              alert("Sale not found.");
            }
          } else {
            alert(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }
          this.setState({
            loading_page: false,
          });
        } else if (err.request) {
          alert(
            "There was a problem with your request. Please reload this page and try again."
          );
          this.setState({
            loading_page: false,
          });
        } else {
          //error not detected. Request could have been cancelled and it's waiting for second
          //request to finish
          this.setState({
            loading_page: true,
          });
        }
        console.log(err);
        return null;
      });
  }

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    const sale = this.state.sale;
    return (
      <div id="SaleDetail_component">
        <div className="container">
          {this.state.loading_page === true ? <LoadingLayer /> : ""}
          {sale && this.state.showWorkflows ? (
            <div className="detail_components">
              <h1 className="order-number">
                Order # {sale.onlineOrderNumber}
                <ShowSaleConsecutive
                  sale={sale}
                  breackSpace={false}
                  labelSmall={true}
                />
              </h1>
              <div className="row">
                <div className="col-sm">
                  {<MultimediaList saleId={sale.id} />}
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  {<SaleDetailGeneralInfo sale={sale} />}
                </div>
                <div className="col-sm">
                  {<SaleDetailProduct sale={sale} />}
                </div>
                <div className="col-sm">
                  {
                    <SaleDetailProduction
                      sale={sale}
                      workflowSales={this.state.workflowSales}
                    />
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  {
                    <SaleDetailAccounts
                      sale={sale}
                      workflowSales={this.state.workflowSales}
                    />
                  }
                </div>
                <div className="col-sm">
                  {
                    <SaleDetailCosts
                      sale={sale}
                      workflowSales={this.state.workflowSales}
                    />
                  }
                </div>
                <div className="col-sm">
                  {<SaleDetailSalesMarketing sale={sale} />}
                </div>
              </div>
              <Row>
                <SaleDetailSaleCommissions
                  sale={sale}
                  type={globalConst.SALE_COMMISSION_TYPE_SALES}
                />
              </Row>

              <Row>
                <SaleDetailSaleCommissions
                  sale={sale}
                  type={globalConst.SALE_COMMISSION_TYPE_REVIEWS}
                />
              </Row>
              <div className="row">
                <div className="col-sm">
                  {
                    <SaleDetailLog
                      sale={sale}
                      loading_log={this.state.loading_log}
                      sales_logs={this.state.sales_logs}
                      error_loading_log={this.state.error_loading_log}
                      titleSales={true}
                    />
                  }
                </div>
                <div className="col-sm">
                  {
                    <SaleDetailLog
                      sale={sale}
                      loading_log={this.state.loading_log}
                      sales_logs={this.state.sales_logs_workflow}
                      error_loading_log={this.state.error_loading_log}
                      titleSales={false}
                    />
                  }
                </div>
                <div className="col-sm">
                  <QualityControlCheckLogHistory sale={sale} />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  componentDidMount() {
    let url_params = this.state.url_params;
    if ("id" in url_params) {
      this.fetchSaleDetailWithId(url_params.id);
    }
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************

  axiosRequest = null;
  fetchSalesLogs(sale) {
    if (this.axiosRequest !== null) {
      this.axiosRequest.cancel();
    }

    this.axiosRequest = axios.CancelToken.source();

    const url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_SALES_LOG_LIST;

    const urlWorkflowsLog = sprintf(
      "%s%s/%s",
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN"),
      globalConst.REACT_APP_NOVITAERP_API_SALES_LOG_WORKFLOW_LIST,
      sale.id
    );

    let workflowsLogs = axios.get(
      urlWorkflowsLog,
      {
        headers: { Authorization: `Bearer ${Auth.getToken()}` },
      },
      {
        cancelToken: this.axiosRequest.token,
      }
    );

    const filter_params = {
      salesId: sale.id,
    };
    let salesLogs = axios.get(
      url,
      {
        params: filter_params, //this params are sent as query. In post requests they should go as body params in the following block
        headers: { Authorization: `Bearer ${Auth.getToken()}` },
      },
      {
        cancelToken: this.axiosRequest.token,
      }
    );

    Promise.all([salesLogs, workflowsLogs])
      .then((values) => {
        let items = [];
        let itemsWorkflow = [];

        if (values[0]) {
          items = items.concat(values[0].data.result.rows);
        }

        if (values[1]) {
          itemsWorkflow = itemsWorkflow.concat(values[1].data.value);
        }

        if (items.length > 0) {
          items = items.sort((a, b) => {
            return new Date(b.created) - new Date(a.created);
          });

          this.setState({
            loading_log: false,
            sales_logs: items,
          });
        }

        if (itemsWorkflow.length > 0) {
          itemsWorkflow = itemsWorkflow.sort((a, b) => {
            return new Date(b.created) - new Date(a.created);
          });

          this.setState({
            loading_log: false,
            sales_logs_workflow: itemsWorkflow,
          });
        }

        const allItems = items.concat(itemsWorkflow);

        if (allItems.length <= 0) {
          this.setState({
            loading_log: false,
            error_loading_log: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response || error.request) {
          this.setState({
            loading_log: false,
            error_loading_log: true,
          });
        } else {
          //error not detected. Request could have been cancelled and it's waiting for second
          //request to finish
          this.setState({
            loading_log: true,
            error_loading_log: false,
          });
        }

        return null;
      })
      .finally((data) => {
        this.setState({
          error_loading_log: false,
        });
      });
  }

  findWorkflowStatus(sale) {
    //AJAX CONTROLS
    //cancel previous ajax request if exists
    if (this.ajaxRequest !== null) {
      this.ajaxRequest.cancel();
    }

    this.ajaxRequest = axios.CancelToken.source();
    let url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_SALE_WORKFLOW_ACTIVITY;
    url = url.replace("{saleID}", sale.id);

    axios
      .get(
        url,
        {
          params: this.filter_params,
          headers: { Authorization: `Bearer ${Auth.getToken()}` },
        },
        {
          cancelToken: this.ajaxRequest.token,
        }
      )
      .then((api_response) => {
        if (api_response.data.result.rows.length > 0) {
          this.setState({
            workflowSales: api_response.data.result.rows,
            showWorkflows: true,
          });
        }
      })
      .finally((data) => {
        this.setState({
          showWorkflows: true,
        });
      });
  }
}

export default withNavigation(SaleDetail);
