import React, { Component } from "react";
import { Spinner } from "react-bootstrap";

class LoadingLayer extends Component {
  render() {
    return (
      <div id="LoadingLayer_component">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
}

export default LoadingLayer;
