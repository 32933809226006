import React from "react";
import PropTypes from "prop-types";

const FIRST_CONSECUTIVE = ["1 of 1", "1 to 1"];

const ShowSaleConsecutive = ({ sale, breackSpace, labelSmall }) => {
  if (!sale) {
    return "";
  }

  const { productConsecutive } = sale;

  if (FIRST_CONSECUTIVE.includes(productConsecutive)) {
    return "";
  }

  return (
    <>
      {breackSpace ? <br /> : " "}
      {productConsecutive && (
        <span className={labelSmall ? "order-number-small" : ""}>
          ({productConsecutive})
        </span>
      )}
    </>
  );
};

ShowSaleConsecutive.propTypes = {
  sale: PropTypes.any,
  breackSpace: PropTypes.bool,
  labelSmall: PropTypes.bool,
};

ShowSaleConsecutive.defaultProps = {
  breackSpace: true,
  labelSmall: false,
};

export default ShowSaleConsecutive;
