import { REACT_APP_WORKFLOW_TYPE_DELIVERING_TO_CUSTOMER } from "../../../../global/const";

export const findWorkflowActivityOnlyDelivered = (workflowSale) => {
  if (!workflowSale) {
    return false;
  }

  const { workflowActivityModels } = workflowSale;

  if (!workflowActivityModels) {
    return false;
  }

  const activities = workflowActivityModels.map((workflowActivity) => {
    const { type } = workflowActivity;

    return type;
  });

  if (
    activities.length === 1 &&
    activities[0] === REACT_APP_WORKFLOW_TYPE_DELIVERING_TO_CUSTOMER
  ) {
    return true;
  }

  return false;
};

export const findWorkflowActivityDelivering = (workflowSale) => {
  if (!workflowSale) {
    return false;
  }

  const { workflowActivityModels } = workflowSale;

  if (!workflowActivityModels) {
    return false;
  }

  const activities = workflowActivityModels
    .map((workflowActivity) => {
      const { type } = workflowActivity;

      if (type === REACT_APP_WORKFLOW_TYPE_DELIVERING_TO_CUSTOMER) {
        return workflowActivity;
      }

      return null;
    })
    .filter((workflowActivity) => workflowActivity !== null);

  if (activities && activities.length > 0) {
    return activities[0];
  }

  return null;
};
