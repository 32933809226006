import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { dateFormatCustom } from "../../../utils/dateUtils";
import Util from "../../../Util";

const SaleOutdatedFields = ({ sale }) => {
  return (
    <Col className="sale_data mt-2">
      <h2 className="heading">Outdated Fields</h2>
      <Col className="container mb-4">
        <Row className="row">
          <Col>Setting height</Col>
          <Col>{sale.settingHeight}</Col>
        </Row>
        {Util.wordInArray(sale.saleType, [
          "engagement ring",
          "jewellery",
          "wedding",
        ]) === true ? (
          <Row className="row">
            <Col>Appraisal Status</Col>
            <Col>{sale.valuationStatus}</Col>
          </Row>
        ) : null}
        <Row className="row">
          <Col>Ring sent with Resized</Col>
          <Col>{sale.ringSentWithResize}</Col>
        </Row>
        <Row className="row">
          <Col>Date collected or shipped</Col>
          <Col>
            {sale.collectedShippedDate
              ? dateFormatCustom(sale.collectedShippedDate)
              : ""}
          </Col>
        </Row>
        <Row className="row">
          <Col>Setting status</Col>
          <Col>{sale.settingStatus}</Col>
        </Row>
      </Col>
    </Col>
  );
};

SaleOutdatedFields.propTypes = {
  sale: PropTypes.any.isRequired,
};

export default SaleOutdatedFields;
