import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import AlertMessage from "../../../Utils/AlertMessage";
import {
  fetchSendJewelleryRingRequest,
  setSalesIds,
} from "../../../../store/slices/jewelleryRingrequestSlice";
import Util from "../../../../Util";
import JewelleryRingRequestTableResponse from "./JewelleryRingRequestTableResponse";
import LoadingLayer from "../../../LoadingLayer";

const JewelleryRingRequestMultiple = ({
  onResponse,
  currentTab,
  listTabs = [],
}) => {
  const { salesIds } = useSelector((state) => state.jewelleryRingRequest);

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const [salesProcess, setSalesProcess] = useState([]);

  useEffect(() => {
    if (salesIds.length <= 0) {
      let ids = [];
      listTabs.forEach((list) => {
        if (currentTab === list.name && list.salesIds) {
          ids = JSON.parse(list.salesIds.toString());
        }
      });

      if (ids.length > 0) {
        dispatch(setSalesIds(ids));
      }
    }
  });

  const closeModal = () => {
    setShowModal(false);
    onResponse(false);
  };

  const onResponseData = async () => {
    setLoading(true);
    let chunks = Util.chunk(salesIds, 10);

    let jewelleryResponses = [];

    await Promise.all(
      chunks.map(async (items) => {
        const response = await dispatch(
          fetchSendJewelleryRingRequest({
            salesIds: items,
          })
        );

        response?.payload.map((item) => {
          jewelleryResponses.push(item);
        });
      })
    );

    setSalesProcess(jewelleryResponses);

    setLoading(false);
  };

  return (
    <>
      {loading && (
        <Row className="loading-process">
          <LoadingLayer />
        </Row>
      )}

      <Modal
        show={showModal}
        size="lg"
        centered
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          Request Orders to Grown Diamonds System
        </Modal.Header>

        {salesIds.length <= 0 && (
          <Col>
            <AlertMessage type="warning" message={"Sales not found"} />
          </Col>
        )}

        <Modal.Body>
          {salesProcess.length <= 0 && (
            <>
              <Row>
                <Col>
                  The orders listed in the tab <b>"{currentTab}"</b> will be
                  sent to the Grown Diamonds' system to start the manufacturing
                  process with the jeweller.
                </Col>
              </Row>
              <br />
              <Row>
                <Col>Do you want to send the request now?</Col>
              </Row>
            </>
          )}

          {salesProcess.length > 0 && (
            <JewelleryRingRequestTableResponse sales={salesProcess} />
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" type="submit" onClick={closeModal}>
            Cancel
          </Button>

          {salesProcess.length <= 0 && (
            <Button
              variant="primary"
              type="submit"
              disabled={loading}
              onClick={onResponseData}
            >
              Send request
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

JewelleryRingRequestMultiple.propTypes = {
  onResponse: PropTypes.func.isRequired,
  listTabs: PropTypes.array.isRequired,
  currentTab: PropTypes.string.isRequired,
};

JewelleryRingRequestMultiple.defaultProps = {
  currentTab: "",
};

export default JewelleryRingRequestMultiple;
