import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { faPlus, faEraser, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Auth from "../../../Auth";
import AddAppointmentsForm from "../edit";
import { TOTAL_PERSONAL_TABS } from "../../../global/const";

export default function AppointmentsToolbar({
  handleCreateList,
  handleDeleteList,
  onFilterChange,
  showSearch,
  searchGeneralLoad,
  tabs,
}) {
  const { filterColumns } = useSelector((state) => state.appointments);

  const inputRef = useRef(null);

  useEffect(() => {
    if (searchGeneralLoad) {
      inputRef.current.value = searchGeneralLoad;
    }
  }, [searchGeneralLoad]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = useCallback(
    debounce((text) => {
      onFilterChange(text, "generalSearch");
    }, 300),
    [filterColumns]
  );

  const [listName, setListName] = useState("");
  const [showFormAppointment, setShowFormAppointment] = useState(false);
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);

  const accesEdit = () => {
    const access = Auth.canAccessRolesAndLeader("rolesAccessCRMCreate");

    const accessOperation = Auth.canAccessRolesAndLeader(
      "rolesAccessCRMOperation",
      true
    );

    if (access || accessOperation) {
      return true;
    }

    return false;
  };

  return (
    <div>
      <div className="row sales_control">
        <div className="fixed-height-div">
          <div className="search-filter">
            <div className="row general_search mb-2">
              <Row>
                <Col
                  xs="12"
                  lg="4"
                  className="text-left add_new_customer customised-sales-controls"
                >
                  <div className="input-group add-new-tab">
                    <input
                      type="text"
                      placeholder="Add new tab"
                      className="form-control box-bg-input"
                      value={listName}
                      onChange={(e) => {
                        setListName(e.target.value);
                      }}
                    />
                  </div>
                  <button
                    onClick={(e) => {
                      handleCreateList(listName);
                      setListName("");
                    }}
                    disabled={tabs.length >= TOTAL_PERSONAL_TABS ? true : false}
                    className="btn btn-default"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                  <button
                    onClick={(e) => {
                      setShowConfirmationDelete(true);
                    }}
                    className="btn btn-default"
                  >
                    <FontAwesomeIcon icon={faEraser} />
                  </button>
                </Col>
                <Col xs="12" lg="4"></Col>
                <Col xs="12" lg="4">
                  {showSearch && (
                    <Row>
                      <Col xs={10} lg={10}>
                        <InputGroup className="input-group-pink">
                          <Form.Control
                            className="advanced_filter_customerModel_general_search"
                            placeholder="Search..."
                            name="generalSearch"
                            ref={inputRef}
                            onChange={(e) => handler(e)}
                          />
                          <InputGroup.Text id="basic-addon2">
                            <FontAwesomeIcon icon={faSearch} />
                          </InputGroup.Text>
                        </InputGroup>
                      </Col>
                      <Col xs={2} lg={2}>
                        {accesEdit() && (
                          <Button
                            className="btn-icon-pink"
                            onClick={() => setShowFormAppointment(true)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showConfirmationDelete}
        onHide={() => {
          setShowConfirmationDelete(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this list?</p>
          <div className="flex-row">
            <Button
              className="mt-2 float-right"
              variant="secondary"
              onClick={() => {
                setShowConfirmationDelete(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleDeleteList(listName);
                setShowConfirmationDelete(false);
              }}
              className="ms-2 mt-2 float-right"
              variant="primary"
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {showFormAppointment && (
        <AddAppointmentsForm
          show={showFormAppointment}
          onHide={() => {
            setShowFormAppointment(false);
          }}
        />
      )}
    </div>
  );
}
