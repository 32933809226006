import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios";
import {
  API_STATUS_FAILED,
  API_STATUS_IDLE,
  API_STATUS_PENDING,
  API_STATUS_SUCCESS,
} from "../constants";

const initialState = {
  error: null,
  workflowCads: [],
  selectedWorkflowCad: null,
  createNewVersion: false,
  status: API_STATUS_IDLE,
  progress: 0,
};

export const fetchWorkflowCads = createAsyncThunk(
  "/workflow-activity/workflow-cad/list",
  async (args, thunkAPI) => {
    const { maxResults, page, cancelTokenSource, createdSort, workflowActivityId } = args;
    try {
      const response = await axios.get(
        `/api/v1/workflow-activity/workflow-cad/list?max_results=${maxResults}&page=${page}&order_by=created&order_type=${createdSort ?? "DESC"}&workflowActivityId=${workflowActivityId}`,
        { cancelToken: cancelTokenSource?.token }
      );

      response.data.createdSort = createdSort;

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createWorkflowCad = createAsyncThunk(
  "/workflow-activity/workflow-cad/create",
  async (args, thunkAPI) => {
    try {
      const response = await axios.post(`/api/v1/workflow-activity/workflow-cad/create`, args, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          thunkAPI.dispatch(workflowCadSlice.actions.setProgress(percentCompleted));
        }
      });
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const editWorkflowCad = createAsyncThunk(
  "/workflow-activity/workflow-cad/update",
  async (args, thunkAPI) => {
    try {
      const response = await axios.put(`/api/v1/workflow-activity/workflow-cad/update/${args.id}`, args.data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          thunkAPI.dispatch(workflowCadSlice.actions.setProgress(percentCompleted));
        }
      });
      return { workflowCadId: args.id, data: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createNewVersionWorkflowCad = createAsyncThunk(
  "/workflow-activity/workflow-cad/create-new-version",
  async (args, thunkAPI) => {
    try {
      const response = await axios.post(`/api/v1/workflow-activity/workflow-cad/create-new-version/${args.id}`, args.data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          thunkAPI.dispatch(workflowCadSlice.actions.setProgress(percentCompleted));
        }
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateFollowUpWorkflowCad = createAsyncThunk(
  "/workflow-activity/workflow-cad/update-follow-up",
  async (args, thunkAPI) => {
    try {
      const response = await axios.put(`/api/v1/workflow-activity/workflow-cad/update-follow-up/${args.id}`, args.data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          thunkAPI.dispatch(workflowCadSlice.actions.setProgress(percentCompleted));
        }
      });
      return { workflowCadId: args.id, data: response.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const workflowCadSlice = createSlice({
  name: "workflowCad",
  initialState,
  reducers: {
    setProgress(state, action) {
      state.progress = action.payload;
    },
    clearWorkflowCad(state) {
      state.error = initialState.error;
      state.status = initialState.status;
      state.workflowCads = initialState.workflowCads;
      state.selectedWorkflowCad = initialState.selectedWorkflowCad;
      state.progress = initialState.progress;
    },
    selectWorkFlowCad(state, action) {
      state.selectedWorkflowCad = action.payload;
    },
    removeWorkflowCadFile(state, action) {
      state.selectedWorkflowCad.workflowCadFileModels = state.selectedWorkflowCad.workflowCadFileModels.filter(
        workflowCadFile => workflowCadFile.id !== action.payload
      );
    },
    resetWorkflowCads(state, action) {
      state.workflowCads = [];
    },
    createNewVersionStatus(state, action) {
      state.createNewVersion = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchWorkflowCads.fulfilled, (state, action) => {
      state.workflowCads = [...state.workflowCads, ...action.payload.rows].unique();
      state.error = null;
      state.status = API_STATUS_SUCCESS;
    });
    builder.addCase(fetchWorkflowCads.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(fetchWorkflowCads.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    // Create WorkflowCad
    builder.addCase(createWorkflowCad.fulfilled, (state, action) => ({
      progress: 0,
      selectedWorkflowCad: state.selectedWorkflowCad,
      workflowCads: [...state.workflowCads, action.payload.result],
      error: null,
      status: API_STATUS_SUCCESS,
    }));
    builder.addCase(createWorkflowCad.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(createWorkflowCad.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    // Edit WorkflowCad
    builder.addCase(editWorkflowCad.fulfilled, (state, action) => {
      const { workflowCadId, data } = action.payload;
      const workflowCadIndex = state.workflowCads.findIndex(
        (workflowCad) => workflowCad.id === workflowCadId
      );
      if (workflowCadIndex !== -1) {
        state.workflowCads[workflowCadIndex] = data.result;
      }
      state.progress = 0;
      state.error = null;
      state.status = API_STATUS_SUCCESS;
    });
    builder.addCase(editWorkflowCad.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(editWorkflowCad.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    // Create new version WorkflowCad
    builder.addCase(createNewVersionWorkflowCad.fulfilled, (state, action) => ({
      progress: 0,
      workflowCads: [...state.workflowCads, action.payload.result],
      error: null,
      status: API_STATUS_SUCCESS,
    }));
    builder.addCase(createNewVersionWorkflowCad.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(createNewVersionWorkflowCad.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    // Edit Follow up status request WorkflowCad
    builder.addCase(updateFollowUpWorkflowCad.fulfilled, (state, action) => {
      const { workflowCadId, data } = action.payload;
      const workflowCadIndex = state.workflowCads.findIndex(
        (workflowCad) => workflowCad.id === workflowCadId
      );
      if (workflowCadIndex !== -1) {
        state.workflowCads[workflowCadIndex] = data.result;
      }
      state.progress = 0;
      state.error = null;
      state.status = API_STATUS_SUCCESS;
    });
    builder.addCase(updateFollowUpWorkflowCad.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(updateFollowUpWorkflowCad.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
  },
});

export const {
  clearWorkflowCad,
  selectWorkFlowCad,
  resetWorkflowCads,
  removeWorkflowCadFile,
  createNewVersionStatus,
} = workflowCadSlice.actions;

export default workflowCadSlice.reducer;
