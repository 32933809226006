import {
  REACT_APP_WORKFLOW_SALE_STATUS_CANCELLED,
  REACT_APP_WORKFLOW_TYPE_APPRAISAL,
  REACT_APP_WORKFLOW_TYPE_VALUATION,
} from "../../../../global/const";

const LOOSE_DIAMOND = "Loose Diamond";
const ENGAGEMENT_RING = "Engagement Ring";
const JEWELLERY = "Jewellery";
const WEDDING_BAND = "Wedding Band";
const MANUFACTURING = "Manufacturing";
const STATUS_COLLECTED_COMPLETED = "Collected/Completed";
const SPARKLING_BRUSH = "Sparkling Brush";
const E_GIFT_CARD = "E-Gift Card";

const MESSAGE_QQC =
  "Before starting the delivering to customer, you need to complete the quality control check for all workflow activities";

export const validateWorkflowActivityDelivering = (
  isWorkflowActivitiesDeliveringToCustomer,
  sale,
  workflowSales
) => {
  if (!isWorkflowActivitiesDeliveringToCustomer) {
    return {
      valid: true,
      message: "",
    };
  }

  if (!sale) {
    return {
      valid: false,
      message: "Sale not exist",
    };
  }

  const { ownReferenceModified, saleType } = sale;

  if (workflowSales.length <= 0) {
    return {
      valid: false,
      message:
        "The sales does not have a workflow sales to create this activity",
    };
  }

  const allQualiyControlCheck = existAllWorkflowActivityQQC(
    workflowSales,
    saleType
  );

  if (!allQualiyControlCheck) {
    return {
      valid: false,
      message: MESSAGE_QQC,
    };
  }

  const inProcessQualiyControlCheck = workflowActivityQQCAnyInProcess(
    workflowSales,
    saleType
  );

  if (!inProcessQualiyControlCheck) {
    return {
      valid: false,
      message: MESSAGE_QQC,
    };
  }

  if ([LOOSE_DIAMOND, SPARKLING_BRUSH, E_GIFT_CARD].includes(saleType)) {
    return {
      valid: true,
      message: "",
    };
  }

  const status = getStatusWorkflowActivityManufacturing(workflowSales);

  if (
    [ENGAGEMENT_RING, WEDDING_BAND].includes(saleType) &&
    status === STATUS_COLLECTED_COMPLETED
  ) {
    return {
      valid: true,
      message: "",
    };
  }

  const stockNumber =
    getStockNumberWorkflowActivityManufacturing(workflowSales);

  if (
    saleType === JEWELLERY &&
    status === STATUS_COLLECTED_COMPLETED &&
    (stockNumber || ownReferenceModified)
  ) {
    return {
      valid: true,
      message: "",
    };
  }

  let message = 'The manufacturing activity needs to be "Collected/Completed"';

  if (saleType === JEWELLERY) {
    message += " and to have stock number or be customised";
  }

  return {
    valid: false,
    message: message,
  };
};

export const getStatusWorkflowActivityManufacturing = (workflowSales) => {
  let statusResponse = "";

  workflowSales.forEach((workflowSale) => {
    const { status } = workflowSale;
    workflowSale.workflowActivityModels.forEach((workflowActivity) => {
      const { type } = workflowActivity;
      if (MANUFACTURING === type && status === STATUS_COLLECTED_COMPLETED) {
        statusResponse = status;
      }
    });
  });

  return statusResponse;
};

export const getStockNumberWorkflowActivityManufacturing = (workflowSales) => {
  let stockNumberResponse = null;

  workflowSales.forEach((workflowSale) => {
    const { status } = workflowSale;
    workflowSale.workflowActivityModels.forEach((workflowActivity) => {
      const { type, stockNumber } = workflowActivity;
      if (MANUFACTURING === type && status === STATUS_COLLECTED_COMPLETED) {
        stockNumberResponse = stockNumber;
      }
    });
  });

  return stockNumberResponse;
};

export const existAllWorkflowActivityQQC = (workflowSales, type) => {
  if (!workflowSales) {
    return true;
  }

  if (![ENGAGEMENT_RING, WEDDING_BAND].includes(type)) {
    return true;
  }

  let activities = [];
  workflowSales.forEach((workflowSale) => {
    const { status } = workflowSale;
    workflowSale.workflowActivityModels.forEach((workflowActivity) => {
      const { type } = workflowActivity;

      if (
        status !== REACT_APP_WORKFLOW_SALE_STATUS_CANCELLED &&
        ![
          REACT_APP_WORKFLOW_TYPE_VALUATION,
          REACT_APP_WORKFLOW_TYPE_APPRAISAL,
        ].includes(type)
      ) {
        activities.push(workflowActivity);
      }
    });
  });

  const notExist = activities
    .map((activity) => {
      const { qualityControlCheckModel } = activity;

      return qualityControlCheckModel;
    })
    .filter((activity) => activity === null);

  if (notExist.length > 0) {
    return false;
  }

  return true;
};

export const workflowActivityQQCAnyInProcess = (workflowSales, type) => {
  if (!workflowSales) {
    return true;
  }

  if (![ENGAGEMENT_RING, WEDDING_BAND].includes(type)) {
    return true;
  }

  let activities = [];
  workflowSales.forEach((workflowSale) => {
    const { status } = workflowSale;
    workflowSale.workflowActivityModels.forEach((workflowActivity) => {
      const { type } = workflowActivity;

      if (
        status !== REACT_APP_WORKFLOW_SALE_STATUS_CANCELLED &&
        ![
          REACT_APP_WORKFLOW_TYPE_VALUATION,
          REACT_APP_WORKFLOW_TYPE_APPRAISAL,
        ].includes(type)
      ) {
        activities.push(workflowActivity);
      }
    });
  });

  if (!Array.isArray(activities)) {
    return true;
  }

  const qualityControlChecks = activities.map((activity) => {
    return activity.qualityControlCheckModel;
  });

  let allInProcess = true;

  qualityControlChecks.forEach((qualityControlCheck) => {
    if (qualityControlCheck?.approved === null) {
      allInProcess = false;
    }
  });

  return allInProcess;
};
