import React, { Component } from "react";
import {
  Modal,
  Button,
  Form,
  FormLabel,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import DateField from "../../formComponents/DateField";
import LoadingLayer from "../../../LoadingLayer";
import FlashMessages from "../../../FlashMessages";
import SaleWorkflowEditCAD from "./SaleWorkflowEditCAD";
import SaleWorkflowEditDeliveringToCustomer from "./SaleWorkflowEditDeliveringToCustomer";
import SaleWorkflowEditEngraving from "./SaleWorkflowEditEngraving";
import SaleWorkflowEditFixing from "./SaleWorkflowEditFixing";
import SaleWorkflowEditManufacturing from "./SaleWorkflowEditManufacturing";
import SaleWorkflowEditPhotos from "./SaleWorkflowEditPhotos";
import SaleWorkflowEditPolish from "./SaleWorkflowEditPolish";
import SaleWorkflowEditResizing from "./SaleWorkflowEditResizing";
import SaleWorkflowEditSetting from "./SaleWorkflowEditSetting";
import SaleWorkflowEditValuation from "./SaleWorkflowEditValuation";
import Auth from "../../../../Auth";
import axios from "axios";
import * as globalConst from "../../../../global/const";
import { Typeahead } from "react-bootstrap-typeahead";

// SCSS
import "../../../../css/sales/sale_edit.scss";
import "../../../../css/modal.scss";
import SaleWorkflowEditReturn from "./SaleWorkflowEditReturn";
import { SendUpdateWorkflowReturn } from "../Actions/UpdateWorkflowReturn";
import { workflowReturnPrepareData } from "../../edit/Acctions/WorkflowReturn";
import {
  getDiamondCertificate,
  getDiamondGdOwnKey,
} from "../../edit/Data/FindDataDiamondCertificate";
import {
  FindJewelleyByContactName,
  findDefauldProvider,
} from "../../../actions/JewelleryFind";
import { isManufacturingActivity } from "../Actions/WorkflowActivity";
import FormInputTextarea from "../../../Form/FormInputTextarea";
import Util from "../../../../Util";

const source = axios.CancelToken.source();

const LIST_STATUS_NOT_DELIVERY = ["In transit", "Collected/Completed"];
const CANCELLED = "Cancelled";

const BTNS_DISABLES = [
  globalConst.BTN_CREATE_WORKFLOW,
  globalConst.BTN_EDIT_SALE,
  globalConst.BTN_DUPLICATE_SALE,
  globalConst.BTN_QUALITY_CONTROL_CHECK_START,
  globalConst.BTN_QUALITY_CONTROL_CHECK_PENDING,
  globalConst.BTN_QUALITY_CONTROL_CHECK_APPROVED,
  globalConst.BTN_QUALITY_CONTROL_CHECK_REJECTED,
];

class SaleWorkflowEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saleWorkflow: props.saleWorkflow,
      showPanelInformation: props.showPanelInformation,
      sale: props.sale,
      edited_sale: null,
      error_messages: [],
      warning_messages: [],
      success_messages: [],
      updating_sale: false,
      providerId: props.saleWorkflow.providerId ?? 0,
      existProviderId: props.saleWorkflow.providerId ? true : false,
      expectedDate: props.saleWorkflow.providerExpectedDate ?? "",
      givenDate: props.saleWorkflow.providerGivenDate ?? "",
      status: props.saleWorkflow.status ?? "",
      statusComment: props.saleWorkflow.statusComment ?? "",
      comments: props.saleWorkflow.comments ?? "",
      saleWorkflowManufacturing: false,
      saleWorkflowManufacturingShow: true,
      saleWorkflowResizing: false,
      saleWorkflowResizingShow: true,
      saleWorkflowFixing: false,
      saleWorkflowFixingShow: true,
      saleWorkflowSetting: false,
      saleWorkflowSettingShow: true,
      saleWorkflowPolishing: false,
      saleWorkflowPolishingShow: true,
      provider: [],
      providers: [],
      showSelectProvider: false,
      melees: [],
      isChange: false,
      validated: false,
      showStatusComment: false,
      showReturnForm: true,
      providerSave: props.saleWorkflow.providerId ? true : false,
    };

    this.showFormProvider = false;
    this.showOtherForm = false;

    this.activity = {};

    this.manufacturing = React.createRef();
    this.resizing = React.createRef();
    this.setting = React.createRef();
    this.engraving = React.createRef();
    this.deliveringToCustomer = React.createRef();
    this.CAD = React.createRef();
    this.fixing = React.createRef();
    this.photos = React.createRef();
    this.polishing = React.createRef();
    this.valuation = React.createRef();
    this.return = React.createRef();

    this.closeEditionComponent = this.closeEditionComponent.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleChangeDateField = this.handleChangeDateField.bind(this);
    this.saveEdit = this.saveEdit.bind(this);
    this.saveManufacturingEdit = this.saveManufacturingEdit.bind(this);
    this.saveResizingEdit = this.saveResizingEdit.bind(this);
    this.saveSettingEdit = this.saveSettingEdit.bind(this);
    this.saveEngravingEdit = this.saveEngravingEdit.bind(this);
    this.saveDeliveringToCustomerEdit =
      this.saveDeliveringToCustomerEdit.bind(this);
    this.saveCADEdit = this.saveCADEdit.bind(this);
    this.saveFixingEdit = this.saveFixingEdit.bind(this);
    this.savePhotosEdit = this.savePhotosEdit.bind(this);
    this.savePolishingEdit = this.savePolishingEdit.bind(this);
    this.saveValuationEdit = this.saveValuationEdit.bind(this);

    this.loadListInformation();
  }

  loadListInformation = () => {
    this.listStatus = Util.getStorageParameter("REACT_APP_WORKFLOW_STATUS").split(",");
    this.listCancelWorkflow =
      Util.getStorageParameter("REACT_APP_LIST_CANCEL_WORKFLOW").split("~");
  };

  saleWorkflowActivityDeliveringToCustomer = false;
  saleWorkflowActivityCAD = false;
  saleWorkflowActivityPhotos = false;
  saleWorkflowActivityPolishing = false;
  saleWorkflowActivityValuation = false;
  saleWorkflowActivityManufacturing = false;
  saleWorkflowActivityResizing = false;
  saleWorkflowActivitySetting = false;
  saleWorkflowActivityValuetion = false;
  saleWorkflowActivityEngraving = false;
  saleWorkflowActivityFixing = false;
  saleWorkflowActivityReturn = false;

  handleChangeSelect = async (event) => {
    const field_name = event.target.name;
    let state = {};

    state[field_name] = event.target.value;
    state["showStatusComment"] = false;

    await this.setState(state);

    if (this.state.status === CANCELLED) {
      this.setState({
        showStatusComment: true,
      });
    }
  };

  handleChangeProvider(event) {
    if (event.length <= 0) {
      this.setState({
        providerId: 0,
      });

      return;
    }

    this.setState({
      providerId: event[0].id,
      provider: event,
    });
  }

  handleChangeDateField = (date, field_name) => {
    const state = {};

    if (isNaN(Date.parse(date))) {
      state[field_name] = null;
    } else {
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();

      date = new Date(year, month - 1, day, hours, minutes);
      if (isNaN(Date.parse(date))) {
        state[field_name] = null;
      } else {
        state[field_name] =
          year + "-" + month + "-" + day + " " + hours + ":" + minutes;
      }
    }
    this.setState(state);
  };

  handleChangeText = async (event) => {
    const { value, name } = event.target;

    await this.setState({
      [name]: value,
    });
  };

  closeEditionComponent() {
    this.props.disabledActionsBotons([]);
    this.props.closeEditionComponent(this.state.isChange);
  }

  showOtherFormValidate = () => {
    this.showOtherForm = false;

    if (
      this.saleWorkflowActivityCAD !== false ||
      this.saleWorkflowActivityEngraving !== false ||
      this.saleWorkflowActivityFixing !== false ||
      this.saleWorkflowActivityManufacturing !== false ||
      this.saleWorkflowActivityPhotos !== false ||
      this.saleWorkflowActivityPolishing !== false ||
      this.saleWorkflowActivityResizing !== false ||
      this.saleWorkflowActivitySetting !== false ||
      this.saleWorkflowActivityValuation !== false ||
      this.saleWorkflowActivityReturn !== false
    ) {
      this.showOtherForm = true;
    }
  };

  showFormProviderValidate = () => {
    this.showFormProvider = false;

    if (
      this.saleWorkflowActivityCAD !== false ||
      this.saleWorkflowActivityEngraving !== false ||
      this.saleWorkflowActivityFixing !== false ||
      this.saleWorkflowActivityManufacturing !== false ||
      this.saleWorkflowActivityPhotos !== false ||
      this.saleWorkflowActivityPolish !== false ||
      this.saleWorkflowActivityResizing !== false ||
      this.saleWorkflowActivitySetting !== false ||
      this.saleWorkflowActivityValuation !== false
    ) {
      this.showFormProvider = true;
    }
  };

  validDisabledProvider = () => {
    if (!isManufacturingActivity(this.state.saleWorkflow)) {
      return false;
    }

    if (!this.state.providerId) {
      return false;
    }

    if (this.state.providerId && !this.state.providerSave) {
      return false;
    }

    return true;
  };

  handleChangeInput = async (value, name) => {
    this.setState({
      [name]: value,
    });
  };

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    let workflowActivityModels = this.state.saleWorkflow.workflowActivityModels;

    workflowActivityModels.map((activity) => {
      switch (activity.type) {
        case "Manufacturing":
          this.saleWorkflowActivityManufacturing = activity;
          break;
        case "Resizing":
          this.saleWorkflowActivityResizing = activity;
          break;
        case "Setting":
          this.saleWorkflowActivitySetting = activity;
          break;
        case "Engraving":
          this.saleWorkflowActivityEngraving = activity;
          break;
        case "Delivering to customer":
          this.saleWorkflowActivityDeliveringToCustomer = activity;
          this.showFormProvider = false;

          this.listStatus = this.listStatus.filter(
            (item) => !LIST_STATUS_NOT_DELIVERY.includes(item)
          );
          break;
        case "CAD":
          this.saleWorkflowActivityCAD = activity;
          break;
        case "Fixing":
          this.saleWorkflowActivityFixing = activity;
          break;
        case "Photos":
          this.saleWorkflowActivityPhotos = activity;
          break;
        case "Polish":
          this.saleWorkflowActivityPolishing = activity;
          break;
        case "Return":
          const workflowReturnModel = workflowReturnPrepareData(
            activity.workflowReturnModel
          );

          activity.workflowReturnModel = workflowReturnModel;

          this.saleWorkflowActivityReturn = activity;

          this.activity = activity;
          break;
        case "Appraisal":
        case "Valuation":
          activity.type = "Appraisal";
          this.saleWorkflowActivityValuation = activity;
          break;
        default:
      }

      return null;
    });

    this.showFormProviderValidate();
    this.showOtherFormValidate();

    let expectedDate = this.state.expectedDate
      ? new Date(this.state.expectedDate)
      : null;
    let givenDate = this.state.givenDate
      ? new Date(this.state.givenDate)
      : null;

    return (
      <div>
        <Modal
          show={true}
          onHide={this.closeEditionComponent}
          dialogClassName="modal-90w"
          backdrop="static"
          backdropClassName={
            this.state.showPanelInformation
              ? "modal-black-show-informacion-panel"
              : ""
          }
          className={
            this.state.showPanelInformation
              ? "modal-show-informacion-panel"
              : ""
          }
        >
          <Modal.Header closeButton>Editing activity</Modal.Header>
          <Modal.Body>
            <div id="WorkflowSaleEdit_component">
              {this.state.updating_sale === true ? <LoadingLayer /> : ""}
              {
                <FlashMessages
                  error_messages={this.state.error_messages}
                  warning_messages={this.state.warning_messages}
                  success_messages={this.state.success_messages}
                />
              }

              <Card className="mb-2">
                <Card.Body>
                  {this.showOtherForm ? (
                    <div>
                      {this.showFormProvider && (
                        <div className="col-12">
                          <FormLabel className="d-block">Provider</FormLabel>
                          <Form.Group>
                            {this.state.showSelectProvider ? (
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="nameComplete"
                                options={this.state.providers}
                                placeholder="Choose a provider..."
                                defaultSelected={this.state.provider}
                                onChange={(e) => this.handleChangeProvider(e)}
                                disabled={this.validDisabledProvider()}
                              />
                            ) : null}
                          </Form.Group>
                        </div>
                      )}

                      <div className="col-12">
                        <Form.Group className="form-group date-small">
                          <FormLabel>
                            {this.saleWorkflowActivityReturn
                              ? "Date received from customer"
                              : "Date given to provider"}
                          </FormLabel>
                          <DateField
                            handleChangeDateField={this.handleChangeDateField}
                            field_name="givenDate"
                            currentDate={givenDate}
                          />
                        </Form.Group>
                      </div>

                      {this.showFormProvider && (
                        <div className="col-12">
                          <Form.Group className="form-group date-small">
                            <FormLabel>Expected date from provider</FormLabel>
                            <DateField
                              handleChangeDateField={this.handleChangeDateField}
                              field_name="expectedDate"
                              currentDate={expectedDate}
                            />
                          </Form.Group>
                        </div>
                      )}
                    </div>
                  ) : null}
                  <div className="col-12">
                    <FormLabel className="d-block">Status</FormLabel>
                    <Form.Select
                      onChange={(e) => this.handleChangeSelect(e)}
                      value={this.state.status ?? ""}
                      name="status"
                    >
                      <option value=""></option>
                      {this.listStatus.map((listStatus) => {
                        return (
                          <option
                            key={listStatus}
                            value={listStatus}
                            disabled={this.disabledStatus(listStatus)}
                          >
                            {listStatus}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                  {this.state.showStatusComment && (
                    <Row>
                      <Col>
                        <Form noValidate validated={this.state.validated}>
                          <Form.Group controlId="validationStatusComment">
                            <FormLabel className="d-block">
                              Status comment
                            </FormLabel>
                            <Form.Select
                              onChange={(e) => this.handleChangeSelect(e)}
                              value={this.state.statusComment ?? ""}
                              name="statusComment"
                              required
                            >
                              <option value=""></option>
                              {this.listCancelWorkflow.map(
                                (listCancelWorkflow) => {
                                  return (
                                    <option
                                      key={listCancelWorkflow}
                                      value={listCancelWorkflow}
                                    >
                                      {listCancelWorkflow}
                                    </option>
                                  );
                                }
                              )}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              The status comment can’t be empty
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form>
                      </Col>
                    </Row>
                  )}

                  <FormInputTextarea
                    label="Comments"
                    nameInput="comments"
                    value={this.state.comments ?? ""}
                    required={true}
                    onResponse={this.handleChangeInput}
                  />
                </Card.Body>
              </Card>

              <h4 className="shipping-info-heading">Workflow activities</h4>
              {this.saleWorkflowActivityManufacturing ? (
                <div className="general_information sales-details-card">
                  <h2>Manufacturing</h2>
                  <SaleWorkflowEditManufacturing
                    activity={this.saleWorkflowActivityManufacturing}
                    ref={this.manufacturing}
                    responseProvider={this.responseProvider}
                    melees={this.state.melees}
                    status={this.state.status ?? ""}
                    saleWorkflow={this.state.saleWorkflow}
                  />
                </div>
              ) : null}
              {this.saleWorkflowActivityResizing ? (
                <div className="general_information sales-details-card">
                  <h2>Resizing</h2>
                  <SaleWorkflowEditResizing
                    activity={this.saleWorkflowActivityResizing}
                    ref={this.resizing}
                  />
                </div>
              ) : null}
              {this.saleWorkflowActivitySetting ? (
                <div className="general_information sales-details-card">
                  <h2>Setting</h2>
                  <SaleWorkflowEditSetting
                    activity={this.saleWorkflowActivitySetting}
                    ref={this.setting}
                  />
                </div>
              ) : null}
              {this.saleWorkflowActivityEngraving ? (
                <div className="general_information sales-details-card">
                  <h2>Engraving</h2>
                  <SaleWorkflowEditEngraving
                    activity={this.saleWorkflowActivityEngraving}
                    ref={this.engraving}
                  />
                </div>
              ) : null}
              {this.saleWorkflowActivityCAD ? (
                <div className="general_information sales-details-card">
                  <h2>CAD</h2>
                  <SaleWorkflowEditCAD
                    activity={this.saleWorkflowActivityCAD}
                    ref={this.CAD}
                    saleWorkflow={this.state.saleWorkflow}
                  />
                </div>
              ) : null}
              {this.saleWorkflowActivityPolishing ? (
                <div className="general_information sales-details-card">
                  <h2>Polish</h2>
                  <SaleWorkflowEditPolish
                    activity={this.saleWorkflowActivityPolishing}
                    ref={this.polishing}
                  />
                </div>
              ) : null}
              {this.saleWorkflowActivityFixing ? (
                <div className="general_information sales-details-card">
                  <h2>Fixing</h2>
                  <SaleWorkflowEditFixing
                    activity={this.saleWorkflowActivityFixing}
                    ref={this.fixing}
                  />
                </div>
              ) : null}
              {this.saleWorkflowActivityPhotos ? (
                <div className="general_information sales-details-card">
                  <h2>Photos</h2>
                  <SaleWorkflowEditPhotos
                    activity={this.saleWorkflowActivityPhotos}
                    ref={this.photos}
                  />
                </div>
              ) : null}
              {this.saleWorkflowActivityDeliveringToCustomer ? (
                <div className="general_information sales-details-card">
                  <h2>Delivering to Customer</h2>
                  <SaleWorkflowEditDeliveringToCustomer
                    activity={this.saleWorkflowActivityDeliveringToCustomer}
                    ref={this.deliveringToCustomer}
                  />
                </div>
              ) : null}
              {this.saleWorkflowActivityValuation ? (
                <div className="general_information sales-details-card">
                  <h2>Appraisal</h2>
                  <SaleWorkflowEditValuation
                    activity={this.saleWorkflowActivityValuation}
                    ref={this.valuation}
                  />
                </div>
              ) : null}
              {this.saleWorkflowActivityReturn && this.state.showReturnForm && (
                <div className="general_information sales-details-card">
                  <h2>Return</h2>
                  <SaleWorkflowEditReturn
                    sale={this.state.sale}
                    activity={this.saleWorkflowActivityReturn}
                    workflowSale={this.state.saleWorkflow}
                    onResponseReturn={(evet) => this.onResponseReturn(evet)}
                    onResponseLoading={this.onResponseLoading}
                  />
                </div>
              )}
              <Button type="submit" onClick={this.saveEdit}>
                Save
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  onResponseLoading = async (value) => {
    this.setState({
      updating_sale: value,
    });
  };

  onResponseReturn = (activity) => {
    this.activity = activity;
  };

  disabledStatus = (status) => {
    if (!this.saleWorkflowActivityReturn) {
      return false;
    }

    if (this.saleWorkflowActivityReturn && status === CANCELLED) {
      return false;
    }

    return true;
  };

  async saveEdit(event) {
    if (
      this.state.status === CANCELLED &&
      this.state.statusComment.length <= 0
    ) {
      this.setState({
        validated: true,
      });

      return;
    }

    event.preventDefault();

    this.setState({
      updating_sale: true,
      isChange: false,
      providerSave: false,
    });

    const user = Auth.getAuthenticatedUser();
    const saleWorkflow = this.props.saleWorkflow;

    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else if (saleWorkflow === null || saleWorkflow === undefined) {
      this.setState({
        error_messages: [
          "Sale identification error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_SALE_EDIT;
      url = url.replace("{id}", saleWorkflow.id);

      const params = new URLSearchParams();
      if (this.state.providerId !== 0) {
        params.append("providerId", this.state.providerId);
      }

      params.append("status", this.state.status);
      params.append(
        "providerExpectedDate",
        this.state.expectedDate.length > 0 ? this.state.expectedDate : null
      );
      params.append(
        "providerGivenDate",
        this.state.givenDate.length > 0 ? this.state.givenDate : null
      );
      params.append(
        "statusComment",
        this.state.statusComment.length > 0 ? this.state.statusComment : null
      );
      params.append(
        "comments",
        this.state.comments.length > 0 ? this.state.comments : null
      );

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      axios
        .put(url, params, config)
        .then(async (response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              isChange: true,
              updating_sale: false,
              success_messages: ["Activity updated"],
            });

            if (this.saleWorkflowActivityManufacturing) {
              await this.saveManufacturingEdit();
            }
            if (this.saleWorkflowActivityResizing) {
              await this.saveResizingEdit();
            }
            if (this.saleWorkflowActivitySetting) {
              await this.saveSettingEdit();
            }
            if (this.saleWorkflowActivityEngraving) {
              await this.saveEngravingEdit();
            }
            if (this.saleWorkflowActivityDeliveringToCustomer) {
              await this.saveDeliveringToCustomerEdit();
            }
            if (this.saleWorkflowActivityCAD) {
              await this.saveCADEdit();
            }
            if (this.saleWorkflowActivityFixing) {
              await this.saveFixingEdit();
            }
            if (this.saleWorkflowActivityPhotos) {
              await this.savePhotosEdit();
            }
            if (this.saleWorkflowActivityPolishing) {
              await this.savePolishingEdit();
            }
            if (this.saleWorkflowActivityValuation) {
              await this.saveValuationEdit();
            }
            if (this.saleWorkflowActivityReturn) {
              await this.saveReturn();
            }

            this.props.updateWorkflowSaleInformation(true);
          } else {
            this.setState({
              updating_sale: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }

          let elements = document.getElementsByClassName("modal");
          elements[0].scrollTo(0, 0);
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else {
            this.setState({
              updating_sale: true,
            });
          }
          return null;
        })
        .finally(() => {
          this.setState({
            providerSave: this.state.providerId ? true : false,
          });
        });
    }
  }

  responseProvider = async (value) => {
    if (!value) {
      return;
    }

    let provider = { id: 0, name: "" };

    if (!this.state.existProviderId) {
      provider = findDefauldProvider(
        this.state.providers ?? [],
        Util.getStorageParameter("REACT_APP_DEFAULT_MANUFACTURING_PROVIDER"),
        "name"
      );
    }

    if (this.state.existProviderId) {
      provider = findDefauldProvider(
        this.state.providers ?? [],
        provider.name,
        "name"
      );
    }

    await this.setState({
      showSelectProvider: false,
    });

    if (provider.length > 0) {
      const { id } = provider[0];
      await this.setState({
        provider: provider,
        providerId: id,
        providerSave: true,
      });
    }

    await this.setState({
      showSelectProvider: true,
    });
  };

  async saveManufacturingEdit() {
    let activity = this.manufacturing.current.state.activity;
    let cost = this.manufacturing.current.state.cost
      ? this.manufacturing.current.state.cost
      : null;
    let invoiceNumber = this.manufacturing.current.state.invoiceNumber;
    let invoiceTo = this.manufacturing.current.state.invoiceTo;
    let stockNumber = this.manufacturing.current.state.stockNumber;
    let stockInShowroom = this.manufacturing.current.state.stockInShowroom;
    let readyToPayToProvider =
      this.manufacturing.current.state.readyToPayToProvider;
    let metalYellowWeight = this.manufacturing.current.state.metalYellowWeight;
    let metalWhiteWeight = this.manufacturing.current.state.metalWhiteWeight;
    let metalRoseWeight = this.manufacturing.current.state.metalRoseWeight;
    let metalPlatinumWeight =
      this.manufacturing.current.state.metalPlatinumWeight;
    let metalCost = this.manufacturing.current.state.metalCost
      ? this.manufacturing.current.state.metalCost
      : null;
    let metalSupplierName = this.manufacturing.current.state.metalSupplierName;
    let metalInvoiceNumber =
      this.manufacturing.current.state.metalInvoiceNumber;
    let metalInvoiceTo = this.manufacturing.current.state.metalInvoiceTo;
    let metalCostReady = this.manufacturing.current.state.metalCostReady;
    let melesSets = this.manufacturing.current.state.melesSets;
    let mmdiaInvoice = this.manufacturing.current.state.mmdiaInvoice;
    let novaInvoice = this.manufacturing.current.state.novaInvoice;
    let invoiceStatus = this.manufacturing.current.state.invoiceStatus;
    let originalLocation = this.manufacturing.current.state.originalLocation;
    let attachedDiamondDetails =
      this.manufacturing.current.state.attachedDiamondDetails;
    let attachedJewelleryDetails =
      this.manufacturing.current.state.attachedJewelleryDetails ?? null;
    let stockCategory = this.manufacturing.current.state.stockCategory ?? null;

    const { saleType, diamondDetails, gdOwnKey } = this.state.sale;
    if (
      (saleType === "Wedding Band" || saleType === "Jewellery") &&
      attachedDiamondDetails !== null
    ) {
      let newDiamondDetails = diamondDetails ?? "";
      let newDiamondGdOwnKey = gdOwnKey ?? "";

      attachedDiamondDetails.forEach((attachedDiamondDetail) => {
        newDiamondDetails = getDiamondCertificate(
          attachedDiamondDetail,
          newDiamondDetails
        );
        newDiamondGdOwnKey = getDiamondGdOwnKey(
          attachedDiamondDetail,
          newDiamondGdOwnKey
        );
      });

      await this.editSale(newDiamondDetails, newDiamondGdOwnKey);
    }

    this.setState({
      updating_sale: true,
    });

    const user = Auth.getAuthenticatedUser();
    const saleWorkflow = this.props.saleWorkflow;

    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else if (saleWorkflow === null || saleWorkflow === undefined) {
      this.setState({
        error_messages: [
          "Sale identification error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_EDIT;
      url = url.replace("{id}", activity.id);

      const params = new URLSearchParams();
      params.append("cost", cost);
      params.append("invoiceNumber", invoiceNumber);
      params.append("invoiceTo", invoiceTo);
      params.append("stockNumber", stockNumber);
      params.append("stockInShowroom", stockInShowroom);
      params.append("readyToPayToProvider", readyToPayToProvider);
      params.append("metalYellowWeight", metalYellowWeight);
      params.append("metalWhiteWeight", metalWhiteWeight);
      params.append("metalRoseWeight", metalRoseWeight);
      params.append("metalPlatinumWeight", metalPlatinumWeight);
      params.append("metalCost", metalCost);
      params.append("metalSupplierName", metalSupplierName);
      params.append("metalInvoiceNumber", metalInvoiceNumber);
      params.append("metalInvoiceTo", metalInvoiceTo);
      params.append("metalCostReady", metalCostReady);
      params.append("melesSet", JSON.stringify(melesSets));
      params.append("mmdiaInvoice", mmdiaInvoice);
      params.append("novaInvoice", novaInvoice);
      params.append("invoiceStatus", invoiceStatus);
      params.append("originalLocation", originalLocation);
      params.append("stockCategory", stockCategory ?? "");

      if (attachedJewelleryDetails) {
        params.append(
          "attachedJewelleryDetails",
          JSON.stringify(attachedJewelleryDetails)
        );
      }

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      await axios
        .put(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            if (attachedJewelleryDetails) {
              this.props.updateWorkflowSaleInformation(true);
            }

            this.manufacturing.current.state.attachedJewelleryDetails = null;

            this.setState({
              saleWorkflowManufacturingShow: false,
              updating_sale: false,
              success_messages: ["Activity updated"],
            });

            this.setState({
              saleWorkflowManufacturing: data.result,
              saleWorkflowManufacturingShow: true,
            });

            this.manufacturing.current.updateMelees(
              data.result.workflowMelesModels
            );
          } else {
            this.setState({
              updating_sale: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else {
            this.setState({
              updating_sale: true,
            });
          }
          return null;
        });
    }
  }

  async saveResizingEdit() {
    let activity = this.resizing.current.state.activity;
    let cost = this.resizing.current.state.cost
      ? this.resizing.current.state.cost
      : null;
    let readyToPayToProvider = this.resizing.current.state.readyToPayToProvider;
    let invoiceNumber = this.resizing.current.state.invoiceNumber;
    let invoiceTo = this.resizing.current.state.invoiceTo;
    let metalYellowWeight = this.resizing.current.state.metalYellowWeight;
    let metalWhiteWeight = this.resizing.current.state.metalWhiteWeight;
    let metalRoseWeight = this.resizing.current.state.metalRoseWeight;
    let metalPlatinumWeight = this.resizing.current.state.metalPlatinumWeight;
    let metalCost = this.resizing.current.state.metalCost
      ? this.resizing.current.state.metalCost
      : null;
    let metalSupplierName = this.resizing.current.state.metalSupplierName;
    let metalInvoiceNumber = this.resizing.current.state.metalInvoiceNumber;
    let metalCostReady = this.resizing.current.state.metalCostReady;
    let metalInvoiceTo = this.resizing.current.state.metalInvoiceTo;
    let melesSets = this.resizing.current.state.melesSets;
    let mmdiaInvoice = this.resizing.current.state.mmdiaInvoice;
    let novaInvoice = this.resizing.current.state.novaInvoice;
    let invoiceStatus = this.resizing.current.state.invoiceStatus;

    this.setState({
      updating_sale: true,
    });

    const user = Auth.getAuthenticatedUser();
    const saleWorkflow = this.props.saleWorkflow;

    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else if (saleWorkflow === null || saleWorkflow === undefined) {
      this.setState({
        error_messages: [
          "Sale identification error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_EDIT;
      url = url.replace("{id}", activity.id);

      const params = new URLSearchParams();
      params.append("cost", cost);
      params.append("invoiceNumber", invoiceNumber);
      params.append("invoiceTo", invoiceTo);
      params.append("readyToPayToProvider", readyToPayToProvider);
      params.append("metalYellowWeight", metalYellowWeight);
      params.append("metalWhiteWeight", metalWhiteWeight);
      params.append("metalRoseWeight", metalRoseWeight);
      params.append("metalPlatinumWeight", metalPlatinumWeight);
      params.append("metalCost", metalCost);
      params.append("metalSupplierName", metalSupplierName);
      params.append("metalInvoiceNumber", metalInvoiceNumber);
      params.append("metalCostReady", metalCostReady);
      params.append("metalInvoiceTo", metalInvoiceTo);
      params.append("melesSet", JSON.stringify(melesSets));
      params.append("mmdiaInvoice", mmdiaInvoice);
      params.append("novaInvoice", novaInvoice);
      params.append("invoiceStatus", invoiceStatus);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      await axios
        .put(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              saleWorkflowResizingShow: false,
              updating_sale: false,
              success_messages: ["Activity updated"],
            });

            this.setState({
              saleWorkflowResizing: data.result,
              saleWorkflowResizingShow: true,
            });

            this.resizing.current.updateMelees(data.result.workflowMelesModels);
          } else {
            this.setState({
              updating_sale: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else {
            this.setState({
              updating_sale: true,
            });
          }
          return null;
        });
    }
  }

  async editSale(newDiamondDetails, newDiamondGdOwnKey) {
    this.setState({
      updating_sale: true,
    });

    const user = Auth.getAuthenticatedUser();
    const sale = this.state.sale;
    if (!user || !user?.employeeModel) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
      return;
    }
    if (!sale) {
      this.setState({
        error_messages: [
          "Sale identification error. Reload the page and try again.",
        ],
      });
      return;
    }

    let url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_SALES_EDIT;
    url = url.replace("{id}", sale.id);
    const params = new URLSearchParams();
    params.append("diamondDetails", newDiamondDetails);
    params.append("gdOwnKey", newDiamondGdOwnKey);

    const config = {
      cancelToken: source.token,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    };

    await axios
      .put(url, params, config)
      .then((response) => {
        const data = response.data;
        if (data !== undefined && data.code === 200) {
          this.setState({
            updating_sale: false,
            success_messages: ["Activity updated"],
          });
          this.props.updateSaleInformation(data.result);
          this.props.updateSaleForList(
            this.props.selected_sale_index,
            data.result
          );
        } else {
          this.setState({
            updating_sale: false,
            error_messages: [
              "There was a problem processing the information. Please reload this page and try again.",
            ],
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error_messages = [];
          if ("status" in err.response && err.response.status === 401) {
            error_messages = ["Not authorised."];
          } else {
            error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
          }
          this.setState({
            updating_sale: false,
            error_messages: error_messages,
          });
        } else if (err.request) {
          const error_messages = [
            "There was a problem processing the information. Please reload this page and try again.",
          ];
          this.setState({
            updating_sale: false,
            error_messages: error_messages,
          });
        } else {
          this.setState({
            updating_sale: true,
          });
        }

        return null;
      });
  }

  async saveSettingEdit() {
    let activity = this.setting.current.state.activity;
    let cost = this.setting.current.state.cost
      ? this.setting.current.state.cost
      : null;
    let settingActivityCustomerUpdated =
      this.setting.current.state.settingActivityCustomerUpdated;
    let invoiceNumber = this.setting.current.state.invoiceNumber;
    let invoiceTo = this.setting.current.state.invoiceTo;
    let readyToPayToProvider = this.setting.current.state.readyToPayToProvider;
    let metalYellowWeight = this.setting.current.state.metalYellowWeight;
    let metalWhiteWeight = this.setting.current.state.metalWhiteWeight;
    let metalRoseWeight = this.setting.current.state.metalRoseWeight;
    let metalPlatinumWeight = this.setting.current.state.metalPlatinumWeight;
    let metalCost = this.setting.current.state.metalCost
      ? this.setting.current.state.metalCost
      : null;
    let metalSupplierName = this.setting.current.state.metalSupplierName;
    let metalInvoiceNumber = this.setting.current.state.metalInvoiceNumber;
    let metalCostReady = this.setting.current.state.metalCostReady;
    let metalInvoiceTo = this.setting.current.state.metalInvoiceTo;
    let melesSets = this.setting.current.state.melesSets;
    let mmdiaInvoice = this.setting.current.state.mmdiaInvoice;
    let novaInvoice = this.setting.current.state.novaInvoice;
    let invoiceStatus = this.setting.current.state.invoiceStatus;

    this.setState({
      updating_sale: true,
    });

    const user = Auth.getAuthenticatedUser();
    const saleWorkflow = this.props.saleWorkflow;

    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else if (saleWorkflow === null || saleWorkflow === undefined) {
      this.setState({
        error_messages: [
          "Sale identification error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_EDIT;
      url = url.replace("{id}", activity.id);

      const params = new URLSearchParams();
      params.append("cost", cost);
      params.append("invoiceNumber", invoiceNumber);
      params.append("invoiceTo", invoiceTo);
      params.append(
        "settingActivityCustomerUpdated",
        settingActivityCustomerUpdated
      );
      params.append("readyToPayToProvider", readyToPayToProvider);
      params.append("metalYellowWeight", metalYellowWeight);
      params.append("metalWhiteWeight", metalWhiteWeight);
      params.append("metalRoseWeight", metalRoseWeight);
      params.append("metalPlatinumWeight", metalPlatinumWeight);
      params.append("metalCost", metalCost);
      params.append("metalSupplierName", metalSupplierName);
      params.append("metalInvoiceNumber", metalInvoiceNumber);
      params.append("metalCostReady", metalCostReady);
      params.append("metalInvoiceTo", metalInvoiceTo);
      params.append("melesSet", JSON.stringify(melesSets));
      params.append("mmdiaInvoice", mmdiaInvoice);
      params.append("novaInvoice", novaInvoice);
      params.append("invoiceStatus", invoiceStatus);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      await axios
        .put(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              saleWorkflowSettingShow: false,
              updating_sale: false,
              success_messages: ["Activity updated"],
            });
            this.setState({
              saleWorkflowSetting: data.result,
              saleWorkflowSettingShow: true,
            });

            this.setting.current.updateMelees(data.result.workflowMelesModels);
          } else {
            this.setState({
              updating_sale: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else {
            this.setState({
              updating_sale: true,
            });
          }
          return null;
        });
    }
  }

  async saveEngravingEdit() {
    let activity = this.engraving.current.state.activity;
    let cost = this.engraving.current.state.cost
      ? this.engraving.current.state.cost
      : null;
    let invoiceNumber = this.engraving.current.state.invoiceNumber;
    let invoiceTo = this.engraving.current.state.invoiceTo;
    let readyToPayToProvider =
      this.engraving.current.state.readyToPayToProvider;

    this.setState({
      updating_sale: true,
    });

    const user = Auth.getAuthenticatedUser();
    const saleWorkflow = this.props.saleWorkflow;

    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else if (saleWorkflow === null || saleWorkflow === undefined) {
      this.setState({
        error_messages: [
          "Sale identification error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_EDIT;
      url = url.replace("{id}", activity.id);

      const params = new URLSearchParams();
      params.append("cost", cost);
      params.append("invoiceNumber", invoiceNumber);
      params.append("invoiceTo", invoiceTo);
      params.append("readyToPayToProvider", readyToPayToProvider);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      await axios
        .put(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              updating_sale: false,
              success_messages: ["Activity updated"],
            });
          } else {
            this.setState({
              updating_sale: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else {
            this.setState({
              updating_sale: true,
            });
          }
          return null;
        });
    }
  }

  async saveDeliveringToCustomerEdit() {
    let activity = this.deliveringToCustomer.current.state.activity;
    let readyToPayToProvider =
      this.deliveringToCustomer.current.state.readyToPayToProvider;
    let inTransitToShowroom =
      this.deliveringToCustomer.current.state.inTransitToShowroom;
    let inTransitDestinationShowroom =
      this.deliveringToCustomer.current.state.inTransitDestinationShowroom;
    let inTransitDateSent =
      this.deliveringToCustomer.current.state.inTransitDateSent;
    let inTransitCustomerUpdated =
      this.deliveringToCustomer.current.state.customerWasUpdated;
    let inTransitDateReceived =
      this.deliveringToCustomer.current.state.inTransitDateReceived;
    let deliveringToCustomer =
      this.deliveringToCustomer.current.state
        .customerContactedToOrganiseDelivery;
    let deliveringDateOfContact =
      this.deliveringToCustomer.current.state.deliveringDateOfContact;
    let deliveringCommentsAboutCall =
      this.deliveringToCustomer.current.state.deliveringCommentsAboutCall;
    let deliveringPickedUpInShowroom =
      this.deliveringToCustomer.current.state.pickedUpInShowroom;
    let deliveringPickedUpInShowroomDate =
      this.deliveringToCustomer.current.state.deliveringPickedUpInShowroomDate;
    let deliveredToCustomer =
      this.deliveringToCustomer.current.state.deliveredToCustomer;
    let deliveredToCustomerDate =
      this.deliveringToCustomer.current.state.deliveredToCustomerDate;
    let deliveredToCustomerTrackingNumber =
      this.deliveringToCustomer.current.state.deliveredToCustomerTrackingNumber;

    this.setState({
      updating_sale: true,
    });

    const user = Auth.getAuthenticatedUser();
    const saleWorkflow = this.props.saleWorkflow;

    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else if (saleWorkflow === null || saleWorkflow === undefined) {
      this.setState({
        error_messages: [
          "Sale identification error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_EDIT;
      url = url.replace("{id}", activity.id);

      const params = new URLSearchParams();
      params.append("readyToPayToProvider", readyToPayToProvider);
      params.append("inTransitToShowroom", inTransitToShowroom);
      params.append(
        "inTransitDestinationShowroom",
        inTransitDestinationShowroom
      );
      params.append("inTransitDateSent", inTransitDateSent);
      params.append("inTransitCustomerUpdated", inTransitCustomerUpdated);
      params.append("inTransitDateReceived", inTransitDateReceived);
      params.append("deliveringToCustomer", deliveringToCustomer);
      params.append("deliveringDateOfContact", deliveringDateOfContact);
      params.append("deliveringCommentsAboutCall", deliveringCommentsAboutCall);
      params.append(
        "deliveringPickedUpInShowroom",
        deliveringPickedUpInShowroom
      );
      params.append(
        "deliveringPickedUpInShowroomDate",
        deliveringPickedUpInShowroomDate
      );
      params.append("deliveredToCustomer", deliveredToCustomer);
      params.append("deliveredToCustomerDate", deliveredToCustomerDate);
      params.append(
        "deliveredToCustomerTrackingNumber",
        deliveredToCustomerTrackingNumber
      );

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      await axios
        .put(url, params, config)
        .then((response) => {
          const data = response.data;

          this.props.parentResetInformation(data.resetInformation);

          if (data !== undefined && data.code === 200) {
            this.setState({
              updating_sale: false,
              success_messages: ["Activity updated"],
            });
          } else {
            this.setState({
              updating_sale: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else {
            this.setState({
              updating_sale: true,
            });
          }
          return null;
        });
    }
  }

  async saveCADEdit() {
    let activity = this.CAD.current.state.activity;
    let cost = this.CAD.current.state.cost ? this.CAD.current.state.cost : null;
    let invoiceNumber = this.CAD.current.state.invoiceNumber;
    let invoiceTo = this.CAD.current.state.invoiceTo;
    let readyToPayToProvider = this.CAD.current.state.readyToPayToProvider;

    this.setState({
      updating_sale: true,
    });

    const user = Auth.getAuthenticatedUser();
    const saleWorkflow = this.props.saleWorkflow;

    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else if (saleWorkflow === null || saleWorkflow === undefined) {
      this.setState({
        error_messages: [
          "Sale identification error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_EDIT;
      url = url.replace("{id}", activity.id);

      const params = new URLSearchParams();
      params.append("cost", cost);
      params.append("invoiceNumber", invoiceNumber);
      params.append("invoiceTo", invoiceTo);
      params.append("readyToPayToProvider", readyToPayToProvider);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      await axios
        .put(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              updating_sale: false,
              success_messages: ["Activity updated"],
            });
          } else {
            this.setState({
              updating_sale: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else {
            this.setState({
              updating_sale: true,
            });
          }
          return null;
        });
    }
  }

  async saveFixingEdit() {
    let activity = this.fixing.current.state.activity;
    let cost = this.fixing.current.state.cost
      ? this.fixing.current.state.cost
      : null;
    let invoiceNumber = this.fixing.current.state.invoiceNumber;
    let invoiceTo = this.fixing.current.state.invoiceTo;
    let readyToPayToProvider = this.fixing.current.state.readyToPayToProvider;
    let metalYellowWeight = this.fixing.current.state.metalYellowWeight;
    let metalWhiteWeight = this.fixing.current.state.metalWhiteWeight;
    let metalRoseWeight = this.fixing.current.state.metalRoseWeight;
    let metalPlatinumWeight = this.fixing.current.state.metalPlatinumWeight;
    let metalCost = this.fixing.current.state.metalCost
      ? this.fixing.current.state.metalCost
      : null;
    let metalSupplierName = this.fixing.current.state.metalSupplierName;
    let metalInvoiceNumber = this.fixing.current.state.metalInvoiceNumber;
    let metalInvoiceTo = this.fixing.current.state.metalInvoiceTo;
    let metalCostReady = this.fixing.current.state.metalCostReady;
    let melesSets = this.fixing.current.state.melesSets;
    let mmdiaInvoice = this.fixing.current.state.mmdiaInvoice;
    let novaInvoice = this.fixing.current.state.novaInvoice;
    let invoiceStatus = this.fixing.current.state.invoiceStatus;

    this.setState({
      updating_sale: true,
    });

    const user = Auth.getAuthenticatedUser();
    const saleWorkflow = this.props.saleWorkflow;

    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else if (saleWorkflow === null || saleWorkflow === undefined) {
      this.setState({
        error_messages: [
          "Sale identification error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_EDIT;
      url = url.replace("{id}", activity.id);

      const params = new URLSearchParams();
      params.append("cost", cost);
      params.append("invoiceNumber", invoiceNumber);
      params.append("invoiceTo", invoiceTo);
      params.append("readyToPayToProvider", readyToPayToProvider);
      params.append("metalYellowWeight", metalYellowWeight);
      params.append("metalWhiteWeight", metalWhiteWeight);
      params.append("metalRoseWeight", metalRoseWeight);
      params.append("metalPlatinumWeight", metalPlatinumWeight);
      params.append("metalCost", metalCost);
      params.append("metalSupplierName", metalSupplierName);
      params.append("metalInvoiceNumber", metalInvoiceNumber);
      params.append("metalInvoiceTo", metalInvoiceTo);
      params.append("metalCostReady", metalCostReady);
      params.append("melesSet", JSON.stringify(melesSets));
      params.append("mmdiaInvoice", mmdiaInvoice);
      params.append("novaInvoice", novaInvoice);
      params.append("invoiceStatus", invoiceStatus);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      await axios
        .put(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              saleWorkflowFixingShow: false,
              updating_sale: false,
              success_messages: ["Activity updated"],
            });

            this.setState({
              saleWorkflowFixing: data.result,
              saleWorkflowFixingShow: true,
            });

            this.fixing.current.updateMelees(data.result.workflowMelesModels);
          } else {
            this.setState({
              updating_sale: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else {
            this.setState({
              updating_sale: true,
            });
          }
          return null;
        });
    }
  }

  async savePhotosEdit() {
    let activity = this.photos.current.state.activity;

    let datePhotosRequested = this.photos.current.state.datePhotosRequested;
    let photosCompleted = this.photos.current.state.photosCompleted;

    this.setState({
      updating_sale: true,
    });

    const user = Auth.getAuthenticatedUser();
    const saleWorkflow = this.props.saleWorkflow;

    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else if (saleWorkflow === null || saleWorkflow === undefined) {
      this.setState({
        error_messages: [
          "Sale identification error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_EDIT;
      url = url.replace("{id}", activity.id);

      const params = new URLSearchParams();
      params.append("datePhotosRequested", datePhotosRequested);
      params.append("photosCompleted", photosCompleted);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      await axios
        .put(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              updating_sale: false,
              success_messages: ["Activity updated"],
            });
          } else {
            this.setState({
              updating_sale: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else {
            this.setState({
              updating_sale: true,
            });
          }
          return null;
        });
    }
  }

  async savePolishingEdit() {
    let activity = this.polishing.current.state.activity;
    let cost = this.polishing.current.state.cost
      ? this.polishing.current.state.cost
      : null;
    let invoiceNumber = this.polishing.current.state.invoiceNumber;
    let invoiceTo = this.polishing.current.state.invoiceTo;
    let readyToPayToProvider =
      this.polishing.current.state.readyToPayToProvider;
    let metalYellowWeight = this.polishing.current.state.metalYellowWeight;
    let metalWhiteWeight = this.polishing.current.state.metalWhiteWeight;
    let metalRoseWeight = this.polishing.current.state.metalRoseWeight;
    let metalPlatinumWeight = this.polishing.current.state.metalPlatinumWeight;
    let metalCost = this.polishing.current.state.metalCost
      ? this.polishing.current.state.metalCost
      : null;
    let metalSupplierName = this.polishing.current.state.metalSupplierName;
    let metalInvoiceNumber = this.polishing.current.state.metalInvoiceNumber;
    let metalCostReady = this.polishing.current.state.metalCostReady;
    let metalInvoiceTo = this.polishing.current.state.metalInvoiceTo;
    let melesSets = this.polishing.current.state.melesSets;
    let mmdiaInvoice = this.polishing.current.state.mmdiaInvoice;
    let novaInvoice = this.polishing.current.state.novaInvoice;
    let invoiceStatus = this.polishing.current.state.invoiceStatus;

    this.setState({
      updating_sale: true,
    });

    const user = Auth.getAuthenticatedUser();
    const saleWorkflow = this.props.saleWorkflow;

    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else if (saleWorkflow === null || saleWorkflow === undefined) {
      this.setState({
        error_messages: [
          "Sale identification error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_EDIT;
      url = url.replace("{id}", activity.id);

      const params = new URLSearchParams();
      params.append("cost", cost);
      params.append("invoiceNumber", invoiceNumber);
      params.append("invoiceTo", invoiceTo);
      params.append("readyToPayToProvider", readyToPayToProvider);
      params.append("metalYellowWeight", metalYellowWeight);
      params.append("metalWhiteWeight", metalWhiteWeight);
      params.append("metalRoseWeight", metalRoseWeight);
      params.append("metalPlatinumWeight", metalPlatinumWeight);
      params.append("metalCost", metalCost);
      params.append("metalSupplierName", metalSupplierName);
      params.append("metalInvoiceNumber", metalInvoiceNumber);
      params.append("metalInvoiceTo", metalInvoiceTo);
      params.append("metalCostReady", metalCostReady);
      params.append("melesSet", JSON.stringify(melesSets));
      params.append("mmdiaInvoice", mmdiaInvoice);
      params.append("novaInvoice", novaInvoice);
      params.append("invoiceStatus", invoiceStatus);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      await axios
        .put(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              saleWorkflowPolishingShow: false,
              updating_sale: false,
              success_messages: ["Activity updated"],
            });

            this.setState({
              saleWorkflowPolishing: data.result,
              saleWorkflowPolishingShow: true,
            });

            this.polishing.current.updateMelees(
              data.result.workflowMelesModels
            );
          } else {
            this.setState({
              updating_sale: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else {
            this.setState({
              updating_sale: true,
            });
          }
          return null;
        });
    }
  }

  async saveValuationEdit() {
    let activity = this.valuation.current.state.activity;
    let cost = this.valuation.current.state.cost
      ? this.valuation.current.state.cost
      : null;
    let invoiceNumber = this.valuation.current.state.invoiceNumber;
    let invoiceTo = this.valuation.current.state.invoiceTo;
    let readyToPayToProvider =
      this.valuation.current.state.readyToPayToProvider;

    this.setState({
      updating_sale: true,
    });

    const user = Auth.getAuthenticatedUser();
    const saleWorkflow = this.props.saleWorkflow;

    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else if (saleWorkflow === null || saleWorkflow === undefined) {
      this.setState({
        error_messages: [
          "Sale identification error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_EDIT;
      url = url.replace("{id}", activity.id);

      const params = new URLSearchParams();
      params.append("cost", cost);
      params.append("invoiceNumber", invoiceNumber);
      params.append("invoiceTo", invoiceTo);
      params.append("readyToPayToProvider", readyToPayToProvider);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      await axios
        .put(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              updating_sale: false,
              success_messages: ["Activity updated"],
            });
          } else {
            this.setState({
              updating_sale: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else {
            this.setState({
              updating_sale: true,
            });
          }
          return null;
        });
    }
  }

  saveReturn = async () => {
    const { workflowReturnModel } = this.activity;

    this.setState({ updating_sale: true });

    const data = await SendUpdateWorkflowReturn(
      this.activity,
      workflowReturnModel
    );

    this.setState({ updating_sale: false });

    if (data !== undefined && data.code === 200) {
      this.setState({
        showReturnForm: false,
      });

      this.props.parentResetInformation(data.resetInformation);

      const { result = {} } = data;
      result.workflowReturnModel = workflowReturnPrepareData(
        result.workflowReturnModel
      );

      const saleWorkflow = this.state.saleWorkflow;

      const activities = saleWorkflow.workflowActivityModels.map(
        (workflowActivity) => {
          if (workflowActivity.id === result.id) {
            workflowActivity.workflowReturnModel = result.workflowReturnModel;
          }

          return workflowActivity;
        }
      );

      if (activities.length > 0) {
        saleWorkflow.workflowActivityModels = activities;
        this.setState({
          saleWorkflow: saleWorkflow,
        });
      }

      setTimeout(() => {
        this.setState({
          showReturnForm: true,
          updating_sale: false,
          success_messages: ["Activity updated"],
        });
      }, 50);
    }

    if (data !== undefined && data.error) {
      this.setState({
        showReturnForm: true,
        updating_sale: false,
        error_messages: data["error"],
      });
    }
  };

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  static getDerivedStateFromProps(props, state) {
    let edited_sale = state.edited_sale;
    if (edited_sale !== null && edited_sale.id !== props.sale.id) {
      edited_sale = null;
    }
    return {
      edited_sale: edited_sale,
    };
  }

  getJewellers = async () => {
    const isManufacturing = isManufacturingActivity(this.state.saleWorkflow);
    const jewellers = await FindJewelleyByContactName();

    let provider = findDefauldProvider(
      jewellers?.items ?? [],
      this.state.providerId
    );

    if (provider.length <= 0 && isManufacturing) {
      provider = findDefauldProvider(
        jewellers?.items ?? [],
        Util.getStorageParameter("REACT_APP_DEFAULT_MANUFACTURING_PROVIDER"),
        "name"
      );
    }

    this.setState({
      providers: jewellers?.items ?? [],
      provider: this.state.providerId ? provider : [],
      showSelectProvider: true,
    });
  };

  componentDidMount() {
    if (this.state.status === CANCELLED) {
      this.setState({
        showStatusComment: true,
      });
    }

    if (this.props.showPanelInformation) {
      this.props.disabledActionsBotons(BTNS_DISABLES);
    }

    this.getJewellers();
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default SaleWorkflowEdit;
