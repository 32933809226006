import { Link } from "react-router-dom";
import BackIcon from "../../../image/backArrow.png";

export default function ReportsHeader() {
  return (
    <div className="row bg-dark text-white">
        <div className="col-2 d-flex align-items-center pl-0">
            <Link to="/dashboard">
                <img height="50px" src={BackIcon} alt="Back" />
            </Link>
        </div>
        <div className="col-10 text-center d-flex align-items-center justify-content-center">
            <span className="display-6 font-weight-bold">Reports</span>
        </div>
    </div>
  );
}
