import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios";
import {
  API_STATUS_FAILED,
  API_STATUS_PENDING,
  API_STATUS_SUCCESS,
} from "../constants";

const initialState = {
  error: null,
  status: "",
  statusEdit: "",
  createQualityControl: null,
  dataQuestions: null,
  activeChange: false,
  dataQualityControlCheck: null,
  reloadSale: false,
  dataQualityControlCheckLogs: [],
  dataBtnDisabled: [],
  changeBtns: false,
};

export const fetchQualityControlCheck = createAsyncThunk(
  "/workflow-activity/quality-control-check",
  async (args, thunkAPI) => {
    try {
      const { activityId } = args;
      const response = await axios.post(
        `/api/v1/workflow-activity/${activityId}/quality-control-check`
      );
      const { data = {} } = response;
      const { value = {} } = data;

      if (value) {
        return value;
      }

      return null;
    } catch (err) {
      const { response = {} } = err;
      const { data = {} } = response;
      const { error = null } = data;

      return thunkAPI.rejectWithValue(error ?? err.message);
    }
  }
);

export const fetchUpdateQualityControl = createAsyncThunk(
  "/workflow-activity/quality-control/update",
  async (args, thunkAPI) => {
    try {
      const { id, questions, approved, completedById, completedOn } = args;

      let url = `/api/v1/workflow-activity/quality-control-check/${id}`;
      let completedByIdData = null;
      let completedOnData = null;
      let approvedData = null;

      if (approved === true || approved === false) {
        completedByIdData = completedById;
        completedOnData = completedOn;
        approvedData = approved;
      }

      const response = await axios.put(url, {
        questions,
        approved: approvedData,
        completedById: completedByIdData,
        completedOn: completedOnData,
      });

      const { data = {} } = response;
      const { value = {} } = data;

      if (value) {
        return value;
      }

      return null;
    } catch (err) {
      const { response = {} } = err;
      const { data = {} } = response;
      const { error = null } = data;

      return thunkAPI.rejectWithValue(error ?? err.message);
    }
  }
);

export const fetchQualityControlLogs = createAsyncThunk(
  "/quality-control-check/saleId/logs",
  async (args, thunkAPI) => {
    try {
      const { id } = args;

      let url = `/api/v1/workflow-activity/quality-control-check/sale/${id}/logs`;

      const response = await axios.get(url);

      const { data = {} } = response;
      const { value = [] } = data;

      if (value) {
        return value;
      }

      return [];
    } catch (err) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const qualityControlCheckSlice = createSlice({
  name: "customerLogs",
  initialState,
  reducers: {
    clearQualityControlCheck(state) {
      state.createQualityControl = null;
      state.dataQualityControlCheck = null;
    },
    clearEdit(state) {
      state.dataQuestions = null;
    },
    clearStatus(state) {
      if (state.status === API_STATUS_SUCCESS) {
        state.status = "";
        state.statusEdit = "";
      }
    },
    updateQuestions(state, action) {
      state.dataQuestions = action.payload;
    },
    setActiveChange(state, action) {
      state.activeChange = action.payload;
    },
    setReloadSale(state, action) {
      state.reloadSale = action.payload;
    },
    clearAllData(state) {
      state.error = "";
      state.status = "";
      state.statusEdit = "";
      state.reloadSale = false;
      state.createQualityControl = null;
      state.dataQualityControlCheck = null;
    },
    setBtnDisabled(state, action) {
      state.dataBtnDisabled = action.payload;
    },
    setChangeBtns(state, action) {
      state.changeBtns = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchQualityControlCheck.fulfilled, (state, action) => ({
      error: null,
      status: API_STATUS_SUCCESS,
      createQualityControl: action.payload,
    }));
    builder.addCase(fetchQualityControlCheck.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(fetchQualityControlCheck.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    builder.addCase(fetchUpdateQualityControl.fulfilled, (state, action) => ({
      error: null,
      statusEdit: API_STATUS_SUCCESS,
      dataQualityControlCheck: action.payload,
    }));
    builder.addCase(fetchUpdateQualityControl.rejected, (state, action) => {
      state.error = action.payload;
      state.statusEdit = API_STATUS_FAILED;
    });
    builder.addCase(fetchUpdateQualityControl.pending, (state, action) => {
      state.statusEdit = API_STATUS_PENDING;
    });
    builder.addCase(fetchQualityControlLogs.fulfilled, (state, action) => ({
      error: null,
      status: API_STATUS_SUCCESS,
      dataQualityControlCheckLogs: action.payload,
    }));
    builder.addCase(fetchQualityControlLogs.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(fetchQualityControlLogs.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
  },
});

export const {
  clearQualityControlCheck,
  updateQuestions,
  clearEdit,
  setActiveChange,
  setReloadSale,
  clearAllData,
  clearStatus,
  setBtnDisabled,
  setChangeBtns,
} = qualityControlCheckSlice.actions;

export default qualityControlCheckSlice.reducer;
