import React, { Component } from 'react';
import Util from '../../../Util'

// SCSS
import '../../../css/sales/sale_information.scss'

class PostSaleInformationHistory extends Component {

    constructor(props) {
        super();
        this.sales_logs_rows = []
        this.state = {
            sale: props.sale,
            sales_logs: props.sales_logs,
            error_loading_log: props.error_loading_log,
            loading_log: props.loading_log,
            sales_logs_rows: []
        }
    }

    render() {
        const sales_logs = this.state.sales_logs
        let sales_logs_rows = []

        if (Array.isArray(sales_logs) === true && sales_logs.length > 0) {
            sales_logs_rows = this.getRowsFromSalesLogs(sales_logs)
        }

        return (
            <div className='sale_information_subcontainer'>
                <div className="container">
                    <div className="row">
                        <div className="col-sm">
                            {
                                sales_logs_rows.map(function (sales_logs_row, index) {
                                    let log_date = null
                                    if (sales_logs_row.created !== null && sales_logs_row.created !== '') {
                                        log_date = new Date(sales_logs_row.created);
                                    }

                                    return (
                                        <div className='row' key={index}>
                                            <div className="col-sm">
                                                {
                                                    log_date !== null ?
                                                        <span>
                                                            {log_date.getDate()} {log_date.toLocaleString('default', { month: 'short' })}, {log_date.getFullYear()} - {log_date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                                                        </span>
                                                        : ('')
                                                }
                                            </div>
                                            <div className="col-sm"
                                                dangerouslySetInnerHTML={{ __html: sales_logs_row.message }}>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    static getDerivedStateFromProps(props, state) {
        return {
            sales_logs: props.sales_logs,
            sale: props.sale,
            error_loading_log: props.error_loading_log,
            loading_log: props.loading_log
        }
    }

    getRowsFromSalesLogs = (sales_logs) => {
        const sales_logs_rows = []
        if (Array.isArray(sales_logs) === true) {
            sales_logs.forEach(function (sales_log) {
                let name_editor = 'An user'
                /*const userModel = sales_log.userModel
                if(userModel !== undefined && userModel.employeeModel !== undefined){
                    name_editor = userModel.employeeModel.name
                }*/
                const employeeModel = sales_log.employeeModel
                if (employeeModel !== undefined) {
                    name_editor = employeeModel.name
                }

                const old_information = Util.tryParseJSONObject(sales_log.oldInformation)
                const new_information = Util.tryParseJSONObject(sales_log.newInformation)

                if (sales_log.action === "CREATE") { //recently created log
                    let sales_logs_row_message = 'Created by ' + name_editor
                    const sales_logs_row = {
                        message: sales_logs_row_message,
                        created: sales_log.created
                    }
                    sales_logs_rows.push(sales_logs_row)
                } else if (sales_log.action === "CREATE_MEDIA") {
                    let sales_logs_row_message = name_editor + ' created multimedia element (<span class="modified_value">' + new_information.filename + '</span>)'
                    const sales_logs_row = {
                        message: sales_logs_row_message,
                        created: sales_log.created
                    }
                    sales_logs_rows.push(sales_logs_row)
                } else if (sales_log.action === "DELETE_MEDIA") {
                    let sales_logs_row_message = name_editor + ' deleted multimedia element (<span class="modified_value">' + old_information.filename + '</span>)'
                    const sales_logs_row = {
                        message: sales_logs_row_message,
                        created: sales_log.created
                    }
                    sales_logs_rows.push(sales_logs_row)
                }
                else {

                    const old_information_keys = Object.keys(old_information)
                    old_information_keys.forEach(function (old_information_key) {
                        if (old_information_key in new_information && old_information_key !== 'created' && old_information_key !== 'updated') {
                            // const camelcase_key = this.camelize(old_information_key)
                            if (old_information[old_information_key] !== new_information[old_information_key]) {

                                if (old_information_key === 'sheetType') {
                                    if (old_information[old_information_key] === 'ACTIVE') {
                                        old_information[old_information_key] = 'Current Responses'
                                    }
                                    else if (old_information[old_information_key] === 'FINALISED') {
                                        old_information[old_information_key] = 'Finished Jobs'
                                    }

                                    if (new_information[old_information_key] === 'ACTIVE') {
                                        new_information[old_information_key] = 'Current Responses'
                                    }
                                    else if (new_information[old_information_key] === 'FINALISED') {
                                        new_information[old_information_key] = 'Finished Jobs'
                                    }
                                }

                                const date_fields = [
                                    "customerNeedsOn",
                                    "dateWeNeedBy",
                                    "dueDate",
                                    "created",
                                    "updated",
                                    "datePickupRequested",
                                    "dateReceivedInBackOffice",
                                    "datePickedUpFromJeweller",
                                    "shippingDate"
                                ]
                                if (old_information_key !== "jewellers") {
                                    if (date_fields.indexOf(old_information_key) > -1) {
                                        if (!isNaN(Date.parse(old_information[old_information_key]))) {
                                            old_information[old_information_key] = new Date(old_information[old_information_key])
                                            old_information[old_information_key] = old_information[old_information_key].toLocaleString('default', { day: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") }) + " " + old_information[old_information_key].toLocaleString('default', { month: 'short', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") }) + ", " + old_information[old_information_key].toLocaleString('default', { year: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") })
                                        }
                                        if (!isNaN(Date.parse(new_information[old_information_key]))) {
                                            new_information[old_information_key] = new Date(new_information[old_information_key])
                                            new_information[old_information_key] = new_information[old_information_key].toLocaleString('default', { day: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") }) + " " + new_information[old_information_key].toLocaleString('default', { month: 'short', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") }) + ", " + new_information[old_information_key].toLocaleString('default', { year: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") })
                                        }
                                    }
                                }

                                if (old_information_key === "jewellers") {
                                    let oldJewellers = []
                                    if (typeof old_information[old_information_key] !== "undefined" && old_information[old_information_key] !== null) {
                                        if (old_information[old_information_key] !== "") {
                                            try {
                                                oldJewellers = JSON.parse(old_information[old_information_key])
                                            }
                                            catch (e) {
                                                console.log("Error: " + e)
                                            }
                                        }
                                    }

                                    let newJewellers = []
                                    if (typeof new_information[old_information_key] !== "undefined" && new_information[old_information_key] !== null) {
                                        if (new_information[old_information_key] !== "") {
                                            try {
                                                newJewellers = JSON.parse(new_information[old_information_key])
                                            }
                                            catch (e) {
                                                console.log("Error: " + e)
                                            }
                                        }
                                    }

                                    let modifiedJewellersNewRow = []
                                    let modifiedJewellersOldRow = []
                                    let addedJewellersRow = []
                                    let removedJewellersRow = []
                                    if (oldJewellers.length <= newJewellers.length) {
                                        for (let i = 0; i < oldJewellers.length; i++) {
                                            let oldJeweller = oldJewellers[i]
                                            let newJeweller = newJewellers[i]
                                            let newDiffs = Object.keys(newJeweller).reduce((diff, key) => {
                                                if (oldJeweller[key] === newJeweller[key]) return diff
                                                return {
                                                    ...diff,
                                                    [key]: newJeweller[key]
                                                }
                                            }, {})
                                            let oldDiffs = Object.keys(newJeweller).reduce((diff, key) => {
                                                if (oldJeweller[key] === newJeweller[key]) return diff
                                                return {
                                                    ...diff,
                                                    [key]: oldJeweller[key]
                                                }
                                            }, {})
                                            modifiedJewellersNewRow.push(newDiffs)
                                            modifiedJewellersOldRow.push(oldDiffs)
                                        }
                                        if (oldJewellers.length < newJewellers.length) {
                                            for (let i = oldJewellers.length; i < newJewellers.length; i++) {
                                                addedJewellersRow.push(newJewellers[i])
                                            }
                                        }
                                    }
                                    else {
                                        for (let i = 0; i < newJewellers.length; i++) {
                                            let oldJeweller = oldJewellers[i]
                                            let newJeweller = newJewellers[i]
                                            let newDiffs = Object.keys(newJeweller).reduce((diff, key) => {
                                                if (oldJeweller[key] === newJeweller[key]) return diff
                                                return {
                                                    ...diff,
                                                    [key]: newJeweller[key]
                                                }
                                            }, {})
                                            let oldDiffs = Object.keys(newJeweller).reduce((diff, key) => {
                                                if (oldJeweller[key] === newJeweller[key]) return diff
                                                return {
                                                    ...diff,
                                                    [key]: oldJeweller[key]
                                                }
                                            }, {})
                                            modifiedJewellersNewRow.push(newDiffs)
                                            modifiedJewellersOldRow.push(oldDiffs)
                                        }
                                        for (let i = newJewellers.length; i < oldJewellers.length; i++) {
                                            removedJewellersRow.push(oldJewellers[i])
                                        }
                                    }

                                    modifiedJewellersNewRow.map(function (row, index) {
                                        if (Object.keys(row).length > 0) {
                                            for (let key in row) {
                                                let oldValue = ""
                                                let newValue = ""
                                                if (index in modifiedJewellersOldRow) {
                                                    if (key in modifiedJewellersOldRow[index]) {
                                                        oldValue = modifiedJewellersOldRow[index][key]
                                                    }
                                                }
                                                if (key in row) {
                                                    newValue = row[key]
                                                }

                                                if (date_fields.indexOf(key) > -1) {
                                                    if (!isNaN(Date.parse(oldValue))) {
                                                        oldValue = new Date(oldValue)
                                                        oldValue = oldValue.toLocaleString('default', { day: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") }) + " " + oldValue.toLocaleString('default', { month: 'short', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") }) + ", " + oldValue.toLocaleString('default', { year: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") })
                                                    }
                                                    if (!isNaN(Date.parse(newValue))) {
                                                        newValue = new Date(newValue)
                                                        newValue = newValue.toLocaleString('default', { day: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") }) + " " + newValue.toLocaleString('default', { month: 'short', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") }) + ", " + newValue.toLocaleString('default', { year: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") })
                                                    }
                                                }

                                                let sales_logs_row_message = name_editor + ' changed ' + key + ' from <span class="modified_value">' + oldValue + '</span> to <span class="modified_value">' + newValue + '</span>'
                                                const sales_logs_row = {
                                                    message: sales_logs_row_message,
                                                    created: sales_log.created
                                                }
                                                sales_logs_rows.push(sales_logs_row)
                                            }
                                        }
                                    })

                                    addedJewellersRow.map(function (row) {
                                        let jeweller
                                        let dateGivenToJeweller
                                        let datePickedUpFromJeweller
                                        let jewellersInvoice
                                        let cost
                                        let jobPackage
                                        let notes
                                        if (row["jeweller"] !== "") {
                                            jeweller = 'the jeweller <span class="modified_value">' + row["jeweller"] + '</span>'
                                        }
                                        else {
                                            jeweller = 'a jeweller with no selected name'
                                        }
                                        if (row["dateGivenToJeweller"] !== "") {
                                            let dateGivenToJeweller_val
                                            if (typeof row["dateGivenToJeweller"] !== "undefined" && row["dateGivenToJeweller"] !== null) {
                                                const dateGivenToJeweller_string = row["dateGivenToJeweller"].substring(0, 10)
                                                dateGivenToJeweller_val = new Date(dateGivenToJeweller_string)
                                                dateGivenToJeweller_val = dateGivenToJeweller_val.toLocaleString('default', { day: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") }) + " " + dateGivenToJeweller_val.toLocaleString('default', { month: 'short', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") }) + ", " + dateGivenToJeweller_val.toLocaleString('default', { year: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") })
                                                if (dateGivenToJeweller_val === 'Invalid Date') {
                                                    dateGivenToJeweller_val = ''
                                                }
                                            }

                                            if (dateGivenToJeweller_val !== "" && typeof dateGivenToJeweller_val !== "undefined") {
                                                dateGivenToJeweller = 'with a date given to jeweller of <span class="modified_value">' + dateGivenToJeweller_val + '</span>'
                                            }
                                            else {
                                                dateGivenToJeweller = 'with no date given to jeweller'
                                            }
                                        }
                                        else {
                                            dateGivenToJeweller = 'with no date given to jeweller'
                                        }
                                        if (row["datePickedUpFromJeweller"] !== "") {
                                            let datePickedUpFromJeweller_val
                                            if (typeof row["datePickedUpFromJeweller"] !== "undefined" && row["datePickedUpFromJeweller"] !== null) {
                                                const datePickedUpFromJeweller_string = row["datePickedUpFromJeweller"].substring(0, 10)
                                                datePickedUpFromJeweller_val = new Date(datePickedUpFromJeweller_string)
                                                datePickedUpFromJeweller_val = datePickedUpFromJeweller_val.toLocaleString('default', { day: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") }) + " " + datePickedUpFromJeweller_val.toLocaleString('default', { month: 'short', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") }) + ", " + datePickedUpFromJeweller_val.toLocaleString('default', { year: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") })
                                                if (datePickedUpFromJeweller_val === 'Invalid Date') {
                                                    datePickedUpFromJeweller_val = ''
                                                }
                                            }

                                            if (datePickedUpFromJeweller_val !== "" && typeof datePickedUpFromJeweller_val !== "undefined") {
                                                datePickedUpFromJeweller = 'with a date picked up from jeweller of <span class="modified_value">' + datePickedUpFromJeweller_val + '</span>'
                                            }
                                            else {
                                                datePickedUpFromJeweller = 'with no date picked up from jeweller'
                                            }
                                        }
                                        else {
                                            datePickedUpFromJeweller = 'with no date picked up from jeweller'
                                        }

                                        if (row["jewellersInvoice"] !== "") {
                                            jewellersInvoice = 'a Jeweller\'s invoice of <span class="modified_value">' + row["jewellersInvoice"] + '</span>'
                                        }
                                        else {
                                            jewellersInvoice = 'no Jeweller\'s invoice'
                                        }
                                        if (row["cost"] !== "") {
                                            cost = 'a Jeweller\'s cost of <span class="modified_value">' + row["cost"] + '</span>'
                                        }
                                        else {
                                            cost = 'no Jeweller\'s cost'
                                        }
                                        if (row["jobPackage"] !== "") {
                                            jobPackage = 'a Job package of <span class="modified_value">' + row["jobPackage"] + '</span>'
                                        }
                                        else {
                                            jobPackage = 'no Job package'
                                        }
                                        if (row["notes"] !== "") {
                                            notes = 'and Notes of <span class="modified_value">' + row["notes"] + '</span>'
                                        }
                                        else {
                                            notes = 'and no Notes'
                                        }
                                        let sales_logs_row_message = name_editor + ' added ' + jeweller + ', ' + dateGivenToJeweller + ', ' + datePickedUpFromJeweller + ', ' + jewellersInvoice + ', ' + jobPackage + ' ,' + cost + ', ' + notes
                                        const sales_logs_row = {
                                            message: sales_logs_row_message,
                                            created: sales_log.created
                                        }
                                        sales_logs_rows.push(sales_logs_row)
                                    })

                                    removedJewellersRow.map(function (row) {
                                        let jeweller
                                        let dateGivenToJeweller
                                        let datePickedUpFromJeweller
                                        let jewellersInvoice
                                        let cost
                                        let jobPackage
                                        let notes
                                        if (row["jeweller"] !== "") {
                                            jeweller = 'the jeweller <span class="modified_value">' + row["jeweller"] + '</span>'
                                        }
                                        else {
                                            jeweller = 'a jeweller with no selected name'
                                        }
                                        if (row["dateGivenToJeweller"] !== "") {
                                            let dateGivenToJeweller_val
                                            if (typeof row["dateGivenToJeweller"] !== "undefined" && row["dateGivenToJeweller"] !== null) {
                                                const dateGivenToJeweller_string = row["dateGivenToJeweller"].substring(0, 10)
                                                dateGivenToJeweller_val = new Date(dateGivenToJeweller_string)
                                                dateGivenToJeweller_val = dateGivenToJeweller_val.toLocaleString('default', { day: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") }) + " " + dateGivenToJeweller_val.toLocaleString('default', { month: 'short', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") }) + ", " + dateGivenToJeweller_val.toLocaleString('default', { year: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") })
                                                if (dateGivenToJeweller_val === 'Invalid Date') {
                                                    dateGivenToJeweller_val = ''
                                                }
                                            }

                                            if (dateGivenToJeweller_val !== "" && typeof dateGivenToJeweller_val !== "undefined") {
                                                dateGivenToJeweller = 'with a date given to jeweller of <span class="modified_value">' + dateGivenToJeweller_val + '</span>'
                                            }
                                            else {
                                                dateGivenToJeweller = 'with no date given to jeweller'
                                            }
                                        }
                                        else {
                                            dateGivenToJeweller = 'with no date given to jeweller'
                                        }
                                        if (row["datePickedUpFromJeweller"] !== "") {
                                            let datePickedUpFromJeweller_val
                                            if (typeof row["datePickedUpFromJeweller"] !== "undefined" && row["datePickedUpFromJeweller"] !== null) {
                                                const datePickedUpFromJeweller_string = row["datePickedUpFromJeweller"].substring(0, 10)
                                                datePickedUpFromJeweller_val = new Date(datePickedUpFromJeweller_string)
                                                datePickedUpFromJeweller_val = datePickedUpFromJeweller_val.toLocaleString('default', { day: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") }) + " " + datePickedUpFromJeweller_val.toLocaleString('default', { month: 'short', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") }) + ", " + datePickedUpFromJeweller_val.toLocaleString('default', { year: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") })
                                                if (datePickedUpFromJeweller_val === 'Invalid Date') {
                                                    datePickedUpFromJeweller_val = ''
                                                }
                                            }

                                            if (datePickedUpFromJeweller_val !== "" && typeof datePickedUpFromJeweller_val !== "undefined") {
                                                datePickedUpFromJeweller = 'with a date picked up from jeweller of <span class="modified_value">' + datePickedUpFromJeweller_val + '</span>'
                                            }
                                            else {
                                                datePickedUpFromJeweller = 'with no date picked up from jeweller'
                                            }
                                        }
                                        else {
                                            datePickedUpFromJeweller = 'with no date picked up from jeweller'
                                        }
                                        if (row["jewellersInvoice"] !== "") {
                                            jewellersInvoice = 'a Jeweller\'s invoice of <span class="modified_value">' + row["jewellersInvoice"] + '</span>'
                                        }
                                        else {
                                            jewellersInvoice = 'no Jeweller\'s invoice'
                                        }
                                        if (row["cost"] !== "") {
                                            cost = 'a Jeweller\'s cost of <span class="modified_value">' + row["cost"] + '</span>'
                                        }
                                        else {
                                            cost = 'no Jeweller\'s cost'
                                        }
                                        if (row["jobPackage"] !== "") {
                                            jobPackage = 'a Job package of <span class="modified_value">' + row["jobPackage"] + '</span>'
                                        }
                                        else {
                                            jobPackage = 'no Job package'
                                        }
                                        if (row["notes"] !== "") {
                                            notes = 'and Notes of <span class="modified_value">' + row["notes"] + '</span>'
                                        }
                                        else {
                                            notes = 'and no Notes'
                                        }

                                        let sales_logs_row_message = name_editor + ' removed ' + jeweller + ', ' + dateGivenToJeweller + ', ' + datePickedUpFromJeweller + ', ' + jewellersInvoice + ', ' + jobPackage + ', ' + cost + ', ' + notes
                                        const sales_logs_row = {
                                            message: sales_logs_row_message,
                                            created: sales_log.created
                                        }
                                        sales_logs_rows.push(sales_logs_row)
                                    })
                                }
                                else {

                                    let sales_logs_row_message = '';
                                    if (Util.wordInArray(old_information_key, ['filename', 'description'])) {
                                        sales_logs_row_message = name_editor + ' changed multimedia element. ' + old_information_key + ' from <span class="modified_value">' + old_information[old_information_key] + '</span> to <span class="modified_value">' + new_information[old_information_key] + '</span>'
                                    } else {
                                        sales_logs_row_message = name_editor + ' changed ' + old_information_key + ' from <span class="modified_value">' + old_information[old_information_key] + '</span> to <span class="modified_value">' + new_information[old_information_key] + '</span>'
                                    }
                                    if (old_information[old_information_key] === undefined || old_information[old_information_key] === 'undefined' || old_information[old_information_key] === '') {
                                        sales_logs_row_message = name_editor + ' assigned the value <span class="modified_value">' + new_information[old_information_key] + '</span> to ' + old_information_key
                                    }
                                    if (new_information[old_information_key] === undefined || new_information[old_information_key] === 'undefined' || new_information[old_information_key] === '') {
                                        sales_logs_row_message = name_editor + ' deleted the value <span class="modified_value">' + old_information[old_information_key] + '</span> from ' + old_information_key
                                    }

                                    const sales_logs_row = {
                                        message: sales_logs_row_message,
                                        created: sales_log.created
                                    }
                                    sales_logs_rows.push(sales_logs_row)
                                }

                            }
                        }
                    })
                }

            })
        }
        return sales_logs_rows
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************

    updateLog(log) {
        const sales_logs = log
        let sales_logs_rows = []
        if (Array.isArray(sales_logs) === true && sales_logs.length > 0) {
            sales_logs_rows = this.getRowsFromSalesLogs(sales_logs)
        }

        this.sales_logs_rows = sales_logs_rows
        this.setState(
            {
                loading_log: false
            }
        )
    }

}

export default PostSaleInformationHistory;