import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Row } from "react-bootstrap";
import FormQuestionQualityControl from "./FormQuestionQualityControl";

const FormGroupQualityControl = ({ group, index, editForm }) => {
  const { title, questions } = group;

  return (
    <Card className="mt-1">
      <Card.Header>{title}</Card.Header>
      <Card.Body>
        <Row lg={12} xs={12}>
          <Col lg={6} xs={6} className="center-element">
            <b>Checklist</b>
          </Col>
          <Col lg={6} xs={6} className="center-element">
            <b>Comments</b>
          </Col>
        </Row>
        {questions.map((question, key) => {
          return (
            <FormQuestionQualityControl
              key={key}
              question={question}
              index={key}
              groupIndex={index}
              editForm={editForm}
            />
          );
        })}
      </Card.Body>
    </Card>
  );
};

FormGroupQualityControl.propTypes = {
  group: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  editForm: PropTypes.bool,
};

FormGroupQualityControl.defaultProps = {
  editForm: true,
};

export default FormGroupQualityControl;
