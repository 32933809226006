import React, { Component } from "react";
import Util from "../../../Util";
import ShowDataStock from "../CustomInfo/ShowDataStock";

class SaleDetailProduction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sale: props.sale,
      workflowSales: props.workflowSales ?? [],
    };
  }

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    const sale = this.state.sale;

    let customerNeedsOnDate = null;
    if (sale.customerNeedsOn !== null && sale.customerNeedsOn !== "") {
      customerNeedsOnDate = new Date(sale.customerNeedsOn).toLocaleString(
        "default",
        {
          day: "numeric",
          month: "short",
          year: "numeric",
          timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE"),
        }
      );
      customerNeedsOnDate =
        customerNeedsOnDate.slice(0, -5) + ", " + customerNeedsOnDate.slice(-4);
    }

    let urgentDateRequestedByCustomer = "No";
    if (sale.urgentDateRequestedByCustomer === true) {
      urgentDateRequestedByCustomer = "Yes";
    }

    let urgentDateRequestedByCustomerClass = "";
    if (urgentDateRequestedByCustomer === "Yes") {
      urgentDateRequestedByCustomerClass = "highlighted";
    }

    let weNeedByDate = null;
    if (sale.dateWeNeedBy !== null && sale.dateWeNeedBy !== "") {
      weNeedByDate = new Date(sale.dateWeNeedBy).toLocaleString("default", {
        day: "numeric",
        month: "short",
        year: "numeric",
        timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE"),
      });
      weNeedByDate = weNeedByDate.slice(0, -5) + ", " + weNeedByDate.slice(-4);
    }

    return (
      <div id="SaleDetailProduction_component">
        <div className="sale_data">
          <h2 className="heading">PRODUCTION</h2>
          <div className="container mb-4">
            <div className="row">
              <div className="col-sm">Order Status</div>
              <div className="col-sm">
                <div className={Util.statusAsSlug(sale.status)}></div>
                {sale.status}
              </div>
            </div>

            {Util.wordInArray(sale.saleType, [
              "engagement ring",
              "jewellery",
              "wedding",
            ]) === true ? (
              <div className="row">
                <div className="col-sm">Jeweller</div>
                <div className="col-sm">{this.showSaleJeweller()}</div>
              </div>
            ) : null}

            {this.state.workflowSales.length > 0 && (
              <ShowDataStock workflowSales={this.state.workflowSales} />
            )}

            {Util.wordInArray(sale.saleType, [
              "engagement ring",
              "jewellery",
              "wedding",
            ]) === true ? (
              <div className="row">
                <div className="col-sm">Manufacturing Location</div>
                <div className="col-sm">{sale.manufacturingLocation}</div>
              </div>
            ) : null}
            <div className="row">
              <div className="col-sm">Date customer needs by</div>
              <div
                className={
                  "col-sm dateCustomerNeedsBy " +
                  urgentDateRequestedByCustomerClass
                }
              >
                {customerNeedsOnDate !== null ? (
                  <span>{customerNeedsOnDate}</span>
                ) : (
                  "Not registered"
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm">Urgent Date - requested by Customer</div>
              <div
                className={
                  "col-sm urgentDateRequestedByCustomer " +
                  urgentDateRequestedByCustomerClass
                }
              >
                <span>{urgentDateRequestedByCustomer}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-sm">Date we need by</div>
              <div className="col-sm">
                {weNeedByDate !== null ? (
                  <span>{weNeedByDate}</span>
                ) : (
                  "Not registered"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  showSaleJeweller() {
    if (this.state.sale.workflowLatestProvider) {
      let sale = this.state.sale;
      return `${sale?.workflowLatestProvider} - (${sale?.workflowLatestProviderContactName})`;
    }

    return "";
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default SaleDetailProduction;
