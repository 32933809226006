import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, Row } from "react-bootstrap";
import FormInputCheckbox from "../../../../Form/FormInputCheckbox";
import FormInputTextarea from "../../../../Form/FormInputTextarea";
import DividerDashed from "../../../../Divider/DividerDashed";
import ConfirmCreateReturn from "./ConfirmCreateReturn";
import {
  API_STATUS_FAILED,
  STATUS_CANCELLED,
  WORKFLOW_RETURN_TYPE_EXCHANGE,
  WORKFLOW_RETURN_TYPE_REFUND,
  WORKFLOW_RETURN_TYPE_REMAKING,
} from "../../../../../store/constants";
import { ExistSaleDiamonds } from "../../../detail/Actions/GetSaleDiamonds";
import { useSelector } from "react-redux";
import AlertMessage from "../../../../Utils/AlertMessage";
import SaleWorkflowReturnTooltip from "./SaleWorkflowReturnTooltip";

const TYPES = {
  reMaking: WORKFLOW_RETURN_TYPE_REMAKING,
  refund: WORKFLOW_RETURN_TYPE_REFUND,
  exchange: WORKFLOW_RETURN_TYPE_EXCHANGE,
};

const SaleWorkflowEditReturnStepOne = ({
  stepOne,
  onResponse,
  onResponseSave,
  type,
  workflowStatus,
  disabledInputs,
  sale,
}) => {
  const existSaleDiamond = ExistSaleDiamonds(sale);

  const { status, error } = useSelector((state) => state.workflowReturn);

  stepOne.hasDiamonds = existSaleDiamond;

  const [step, setStep] = useState(stepOne);

  const [showConfirmRemaking, setShowConfirmRemaking] = useState(false);
  const [showConfirmRefund, setShowConfirmRefund] = useState(false);
  const [showConfirmExchange, setShowConfirmExchange] = useState(false);
  const [isPrevent, setIsPrevent] = useState(false);

  const handleChange = (value, name) => {
    setStep({
      ...step,
      [name]: value,
    });

    stepOne[name] = value;

    setIsPrevent(stepOne?.reason.length > 255);

    onResponse(stepOne, "stepOneQuestions");
  };

  const handleChangeCheckbox = (checked, name) => {
    setStep({
      ...step,
      [name]: checked,
    });

    stepOne[name] = checked;

    onResponse(stepOne, "stepOneQuestions");
  };

  const setReturnType = (value) => {
    if (TYPES.reMaking === value) {
      setShowConfirmRemaking(true);
    }

    if (TYPES.refund === value) {
      setShowConfirmRefund(true);
    }

    if (TYPES.exchange === value) {
      setShowConfirmExchange(true);
    }
  };

  const disabledReturnType = () => {
    if (
      type === "" &&
      step.complete &&
      step.reason &&
      step.authoriseReturnWithoutValidation &&
      step.reasonReturnWithoutValidation
    ) {
      return false;
    }

    if (!step.hasDiamonds) {
      if (
        type === "" &&
        step.complete &&
        step.reason &&
        step.withinValidReturnPeriod &&
        step.noResizing &&
        step.paidInFull &&
        step.newUnwornCondition &&
        step.notCustomised &&
        step.noEngraving &&
        !step.authoriseReturnWithoutValidation
      ) {
        return false;
      }
    }

    if (step.hasDiamonds) {
      if (
        type === "" &&
        step.complete &&
        step.reason &&
        step.withinValidReturnPeriod &&
        step.noResizing &&
        step.paidInFull &&
        step.newUnwornCondition &&
        step.notCustomised &&
        step.diamondCertificateReturned &&
        step.noEngraving &&
        !step.authoriseReturnWithoutValidation
      ) {
        return false;
      }
    }

    return true;
  };

  const confirmCreateReturn = (value, type) => {
    setShowConfirmRemaking(false);

    setShowConfirmRefund(false);

    setShowConfirmExchange(false);

    if (value) {
      onResponseSave(type);
    }
  };

  return (
    <>
      <Form>
        <Row>
          <Col xs={12} lg={12}>
            {isPrevent && (
              <span className="text-danger">
                This field shouldn't have have more than 225 characters, it currently
                has
                {` ${step?.reason.length}`}.
              </span>
            )}
            <FormInputTextarea
              label="Reason for return"
              nameInput="reason"
              value={step.reason ?? ""}
              required={true}
              onResponse={handleChange}
              disabled={disabledInputs}
            />
          </Col>
        </Row>
        <Row className="mt-1">
          <Col xs={12} lg={6} className="dashed-right">
            <Row>
              <Col xs={1} lg={1}>
                <SaleWorkflowReturnTooltip
                  field="withinValidReturnPeriod"
                  message={step.withinValidReturnPeriodTooltip}
                />
              </Col>
              <Col xs={11} lg={11}>
                <FormInputCheckbox
                  typeView="switch"
                  type="checkbox"
                  label="Product within 30 days of receipt / collected or 60 days after we've informed the client"
                  nameInput="withinValidReturnPeriod"
                  required={
                    !step.authoriseReturnWithoutValidation ? true : false
                  }
                  checked={step.withinValidReturnPeriod ?? false}
                  onResponse={handleChangeCheckbox}
                  disabled={disabledInputs}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={6}>
            <Row>
              <Col xs={1} lg={1}>
                <SaleWorkflowReturnTooltip
                  field="noResizing"
                  message={step.noResizingTooltip}
                />
              </Col>
              <Col xs={11} lg={11}>
                <FormInputCheckbox
                  typeView="switch"
                  type="checkbox"
                  label="No resizing has been applied"
                  nameInput="noResizing"
                  checked={step.noResizing ?? false}
                  required={
                    !step.authoriseReturnWithoutValidation ? true : false
                  }
                  onResponse={handleChangeCheckbox}
                  disabled={disabledInputs}
                />
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Post
                  sales service must be checked in the ERP
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col xs={12} lg={6} className="dashed-right">
            <Row>
              <Col xs={1} lg={1}>
                <SaleWorkflowReturnTooltip
                  field="paidInFull"
                  message={step.paidInFullTooltip}
                />
              </Col>
              <Col xs={11} lg={11}>
                <FormInputCheckbox
                  typeView="switch"
                  type="checkbox"
                  label="The order was paid in full"
                  nameInput="paidInFull"
                  checked={step.paidInFull ?? false}
                  required={
                    !step.authoriseReturnWithoutValidation ? true : false
                  }
                  onResponse={handleChangeCheckbox}
                  disabled={disabledInputs}
                />
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- At the
                  moment of the purchase
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Check
                  with accounts team if unsure
                </span>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={6}>
            <Row>
              <Col xs={1} lg={1}>
                <SaleWorkflowReturnTooltip
                  field="newUnwornCondition"
                  message={step.newUnwornConditionTooltip}
                />
              </Col>
              <Col xs={11} lg={11}>
                <FormInputCheckbox
                  typeView="switch"
                  type="checkbox"
                  label="The item is in brand new/unworn condition"
                  nameInput="newUnwornCondition"
                  checked={step.newUnwornCondition ?? false}
                  required={
                    !step.authoriseReturnWithoutValidation ? true : false
                  }
                  onResponse={handleChangeCheckbox}
                  disabled={disabledInputs}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col xs={12} lg={6} className="dashed-right">
            <Row>
              <Col xs={1} lg={1}>
                <SaleWorkflowReturnTooltip
                  field="notCustomised"
                  message={step.notCustomisedTooltip}
                />
              </Col>
              <Col xs={11} lg={11}>
                <FormInputCheckbox
                  typeView="switch"
                  type="checkbox"
                  label="The returned item was not customised"
                  nameInput="notCustomised"
                  checked={step.notCustomised ?? false}
                  required={
                    !step.authoriseReturnWithoutValidation ? true : false
                  }
                  onResponse={handleChangeCheckbox}
                  disabled={disabledInputs}
                />
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Check
                  the ERP notes to confirm this information as well
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Eg:
                  added/removed diamonds, modification of our designs, etc
                </span>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={6}>
            <Row>
              <Col xs={1} lg={1}>
                <SaleWorkflowReturnTooltip
                  field="returnedInLast12months"
                  message={step.returnedInLast12monthsTooltip}
                />
              </Col>
              <Col xs={11} lg={11}>
                <FormInputCheckbox
                  typeView="switch"
                  type="checkbox"
                  label="Ensure that the customer has not returned an item in the last 12 months (only 1 return permitted within 12 month period)"
                  nameInput="returnedInLast12months"
                  checked={step.returnedInLast12months ?? false}
                  required={
                    !step.authoriseReturnWithoutValidation ? true : false
                  }
                  onResponse={handleChangeCheckbox}
                  disabled={disabledInputs}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col xs={12} lg={6} className="dashed-right">
            <Row>
              <Col xs={1} lg={1}>
                <SaleWorkflowReturnTooltip
                  field="noEngraving"
                  message={step.noEngravingTooltip}
                />
              </Col>
              <Col xs={11} lg={11}>
                <FormInputCheckbox
                  typeView="switch"
                  type="checkbox"
                  label="There is no engraving"
                  nameInput="noEngraving"
                  checked={step.noEngraving ?? false}
                  required={
                    !step.authoriseReturnWithoutValidation ? true : false
                  }
                  onResponse={handleChangeCheckbox}
                  disabled={disabledInputs}
                />
              </Col>
            </Row>
          </Col>
          {existSaleDiamond > 0 && (
            <Col xs={12} lg={6}>
              <Row>
                <Col xs={1} lg={1}>
                  <SaleWorkflowReturnTooltip
                    field="diamondCertificateReturned"
                    message={step.diamondCertificateReturnedTooltip}
                  />
                </Col>
                <Col xs={11} lg={11}>
                  <FormInputCheckbox
                    typeView="switch"
                    type="checkbox"
                    label="Diamond Certificate is returned by customer"
                    nameInput="diamondCertificateReturned"
                    checked={step.diamondCertificateReturned ?? false}
                    required={
                      !step.authoriseReturnWithoutValidation ? true : false
                    }
                    onResponse={handleChangeCheckbox}
                    disabled={disabledInputs}
                  />
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-
                    Laser inscription checked
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-
                    Applies only to engagement rings or certified diamond
                    jewellery
                  </span>
                </Col>
              </Row>
            </Col>
          )}
        </Row>

        <DividerDashed />

        <Row className="mt-1">
          <Col xs={12} lg={12}>
            <FormInputCheckbox
              typeView="switch"
              type="checkbox"
              label="Allow to start the return request without completing the validation check list. For example, the item could be faulty. The request can only be sent by a team leader."
              nameInput="authoriseReturnWithoutValidation"
              checked={step.authoriseReturnWithoutValidation ?? false}
              onResponse={handleChangeCheckbox}
              disabled={disabledInputs}
            />
          </Col>
          <Col xs={12} lg={12}>
            <FormInputTextarea
              label="Include details for not completing the validation check list"
              nameInput="reasonReturnWithoutValidation"
              value={step.reasonReturnWithoutValidation ?? ""}
              required={step.authoriseReturnWithoutValidation ? true : false}
              onResponse={handleChange}
              disabled={disabledInputs}
            />
          </Col>
        </Row>

        {status === API_STATUS_FAILED && error && (
          <AlertMessage
            type="danger"
            message={error}
            infoHeading=""
            autoClose={true}
          />
        )}

        {workflowStatus !== STATUS_CANCELLED && (
          <Row className="mt-1">
            <Col xs={12} lg={4} className="center-element">
              <Button
                onClick={() => setReturnType(TYPES.reMaking)}
                disabled={disabledReturnType()}
              >
                Request Re-making
              </Button>
            </Col>
            <Col xs={12} lg={4} className="center-element">
              <Button
                onClick={() => setReturnType(TYPES.refund)}
                disabled={disabledReturnType()}
              >
                Request Refund
              </Button>
            </Col>
            <Col xs={12} lg={4} className="center-element">
              <Button
                onClick={() => setReturnType(TYPES.exchange)}
                disabled={disabledReturnType()}
              >
                Request Exchange
              </Button>
            </Col>
          </Row>
        )}

        {workflowStatus !== STATUS_CANCELLED && (
          <Row className="mt-1">
            <Col xs={12} lg={4}>
              <span>
                - Faulty engagement rings / jewellery
                <br /> - Engagement rings that will
                <br />- upgrade the setting * Engagement rings always keep the
                same diamond
              </span>
            </Col>
            <Col xs={12} lg={4}>
              <span>- The customer wants the money back</span>
            </Col>
            <Col xs={12} lg={4}>
              <span>
                - Get the money paid into a new order *
                <br /> - Engagement rings that will need a new diamond
                <br />- Used if the customer wants to use the money on a
                different product
              </span>
            </Col>
          </Row>
        )}
      </Form>

      {showConfirmRemaking && (
        <ConfirmCreateReturn
          message="By requesting a re-making, a new order will be created automatically to process the new order"
          onResponse={confirmCreateReturn}
          type={TYPES.reMaking}
        />
      )}

      {showConfirmRefund && (
        <ConfirmCreateReturn
          message="By requesting a refund the process to re-stock the item will begin"
          onResponse={confirmCreateReturn}
          type={TYPES.refund}
        />
      )}

      {showConfirmExchange && (
        <ConfirmCreateReturn
          message="By requesting an exchange the process to re-stock the item will begin. You need to create the new order manually"
          onResponse={confirmCreateReturn}
          type={TYPES.exchange}
        />
      )}
    </>
  );
};

SaleWorkflowEditReturnStepOne.propTypes = {
  stepOne: PropTypes.object.isRequired,
  workflowStatus: PropTypes.string.isRequired,
  disabledInputs: PropTypes.bool.isRequired,
  sale: PropTypes.any,
  type: PropTypes.string,
};

SaleWorkflowEditReturnStepOne.defaultProps = {
  disabledInputs: false,
  sale: {},
};

export default SaleWorkflowEditReturnStepOne;
