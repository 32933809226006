import React, { Component } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import Auth from "../../../Auth";
import axios from "axios";
import FlashMessages from "../../FlashMessages";
import LoadingLayer from "../../LoadingLayer";
import * as globalConst from "../../../global/const";
import Util from "../../../Util";

// SCSS
import "../../../css/sales/sale_create.scss";
import "../../../css/modal.scss";
import SaleCreateCustomerData from "./SaleCreateCustomerData";
import SaleCreateShippingAdrressData from "./SaleCreateShippingAdrressData";
import SaleCreateProductData from "./SaleCreateProductData";
import SaleCreatePricesData from "./SaleCreatePricesData";
import SaleCreateSalesMarketingData from "./SaleCreateSalesMarketingData";
import SaleCreateAccountingData from "./SaleCreateAccountingData";
import { STATUS_PENDING_APPROVAL } from "../../../store/constants";

const CUSTOM = "Custom";
const BTNS_DISABLES = [
  globalConst.BTN_EDIT_SALE,
  globalConst.BTN_CREATE_WORKFLOW,
  globalConst.BTN_EDIT_WORKFLOW_ACTIVITY,
  globalConst.BTN_QUALITY_CONTROL_CHECK_START,
  globalConst.BTN_QUALITY_CONTROL_CHECK_PENDING,
  globalConst.BTN_QUALITY_CONTROL_CHECK_APPROVED,
  globalConst.BTN_QUALITY_CONTROL_CHECK_REJECTED,
];

class SaleCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customerData: {
        name: props.sale?.name ?? "",
        phone: props.sale?.phone ?? "",
        email: props.sale?.email ?? "",
      },
      shippingData: {
        shippingAddress: props.sale?.shippingAddress ?? "",
        shippingSuburb: props.sale?.shippingSuburb ?? "",
        shippingPostCode: props.sale?.shippingPostCode ?? "",
        shippingState: props.sale?.shippingState ?? "",
        shippingCountry: props.sale?.shippingCountry ?? "",
        shippingTo: Util.removeHTMLTags(props.sale?.shippingTo),
        shipCustomersAddress: Util.isShippingAddress(props.sale),
        pickUpAtShowroom: Util.isShippingShowroom(props.sale),
      },
      productData: {
        saleDate: new Date(),
        settingStatus: "",
        saleType: "Wedding Band",
        ringPreference: "",
        engraving: "",
        metal: "",
        ringSize: "",
        orderType: "",
      },
      pricesData: {
        pricesIncludeGst: true,
        totalDiamondWogst: "",
        totalSettingWogst: "",
        expressManufacturingWogst: "",
        engravingWogst: "",
        totalIncGst: "",
        balance: "",
        orderCurrency: "",
        invoice: "",
        amountAlreadyPaid: "",
        originalSaleId: null,
        taxPercentage: 0,
      },
      salesMarketingData: {
        soldFrom: "",
        salesRepName: "",
      },
      accountingData: {
        billingInformation: "",
      },
      customerId: "",
      saleDate: new Date(),
      name: props.sale?.name ?? "",
      phone: props.sale?.phone ?? "",
      email: props.sale?.email ?? "",
      sheetType: "ACTIVE",
      saleType: "Wedding Band",
      ringPreference: "",
      settingStatus: "",
      manufacturingLocation: "",
      engraving: "",
      metal: "",
      ringSize: "",
      orderType: "",
      expressManufacturingWogst: "",
      engravingWogst: "",
      totalIncGst: "",
      pricesIncludeGst: true,
      totalDiamondWogst: "",
      totalSettingWogst: "",
      balance: "",
      orderCurrency: "",
      invoice: "",
      onlineOrderNumber: "MRD",
      shippingAddress: props.sale?.shippingAddress ?? "",
      shippingSuburb: props.sale?.shippingSuburb ?? "",
      shippingPostCode: props.sale?.shippingPostCode ?? "",
      shippingState: props.sale?.shippingState ?? "",
      shippingCountry: props.sale?.shippingCountry ?? "",
      showPanelInformation: props.showPanelInformation ?? false,
      shippingTo: Util.removeHTMLTags(props.sale?.shippingTo),
      salesRepName: "",
      billingInformation: "",
      soldFrom: "",
      taxPercentage: 0,
      creating_sale: false,
      validated: false,
      amountAlreadyPaid: null,
      originalSaleId: null,
    };
  }

  closeModal = () => {
    this.props.disabledActionsBotons([]);
    this.props.closeCreateComponent();
  };

  validateFieldsAndSave = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    const check = form.checkValidity();

    if (!check) {
      this.setState({
        validated: true,
      });

      return;
    }

    this.createSale();
  };

  axioRequest = null;
  createSale = () => {
    this.setState({
      creating_sale: true,
      error_messages: [],
    });
    const user = Auth.getAuthenticatedUser();
    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_SALES_CREATE;
      const params = new URLSearchParams();
      params.append("saleDate", this.state.saleDate);
      params.append("name", this.state.name);
      params.append("phone", this.state.phone);
      params.append("email", this.state.email);
      params.append("sheetType", this.state.sheetType);
      params.append("saleType", this.state.saleType);
      params.append(
        "ringPreference",
        this.state.ringPreference.length <= 0
          ? CUSTOM
          : this.state.ringPreference
      );
      params.append("manufacturingLocation", this.state.manufacturingLocation);
      params.append("settingStatus", this.state.settingStatus);
      params.append("engraving", this.state.engraving);
      params.append("metal", this.state.metal);
      params.append("ringSize", this.state.ringSize);
      params.append("orderType", this.state.orderType);
      params.append(
        "expressManufacturingWogst",
        this.state.expressManufacturingWogst
      );
      params.append("engravingWogst", this.state.engravingWogst);
      params.append("totalIncGst", this.state.totalIncGst);
      params.append("pricesIncludeGst", this.state.pricesIncludeGst);
      params.append("totalDiamondWogst", this.state.totalDiamondWogst);
      params.append("totalSettingWogst", this.state.totalSettingWogst);
      params.append("balance", this.state.balance);
      params.append("orderCurrency", this.state.orderCurrency);
      params.append("invoice", this.state.invoice);
      params.append("shippingAddress", this.state.shippingAddress ?? "");
      params.append("shippingCountry", this.state.shippingCountry ?? "");
      params.append("shippingPostCode", this.state.shippingPostCode ?? "");
      params.append("shippingState", this.state.shippingState ?? "");
      params.append("shippingSuburb", this.state.shippingSuburb ?? "");
      params.append("shippingTo", this.state.shippingTo ?? "");
      params.append("salesRepName", this.state.salesRepName ?? "");
      params.append("billingInformation", this.state.billingInformation ?? "");
      params.append("soldFrom", this.state.soldFrom ?? "");
      params.append("customerId", this.state.customerId ?? "");
      params.append("taxPercentage", this.state.taxPercentage ?? 0);

      if (this.state.originalSaleId) {
        params.append("amountAlreadyPaid", this.state.amountAlreadyPaid ?? "");
        params.append("originalSaleId", this.state.originalSaleId ?? "");
        params.append("forceStatus", STATUS_PENDING_APPROVAL);
      }

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      axios
        .post(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              creating_sale: false,
              success_messages: ["Sale created"],
              error_messages: [],
            });

            var created_sale = data.result;
            this.generateCreationLog(created_sale);
          } else {
            this.setState({
              creating_sale: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];

            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }

            this.setState({
              creating_sale: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              creating_sale: false,
              error_messages: error_messages,
            });
          } else {
            this.setState({
              creating_sale: true,
            });
          }
          return null;
        });
    }
  };

  //Generate log after editing element
  axioRequest = null;
  last_sale_reported_for_log = null;
  generateCreationLog(edited_sale) {
    if (this.axioRequest !== null) {
      this.axioRequest.cancel();
    }

    this.axioRequest = axios.CancelToken.source();

    const url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_SALES_LOG_NEW;
    const params = new URLSearchParams();

    const user = Auth.getAuthenticatedUser();

    params.append("salesId", edited_sale.id);
    params.append("userId", user.id);
    params.append("oldInformation", JSON.stringify(edited_sale));
    params.append("newInformation", JSON.stringify(edited_sale));
    params.append("action", "CREATE");

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    };

    axios
      .post(url, params, config)
      .then((response) => {
        const data = response.data;
        if (data !== undefined && data.code === 200) {
          console.log("sales_log stored");
          window.location.reload(false);
        } else {
          console.log("Error storing sales_log");
        }
        this.axioRequest = null;
      })
      .catch((err) => {
        console.log("Error storing sales_log.");
        if (err.response) {
          if ("status" in err.response && err.response.status === 401) {
            console.log("Not authorised.");
          } else {
            console.log(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }
        } else if (err.request) {
          console.log(
            "There was a problem processing the information. Please reload this page and try again."
          );
        }
        return null;
      });
  }

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    return (
      <div id="SaleCreate_component">
        <Modal
          show={true}
          onHide={this.closeModal}
          dialogClassName="modal-90w"
          backdropClassName={
            this.state.showPanelInformation
              ? "modal-black-show-informacion-panel"
              : ""
          }
          className={
            this.state.showPanelInformation
              ? "modal-show-informacion-panel"
              : ""
          }
        >
          <Modal.Header closeButton>
            NEW SALE - CUSTOMISED WEDDING BANDS AND JEWELLERY
          </Modal.Header>
          <Modal.Body>
            <p>
              For sales that include products other than customised wedding
              bands, please follow the shopping cart process at &nbsp;
              <a
                target={"_blank"}
                href={Util.getStorageParameter("REACT_APP_NOVITA_WEBSITE_DOMAIN")}
                rel="noreferrer"
              >
                {Util.showURLApp()}
              </a>
            </p>

            {this.state.creating_sale === true ? <LoadingLayer /> : null}
            {
              <FlashMessages
                error_messages={this.state.error_messages}
                success_messages={this.state.success_messages}
              />
            }
            <Form
              noValidate
              validated={this.state.validated}
              onSubmit={this.validateFieldsAndSave}
            >
              <SaleCreateCustomerData
                saleData={this.state.customerData}
                onResponse={this.onResponse.bind(this)}
              />

              <SaleCreateShippingAdrressData
                saleData={this.state.shippingData}
                onResponse={this.onResponse.bind(this)}
              />

              <SaleCreateProductData
                saleData={this.state.productData}
                onResponse={this.onResponse.bind(this)}
              />

              <SaleCreatePricesData
                taxPercentage={this.state.taxPercentage}
                saleData={this.state.pricesData}
                onResponse={this.onResponse.bind(this)}
              />

              <Row>
                <Col sm="12" lg="6">
                  <SaleCreateSalesMarketingData
                    saleData={this.state.salesMarketingData}
                    onResponse={this.onResponse.bind(this)}
                  />
                </Col>

                <Col sm="12" lg="6">
                  <SaleCreateAccountingData
                    saleData={this.state.accountingData}
                    onResponse={this.onResponse.bind(this)}
                  />
                </Col>
              </Row>

              <Button
                className="btn-default save-information-btn mt-2 position-sticky-bottom float-right"
                type="submit"
                disabled={this.state.creating_sale}
              >
                Save General Information
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  setTaxPercentage = (value, name) => {
    if (name === globalConst.FIELD_SHIP_CUSTOMER_ADRRESS && value) {
      this.setState({
        taxPercentage: 0,
      });
    }

    if (name === globalConst.FIELD_PICK_UP_AT_SHOWROOM && value) {
      this.setState({
        taxPercentage: Util.getPercentage(null),
      });
    }

    if (name === globalConst.FIELD_COUNTRY && value) {
      this.setState({
        taxPercentage: Util.getPercentage(value),
      });
    }
  };

  onResponse = async (value, name) => {
    this.setTaxPercentage(value, name);

    if (this.state[name] === value) {
      return;
    }

    this.setState({
      [name]: value,
    });
  };

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  componentDidMount() {
    if (this.props.showPanelInformation) {
      this.props.disabledActionsBotons(BTNS_DISABLES);
    }
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default SaleCreate;
