import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, FormLabel } from "react-bootstrap";
import FormSelectMultiple from "../../../Form/FormSelectMultiple";
import Util from "../../../../Util";

const FieldSaleURLReviewLink = ({ urls, commissionWasPaid, onResponse }) => {
  let [customerReviewUrl, setCustomerReviewUrl] = useState(urls);
  let [showInput, setShowInput] = useState(true);
  let [isInvalid, setIsInvalid] = useState(false);
  let [messageErrorUrls, setMessageErrorUrls] = useState(false);

  const changeUrls = (selected) => {
    if (selected === customerReviewUrl) {
      return;
    }

    setShowInput(false);
    setIsInvalid(false);
    setMessageErrorUrls("");

    const urls = selected.split(",");

    const newUrls = urls.filter((url) => {
      return Util.validUrlReviews(url) ?? url;
    });

    const errorUrls = urls.filter((url) => {
      return !Util.validUrlReviews(url) ?? url;
    });

    if (errorUrls.length > 0) {
      setMessageErrorUrls(errorUrls[errorUrls.length - 1]);
      setIsInvalid(true);
    }

    let uniqueArray = [...new Set(newUrls)];

    const urlsStr = uniqueArray.join(",");

    setCustomerReviewUrl(urlsStr);

    onResponse(urlsStr);
  };

  useEffect(() => {
    if (!showInput) {
      setShowInput(true);
    }

    if (isInvalid) {
      cleanError();
    }
  }, [showInput, isInvalid]);

  const cleanError = () => {
    setTimeout(() => {
      setIsInvalid(false);
    }, 5000);
  };

  return (
    <>
      <Form.Group>
        <FormLabel>URL review link</FormLabel>
        {showInput && (
          <FormSelectMultiple
            options={[]}
            onChange={changeUrls}
            nameField="customerReviewUrl"
            data={customerReviewUrl ?? ""}
            labelKey={"name"}
            disabled={commissionWasPaid}
            allowNew={true}
            newSelectionPrefix={"Add url: "}
          />
        )}

        <Form.Control
          name="customerReviewUrl"
          hidden={true}
          onChange={changeUrls}
          value={customerReviewUrl ?? ""}
          required={true}
        />

        <Form.Control.Feedback type="invalid">
          URL review link is required
        </Form.Control.Feedback>

        {isInvalid && (
          <>
            <p className="text-danger">
              The url {messageErrorUrls} not is valid
            </p>
          </>
        )}

        <Form.Text muted>
          Valid URLS:
          <ul>
            <li>google.com</li>
            <li>trustpilot.com</li>
            <li>maps.google.com</li>
            <li>goo.gl.com</li>
            <li>maps.app.goo.gl</li>
            <li>g.co</li>
            <li>xiaohongshu.com</li>
          </ul>
        </Form.Text>
      </Form.Group>
    </>
  );
};

FieldSaleURLReviewLink.propTypes = {
  urls: PropTypes.string.isRequired,
  commissionWasPaid: PropTypes.bool.isRequired,
  onResponse: PropTypes.func.isRequired,
};

export default FieldSaleURLReviewLink;
