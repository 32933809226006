import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Modal, FormLabel } from "react-bootstrap";
import InfiniteScroll from 'react-infinite-scroll-component';
import { findCustomers, resetCustomerCreateFind } from "../../../store/slices";
import { formatCustomerCreationDate } from "../../../utils/dateUtils";
import axiosServices from "../../../axios";
import { debounce } from "lodash";

const SearchCustomers = ({
    show,
    onHide,
    handleSelection
}) => {
    const { customerCreateFind = [] } = useSelector((state) => state.customers);
    const dispatch = useDispatch();
    const [highlightedCustomer, setHighlightedCustomer] = useState(null);
    const [disableButton, setDisableButton] = useState(true);

    const fetchCustomer = async (id) => {
        const response = await axiosServices.get(`/api/v1/customers/customer/${id}`);
        return response.data;
    };

    const handleSelectionCustomer = (customer) => {
        fetchCustomer(customer.id).then((data) => {
            setHighlightedCustomer(data);
        });
        setDisableButton(false);
    }

    const handleCloseSearchCustomer = () => {
        if (highlightedCustomer == null) {
            alert("Please select customer.");
        } else {
            onHide();
            handleSelection(highlightedCustomer);
        }
    }

    useEffect(() => {
        return () => {
            dispatch(resetCustomerCreateFind());
        };
    }, [dispatch]);

    const handler = useCallback(
        debounce(
          (e) =>
            dispatch(
                findCustomers({
                    filterText: e.target.value,
                    cancelToken: null,
                    createdSort: 'DESC',
                })
            ),
          300
        ),
        [customerCreateFind]
    );

    const getCustomerCity = (customer) => {
        if (
            customer.customerAppointments &&
            customer.customerAppointments.length > 0
        ) {
            return customer.customerAppointments[0].showroomCity;
        }
        return "";
    };

    const extractEmployeeNames = (customer) => {
        if (!customer) {
            return "";
        }

        const { customerAppointments } = customer;

        if (!customerAppointments || !Array.isArray(customerAppointments)) {
            return "";
        }

        const employees = [];

        customerAppointments.forEach((customerAppointment) => {
            customerAppointment.customerEmployees.forEach((customerEmployee) => {
                employees.push(customerEmployee.employee.name);
            });
        });

        return employees.join(" / ");
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            dialogClassName="modal-90w"
        >
            <Modal.Header closeButton>
                SELECT CUSTOMER
            </Modal.Header>
            <Modal.Body>
                <Form id="PostSaleSearch_component">
                    <Form.Group>
                        <FormLabel>Search customer:</FormLabel>
                        <input className='box-bg' type="text" onChange={(e) => handler(e)} onKeyDown={e => { if (e.key === 'Enter') e.preventDefault(); }} />
                    </Form.Group>
                    <InfiniteScroll
                        dataLength={10}
                        hasMore={false}
                        height={'calc(70vh - 200px)'}
                    >
                        <table className="table table-striped table-sm table-hover" id="table_sales">
                            <thead className="thead-dark">
                                <tr className='titles'>
                                    <th className="saleDate">
                                        Date of Created
                                    </th>
                                    <th className="name">
                                        Name
                                    </th>
                                    <th className="status">
                                        Email
                                    </th>
                                    <th className="orderTypeCol">
                                        Phone
                                    </th>
                                    <th className="dateCustomerNeedsBy">
                                        City
                                    </th>
                                    <th className="settingStatus">
                                        Sales Representative
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="overflow-scroll">
                                {customerCreateFind.map((customer, index) => {
                                    return (
                                        <tr
                                            className={`customer_row ${highlightedCustomer?.id === customer.id ? "highlighted_row" : ""
                                                }`}
                                            key={customer.id}
                                            onClick={() => handleSelectionCustomer(customer)}
                                        >
                                            <td>{formatCustomerCreationDate(customer.created)}</td>
                                            <td>{customer.name}</td>
                                            <td>{customer.email}</td>
                                            <td>{customer.phone}</td>
                                            <td>{getCustomerCity(customer)}</td>
                                            <td>{extractEmployeeNames(customer)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                    <Button
                        className="btn-default save-information-btn"
                        type="button"
                        disabled={disableButton}
                        onClick={() => handleCloseSearchCustomer()}
                    >
                        Select
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
export default SearchCustomers;