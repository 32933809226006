import React, { Component } from "react";
import axios from "axios";
// import { Link, NavLink } from 'react-router-dom';
import PostSaleRow from "./PostSaleRow";
import PostSaleInformation from "./postSaleInformation/PostSaleInformation";
import PostSaleInformationModal from "./postSaleInformation/PostSaleInformationModal";
import InfiniteScroll from "react-infinite-scroll-component";
import Auth from "../../Auth";
import LoadingLayer from "../LoadingLayer";
import { Link } from "react-router-dom";
import CreatedFilter from "./CreatedFilter";
import DateFilter from "./DateFilter";
import DueDateFilter from "./DueDateFilter";
import PostSaleEdit from "./edit/PostSaleEdit";
import PostSaleCreate from "./create/PostSaleCreate";
import { faPlus, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";
import BackIcon from "../../image/backArrow.png";
import SearchIcon from "../../image/pink_search.png";
import * as globalConst from "../../global/const";
import Notifications from "../../Notifications";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

// SCSS
import "../../css/sales/sales.scss";
import withNavigation from "../../Router/WithNavigation";
import Util from "../../Util";

// import { Switch, Route } from 'react-router-dom';

const CancelToken = axios.CancelToken;
let cancel;

class PostSaleServices extends Component {
  constructor(props) {
    super(props);
    const isAuth = Auth.getIsAuth();
    if (isAuth === false) {
      const { navigate } = this.props;

      navigate("/");
    }

    const order_ref = new URLSearchParams(window.location.search).get(
      "order-ref"
    );

    this.child = React.createRef();

    this.state = {
      sales: [],
      loading_page: false,
      selected_sale: null,
      selected_sale_index: null,
      total_results: 0,
      api_has_more: false,
      sheetType: "ACTIVE",
      sale_to_edit: null,
      search: order_ref ?? "",
      show_create_sale_modal: false,
      show_information_sale_modal: false,
      customerNeedsToPay: "all",
      sales_logs: null,
      loading_log: true,
      loading_excel: false,
      show_information_panel: false,
    };
    this.resetFilters();
    this.openEditionComponent = this.openEditionComponent.bind(this);
    this.closeEditionComponent = this.closeEditionComponent.bind(this);
    this.closeCreateComponent = this.closeCreateComponent.bind(this);
    this.closeInformationComponent = this.closeInformationComponent.bind(this);
    this.fetchPostSaleDetailWithId = this.fetchPostSaleDetailWithId.bind(this);
  }

  filter_params = {};

  resetFilters = () => {
    this.filter_params = {
      page: 1,
      max_results: 100,
      order_by: "created",
      order_type: "DESC",
      sheetType: this.state.sheetType,
      dateReceivedInBackOffice: "",
    };
  };

  fetchMoreData = () => {
    this.filter_params["page"] = this.filter_params["page"] + 1;
    this.fetchPostSales();
  };

  fetchPostSales() {
    this.setState({
      loading_page: true,
    });
    //AJAX CONTROLS
    //cancel previous ajax request if exists
    if (cancel !== undefined) {
      cancel();
    }
    // creates a new token for upcomming ajax (overwrite the previous one)
    const url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_POST_SALE_SERVICES_LIST;
    axios
      .get(url, {
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
        params: this.filter_params, //this params are sent as query. In post requests they should go as body params in the following block
        headers: { Authorization: `Bearer ${Auth.getToken()}` },
      })
      .then((api_response) => {
        const result = api_response.data.result;
        const items = result.rows;

        // add list to previous items
        let list = [];
        if (this.filter_params.page === 1) {
          list = items;
          // window.scrollTo(0,0);
          window.scrollTo({ top: 0 });
        } else {
          let current_list = [...this.state.sales];
          list = current_list.concat(items);
        }

        this.setState({
          sales: list,
          loading_page: false,
          total_results: result.count,
          api_has_more: result.amount_results > 0 ? true : false,
          sheetType: this.filter_params.sheetType,
        });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          return;
        }

        if (err.response) {
          if ("status" in err.response && err.response.status === 401) {
            alert("User not authorised. Please reload the page");
          } else {
            alert(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }
          this.setState({
            loading_page: false,
          });
        } else if (err.request) {
          alert(
            "There was a problem with your request. Please reload this page and try again."
          );
          this.setState({
            loading_page: false,
          });
        } else {
          //error not detected. Request could have been cancelled and it's waiting for second
          //request to finish
          this.setState({
            loading_page: true,
          });
        }
        console.log(err);
      });
  }

  sortCreated = () => {
    if (typeof this.filter_params.order_type !== "undefined") {
      if (this.filter_params.order_type === "DESC") {
        this.filter_params.order_type = "ASC";
      } else {
        this.filter_params.order_type = "DESC";
      }
    }
    this.filter_params.page = 1;
    this.filter_params.max_results = 100;
    this.filter_params.order_by = "created";
    this.fetchPostSales();
  };

  sortDateReceivedInBackOffice = () => {
    if (typeof this.filter_params.order_type !== "undefined") {
      if (this.filter_params.order_type === "DESC") {
        this.filter_params.order_type = "ASC";
      } else {
        this.filter_params.order_type = "DESC";
      }
    }
    this.filter_params.page = 1;
    this.filter_params.max_results = 100;
    this.filter_params.order_by = "dateReceivedInBackOffice";
    this.fetchPostSales();
  };

  sortDueDate = () => {
    if (typeof this.filter_params.order_type !== "undefined") {
      if (this.filter_params.order_type === "DESC") {
        this.filter_params.order_type = "ASC";
      } else {
        this.filter_params.order_type = "DESC";
      }
    }
    this.filter_params.page = 1;
    this.filter_params.max_results = 100;
    this.filter_params.order_by = "dueDate";
    this.fetchPostSales();
  };

  render() {
    var access_to_create_post_sale = Auth.isAuthorised("post_sales_create");

    return (
      <div id="PostSalesList_component">
        {this.state.sale_to_edit !== null ? (
          <PostSaleEdit
            sale={this.state.sale_to_edit}
            oldSale={this.state.sale_to_edit}
            closeEditionComponent={this.closeEditionComponent}
            selected_sale_index={this.state.selected_sale_index}
            fetchPostSaleDetailWithId={this.fetchPostSaleDetailWithId}
          />
        ) : null}
        {this.state.show_create_sale_modal === true ? (
          <PostSaleCreate closeCreateComponent={this.closeCreateComponent} />
        ) : null}
        {this.state.show_information_sale_modal === true ? (
          <PostSaleInformationModal
            selected_sale={this.state.selected_sale}
            openEditionComponent={this.openEditionComponent}
            closeInformationComponent={this.closeInformationComponent}
          />
        ) : null}
        <div className="container_sales">
          {this.state.loading_page === true ? <LoadingLayer /> : ""}
          {this.state.loading_excel === true ? <LoadingLayer /> : ""}
          <div className="list_and_information">
            <div className="row">
              <div className="sales_list">
                <div className="header">
                  <div className="row header_tools">
                    <div className="col-12">
                      <div className="link_to_dashboard">
                        <Link to="/dashboard">
                          <img height="50px" src={BackIcon} alt="" />
                        </Link>
                      </div>
                      <div className="db-top-heading">
                        <h2 className="sales-title">POST SALE SERVICES</h2>
                        <Notifications />
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <div className="row">
                        <div className="col-6 col-left">
                          ({this.state.total_results})
                        </div>
                        <div className="col-6 col-right">
                          <div className="add_new_sale">
                            <button
                              className="btn"
                              onClick={this.generateExcel}
                            >
                              <FontAwesomeIcon icon={faDownload} />
                            </button>
                            {access_to_create_post_sale === true ? (
                              <button onClick={this.openCreateComponent}>
                                <FontAwesomeIcon icon={faPlus} />
                              </button>
                            ) : null}
                          </div>
                          <div className="general_searcj float-right">
                            <div className="input-group">
                              <input
                                type="text"
                                placeholder="Search"
                                className="form-control box-bg"
                                value={this.state.search ?? ""}
                                onChange={(e) =>
                                  this.handleFilterChange(e, "generalSearch")
                                }
                              />
                              <div className="input-group-append">
                                <span
                                  className="input-group-text"
                                  id="basic-addon2"
                                >
                                  <img src={SearchIcon} alt="" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <InfiniteScroll
                  dataLength={this.state.sales.length}
                  next={this.fetchMoreData}
                  hasMore={this.state.api_has_more}
                  height={"calc(100vh - 150px)"}
                >
                  <table
                    className="table table-striped table-sm table-hover"
                    id="table_sales"
                  >
                    <thead className="thead-dark">
                      <tr className="titles">
                        <th className="created" onClick={this.sortCreated}>
                          Created
                          {this.filter_params.order_by === "created"
                            ? this.filter_params.order_type === "DESC"
                              ? " ▼"
                              : " ▲"
                            : null}
                        </th>
                        <th className="onlineOrderNumber">Order Reference</th>
                        <th
                          className="dateReceivedInBackOffice"
                          onClick={this.sortDateReceivedInBackOffice}
                        >
                          Date received in Back Office
                          {this.filter_params.order_by ===
                          "dateReceivedInBackOffice"
                            ? this.filter_params.order_type === "DESC"
                              ? " ▼"
                              : " ▲"
                            : null}
                        </th>
                        <th className="dueDate" onClick={this.sortDueDate}>
                          Due Date
                          {this.filter_params.order_by === "dueDate"
                            ? this.filter_params.order_type === "DESC"
                              ? " ▼"
                              : " ▲"
                            : null}
                        </th>
                        <th className="status">Status</th>
                        <th className="name">Name</th>
                        <th className="handledVia">Showroom</th>
                        <th className="destination">Fixing location</th>
                        <th className="jeweller">Jeweller</th>
                        <th className="customerNeedsToPay">
                          Customer needs to pay
                        </th>
                      </tr>
                      <tr className="filters">
                        <th className="created">
                          <CreatedFilter
                            handleCreatedFilter={this.handleCreatedFilter}
                          />
                        </th>
                        <th className="onlineOrderNumber">
                          <input
                            type="text"
                            onChange={(e) =>
                              this.handleFilterChange(e, "onlineOrderNumber")
                            }
                          />
                          <Form.Check
                            type="checkbox"
                            name="filterEmptyOnlineOrderNumber"
                            id={"filterEmptyOnlineOrderNumber"}
                            onChange={(e) =>
                              this.handleFilterChange(
                                e,
                                "onlineOrderNumberEmpty"
                              )
                            }
                          />
                        </th>
                        <th className="dateReceivedInBackOffice">
                          <DateFilter
                            handleSaleDateFilter={this.handleSaleDateFilter}
                          />
                        </th>
                        <th className="dueDate">
                          <DueDateFilter
                            handleDueDateFilter={this.handleDueDateFilter}
                          />
                        </th>
                        <th className="status">
                          <input
                            type="text"
                            onChange={(e) =>
                              this.handleFilterChange(e, "status")
                            }
                          />
                          <Form.Check
                            type="checkbox"
                            name="filterEmptyStatus"
                            id={"filterEmptyStatus"}
                            onChange={(e) =>
                              this.handleFilterChange(e, "statusEmpty")
                            }
                          />
                        </th>
                        <th className="name">
                          <input
                            type="text"
                            onChange={(e) => this.handleFilterChange(e, "name")}
                          />
                          <Form.Check
                            type="checkbox"
                            name="filterEmptyName"
                            id={"filterEmptyName"}
                            onChange={(e) =>
                              this.handleFilterChange(e, "nameEmpty")
                            }
                          />
                        </th>
                        <th className="showroom">
                          <input
                            type="text"
                            onChange={(e) =>
                              this.handleFilterChange(e, "showroom")
                            }
                          />
                          <Form.Check
                            type="checkbox"
                            name="filterEmptyShowroom"
                            id={"filterEmptyShowroom"}
                            onChange={(e) =>
                              this.handleFilterChange(e, "showroomEmpty")
                            }
                          />
                        </th>
                        <th className="destination">
                          <input
                            type="text"
                            onChange={(e) =>
                              this.handleFilterChange(e, "destination")
                            }
                          />
                          <Form.Check
                            type="checkbox"
                            name="filterEmptyDestination"
                            id={"filterEmptyDestination"}
                            onChange={(e) =>
                              this.handleFilterChange(e, "destinationEmpty")
                            }
                          />
                        </th>
                        <th className="jewellers">
                          <input
                            type="text"
                            onChange={(e) =>
                              this.handleFilterChange(e, "jewellers")
                            }
                          />
                          <Form.Check
                            type="checkbox"
                            name="filterEmptyJewellers"
                            id={"filterEmptyJewellers"}
                            onChange={(e) =>
                              this.handleFilterChange(e, "jewellersEmpty")
                            }
                          />
                        </th>
                        <th className="customerNeedsToPay">
                          <select
                            name="filterCustomerNeedsToPay"
                            value={this.state.filterCustomerNeedsToPay}
                            onChange={(e) =>
                              this.handleFilterChange(e, "customerNeedsToPay")
                            }
                          >
                            <option value="all">All</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.sales.map((sale, index) => {
                        return (
                          <PostSaleRow
                            key={sale.id}
                            sale={sale}
                            index={index}
                            handleRowSelection={this.handleRowSelection}
                            selected_sale={this.state.selected_sale}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
              {this.state.show_information_panel ? (
                <PostSaleInformation
                  ref={this.child}
                  sale={this.state.selected_sale}
                  openEditionComponent={this.openEditionComponent}
                  loading_log={this.state.loading_log}
                  sales_logs={this.state.sales_logs}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  resetInformation = () => {
    this.setState({
      highlight_row: false,
      show_information_panel: false,
    });

    setTimeout(() => {
      this.setState({
        selected_sale: null,
        show_information_panel: true,
      });
    }, 1);
  };

  axiosRequest = null;
  fetchPostSalesLogs(selected_sale) {
    this.setState({
      show_information_panel: false,
    });

    //AJAX CONTROLS
    //cancel previous ajax request if exists
    if (this.axiosRequest !== null) {
      this.axiosRequest.cancel();
    }
    // creates a new token for upcomming ajax (overwrite the previous one)
    this.axiosRequest = axios.CancelToken.source();
    //FINISH AJAX CONTROLS
    const url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_POST_SALE_LOG_LIST;
    const filter_params = {
      postSaleServiceId: selected_sale.id,
    };
    axios
      .get(
        url,
        {
          params: filter_params, //this params are sent as query. In post requests they should go as body params in the following block
          headers: { Authorization: `Bearer ${Auth.getToken()}` },
        },
        {
          cancelToken: this.axiosRequest.token,
        }
      )
      .then((response) => {
        const data = response.data;
        if (data !== undefined && data.code === 200) {
          this.setState({
            loading_log: false,
            sales_logs: data.result.rows,
            show_information_panel: true,
          });
        } else {
          this.setState({
            loading_log: false,
            error_loading_log: true,
            show_information_panel: true,
          });
        }
      })
      .catch((err) => {
        if (err.response || err.request) {
          this.setState({
            loading_log: false,
            error_loading_log: true,
            show_information_panel: true,
          });
        } else {
          //error not detected. Request could have been cancelled and it's waiting for second
          //request to finish
          this.setState({
            loading_log: true,
            error_loading_log: false,
            show_information_panel: true,
          });
        }
        //console.log((err);
        return null;
      });
  }

  generateExcel = () => {
    this.setState({
      loading_excel: true,
    });
    let url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_POST_SALE_EXPORT;
    const params = new URLSearchParams();

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${Auth.getToken()}`,
      },
      responseType: "arraybuffer",
    };

    axios
      .post(url, params, config)
      .then((response) => {
        const data = response.data;
        var blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        var objectUrl = URL.createObjectURL(blob);
        window.open(objectUrl);
        this.setState({
          loading_excel: false,
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else {
          //error not detected. Request could have been cancelled and it's waiting for second
          //request to finish
          console.log(err);
        }
        this.setState({
          loading_excel: false,
        });
        return null;
      });
  };

  closeEditionComponent = (
    edited_sale,
    selected_sale_index,
    isChange = true
  ) => {
    if (!isChange) {
      this.setState({
        sale_to_edit: null,
      });
      return;
    }

    if (edited_sale !== null) {
      this.fetchPostSaleDetailWithId(
        edited_sale.id,
        selected_sale_index,
        isChange
      );
    }

    this.setState({
      sale_to_edit: null,
      oldSale: null,
    });
  };

  openEditionComponent = (sale_to_edit) => {
    this.setState({
      sale_to_edit: sale_to_edit,
      oldSale: sale_to_edit,
    });
  };

  closeCreateComponent = () => {
    this.setState({
      show_create_sale_modal: false,
    });
  };

  openCreateComponent = () => {
    this.setState({
      show_create_sale_modal: true,
    });
  };

  closeInformationComponent = () => {
    this.setState({
      show_information_sale_modal: false,
    });
  };

  openInformationComponent = () => {
    this.setState({
      show_information_sale_modal: true,
    });
  };

  handleCreatedFilter = (date) => {
    if (date !== null && date !== "") {
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      this.filter_params.created = year + "-" + month + "-" + day;
    } else {
      this.filter_params.created = null;
    }
    this.fetchPostSales();

    this.resetInformation();
  };

  handleSaleDateFilter = (date) => {
    if (date !== null && date !== "") {
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      this.filter_params.dateReceivedInBackOffice =
        year + "-" + month + "-" + day;
    } else {
      this.filter_params.dateReceivedInBackOffice = null;
    }
    this.fetchPostSales();

    this.resetInformation();
  };

  handleDueDateFilter = (date) => {
    if (date !== null && date !== "") {
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      this.filter_params.dueDate = year + "-" + month + "-" + day;
    } else {
      this.filter_params.dueDate = null;
    }
    this.fetchPostSales();

    this.resetInformation();
  };

  handleFilterChange = (e, field_name) => {
    let filter_value = e.target.value;
    let execute_filter = false;

    if (field_name === "generalSearch") {
      this.setState({
        search: filter_value,
      });
    }

    if (e.target.type === "checkbox") {
      field_name = field_name.replace("Empty", "");
      if (typeof this.filter_params[field_name] === "undefined") {
        this.filter_params[field_name] = "";
      }
      if (e.target.checked === true) {
        this.filter_params[field_name] =
          this.filter_params[field_name] + "&empty_filter";
        execute_filter = true;
      } else {
        this.filter_params[field_name] = this.filter_params[field_name].replace(
          "&empty_filter",
          ""
        );
        execute_filter = true;
      }
    } else {
      if (
        field_name === "id" ||
        field_name === "status" ||
        field_name === "statusEmpty" ||
        field_name === "name" ||
        field_name === "nameEmpty" ||
        field_name === "jewellers" ||
        field_name === "jewellersEmpty" ||
        field_name === "destination" ||
        field_name === "destinationEmpty" ||
        field_name === "showroom" ||
        field_name === "showroomEmpty" ||
        field_name === "onlineOrderNumber" ||
        field_name === "onlineOrderNumberEmpty" ||
        field_name === "generalSearch"
      ) {
        if (typeof this.filter_params[field_name] !== "undefined") {
          if (this.filter_params[field_name] === "") {
            this.filter_params[field_name] = filter_value;
          } else {
            let temp_field_params = this.filter_params[field_name].replace(
              "&empty_filter",
              ""
            );
            this.filter_params[field_name] = this.filter_params[
              field_name
            ].replace(temp_field_params, filter_value);
          }
        } else {
          this.filter_params[field_name] = filter_value;
        }
        execute_filter = true;
      } else if (
        field_name === "customerNeedsToPay" ||
        field_name === "customerNeedsToPayEmpty"
      ) {
        let state = {};
        state[field_name] = filter_value;
        this.setState(state);

        if (filter_value === "true") {
          this.filter_params[field_name] = true;
        } else if (filter_value === "false") {
          this.filter_params[field_name] = false;
        } else {
          delete this.filter_params[field_name];
        }
        execute_filter = true;
      }
    }
    this.filter_params["page"] = 1;
    if (execute_filter === true) {
      this.fetchPostSales();
    }

    this.resetInformation();
  };

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  componentDidMount() {
    //execute once the first time
    if (this.state.search) {
      this.handleFilterChange(
        { target: { value: this.state.search } },
        "generalSearch"
      );
    }

    if (!this.state.search) {
      this.fetchPostSales();
    }
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************

  handleRowSelection = (index) => {
    const sale = this.state.sales[index];
    const sale_id = sale.id;
    this.fetchPostSaleDetailWithId(sale_id, index);
    if (window.innerWidth <= 600) {
      this.openInformationComponent();
    }
  };

  ajaxRequestForDetail = null;
  fetchPostSaleDetailWithId(sale_id, selected_sale_index, isChange = true) {
    if (!isChange) {
      return;
    }

    let sales_list = this.state.sales;

    this.setState({
      loading_page: true,
    });
    //AJAX CONTROLS
    //cancel previous ajax request if exists
    if (this.ajaxRequestForDetail !== null) {
      this.ajaxRequestForDetail.cancel();
    }
    // creates a new token for upcomming ajax (overwrite the previous one)
    this.ajaxRequestForDetail = axios.CancelToken.source();
    //FINISH AJAX CONTROLS

    let url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_POST_SALE_SERVICES_DETAIL;
    url = url.replace("{id}", sale_id);

    axios
      .get(
        url,
        {
          headers: { Authorization: `Bearer ${Auth.getToken()}` },
        },
        {
          cancelToken: this.ajaxRequestForDetail.token,
        }
      )
      .then((api_response) => {
        const selected_sale = api_response.data.result;
        this.fetchPostSalesLogs(selected_sale);
        sales_list[selected_sale_index] = selected_sale;
        this.setState({
          selected_sale: selected_sale,
          selected_sale_index: selected_sale_index,
          loading_page: false,
          sales: sales_list,
        });
      })
      .catch((err) => {
        if (err.response) {
          if ("status" in err.response && err.response.status === 401) {
            alert("User not authorised. Please reload the page");
          } else {
            alert(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }
          this.setState({
            loading_page: false,
          });
        } else if (err.request) {
          alert(
            "There was a problem with your request. Please reload this page and try again."
          );
          this.setState({
            loading_page: false,
          });
        } else {
          //error not detected. Request could have been cancelled and it's waiting for second
          //request to finish
          this.setState({
            loading_page: true,
          });
        }
        console.log(err);
        return null;
      });
  }

  componentDidUpdate() {
    if (this.state.sales_logs !== null) {
      this.child ?? this.child.current.updateLog(this.state.sales_logs);
    }
  }
}

export default withNavigation(PostSaleServices);
