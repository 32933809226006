import React, {Component} from 'react';
import Util from '../../../Util';

class SaleComment extends Component {

    //*****************************************
    //RENDER
    //*****************************************
    render(){
        const sale_comment = this.props.sale_comment
        let comment_date = null
        if(sale_comment.created !== null && sale_comment.created !== ''){
            comment_date = new Date(sale_comment.created);
        }
        return(
            <div className='SaleComment_component'>
                <div className='row'>
                    <div className='col-12 mb-2 pb-2 border-bottom'>
                        <div className='sale_comment_subject'>
                           <b>{sale_comment.topic}</b>
                        </div>
                    </div>
                    <div className='col-12 mb-2 pb-2 border-bottom'>
                        <div className='sale_comment_text'>
                            {sale_comment.text}
                        </div>
                    </div>
                    <div className='col-6  mb-2 '>
                        <div className='sale_comment_employee'>
                            <b>  Added By: 
                            {
                                sale_comment.employeeModel !== null && sale_comment.employeeModel.name !== undefined ?
                                (sale_comment.employeeModel.name):
                                ('')
                            }
                            </b> 
                        </div>
                    </div>
                    <div className='col-6  mb-2 text-right'>
                        <div className="sale_comment_created">
                            <b>
                            { comment_date !== null ? 
                                <span>
                                    {comment_date.toLocaleString('default', { day: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") })} {comment_date.toLocaleString('default', { month: 'short', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") })}, { comment_date.toLocaleString('default', { year: 'numeric', timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") }) } - { comment_date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: Util.getStorageParameter("REACT_APP_TIMEZONE") }) }
                                </span>
                                :('')
                            }
                            </b>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************


    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************
    

}

export default SaleComment;