import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const SaleWorkflowReturnTooltip = ({ field, message }) => {
  if (!message) {
    return "";
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body
        dangerouslySetInnerHTML={{ __html: message }}
      ></Popover.Body>
    </Popover>
  );

  const render = () => {
    return (
      <OverlayTrigger key={field} placement={"bottom"} overlay={popover}>
        <FontAwesomeIcon
          className="ps-1 text-success add-task-button"
          icon={faInfoCircle}
        />
      </OverlayTrigger>
    );
  };

  return render();
};

SaleWorkflowReturnTooltip.propTypes = {
  message: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
};

SaleWorkflowReturnTooltip.defaultProps = {
  message: "",
};

export default SaleWorkflowReturnTooltip;
