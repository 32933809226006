import { faHandPointLeft } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import EditIcon from "../../../image/Edit.png";
import { useEffect, useState } from "react";
import axiosServices from "../../../axios";
import Auth from "../../../Auth";
import CustomerComments from "../../customers/information/comments";
import CustomerCard from "./customer-card";
import AppointmentCard from "./appointment-card";
import AddAppointmentsForm from "../edit";
import AppointmentFollowUpsCard from "./appointment-follow-ups-card";
import CustomerHistory from "./history";

const fetchAppointment = async (id) => {
  const response = await axiosServices.get(`/api/v1/customers/appointment/${id}`);
  return response.data;
};

export default function AppointmentsInformation() {
  const { selectedAppointment: appointment } = useSelector(
    (state) => state.appointments
  );
  const [showEditAppointment, setShowEditAppointment] = useState(false);
  const [refreshedAppointment, setRefreshedAppointment] = useState(null);

  useEffect(() => {
    setRefreshedAppointment(null);
  }, [appointment]);

  if (!appointment) {
    return (
      <div id="SaleInformation_component">
        <div className="choose_message">
          Choose an item from the list to find more
          <br />
          <span>
            <FontAwesomeIcon icon={faHandPointLeft} />
          </span>
        </div>
      </div>
    );
  }

  if (!refreshedAppointment) {
    fetchAppointment(appointment.id).then((data) => {
      setRefreshedAppointment(data);
    });
  }

  const editAppointment = () => {
    setShowEditAppointment(true);
  };

  if (!refreshedAppointment) {
    return (
      <div id="SaleInformation_component">
        <div className="choose_message">
          Choose an item from the list to find more
          <br />
          <span>
            <FontAwesomeIcon icon={faHandPointLeft} />
          </span>
        </div>
      </div>
    );
  }

  const accesEdit = () => {
    const access = Auth.canAccessRolesAndLeader("rolesAccessCRMCreate");

    const accessOperation = Auth.canAccessRolesAndLeader(
      "rolesAccessCRMOperation",
      true
    );

    if (access || accessOperation) {
      return true;
    }

    return false;
  };

  return (
    <div id="sales_card">
      <h1>{refreshedAppointment?.customer.name}</h1>
      <div className="d-flex flex-row">
        {accesEdit() && (
          <button className="btn" onClick={editAppointment}>
            EDIT Appointment{" "}
            <img style={{ width: "40px" }} src={EditIcon} alt="" />
          </button>
        )}
      </div>
      <CustomerComments customer={refreshedAppointment?.customer} />
      <CustomerCard customer={refreshedAppointment?.customer} />
      <AppointmentCard appointment={refreshedAppointment} />
      <AppointmentFollowUpsCard appointment={refreshedAppointment} />
      <CustomerHistory customer={refreshedAppointment?.customer} />
      {showEditAppointment && (
        <AddAppointmentsForm
          show={showEditAppointment}
          onHide={() => {
            setShowEditAppointment(false);
          }}
          editingAppointment={refreshedAppointment}
        />
      )}
    </div>
  );
}
