import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import DividerDashed from "../../../../Divider/DividerDashed";
import { convertUtcToTimezone } from "../../../../../utils/dateUtils";
import FormDatePicker from "../../../../Form/FormDatePicker";
import FormInputCheckbox from "../../../../Form/FormInputCheckbox";
import Util from "../../../../../Util";

const SaleWorkflowEditReturnStepThree = ({
  stepThree,
  stepTwo,
  onResponse,
}) => {
  const { dateSentMainOfficeRestocking } = stepTwo;
  const [step, setStep] = useState(stepThree);

  const handleChange = (value, name) => {
    setStep({
      ...step,
      [name]: value,
    });

    stepThree[name] = value;

    onResponse(stepThree, "stepThreeQuestions");
  };

  return (
    <>
      <DividerDashed />
      <Row>
        <Col xs="12" lg="12">
          <h2>
            Sent for re-stocking on the{" "}
            {convertUtcToTimezone(
              dateSentMainOfficeRestocking,
              "Do MMM of YYYY",
              Util.getStorageParameter("REACT_APP_TIMEZONE")
            )}
          </h2>
        </Col>
      </Row>

      <Row>
        <Row className="mt-1">
          <Col xs={12} lg={12}>
            <FormDatePicker
              label={"Date stock received in main office"}
              nameInput="dateStockReceivedMainOffice"
              value={step.dateStockReceivedMainOffice ?? ""}
              onResponse={handleChange}
              columnsSize={"12"}
              format={"dd/MM/yyyy"}
              maxDate={new Date()}
            />
          </Col>
        </Row>

        <Row className="mt-1">
          <Col xs={12} lg={12}>
            <FormInputCheckbox
              typeView="switch"
              type="checkbox"
              label="Stock and / or diamond were re-activated"
              nameInput="stockOrDiamondReactivated"
              checked={step.stockOrDiamondReactivated ?? false}
              onResponse={handleChange}
            />
          </Col>
        </Row>

        <Row className="mt-1">
          <Col xs={12} lg={12}>
            <FormInputCheckbox
              typeView="switch"
              type="checkbox"
              label="Location was updated in GD system"
              nameInput="locationUpdatedInGd"
              checked={step.locationUpdatedInGd ?? false}
              onResponse={handleChange}
            />
          </Col>
        </Row>

        <Row className="mt-1">
          <Col xs={12} lg={12}>
            <FormInputCheckbox
              typeView="switch"
              type="checkbox"
              label="Chip reactivated or new tag created"
              nameInput="chipReactivationRequested"
              checked={step.chipReactivationRequested ?? false}
              onResponse={handleChange}
            />
          </Col>
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- For ring
            and/or diamond
          </span>
        </Row>
      </Row>
    </>
  );
};

SaleWorkflowEditReturnStepThree.propTypes = {
  stepThree: PropTypes.object.isRequired,
  stepTwo: PropTypes.object.isRequired,
};

export default SaleWorkflowEditReturnStepThree;
