import axios from "axios";
import * as globalConst from "../../global/const";
import Auth from "../../Auth";
import Util from "../../Util";

let dataProviders = null;
const source = axios.CancelToken.source();

export const FindJewelleyByContactName = async (value = null, field = "id") => {
  try {
    if (dataProviders && dataProviders?.items) {
      const defaultProvider = findDefauldProvider(
        dataProviders.items,
        value,
        field
      );

      return { items: dataProviders.items, defaultProvider };
    }

    const url = `${Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN")}${
      globalConst.REACT_APP_NOVITAERP_API_JEWELLER_LIST
    }`;

    const config = {
      cancelToken: source.token,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    };

    return await axios
      .get(url, config)
      .then((response) => {
        const { data } = response;
        const { result } = data;
        const { rows } = result;

        if (rows) {
          const newRows = rows.map((item) => {
            item.nameComplete = `${item.name} (${item.contactName})`;

            return item;
          });

          const defaultProvider = findDefauldProvider(newRows, value, field);

          dataProviders = { items: newRows, defaultProvider };

          return dataProviders;
        }

        return [];
      })
      .catch((error) => {
        console.error(error);
        if (axios.isCancel(error)) {
          return;
        }

        return [];
      });
  } catch (error) {
    return [];
  }
};

export const findDefauldProvider = (items = [], value = null, field = "id") => {
  const defaultProvider = [];

  if (!value) {
    return defaultProvider;
  }

  items.forEach((item) => {
    if (
      item[field].toString().trim().toUpperCase() ===
      value.toString().trim().toUpperCase()
    ) {
      defaultProvider.push(item);
    }
  });

  return defaultProvider;
};
