import React, { Component } from "react";
import Auth from "../../Auth";
import axios from "axios";
import * as globalConst from "../../global/const";
import SaleRowCustomisedList from "./SaleRowCustomisedList";
import InfiniteScroll from "react-infinite-scroll-component";
import Util from "../../Util";

class MyListTab extends Component {
  constructor(props) {
    super();
    this.state = {
      addToMyListSelectedSalesSortedBy: "created",
      addToMyListSelectedSalesSortedType: "ASC",
      addToMyListSelectedSales: props.checkedSales[0].salesIds,
      selected_accounting: props.selected_accounting,
      salesData: [],
      name: props.name,
      checkedSales: props.checkedSales[0].salesIds,
    };
    this.addSaleToCustomisedList = this.addSaleToCustomisedList.bind(this);
    this.removeSaleFromCustomisedList =
      this.removeSaleFromCustomisedList.bind(this);
    this.getNextSales = this.getNextSales.bind(this);
  }
  salesDataAll = [];
  salesDataPage = 1;
  salesPageSize = 20;

  /*handleChangeCheckbox(event){
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.checked
        this.setState(state)
        this.props.handleChangeCheckbox(this.props.index, event.target.checked)
    }*/

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    return (
      <InfiniteScroll
        dataLength={this.salesDataPage * this.salesPageSize}
        next={this.getNextSales}
        hasMore={true}
        height={
          this.state.salesData.length > 19 ? "calc(100vh - 150px)" : "auto"
        }
      >
        <table
          className="table table-striped table-sm table-hover"
          id="table_my_list"
        >
          <thead className="thead-dark">
            <tr className="titles">
              <th className="created" onClick={this.sortDateOfSaleMyList}>
                Date
                {this.state.addToMyListSelectedSalesSortedBy === "created"
                  ? this.state.addToMyListSelectedSalesSortedType === "DESC"
                    ? " ▼"
                    : " ▲"
                  : null}
              </th>
              <th className="addToMyList">My List</th>
              <th className="name">Name</th>
              <th className="typeOfService">Type of Service</th>
              <th className="saleType">Type of Product</th>
              <th className="diamondInfo">Diamond Info</th>
              <th className="ringPreference">Product's Ref.</th>
              <th className="orderCurrency">Currency</th>
              <th className="invoice">Invoice Status</th>
              <th className="totalIncGst">
                Total Invoice (Inc{" "}
                {Util.getStorageParameter("REACT_APP_TAX_NAME")})
              </th>
              <th className="balance">Balance</th>
              <th className="xeroCode">Xero Code</th>
              <th className="onlineOrderNumber">Online Ref.</th>
              <th className="paymentMethod">Payment Method</th>
              <th className="generalCostStatus">Accounting Status</th>
            </tr>
          </thead>
          <tbody>
            {this.state.salesData.map((accounting, index) => {
              return (
                <SaleRowCustomisedList
                  key={accounting.id}
                  accounting={accounting}
                  accountingId={accounting.id}
                  index={index}
                  handleRowSelection={this.handleRowSelectionMyList}
                  handleChangeCheckbox={null}
                  selected_accounting={this.state.selected_accounting}
                  addToMyList={true}
                  addSaleToCustomisedList={this.addSaleToCustomisedList}
                  removeSaleFromCustomisedList={
                    this.removeSaleFromCustomisedList
                  }
                />
              );
            })}
          </tbody>
        </table>
      </InfiniteScroll>
    );
  }

  addSaleToCustomisedList(saleId) {
    this.props.addSaleToCustomisedList(
      saleId,
      this.state.addToMyListSelectedSales
    );
  }

  removeSaleFromCustomisedList(saleId) {
    var newCheckedSales = JSON.parse(this.state.addToMyListSelectedSales);

    newCheckedSales = newCheckedSales.filter(function (e) {
      return e !== saleId;
    });
    this.setState(
      {
        addToMyListSelectedSales: JSON.stringify(newCheckedSales),
      },
      () => {
        this.props.removeSaleFromCustomisedList(saleId, newCheckedSales);
        this.getCheckedSales();
      }
    );
  }

  sortDateOfSaleMyList = () => {
    let addToMyListSelectedSales = this.salesDataAll;
    let addToMyListSelectedSalesSortedType =
      this.state.addToMyListSelectedSalesSortedType;

    if (addToMyListSelectedSalesSortedType === "DESC") {
      addToMyListSelectedSalesSortedType = "ASC";
      addToMyListSelectedSales.sort(function (a, b) {
        var keyA = new Date(a.created);
        var keyB = new Date(b.created);

        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;

        return 0;
      });
    } else {
      addToMyListSelectedSalesSortedType = "DESC";
      addToMyListSelectedSales.sort(function (a, b) {
        var keyA = new Date(a.created);
        var keyB = new Date(b.created);

        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;

        return 0;
      });
    }

    let salesDataSliced = addToMyListSelectedSales.slice(0, this.salesPageSize);
    this.salesDataPage = 1;

    this.setState({
      salesData: salesDataSliced,
      addToMyListSelectedSalesSortedType: addToMyListSelectedSalesSortedType,
      addToMyListSelectedSalesSortedBy: "created",
    });
  };

  handleRowSelectionMyList = (index) => {
    const sale = this.state.salesData[index];
    const sale_id = sale.id;
    this.props.fetchSaleDetailWithId(sale_id, index);
    /*if (window.innerWidth <= 600) {
            this.openInformationComponent()
        }*/
  };

  handleChangeCheckboxMyList = (index, state) => {
    this.props.togglecustomisedListItem();
    this.getCheckedSales();
  };

  getCheckedSales() {
    if (typeof this.state.addToMyListSelectedSales === "string") {
      this.fetchCheckedSales(this.state.addToMyListSelectedSales);
    }
  }

  axiosRequest = null;
  fetchCheckedSales(checkedSales) {
    //AJAX CONTROLS
    //cancel previous ajax request if exists
    if (this.axiosRequest !== null) {
      this.axiosRequest.cancel();
    }
    // creates a new token for upcomming ajax (overwrite the previous one)
    this.axiosRequest = axios.CancelToken.source();
    //FINISH AJAX CONTROLS
    const url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_ACCOUNTING_GET_BY_IDS;
    const params = new URLSearchParams();
    params.append("idsList", checkedSales);
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    };

    axios
      .post(url, params, config)
      .then((response) => {
        const data = response.data;
        if (data !== undefined && data.code === 200) {
          this.salesDataAll = data.result.rows;
          let salesDataSliced = this.salesDataAll.slice(0, this.salesPageSize);
          this.salesDataPage = 1;
          this.setState(
            {
              salesData: salesDataSliced,
            },
            this.sortDateOfSaleMyList
          );
        }
      })
      .catch((err) => {
        if (err.response || err.request) {
          this.setState({
            loading_log: false,
            error_loading_log: true,
          });
        } else {
          //error not detected. Request could have been cancelled and it's waiting for second
          //request to finish
          this.setState({
            loading_log: true,
            error_loading_log: false,
          });
        }
        //console.log((err);
        return null;
      });
  }

  ajaxRequestForDetail = null;
  fetchSaleDetailWithId(sale_id, selected_sale_index) {
    this.setState({
      loading_page: true,
    });
    //AJAX CONTROLS
    //cancel previous ajax request if exists
    if (this.ajaxRequestForDetail !== null) {
      this.ajaxRequestForDetail.cancel();
    }
    // creates a new token for upcomming ajax (overwrite the previous one)
    this.ajaxRequestForDetail = axios.CancelToken.source();
    //FINISH AJAX CONTROLS
    let url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_SALES_DETAIL;
    url = url.replace("{id}", sale_id);

    axios
      .get(
        url,
        {
          headers: { Authorization: `Bearer ${Auth.getToken()}` },
        },
        {
          cancelToken: this.ajaxRequestForDetail.token,
        }
      )
      .then((api_response) => {
        const selected_sale = api_response.data.result;
        this.setState({
          selected_sale: selected_sale,
          selected_sale_index: selected_sale_index,
          loading_page: false,
        });
      })
      .catch((err) => {
        if (err.response) {
          if ("status" in err.response && err.response.status === 401) {
            alert("User not authorised. Please reload the page");
          } else {
            alert(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }
          this.setState({
            loading_page: false,
          });
        } else if (err.request) {
          alert(
            "There was a problem with your request. Please reload this page and try again."
          );
          this.setState({
            loading_page: false,
          });
        } else {
          //error not detected. Request could have been cancelled and it's waiting for second
          //request to finish
          this.setState({
            loading_page: true,
          });
        }
        console.log(err);
        return null;
      });
  }

  getNextSales() {
    this.salesDataPage++;
    let salesDataSliced = this.salesDataAll.slice(
      0,
      this.salesDataPage * this.salesPageSize
    );
    this.setState({
      salesData: salesDataSliced,
    });
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  componentDidMount() {
    //execute once the first time
    this.getCheckedSales();
  }

  static getDerivedStateFromProps(props, state) {
    return {
      checkedSales: props.checkedSales[0].salesIds,
      selected_accounting: props.selected_accounting,
    };
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default MyListTab;
