import React, { Component } from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";
import SaleProductionEdit from "../../sales/edit/SaleProductionEdit";
import PostSaleAccountingEdit from "../../post_sale_services/edit/PostSaleAccountingEdit";
import SaleCostsEditWorkflow from "../../sales/edit/Cost/SaleCostsEditWorkflow";
import CostsEditCloseConfirmation from "./CostsEditCloseConfirmation";
import Auth from "../../../Auth";
import axios from "axios";
import * as globalConst from "../../../global/const";

// SCSS
import "../../../css/sales/sale_edit.scss";
import "../../../css/modal.scss";
import ShowSaleConsecutive from "../../sales/Acctions/ShowSaleConsecutive";
import { getWorkflowSale } from "../../sales/edit/Workflow/WorkflowSaleConsult";
import Util from "../../../Util";

class CostsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounting: props.accounting,
      showPanelInformation: props.showPanelInformation ?? false,
      edited_sale: null,
      edited_post_sale: null,
      pending_changes_accounts: false,
      pending_changes_general: false,
      pending_changes_product: false,
      pending_changes_production: false,
      pending_changes_marketing: false,
      pending_changes_costs: false,
      pending_changes_accounting: false,
      show_close_edit_confirmation: false,
      workflowSales: null,
      loadWorkflowSales: false,
    };

    this.updateData = false;
    this.closeEditionComponent = this.closeEditionComponent.bind(this);
    this.closeEditionComponentWithoutChanges =
      this.closeEditionComponentWithoutChanges.bind(this);
    this.saleUpdated = this.saleUpdated.bind(this);
    this.postSaleUpdated = this.postSaleUpdated.bind(this);
    this.generatePostSalesEditionLog =
      this.generatePostSalesEditionLog.bind(this);
    this.generateSaleEditionLog = this.generateSaleEditionLog.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.closeEditCloseConfirmation =
      this.closeEditCloseConfirmation.bind(this);
    this.updateJewellers = this.updateJewellers.bind(this);
  }

  closeEditCloseConfirmation() {
    this.setState({
      show_close_edit_confirmation: false,
    });
  }

  closeEditionComponent() {
    if (
      this.state.pending_changes_accounts ||
      this.state.pending_changes_general ||
      this.state.pending_changes_marketing ||
      this.state.pending_changes_product ||
      this.state.pending_changes_production ||
      this.state.pending_changes_costs ||
      this.state.pending_changes_accounting
    ) {
      this.openEditCloseConfirmationModal();
    } else {
      this.props.closeEditionComponent(
        this.state.edited_sale,
        this.state.edited_post_sale,
        this.props.selected_accounting_index,
        !this.updateData
      );
    }
  }

  closeEditionComponentWithoutChanges() {
    this.props.closeEditionComponent(null, null, null, !this.updateData);
  }

  saleUpdated(edited_sale) {
    this.setState({
      edited_sale: edited_sale,
    });
    this.generateSaleEditionLog(edited_sale);
    this.props.updateAccountingInformation(edited_sale);
  }

  postSaleUpdated(edited_post_sale) {
    this.setState({
      edited_post_sale: edited_post_sale,
    });
    this.generatePostSalesEditionLog(edited_post_sale);
  }

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    var access_to_production = Auth.isAuthorised("costs_edit_production");
    var access_to_account = Auth.isAuthorised("costs_edit_accounts");
    var access_to_costs = Auth.isAuthorised("costs_edit_costs");

    return (
      <div>
        {this.state.show_close_edit_confirmation === true ? (
          <CostsEditCloseConfirmation
            closeEditCloseConfirmation={this.closeEditCloseConfirmation}
            closeEditionComponent={this.closeEditionComponent}
            closeEditionComponentWithoutChanges={
              this.closeEditionComponentWithoutChanges
            }
          />
        ) : null}

        <Modal
          show={true}
          onHide={this.closeEditionComponent}
          dialogClassName="modal-90w"
          backdrop="static"
          backdropClassName={
            this.state.showPanelInformation
              ? "modal-black-show-informacion-panel"
              : ""
          }
          className={
            this.state.showPanelInformation
              ? "modal-show-informacion-panel"
              : ""
          }
        >
          <Modal.Header closeButton>
            Edit Costs{" "}
            {this.state.accounting?.postSaleServiceModel
              ? this.state.accounting?.postSaleServiceModel.onlineOrderNumber
              : this.state.accounting?.salesModel?.onlineOrderNumber}
            <ShowSaleConsecutive
              sale={
                this.state.accounting?.postSaleServiceModel
                  ? this.state.accounting?.postSaleServiceModel
                  : this.state.accounting?.salesModel
              }
              breackSpace={false}
              labelSmall={true}
            />
          </Modal.Header>
          <Modal.Body>
            <div id="SaleEdit_component">
              {this.state.accounting.salesModel !== null ? (
                <Tabs id="SaleEditTabs" className="mb-3">
                  {access_to_production === true ? (
                    <Tab
                      eventKey="production"
                      title="Production"
                      tabClassName={
                        this.state.pending_changes_production
                          ? "pending-changes"
                          : null
                      }
                    >
                      {this.state.loadWorkflowSales && (
                        <SaleProductionEdit
                          sale={this.state.accounting.salesModel}
                          workflowSales={this.state.workflowSales ?? []}
                          saleUpdated={this.saleUpdated}
                          handleTabChange={this.handleTabChange}
                        />
                      )}
                    </Tab>
                  ) : null}
                  {access_to_costs === true ? (
                    <Tab
                      eventKey="costs"
                      title="Costs"
                      tabClassName={
                        this.state.pending_changes_costs
                          ? "pending-changes"
                          : null
                      }
                    >
                      <SaleCostsEditWorkflow
                        sale={this.state.accounting.salesModel}
                        saleUpdated={this.saleUpdated}
                        handleTabChange={this.handleTabChange}
                        isCosts={true}
                      />
                    </Tab>
                  ) : null}
                </Tabs>
              ) : null}
              {this.state.accounting.postSaleServiceModel !== null ? (
                <Tabs id="SaleEditTabs" className="mb-3">
                  {access_to_account === true ? (
                    <Tab
                      eventKey="accounting"
                      title="Accounts"
                      tabClassName={
                        this.state.pending_changes_accounting
                          ? "pending-changes"
                          : null
                      }
                    >
                      <PostSaleAccountingEdit
                        sale={this.state.accounting.postSaleServiceModel}
                        saleUpdated={this.postSaleUpdated}
                        handleTabChange={this.handleTabChange}
                        updateJewellers={this.updateJewellers}
                        isCosts={true}
                      />
                    </Tab>
                  ) : null}
                </Tabs>
              ) : null}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  openEditCloseConfirmationModal() {
    this.setState({
      show_close_edit_confirmation: true,
    });
  }

  handleTabChange(tab, status) {
    if (status) {
      this.updateData = true;
    }

    switch (tab) {
      case "accounts":
        this.setState({
          pending_changes_accounts: status,
        });
        break;
      case "general":
        this.setState({
          pending_changes_general: status,
        });
        break;
      case "product":
        this.setState({
          pending_changes_product: status,
        });
        break;
      case "production":
        this.setState({
          pending_changes_production: status,
        });
        break;
      case "marketing":
        this.setState({
          pending_changes_marketing: status,
        });
        break;
      case "costs":
        this.setState({
          pending_changes_costs: status,
        });
        break;
      case "accounting":
        this.setState({
          pending_changes_accounting: status,
        });
        break;
      default:
        break;
    }
  }

  //Generate log after editing element
  axioRequest = null;
  last_sale_reported_for_log = null;
  generateSaleEditionLog(edited_sale) {
    //AJAX CONTROLS
    //cancel previous ajax request if exists
    if (this.axioRequest !== null) {
      this.axioRequest.cancel();
    }
    // creates a new token for upcomming ajax (overwrite the previous one)
    this.axioRequest = axios.CancelToken.source();

    let sale_before_update = this.last_sale_reported_for_log;
    if (sale_before_update === null) {
      sale_before_update = this.props.accounting.salesModel;
    }

    const url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_SALES_LOG_NEW;
    const params = new URLSearchParams();

    const user = Auth.getAuthenticatedUser();
    params.append("salesId", sale_before_update.id);
    params.append("userId", user.id);
    params.append("oldInformation", JSON.stringify(sale_before_update));
    params.append("newInformation", JSON.stringify(edited_sale));
    params.append("action", "EDIT");

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    };
    axios
      .post(url, params, config)
      .then((response) => {
        const data = response.data;
        if (data !== undefined && data.code === 200) {
          //console.log('sales_log stored')
        } else {
          console.log("Error storing sales_log");
        }
        this.axioRequest = null;
      })
      .catch((err) => {
        console.log("Error storing sales_log.");
        if (err.response) {
          if ("status" in err.response && err.response.status === 401) {
            console.log("Not authorised.");
          } else {
            console.log(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }
        } else if (err.request) {
          console.log(
            "There was a problem processing the information. Please reload this page and try again."
          );
        } else {
          //error not detected. Request could have been cancelled and it's waiting for second
          //request to finish
        }
        //console.log((err);
        return null;
      });
  }

  last_post_sale_reported_for_log = null;
  generatePostSalesEditionLog(edited_post_sale) {
    //AJAX CONTROLS
    //cancel previous ajax request if exists
    if (this.axioRequest !== null) {
      this.axioRequest.cancel();
    }
    // creates a new token for upcomming ajax (overwrite the previous one)
    this.axioRequest = axios.CancelToken.source();

    let sale_before_update = this.last_sale_reported_for_log;
    if (sale_before_update === null) {
      sale_before_update = this.state.accounting.postSaleServiceModel;
    }

    delete sale_before_update["salesModel"];
    delete edited_post_sale["salesModel"];

    const url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_POST_SALE_LOG_NEW;
    const params = new URLSearchParams();
    const user = Auth.getAuthenticatedUser();
    let postSaleId = this.state.accounting.postSaleServiceModel.id;
    params.append("postSaleServiceId", postSaleId);
    params.append("employeeId", user.employeeModel.id);
    params.append("oldInformation", JSON.stringify(sale_before_update));
    params.append("newInformation", JSON.stringify(edited_post_sale));
    params.append("action", "EDIT");

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    };
    axios
      .post(url, params, config)
      .then((response) => {
        const data = response.data;
        if (data !== undefined && data.code === 200) {
          console.log("sales_log stored");
        } else {
          console.log("Error storing sales_log");
        }
        this.axioRequest = null;
      })
      .catch((err) => {
        console.log("Error storing sales_log.");
        if (err.response) {
          if ("status" in err.response && err.response.status === 401) {
            console.log("Not authorised.");
          } else {
            console.log(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }
        } else if (err.request) {
          console.log(
            "There was a problem processing the information. Please reload this page and try again."
          );
        } else {
          //error not detected. Request could have been cancelled and it's waiting for second
          //request to finish
        }
        //console.log((err);
        return null;
      });

    Object.keys(sale_before_update).forEach((key) => {
      if (sale_before_update[key] !== edited_post_sale[key]) {
        if (key !== "updated") {
          let url =
            Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
            globalConst.REACT_APP_NOVITAERP_API_POST_SALE_EDITION_REQUIREMENT_CREATE;
          const params = new URLSearchParams();

          var rightNow = new Date();
          var date = rightNow.toISOString().slice(0, 10).replace(/-/g, "/");

          params.append("postSaleServiceId", sale_before_update.id);
          params.append("verificatorRoles", "ROLE_ADMIN");
          params.append("fieldName", key);
          params.append("oldValue", sale_before_update[key]);
          params.append("newValue", edited_post_sale[key]);
          params.append("employeeId", user.employeeModel.id);
          params.append("requiresAction", false);
          params.append(
            "created",
            date + " " + new Date().toLocaleTimeString()
          );
          params.append(
            "updated",
            date + " " + new Date().toLocaleTimeString()
          );

          const config = {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
          };

          axios
            .post(url, params, config)
            .then((response) => {
              const data = response.data;
              if (data !== undefined && data.code === 200) {
                console.log("Sale edition requirement created");
              } else {
                console.log(
                  "There was a problem processing the information. Please reload this page and try again."
                );
              }
            })
            .catch((err) => {
              if (err.response) {
                let error_messages = [];
                if ("status" in err.response && err.response.status === 401) {
                  error_messages = ["Not authorised."];
                } else {
                  error_messages = [
                    "There was a problem processing the information. Please reload this page and try again.",
                  ];
                }
                console.log(error_messages);
              } else if (err.request) {
                const error_messages = [
                  "There was a problem processing the information. Please reload this page and try again.",
                ];
                console.log(error_messages);
              } else {
                //error not detected. Request could have been cancelled and it's waiting for second
                //request to finish
              }
              //console.log((err);
              return null;
            });
        }
      }
    });
  }

  updateJewellers(new_jewellers) {
    if (this.state.accounting.postSaleServiceModel !== null) {
      var editedAccounting = JSON.parse(JSON.stringify(this.state.accounting));
      editedAccounting["postSaleServiceModel"]["jewellers"] = new_jewellers;

      this.setState({
        accounting: editedAccounting,
      });

      let edited_post_sale = editedAccounting["postSaleServiceModel"];
      this.generatePostSalesEditionLog(edited_post_sale);
    }
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  static getDerivedStateFromProps(props, state) {
    let edited_sale = state.edited_sale;
    let edited_post_sale = state.edited_post_sale;

    return {
      edited_sale: edited_sale,
      edited_post_sale: edited_post_sale,
    };
  }

  componentDidMount() {
    if (this.props.accounting) {
      const { salesModel } = this.props.accounting;

      this.findWorkflowSales(salesModel);
    }
  }

  findWorkflowSales = async (sale) => {
    if (!sale) {
      this.setState({
        workflowSales: [],
        loadWorkflowSales: true,
      });

      return null;
    }

    const workflowSales = await getWorkflowSale(sale);

    this.setState({
      workflowSales: workflowSales ?? [],
      loadWorkflowSales: true,
    });
  };

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default CostsEdit;
