import axios from "axios";
import { sprintf } from "sprintf-js";
import * as globalConst from '../../../global/const';
import Auth from "../../../Auth";
import Util from "../../../Util";

const CancelToken = axios.CancelToken;
let cancel;

export const getDiamonProductPicture = async (
    sale
) => {
    try {
        const { diamondDetails } = sale;
        const diamondDetailsParts = diamondDetails.split(' ')

        const url = sprintf(
            '%s%s',
            Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN"),
            globalConst.REACT_APP_NOVITAERP_API_DIAMOND_PRODUCT_LIST
        );

        const parameters = {
            certNo: diamondDetailsParts[0].toString()
        }

        if (cancel !== undefined) {
            cancel();
        }

        return await axios.get(
            url,
            {
                params: parameters,
                cancelToken: new CancelToken((c) => {
                    cancel = c;
                }),
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            }
        )
            .then(response => {
                const { data } = response;
                const { result } = data;
                const { rows } = result

                if (Array.isArray(rows) === true && rows.length > 0) {
                    const diamondProduct = rows[0]

                    return diamondProduct
                }

                return null
            }).catch(() => {
                return null
            })
    } catch (error) {
        return null
    }
}