import React from "react";
import { useNavigation } from "./NavigationContext";
import { useLocation, useParams } from "react-router-dom";

const withNavigation = (Component) => {
  return (props) => {
    const navigate = useNavigation();
    const location = useLocation();
    const params = useParams();

    return (
      <Component
        {...props}
        navigate={navigate}
        location={location}
        params={params}
      />
    );
  };
};

export default withNavigation;
