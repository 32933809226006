import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import WorkflowSaleForm from "./WorkflowSaleForm";
import { useDispatch, useSelector } from "react-redux";
import {
  clearWorkflowSale,
  fetchWorkflowSaleUpdate,
  setFormData,
} from "../../../../store/slices/workflowSaleSlice";
import ConfirmSaveForm from "../../../ConfirmSaveForm";
import AlertMessage from "../../../Utils/AlertMessage";
import {
  API_STATUS_FAILED,
  API_STATUS_SUCCESS,
} from "../../../../store/constants";
import WorkflowTableErros from "./WorkflowTableErros";
import LoadingSpinnerLayer from "../../../LoadingSpinnerLayer";

const UpdateWorkflowSaleMultiple = ({
  onResponse,
  currentTab = "",
  listTabs = [],
}) => {
  const { formData, workflowSaleData, status } = useSelector(
    (state) => state.workflowSale
  );

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(true);
  const [validated, setValidated] = useState(false);
  const [confirmSave, setConfirmSave] = useState(false);
  const [loading, setLoading] = useState(false);

  let salesIds = [];

  useEffect(() => {
    if (formData.salesIds.length <= 0) {
      let ids = [];
      listTabs.forEach((list) => {
        if (currentTab === list.name && list.salesIds) {
          ids = JSON.parse(list.salesIds.toString());
        }
      });

      const form = JSON.parse(JSON.stringify(formData));

      form.salesIds = ids;

      if (ids.length > 0) {
        dispatch(setFormData(form));
      }
    }
  });

  const closeModal = () => {
    dispatch(clearWorkflowSale());
    setShowModal(false);
    onResponse(false, workflowSaleData.reload);
  };

  const onResponseData = (data) => {
    let dataForm = data;
    dataForm.salesIds = salesIds;

    dispatch(setFormData(dataForm));
  };

  const validateSubmit = async (e) => {
    e.preventDefault();

    setValidated(false);
    const form = e.currentTarget;
    const check = form.checkValidity();

    if (!check) {
      setValidated(true);
      return;
    }

    setConfirmSave(true);
  };

  const processConfirmSave = async (value) => {
    setConfirmSave(false);
    setLoading(false);

    if (value) {
      setLoading(true);

      await dispatch(fetchWorkflowSaleUpdate(formData));

      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        size="lg"
        centered
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>Edit multiple orders</Modal.Header>

        {formData?.salesIds.length <= 0 && (
          <Col>
            <AlertMessage type="warning" message={"Sales not found"} />
          </Col>
        )}

        <Form onSubmit={validateSubmit} noValidate validated={validated}>
          <>
            <Modal.Body>
              {status === API_STATUS_SUCCESS &&
                Object.keys(workflowSaleData).length > 0 && (
                  <AlertMessage
                    type="success"
                    message={`${workflowSaleData?.totalSucessSave}/${formData.salesIds.length} sales where update successfull  `}
                    infoHeading=""
                    autoClose={false}
                  />
                )}

              {status !== API_STATUS_SUCCESS &&
                status !== API_STATUS_FAILED && (
                  <WorkflowSaleForm onResponse={onResponseData} />
                )}

              {status === API_STATUS_SUCCESS &&
                workflowSaleData &&
                workflowSaleData.errors.length > 0 && (
                  <div>
                    <AlertMessage
                      type="warning"
                      message={`${workflowSaleData.errors.length} orders could not be updated because they are not valid. Find below the list`}
                      infoHeading=""
                      autoClose={false}
                    />
                    <WorkflowTableErros />
                  </div>
                )}
            </Modal.Body>

            <Modal.Footer>
              {formData?.salesIds.length > 0 && (
                <Row lg={12}>
                  {loading && (
                    <Col className="center-element" lg={6}>
                      <LoadingSpinnerLayer />
                    </Col>
                  )}

                  {status !== API_STATUS_SUCCESS &&
                    status !== API_STATUS_FAILED && (
                      <Col lg={6}>
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={loading}
                        >
                          Save
                        </Button>
                      </Col>
                    )}
                </Row>
              )}
            </Modal.Footer>
          </>
        </Form>
      </Modal>

      {confirmSave && (
        <ConfirmSaveForm
          message={"Are you sure to save the process?"}
          onResponse={processConfirmSave}
        />
      )}
    </>
  );
};

UpdateWorkflowSaleMultiple.propTypes = {
  onResponse: PropTypes.func.isRequired,
  listTabs: PropTypes.array.isRequired,
  currentTab: PropTypes.string.isRequired,
};

export default UpdateWorkflowSaleMultiple;
