import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { BTN_QUALITY_CONTROL_CHECK_APPROVED } from "../../../../global/const";
import { dateFormatCustom } from "../../../../utils/dateUtils";

const QualityControlApproved = ({
  qualityControlCheck,
  btnsDisable,
  openModalEdit,
}) => {
  if (!qualityControlCheck) {
    return "";
  }

  const disabledBTNApproved = btnsDisable.includes(
    BTN_QUALITY_CONTROL_CHECK_APPROVED
  );

  const { approved = null, completedOn } = qualityControlCheck;

  if (approved !== true) {
    return "";
  }

  const openEdit = (event) => {
    event.preventDefault();

    openModalEdit(true);
  };

  const showNameEmployee = () => {
    const { employeeModel } = qualityControlCheck;

    if (employeeModel) {
      return employeeModel?.name;
    }

    return "";
  };

  return (
    <>
      <span>Quality Control Check: </span>
      <Link
        to=""
        className={disabledBTNApproved ? "disabled-btn" : ""}
        onClick={(event) => openEdit(event)}
      >
        Approved by {showNameEmployee()}
        {completedOn && (
          <span> on {dateFormatCustom(completedOn, "DD-MMM-YYYY")}</span>
        )}
      </Link>
    </>
  );
};

QualityControlApproved.propTypes = {
  qualityControlCheck: PropTypes.object,
  btnsDisable: PropTypes.array,
  openModalEdit: PropTypes.func,
};

QualityControlApproved.defaultProps = {
  qualityControlCheck: null,
  btnsDisable: [],
};

export default QualityControlApproved;
