import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import QualityControlCheck from "./QualityControl";
import {
  findQualityControlCheck,
  replaceQualityControlCheck,
} from "./QualityControl/Actions";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAllData,
  clearQualityControlCheck,
  clearStatus,
  setChangeBtns,
  setReloadSale,
} from "../../../store/slices";

const WorkflowActivityTitle = ({
  activity,
  qualityControlChecks,
  btnsDisable,
  onReloadSale,
  changeBtnDisabled,
}) => {
  const dispatch = useDispatch();

  const {
    createQualityControl,
    status,
    statusEdit,
    reloadSale,
    dataQualityControlCheck,
    dataBtnDisabled,
    changeBtns,
  } = useSelector((state) => state.qualityControlCheck);

  const [dataQualityControlChecks, setDataQualityControlChecks] = useState([]);
  const [update, setUpdate] = useState(true);

  useEffect(() => {
    if (
      update &&
      JSON.stringify(dataQualityControlChecks) !==
        JSON.stringify(qualityControlChecks)
    ) {
      setDataQualityControlChecks(qualityControlChecks);
      setUpdate(false);
    }
  }, [dataQualityControlChecks, qualityControlChecks, update]);

  useEffect(() => {
    if (createQualityControl || dataQualityControlCheck) {
      const data = dataQualityControlCheck ?? createQualityControl;
      const response = replaceQualityControlCheck(
        data,
        dataQualityControlChecks
      );

      setDataQualityControlChecks(response);
      dispatch(clearQualityControlCheck());
    }
  }, [
    createQualityControl,
    dataQualityControlChecks,
    dispatch,
    dataQualityControlCheck,
  ]);

  useEffect(() => {
    if ((status || statusEdit) && reloadSale) {
      onReloadSale();

      dispatch(setReloadSale(false));
      dispatch(clearStatus());
      dispatch(clearAllData());
    }
  }, [dispatch, onReloadSale, reloadSale, status, statusEdit]);

  useEffect(() => {
    if (dataBtnDisabled && changeBtns) {
      dispatch(setChangeBtns(false));
      changeBtnDisabled(dataBtnDisabled);
    }
  }, [btnsDisable, changeBtnDisabled, changeBtns, dataBtnDisabled, dispatch]);

  if (!activity) {
    return "";
  }

  const qualityControlCheck = findQualityControlCheck(
    activity,
    dataQualityControlChecks
  );

  const { id, type } = activity;

  return (
    <>
      <Row lg={12} xs={12}>
        <Col>
          <QualityControlCheck
            qualityControlCheck={qualityControlCheck}
            activity={activity}
            btnsDisable={btnsDisable ?? []}
          />
        </Col>
      </Row>

      <Col className="general_information">
        <div className="saleSubWorkflowHeading">
          {type}
          <span className={"order-number-small"}> {id}</span>
        </div>
      </Col>
    </>
  );
};

WorkflowActivityTitle.propTypes = {
  qualityControlChecks: PropTypes.array,
  activity: PropTypes.object.isRequired,
  btnsDisable: PropTypes.array,
  onReloadSale: PropTypes.func,
  changeBtnDisabled: PropTypes.func,
};

WorkflowActivityTitle.defaultProps = {
  qualityControlChecks: [],
  activity: null,
  btnsDisable: [],
};

export default WorkflowActivityTitle;
