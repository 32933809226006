import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, Col, Row, Table } from "react-bootstrap";
import LoadingSpinnerLayer from "../../../LoadingSpinnerLayer";
import {
  LoadDataField,
  SendDataUpdate,
  UpdateSaleCommissions,
} from "../Actions/LoadDataField";
import AlertMessage from "../../../Utils/AlertMessage";
import {
  SALE_COMMISSION_TYPE_REVIEWS,
  SALE_COMMISSION_TYPE_SALES,
} from "../../../../global/const";
import Util from "../../../../Util";
import LoadingLayer from "../../../LoadingLayer";

const TableResultSaleCommissions = ({ file, type, onResponse }) => {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState([]);

  useEffect(() => {
    const loadResults = async () => {
      if (results.length === 0 && loading) {
        const response = await LoadDataField(file);

        if (response.length > 0) {
          const data = await SendDataUpdate(
            response,
            type,
            UpdateSaleCommissions
          );

          setResults(data);
          onResponse(true);
        }

        setLoading(false);
      }
    };

    loadResults();
  }, [file, loading, results, onResponse, type]);

  const renderRow = () => {
    return results.map((item) => {
      return (
        <tr key={item.id}>
          <td>{item.date}</td>
          <td>{item.name}</td>
          <td>{type}</td>
          <td>{item.typeProduct}</td>
          <td>{item.currency}</td>
          <td>{item.taxPercentage}</td>
          <td>{item.totalInvoiceExtGst}</td>
          <td>{item.totalInvoice}</td>
          <td>{item.xeroCode}</td>
          <td>{item.onlineReference}</td>
          <td>{item.status}</td>
          <td>{item.employeeName}</td>
          <td>{item.employeeLocation}</td>
          {type === SALE_COMMISSION_TYPE_SALES && (
            <td>{item.commissionPercentage}</td>
          )}
          {type === SALE_COMMISSION_TYPE_REVIEWS && (
            <td>{item.commissionBase}</td>
          )}
          <td>{Util.numberDecimal(item.commissionValue)}</td>
          <td>{item.paymentDateDay}</td>
          <td>{item.paymentDateMonth}</td>
          <td>{item.paymentDateYear}</td>
          <td className={item.responseStatus}>{item.responseMessage}</td>
        </tr>
      );
    });
  };

  const renderTable = () => {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Sale Date</th>
            <th>Name</th>
            <th>Type Commission</th>
            <th>Type of Product</th>
            <th>Currency</th>
            <th>Tax percentage</th>
            <th>Total Invoice (Exc Tax)</th>
            <th>Total Invoice (Inc Tax)</th>
            <th>Xero Code</th>
            <th>Online Reference</th>
            <th>Status</th>
            <th>Employee Name</th>
            <th>Employee Location</th>
            {type === SALE_COMMISSION_TYPE_SALES && (
              <th>Commission percentage</th>
            )}
            {type === SALE_COMMISSION_TYPE_REVIEWS && <th>Commission base</th>}
            <th>
              {type === SALE_COMMISSION_TYPE_SALES && "Commission value"}
              {type === SALE_COMMISSION_TYPE_REVIEWS && "Review Value"}
            </th>
            <th>Payment date (day)</th>
            <th>Payment date (month)</th>
            <th>Payment date (year)</th>
            <th>Response</th>
          </tr>
        </thead>
        <tbody>{renderRow()}</tbody>
      </Table>
    );
  };

  return (
    <Card className="card-sale-commissions">
      <Card.Body>
        <Card.Title>Results sale commissions</Card.Title>
        <Row>
          {loading ? (
            <Col className="loading-process">
              <LoadingLayer />
            </Col>
          ) : null}

          {results.length > 0 ? renderTable() : null}

          {results.length === 0 && !loading ? (
            <Col>
              <AlertMessage
                type="warning"
                message={"The file is empty"}
                infoHeading=""
                autoClose={false}
              />
            </Col>
          ) : null}
        </Row>
      </Card.Body>
    </Card>
  );
};

TableResultSaleCommissions.propTypes = {
  file: PropTypes.any.isRequired,
  onResponse: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

TableResultSaleCommissions.defaultProps = {
  type: SALE_COMMISSION_TYPE_SALES,
};

export default TableResultSaleCommissions;
