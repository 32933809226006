import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Card, Col, Form, FormLabel, Row } from "react-bootstrap";
import AddNewExchange from "../AddNewExchange/AddNewExchange";
import DividerDashed from "../../Divider/DividerDashed";
import Util from "../../../Util";

const SaleCreatePricesData = ({ saleData, taxPercentage, onResponse }) => {
  const [sale, setSale] = useState(saleData);

  const listAccountCurrency =
    Util.getStorageParameter("REACT_APP_ACCOUNTS_CURRENCY").split(",");
  const listAccountInvoiceStatus =
    Util.getStorageParameter("REACT_APP_INVOICE_STATUS").split(",");

  const handleChange = (e) => {
    const data = sale;

    const { value, name } = e.target;

    data[name] = value;

    setSale((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    onResponse(value, name);
  };

  useEffect(() => {
    if (sale.taxPercentage !== taxPercentage) {
      setSale((prevState) => ({
        ...prevState,
        taxPercentage: taxPercentage,
      }));

      onResponse("taxPercentage", taxPercentage);
    }
  }, [sale, taxPercentage, onResponse]);

  return (
    <Card className="mt-2">
      <Card.Body>
        <Card.Title>Prices Information</Card.Title>
        <Row>
          <Col sm="6" lg="6">
            <Form.Group className="form-group">
              <FormLabel>Tax percentage</FormLabel>
              <Form.Control
                type="number"
                name="taxPercentage"
                value={sale.taxPercentage ?? ""}
                onChange={handleChange}
                required
                mim={0}
                max={100}
              />
              <Form.Control.Feedback type="invalid">
                Tax percentage is required and value is 0 to 100
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm="12" lg="3">
            <Form.Group className="form-group">
              <FormLabel>Total Diamond(s)</FormLabel>
              <Form.Control
                type="number"
                name="totalDiamondWogst"
                value={sale.totalDiamondWogst ?? ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm="12" lg="3">
            <Form.Group className="form-group">
              <FormLabel>Total Jewellery</FormLabel>
              <Form.Control
                type="number"
                name="totalSettingWogst"
                value={sale.totalSettingWogst ?? ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm="12" lg="3">
            <Form.Group className="form-group">
              <FormLabel>Express Manufacturing</FormLabel>
              <Form.Control
                type="number"
                name="expressManufacturingWogst"
                value={sale.expressManufacturingWogst ?? ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm="12" lg="3">
            <Form.Group className="form-group">
              <FormLabel>Engraving</FormLabel>
              <Form.Control
                type="number"
                name="engravingWogst"
                value={sale.engravingWogst ?? ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm="12" lg="3">
            <Form.Group className="form-group">
              <FormLabel>Total Invoice</FormLabel>
              <Form.Control
                type="number"
                name="totalIncGst"
                value={sale.totalIncGst ?? ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm="12" lg="3">
            <Form.Group className="form-group">
              <FormLabel>Balance</FormLabel>
              <Form.Control
                type="number"
                name="balance"
                value={sale.balance ?? ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col sm="12" lg="3">
            <Form.Group className="form-group">
              <FormLabel>Order Currency</FormLabel>
              <Form.Select
                onChange={(e) => handleChange(e)}
                value={sale.orderCurrency ?? ""}
                name="orderCurrency"
              >
                <option value=""></option>
                {listAccountCurrency.map((listAccountCurrency) => {
                  return (
                    <option
                      key={listAccountCurrency}
                      value={listAccountCurrency}
                    >
                      {listAccountCurrency}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm="12" lg="3">
            <Form.Group className="form-group">
              <FormLabel>Invoice Status</FormLabel>
              <Form.Select
                onChange={(e) => handleChange(e)}
                value={sale.invoice ?? ""}
                name="invoice"
              >
                <option value=""></option>
                {listAccountInvoiceStatus.map((listInvoiceStatus) => {
                  return (
                    <option key={listInvoiceStatus} value={listInvoiceStatus}>
                      {listInvoiceStatus}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <DividerDashed />
        <AddNewExchange onResponse={onResponse} />
      </Card.Body>
    </Card>
  );
};

SaleCreatePricesData.propTypes = {
  saleData: PropTypes.any.isRequired,
  onResponse: PropTypes.func.isRequired,
};

export default SaleCreatePricesData;
