import axios from "axios";
import { sprintf } from "sprintf-js";
import * as globalConst from '../../../global/const';
import Auth from "../../../Auth";
import Util from "../../../Util";

const CancelToken = axios.CancelToken;
let cancel;

export const getEngagementRingPictureChangeClawMetal = async (
    sale,
    clawMetal
) => {
    try {
        const { saleType, ringPreference } = sale

        if (!Util.wordInArray(saleType, ['engagement ring'])) {
            return null
        }

        if (ringPreference === 'Custom') {
            return null
        }

        if (cancel !== undefined) {
            cancel();
        }

        const url = sprintf(
            '%s%s',
            Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN"),
            globalConst.REACT_APP_NOVITAERP_API_PRODUCT_ENGAGEMENT_RING_CLAW_METAL
        )

        return await axios.get(
            url,
            {
                params: {
                    ringPreference: ringPreference,
                    clawMetal: clawMetal
                },
                cancelToken: new CancelToken((c) => {
                    cancel = c;
                }),
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            }
        ).then(apiResponse => {
            const { data } = apiResponse
            const { value } = data

            return value
        }).catch(() => {
            return null
        })
    } catch (error) {
        return null
    }
}