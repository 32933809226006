import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Modal, Row } from "react-bootstrap";

const ConfirmSaveForm = ({ message, labelCancel, labelSave, onResponse }) => {
  const [showModal, setShowModal] = useState(true);

  const closeModal = (value) => {
    setShowModal(false);

    onResponse(value);
  };

  return (
    <Modal show={showModal} size="md" centered>
      <Modal.Header>{message}</Modal.Header>

      <Modal.Body>
        <Row lg={12}>
          <Col lg={6}>
            <Button
              variant="secondary"
              className="btn btn-default confirm-btn"
              onClick={() => closeModal(false)}
            >
              {labelCancel}
            </Button>
          </Col>
          <Col lg={6}>
            <Button
              variant="primary"
              className="btn btn-default disagree-btn"
              onClick={() => closeModal(true)}
            >
              {labelSave}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

ConfirmSaveForm.propTypes = {
  message: PropTypes.string.isRequired,
  onResponse: PropTypes.func.isRequired,
  labelCancel: PropTypes.string.isRequired,
  labelSave: PropTypes.string.isRequired,
};

ConfirmSaveForm.defaultProps = {
  labelCancel: "Discard Changes",
  labelSave: "Continue",
};

export default ConfirmSaveForm;
