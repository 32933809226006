import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios";
import {
  API_STATUS_FAILED,
  API_STATUS_IDLE,
  API_STATUS_PENDING,
  API_STATUS_SUCCESS,
  APPOINTMENTS_LIST_TYPE_DEFAULT,
  APPOINTMENTS_LIST_TYPE_SEARCH,
} from "../constants";

// eslint-disable-next-line no-extend-native
Array.prototype.unique = function () {
  var a = this.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i].id === a[j].id) a.splice(j--, 1);
    }
  }

  return a;
};

const initialState = {
  error: null,
  appointments: [],
  searchResults: [],
  totalAppointments: 0,
  status: API_STATUS_IDLE,
  appointmentListType: APPOINTMENTS_LIST_TYPE_DEFAULT,
  selectedAppointment: null,
  filterColumns: [],
  filterText: "",
  filterResultsCount: 0,
  minCreatedDate: 0,
  maxCreatedDate: 0,
  createdSort: "desc",
  createAppointmentShow: false,
  appointmentCreateFind: [],
  advanceFilter: false,
};

export const fetchAppointments = createAsyncThunk(
  "/customers/appointments",
  async (args, thunkAPI) => {
    const { maxResults, page, cancelTokenSource, createdSort } = args;
    try {
      const response = await axios.get(
        `/api/v1/customers/appointments?max_results=${maxResults}&page=${page}&order_by=created&order_type=${
          createdSort ?? "DESC"
        }`,
        { cancelToken: cancelTokenSource?.token }
      );

      response.data.createdSort = createdSort;

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchAppointment = createAsyncThunk(
  "/customers/appointment/appointmentId",
  async (args, thunkAPI) => {
    try {
      const response = await axios.get(`/api/v1/customers/appointment/${args.id}`);
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchLatestAppointmentByCustomerId = createAsyncThunk(
  "/customers/appointment/customer/customerId",
  async (args, thunkAPI) => {
    try {
      const response = await axios.get(`/api/v1/customers/appointment/customer/${args.customerId}`);
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const searchAppointments = createAsyncThunk(
  "/customers/appointments/filter",
  async (args, thunkAPI) => {
    const {
      columns,
      filterText,
      cancelToken,
      returnEmptyValues,
      minDate,
      maxDate,
      createdSort,
      exportCSV,
      page = 1,
      maxResults,
      advanceFilter,
    } = args;

    try {
      const response = await axios.post(
        `/api/v1/customers/appointments/advanced-filter`,
        {
          columns: columns,
          text: filterText,
          returnEmptyValues: returnEmptyValues,
          minDate: minDate === 0 ? undefined : minDate,
          maxDate: maxDate === 0 ? undefined : maxDate,
          createdSort: createdSort ?? "DESC",
          exportCSV: exportCSV,
          page: page ?? 1,
          maxResults: maxResults ?? 100,
          advanceFilter: advanceFilter,
        },
        { cancelToken: cancelToken?.token }
      );

      const { status = null } = response;
      const { data = {} } = response;
      const { rows } = data;
      const { count } = data;

      if (Object.keys(rows).length > 0 && status === 200) {
        return {
          data: rows,
          columns,
          filterText,
          count: count,
          page: page ?? 1,
          advanceFilter,
        };
      }

      return {
        data: [],
        columns,
        filterText,
        count: count,
        page: page,
        advanceFilter,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const findAppointments = createAsyncThunk(
  "/appointments/find",
  async (args, thunkAPI) => {
    const {
      columns,
      filterText,
      cancelToken,
      returnEmptyValues,
      minDate,
      maxDate,
      createdSort,
      exportCSV,
      page,
      maxResults,
      advanceFilter,
    } = args;

    try {
      const response = await axios.post(
        `/api/v1/customers/advanced-filter`,
        {
          columns: columns,
          text: filterText,
          returnEmptyValues: returnEmptyValues,
          minDate: minDate === 0 ? undefined : minDate,
          maxDate: maxDate === 0 ? undefined : maxDate,
          createdSort: createdSort ?? "DESC",
          exportCSV: exportCSV,
          page: page ?? 1,
          maxResults: maxResults ?? 100,
          advanceFilter: advanceFilter,
        },
        { cancelToken: cancelToken?.token }
      );

      const { status = null } = response;
      const { data = {} } = response;
      const { rows } = data;

      if (Object.keys(rows).length > 0 && status === 200) {
        return rows;
      }

      return [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const appointmentsSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {
    clear(state) {
      state.error = initialState.error;
      state.status = initialState.status;
      state.appointments = initialState.appointments;
      state.appointmentListType = initialState.appointmentListType;
      state.totalAppointments = initialState.totalAppointments;
      state.searchResults = initialState.searchResults;
      state.minCreatedDate = initialState.minCreatedDate;
      state.maxCreatedDate = initialState.maxCreatedDate;
      state.filterColumns = initialState.filterColumns;
      state.filterText = initialState.filterText;
      state.createdSort = initialState.createdSort;
      state.createAppointmentShow = false;
      state.appointmentCreateFind = initialState.appointmentCreateFind;
      state.advanceFilter = initialState.advanceFilter;
    },
    resetSearch(state, action) {
      state.appointmentListType = APPOINTMENTS_LIST_TYPE_DEFAULT;
      state.filterColumns = action.payload;
    },
    selectAppointment(state, action) {
      state.selectedAppointment = action.payload;
    },
    setMinAppointmentDate(state, action) {
      state.minCreatedDate = action.payload;
    },
    setMaxAppointmentDate(state, action) {
      state.maxCreatedDate = action.payload;
    },
    setAppointmentCreatedSort(state, action) {
      state.createdSort = action.payload;
    },
    resetAppointments(state, action) {
      state.appointments = [];
      state.totalAppointments = 0;
    },
    createAppointmentShowAction(state, action) {
      state.createAppointmentShow = action.payload;
    },
    resetAppointmentCreateFind(state) {
      state.appointmentCreateFind = initialState.appointmentCreateFind;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchAppointments.fulfilled, (state, action) => {
      state.totalAppointments = action.payload.count;
      state.appointments = [...state.appointments, ...action.payload.rows].unique();
      state.appointmentListType = APPOINTMENTS_LIST_TYPE_DEFAULT;
      state.error = null;
      state.status = API_STATUS_SUCCESS;
      state.searchResults = [];
      state.filterColumns = [];
      state.createAppointmentShow = false;
    });
    builder.addCase(fetchAppointments.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(fetchAppointments.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    // search Appointments
    builder.addCase(searchAppointments.fulfilled, (state, action) => {
      state.appointmentListType = APPOINTMENTS_LIST_TYPE_SEARCH;
      state.searchResults = [
        ...state.searchResults,
        ...action.payload.data,
      ].unique();

      if (action.payload.page <= 1) {
        state.searchResults = [...[], ...action.payload.data].unique();
      }

      state.filterColumns = [...action.payload.columns];
      state.error = null;
      state.status = API_STATUS_SUCCESS;
      state.appointments = state.searchResults;
      state.totalAppointments = action.payload.count;
      state.filterResultsCount = action.payload.count;
      state.filterText = action.payload.filterText;
      state.createAppointmentShow = false;
      state.advanceFilter = action.payload.advanceFilter;
    });
    builder.addCase(searchAppointments.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(searchAppointments.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    builder.addCase(findAppointments.fulfilled, (state, action) => {
      state.appointmentCreateFind = action.payload;
      state.status = API_STATUS_SUCCESS;
    });
    builder.addCase(findAppointments.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(findAppointments.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    builder.addCase(fetchAppointment.fulfilled, (state, action) => {
      const findAppointment = action.payload;
      const appointmentIndex = state.appointments.findIndex(
        (appointment) => appointment.id === findAppointment.id
      );
      if (appointmentIndex !== -1) {
        state.appointments[appointmentIndex] = findAppointment;
        state.selectedAppointment = state.appointments[appointmentIndex];
      }
      state.status = API_STATUS_SUCCESS;      
    });
    builder.addCase(fetchAppointment.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(fetchAppointment.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    builder.addCase(fetchLatestAppointmentByCustomerId.fulfilled, (state, action) => ({
      appointmentListType: APPOINTMENTS_LIST_TYPE_DEFAULT,
      searchResults: state.searchResults,
      error: null,
      status: API_STATUS_SUCCESS,
      appointments: [...state.appointments, action.payload],
      totalAppointments: state.totalAppointments + 1,
    }));
    builder.addCase(fetchLatestAppointmentByCustomerId.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(fetchLatestAppointmentByCustomerId.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
  },
});

export const {
  clear,
  resetSearch,
  selectAppointment,
  setMinAppointmentDate,
  setMaxAppointmentDate,
  setAppointmentCreatedSort,
  resetAppointments,
  createAppointmentShowAction,
  resetAppointmentCreateFind,
} = appointmentsSlice.actions;

export default appointmentsSlice.reducer;
