import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { clearLogin, fetchLoginVerify2af } from "../../store/slices";
import LoadingLayer from "../LoadingLayer";
import FlashMessages from "../FlashMessages";
import Auth from "../../Auth";
import { findUserTwoFactorAuthenticatorValidated } from "./Action";

const Verify2fa = ({ onResponse, secret, data }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [twoFactorAuthenticatorValidated] = useState(
    findUserTwoFactorAuthenticatorValidated(data)
  );
  const inputRef = useRef(null);

  const { verify } = useSelector((state) => state.login);
  const [token, setToken] = useState("");

  useEffect(() => {
    if (verify) {
      setShowModal(false);
      Auth.authenticate(data);
      window.location.href = "/dashboard";
    }

    if (verify === false && !error) {
      setError(["Error in code, please try again"]);
    }

    if (inputRef.current) {
      inputRef.current.focus();
    }
  });

  const closeModal = (value) => {
    setShowModal(false);
    setError(null);
    dispatch(clearLogin());

    onResponse(value);
  };

  const onChangeInput = (event) => {
    let { value } = event.target;

    value = value.toString();
    value = value.replace(/,/g, ".").replace(/[^0-9.]/g, "");

    setToken(value);
  };

  const verify2faHandle = async () => {
    setLoading(true);
    setError(null);

    await dispatch(
      fetchLoginVerify2af({
        secret: secret,
        token: token,
      })
    );

    setLoading(false);
  };

  const handleKeyDown = (event) => {
    if (loading) {
      return;
    }

    if (event.key === "Enter") {
      verify2faHandle(true);
    }
  };

  return (
    <Modal show={showModal} size="md" centered>
      {loading && (
        <Col className="loading-process">
          <LoadingLayer />
        </Col>
      )}

      <Modal.Header>Two factor authentication</Modal.Header>

      <Modal.Body>
        <Row>
          {Array.isArray(error) && (
            <FlashMessages error_messages={error ?? []} />
          )}
        </Row>

        {!twoFactorAuthenticatorValidated && (
          <p>
            Please visit the link below to register
            <br />
            <a
              href={process.env.REACT_APP_NOVITAERP_WEB_DOMAIN + "/login"}
              target="_blank"
            >
              {process.env.REACT_APP_NOVITAERP_WEB_DOMAIN + "/login"}
            </a>
            <br />
            Once registered, come back and try to login again
          </p>
        )}

        {twoFactorAuthenticatorValidated && (
          <p>Enter 6 digit code from Google Authenticator app</p>
        )}

        <Row lg={12} className="mt-1">
          {twoFactorAuthenticatorValidated && (
            <Col>
              <Form.Control
                ref={inputRef}
                onKeyDown={handleKeyDown}
                type="text"
                name={"token"}
                value={token ?? ""}
                onChange={onChangeInput}
                placeholder={"Pease enter 6 digit code"}
              />
            </Col>
          )}
        </Row>

        <Row lg={12} className="mt-1">
          <Col lg={6}>
            <Button
              variant="secondary"
              className="btn btn-default confirm-btn"
              onClick={() => closeModal(false)}
            >
              Cancel
            </Button>
          </Col>
          <Col lg={6}>
            {twoFactorAuthenticatorValidated && (
              <Button
                variant="primary"
                className="btn btn-default disagree-btn"
                onClick={() => verify2faHandle(true)}
              >
                Sign in
              </Button>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

Verify2fa.propTypes = {
  secret: PropTypes.string.isRequired,
  onResponse: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default Verify2fa;
