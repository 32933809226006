import React from "react";
import { useNavigate } from "react-router-dom";

const NavigationContext = React.createContext(null);

export const NavigationProvider = ({ children }) => {
  const navigate = useNavigate();

  return (
    <NavigationContext.Provider value={navigate}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => React.useContext(NavigationContext);
