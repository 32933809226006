import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios";
import {
  API_STATUS_SUCCESS,
  API_STATUS_PENDING,
  API_STATUS_FAILED,
} from "../constants";
import {
  ExistSaleDiamonds,
  GetSaleDiamonds,
  GetSaleForDetails,
} from "../../components/sales/detail/Actions/GetSaleDiamonds";
import { findWorkflowActivity } from "../../components/sales/saleWorkflow/edit/SaleWorkflowEditReturn/Actions/WorkflowActivity";
import Auth from "../../Auth";

const initialState = {
  error: null,
  workflowReturn: {},
  status: "",
  statusGD: "",
  errorMessage: "",
  saleDiamonds: false,
  salesExchanges: [],
};

export const fetchWorkflowReturn = createAsyncThunk(
  "/workflow-activity/workflow/return",
  async (args, thunkAPI) => {
    try {
      const response = await axios.put(
        `/api/v1/workflow-activity/workflow/return/${args.id}`,
        {
          type: args.type,
          saleId: args.saleId,
        }
      );

      const { data } = response;
      const { value = null } = data;

      if (value) {
        return value;
      }

      return {};
    } catch (error) {
      const { response = { data: {} } } = error;
      const { data = { error: error.message } } = response;

      return thunkAPI.rejectWithValue(data.error);
    }
  }
);

export const fetchSaleExcahnges = createAsyncThunk(
  "/workflow-activity/sale/exchange",
  async (args, thunkAPI) => {
    try {
      const response = await axios.get(
        `/api/v1/workflow-activity/sale/exchange?search=${args}`
      );

      const { data } = response;
      const { value = null } = data;

      if (value) {
        return value;
      }

      return [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchWorkflowReturnSendGD = createAsyncThunk(
  "/workflow-activity/workflow/return/send/gd",
  async (args, thunkAPI) => {
    try {
      const saleDiamonds = GetSaleDiamonds(args.sale).saleDiamondModels;
      const WorkflowActivity = findWorkflowActivity(args.sale);
      const { id } = args.sale;
      const { workflowReturn = {} } = args;
      const { stepTwoQuestions = {} } = workflowReturn;
      const { diamonds = false, jewellery = false } = stepTwoQuestions;

      let products = [];

      if (saleDiamonds) {
        saleDiamonds.forEach((saleDiamond) => {
          if (saleDiamond?.diamondProductModel?.certNo && diamonds) {
            products.push({
              reference: saleDiamond?.diamondProductModel?.certNo,
              is_diamond: true,
            });
          }
        });
      }

      if (WorkflowActivity) {
        if (WorkflowActivity?.stockNumber && jewellery) {
          products.push({
            reference: WorkflowActivity?.stockNumber,
            is_diamond: false,
          });
        }
      }

      const user = Auth.getAuthenticatedUser();

      const response = await axios.put(
        `/api/v1/workflow-activity/workflow/return/${args.id}/send/gd`,
        {
          products: products,
          workflowReturn: args.workflowReturn,
          email: user.email,
          saleId: id,
        }
      );

      const { data } = response;
      const { value = null } = data;

      if (value) {
        return value;
      }

      return {};
    } catch (error) {
      const { response = {} } = error;
      const { data = {} } = response;
      const errorMessage = data?.error;

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const saleHaveTwoDiamonds = createAsyncThunk(
  "/workflow-activity/workflow/return/find/diamonds",
  async (sale) => {
    const existSaleDiamond = ExistSaleDiamonds(sale);
    let saleDiamonds = [];

    if (existSaleDiamond) {
      saleDiamonds = GetSaleDiamonds(sale).saleDiamondModels;
    }

    if (saleDiamonds.length >= 1) {
      return saleDiamonds;
    }

    if (saleDiamonds.length === 0) {
      const saleDiamondForDetails = GetSaleForDetails(sale);

      if (saleDiamondForDetails.length >= 1) {
        return saleDiamondForDetails;
      }
    }

    return [];
  }
);

export const workflowReturnSlice = createSlice({
  name: "workflowReturn",
  initialState,
  reducers: {
    clear(state) {
      state.error = initialState.error;
      state.status = initialState.status;
      state.statusGD = initialState.statusGD;
      state.workflowReturn = initialState.workflowReturn;
      state.saleDiamonds = false;
    },
    clearWorkflowReturn(state) {
      state.workflowReturn = {};
    },
    clearSalesExchanges(state) {
      state.salesExchanges = initialState.salesExchanges;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchWorkflowReturn.fulfilled, (state, action) => {
      state.error = null;
      state.status = API_STATUS_SUCCESS;
      state.workflowReturn = action.payload;
    });
    builder.addCase(fetchWorkflowReturn.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(fetchWorkflowReturn.pending, (state) => {
      state.status = API_STATUS_PENDING;
    });
    builder.addCase(fetchWorkflowReturnSendGD.fulfilled, (state, action) => {
      state.error = null;
      state.statusGD = API_STATUS_SUCCESS;
      state.workflowReturn = action.payload;
    });
    builder.addCase(fetchWorkflowReturnSendGD.rejected, (state, action) => {
      state.error = action.payload;
      state.statusGD = API_STATUS_FAILED;
    });
    builder.addCase(fetchWorkflowReturnSendGD.pending, (state) => {
      state.statusGD = API_STATUS_PENDING;
    });
    builder.addCase(saleHaveTwoDiamonds.fulfilled, (state, action) => {
      state.saleDiamonds = action.payload;
    });
    builder.addCase(fetchSaleExcahnges.fulfilled, (state, action) => {
      state.error = null;
      state.status = API_STATUS_SUCCESS;
      state.salesExchanges = action.payload;
    });
    builder.addCase(fetchSaleExcahnges.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
      state.salesExchanges = initialState.salesExchanges;
    });
    builder.addCase(fetchSaleExcahnges.pending, (state) => {
      state.status = API_STATUS_PENDING;
    });
  },
});

export const {
  clear,
  clearWorkflowReturn,
  clearSalesExchanges,
  findSaleDiamond,
} = workflowReturnSlice.actions;

export default workflowReturnSlice.reducer;
