import React, { useState } from "react";
import PropTypes from "prop-types";
import { BTN_QUALITY_CONTROL_CHECK_START } from "../../../../global/const";
import { useDispatch, useSelector } from "react-redux";
import AlertMessage from "../../../Utils/AlertMessage";
import ConfirCreateQualityControl from "./ConfirCreateQualityControl";
import {
  fetchQualityControlCheck,
} from "../../../../store/slices";
import { API_STATUS_FAILED } from "../../../../store/constants";
import { Link } from "react-router-dom";

const QualityControlStart = ({
  qualityControlCheck,
  activity,
  btnsDisable,
  openEditModal,
}) => {
  const dispatch = useDispatch();

  const { status, error } = useSelector((state) => state.qualityControlCheck);

  const [activityAction, setActivityAction] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  if (qualityControlCheck) {
    return "";
  }

  const disabledBTNStart = btnsDisable.includes(
    BTN_QUALITY_CONTROL_CHECK_START
  );

  const createQualityControl = (event) => {
    event.preventDefault();

    if (disabledBTNStart) {
      return;
    }

    setShowConfirm(true);
  };

  const { id } = activity;

  const hideAlert = () => {
    setActivityAction(null);
  };

  const renderError = () => {
    if (!activityAction) {
      return;
    }

    return (
      <>
        <AlertMessage
          message={error ?? "Error to create quality control check"}
          autoClose={false}
          type="danger"
          onResponse={hideAlert}
        />
      </>
    );
  };

  const onResponseConfirm = async (response) => {
    if (!response.value) {
      setActivityAction(null);
    }

    if (response.value) {
      setActivityAction(response.activity);
      await dispatch(await fetchQualityControlCheck({ activityId: id }));
    }

    setShowConfirm(false);
    openEditModal(response.value);
  };

  return (
    <>
      {showConfirm && (
        <ConfirCreateQualityControl
          onResponse={onResponseConfirm}
          activity={activity}
        />
      )}
      {status === API_STATUS_FAILED && renderError()}
      <span>Quality Control Check: </span>
      <Link
        to=""
        className={disabledBTNStart ? "disabled-btn" : ""}
        onClick={(event) => createQualityControl(event)}
      >
        Start Quality Control Check
      </Link>
    </>
  );
};

QualityControlStart.propTypes = {
  qualityControlCheck: PropTypes.object,
  activity: PropTypes.object.isRequired,
  btnsDisable: PropTypes.array,
  openEditModal: PropTypes.func,
};

QualityControlStart.defaultProps = {
  qualityControlCheck: null,
  btnsDisable: [],
};

export default QualityControlStart;
