import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";

const AlertMessage = ({
  message,
  type,
  infoHeading,
  autoClose,
  timeClose,
  onResponse,
}) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (autoClose) {
      const autoCloseAlert = async () => {
        if (show) {
          setTimeout(() => {
            if (onResponse) {
              onResponse(false);
            }
            setShow(false);
          }, timeClose);
        }
      };

      autoCloseAlert();
    }

    return () => {};
  }, [message, type, infoHeading, autoClose, timeClose, show, onResponse]);

  const handleClose = () => {
    if (onResponse) {
      onResponse(false);
    }
    setShow(false);
  };

  return show ? (
    <Alert variant={type} onClose={handleClose} dismissible>
      <Alert.Heading>{infoHeading}</Alert.Heading>
      <p>{message}</p>
    </Alert>
  ) : null;
};

AlertMessage.propTypes = {
  type: PropTypes.string.isRequired,
  infoHeading: PropTypes.string,
  message: PropTypes.string.isRequired,
  autoClose: PropTypes.bool,
  timeClose: PropTypes.number,
  onResponse: PropTypes.func,
};

AlertMessage.defaultProps = {
  type: "success",
  infoHeading: "",
  autoClose: false,
  timeClose: 5000,
  onResponse: null,
};

export default AlertMessage;
