import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  API_STATUS_PENDING,
  CUSTOMERS_LIST_TYPE_DEFAULT,
  CUSTOMERS_LIST_TYPE_SEARCH,
} from "../../../store/constants";
import {
  addCustomerCustomizedLists,
  batchAddCustomerCustomizedLists,
  removeCustomerCustomizedLists,
} from "../../../store/slices";
import CustomersListHeader from "./header";
import LoadingLayer from "../../LoadingLayer";
import { dateFormatCustom } from "../../../utils/dateUtils";

export default function DefaultList({
  loadedCount,
  hasMore,
  onNextPage,
  onNextPageFilter,
  customerListType,
  filterChanged,
  customers,
  selectedCustomer,
  onSelected,
  extractEmployeeNames,
}) {
  const dispatch = useDispatch();
  const [selectedList, setSelectedList] = useState("");
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);

  const { listCustomers, lists, status } = useSelector(
    (state) => state.customerCustomizedLists
  );

  const handleCheckAll = (e) => {
    // Check list type
    if (selectedList.length <= 0) {
      alert(
        "You need to select a personalised list from the dropdown to add elements to it."
      );
      return;
    }

    const ccs = [...customers.map((e) => e.id)];

    const selected = getSelectedList();
    if (selected) {
      dispatch(
        batchAddCustomerCustomizedLists({
          listId: selected.id,
          customerIds: ccs,
        })
      );
    } else {
      setSelectedCustomerIds(ccs);
    }
  };

  const getSelectedList = () => {
    const selected = lists.filter((e) => e.name === selectedList);
    if (selected.length > 0) {
      return selected[0];
    }
    return null;
  };

  useEffect(() => {
    const selected = getSelectedList();
    if (selected) {
      const ccs = listCustomers[selected.id];
      setSelectedCustomerIds(ccs.map((e) => e.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList, lists, listCustomers]);

  const handleListChange = (e) => {
    setSelectedCustomerIds([]);
    setSelectedList(e.target.value);
  };

  const handleCustomerSelectChange = (customer, e) => {
    const selected = getSelectedList();
    if (e.target.checked) {
      if (selected) {
        dispatch(
          addCustomerCustomizedLists({
            listId: selected.id,
            customerId: customer.id,
          })
        );
      } else {
        setSelectedCustomerIds((selectedCustomerIds) => [
          ...selectedCustomerIds,
          customer.id,
        ]);
      }
    } else {
      if (selected) {
        dispatch(
          removeCustomerCustomizedLists({
            listId: selected.id,
            customerId: customer.id,
          })
        );
      } else {
        setSelectedCustomerIds((selectedCustomerIds) => [
          ...selectedCustomerIds.filter((e) => e !== customer.id),
        ]);
      }
    }
  };

  const getCustomerCity = (customer) => {
    if (
      customer.customerAppointments &&
      customer.customerAppointments.length > 0
    ) {
      return customer.customerAppointments[0].showroomCity;
    }
    return "";
  };

  return (
    <InfiniteScroll
      dataLength={loadedCount}
      next={() => {
        if (customerListType === CUSTOMERS_LIST_TYPE_DEFAULT) {
          onNextPage();
        }
        if (customerListType === CUSTOMERS_LIST_TYPE_SEARCH) {
          onNextPageFilter();
        }
      }}
      hasMore={hasMore}
      height={"calc(110vh - 200px)"}
    >
      {status === API_STATUS_PENDING && <LoadingLayer />}
      <table
        className="table table-striped table-sm table-hover overflow-scroll"
        id="table_sales"
      >
        <CustomersListHeader
          selectedList={selectedList}
          handleCheckAll={handleCheckAll}
          handleListChange={handleListChange}
          hasFilters={true}
          onFilterChange={filterChanged}
        />
        <tbody className="overflow-scroll">
          {customers.map((customer, index) => {
            return (
              <tr
                className={`customer_row ${
                  selectedCustomer?.id === customer.id ? "highlighted_row" : ""
                }`}
                key={customer.id}
                onClick={() => onSelected(customer)}
              >
                <td>
                  <div className="form-check custom-from-check">
                    <input
                      type="checkbox"
                      id="customer_check"
                      name="customer_check"
                      className="advanced_filter_empty_customer_name"
                      checked={selectedCustomerIds.includes(customer.id)}
                      onChange={(e) => {
                        handleCustomerSelectChange(customer, e);
                      }}
                    />
                  </div>
                </td>
                <td>{dateFormatCustom(customer.created)}</td>
                <td>{customer.name}</td>
                <td>{customer.email}</td>
                <td>{customer.phone}</td>
                <td>{getCustomerCity(customer)}</td>
                <td>{extractEmployeeNames(customer)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </InfiniteScroll>
  );
}
