import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import PostSaleRow from "../../../post_sale_services/PostSaleRow";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "../../../../axios";
import * as globalConst from "../../../../global/const";
import SearchIcon from "../../../../image/pink_search.png";
import "../../../../css/crm/crm.scss";
import "../../../../css/crm/customer_information.scss";
import Util from "../../../../Util";

export const AddPostSales = ({ show, onHide, customer }) => {
  const [sales, setSales] = useState([]);
  const [selectedSale, setSelectedSale] = useState(null);
  const [apiHasMore, setApiHasMore] = useState(true);

  const [filterParams, setFilterParams] = useState({
    page: 1,
    max_results: 100,
    order_by: "created",
    order_type: "DESC",
    sheetType: "ACTIVE",
    dateReceivedInBackOffice: "",
    generalSearch: "",
  });

  useEffect(() => {
    fetchData();
  }, [filterParams]);

  const handleRowSelection = (saleIndex) => {
    setSelectedSale(sales[saleIndex]);
  };

  const fetchMoreData = () => {
    setFilterParams((state) => ({ ...state, page: state.page + 1 }));
  };

  const fetchData = async () => {
    const url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_POST_SALE_SERVICES_LIST;
    const response = await axios.get(url, { params: { ...filterParams } });
    const data = response.data;
    if (filterParams.page === 1) {
      setSales(data.result.rows);
    } else {
      setSales((prevSales) => [...prevSales, ...data.result.rows]);
    }
    setApiHasMore(data.result.amount_results > 0);
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilterParams((state) => ({ ...state, generalSearch: value, page: 1 }));
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
      }}
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title>Search Post Sales</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="crm_component">
          <div className="general_search d-flex mb-2">
            <input
              type="text"
              placeholder="Search"
              className="form-control box-bg"
              onChange={(e) => handleFilterChange(e)}
            />
            <div className="input-group-append">
              <span className="input-group-text" id="basic-addon2">
                <img src={SearchIcon} alt="" />
              </span>
            </div>
          </div>
          <InfiniteScroll
            dataLength={sales.length}
            next={fetchMoreData}
            hasMore={apiHasMore}
            scrollableTarget="search_sales"
          >
            <div
              id="search_sales"
              style={{ height: "70vh", overflowY: "scroll", overflowX: "clip" }}
            >
              <table className="table table-striped table-sm table-hover">
                <thead className="thead-dark">
                  <tr className="titles">
                    <th className="created">Created</th>
                    <th className="onlineOrderNumber">Order Reference</th>
                    <th className="dateReceivedInBackOffice">
                      Date received in Back Office
                    </th>
                    <th className="dueDate">Due Date</th>
                    <th className="status">Status</th>
                    <th className="name">Name</th>
                    <th className="handledVia">Showroom</th>
                    <th className="destination">Fixing location</th>
                    <th className="jeweller">Jeweller</th>
                    <th className="customerNeedsToPay">
                      Customer needs to pay
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sales.map((sale, index) => {
                    return (
                      <PostSaleRow
                        key={sale.id}
                        sale={sale}
                        index={index}
                        handleRowSelection={handleRowSelection}
                        selected_sale={selectedSale}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </InfiniteScroll>
          <div className="d-flex justify-content-end mt-2">
            <Button
              className="me-2"
              variant="secondary"
              onClick={() => {
                onHide();
              }}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                onHide(selectedSale);
              }}
            >
              Select
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
