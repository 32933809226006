import TreeView, { flattenTree } from "react-accessible-treeview";
import { TreeViewIcon } from "./tree-icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  fetchCategories,
  selectCategory,
} from "../../../store/slices/categories";
import { faEdit, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateCategory from "./create-category";
import Auth from "../../../Auth";

export default function DocumentationCategories({ categorySelected }) {
  const { categories, error } = useSelector((state) => state.categories);
  const dispatch = useDispatch();

  const [treeState, setTreeState] = useState({ name: "", children: [] });
  const [isCreateCategoryOpen, setIsCreateCategoryOpen] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);
  const [canCreateCategory, setCanCreateCategory] = useState(false);

  const loadCanCreateCategory = async () => {
    const currentUser = await Auth.getAuthenticatedUser();
    if (currentUser.employeeModel.isLeader) {
      setCanCreateCategory(true);
      return;
    }
    setCanCreateCategory(Auth.isAuthorised("create_document_category"));
  };

  useEffect(() => {
    dispatch(fetchCategories());
    loadCanCreateCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (categories) {
      let root = {
        name: "",
        children: [],
      };

      for (const category of categories) {
        processCategories(category, root);
      }
      setTreeState(root);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  const processCategories = (category, root) => {
    //insert my self in the root
    const cat = {
      name: `${category.name} (${category.documents.length})${category.id}`,
      documents: category.documents,
      id: category.id,
      children: [],
    };
    root.children.push(cat);
    // call for each child
    category.documentCategories.forEach((e) => processCategories(e, cat));
  };

  const findCategoryById = (id, category) => {
    if (category.id === id) {
      return category;
    } else {
      if (!category.documentCategories) return null;
      for (const child of category.documentCategories) {
        const match = findCategoryById(id, child);
        if (match) {
          return match;
        }
      }
      return null;
    }
  };

  const handleSelectCategory = (category) => {
    for (const cat of categories) {
      if (cat.id === category.categoryId) {
        dispatch(selectCategory(cat));
        return;
      }
      const foundCategory = findCategoryById(category.categoryId, cat);
      if (foundCategory) {
        dispatch(selectCategory(foundCategory));
        return;
      }
    }
  };

  const handleEditCategory = (category) => {
    for (const cat of categories) {
      if (cat.id === category.categoryId) {
        setEditingCategory(cat);
        setIsCreateCategoryOpen(true);
        return;
      }
      const foundCategory = findCategoryById(category.categoryId, cat);
      if (foundCategory) {
        setEditingCategory(foundCategory);
        setIsCreateCategoryOpen(true);
        return;
      }
    }
  };

  function extractId(str) {
    const lastClosingBracketIndex = str.lastIndexOf(")");
    if (lastClosingBracketIndex !== -1) {
      const numberStr = str
        .slice(lastClosingBracketIndex + 1)
        .match(/\d+/)?.[0];
      if (numberStr) {
        const number = parseInt(numberStr);
        str =
          str.slice(0, lastClosingBracketIndex + 1) +
          str.slice(lastClosingBracketIndex + numberStr.length + 1);
        return [number, str];
      }
    }
    return [null, str];
  }

  const treeData = flattenTree(treeState).map((e) => {
    const [categoryId, name] = extractId(e.name);
    return { ...e, name, categoryId };
  });

  const canEditCategory = (category) => {
    const user = Auth.getAuthenticatedUser();
    // check if user.roles in clude ROLE_ADMIN and ROLE_SUPER_ADMIN
    if (
      user.roles.includes("ROLE_ADMIN") ||
      user.roles.includes("ROLE_SUPER_ADMIN")
    ) {
      return true;
    }

    if (user.employeeModel.isLeader) {
      return true;
    }

    // Find actual category
    let actualCategory;
    for (const cat of categories) {
      if (cat.id === category.categoryId) {
        actualCategory = cat;
        break;
      }
      const foundCategory = findCategoryById(category.categoryId, cat);
      if (foundCategory) {
        actualCategory = foundCategory;
        break;
      }
    }

    // check if user is owner of category
    if (user.employeeModel.id === actualCategory.employeeId) {
      return true;
    }
    return false;
  };

  return (
    <div className="m-2 ps-2 pr-2">
      <CreateCategory
        editingCategory={editingCategory}
        isOpen={isCreateCategoryOpen}
        handleClose={() => {
          setIsCreateCategoryOpen(false);
          setEditingCategory(undefined);
        }}
      />
      {error && (
        <div className="alert alert-danger" role="alert">
          {JSON.stringify(error)}
        </div>
      )}
      {canCreateCategory && (
        <div className="d-flex rounded border ml-2 mr-2 mb-2 p-1 justify-content-between align-items-center">
          <div className="small">Create category</div>
          <div
            onClick={() => {
              setIsCreateCategoryOpen(true);
            }}
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon size="lg" icon={faPlusCircle} />
          </div>
        </div>
      )}
      <div className="rounded border pt-2">
        <TreeView
          className="category-tree"
          data={treeData}
          aria-label="directory tree"
          nodeRenderer={({
            element,
            isBranch,
            isExpanded,
            getNodeProps,
            handleExpand,
            handleSelect,
            isSelected,
          }) => {
            const selectedStyle = isSelected
              ? {
                  backgroundColor: "#FFB7B2",
                  color: "white",
                }
              : {};
            return (
              // add rounded borders to style

              <div
                {...getNodeProps()}
                style={{
                  ...selectedStyle,
                  cursor: "pointer",
                  padding: "0.5rem 0.5rem 0.5rem 0.5rem",
                  borderRadius: "0.25rem",
                  marginRight: "0.5rem",
                }}
                onClick={(e) => {
                  handleSelect(e);
                  handleSelectCategory(element);
                }}
              >
                {isBranch ? (
                  <TreeViewIcon
                    isOpen={isExpanded}
                    handleExpand={handleExpand}
                  />
                ) : null}

                <span className="ps-1" style={{ cursor: "pointer" }}>
                  {element.name}
                </span>
                {canEditCategory(element) && (
                  <FontAwesomeIcon
                    style={{ cursor: "pointer", float: "right" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleEditCategory(element);
                    }}
                    className="ps-1"
                    icon={faEdit}
                  />
                )}
              </div>
            );
          }}
        />
      </div>
    </div>
  );
}
