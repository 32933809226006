import { useDispatch, useSelector } from "react-redux";
import DocumentsListHeader from "./header";
import { formatDate } from "../../../utils/dateUtils";
import { Button, Figure, Modal, Spinner } from "react-bootstrap";
import {
  filterDocumentsBy,
  resetDocumentsSearch,
} from "../../../store/slices/categories";
import axios from "../../../axios";
import Auth from "../../../Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import CreateFile from "./create-file";
import { useEffect, useState } from "react";

export function DocumentsList({ setSelectedDocument, selectedDocument }) {
  const { selectedCategory, documents } = useSelector(
    (state) => state.categories
  );
  const dispatch = useDispatch();

  const [isFileCreateOpen, setIsFileCreateOpen] = useState(false);
  const [editingDocument, setEditingDocument] = useState();
  const [downloadingFile, setDownloadingFile] = useState();
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [canCreateFile, setCanCreateFile] = useState(false);

  const loadCanCreateFile = async () => {
    const currentUser = await Auth.getAuthenticatedUser();
    if (currentUser.employeeModel.isLeader) {
      setCanCreateFile(true);
      return;
    }
    setCanCreateFile(Auth.isAuthorised("create_document_category"));
  };

  useEffect(() => {
    loadCanCreateFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDocumentClick = (document) => {
    setSelectedDocument(document);
  };

  const filterChanged = (e, columnName, returnEmptyValues) => {
    if (!returnEmptyValues) {
      // user unchecked some column, revert to earlier search
      dispatch(resetDocumentsSearch());
      return;
    }

    let filterText = e.target.value;
    if (filterText === "") {
      dispatch(resetDocumentsSearch());
    } else {
      dispatch(filterDocumentsBy({ columnName, text: filterText }));
    }
  };

  const handleDownload = async (e, event) => {
    event.stopPropagation();
    setDownloadingFile(e);
    try {
      const response = await axios.get(
        `/api/v1/documents/document/download/${e.id}`,
        {
          responseType: "arraybuffer",
          onDownloadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setDownloadProgress(percentCompleted);
          },
        }
      );
      const data = response.data;
      const url = window.URL.createObjectURL(new Blob([data]));

      // Create a link element to trigger the file download
      const link = document.createElement("a");
      link.href = url;
      link.download = e.filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the URL object to free up memory
      window.URL.revokeObjectURL(url);
      setDownloadingFile(null);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (e, event) => {
    event.stopPropagation();
    setEditingDocument(e);
    setIsFileCreateOpen(true);
  };

  const isDownloading = (file) => {
    return downloadingFile && downloadingFile.id === file.id;
  };

  const canEditFile = (file) => {
    // Super admins and admins can edit all files
    const user = Auth.getAuthenticatedUser();
    // check if user created this file
    if (user.employeeModel.id === file.uploadedById) {
      return true;
    }
    return Auth.isAuthorised("create_document_category");
  };

  return (
    <>
      <Modal
        size="lg"
        show={isFileCreateOpen}
        onHide={() => {
          setIsFileCreateOpen(false);
          setEditingDocument(null);
        }}
      >
        <CreateFile
          document={editingDocument}
          handleClose={() => {
            setIsFileCreateOpen(false);
            setEditingDocument(null);
          }}
        />
      </Modal>

      {canCreateFile && (
        <div className="d-flex rounded border ml-2 mr-2 mb-2 p-1 justify-content-end align-items-center">
          <div className="small me-2">Create file</div>
          <div
            onClick={() => {
              setIsFileCreateOpen(true);
            }}
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon color="#dfa4a3" size="lg" icon={faPlusCircle} />
          </div>
        </div>
      )}
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 90px)" }}
      >
        <table
          className="table table-striped table-sm table-hover overflow-scroll"
          id="documents-list"
        >
          <DocumentsListHeader onFilterChange={filterChanged} />
          <tbody>
            {selectedCategory &&
              documents &&
              documents.map((e) => {
                return (
                  <tr
                    className={`${
                      selectedDocument &&
                      selectedDocument.id === e.id &&
                      "highlighted_row"
                    }`}
                    key={e.id}
                    onClick={(event) => {
                      handleDocumentClick(e);
                    }}
                  >
                    <td className="">
                      <Figure.Image
                        className="pt-1 ps-1"
                        width={50}
                        height={60}
                        alt={"Thumbnail"}
                        src={e.thumbnail}
                      />
                    </td>
                    <td className="text-center align-middle">{e.title}</td>
                    <td className="text-center align-middle">
                      {selectedCategory.name}
                    </td>
                    <td className="text-center align-middle">
                      {formatDate(e.created)}
                    </td>
                    <td className="text-center align-middle flex">
                      <div className="d-flex justify-content-between">
                        <Button
                          className="me-2"
                          onClick={(event) => {
                            handleDownload(e, event);
                          }}
                          variant="secondary"
                          size="sm"
                        >
                          {isDownloading(e) && (
                            <Spinner
                              animation="border"
                              size="sm"
                              className="me-2"
                            />
                          )}
                          {isDownloading(e) ? "Downloading..." : "Download"}
                        </Button>
                        {canEditFile(e) && (
                          <Button
                            onClick={(event) => {
                              handleEdit(e, event);
                            }}
                            variant="primary"
                            size="sm"
                          >
                            Edit
                          </Button>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}
