import axios from "axios";
import Auth from "../../../../Auth";
import { REACT_APP_NOVITAERP_API_PRODUCT_LIST } from "../../../../global/const";
import Util from "../../../../Util";

export const FindProductCategoryInJewellery = async (reference) => {
  if (!reference) {
    return;
  }

  let url = `${Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN")}${REACT_APP_NOVITAERP_API_PRODUCT_LIST}`;

  const config = {
    params: {
      reference: reference,
    },
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${Auth.getToken()}`,
    },
  };

  return await axios
    .get(url, config)
    .then((response) => {
      const { data } = response;
      const { result } = data;
      const { rows } = result;

      if (rows.length > 0) {
        const { productCategoryModel = {} } = rows[0];
        const name = productCategoryModel?.name;

        return name;
      }

      return null;
    })
    .catch((err) => {
      return null;
    });
};
