import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import FormSelectCustomer from "../../../Form/FormSelectCustomer";
import Util from "../../../../Util";

const FormSaleCommissions = (props) => {
  const formRef = React.createRef();
  const { onResponse } = props;

  const [file, setFile] = useState(null);
  const [validated, setValidated] = useState(false);
  const [type, setType] = useState("");
  const types = Util.getStorageParameter(
    "REACT_APP_LIST_TYPE_COMMISSIONS"
  ).split(",");

  const listTypes = types.map((type) => {
    return {
      value: type,
      id: type,
    };
  });

  const uploadFile = (form) => {
    const check = form.checkValidity();

    setValidated(false);

    if (!check) {
      setValidated(true);
      return;
    }

    onResponse(file, type);
  };

  return (
    <Form noValidate validated={validated} ref={formRef}>
      <Col xs={12} lg={4}>
        <FormSelectCustomer
          label="Type"
          nameInput="value"
          items={listTypes}
          keyValue={"value"}
          value={type ?? ""}
          required={true}
          columnsSize={12}
          onResponse={(value) => {
            const data = JSON.parse(value);
            setType(data?.value ?? "");
          }}
        />

        <Form.Group className="mt-1">
          <Form.Label>Upload file</Form.Label>
          <InputGroup>
            <Form.Control
              accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              type="file"
              placeholder="No file selected."
              required
              onChange={(e) => {
                const file = e;
                setFile(file);
              }}
            />
            <InputGroup.Text
              className="add-task-button"
              onClick={() => {
                uploadFile(formRef.current);
              }}
            >
              <FontAwesomeIcon icon={faUpload} />
              &nbsp;Upload
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              <ul>
                <li>The file is required</li>
                <li>The accepted file type is a spreadsheet format</li>
              </ul>
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Col>
    </Form>
  );
};

FormSaleCommissions.propTypes = {
  onResponse: PropTypes.func.isRequired,
};

export default FormSaleCommissions;
