import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios";
import {
  API_STATUS_FAILED,
  API_STATUS_IDLE,
  API_STATUS_PENDING,
  API_STATUS_SUCCESS,
  CUSTOMERS_TYPE_DESTINATION,
  CUSTOMERS_TYPE_ORIGIN,
} from "../constants";

const initialState = {
  error: null,
  comments: [],
  originCustomerComments: null,
  destinationCustomerComments: null,
  mergeCustomerComments: null,
  status: API_STATUS_IDLE,
};

export const fetchComments = createAsyncThunk(
  "/customers/comments",
  async (args, thunkAPI) => {
    const { customerId, cancelToken } = args;
    try {
      const response = await axios.get(
        `/api/v1/customers/customer-comments/${customerId}`,
        { cancelToken: cancelToken?.token }
      );
      return { customerId, data: response.data};
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchAllCommentsByIds = createAsyncThunk(
  "/customers/comments/get-by-customer-ids",
  async (args, thunkAPI) => {
    const {customerIds, customerType} = args;
    try {
      const response = await axios.post(
        `/api/v1/customers/customer-comments/get-by-customer-ids`,
        customerIds
      );
      return { customerIds: customerIds, data: response.data.value, customerType: customerType};
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addComment = createAsyncThunk(
  "/customers/add-comment",
  async (args, thunkAPI) => {
    const { customerId, topic, text } = args;
    try {
      const response = await axios.post(
        `/api/v1/customers/customer-comments`, { customerId, topic, text },
      );
      return { customerId, data: response.data};
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const customerCommentsSlice = createSlice({
  name: "customerComments",
  initialState,
  reducers: {
    clearCustomerComments(state) {
        state.error = initialState.error;
        state.status = initialState.status;
        state.comments = initialState.comments;
        state.originCustomerComments = initialState.originCustomerComments;
        state.destinationCustomerComments = initialState.destinationCustomerComments;
        state.mergeCustomerComments = initialState.mergeCustomerComments;
    },
  },
  extraReducers(builder) {
    //fetchComments
    builder.addCase(fetchComments.fulfilled, (state, action) => {
      const { customerId, data } = action.payload;
      state.comments = [...state.comments, {customerId, messages: data}];
      state.status = API_STATUS_SUCCESS;
    });
    builder.addCase(fetchComments.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(fetchComments.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    //fetchAllCommentsByIds
    builder.addCase(fetchAllCommentsByIds.fulfilled, (state, action) => {
      const { data, customerType } = action.payload;
      switch(customerType) {
        case CUSTOMERS_TYPE_ORIGIN:
          state.originCustomerComments = data;
          break;
        case CUSTOMERS_TYPE_DESTINATION:
          state.destinationCustomerComments = data;
          break;
        default:
          state.mergeCustomerComments = data;
          break;
      }
      state.status = API_STATUS_SUCCESS;
    });
    builder.addCase(fetchAllCommentsByIds.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(fetchAllCommentsByIds.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    //addComment
    builder.addCase(addComment.fulfilled, (state, action) => {
      const { customerId, data } = action.payload;
      const comments = state.comments.find((customer) => customer.customerId === customerId);
      // check if customer is found
      if (comments) {
        comments.messages?.push(data);
      } else {
        state.comments = [...state.comments, {customerId, messages: [data]}];
      }
      state.status = API_STATUS_SUCCESS;
    });
    builder.addCase(addComment.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(addComment.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
  }
});

export const { clearCustomerComments } = customerCommentsSlice.actions;

export default customerCommentsSlice.reducer;
