import React, {Component} from 'react';
import * as globalConst from '../../global/const';

class ProductReferenceRow extends Component {

    constructor(props){
        super();
        this.state = {
            product_reference : props.product_reference,
            selected_product_reference : props.selected_product_reference,
        }
    }

    //*****************************************
    //RENDER
    //*****************************************
    render(){
        const product_reference = this.state.product_reference;
        
        let row_class = "product_reference_row";
        if (this.state.highlight_row === true) {
            row_class = "product_reference_row highlighted_row";
        }

        if(this.state.selected_product_reference !== null && this.state.selected_product_reference !== undefined && product_reference !== null && product_reference.id === this.state.selected_product_reference.id){
            row_class = row_class+" highlighted_row"
        }

        return(
            <tr className={row_class} onClick={() => this.props.handleRowSelection(this.props.index)}>
                <td className="name">
                    {product_reference?.name}
                </td>
                <td className="reference">
                    {product_reference?.reference}
                </td>
                <th className="metal">
                    {this.getMetel(product_reference)}
                </th>
                <th className="diamondShape">
                    { (globalConst.REACT_APP_PRODUCT_CATEGORY_ID_ER === product_reference.productCategoryId) ?
                    product_reference?.engagementRingModels[0]?.diamondShapeModel?.name : 'N/A'
                    }
                </th>
            </tr>
        )
    }


    highlightRow(){
        this.setState({
            highlight_row : !this.state.highlight_row
        });
    }

    getMetel(product_reference) {
        if (product_reference.ringModels.length <= 0) {
            return "N/A"
        }

        switch(product_reference.productCategoryId){
            case globalConst.REACT_APP_PRODUCT_CATEGORY_ID_RING:
              return product_reference.ringModels[0].metalTypeModel?.name
            case globalConst.REACT_APP_PRODUCT_CATEGORY_ID_EARRING:
                return product_reference.earringModels[0].metalTypeModel?.name
            case globalConst.REACT_APP_PRODUCT_CATEGORY_ID_BRACELET:
                return product_reference.braceletModels[0].metalTypeModel?.name
            case globalConst.REACT_APP_PRODUCT_CATEGORY_ID_NECKLACE:
                return product_reference.necklaceModels[0].metalTypeModel?.name
            case globalConst.REACT_APP_PRODUCT_CATEGORY_ID_ER:
                return product_reference.engagementRingModels[0].metalTypeModel?.name
            case globalConst.REACT_APP_PRODUCT_CATEGORY_ID_CABLE_CHAIN:
                return product_reference.cableChainModels[0].metalTypeModel?.name
            default:
              return "N/A"
          }
    }

    //********************************
    //REWRITING LIFECYCLE METHODS
    //********************************

    static getDerivedStateFromProps(props, state) {
        return{
            product_reference: props.product_reference,
            selected_product_reference : props.selected_product_reference,
        }
    }

    //********************************
    //REWRITING LIFECYCLE METHODS END
    //********************************
    
}

export default ProductReferenceRow;