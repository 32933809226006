import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Card, Tabs, Tab, Button, ProgressBar } from "react-bootstrap";
import AddElementTag from "./addElementTag";
import AddFiles from "./addFiles";
import { createNewVersionStatus, createNewVersionWorkflowCad, createWorkflowCad, editWorkflowCad, selectWorkFlowCad } from "../../../../../store/slices/workflowCadSlice";
import Util from "../../../../../Util";
import { API_STATUS_PENDING } from "../../../../../store/constants";
import FormInputTextarea from "../../../../Form/FormInputTextarea";

const STATUS_PENDING = 'Pending';
const RequestCustomisation = ({ onClose, activity }) => {

    const { status, progress, selectedWorkflowCad, createNewVersion } = useSelector((state) => state.workflowCad);
    const [customisationTypesItems, setCustomisationTypesItems] = useState(selectedWorkflowCad?.request ?? []);
    const [salesRepNotes, setSalesRepNotes] = useState(selectedWorkflowCad?.salesRepNotes ?? '');
    const [cadFiles, setCadFiles] = useState([]);
    const [fileIdsToRemove, setFileIdsToRemove] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(selectWorkFlowCad(null));
        dispatch(createNewVersionStatus(false));
        setFileIdsToRemove([]);
        onClose();
    };

    const createFormData = (additionalFields) => {
        const formData = new FormData();

        formData.append('workflowActivityId', activity.id);
        formData.append('salesRepNotes', salesRepNotes);
        cadFiles.forEach(cadFile => formData.append('cadFiles[]', cadFile));

        // Append additional fields to formData
        Object.entries(additionalFields).forEach(([key, value]) => {
            formData.append(key, value);
        });

        return formData;
    };

    const handleRequestButtonClick = async (event) => {
        event.preventDefault();
        setIsButtonDisabled(true);

        const formData = createFormData({
            request: Util.changeArrayToString(customisationTypesItems),
            version: 1,
            jewellerStatus: STATUS_PENDING,
            salesRepStatus: STATUS_PENDING
        });
        handleFormSubmission(formData);
    }

    const handleDraftButtonClick = async (event) => {
        event.preventDefault();
        setIsButtonDisabled(true);

        const formData = createFormData({
            request: Util.changeArrayToString(customisationTypesItems),
            version: 0
        });

        handleFormSubmission(formData);
    }

    const handleFormSubmission = (formData) => {
        if (selectedWorkflowCad) {
            formData.append('fileIdsToRemove', Util.changeArrayToString(fileIdsToRemove));
            if (createNewVersion) {
                dispatch(createNewVersionWorkflowCad({ data: formData, id: selectedWorkflowCad.id })).then(handleClose);
            } else {
                dispatch(editWorkflowCad({ data: formData, id: selectedWorkflowCad.id })).then(handleClose);
            }
        } else {
            dispatch(createWorkflowCad(formData)).then(handleClose);
        }
    };

    const handleChange = (value) => {
        setSalesRepNotes(value);
    };

    useEffect(() => {
        setIsButtonDisabled(customisationTypesItems.length === 0 && cadFiles.length === 0);
    }, [customisationTypesItems, cadFiles]);


    return (
        <Modal
            show={true}
            onHide={handleClose}
            dialogClassName="modal-90w"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    REQUEST CAD FORM
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="SaleWorkflowEditCAD_request_component">
                    <Tabs id="SaleWorkflowEditCADTabs" className="mb-3">
                        <Tab
                            eventKey="request_cad"
                            title="Request CAD"
                            tabClassName={null}
                        >
                            <Card>
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-8 div-border-right-solid-2">
                                            <AddElementTag
                                                customisationTypesItems={customisationTypesItems}
                                                setCustomisationTypesItems={setCustomisationTypesItems}
                                            />
                                            <div className="mt-3 row">
                                                <FormInputTextarea
                                                    label="Sales rep Notes"
                                                    nameInput="sales_rep_notes"
                                                    value={salesRepNotes ?? ""}
                                                    onResponse={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <AddFiles
                                                cadFiles={cadFiles}
                                                setCadFiles={setCadFiles}
                                                setFileIdsToRemove={setFileIdsToRemove}
                                            />
                                        </div>
                                    </div>
                                    {status === API_STATUS_PENDING && <ProgressBar className="mt-2" animated now={progress} />}
                                    <hr />
                                    <div className="row float-right">
                                        <div className="col-12 mt-2">
                                            {!createNewVersion && 
                                                <Button
                                                    variant="primary"
                                                    className="request-cad-btn"
                                                    disabled={isButtonDisabled}
                                                    onClick={handleDraftButtonClick}
                                                >
                                                    {selectedWorkflowCad ? 'UPDATE DRAFT' : 'CREATE DRAFT'}
                                                </Button>
                                            }
                                            <Button
                                                variant="primary"
                                                className="request-cad-btn"
                                                disabled={isButtonDisabled}
                                                onClick={handleRequestButtonClick}
                                            >
                                                {createNewVersion ? 'CREATE NEW VERSION' : 'SEND NEW REQUEST'}
                                            </Button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Tab>
                    </Tabs>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default RequestCustomisation;