import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typeahead } from "react-bootstrap-typeahead";
import { LIST_SPECIAL_REQUEST } from "../../../../global/const";
import { Form } from "react-bootstrap";

const FieldMultipleSpecialRequest = ({
  nameField,
  onChange,
  labelKey,
  placeholder,
  disabled,
  label,
  value,
}) => {
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState(LIST_SPECIAL_REQUEST);

  useEffect(() => {
    if (value && selected.length <= 0 && options.length > 0) {
      const manufacturingSpecialRequests = value.split(",");

      if (manufacturingSpecialRequests.length <= 0) {
        return;
      }

      let selected = [];

      manufacturingSpecialRequests.forEach((manufacturingSpecialRequest) => {
        options.forEach((specialRequestList) => {
          if (specialRequestList.label === manufacturingSpecialRequest) {
            selected.push(specialRequestList);
          }
        });
      });

      const specialRequestLists = activeOptions(value);

      setOptions(specialRequestLists);
      setSelected(selected);
    }
  }, [options, selected, value]);

  const handleSpecialRequestsResponse = (selected) => {
    setSelected(selected);

    const selectedData = [];

    selected.forEach((item) => {
      selectedData.push(item.value);
    });

    const specialRequestLists = activeOptions(selectedData);

    setOptions(specialRequestLists);

    const selectedStr = selectedData.join(",");

    onChange(nameField, selectedStr);
  };

  const activeOptions = (selectedData) => {
    let cloneSpecialRequestLists = JSON.parse(
      JSON.stringify(LIST_SPECIAL_REQUEST)
    );

    cloneSpecialRequestLists = cloneSpecialRequestLists.map(
      (cloneSpecialRequestLists) => {
        cloneSpecialRequestLists.disabled = false;
        return cloneSpecialRequestLists;
      }
    );

    if (selectedData.includes(cloneSpecialRequestLists[0].value)) {
      cloneSpecialRequestLists[1].disabled = true;
      cloneSpecialRequestLists[2].disabled = true;
    }

    if (selectedData.includes(cloneSpecialRequestLists[1].value)) {
      cloneSpecialRequestLists[0].disabled = true;
      cloneSpecialRequestLists[2].disabled = true;
    }

    if (selectedData.includes(cloneSpecialRequestLists[2].value)) {
      cloneSpecialRequestLists[0].disabled = true;
      cloneSpecialRequestLists[1].disabled = true;
    }

    return cloneSpecialRequestLists;
  };

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>

      <Typeahead
        id={nameField}
        labelKey={labelKey}
        multiple
        placeholder={placeholder}
        onChange={(e) => handleSpecialRequestsResponse(e)}
        options={options ?? []}
        selected={selected ?? []}
        disabled={disabled}
        renderMenuItemChildren={(option, props) => (
          <div
            style={{
              pointerEvents: option.disabled ? "none" : "auto",
              color: option.disabled ? "#c9c9c9" : "#000",
            }}
          >
            {option.label}
          </div>
        )}
      />
    </Form.Group>
  );
};

FieldMultipleSpecialRequest.propTypes = {
  onChange: PropTypes.func.isRequired,
  nameField: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

FieldMultipleSpecialRequest.defaultProps = {
  placeholder: "Choose option...",
};

export default FieldMultipleSpecialRequest;
