import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmployees } from "../../../store/slices";
import { Form } from "react-bootstrap";
import Auth from "../../../Auth";

const EmployeeSelectOption = (data) => {
  const { employee } = data;

  return <option value={employee.name}>{employee.name}</option>;
};

const SalesRepSelect = (props) => {
  const dispatch = useDispatch();
  const { onResponse } = props;
  const { employees, isLoading } = useSelector((state) => state.employees);

  const user = Auth.getAuthenticatedUser();
  const { name } = user.employeeModel;
  const leader = Auth.canAccessRolesAndLeader("rolesEditReports", true);
  const roleAccounting = Auth.canAccessRolesAndLeader("accountingStatus");

  const [selectedEmployee, setSelectedEmployeeId] = useState(
    !leader ? name : ""
  );

  useEffect(() => {
    dispatch(fetchEmployees());
  }, [dispatch]);

  const salesEmployees = employees ?? [];

  const handleSelectionChanged = (event) => {
    const { value } = event.target;

    setSelectedEmployeeId(value);

    onResponse(value);
  };

  return (
    <Form.Group controlId="startDate">
      <Form.Label>Select Sales Rep</Form.Label>
      <Form.Select
        value={selectedEmployee}
        onChange={handleSelectionChanged}
        required={false}
      >
        {(leader || roleAccounting) && <option value="">All</option>}
        {isLoading ? (
          <option>Loading employees...</option>
        ) : (
          salesEmployees.map((employee) => (
            <EmployeeSelectOption key={employee.id} employee={employee} />
          ))
        )}
      </Form.Select>
    </Form.Group>
  );
};

SalesRepSelect.propTypes = {
  onResponse: PropTypes.func.isRequired,
};

export default SalesRepSelect;
