import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS_PENDING } from "../../../../store/constants";
import { addComment } from "../../../../store/slices/commentsSlice";
import Util from "../../../../Util";

const AddCustomerComment = ({ customer }) => {
  const [message, setMessage] = useState("");
  const [selectedTopic, setSelectedTopic] = useState();
  const { status } = useSelector((state) => state.customerComments);

  const listShowTopics = Util.getStorageParameter("REACT_APP_TOPIC_CUSTOMER").split(",");

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.length === 0) {
      return;
    }
    if (!selectedTopic) {
      alert("Please select a topic");
      return;
    }

    if (selectedTopic === "select") {
      alert("Please select a topic");
      return;
    }

    dispatch(
      addComment({
        customerId: customer.id,
        text: message,
        topic: selectedTopic,
      })
    );
    setMessage("");
  };

  return (
    <div id="SaleCommentForm_component">
      <form onSubmit={handleSubmit}>
        <div className="col-12  mb-3 mt-3 pink-border-top">
          <div className="topic_field">
            <label className="form-label comment-label">ADD A COMMENT:</label>
            <select
              className="select-box  ml-0"
              value={selectedTopic}
              onChange={(e) => {
                setSelectedTopic(e.target.value);
              }}
            >
              <option value="">Select topic</option>
              {listShowTopics.map((listShowTopic) => {
                return (
                  <option key={listShowTopic} value={listShowTopic}>
                    {listShowTopic}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="col-12  mb-2">
          <div className="text_field">
            <textarea
              className="form-control w-100"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-12">
          <div className="submit_field">
            <input
              disabled={status === API_STATUS_PENDING}
              className="submit-btn btn btn-default"
              type="submit"
              value="Submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddCustomerComment;
