import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Modal, Row } from "react-bootstrap";
import LoadingSpinnerLayer from "../../../LoadingSpinnerLayer";

const ConfirCreateQualityControl = ({ onResponse, activity }) => {
  const [processSave, setProcessSave] = useState(false);

  const closeModal = (value) => {
    setProcessSave(true);

    const response = {
      value: value,
      activity: activity,
    };

    onResponse(response);
  };

  return (
    <Modal show={true} size="md" centered>
      <Modal.Body>
        <Col>
          Are you sure that you want to create the quality control check?
        </Col>
        {processSave && (
          <Row className="center-element">
            <LoadingSpinnerLayer />
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Row lg={12}>
          <Col lg={6}>
            <Button variant="secondary" onClick={() => closeModal(false)}>
              No
            </Button>
          </Col>
          <Col lg={6}>
            <Button variant="primary" onClick={() => closeModal(true)}>
              Yes
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

ConfirCreateQualityControl.propTypes = {
  onResponse: PropTypes.func.isRequired,
  activity: PropTypes.object.isRequired,
};

export default ConfirCreateQualityControl;
