import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS_FAILED, API_STATUS_IDLE, API_STATUS_PENDING, API_STATUS_SUCCESS } from "../constants";
import axios from "../../axios";

const initialState = {
  error: null,
  lists: [],
  totalLists: 0,
  listAppointments: {},
  status: API_STATUS_IDLE
};

export const deleteAppointmentsCustomizedList = createAsyncThunk(
  "customers/appointments-customized-lists/delete",
  async (args, thunkAPI) => {
    try {
      const response = await axios.delete(`api/v1/customers/appointments-customized-lists/${encodeURIComponent(args.name)}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createAppointmentsCustomizedList = createAsyncThunk(
  "customers/appointments-customized-lists/create",
  async (args, thunkAPI) => {
    try {
      const response = await axios.post("api/v1/customers/appointments-customized-lists/create", {
        name: args.name
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchAppointmentsFromString = createAsyncThunk(
  "customers/appointments-customized-lists/details",
  async (args, thunkAPI) => {
    try {
      const { id } = args;
      const response = await axios.get(
        `/api/v1/customers/appointments-customized-lists/details/${id}`,
      );
      const data = response.data;
      return {id: id, appointments: data};
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchAppointmentCustomizedLists = createAsyncThunk(
  "customers/appointments-customized-lists",
  async (args, thunkAPI) => {
    try {
      const response = await axios.get(
        `/api/v1/customers/appointments-customized-lists`,
      );
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addAppointmentCustomizedLists = createAsyncThunk(
  "customers/appointments-customized-lists/add",
  async (args, thunkAPI) => {
    try {
      const response = await axios.put(
        `/api/v1/customers/appointments-customized-lists/add`, {
          listId: args.listId,
          customerAppointmentId: args.customerAppointmentId
        }
      );
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const removeAppointmentCustomizedLists = createAsyncThunk(
  "customers/appointments-customized-lists/remove",
  async (args, thunkAPI) => {
    try {
      const response = await axios.put(
        `/api/v1/customers/appointments-customized-lists/remove`, {
          listId: args.listId,
          customerAppointmentId: args.customerAppointmentId
        }
      );
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const batchAddAppointmentCustomizedLists = createAsyncThunk(
  "customers/appointments-customized-lists/batchAdd",
  async (args, thunkAPI) => {
    try {
      const response = await axios.put(
        `/api/v1/customers/appointments-customized-lists/batchAdd`, {
          listId: args.listId,
          customerAppointmentIds: JSON.stringify(args.customerAppointmentIds)
        }
      );
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const appointmentsCustomizedListsSlice = createSlice({
  name: "appointmentsCustomizedLists",
  initialState,
  reducers: {
    clearCustomizedLists(state) {
      state.error = initialState.error;
      state.status = initialState.status;
      state.lists = initialState.lists;
      state.listAppointments = initialState.listAppointments;
      state.totalLists = initialState.totalLists;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchAppointmentCustomizedLists.fulfilled, (state, action) => ({
      totalLists: action.payload.count,
      lists: action.payload.rows,
      error: null,
      listAppointments: {},
      status: API_STATUS_SUCCESS,
    }));
    builder.addCase(fetchAppointmentCustomizedLists.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(fetchAppointmentCustomizedLists.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    //fetchAppointmentsFromString
    builder.addCase(fetchAppointmentsFromString.fulfilled, (state, action) => {
      let tt = new Map();
      tt[action.payload.id] = action.payload.appointments;
      return {
        totalLists: state.totalLists,
        lists: state.lists,
        listAppointments: { ...state.listAppointments, ...tt },
        error: null,
        status: API_STATUS_SUCCESS,
      }
    });
    builder.addCase(fetchAppointmentsFromString.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(fetchAppointmentsFromString.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    // createAppointmentsCustomizedList
    builder.addCase(createAppointmentsCustomizedList.fulfilled, (state, action) => ({
      totalLists: state.totalLists + 1,
      lists: [...state.lists, {...action.payload, customerAppointmentIds: JSON.parse(action.payload.customerAppointmentIds)}],
      listAppointments: state.listAppointments,
      error: null,
      status: API_STATUS_SUCCESS,
    }));
    builder.addCase(createAppointmentsCustomizedList.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(createAppointmentsCustomizedList.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    // deleteAppointmentsCustomizedList
    builder.addCase(deleteAppointmentsCustomizedList.fulfilled, (state, action) => ({
      totalLists: action.payload.count,
      lists: action.payload.rows,
      listAppointments: state.listAppointments,
      error: null,
      status: API_STATUS_SUCCESS,
    }));
    builder.addCase(deleteAppointmentsCustomizedList.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(deleteAppointmentsCustomizedList.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    // addAppointmentCustomizedLists
    // returns the modifed list only
    builder.addCase(addAppointmentCustomizedLists.fulfilled, (state, action) => ({
      totalLists: state.totalLists,
      lists: [...state.lists.filter((e) => e.id !== action.payload.id), action.payload],
      listAppointments: state.listAppointments,
      error: null,
      status: API_STATUS_SUCCESS,
    }));
    builder.addCase(addAppointmentCustomizedLists.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(addAppointmentCustomizedLists.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    //removeAppointmentCustomizedLists
    builder.addCase(removeAppointmentCustomizedLists.fulfilled, (state, action) => ({
      totalLists: state.totalLists,
      lists: [...state.lists.filter((e) => e.id !== action.payload.id), action.payload],
      listAppointments: state.listAppointments,
      error: null,
      status: API_STATUS_SUCCESS,
    }));
    builder.addCase(removeAppointmentCustomizedLists.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(removeAppointmentCustomizedLists.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    // batchAddAppointmentCustomizedLists
    builder.addCase(batchAddAppointmentCustomizedLists.fulfilled, (state, action) => ({
      totalLists: state.totalLists,
      lists: [...state.lists.filter((e) => e.id !== action.payload.id), action.payload],
      listAppointments: state.listAppointments,
      error: null,
      status: API_STATUS_SUCCESS,
    }));
    builder.addCase(batchAddAppointmentCustomizedLists.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(batchAddAppointmentCustomizedLists.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
  },
});

export const { clearCustomizedLists } = appointmentsCustomizedListsSlice.actions;

export default appointmentsCustomizedListsSlice.reducer;
