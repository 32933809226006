import axios from "axios";
import { sprintf } from "sprintf-js";
import * as globalConst from "../../../../global/const";
import Auth from "../../../../Auth";
import Util from "../../../../Util";

const source = axios.CancelToken.source();

export const findEmployeeCommissionRate = async (saleId, employeeId) => {
  try {
    const url = sprintf(
      "%s%s",
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN"),
      globalConst.REACT_APP_NOVITAERP_API_EMPLOYEE_COMMISSION_RATE
    );

    const parameters = {
      saleId: saleId,
      employeeId: employeeId,
    };

    return await axios
      .get(url, {
        params: parameters,
        cancelToken: source.token,
        headers: {
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      })
      .then((response) => {
        const { data } = response;
        const { value } = data;

        if (Array.isArray(value) === true && value.length > 0) {
          return value;
        }

        return [];
      })
      .catch(() => {
        return [];
      });
  } catch (error) {
    return [];
  }
};
