import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS_PENDING } from "../../store/constants";

import LoadingLayer from "../LoadingLayer";

import "../../css/appointments/appointments.scss";
import "../../css/appointments/appointments_information.scss";
import AppointmentsHeader from "./header";
import AppointmentsList from "./list";
import AppointmentsInformation from "./information";
import { selectAppointment } from "../../store/slices";
import axios from "axios";
import { fetchComments } from "../../store/slices/commentsSlice";

export default function AppointmentsView(props) {
  const { status } = useSelector((state) => state.appointments);
  const [cancelToken, setCancelToken] = useState(null);

  const appointment = useSelector((state) => state.appointments.selectedAppointment);

  const dispatch = useDispatch();

  const handleSelection = (appointment) => {
    if (cancelToken) {
      cancelToken.cancel();
      setCancelToken(axios.CancelToken.source());
    }
    dispatch(selectAppointment(appointment));
    dispatch(fetchComments({ customerId: appointment?.customer?.id, cancelToken }));
  };

  return (
    <div id="crm_component">
      <div className="container_crm">
        {status === API_STATUS_PENDING && <LoadingLayer />}
        <div className="list_and_information">
          <div className="row">
            <div className="crm_list">
                <AppointmentsHeader />
                <AppointmentsList selected={appointment} onSelected={handleSelection}/>
            </div>
            <div id="CRMInformation_component">
                <AppointmentsInformation appointment={appointment}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
