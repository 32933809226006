import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { fetchEmployees } from "../../../store/slices";
import { Form } from "react-bootstrap";

const GenerateReportOptions = ({ onResponse, reports }) => {
  const dispatch = useDispatch();

  const [selectedReport, setSelectedReport] = useState("");

  useEffect(() => {
    dispatch(fetchEmployees());
  }, [dispatch]);

  const handleSelectionChanged = (event) => {
    const { value } = event.target;

    setSelectedReport(value);

    onResponse(value);
  };

  return (
    <Form.Group>
      <Form.Label>Select report</Form.Label>
      <Form.Select
        value={selectedReport}
        onChange={handleSelectionChanged}
        required={true}
      >
        <option value=""></option>
        {reports.map((report) => (
          <option key={report.value} value={report.name}>
            {report.name}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
};

GenerateReportOptions.propTypes = {
  onResponse: PropTypes.func.isRequired,
  reports: PropTypes.array.isRequired,
};

export default GenerateReportOptions;
