import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios";
import {
  API_STATUS_SUCCESS,
  API_STATUS_PENDING,
  API_STATUS_FAILED,
} from "../constants";

const initialState = {
  error: null,
  status: "",
  salesIds: [],
  jewelleryRingRequests: [],
};

export const fetchSendJewelleryRingRequest = createAsyncThunk(
  "/sales/jeweller/ring/request",
  async (args, thunkAPI) => {
    try {
      const response = await axios.post(`/api/v1/sales/jeweller/ring/request`, {
        saleIDS: args.salesIds,
      });

      const { data } = response;
      const { value = null } = data;

      if (value) {
        return value;
      }

      return [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const jewelleryRingRequestSlice = createSlice({
  name: "jewelleryRingrequest",
  initialState,
  reducers: {
    clearJewelleryRingRequest(state) {
      state.salesIds = initialState.salesIds;
    },
    setSalesIds(state, action) {
      state.salesIds = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      fetchSendJewelleryRingRequest.fulfilled,
      (state, action) => {
        state.error = null;
        state.status = API_STATUS_SUCCESS;
        state.jewelleryRingRequests = action.payload;
      }
    );
    builder.addCase(fetchSendJewelleryRingRequest.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(fetchSendJewelleryRingRequest.pending, (state) => {
      state.status = API_STATUS_PENDING;
    });
  },
});

export const { setSalesIds } = jewelleryRingRequestSlice.actions;

export default jewelleryRingRequestSlice.reducer;
