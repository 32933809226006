import React from "react";
import { useSelector } from "react-redux";
import Auth from "../../Auth";
import SalesCharts from "./charts/SalesCharts";
import AppointmentsCharts from "./charts/AppointmentsCharts";
import InputsFormSearch from "./Inputs/InputsFormSearch";
import LoadingSpinnerLayer from "../LoadingSpinnerLayer";
import { Row } from "react-bootstrap";
import CommissionsCharts from "./charts/CommissionsCharts";
import ReportRequest from "./Table/ReportRequest/Index";
import { CONST_CUSTOMER } from "../../store/constants";

const MainReports = () => {
  const { selectedReport, loadingRepots } = useSelector(
    (state) => state.reports
  );

  const leaders = Auth.canAccessRolesAndLeader("rolesEditReports", true);

  const roleSales = Auth.canAccessRolesAndLeader("rolesIsSales");
  const roleAccounting = Auth.canAccessRolesAndLeader("accountingStatus");

  if (!leaders && !roleSales && !roleAccounting) {
    return (
      <div className="main-reports-header">
        <h1>Unauthorized</h1>
      </div>
    );
  }

  return (
    <main className="main-flex">
      <InputsFormSearch />

      {loadingRepots && (
        <Row className="center-element mt-2">
          <LoadingSpinnerLayer />
        </Row>
      )}

      {selectedReport === CONST_CUSTOMER.REPORTS.resultSales &&
        !loadingRepots && <SalesCharts />}

      {selectedReport === CONST_CUSTOMER.REPORTS.resultAppointments &&
        !loadingRepots && <AppointmentsCharts />}
      {[
        CONST_CUSTOMER.REPORTS.resultCommissionsSales,
        CONST_CUSTOMER.REPORTS.resultCommissionsReviews,
      ].includes(selectedReport) &&
        !loadingRepots && <CommissionsCharts />}

      {selectedReport === CONST_CUSTOMER.REPORTS.reportRequest &&
        !loadingRepots && <ReportRequest />}
    </main>
  );
};

export default MainReports;
