// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-black-show-informacion-panel {
  width: 70% !important;
}

.modal-show-informacion-panel {
  width: 70% !important;
}
.modal-show-informacion-panel .modal-dialog {
  margin: 5%;
  min-width: 90% !important;
}
.modal-show-informacion-panel #LoadingLayer_component {
  width: 100%;
  height: 100%;
  z-index: 100000;
  position: fixed;
  min-height: 100%;
  min-width: 100%;
  top: 0px;
  left: 0px;
  opacity: 0.8;
}`, "",{"version":3,"sources":["webpack://./src/css/modal.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;;AAEA;EACE,qBAAA;AACF;AACE;EACE,UAAA;EACA,yBAAA;AACJ;AAEE;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;EACA,QAAA;EACA,SAAA;EACA,YAAA;AAAJ","sourcesContent":[".modal-black-show-informacion-panel {\n  width: 70% !important;\n}\n\n.modal-show-informacion-panel {\n  width: 70% !important;\n\n  .modal-dialog {\n    margin: 5%;\n    min-width: 90% !important;\n  }\n\n  #LoadingLayer_component {\n    width: 100%;\n    height: 100%;\n    z-index: 100000;\n    position: fixed;\n    min-height: 100%;\n    min-width: 100%;\n    top: 0px;\n    left: 0px;\n    opacity: 0.8;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
