import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Form, FormLabel, Row } from "react-bootstrap";
import DividerDashed from "../../../../Divider/DividerDashed";
import { dateFormatCustom } from "../../../../../utils/dateUtils";
import FormInputCheckbox from "../../../../Form/FormInputCheckbox";
import FormDatePicker from "../../../../Form/FormDatePicker";
import FormSelectMultiple from "../../../../Form/FormSelectMultiple";
import ReturnJewelleyToGD from "./ReturnJewelleyToGD";
import { saleHaveTwoDiamonds } from "../../../../../store/slices/workflowReturnSlice";
import { useDispatch } from "react-redux";
import Util from "../../../../../Util";

const SaleWorkflowEditReturnStepTwo = ({
  stepTwo,
  onResponse,
  disabledInputs,
  date,
  type,
  sale,
  onResponseGD,
}) => {
  const dispatch = useDispatch();

  const descriptionCostsGeneratedReturn = Util.getStorageParameter(
    "REACT_APP_LIST_GENERATED_RETURN"
  )
    ? Util.getStorageParameter("REACT_APP_LIST_GENERATED_RETURN").split(",")
    : [];

  const [step, setStep] = useState(stepTwo);

  useEffect(() => {
    dispatch(saleHaveTwoDiamonds(sale));
  }, [dispatch, sale]);

  const onChangeInput = (value, name) => {
    setStep({
      ...step,
      [name]: value,
    });

    stepTwo[name] = value;

    onResponse(stepTwo, "stepTwoQuestions");
  };

  const onChangeResponse = (value, name) => {
    setStep({
      ...step,
      [name]: value,
    });

    stepTwo[name] = value;

    onResponse(stepTwo, "stepTwoQuestions");
  };

  const onChangeValue = (event) => {
    const { name } = event.target;
    let { value } = event.target;

    value = value.toString();
    value = value.replace(/,/g, ".").replace(/[^0-9.]/g, "");

    onChangeResponse(value, name);
  };

  const onChangeValueBlur = (event) => {
    const { value, name } = event.target;

    if (value === "") {
      onChangeResponse(0, name);
    }
  };

  const onResponseReturn = (value, name) => {
    setStep({
      ...step,
      [name]: value,
    });

    stepTwo[name] = value;

    onResponse(stepTwo, "stepTwoQuestions");
  };

  return (
    <>
      <DividerDashed />
      <Row>
        <Col xs="12" lg="12">
          <h2>
            {type} requested on the {dateFormatCustom(date, "Do MMM of YYYY")}
          </h2>
        </Col>

        <Row className="mt-1">
          <Col xs={12} lg={12}>
            <FormInputCheckbox
              typeView="switch"
              type="checkbox"
              label="Notification sent to invoices group"
              nameInput="notificationSentInvoicesGroup"
              checked={step.notificationSentInvoicesGroup ?? false}
              onResponse={onChangeInput}
              disabled={disabledInputs}
            />
            <span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Tag team
              leaders from Operations and Accounting
            </span>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col xs={12} lg={12}>
            <FormDatePicker
              label={"Date sent to main office for re-stocking"}
              nameInput="dateSentMainOfficeRestocking"
              value={step.dateSentMainOfficeRestocking ?? ""}
              onResponse={onChangeInput}
              columnsSize={"12"}
              format={"dd/MM/yyyy"}
              maxDate={new Date()}
              disabled={disabledInputs}
            />
          </Col>
        </Row>

        <Row className="mt-2">
          <Col xs={12} lg={6}>
            <Row>
              <Form.Group controlId="customerName">
                <Col xs={12} lg={12}>
                  <Form.Label>
                    Description costs incurred by customer
                  </Form.Label>
                </Col>
                <Col xs={12} lg={12}>
                  {step.descriptionCostsGeneratedReturn !== undefined && (
                    <FormSelectMultiple
                      options={descriptionCostsGeneratedReturn}
                      onChange={onChangeInput}
                      nameField="descriptionCostsGeneratedReturn"
                      data={step.descriptionCostsGeneratedReturn ?? ""}
                      labelKey={"name"}
                      allowNew={true}
                    />
                  )}
                </Col>
              </Form.Group>
            </Row>
          </Col>
          <Col xs={12} lg={6}>
            {step.descriptionCostsGeneratedReturn && (
              <Form.Group className="form-group">
                <Row>
                  <Col sm={12} lg={12}>
                    <FormLabel>Value of costs incurred by customer</FormLabel>
                  </Col>
                  <Col sm={12} lg={12}>
                    <Form.Control
                      type="text"
                      name="valueCostsGeneratedReturn"
                      value={step.valueCostsGeneratedReturn ?? 0}
                      onChange={onChangeValue}
                      onBlur={onChangeValueBlur}
                    />
                  </Col>
                </Row>
              </Form.Group>
            )}
          </Col>
        </Row>

        <ReturnJewelleyToGD
          sale={sale}
          stepOrigin={step}
          onResponse={onResponseReturn}
          onResponseGD={onResponseGD}
        />
      </Row>
    </>
  );
};

SaleWorkflowEditReturnStepTwo.propTypes = {
  stepTwo: PropTypes.object.isRequired,
  disabledInputs: PropTypes.bool.isRequired,
  type: PropTypes.string,
  date: PropTypes.string,
  onResponseGD: PropTypes.func,
  sale: PropTypes.object,
};

SaleWorkflowEditReturnStepTwo.defaultProps = {
  disabledInputs: false,
  type: "",
  date: "",
};

export default SaleWorkflowEditReturnStepTwo;
