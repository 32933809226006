import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_STATUS_FAILED,
  API_STATUS_IDLE,
  API_STATUS_PENDING,
  API_STATUS_SUCCESS,
} from "../constants";
import axios from "../../axios";

const initialState = {
  error: null,
  lists: [],
  totalLists: 0,
  listCustomers: {},
  status: API_STATUS_IDLE,
  downloadCSV: false,
};

export const deleteCustomizedList = createAsyncThunk(
  "customers/customized-lists/delete",
  async (args, thunkAPI) => {
    try {
      const response = await axios.delete(
        `api/v1/customers/customized-lists/${encodeURIComponent(args.name)}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createCustomizedList = createAsyncThunk(
  "customers/customized-lists/create",
  async (args, thunkAPI) => {
    try {
      const response = await axios.post(
        "api/v1/customers/customized-lists/create",
        {
          name: args.name,
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchCustomersFromString = createAsyncThunk(
  "customers/customized-lists/details",
  async (args, thunkAPI) => {
    try {
      const { id } = args;
      const response = await axios.get(
        `/api/v1/customers/customized-lists/details/${id}`
      );
      const data = response.data;
      return { id: id, customers: data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchCustomerCustomizedLists = createAsyncThunk(
  "customers/customized-lists",
  async (args, thunkAPI) => {
    try {
      const response = await axios.get(`/api/v1/customers/customized-lists`);
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addCustomerCustomizedLists = createAsyncThunk(
  "customers/customized-lists/add",
  async (args, thunkAPI) => {
    try {
      const response = await axios.put(
        `/api/v1/customers/customized-lists/add`,
        {
          listId: args.listId,
          customerId: args.customerId,
        }
      );
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const removeCustomerCustomizedLists = createAsyncThunk(
  "customers/customized-lists/remove",
  async (args, thunkAPI) => {
    try {
      const response = await axios.put(
        `/api/v1/customers/customized-lists/remove`,
        {
          listId: args.listId,
          customerId: args.customerId,
        }
      );
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const batchAddCustomerCustomizedLists = createAsyncThunk(
  "customers/customized-lists/batchAdd",
  async (args, thunkAPI) => {
    try {
      const response = await axios.put(
        `/api/v1/customers/customized-lists/batchAdd`,
        {
          listId: args.listId,
          customerIds: JSON.stringify(args.customerIds),
        }
      );
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const customerCustomizedListsSlice = createSlice({
  name: "customerCustomizedLists",
  initialState,
  reducers: {
    setDownloadCSV(state, { payload }) {
      state.downloadCSV = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      fetchCustomerCustomizedLists.fulfilled,
      (state, action) => ({
        totalLists: action.payload.count,
        lists: action.payload.rows,
        error: null,
        listCustomers: {},
        status: API_STATUS_SUCCESS,
      })
    );
    builder.addCase(fetchCustomerCustomizedLists.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(fetchCustomerCustomizedLists.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    //fetchCustomersFromString
    builder.addCase(fetchCustomersFromString.fulfilled, (state, action) => {
      let tt = new Map();
      tt[action.payload.id] = action.payload.customers;
      return {
        totalLists: state.totalLists,
        lists: state.lists,
        listCustomers: { ...state.listCustomers, ...tt },
        error: null,
        status: API_STATUS_SUCCESS,
      };
    });
    builder.addCase(fetchCustomersFromString.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(fetchCustomersFromString.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    // createCustomizedList
    builder.addCase(createCustomizedList.fulfilled, (state, action) => ({
      totalLists: state.totalLists + 1,
      lists: [
        ...state.lists,
        {
          ...action.payload,
          customerIds: JSON.parse(action.payload.customerIds),
        },
      ],
      listCustomers: state.listCustomers,
      error: null,
      status: API_STATUS_SUCCESS,
    }));
    builder.addCase(createCustomizedList.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(createCustomizedList.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    // deleteCustomizedList
    builder.addCase(deleteCustomizedList.fulfilled, (state, action) => ({
      totalLists: action.payload.count,
      lists: action.payload.rows,
      listCustomers: state.listCustomers,
      error: null,
      status: API_STATUS_SUCCESS,
    }));
    builder.addCase(deleteCustomizedList.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(deleteCustomizedList.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    // addCustomerCustomizedLists
    // returns the modifed list only
    builder.addCase(addCustomerCustomizedLists.fulfilled, (state, action) => ({
      totalLists: state.totalLists,
      lists: [
        ...state.lists.filter((e) => e.id !== action.payload.id),
        action.payload,
      ],
      listCustomers: state.listCustomers,
      error: null,
      status: API_STATUS_SUCCESS,
    }));
    builder.addCase(addCustomerCustomizedLists.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(addCustomerCustomizedLists.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    //removeCustomerCustomizedLists
    builder.addCase(
      removeCustomerCustomizedLists.fulfilled,
      (state, action) => ({
        totalLists: state.totalLists,
        lists: [
          ...state.lists.filter((e) => e.id !== action.payload.id),
          action.payload,
        ],
        listCustomers: state.listCustomers,
        error: null,
        status: API_STATUS_SUCCESS,
      })
    );
    builder.addCase(removeCustomerCustomizedLists.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
    });
    builder.addCase(removeCustomerCustomizedLists.pending, (state, action) => {
      state.status = API_STATUS_PENDING;
    });
    // batchAddCustomerCustomizedLists
    builder.addCase(
      batchAddCustomerCustomizedLists.fulfilled,
      (state, action) => ({
        totalLists: state.totalLists,
        lists: [
          ...state.lists.filter((e) => e.id !== action.payload.id),
          action.payload,
        ],
        listCustomers: state.listCustomers,
        error: null,
        status: API_STATUS_SUCCESS,
      })
    );
    builder.addCase(
      batchAddCustomerCustomizedLists.rejected,
      (state, action) => {
        state.error = action.payload;
        state.status = API_STATUS_FAILED;
      }
    );
    builder.addCase(
      batchAddCustomerCustomizedLists.pending,
      (state, action) => {
        state.status = API_STATUS_PENDING;
      }
    );
  },
});

export const { setDownloadCSV } = customerCustomizedListsSlice.actions;

export default customerCustomizedListsSlice.reducer;
