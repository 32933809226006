import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import { sprintf } from "sprintf-js";
import moment from 'moment';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import axios from 'axios';
import * as globalConst from '../../global/const';
import Auth from '../../Auth';
import ConfirmRemoveFilters from './ConfirmRemoveFilters';

// Css
import "react-datepicker/dist/react-datepicker.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Util from '../../Util';

const DATEPICKER_FORMAT_DATE = 'dd/MM/yyyy'
const MOMENT_FORMAT_DATE = 'YYYY-DD-MM'
const AUTOCOMPLETE = 'autocomplete'
const DATE = 'date'

const CancelToken = axios.CancelToken;
let cancel = [];

const GENERAL_SEARCH = 'general_search'

class RenderFieldSearch extends Component {

    constructor(props) {
        super();

        this.state = {
            field: props.field,
            isEmpty: false,
            minDateChange: '',
            maxDateChange: '',
            numberMin: '',
            numberMax: '',
            optionsAutocompleted: [],
            allOptionsAutocompleted: [],
            isLoading: false,
            showRemoveFilter: false,
            selected: [],
            loadDate: false
        }

        this.loadOptionsAutoComplete = true
        this.dateChange = false
        this.updateData = false

        if (this.state.field.typeField === AUTOCOMPLETE) {
            setTimeout(() => {
                if (this.state.field.name != GENERAL_SEARCH) {
                    this.searchElementsAutocomplete('')
                }

                this.setIsEmpty(this.state.field)
            }, 1);
        } else {
            this.setDatepicker(this.state.field)
        }

        this.state.field.isEmptyReload = false
    }

    componentDidUpdate = (nextProps, nextState) => {
        this.setDatepicker(
            !this.dateChange ? nextProps.field : this.state.field
        )

        if (
            nextProps.field.isEmptyReload
        ) {
            this.setIsEmpty(nextProps.field)
        }
    }

    render() {
        let field = this.state.field

        return (
            <div className='row'>
                <div className='col-4 label-filter-advanced'>
                    {field.label}
                </div>
                <div className='col-6'>
                    <div className='row'>
                        <div className='col-12'>
                            {
                                field.typeField === 'select' ?
                                    this.renderSelect(field)
                                    :
                                    ''
                            }

                            {
                                field.typeField === AUTOCOMPLETE ?
                                    this.renderAutocomplete(field)
                                    :
                                    ''
                            }

                            {
                                field.typeField === DATE ?
                                    this.renderDatepicker(field)
                                    :
                                    ''
                            }

                            {
                                field.typeField === 'number' ?
                                    this.renderNumber()
                                    :
                                    ''
                            }
                        </div>
                        <div className='col-12'>
                            {
                                (field.name !== GENERAL_SEARCH) ?
                                    (
                                        this.renderIsEmpty(field)
                                    ) :
                                    (null)
                            }
                        </div>
                    </div>
                </div>
                <div className='col-2'>
                    <a
                        className='btn btn-outline-danger'
                        onClick={(e) => this.removeElement(field)}
                    >
                        <FontAwesomeIcon icon={faClose} />
                    </a>
                </div>

                {
                    this.state.showRemoveFilter === true ?
                        <ConfirmRemoveFilters
                            showModal={this.state.showRemoveFilter}
                            removeMultiple={false}
                            parentRemoveFilters={this.parentRemoveFilters} />
                        :
                        ('')
                }
            </div>
        )
    }

    removeElement = () => {
        this.setState({
            showRemoveFilter: true
        })
    }

    parentRemoveFilters = (value) => {
        if (value === true) {
            const field = this.state.field
            field.value = ''
            field.valueNew = ''
            field.isSelected = false
            field.showInAddFilter = true
            field.search = false
            field.isSelectedImportant = false
            field.isEmpty = false

            this.setState({
                field: field
            })

            setTimeout(() => {
                this.props.parentRemoveField(field)
            }, 2);
        }

        this.setState({
            showRemoveFilter: false
        })
    }

    renderAutocomplete = (field) => {
        if (field.name === GENERAL_SEARCH) {
            return this.renderAutocompleteNotAsync(field)
        }

        return this.renderAutocompleteAsync(field)
    }

    renderAutocompleteAsync = (field) => {
        this.setValueForAdvancedFilter(
            this.state.field,
            this.state.allOptionsAutocompleted
        )

        return (
            <div>
                <AsyncTypeahead
                    allowNew
                    multiple
                    id="async-autocomplete"
                    isLoading={this.state.isLoading}
                    labelKey={'value'}
                    minLength={0}
                    selected={this.state.selected}
                    onSearch={async (e) => this.searchElementsAutocomplete(e)}
                    options={this.state.optionsAutocompleted}
                    onChange={(selected) => { this.selectedAutocompleted(selected) }}
                    placeholder={"Search in ERP..."}
                    renderMenuItemChildren={(option) => (
                        <div>
                            {
                                (field.concat) ?
                                    (
                                        this.renderLabelAtucomplete(option)
                                    )
                                    :
                                    (option.value)
                            }
                        </div>
                    )}
                />
            </div>
        )
    }

    renderAutocompleteNotAsync = (field) => {
        if (this.loadOptionsAutoComplete) {
            this.loadAutoCompleteGeneralSearch(this.state.field)
        }

        this.setValueForAdvancedFilter(
            this.state.field,
            field.autoSelected,
            true
        )

        return (
            <div>
                <Typeahead
                    allowNew
                    multiple
                    id="async-autocomplete"
                    labelKey={'value'}
                    minLength={0}
                    selected={this.state.selected}
                    options={field.autoSelected}
                    onChange={(selected) => { this.selectedAutocompleted(selected) }}
                    placeholder={"Search in ERP..."}
                    defaultSelected={field.autoSelected}
                    renderMenuItemChildren={(option) => (
                        <div>
                            {
                                (field.concat) ?
                                    (
                                        this.renderLabelAtucomplete(option)
                                    )
                                    :
                                    (option.value)
                            }
                        </div>
                    )}
                />
            </div>
        )
    }

    loadAutoCompleteGeneralSearch = (field) => {
        field.autoSelected = []
        this.state.selected = []

        let options = field.value.split(',')

        options.map(option => {
            if (option.length > 0) {
                field.autoSelected.push({
                    value: option
                })
                this.state.selected.push({
                    value: option
                })
            }
        })

        this.state.selected = this.state.selected.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.value === value.value
            ))
        )
    }

    renderLabelAtucomplete = (option) => {
        return option.name + ' (' + option.contact_name + ')'
    }

    setValueForAdvancedFilter = (field, options, loadSearch = false) => {
        if (field.typeField === AUTOCOMPLETE) {
            let valuesArray = field.value.split(',')
            let selected = []

            valuesArray.map(value => {
                if (value.trim()) {
                    selected.push({
                        value: value.trim()
                    })
                }
            })

            let optionsData = []

            selected.map(select => {
                options.map(option => {
                    if (option.value === select.value) {
                        optionsData.push(option)
                    }
                })
            })

            this.state.selected = optionsData.length > 0 ? optionsData : selected
        }

        if (loadSearch) {
            this.loadAutoCompleteGeneralSearch(field)
        }
    }

    selectedAutocompleted = (selected) => {
        this.loadOptionsAutoComplete = false

        let texts = selected.map(value => {
            return value.value
        });

        let field = Object.assign({}, this.state.field)

        field.value = texts.toString()
        field.valueNew = texts.toString()

        this.props.parentResponseDataForAdvancedFilter(field)

        this.setState({
            field: field
        })

        this.response(field)
    }

    searchElementsAutocomplete = (value) => {
        this.setState({
            isLoading: true
        })

        if (cancel !== undefined && value != '') {
            cancel();
        }

        const url = sprintf(
            '%s%s',
            Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN"),
            globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_SEARCH_AUTOCOMPLETE
        )

        const params = {
            query: value,
            name: this.state.field.name,
            model: this.state.field.model,
            fields: this.state.field.fields
        }

        const config = {
            cancelToken: new CancelToken((c) => {
                cancel = c;
            }),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Auth.getToken()}`
            }
        }

        axios.post(
            url,
            params,
            config
        ).then(response => {
            let allOptionsAutocompleted = this.state.allOptionsAutocompleted;
            allOptionsAutocompleted.concat(response.data.value);
            this.state.allOptionsAutocompleted = allOptionsAutocompleted.filter(
                (value, index, array) => array.indexOf(value) === index
            )

            this.setState({
                optionsAutocompleted: response.data.value,
                isLoading: false
            })
        }).catch((error) => {
            if (axios.isCancel(error)) {
                return;
            }

            this.setState({
                optionsAutocompleted: [],
                isLoading: false
            })
        })
    }

    renderSelect = (field) => {
        return (
            <Form.Select
                value={field.valueNew ?? field.value}
                onChange={(e) => this.changeSelect(e.target.value)}
                name={'text_' + field.name}
            >
                <option value=''></option>
                {
                    field.valuesSelect.map(item => {
                        return (<option key={item.value} value={item.value}>{item.name}</option>)
                    })
                }
            </Form.Select>
        )
    }

    changeSelect = (value) => {
        let field = this.state.field

        if (value === 'true' || value === 'false') {
            field.value = JSON.parse(value.toLowerCase());
            field.valueNew = JSON.parse(value.toLowerCase());
        } else {
            field.value = value
            field.valueNew = value
        }

        this.response(field)
    }

    renderIsEmpty = (field) => {
        return (
            <Form.Group className="form-group float-right">
                <Form.Check
                    id={"isEmpty_" + field.name}
                    label="¿Is empty?"
                    checked={this.state.isEmpty}
                    onChange={(e) => this.changeIsEmpty(e.target.checked)}
                />
            </Form.Group>
        )
    }

    setIsEmpty = (field) => {
        this.state.isEmpty = field.isEmpty
    }

    changeIsEmpty = (value) => {
        this.updateData = true

        let field = Object.assign({}, this.state.field)

        field.isEmpty = value
        field.isEmptyReload = false

        this.state.isEmpty = value

        this.response(field)

        this.props.parentResponseDataForAdvancedFilter(field)
    }

    renderDatepicker = (field) => {
        return (
            <div className='row'>
                <div className='col-6'>
                    <DatePicker
                        className="form-control"
                        selected={this.state.minDateChange}
                        onChange={date => this.handleMinDateChange(date)}
                        dateFormat={DATEPICKER_FORMAT_DATE}
                        isClearable={false}
                        closeOnScroll={true}
                        placeholderText="From"
                    />
                </div>
                <div className='col-6'>
                    <DatePicker
                        className="form-control"
                        selected={this.state.maxDateChange}
                        onChange={date => this.handleMaxDateChange(date)}
                        dateFormat={DATEPICKER_FORMAT_DATE}
                        isClearable={false}
                        closeOnScroll={true}
                        placeholderText="To"
                    />
                </div>
            </div>

        )
    }

    setDatepicker = (field) => {
        if (!field.value) {
            return
        }

        let valuesArray = (field.valueNew ? field.valueNew + '' : field.value + '').split(',')

        if (valuesArray.length <= 0) {
            return
        }

        let minDateChange = valuesArray[0]
        let maxDateChange = valuesArray[1]

        if (minDateChange) {
            this.state.minDateChange = minDateChange ? new Date(
                moment(
                    minDateChange, 'YYYY/DD/MM'
                ).format('MM/DD/YYYY')) : ''
        }

        if (maxDateChange) {
            this.state.maxDateChange = maxDateChange ? new Date(
                moment(
                    maxDateChange, 'YYYY/DD/MM'
                ).format('MM/DD/YYYY')) : ''
        }
    }

    handleMinDateChange = (date) => {
        date = new Date(moment(
            date, 'YYYY/DD/MM'
        ).format('MM/DD/YYYY'))

        this.state.minDateChange = date

        setTimeout(() => {
            this.setValueDate()
        }, 1);
    }

    handleMaxDateChange = (date) => {
        date = new Date(moment(
            date, 'YYYY/DD/MM'
        ).format('MM/DD/YYYY'))

        this.state.maxDateChange = date

        setTimeout(() => {
            this.setValueDate()
        }, 1);
    }

    setValueDate = () => {
        this.dateChange = true
        let field = this.state.field

        if (this.state.minDateChange == 'Invalid Date') {
            this.state.minDateChange = ''
        }

        if (this.state.maxDateChange == 'Invalid Date') {
            this.state.maxDateChange = ''
        }

        const value = sprintf(
            '%s,%s',
            this.state.minDateChange ? moment(this.state.minDateChange).format(MOMENT_FORMAT_DATE) : '',
            this.state.maxDateChange ? moment(this.state.maxDateChange).format(MOMENT_FORMAT_DATE) : ''
        )

        field.value = value
        field.valueNew = value

        this.response(field)
    }

    renderNumber = () => {
        return (
            <div className='row'>
                <div className='col-6'>
                    <Form.Control
                        type='text'
                        onChange={(e => { this.changeNumberMin(e.target.value) })}
                        value={this.state.numberMin}
                    />
                </div>
                <div className='col-6'>
                    <Form.Control
                        type='text'
                        onChange={(e => { this.changeNumberMax(e.target.value) })}
                        value={this.state.numberMax}
                    />
                </div>
            </div>

        )
    }

    changeNumberMin = (field) => {
        this.setState({
            numberMin: field.replace(/\D/g, '')
        })

        this.setValueNumber()
    }

    changeNumberMax = (field) => {
        this.setState({
            numberMax: field.replace(/\D/g, '')
        })

        this.setValueNumber()
    }

    setValueNumber = () => {
        setTimeout(() => {
            let field = this.state.field

            const value = sprintf(
                '%s,%s',
                this.state.numberMin,
                this.state.numberMax
            )

            field.value = value
            field.valueNew = value

            this.response(field)
        }, 1)
    }

    response = (field) => {
        field.search = true

        setTimeout(() => {
            this.props.parentResponseDataField(field);
        }, 1);
    }
}

export default RenderFieldSearch;