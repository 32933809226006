import { Table, Button, Form, FormLabel } from "react-bootstrap";
import React, { Component } from "react";
import axios from "axios";
import Auth from "../../../../Auth";
import * as globalConst from "../../../../global/const";
import { sprintf } from "sprintf-js";

import "../../../../css/sales/sale_edit.scss";
import SaleCostsEditWorkflowRow from "./SaleCostsEditWorkflowRow";
import LoadingLayer from "../../../LoadingLayer";
import FlashMessages from "../../../FlashMessages";
import { GetSaleDiamondDetailsObject } from "../../detail/Actions/GetSaleDiamonds";
import Util from "../../../../Util";

const source = axios.CancelToken.source();

const TYPE_DELIVERING = "Delivering to customer";
const TYPE_PHOTOS = "Photos";
const SALE_MODEL = "salesModel";
const SALE_DIAMOND_MODEL = "saleDiamondModel";
const WORKFLOW_ACTIVITY_MODEL = "workflowActivityModel";
const WORKFLOW_METAL_MODEL = "workflowMetalModel";
const WORKFLOW_MELEE_MODEL = "workflowMeleeModel";
const EMPTY = "empty";
const TOTAL_COST = "totalCost";
const MODELS = [
  WORKFLOW_ACTIVITY_MODEL,
  WORKFLOW_MELEE_MODEL,
  WORKFLOW_METAL_MODEL,
];
const DIAMOND_COST_CONCEPT = "Diamond Cost";
const STATUS_UPDATED = "Updated";

class SaleCostsEditWorkflow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sale: props.sale,
      costs: [],
      updatingSale: true,
      loading: true,
      loadingSave: false,
      showCostRow: true,
      generalCostStatus: props.sale.generalCostStatus ?? "",
    };

    this.rowCostRef = React.createRef();

    this.workflowResults = [];
  }

  getWorkflow = () => {
    let url = sprintf(
      "%s%s/%s",
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN"),
      globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_SALE,
      this.state.sale.id
    );

    axios
      .get(
        url,
        {
          headers: { Authorization: `Bearer ${Auth.getToken()}` },
        },
        {
          cancelToken: source.token,
        }
      )
      .then((apiResponse) => {
        this.workflowResults = apiResponse.data.result.rows;

        let costs = this.setDataCost();

        this.setState({
          costs: costs,
          loading: false,
        });
        if (this.state.generalCostStatus !== STATUS_UPDATED) {
          this.setState({
            generalCostStatus:
              this.isAllAccountStatusUpdated("accountingStatus"),
          });
        }
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  setDataCost = () => {
    let results = this.workflowResults;
    let sale = this.state.sale;

    let costs = [];

    const costSale = this.setDataSaleCost(sale);

    if (costSale) {
      costs.push(costSale);
    }

    sale.sdm.map((saleDiamond) => {
      const costDiamond = {
        model: SALE_DIAMOND_MODEL,
        id: saleDiamond.id,
        saleLogId: sale.id,
        concept: "Diamond Cost",
        details: GetSaleDiamondDetailsObject(saleDiamond?.dpm) ?? "",
        cost: saleDiamond?.cost ?? "",
        provider: saleDiamond?.provider ?? "",
        providerDetail: saleDiamond?.provider ?? "",
        providerInvoiceNumber: saleDiamond?.providerInvoiceNumber ?? "",
        internalInvoice: saleDiamond?.internalInvoice ?? "",
        novaInvoice: saleDiamond?.novaInvoice ?? "",
        accountingStatus: saleDiamond?.accountingStatus ?? "",
        costReady: saleDiamond?.costReady ?? false,
        show: true,
      };

      costs.push(costDiamond);

      return saleDiamond;
    });

    results.map((workflow) => {
      workflow.workflowActivityModels.map((workflowActivity) => {
        if (
          workflowActivity.type === TYPE_DELIVERING ||
          workflowActivity.type === TYPE_PHOTOS
        ) {
          return workflowActivity;
        }

        costs.push({
          id: Math.random(),
          model: EMPTY,
          cost: 0,
          show: false,
        });

        let indexParent = costs.length;
        const type = workflowActivity.type;

        let costActivity = {
          model: WORKFLOW_ACTIVITY_MODEL,
          id: workflowActivity.id,
          concept: type,
          details: workflowActivity.stockNumber ?? "",
          cost: workflowActivity.cost ?? "",
          provider: workflowActivity.stockNumber ?? "",
          providerDetail: sprintf(
            "%s - %s",
            workflowActivity.invoiceTo ?? "",
            workflow.jewellerModel?.name ?? ""
          ),
          providerInvoiceNumber: workflowActivity.invoiceNumber ?? "",
          internalInvoice: workflowActivity.mmdiaInvoice ?? "",
          novaInvoice: workflowActivity.novaInvoice ?? "",
          accountingStatus: workflowActivity.invoiceStatus ?? "",
          costReady: workflowActivity.readyToPayToProvider ?? false,
          show: true,
          index: indexParent,
          indexParent: indexParent - 1,
        };

        if (costActivity.cost > 0) {
          costs.push(costActivity);
        }

        if (workflowActivity.workflowMetalModel) {
          let workflowMetal = workflowActivity.workflowMetalModel;

          let costMetal = {
            model: WORKFLOW_METAL_MODEL,
            id: workflowMetal.id,
            concept: `${type} - Metal`,
            details: this.getDetailsMetal(workflowMetal),
            cost: workflowMetal.metalCost ?? "",
            provider: workflowMetal.invoiceTo ?? "",
            providerDetail: sprintf(
              "%s - %s",
              workflowMetal.invoiceTo ?? "",
              workflowMetal.metalSupplierName ?? ""
            ),
            providerInvoiceNumber: workflowMetal.metalInvoiceNumber ?? "",
            internalInvoice: workflowMetal.internalInvoice ?? "",
            novaInvoice: "",
            accountingStatus: workflowMetal.invoiceStatus ?? "",
            costReady: workflowMetal.costReady,
            show: true,
            indexParent: indexParent,
            indexParentSeparete: indexParent - 1,
          };

          if (costMetal.cost > 0) {
            costs.push(costMetal);
          }
        }

        workflowActivity.workflowMelesModels.map((workflowMelee) => {
          let costMelee = {
            model: WORKFLOW_MELEE_MODEL,
            id: workflowMelee.id,
            concept: `${type} - Melees`,
            details: this.getDetailsMelee(workflowMelee),
            cost: workflowMelee.melesCost ?? "",
            provider: workflowMelee.invoiceTo ?? "",
            providerDetail: workflowMelee.invoiceTo ?? "",
            providerInvoiceNumber: workflowMelee.invoiceNumber ?? "",
            internalInvoice: workflowMelee.mmdiaInvoice ?? "",
            novaInvoice: workflowMelee.novaInvoice ?? "",
            accountingStatus: workflowMelee.invoiceStatus ?? "",
            costReady: workflowMelee.costReady,
            show: true,
            indexParent: indexParent,
            indexParentSeparete: indexParent - 1,
          };

          costs.push(costMelee);

          return workflowMelee;
        });

        return workflowActivity;
      });

      return workflow;
    });

    costs = this.showDataEmpty(costs);

    costs = this.calculateTotalCost(costs);

    return costs;
  };

  setDataSaleCost = (sale) => {
    if (sale.sdm.length > 0) {
      return null;
    }

    return {
      model: SALE_MODEL,
      id: sale.id,
      concept: "Diamond Cost",
      details: sale.diamondDetails ?? "",
      cost: sale.diamondCost ?? "",
      provider: sale.sellingPriceNova ?? "",
      providerDetail: sale.sellingPriceNova ?? "",
      providerInvoiceNumber: sale.diamondInvoiceNumber ?? "",
      internalInvoice: sale.xeroCodeMmdia ?? "",
      novaInvoice: sale.xeroCodeNova ?? "",
      accountingStatus: sale.accountingStatus ?? "",
      costReady: sale.costReady ?? false,
      show: true,
    };
  };

  showDataEmpty = (costs) => {
    costs.map((item) => {
      item = this.validShowDataCost(item);

      const { model, show, indexParent, indexParentSeparete } = item;

      if (MODELS.includes(model) && show && indexParent > 0) {
        costs[indexParent].show = true;
      }

      if (MODELS.includes(model) && show && indexParentSeparete) {
        costs[indexParentSeparete].show = true;
      }

      return item;
    });

    return costs;
  };

  validShowDataCost = (item) => {
    let { providerDetail, details } = item;

    const {
      cost,
      provider,
      providerInvoiceNumber,
      internalInvoice,
      novaInvoice,
      accountingStatus,
      costReady,
      model,
    } = item;

    if (!MODELS.includes(model)) {
      return item;
    }

    providerDetail = providerDetail.trim().replace("-", "");
    details = details.trim().replace("mm  pcs ct", "");

    if (
      !providerDetail &&
      !details &&
      !cost &&
      !provider &&
      !providerInvoiceNumber &&
      !internalInvoice &&
      !novaInvoice &&
      !accountingStatus &&
      !costReady
    ) {
      item.show = false;
    }

    return item;
  };

  calculateTotalCost = (costs) => {
    let sale = this.state.sale;
    let totalCost = 0;

    let noExistTotalCost = true;

    if (sale.costReady) {
      costs.map((cost) => {
        if (cost.cost > 0 && cost.model !== TOTAL_COST) {
          totalCost += parseInt(cost.cost) ?? 0;
        }

        if (cost.model === TOTAL_COST) {
          cost.cost = totalCost;
          noExistTotalCost = false;
        }

        return cost;
      });

      if (noExistTotalCost) {
        costs.push({
          concept: "Total cost",
          id: Math.random(),
          model: TOTAL_COST,
          cost: totalCost,
        });
      }
    }

    return costs;
  };

  getDetailsMetal = (workflowMetal) => {
    let details = "";

    if (workflowMetal.metalPlatinumWeight > 0) {
      details += `Platinum Metal, ${workflowMetal.metalPlatinumWeight} gm <br>`;
    }

    if (workflowMetal.metalRoseWeight > 0) {
      details += `Rose Metal, ${workflowMetal.metalRoseWeight} gm <br>`;
    }

    if (workflowMetal.metalWhiteWeight > 0) {
      details += `White Metal, ${workflowMetal.metalWhiteWeight} gm <br>`;
    }
    if (workflowMetal.metalYellowWeight > 0) {
      details += `Yellow Metal, ${workflowMetal.metalYellowWeight} gm <br>`;
    }

    return details;
  };

  getDetailsMelee = (workflowMelee) => {
    return sprintf(
      "%s %s mm %s pcs %sct",
      workflowMelee.melesDetails ?? "",
      workflowMelee.melesSize ?? "",
      workflowMelee.melesQuantity ?? "",
      workflowMelee.melesWeight ?? ""
    );
  };

  updateGeneralCostStatus = (name) => {
    const newGeneralCostStatus = this.isAllAccountStatusUpdated(name);
    this.setState({ generalCostStatus: newGeneralCostStatus });
  };

  changeGeneralCostStatus = (event) => {
    const { value } = event.target;

    this.setState({ generalCostStatus: value });

    const { costs, sale } = this.state;
    const updatedCosts = costs.map((cost) => {
      const isValidCost =
        cost.model !== TOTAL_COST &&
        cost.model !== EMPTY &&
        cost.show &&
        !(
          cost.concept === DIAMOND_COST_CONCEPT && sale.diamondDetails === null
        );

      // Update accountingStatus for valid costs
      if (isValidCost) {
        return {
          ...cost,
          accountingStatus: value, // Set the accountingStatus to the new value
        };
      }
      return cost; // Return unchanged cost if not valid
    });

    // Update the costs in the state
    this.setState({ costs: updatedCosts });
    this.activeButton(false);
  };

  isAllAccountStatusUpdated = (name) => {
    const { costs, sale } = this.state;
    let totalItem = 0;
    let totalUpdated = 0;

    costs.forEach((cost) => {
      const isValidCost =
        cost.model !== TOTAL_COST &&
        cost.model !== EMPTY &&
        cost.show &&
        !(
          cost.concept === DIAMOND_COST_CONCEPT && sale.diamondDetails === null
        );

      if (isValidCost) {
        totalItem += 1;

        if (cost[name]) {
          totalUpdated += 1;
        }
      }
    });

    return totalUpdated === totalItem && totalItem > 0 ? STATUS_UPDATED : "";
  };

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    return (
      <div id="SaleCostsEdit_component WorkflowSaleEdit_component">
        {this.state.loadingSave ? <LoadingLayer /> : null}

        {this.state.loading ? (
          <div className="center-element">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : null}

        {
          <FlashMessages
            error_messages={this.state.error_messages}
            warning_messages={this.state.warning_messages}
            success_messages={this.state.success_messages}
          />
        }

        {!this.state.loading ? (
          <div>
            <Form.Group className="form-group mb-2">
              <div className="row">
                <div className="col-6">
                  <Form.Group className="form-group">
                    <FormLabel>General accounting status:</FormLabel>
                    <Form.Select
                      onChange={(e) => this.changeGeneralCostStatus(e)}
                      value={this.state.generalCostStatus}
                      name={"generalCostStatus"}
                      required={true}
                    >
                      <option value=""></option>
                      <option value="Updated">Updated</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </div>
            </Form.Group>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Concept</th>
                  <th>Details</th>
                  <th>Cost</th>
                  <th>Provider</th>
                  <th>Provider invoice Number</th>
                  <th>Internal Invoice</th>
                  <th>Nova Invoice</th>
                  <th>Accounting status</th>
                  <th>Cost ready?</th>
                </tr>
              </thead>
              <tbody>
                {this.state.showCostRow ? (
                  <SaleCostsEditWorkflowRow
                    ref={this.rowCostRef}
                    costs={this.state.costs}
                    parentActiveButton={this.activeButton}
                    parentResponseData={this.responseData}
                    updateGeneralCostStatus={this.updateGeneralCostStatus}
                    sale={this.state.sale}
                  />
                ) : null}
              </tbody>
            </Table>

            <Form>
              <Button
                type="submit"
                onClick={this.updateCost}
                disabled={this.state.updatingSale}
              >
                Save Costs Information
              </Button>
            </Form>
          </div>
        ) : null}
      </div>
    );
  }

  activeButton = (activeButton) => {
    this.setState({
      updatingSale: activeButton,
    });

    if (!activeButton) {
      this.props.handleTabChange("costs", true);
    }
  };

  updateCost = (event) => {
    event.preventDefault();

    this.setState({
      loadingSave: true,
    });

    let sendCosts = [];

    this.state.costs.map((cost) => {
      if (cost.model === EMPTY) {
        return cost;
      }

      if (cost.model === TOTAL_COST) {
        return cost;
      }

      let structure = {};

      if (cost.model === SALE_MODEL) {
        structure = {
          id: cost.id,
          model: cost.model,
          diamondCost: cost.cost > 0 ? cost.cost : null,
          sellingPriceNova: cost.provider,
          diamondInvoiceNumber: cost.providerInvoiceNumber,
          xeroCodeMmdia: cost.internalInvoice,
          accountingStatus: cost.accountingStatus,
          generalCostStatus: this.state.generalCostStatus,
        };
      }

      if (cost.model === SALE_DIAMOND_MODEL) {
        structure = {
          id: cost.id,
          saleLogId: cost.saleLogId,
          model: cost.model,
          cost: cost.cost > 0 ? cost.cost : null,
          provider: cost.provider,
          providerInvoiceNumber: cost.providerInvoiceNumber,
          internalInvoice: cost.internalInvoice,
          accountingStatus: cost.accountingStatus,
          generalCostStatus: this.state.generalCostStatus,
        };
      }

      if (cost.model === WORKFLOW_ACTIVITY_MODEL) {
        structure = {
          id: cost.id,
          model: cost.model,
          mmdiaInvoice: cost.internalInvoice,
          invoiceStatus: cost.accountingStatus,
          generalCostStatus: this.state.generalCostStatus,
        };
      }

      if (cost.model === WORKFLOW_METAL_MODEL) {
        structure = {
          id: cost.id,
          model: cost.model,
          invoiceTo: cost.provider,
          metalInvoiceNumber: cost.providerInvoiceNumber,
          internalInvoice: cost.internalInvoice,
          invoiceStatus: cost.accountingStatus,
          generalCostStatus: this.state.generalCostStatus,
        };
      }

      if (cost.model === WORKFLOW_MELEE_MODEL) {
        structure = {
          id: cost.id,
          model: cost.model,
          invoiceTo: cost.provider,
          invoiceNumber: cost.providerInvoiceNumber,
          mmdiaInvoice: cost.internalInvoice,
          invoiceStatus: cost.accountingStatus,
          generalCostStatus: this.state.generalCostStatus,
        };
      }

      sendCosts.push(structure);

      return cost;
    });

    const url = sprintf(
      "%s%s",
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN"),
      globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_SAVE_COSTS
    );

    const config = {
      cancelToken: source.token,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    };

    axios
      .post(url, sendCosts, config)
      .then((response) => {
        let sale = response.data.value;

        this.setState({
          showCostRow: false,
          updatingSale: true,
          loadingSave: false,
          success_messages: ["Cost updated"],
          sale: sale,
        });

        let costs = this.calculateTotalCost(this.state.costs);

        this.setState({
          costs: costs,
          showCostRow: true,
        });

        this.props.handleTabChange("costs", false);
        this.props.saleUpdated(sale);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          this.setState({
            updatingSale: true,
            loadingSave: false,
          });

          return;
        }

        if (error.response) {
          let error_messages = [];

          if ("status" in error.response && error.response.status === 401) {
            error_messages = ["Not authorised."];
          } else {
            error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
          }

          this.setState({
            error_messages: error_messages,
            loadingSave: false,
          });
        } else if (error.request) {
          const error_messages = [
            "There was a problem processing the information. Please reload this page and try again.",
          ];
          this.setState({
            error_messages: error_messages,
          });
        }

        this.setState({
          updatingSale: true,
          loadingSave: false,
        });
      });
  };

  responseData = (costs) => {
    this.setState({
      costs: costs,
    });
  };

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  componentDidMount() {
    this.getWorkflow();
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default SaleCostsEditWorkflow;
