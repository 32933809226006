import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import HistoryTable from "./HistoryTable";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerAppointmentsLogs } from "../../../../store/slices";
import {
  API_STATUS_FAILED,
  API_STATUS_PENDING,
} from "../../../../store/constants";
import LoadingLayer from "../../../LoadingLayer";
import { clearCustomerLogs } from "../../../../store/slices/customerLogsSlice";

const CustomerHistory = ({ customer }) => {
  const dispatch = useDispatch();

  const { customerLogs, status } = useSelector((state) => state.customerLogs);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!loading) {
        setLoading(true);
        await dispatch(await fetchCustomerAppointmentsLogs(customer));
      }
    };

    fetchData();

    return () => {
      dispatch(clearCustomerLogs());
    };
  }, [customer, dispatch, loading]);

  return (
    <Col className="general_information customer-details-card">
      <h2 style={{ height: "42px" }}>
        <p className="align-self-center mt-2">History</p>
      </h2>

      <Col className="customer-details-container p-2">
        {status !== API_STATUS_PENDING ||
          (status !== API_STATUS_FAILED && <LoadingLayer />)}
        <HistoryTable customerLogs={customerLogs} />
      </Col>
    </Col>
  );
};

CustomerHistory.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default CustomerHistory;
