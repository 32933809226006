import React, { Component } from "react";
import { Modal, Button, Table, Form, FormLabel } from "react-bootstrap";
import axios from "axios";
import * as globalConst from "../../../../global/const";
import { sprintf } from "sprintf-js";
import Spinner from "react-bootstrap/Spinner";
import Auth from "../../../../Auth";
import ConfirmStockInShowroom from "../Actions/ConfirmStockInShowroom";
import { FindProductCategoryInJewellery } from "../Actions/Product";
import Util from "../../../../Util";

const CancelToken = axios.CancelToken;
let cancel;

class SearchStockNumber extends Component {
  filter = {
    stockNumber: "",
  };

  constructor(props) {
    super();

    this.state = {
      showListStockNumber: props.showListStockNumber,
      stockNumber: props.stockNumber,
      stockNumberData: null,
      isStockInShowroom: false,
      showIsStockInShowroom: false,
      loader: false,
      items: [],
      searchActive: false,
      saleWorkflow: props.saleWorkflow,
      sale: props.saleWorkflow.salesModel,
      loadProductCategory: true,
      productCategory: "",
    };
  }

  isStockInShowroom = (value = null) => {
    this.setState({
      showIsStockInShowroom: false,
      isStockInShowroom: value,
    });

    this.props.parentResponseStockNumber(this.state.stockNumberData);
    this.props.parentResponseIsStockInShowroom(value);
    this.props.parentClose();
  };

  componentDidMount() {
    const { sale = null } = this.state;

    if (!sale) {
      return;
    }

    const { ringPreference = "", saleType = "" } = sale;

    if (
      saleType === globalConst.REACT_APP_SALE_TYPE_JEWELLERY &&
      ringPreference
    ) {
      return this.findProductCategory(ringPreference);
    }

    this.setState({
      loadProductCategory: false,
    });
  }

  findProductCategory = async (reference) => {
    const productCategory = await FindProductCategoryInJewellery(reference);

    this.setState({
      productCategory: productCategory ?? "",
      loadProductCategory: false,
    });
  };

  render() {
    return (
      <Modal show={this.state.showListStockNumber} centered size="lg">
        <Modal.Header>List stock</Modal.Header>

        {this.state.loadProductCategory && (
          <div className="center-element">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}

        {!this.state.loadProductCategory && (
          <Modal.Body>
            <div className="row m-2">
              <div className="col-4">
                <FormLabel>Stock number</FormLabel>
              </div>
              <div className="col-8">
                <Form.Group className="form-group">
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    value={this.state.stockNumber}
                    onChange={(e) => this.changeStockNumber(e)}
                  />
                </Form.Group>
              </div>
            </div>

            {this.state.loader && (
              <div className="center-element">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}

            {this.state.items.length > 0 && this.rendeTable()}

            {this.state.items.length <= 0 &&
              !this.state.loader &&
              this.state.searchActive && (
                <div className="center-element">No found results</div>
              )}

            {this.state.showIsStockInShowroom ? (
              <ConfirmStockInShowroom
                message="Is this stock located in Novita's showrooms?"
                onResponse={this.isStockInShowroom.bind(this)}
              />
            ) : null}
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button onClick={() => this.close(this.state.stockNumber)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  changeStockNumber = (e) => {
    const value = e.target.value;

    this.setState({
      stockNumber: value,
    });

    this.filter.stockNumber = value;

    this.searchStockNumber();
  };

  rendeTable = () => {
    return (
      <div className="content-table-stock-number">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Category</th>
              <th>Location</th>
              <th>Provider</th>
              <th>Stock number</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{this.renderRow()}</tbody>
        </Table>
      </div>
    );
  };

  isValidOrderCategory = (
    orderJewelleryType,
    category,
    productCategory = ""
  ) => {
    switch (orderJewelleryType) {
      case globalConst.REACT_APP_SALE_TYPE_ENGAGEMENT_RINGS:
        if (category === globalConst.REACT_APP_SALE_TYPE_ENGAGEMENT_RINGS) {
          return false;
        }
        break;
      case globalConst.REACT_APP_SALE_TYPE_JEWELLERY:
        if (productCategory === category) {
          return false;
        }
        break;
      case globalConst.REACT_APP_SALE_TYPE_WEDDING_BANDS:
        if (
          category === globalConst.REACT_APP_SALE_TYPE_JEWELLERY_CATEGORY_RING
        ) {
          return false;
        }
        break;
      default:
        break;
    }

    return true;
  };

  renderRow = () => {
    const { sale = {} } = this.state;

    const jewelleryType = sale?.saleType;
    const jewellerModel = sale?.jewellerModel;

    return this.state.items.map((item) => {
      return (
        <tr key={item.stock_number}>
          <td>{item.category}</td>
          <td>{item.location_name}</td>
          <td>{item.provider}</td>
          <td>{item.stock_number}</td>
          <td>
            {jewellerModel?.name &&
            item.provider.trim().toUpperCase() !==
              jewellerModel.name.trim().toUpperCase() ? (
              <span className="error">Provider does not match</span>
            ) : (
              <>
                <button
                  onClick={(e) => {
                    this.selectStockNumber(item);
                  }}
                  className="btn btn-primary btn-novita-pink"
                >
                  Select
                </button>
                <br />
                {this.isValidOrderCategory(
                  jewelleryType,
                  item.category,
                  this.state.productCategory
                ) && (
                  <span className="error">
                    The stock number has a different category
                  </span>
                )}
              </>
            )}
          </td>
        </tr>
      );
    });
  };

  searchStockNumber = async () => {
    if (!this.filter.stockNumber) {
      this.setState({
        loader: false,
        items: [],
        searchActive: false,
      });

      return;
    }

    this.setState({
      loader: true,
      searchActive: true,
    });

    if (cancel !== undefined) {
      cancel();
    }

    const url = sprintf(
      "%s%s",
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN"),
      globalConst.REACT_APP_NOVITAERP_API_WORKFLOW_ACTIVITY_SEARCH_STOCK_NUMBER
    );

    let data = {
      query: this.filter.stockNumber,
    };

    const config = {
      cancelToken: new CancelToken((c) => {
        cancel = c;
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    };

    await axios
      .post(url, data, config)
      .then((response) => {
        this.setState({
          loader: false,
          items: response.data.value,
        });
      })
      .catch((error) => {
        this.setState({
          loader: false,
        });

        if (axios.isCancel(error)) {
          return;
        }
      })
      .finally(() => {
        this.setState({
          loader: false,
        });
      });
  };

  selectStockNumber = (value) => {
    const { saleType, diamondDetails } = this.state.sale;
    let attachedDiamondDetails = value.attached_diamond_details;

    if (
      (saleType === "Wedding Band" || saleType === "Jewellery") &&
      attachedDiamondDetails !== null
    ) {
      if (diamondDetails) {
        alert(
          "This stock number is linked to a diamond. To add this stock number, you need to first remove the diamond currently linked to this order."
        );
        return false;
      }
    }

    this.setState({
      stockNumberData: value,
      showIsStockInShowroom: true,
    });
  };

  close = () => {
    this.props.parentClose();
  };
}

export default SearchStockNumber;
