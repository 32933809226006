import { REACT_APP_WORKFLOW_TYPE_MANUFACTURING } from "../../../../global/const";

export const isManufacturingActivity = (workflowSale) => {
  if (!workflowSale) {
    return false;
  }

  const { workflowActivityModels = null } = workflowSale;

  if (!workflowActivityModels) {
    return false;
  }

  const types = workflowActivityModels.map((workflowActivity) => {
    return workflowActivity.type;
  });

  let isManufactury = false;

  const activities = [REACT_APP_WORKFLOW_TYPE_MANUFACTURING];

  types.forEach((type) => {
    if (activities.includes(type)) {
      isManufactury = true;
    }
  });

  return isManufactury;
};
