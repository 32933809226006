import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, Row } from "react-bootstrap";
import FormGroupQualityControl from "./FormGroupQualityControl";
import { useDispatch, useSelector } from "react-redux";
import {
  clearEdit,
  fetchUpdateQualityControl,
  setActiveChange,
} from "../../../../../store/slices";
import FormSelectCustomer from "../../../../Form/FormSelectCustomer";
import Auth from "../../../../../Auth";
import AlertMessage from "../../../../Utils/AlertMessage";
import {
  API_STATUS_FAILED,
  API_STATUS_PENDING,
  API_STATUS_SUCCESS,
} from "../../../../../store/constants";
import LoadingLayer from "../../../../LoadingLayer";
import Util from "../../../../../Util";

const FormQualityControl = ({ qualityControlCheck, editForm }) => {
  const listStatus = JSON.parse(
    Util.getStorageParameter("REACT_APP_QUALITY_CONTROL_CHECK_STATUS")
  );

  const dispatch = useDispatch();
  const { dataQuestions, error, statusEdit } = useSelector(
    (state) => state.qualityControlCheck
  );

  let { id, questions, approved } = qualityControlCheck;

  if (typeof questions === "string") {
    questions = JSON.parse(questions);
  }

  const [newQuestions, setNewQuestions] = useState(questions);
  const [newApproved, setNewApproved] = useState(approved);

  const employee = Auth.getEmployee();

  useEffect(() => {
    if (dataQuestions) {
      newQuestions[dataQuestions.groupIndex].questions[dataQuestions.index] =
        dataQuestions.data;

      setNewQuestions(newQuestions);
      dispatch(clearEdit());
      dispatch(setActiveChange(true));
    }
  }, [dataQuestions, dispatch, newQuestions]);

  const onChangeSelect = (value) => {
    if (value.length > 0) {
      const data = JSON.parse(value);

      setNewApproved(data?.id);

      dispatch(setActiveChange(true));
    }
  };

  const save = async (event) => {
    event.preventDefault();

    await dispatch(
      fetchUpdateQualityControl({
        id: id,
        questions: newQuestions,
        approved: newApproved,
        completedById: employee ? employee.id : null,
        completedOn: new Date(),
      })
    );
  };

  const validEditForm = () => {
    if (approved === true || approved === false) {
      return true;
    }

    const rolValid = Auth.canAccessRolesAndLeader(
      "rolesEditqualityControlCheck",
      true
    );

    if (rolValid) {
      return false;
    }

    return false;
  };

  const validChangeStatus = () => {
    const isOperationLeader = Auth.canAccessRolesAndLeader(
      "rolesEditqualityControlCheck",
      true
    );

    if (isOperationLeader) {
      return false;
    }

    const isOperationNoLeader = Auth.canAccessRolesAndLeader(
      "rolesEditqualityControlCheck"
    );

    if (isOperationNoLeader && (approved === null || approved === "")) {
      return false;
    }

    return true;
  };

  return (
    <Row lg={12}>
      <Col lg={12}>
        {statusEdit === API_STATUS_PENDING && <LoadingLayer />}

        {statusEdit === API_STATUS_FAILED && error && (
          <AlertMessage
            message={error ?? "Error to update quality control check"}
            autoClose={false}
            type="danger"
          />
        )}

        {statusEdit === API_STATUS_SUCCESS && (
          <AlertMessage
            message={"Quality Control Check updated"}
            autoClose={false}
            type="success"
          />
        )}

        <Form>
          {newQuestions.map((group, key) => {
            return (
              <FormGroupQualityControl
                group={group}
                key={key}
                index={key}
                editForm={validEditForm()}
              />
            );
          })}

          <Row className="mt-1">
            <Col xs={12} lg={6}>
              <FormSelectCustomer
                label="Status"
                nameInput="name"
                items={listStatus}
                keyValue={"id"}
                value={newApproved ?? ""}
                onResponse={onChangeSelect}
                columnsSize={12}
                disabled={validChangeStatus()}
              />
            </Col>
          </Row>

          <Button
            className="mt-1"
            type="submit"
            variant="primary"
            onClick={(event) => save(event)}
            disabled={!editForm}
          >
            Save
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

FormQualityControl.propTypes = {
  qualityControlCheck: PropTypes.object.isRequired,
  editForm: PropTypes.bool,
};

FormQualityControl.defaultProps = {
  editForm: true,
};

export default FormQualityControl;
