import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearWorkflowCad, createNewVersionStatus, fetchWorkflowCads, resetWorkflowCads, selectWorkFlowCad } from "../../../../../store/slices/workflowCadSlice";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import { convertUtcToTimezone } from "../../../../../utils/dateUtils";
import axiosServices from "../../../../../axios";
import Auth from "../../../../../Auth";
import Util from "../../../../../Util";

const WorkflowCadRequests = ({ activity, openRequestCADModel, saleWorkflow, openFollowUpRequestModel }) => {

    const { workflowCads, createdSort } = useSelector((state) => state.workflowCad);
    const [cancelToken, setCancelToken] = useState(null);
    const [loader, setLoader] = useState(true);
    const [listProps] = useState({
        workflowActivityId: activity.id,
        maxResults: 100,
        page: 1,
    });

    const hasFollowupStatusAccessLeaders = Auth.canAccessRolesAndLeader("rolesAccessCADFollowupStatus");
    const hasCADDownloadAccess = Auth.canAccessRolesAndLeader("rolesAccessCADDownLoad");
    const hasCADEditAccess = Auth.canAccessRolesAndLeader("rolesAccessCADEdit");
    const hasCADCreateNewVersion = Auth.canAccessRolesAndLeader("rolesAccessCADCreateNewVersion");

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearWorkflowCad());
        };
    }, [dispatch]);

    const callFetchWorkflowCads = useCallback(async () => {
        if (cancelToken) {
            cancelToken.cancel();
            setCancelToken(axios.CancelToken.source());
        }

        dispatch(fetchWorkflowCads({ ...listProps, cancelToken }));
        setLoader(false);
    }, [cancelToken, createdSort, dispatch, listProps]);

    useEffect(() => {
        setLoader(true);
        if (listProps.page > 1) {
            callFetchWorkflowCads(listProps);
        }
    }, [callFetchWorkflowCads, dispatch, listProps]);

    useEffect(() => {
        setLoader(true);
        dispatch(resetWorkflowCads());
        callFetchWorkflowCads(listProps);
    }, [createdSort]);

    const renderActions = (workflowCad) => {

        // Find all CAD files, not just the first one
        const cadFiles = workflowCad?.workflowCadFileModels.filter(file => file.type === 'cad') || [];

        const commonActions = [
            { label: 'Download Request (pdf)', onClick: handleDownload, access: hasCADDownloadAccess },
            { label: 'Follow up', onClick: handleFollowUp, access: hasFollowupStatusAccessLeaders },
            { label: 'Create new version', onClick: handleCreateNewVersion, access: hasCADCreateNewVersion },
            // If there are multiple CAD files, create a dropdown or sub-menu
            {
                label: 'Download CAD Files:',
                subMenu: cadFiles.length > 0 ? cadFiles.map((file, index) => ({
                    label: `File ${index + 1}`,
                    onClick: () => handleDownloadCad(file), // Pass the specific file to download
                })) : null,
                onClick: null,
                access: (cadFiles.length > 0 && hasCADDownloadAccess) ?? false,
                className: '',
                role: ''
            }
        ];

        const draftActions = [
            { label: 'Download Draft (pdf)', onClick: handleDownload, access: hasCADDownloadAccess },
            { label: 'Edit Draft', onClick: handleSelectWorkFlowCad, access: hasCADEditAccess }
        ];

        const actions = workflowCad.version === 0 ? draftActions : commonActions;

        // Filter actions where access is true
        const filteredActions = actions.filter(action => action.access);

        return (
            <>
                {filteredActions.map(({ label, onClick, subMenu, className = 'clickable', role = 'button' }, index) => (
                    <span
                        key={index}
                        className={`mr-5 ${className}`}
                        onClick={onClick ? (event) => { onClick(workflowCad, event) } : null}
                        role={role}
                    >
                        {label}
                        {/* If there's a subMenu, render the dropdown options */}
                        {subMenu && (
                            <ul className="dropdown">
                                {subMenu.map((subAction, subIndex) => (
                                    <li
                                        key={subIndex}
                                        className="dropdown-item clickable"
                                        onClick={subAction.onClick}
                                        role="button"
                                    >
                                        {subAction.label}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </span>
                ))}
            </>
        );
    };

    const handleFollowUp = (workflowCad, event) => {
        event.stopPropagation();
        dispatch(selectWorkFlowCad(workflowCad));
        openFollowUpRequestModel(event);
    }

    const handleSelectWorkFlowCad = (workflowCad, event) => {
        event.stopPropagation();
        dispatch(selectWorkFlowCad(workflowCad));
        openRequestCADModel(event);
    }

    const handleCreateNewVersion = (workflowCad, event) => {
        event.stopPropagation();
        dispatch(selectWorkFlowCad(workflowCad));
        openRequestCADModel(event);
        dispatch(createNewVersionStatus(true));
    }

    const handleDownload = async (workflowCad, event) => {
        event.stopPropagation();
        try {
            const response = await axiosServices.get(
                `/api/v1/workflow-activity/workflow-cad/download?id=${workflowCad.id}&salesId=${saleWorkflow.saleId}`,
                {
                    responseType: 'arraybuffer',
                }
            );
            const data = response.data;
            const url = window.URL.createObjectURL(new Blob([data]));

            // Create a link element to trigger the file download
            const link = document.createElement('a');
            link.href = url;
            link.download = 'workflow-cad-order.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Clean up the URL object to free up memory
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.log(error);
        }
    }

    const handleDownloadCad = async (workflowCad) => {
        try {
            if (workflowCad) {
                const response = await axiosServices.get(
                    `/api/v1/workflow-activity/workflow-cad/download-cad?id=${workflowCad.id}`,
                    {
                        responseType: 'arraybuffer',
                    }
                );
                const data = response.data;
                const url = window.URL.createObjectURL(new Blob([data]));

                // Create a link element to trigger the file download
                const link = document.createElement('a');
                link.href = url;
                link.download = workflowCad.filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Clean up the URL object to free up memory
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="workflowCadRequestTableList">
            <table className="table table-striped">
                <thead className="thead-dark">
                    <tr className="titles">
                        <th scope="col">REQUESTED ON</th>
                        <th scope="col">VERSION</th>
                        <th scope="col">REQUESTED BY</th>
                        <th scope="col">JEWELLER STATUS</th>
                        <th scope="col">SALES APPROVAL</th>
                        <th scope="col" className="action">ACTIONS</th>
                    </tr>
                </thead>
                <tbody className="overflow-scroll">
                    {loader &&
                        <tr>
                            <td colSpan={6}>
                                <div className='center-element'>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            </td>
                        </tr>
                    }
                    {workflowCads.length > 0 && workflowCads.map((workflowCad, index) => {
                        return (
                            <tr key={index}>
                                <td scope="col">{convertUtcToTimezone(
                                    workflowCad.requestedOn,
                                    "DD MMM, YYYY HH:mm",
                                    Util.getStorageParameter("REACT_APP_TIMEZONE")
                                )}</td>
                                <td scope="col">{(workflowCad.version === 0) ? 'Draft' : workflowCad.version}</td>
                                <td scope="col">{workflowCad.employeeModel?.name}</td>
                                <td scope="col">{workflowCad.jewellerStatus ?? '-'}</td>
                                <td scope="col">{workflowCad.salesRepStatus ?? '-'}</td>
                                <td scope="col" className="action">{renderActions(workflowCad)}</td>
                            </tr>
                        );
                    })}
                    {workflowCads.length <= 0 && !loader &&
                        <tr>
                            <td colSpan={6}>
                                <div className="alert center-element" role="alert">
                                    No results found
                                </div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    );
}
export default WorkflowCadRequests;