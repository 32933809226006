import { Form, FormLabel, Button } from "react-bootstrap";
import React, { Component } from "react";
import LoadingLayer from "../../LoadingLayer";
import FlashMessages from "../../FlashMessages";
import Auth from "../../../Auth";
import axios from "axios";
import DateField from "../formComponents/DateField";
import PostSaleJewellerRowEdit from "./PostSaleJewellerRowEdit";
import * as globalConst from "../../../global/const";

// SCSS
import "../../../css/sales/sale_edit.scss";
import { FindJewelleyByContactName } from "../../actions/JewelleryFind";
import Util from "../../../Util";

class PostSaleBackOfficeEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sale: props.sale,
      sheetType: props.sale.sheetType,
      error_messages: [],
      warning_messages: [],
      success_messages: [],
      updating_sale: false,
      fields_no_updated: true,
      dueDate: props.sale.dueDate,
      trackingBookingNumber: props.sale.trackingBookingNumber,
      destination: props.sale.destination,
      datePickupRequested: props.sale.datePickupRequested,
      dateReceivedInBackOffice: props.sale.dateReceivedInBackOffice,
      showroom: props.sale.showroom,
      status: props.sale.status,
      currentLocation: props.sale.currentLocation,
      inTransitTo: props.sale.inTransitTo,
      shippingDate: props.sale.shippingDate,
      jewellers: "",
      is_jewellers_edited: false,
      postSaleJewellerContainerId: 0,
      providers: [],
    };
    this.editSale = this.editSale.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.addNewJewellers = this.addNewJewellers.bind(this);
    this.deleteJeweller = this.deleteJeweller.bind(this);
    this.updateJeweller = this.updateJeweller.bind(this);

    this.setListShowroomCity();
    this.setListBackOfficeStatus();
  }

  setListShowroomCity = () => {
    this.listOrderStatus = [];

    if (Util.getStorageParameter("REACT_APP_SHOWROOM_CITY_OPTIONS")) {
      this.listShowroomCity =
        Util.getStorageParameter("REACT_APP_SHOWROOM_CITY_OPTIONS").split(",");
    }

    this.listShowroomCity = this.listShowroomCity.sort();
  };

  setListBackOfficeStatus = () => {
    this.listBackOfficeStatus = [];

    if (Util.getStorageParameter("REACT_APP_BACK_OFFICE_STATUS")) {
      this.listBackOfficeStatus =
        Util.getStorageParameter("REACT_APP_BACK_OFFICE_STATUS").split(",");
    }
  };

  unsavedJewellers = null;

  handleChange(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.value;
    if (event.target.value !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("back_office", true);
    }
    this.setState(state);
  }

  handleChangeSelect(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.value;
    if (event.target.value !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("back_office", true);
    }
    this.setState(state);
  }

  handleChangeDateField = (date, field_name) => {
    const state = {};
    if (date !== null && date !== "") {
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      state[field_name] = year + "-" + month + "-" + day;
    } else {
      state[field_name] = null;
    }
    if (date !== this.state[field_name]) {
      state["fields_no_updated"] = false;
      this.props.handleTabChange("back_office", true);
    }
    this.setState(state);
  };

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    const sale = this.state.sale;

    let jewellers;
    if (!this.state.is_jewellers_edited) {
      if (
        typeof this.state.jewellers !== "undefined" &&
        this.state.jewellers !== null
      ) {
        if (this.state.jewellers !== "") {
          try {
            jewellers = JSON.parse(this.state.jewellers);
          } catch (e) {
            console.log("Error: " + e);
          }
        }
      }
    } else {
      if (
        typeof this.unsavedJewellers !== "undefined" &&
        this.unsavedJewellers !== null
      ) {
        if (this.unsavedJewellers !== "") {
          try {
            jewellers = JSON.parse(this.unsavedJewellers);
          } catch (e) {
            console.log("Error: " + e);
          }
        }
      }
    }

    if (typeof jewellers === "undefined" || jewellers === null) {
      jewellers = [];
    }

    let dueDate = "";
    if (sale.dueDate !== null) {
      const dueDate_string = sale.dueDate.substring(0, 10);
      dueDate = new Date(dueDate_string);
      if (dueDate === "Invalid Date") {
        dueDate = "";
      }
    }

    let dateReceivedInBackOffice = "";
    if (sale.dateReceivedInBackOffice !== null) {
      const dateReceivedInBackOffice_string =
        sale.dateReceivedInBackOffice.substring(0, 10);
      dateReceivedInBackOffice = new Date(dateReceivedInBackOffice_string);
      if (dateReceivedInBackOffice === "Invalid Date") {
        dateReceivedInBackOffice = "";
      }
    }

    let datePickupRequested = "";
    if (sale.datePickupRequested !== null) {
      const datePickupRequested_string = sale.datePickupRequested.substring(
        0,
        10
      );
      datePickupRequested = new Date(datePickupRequested_string);
      if (datePickupRequested === "Invalid Date") {
        datePickupRequested = "";
      }
    }

    let shippingDate = "";
    if (sale.shippingDate !== null) {
      const shippingDate_string = sale.shippingDate.substring(0, 10);
      shippingDate = new Date(shippingDate_string);
      if (shippingDate === "Invalid Date") {
        shippingDate = "";
      }
    }

    return (
      <div id="SaleGeneralEdit_component">
        {this.state.updating_sale === true ? <LoadingLayer /> : ""}
        {
          <FlashMessages
            error_messages={this.state.error_messages}
            warning_messages={this.state.warning_messages}
            success_messages={this.state.success_messages}
          />
        }
        <Form>
          <div className="row">
            <div className="col-6">
              <Form.Group>
                <FormLabel className="d-block">Status</FormLabel>
                <Form.Select
                  onChange={(e) => this.handleChangeSelect(e)}
                  value={this.state.status ?? ""}
                  name="status"
                >
                  <option value=""></option>
                  {this.listBackOfficeStatus.map((orderStatus) => {
                    return (
                      <option key={orderStatus} value={orderStatus}>
                        {orderStatus}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              {this.state.status === "Internal transit" ? (
                <div className="col-12">
                  <Form.Group className="form_group_diamond_location card">
                    <div className="row">
                      <div className="col-12">
                        <div className="sale_title alight-center bold_font">
                          Internal Transit
                        </div>
                      </div>
                      <Form.Group>
                        <FormLabel className="d-block">
                          Job’s current location
                        </FormLabel>
                        <Form.Select
                          onChange={(e) => this.handleChangeSelect(e)}
                          value={this.state.currentLocation ?? ""}
                          name="currentLocation"
                        >
                          <option value=""></option>
                          {this.listShowroomCity.map((orderStatus) => {
                            return (
                              <option key={orderStatus} value={orderStatus}>
                                {orderStatus}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group>
                        <FormLabel className="d-block">In transit to</FormLabel>
                        <Form.Select
                          onChange={(e) => this.handleChangeSelect(e)}
                          value={this.state.inTransitTo ?? ""}
                          name="inTransitTo"
                        >
                          <option value=""></option>
                          {this.listShowroomCity.map((orderStatus) => {
                            return (
                              <option key={orderStatus} value={orderStatus}>
                                {orderStatus}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group>
                        <FormLabel>Shipping date</FormLabel>
                        <DateField
                          handleChangeDateField={this.handleChangeDateField}
                          field_name="shippingDate"
                          currentDate={shippingDate}
                        />
                      </Form.Group>
                    </div>
                  </Form.Group>
                </div>
              ) : null}
              <Form.Group>
                <FormLabel>Due Date</FormLabel>
                <DateField
                  handleChangeDateField={this.handleChangeDateField}
                  field_name="dueDate"
                  currentDate={dueDate}
                />
              </Form.Group>
              {this.state.sale.handledVia === "Courier transfer" ? (
                <div className="col-12">
                  <Form.Group className="form_group_diamond_location card">
                    <div className="row">
                      <div className="col-12">
                        <div className="sale_title alight-center bold_font">
                          Startrack Pickup Information
                        </div>
                      </div>
                      <Form.Group>
                        <FormLabel>
                          Pick up tracking # and Booking Number
                        </FormLabel>
                        <Form.Control
                          type="text"
                          name="trackingBookingNumber"
                          value={
                            this.state.trackingBookingNumber === null
                              ? ""
                              : this.state.trackingBookingNumber
                          }
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                      <Form.Group>
                        <FormLabel className="d-block">Destination</FormLabel>
                        <Form.Select
                          onChange={(e) => this.handleChangeSelect(e)}
                          value={this.state.destination ?? ""}
                          name="destination"
                        >
                          <option value=""></option>
                          {this.listShowroomCity.map((orderStatus) => {
                            return (
                              <option key={orderStatus} value={orderStatus}>
                                {orderStatus}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group>
                        <FormLabel>Pick up date requested</FormLabel>
                        <DateField
                          handleChangeDateField={this.handleChangeDateField}
                          field_name="datePickupRequested"
                          currentDate={datePickupRequested}
                        />
                      </Form.Group>
                    </div>
                  </Form.Group>
                </div>
              ) : null}
              <Form.Group>
                <FormLabel>Date received in the back office</FormLabel>
                <DateField
                  handleChangeDateField={this.handleChangeDateField}
                  field_name="dateReceivedInBackOffice"
                  currentDate={dateReceivedInBackOffice}
                />
              </Form.Group>
              {this.state.sale.handledVia === "Showroom" ? (
                <div>
                  <div>
                    <Form.Group>
                      <FormLabel className="d-block">Showroom</FormLabel>
                      <Form.Select
                        onChange={(e) => this.handleChangeSelect(e)}
                        value={this.state.showroom ?? ""}
                        name="showroom"
                      >
                        <option value=""></option>
                        {this.listShowroomCity.map((orderStatus) => {
                          return (
                            <option key={orderStatus} value={orderStatus}>
                              {orderStatus}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div>
                    <Form.Group>
                      <FormLabel className="d-block">
                        Office Handling Resize/fix
                      </FormLabel>
                      <Form.Select
                        onChange={(e) => this.handleChangeSelect(e)}
                        value={this.state.destination ?? ""}
                        name="destination"
                      >
                        <option value=""></option>
                        {this.listShowroomCity.map((orderStatus) => {
                          return (
                            <option key={orderStatus} value={orderStatus}>
                              {orderStatus}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="col-6">
              <Form.Group className="form_group_create card">
                {this.state.providers.length > 0 ? (
                  <div className="row">
                    <div className="col-12">
                      <div className="sale_title alight-center bold_font">
                        Jewellers
                      </div>
                      <div
                        className="sale_title alight-center bold_font add_jeweller"
                        onClick={this.addNewJewellers}
                      >
                        + Add new jewellers
                      </div>
                      <div key={this.state.postSaleJewellerContainerId}>
                        {typeof jewellers !== "undefined" &&
                        jewellers !== null &&
                        jewellers !== ""
                          ? jewellers.map((jeweller, index) => {
                              return (
                                <PostSaleJewellerRowEdit
                                  key={index}
                                  index={index}
                                  jeweller={jeweller}
                                  providers={this.state.providers}
                                  deleteJeweller={this.deleteJeweller}
                                  updateJeweller={this.updateJeweller}
                                />
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                ) : null}
              </Form.Group>
            </div>
          </div>
          <Button
            type="submit"
            onClick={this.editSale}
            disabled={this.state.creating_sale}
          >
            Update
          </Button>
        </Form>
      </div>
    );
  }

  axioRequest = null;
  editSale(event) {
    this.setState({
      jewellers: JSON.stringify(this.unsavedJewellers),
    });

    event.preventDefault();
    this.setState({
      updating_sale: true,
    });
    const user = Auth.getAuthenticatedUser();
    const sale = this.props.sale;
    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else if (sale === null || sale === undefined) {
      this.setState({
        error_messages: [
          "Sale identification error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_POST_SALE_SERVICES_EDIT;
      url = url.replace("{id}", sale.id);
      const params = new URLSearchParams();

      let saleId = this.state.sale.id;
      let status = this.state.status;
      if (status === null || typeof status === "undefined") {
        status = this.state.sale.status;
      }
      let dueDate = this.state.dueDate;
      if (dueDate === null || typeof dueDate === "undefined") {
        dueDate = this.state.sale.dueDate;
      }
      let showroom = this.state.showroom;
      if (showroom === null || typeof showroom === "undefined") {
        showroom = this.state.sale.showroom;
      }
      let trackingBookingNumber = this.state.trackingBookingNumber;
      if (
        trackingBookingNumber === null ||
        typeof trackingBookingNumber === "undefined"
      ) {
        trackingBookingNumber = this.state.sale.trackingBookingNumber;
      }
      let destination = this.state.destination;
      if (destination === null || typeof destination === "undefined") {
        destination = this.state.sale.destination;
      }
      let datePickupRequested = this.state.datePickupRequested;
      if (
        datePickupRequested === null ||
        typeof datePickupRequested === "undefined"
      ) {
        datePickupRequested = this.state.sale.datePickupRequested;
      }
      let dateReceivedInBackOffice = this.state.dateReceivedInBackOffice;
      if (
        dateReceivedInBackOffice === null ||
        typeof dateReceivedInBackOffice === "undefined"
      ) {
        dateReceivedInBackOffice = this.state.sale.dateReceivedInBackOffice;
      }
      let currentLocation = this.state.currentLocation;
      if (currentLocation === null || typeof currentLocation === "undefined") {
        currentLocation = this.state.sale.currentLocation;
      }
      let inTransitTo = this.state.inTransitTo;
      if (inTransitTo === null || typeof inTransitTo === "undefined") {
        inTransitTo = this.state.sale.inTransitTo;
      }
      let shippingDate = this.state.shippingDate;
      if (shippingDate === null || typeof shippingDate === "undefined") {
        shippingDate = this.state.sale.shippingDate;
      }

      params.append("id", saleId);
      params.append("status", status);
      params.append("dueDate", dueDate);

      if (this.state.sale.handledVia === "Showroom") {
        params.append("showroom", showroom);
        params.append("destination", destination);
      } else {
        params.append("trackingBookingNumber", trackingBookingNumber);
        params.append("datePickupRequested", datePickupRequested);
      }
      params.append("dateReceivedInBackOffice", dateReceivedInBackOffice);
      if (status === "Internal transit") {
        params.append("currentLocation", currentLocation);
        params.append("inTransitTo", inTransitTo);
        params.append("shippingDate", shippingDate);
      }

      params.append("jewellers", this.unsavedJewellers);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      axios
        .put(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              updating_sale: false,
              success_messages: ["Post sale updated"],
              is_jewellers_edited: false,
            });
            this.props.handleTabChange("back_office", false);
            this.props.saleUpdated(data.result);
            this.props.updateJewellers(this.unsavedJewellers);
          } else {
            this.setState({
              updating_sale: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              updating_sale: false,
              error_messages: error_messages,
            });
          } else {
            //error not detected. Request could have been cancelled and it's waiting for second
            //request to finish
            this.setState({
              updating_sale: true,
            });
          }
          //console.log((err);
          return null;
        });
    }
  }

  addNewJewellers() {
    let jewellers;
    if (!this.state.is_jewellers_edited) {
      if (
        typeof this.state.jewellers !== "undefined" &&
        this.state.jewellers !== null
      ) {
        if (this.state.jewellers !== "") {
          try {
            jewellers = JSON.parse(this.state.jewellers);
          } catch (e) {
            console.log("Error: " + e);
          }
        }
      }
    } else {
      if (
        typeof this.unsavedJewellers !== "undefined" &&
        this.unsavedJewellers !== null
      ) {
        if (this.unsavedJewellers !== "") {
          try {
            jewellers = JSON.parse(this.unsavedJewellers);
          } catch (e) {
            console.log("Error: " + e);
          }
        }
      }
    }

    if (typeof jewellers === "undefined" || jewellers === null) {
      jewellers = [];
    }

    let newJeweller = {
      jeweller: "",
      datePickedUpFromJeweller: "",
      dateGivenToJeweller: "",
      jobPackage: "",
      notes: "",
      cost: "",
      jewellersInvoice: "",
    };

    jewellers.push(newJeweller);

    this.unsavedJewellers = JSON.stringify(jewellers);
    this.setState({
      is_jewellers_edited: true,
    });

    this.props.handleTabChange("back_office", true);
    this.props.updateJewellers(JSON.stringify(jewellers));
  }

  deleteJeweller(indexToDelete) {
    let jewellers;
    if (!this.state.is_jewellers_edited) {
      if (
        typeof this.state.jewellers !== "undefined" &&
        this.state.jewellers !== null
      ) {
        if (this.state.jewellers !== "") {
          try {
            jewellers = JSON.parse(this.state.jewellers);
          } catch (e) {
            console.log("Error: " + e);
          }
        }
      }
    } else {
      if (
        typeof this.unsavedJewellers !== "undefined" &&
        this.unsavedJewellers !== null
      ) {
        if (this.unsavedJewellers !== "") {
          try {
            jewellers = JSON.parse(this.unsavedJewellers);
          } catch (e) {
            console.log("Error: " + e);
          }
        }
      }
    }

    if (typeof jewellers === "undefined" || jewellers === null) {
      jewellers = [];
    }

    jewellers.splice(indexToDelete, 1);

    this.unsavedJewellers = JSON.stringify(jewellers);
    this.setState({
      is_jewellers_edited: true,
      postSaleJewellerContainerId: Math.random(), //this ensure that the whole component would rerender on delete
    });

    this.props.handleTabChange("back_office", true);
    this.props.updateJewellers(JSON.stringify(jewellers));
  }

  updateJeweller(updateIndex, state) {
    let jewellers;
    if (!this.state.is_jewellers_edited) {
      if (
        typeof this.state.jewellers !== "undefined" &&
        this.state.jewellers !== null
      ) {
        if (this.state.jewellers !== "") {
          try {
            jewellers = JSON.parse(this.state.jewellers);
          } catch (e) {
            console.log("Error: " + e);
          }
        }
      }
    } else {
      if (
        typeof this.unsavedJewellers !== "undefined" &&
        this.unsavedJewellers !== null
      ) {
        if (this.unsavedJewellers !== "") {
          try {
            jewellers = JSON.parse(this.unsavedJewellers);
          } catch (e) {
            console.log("Error: " + e);
          }
        }
      }
    }

    let changeStatusToReady = false;
    if (jewellers.length > 0) {
      if (typeof jewellers[updateIndex] !== "undefined") {
        if ("jeweller" in state)
          jewellers[updateIndex].jeweller = state.jeweller;
        if ("datePickedUpFromJeweller" in state) {
          jewellers[updateIndex].datePickedUpFromJeweller =
            state.datePickedUpFromJeweller;
          changeStatusToReady = true;
        }
        if ("dateGivenToJeweller" in state) {
          jewellers[updateIndex].dateGivenToJeweller =
            state.dateGivenToJeweller;
        }
        if ("jobPackage" in state)
          jewellers[updateIndex].jobPackage = state.jobPackage;
        if ("notes" in state) jewellers[updateIndex].notes = state.notes;

        this.unsavedJewellers = JSON.stringify(jewellers);

        if (changeStatusToReady) {
          this.setState({
            is_jewellers_edited: true,
            //postSaleJewellerContainerId: Math.random(), //this ensure that the whole component would rerender on delete
          });
        } else {
          this.setState({
            is_jewellers_edited: true,
            //postSaleJewellerContainerId: Math.random(), //this ensure that the whole component would rerender on delete
          });
        }

        this.props.handleTabChange("back_office", true);
        this.props.updateJewellers(JSON.stringify(jewellers));
      }
    }
  }

  updateGeneralTabSheetType(sheetType) {
    this.setState({
      sheetType: sheetType,
    });
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  static getDerivedStateFromProps(props, state) {
    return {
      jewellers: props.sale.jewellers,
      postSaleJewellerContainerId: Math.random(), //this ensure that the whole component would rerender on delete
    };
  }

  getJewellers = async () => {
    const jewellers = await FindJewelleyByContactName();

    this.setState({
      providers: jewellers?.items ?? [],
    });
  };

  componentDidMount() {
    this.setState({
      jewellers: this.props.sale.jewellers,
    });
    this.unsavedJewellers = this.props.sale.jewellers;

    this.getJewellers();
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default PostSaleBackOfficeEdit;
