import axios from "axios"
import { sprintf } from "sprintf-js";
import * as globalConst from '../../../../global/const';
import Auth from "../../../../Auth";
import Util from "../../../../Util";

const CancelToken = axios.CancelToken;
let cancel;

export const SaleCommissionEmployeeFind = async (
    saleId,
    employee, 
    type
) => {
    try {
        const url = sprintf(
            '%s%s',
            Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN"),
            globalConst.REACT_APP_NOVITAERP_API_SALE_EMPLOYEE_COMMISSION_DETAIL
        )

        const parameters = {
            saleId: saleId,
            employeeName: employee,
            type: type
        }

        if (cancel !== undefined) {
            cancel();
        }

        return await axios.get(
            url,
            {
                params: parameters,
                cancelToken: new CancelToken((c) => {
                    cancel = c;
                }),
                headers: { Authorization: `Bearer ${Auth.getToken()}` }
            }
        )
            .then(response => {
                const { data } = response;
                const { value } = data;

                if (value) {
                    return value
                }

                return null
            }).catch(() => {
                return null
            })
    } catch (error) {
        return null
    }
}