import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, Col, Form, FormLabel, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import * as globalConst from "../../../global/const";
import Util from "../../../Util";

const SHIP_CUSTOMERS_ADDRESS = "shipCustomersAddress";
const PICK_UP_AT_SHOWROOM = "pickUpAtShowroom";

const SaleCreateShippingAdrressData = (props) => {
  const { saleData, onResponse } = props;
  const [sale, setSale] = useState(saleData);

  const listCountries = globalConst.LIST_COUNTRIES.map((country) => {
    return { value: country, id: country };
  });

  const listShippingShowrooms =
    Util.getStorageParameter("REACT_APP_LIST_SHIPPING_TO_SHOWROOM").split("~");

  const clearDataShipping = (name, value) => {
    onResponse(value, name);

    if (name === SHIP_CUSTOMERS_ADDRESS) {
      setSale((prevState) => ({
        ...prevState,
        shippingTo: null,
      }));

      onResponse(null, "shippingTo");
    }

    if (name === PICK_UP_AT_SHOWROOM) {
      setSale((prevState) => ({
        ...prevState,
        shippingAddress: null,
        shippingCountry: null,
        shippingPostCode: null,
        shippingState: null,
        shippingSuburb: null,
      }));

      const data = [
        "shippingAddress",
        "shippingCountry",
        "shippingPostCode",
        "shippingState",
        "shippingSuburb",
      ];

      data.map((item) => onResponse(null, item));
    }
  };

  const handleChange = (e) => {
    const data = sale;

    const { value, name } = e.target;

    data[name] = value;

    setSale((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    onResponse(value, name);
  };

  const handleChangeCountry = (options, name) => {
    if (options.length <= 0) {
      return;
    }

    const option = options[0];

    setSale((prevState) => ({
      ...prevState,
      [name]: option.value,
    }));

    onResponse(option.value, name);
  };

  const handleChangeCheckboxShipping = (event, disabledField = "") => {
    const { checked, name } = event.target;

    setSale((prevState) => ({
      ...prevState,
      [name]: checked,
    }));

    if (disabledField) {
      setSale((prevState) => ({
        ...prevState,
        [disabledField]: false,
      }));
    }

    clearDataShipping(name, checked);
  };

  return (
    <>
      <Form.Check type={"switch"}>
        <Form.Check.Input
          className="check-all-link"
          name="shipCustomersAddress"
          type="checkbox"
          id="shipCustomersAddress"
          checked={sale.shipCustomersAddress ?? true}
          onChange={(e) => handleChangeCheckboxShipping(e, "pickUpAtShowroom")}
        />
        <Form.Check.Label
          className="check-all-link"
          htmlFor="shipCustomersAddress"
        >
          Ship to customer's address
        </Form.Check.Label>
      </Form.Check>

      {sale.shipCustomersAddress ? (
        <Card>
          <Card.Body>
            <Card.Title>Shipping Information</Card.Title>
            <Row>
              <Col sm="12" lg="4">
                <Form.Group className="form-group">
                  <FormLabel>Shipping address *</FormLabel>
                  <Form.Control
                    required={sale.shipCustomersAddress}
                    type="text"
                    name="shippingAddress"
                    value={sale.shippingAddress ?? ""}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Shipping address is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm="12" lg="4">
                <Form.Group className="form-group">
                  <FormLabel>Shipping suburb *</FormLabel>
                  <Form.Control
                    required={sale.shipCustomersAddress}
                    type="text"
                    name="shippingSuburb"
                    value={sale.shippingSuburb ?? ""}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Shipping suburb is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm="12" lg="4">
                <Form.Group className="form-group">
                  <FormLabel>Shipping Postcode *</FormLabel>
                  <Form.Control
                    required={sale.shipCustomersAddress}
                    type="text"
                    name="shippingPostCode"
                    value={sale.shippingPostCode ?? ""}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Shipping Postcode is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="4">
                <Form.Group className="form-group">
                  <FormLabel>Shipping State *</FormLabel>
                  <Form.Control
                    required={sale.shipCustomersAddress}
                    type="text"
                    name="shippingState"
                    value={sale.shippingState ?? ""}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Shipping State is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm="12" lg="4">
                <Form.Group className="form-group">
                  <FormLabel>Shipping Country *</FormLabel>
                  <Typeahead
                    name="shippingCountry"
                    id="shipping-country"
                    labelKey="value"
                    options={listCountries}
                    placeholder="Choose a country..."
                    defaultSelected={[]}
                    onChange={(e) => handleChangeCountry(e, "shippingCountry")}
                  />
                  <Form.Control
                    required={sale.shipCustomersAddress}
                    hidden={true}
                    name="shippingCountry"
                    value={sale.shippingCountry ?? ""}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Shipping Country is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : null}

      <Form.Check type={"switch"}>
        <Form.Check.Input
          className="check-all-link"
          name="pickUpAtShowroom"
          type="checkbox"
          id="pickUpAtShowroom"
          checked={sale.pickUpAtShowroom ?? false}
          onChange={(e) =>
            handleChangeCheckboxShipping(e, "shipCustomersAddress")
          }
        />
        <Form.Check.Label className="check-all-link" htmlFor="pickUpAtShowroom">
          Pick up at showroom
        </Form.Check.Label>
      </Form.Check>

      {sale.pickUpAtShowroom ? (
        <Card>
          <Card.Body>
            <Card.Title>Showroom data</Card.Title>
            <Row>
              <Col sm="12" lg="6">
                <FormLabel>Showroom *</FormLabel>
                <Form.Select
                  required={sale.pickUpAtShowroom}
                  onChange={(e) => handleChange(e)}
                  value={sale.shippingTo ?? ""}
                  name="shippingTo"
                >
                  <option value=""></option>
                  {listShippingShowrooms.map((listShippingShowroom) => {
                    return (
                      <option
                        key={listShippingShowroom}
                        value={listShippingShowroom}
                      >
                        {listShippingShowroom}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Showroom is required
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : null}
    </>
  );
};

SaleCreateShippingAdrressData.propTypes = {
  saleData: PropTypes.any.isRequired,
  onResponse: PropTypes.func.isRequired,
};

export default SaleCreateShippingAdrressData;
