import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../axios";
import {
  API_STATUS_SUCCESS,
  API_STATUS_PENDING,
  API_STATUS_FAILED,
} from "../constants";

const initialState = {
  error: null,
  status: "",
  verify: null,
};

export const fetchLoginVerify2af = createAsyncThunk(
  "/user/2fa/verify",
  async (args, thunkAPI) => {
    try {
      const response = await axios.post(`/api/v1/user/2fa/verify`, {
        secret: args.secret,
        token: args.token,
      });

      const { data } = response;
      const { value = null } = data;

      if (value) {
        return value;
      }

      return [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    clearLogin(state) {
      state.verify = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchLoginVerify2af.fulfilled, (state, action) => {
      state.error = null;
      state.status = API_STATUS_SUCCESS;
      state.verify = action.payload;
    });
    builder.addCase(fetchLoginVerify2af.rejected, (state, action) => {
      state.error = action.payload;
      state.status = API_STATUS_FAILED;
      state.verify = false;
    });
    builder.addCase(fetchLoginVerify2af.pending, (state) => {
      state.status = API_STATUS_PENDING;
    });
  },
});

export const { clearLogin } = loginSlice.actions;

export default loginSlice.reducer;
