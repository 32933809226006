import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_STATUS_FAILED,
  API_STATUS_PENDING,
  API_STATUS_SUCCESS,
} from "../constants";
import axios from "../../axios";
import Auth from "../../Auth";

const initialState = {
  selectedReport: null,
  loadingRepots: false,
  resultAnalysis: {},
  resultAnalysisDetails: [],
  reportRequest: {},
  reportRequestList: { items: [], count: 0 },
  downloadExcel: false,
  downloadExcelNameReport: "",
  resultCommissionsEfficincys: {},
};

export const fetchResultAnalysis = createAsyncThunk(
  "/resultAnalysis",
  async (args, thunkAPI) => {
    try {
      const response = await axios.post(`/api/v1/reports/resultAnalysis`, {
        startDate: args.startDate,
        endDate: args.endDate,
        employee: args.employee,
      });

      const { status = null } = response;
      const { data = {} } = response;
      const { value } = data;

      if (Object.keys(value).length > 0 && status === 200) {
        return value;
      }

      return {};
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchResultCommissionsAnalysis = createAsyncThunk(
  "/resultCommissionsAnalysis",
  async (args, thunkAPI) => {
    try {
      const response = await axios.post(
        `/api/v1/reports/resultCommissionsAnalysis`,
        {
          startDate: args.startDate,
          endDate: args.endDate,
          startDatePaid: args.startDatePaid ?? "",
          endDatePaid: args.endDatePaid ?? "",
          employee: args.employee,
          typeOfReport: args.typeOfReport,
        }
      );

      const { status = null } = response;
      const { data = {} } = response;
      const { value } = data;

      if (Object.keys(value).length > 0 && status === 200) {
        return value;
      }

      return {};
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchResultAnalysisDetails = createAsyncThunk(
  "/resultAnalysisDetails",
  async (args, thunkAPI) => {
    try {
      const response = await axios.post(
        `/api/v1/reports/resultAnalysisDetails`,
        {
          startDate: args.startDate,
          endDate: args.endDate,
          employee: args.employee,
        }
      );

      const { status = null } = response;
      const { data = {} } = response;
      const { value } = data;

      if (Object.keys(value).length > 0 && status === 200) {
        return value;
      }

      return [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchResultAnalysisDetailsPerEmployee = createAsyncThunk(
  "/resultAnalysisDetails-per-employee",
  async (args, thunkAPI) => {
    try {
      const response = await axios.post(
        `/api/v1/reports/resultAnalysisDetails/per-employee`,
        {
          startDate: args.startDate,
          endDate: args.endDate,
          employee: args.employee,
        }
      );

      const { status = null } = response;
      const { data = {} } = response;
      const { value } = data;

      if (Object.keys(value).length > 0 && status === 200) {
        return value;
      }

      return [];
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchReportRequest = createAsyncThunk(
  "/reportRequest",
  async (args, thunkAPI) => {
    try {
      const employee = Auth.getEmployee();
      const { id = null } = employee;

      const response = await axios.post(`/api/v1/sales/report/request`, {
        startDate: args.startDate,
        endDate: args.endDate,
        employeeId: id,
        reference: args.report,
        parameters: args.parameters,
      });

      const { status = null } = response;
      const { data = {} } = response;
      const { value } = data;

      if (Object.keys(value).length > 0 && status === 200) {
        return value;
      }

      return {};
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchReportRequestList = createAsyncThunk(
  "/report/request/list",
  async (args, thunkAPI) => {
    try {
      const { page, maxResult } = args;

      const response = await axios.get(`/api/v1/sales/report/request`, {
        params: { page, maxResult },
      });

      const { status = null } = response;
      const { data = {} } = response;
      const { value } = data;
      const { rows } = value;
      const { count } = value;

      if (Object.keys(value).length > 0 && status === 200) {
        return {
          items: rows,
          count: count,
        };
      }

      return {};
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    selectReport(state, action) {
      state.selectedReport = action.payload;
    },
    loadingRepots(state, action) {
      state.loadingRepots = action.payload;
    },
    clearData(state) {
      state.resultAnalysis = {};
      state.resultAnalysisDetails = [];
      state.resultAnalysisDetailsPerEmployee = [];
      state.downloadExcel = false;
      state.resultCommissionsEfficincys = [];
    },
    setDownloadExcel(state, { payload }) {
      state.downloadExcel = payload;
    },
    setDownloadExcelReport(state, { payload }) {
      state.downloadExcelNameReport = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResultAnalysis.pending, (state, action) => {
        state.status = API_STATUS_PENDING;
      })
      .addCase(fetchResultAnalysis.fulfilled, (state, action) => {
        state.status = API_STATUS_SUCCESS;
        state.resultAnalysis = action.payload;
      })
      .addCase(fetchResultAnalysis.rejected, (state, action) => {
        state.status = API_STATUS_FAILED;
        state.error = action.payload;
      })
      .addCase(fetchReportRequest.pending, (state, action) => {
        state.status = API_STATUS_PENDING;
      })
      .addCase(fetchReportRequest.fulfilled, (state, action) => {
        state.status = API_STATUS_SUCCESS;
        state.reportRequest = action.payload;
      })
      .addCase(fetchReportRequest.rejected, (state, action) => {
        state.status = API_STATUS_FAILED;
        state.error = action.payload;
      })
      .addCase(fetchReportRequestList.pending, (state, action) => {
        state.status = API_STATUS_PENDING;
      })
      .addCase(fetchReportRequestList.fulfilled, (state, action) => {
        state.status = API_STATUS_SUCCESS;
        state.reportRequestList = action.payload;
      })
      .addCase(fetchReportRequestList.rejected, (state, action) => {
        state.status = API_STATUS_FAILED;
        state.error = action.payload;
      })
      .addCase(fetchResultCommissionsAnalysis.pending, (state, action) => {
        state.status = API_STATUS_PENDING;
      })
      .addCase(fetchResultCommissionsAnalysis.fulfilled, (state, action) => {
        state.status = API_STATUS_SUCCESS;
        state.resultCommissionsEfficincys = action.payload;
      })
      .addCase(fetchResultCommissionsAnalysis.rejected, (state, action) => {
        state.status = API_STATUS_FAILED;
        state.error = action.payload;
      })
      .addCase(fetchResultAnalysisDetails.pending, (state, action) => {
        state.status = API_STATUS_PENDING;
      })
      .addCase(fetchResultAnalysisDetails.fulfilled, (state, action) => {
        state.status = API_STATUS_SUCCESS;
        state.resultAnalysisDetails = action.payload;
      })
      .addCase(fetchResultAnalysisDetails.rejected, (state, action) => {
        state.status = API_STATUS_FAILED;
        state.error = action.payload;
      })
      .addCase(
        fetchResultAnalysisDetailsPerEmployee.pending,
        (state, action) => {
          state.status = API_STATUS_PENDING;
        }
      )
      .addCase(
        fetchResultAnalysisDetailsPerEmployee.fulfilled,
        (state, action) => {
          state.status = API_STATUS_SUCCESS;
          state.resultAnalysisDetailsPerEmployee = action.payload;
        }
      )
      .addCase(
        fetchResultAnalysisDetailsPerEmployee.rejected,
        (state, action) => {
          state.status = API_STATUS_FAILED;
          state.error = action.payload;
        }
      );
  },
});

export const {
  selectReport,
  loadingRepots,
  clearData,
  setDownloadExcel,
  setDownloadExcelReport,
} = reportsSlice.actions;

export default reportsSlice.reducer;
