import {
    Accordion,
    Card,
    Col,
    ListGroup,
    Row,
    Table,
} from "react-bootstrap";
import { dateFormatCustom } from "../../../../utils/dateUtils";
import CustomerSalesRepresentatives from "../../information/sales-reps";
import Util from "../../../../Util";

const AppointmentInformation = ({ customerAppointments }) => {
    const messageTimezone = Util.getStorageParameter("REACT_APP_MESSAGE_TIMEZONE");
    
    const buildAppointments = () => {
        return customerAppointments.map((customerAppointment) => {
            return (
                <Accordion key={customerAppointment.id}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            {dateFormatCustom(
                                customerAppointment.appointmentDate,
                                "DD MMM, YYYY HH:mm"
                            )}
                            &nbsp;<span className="text-danger">({messageTimezone})</span>
                            {customerAppointment.lookingFor ? " - " : ""}
                            {customerAppointment.lookingFor}
                        </Accordion.Header>
                        <Accordion.Body>
                            <Table striped bordered>
                                <tbody>
                                    <tr>
                                        <td>Booking Method</td>
                                        <td>{customerAppointment.bookingRequestMethod}</td>
                                    </tr>
                                    <tr>
                                        <td>Looking For</td>
                                        <td>{customerAppointment.lookingFor}</td>
                                    </tr>
                                    <tr>
                                        <td>Showroom City</td>
                                        <td>{customerAppointment.showroomCity}</td>
                                    </tr>
                                    <tr>
                                        <td>Who Came</td>
                                        <td>{customerAppointment.whoCame}</td>
                                    </tr>
                                    <tr>
                                        <td>Is Zoom</td>
                                        <td>{customerAppointment.isZoom.toString()}</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td>{customerAppointment.status}</td>
                                    </tr>
                                    <tr>
                                        <td>Notes</td>
                                        <td>{customerAppointment.notes}</td>
                                    </tr>
                                    <tr>
                                        <td>Appointment Number</td>
                                        <td>{customerAppointment.appointmentNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Sales Representatives</td>
                                        <td>
                                            <CustomerSalesRepresentatives
                                                customerAppointment={customerAppointment}
                                            />
                                        </td>
                                    </tr>
                                    {customerAppointment.customerFollowUps.length > 0 && (
                                        <tr className="SaleComment_component">
                                            <td colSpan={2}>
                                                <h3>Follow Ups</h3>
                                                <ListGroup>
                                                    {customerAppointment.customerFollowUps &&
                                                        customerAppointment.customerFollowUps.length > 0 &&
                                                        customerAppointment.customerFollowUps.map(
                                                            (followUp) => {
                                                                return (
                                                                    <ListGroup.Item key={followUp.id}>
                                                                        <Row>
                                                                            <Col lg="12">
                                                                                {dateFormatCustom(
                                                                                    followUp.date,
                                                                                    "DD MMM, YYYY"
                                                                                )}
                                                                                {followUp.employee?.name
                                                                                    ? " - " + followUp.employee?.name
                                                                                    : ""}
                                                                            </Col>
                                                                            <Col lg="12">
                                                                                <Card className="mt-2">
                                                                                    <Card.Header>Notes:</Card.Header>
                                                                                    <Card.Body>
                                                                                        {followUp.notes}
                                                                                    </Card.Body>
                                                                                </Card>
                                                                            </Col>
                                                                        </Row>
                                                                    </ListGroup.Item>
                                                                );
                                                            }
                                                        )}
                                                </ListGroup>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            );
        });
    };

    return (
        <div className="general_information customer-details-card">
            <h2
                style={{ height: "42px" }}
                className="d-flex flex-row justify-content-between align-items-center"
            >
                <p className="align-self-center mt-3">Appointments</p>
            </h2>
            <div className="customer-details-container p-2">
                {buildAppointments()}
            </div>
        </div>
    )
}
export default AppointmentInformation;