import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FormInputCheckbox from "../../../../Form/FormInputCheckbox";
import AlertMessage from "../../../../Utils/AlertMessage";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { API_STATUS_FAILED } from "../../../../../store/constants";
import { useSelector } from "react-redux";
import { validFormSendGD } from "./Actions/Validations";
import ConfirmSendProdcutToGD from "./ConfirmSendProdcutToGD";
import FormSelectCustomer from "../../../../Form/FormSelectCustomer";
import Util from "../../../../../Util";

const FIELDS_DIAMONDS = "diamonds";
const FIELDS_JEWELLERY = "jewellery";

const FIELDS_VALID = [FIELDS_DIAMONDS, FIELDS_JEWELLERY];

const ReturnJewelleyToGD = ({ sale, stepOrigin, onResponse, onResponseGD }) => {
  const { statusGD, error, saleDiamonds } = useSelector(
    (state) => state.workflowReturn
  );
  const formRef = React.createRef();
  const [step, setStep] = useState({});

  const response = validFormSendGD(saleDiamonds, sale);
  const [btnDisabledSendGD, setBtnDisabledSendGD] = useState(true);
  const [showConfirmSednGD, setShowConfirmSednGD] = useState(false);
  const [diamondChangedUser, setDiamondChangedUser] = useState(false);

  if (!step.diamonds && !step.jewellery) {
    step.diamonds = !diamondChangedUser ? response.diamonds : step.diamonds;
    step.jewellery = response.jewellery;
  }

  const showDiamonds = response.showDiamonds;
  const showJewellery = response.showJewellery;
  const disabledDiamonds = response.disabledDiamonds;
  const disabledJewellery = response.disabledJewellery;

  const cityOptions = Util.getStorageParameter("REACT_APP_SEND_GD_CITY_OPTIONS").split(",");

  const listCitiesSend = cityOptions.map((item) => {
    return {
      fromCityName: item,
      value: item,
    };
  });

  const handleChangeSelect = (data, name) => {
    setBtnDisabledSendGD(true);

    let value = "";

    const item = data ? JSON.parse(data) : {};

    value = item.value;

    setStep({
      ...step,
      [name]: value,
    });

    stepOrigin[name] = value;

    onResponse(value, name);
  };

  const handleChange = (value, name) => {
    if (FIELDS_VALID.includes(name)) {
      setBtnDisabledSendGD(true);
    }

    setStep({
      ...step,
      [name]: value,
    });

    stepOrigin[name] = value;

    setDiamondChangedUser(true);

    onResponse(value, name);
  };

  const disabledSendGD = (form) => {
    const check = form.checkValidity();

    setBtnDisabledSendGD(!check);
  };

  useEffect(() => {
    if (JSON.stringify(step) !== JSON.stringify(stepOrigin)) {
      setStep(stepOrigin);
    }
  }, [step, stepOrigin]);

  useEffect(() => {
    if (btnDisabledSendGD) {
      disabledSendGD(formRef.current);
    }
  }, [btnDisabledSendGD, formRef]);

  const disabledInputSendGD = (name) => {
    if (step.sendProductsToGd) {
      return step.sendProductsToGd;
    }

    if (name === FIELDS_DIAMONDS) {
      return disabledDiamonds;
    }

    if (name === FIELDS_JEWELLERY) {
      return disabledJewellery;
    }

    return false;
  };

  const confirmSendToGD = (value) => {
    setShowConfirmSednGD(false);

    if (value) {
      onResponseGD();
    }
  };

  return (
    <Row className="mt-1">
      {showConfirmSednGD && (
        <ConfirmSendProdcutToGD
          message="The selected products will be reported as in transit to the main office to the GD system. Are you sure?"
          onResponse={confirmSendToGD}
        />
      )}
      <Col>
        <Card>
          <Card.Body>
            {statusGD === API_STATUS_FAILED && (
              <AlertMessage
                type="danger"
                message={`There was an error reported by GD system: "${
                  error ?? ""
                }"`}
                infoHeading=""
                autoClose={false}
              />
            )}

            <Card.Title>Products sent to main office:</Card.Title>
            <Form ref={formRef}>
              <Row>
                {showDiamonds && (
                  <Col xs={12} lg={4}>
                    <FormInputCheckbox
                      typeView="switch"
                      type="checkbox"
                      label="Diamond(s)"
                      nameInput="diamonds"
                      disabled={disabledInputSendGD("diamonds")}
                      checked={step.diamonds ?? false}
                      required={false}
                      onResponse={handleChange}
                    />
                  </Col>
                )}

                {showJewellery && (
                  <Col xs={12} lg={4}>
                    <FormInputCheckbox
                      typeView="switch"
                      type="checkbox"
                      label="Jewellery/Wedding Band/Ring Setting"
                      nameInput="jewellery"
                      disabled={disabledInputSendGD("jewellery")}
                      checked={step.jewellery ?? false}
                      required={false}
                      onResponse={handleChange}
                    />
                  </Col>
                )}
                <Col xs={12} lg={4}>
                  <FormSelectCustomer
                    label="Sent from"
                    nameInput="fromCityName"
                    items={listCitiesSend}
                    keyValue={"value"}
                    value={step.fromCityName ?? ""}
                    required={true}
                    disabled={disabledInputSendGD("fromCityName")}
                    onResponse={handleChangeSelect}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={12} lg={12} className="center-element">
                  {!step.sendProductsToGd && (
                    <Button
                      disabled={btnDisabledSendGD}
                      onClick={() => setShowConfirmSednGD(true)}
                    >
                      Notify product transfer to GD
                    </Button>
                  )}

                  {step.sendProductsToGd &&
                    "The products were notified as sent"}
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

ReturnJewelleyToGD.propTypes = {
  sale: PropTypes.object.isRequired,
  stepOrigin: PropTypes.object.isRequired,
  onResponse: PropTypes.func,
  onResponseGD: PropTypes.func,
};

export default ReturnJewelleyToGD;
