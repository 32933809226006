import { NavLink } from "react-bootstrap";

export default function CustomerCard({ customer }) {

  const openCustomer = (email) => {
    window.open(`/customers?customer-ref=${email}`);
  };

  const buildMiddleRow = (name, value) => {
    return (
      <div key={name} className="row border-bottom">
        <div className="p-1 col-sm table_title border-right ">
          <span className="table_title">{name}</span>
        </div>
        <div className="p-1 col-sm">
          {name === 'Email' ? 
            (
              <NavLink
                onClick={() => openCustomer(value)}
                className="link-primary"
              >
                {value}
              </NavLink>
            ) : (value)
          }
        </div>
      </div>
    );
  };

  return (
    <div className="general_information customer-details-card">
      <h2>Customer</h2>
      <div className="customer-details-container p-2">
        <div className="container">
          {buildMiddleRow("Customer Name", customer.name)}
          {buildMiddleRow("Email", customer.email)}
          {buildMiddleRow("Phone", customer.phone)}
        </div>
      </div>
    </div>
  );
}
