import React, { Component } from "react";
import { GetSaleDiamonds } from "./Actions/GetSaleDiamonds";
import SaleDiamondInformationDetail from "../saleInformation/SaleDiamondInformationDetail";
import Util from "../../../Util";

const MANUFACTURING = "Manufacturing";

class SaleDetailCosts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sale: props.sale,
      workflowSales: props.workflowSales ?? [],
      jewellerInvoiceNumber: "",
      settingCostPrice: "",
      melesCost: "",
      melesReference: "",
    };

    this.renderCostWorkflow = this.renderCostWorkflow.bind(this);
    this.renderCostMelees = this.renderCostMelees.bind(this);
    this.renderCostMetals = this.renderCostMetals.bind(this);
  }

  componentDidMount() {
    this.showSaleCostInfo();
  }

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    const sale = this.state.sale;

    const saleDiamond = GetSaleDiamonds(sale);

    return (
      <div id="SaleDetailCosts_component">
        <div className="sale_data">
          <h2 className="heading">COSTS</h2>
          <div className="container mb-4">
            <div className="row">
              <div className="col-sm">Selling Price to Nova</div>
              <div className="col-sm">
                {Util.formatCurrency(sale.sellingPriceNova)}
              </div>
            </div>
            <div className="row">
              <div className="col-sm">Setting Cost Price</div>
              <div className="col-sm">
                {Util.formatCurrency(this.state.settingCostPrice)}
              </div>
            </div>

            <SaleDiamondInformationDetail
              diamonds={saleDiamond.saleDiamondModels}
              sale={sale}
              searchDetailDiamond={saleDiamond.searchDetailDiamond}
            />

            <div className="row">
              <div className="col-sm">Jeweller Invoice Number</div>
              <div className="col-sm">{this.state.jewellerInvoiceNumber}</div>
            </div>
            <div className="row">
              <div className="col-sm">Meles Cost</div>
              <div className="col-sm">
                {Util.formatCurrency(this.state.melesCost)}
              </div>
            </div>
            <div className="row">
              <div className="col-sm">Meles reference</div>
              <div className="col-sm">{this.state.melesReference}</div>
            </div>
            {this.state.workflowSales.length > 0
              ? this.renderCostWorkflow(this.state.workflowSales)
              : ""}
          </div>
        </div>
      </div>
    );
  }

  renderCostWorkflow = (workflowSales) => {
    return (
      <div>
        <b>Workflow sales cost</b>

        {workflowSales.map((workflowSale) => {
          return workflowSale.workflowActivityModels.map((workflowActivity) => {
            return (
              <div
                className="row"
                key={workflowActivity.id.toString() + "_" + Math.random()}
              >
                {workflowActivity.type !== "Delivering to customer" &&
                workflowActivity.type !== "Photos" ? (
                  <div>
                    <b>{workflowActivity.type}</b>
                    <div className="row">
                      <div className="col-sm">Cost</div>
                      <div className="col-sm">
                        {Util.formatCurrency(workflowActivity.cost)}
                      </div>

                      {this.renderCostMelees(workflowActivity)}

                      {this.renderCostMetals(workflowActivity)}
                    </div>
                  </div>
                ) : null}
              </div>
            );
          });
        })}
      </div>
    );
  };

  renderCostMelees = (workflowActivity) => {
    if (!workflowActivity.workflowMelesModels) {
      return "";
    }

    return (
      <div>
        <b>Melees</b>
        {workflowActivity.workflowMelesModels.map((workflowMelee) => {
          return (
            <div key={workflowMelee.id + Math.random()}>
              <div className="row">
                <div className="col-sm">Cost</div>
                <div className="col-sm">
                  {Util.formatCurrency(workflowMelee.melesCost)}
                </div>
              </div>

              <div className="row">
                <div className="col-sm">Invoice Number</div>
                <div className="col-sm">{workflowMelee.invoiceStatus}</div>
              </div>

              <div className="row">
                <div className="col-sm">NOVA invoice</div>
                <div className="col-sm">{workflowMelee.novaInvoice}</div>
              </div>

              <div className="row">
                <div className="col-sm">MMDIA Invoice</div>
                <div className="col-sm">{workflowMelee.mmdiaInvoice}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  renderCostMetals = (workflowActivity) => {
    if (!workflowActivity) {
      return "";
    }

    if (!workflowActivity.workflowMetalModel) {
      return "";
    }

    return (
      <div>
        <b>Band Metal</b>
        <div className="row">
          <div className="col-sm">Cost</div>
          <div className="col-sm">
            {Util.formatCurrency(
              workflowActivity?.workflowMetalModel?.metalCost ?? 0
            )}
          </div>
        </div>
      </div>
    );
  };

  showSaleCostInfo() {
    let jewellerInvoiceNumber = "";
    let settingCostPrice = "";
    let melesCost = "";
    let melesReference = "";

    this.state.workflowSales.map(function (workflowSale) {
      if (workflowSale.hasOwnProperty("workflowActivityModels")) {
        workflowSale.workflowActivityModels.map(function (workflowActivity) {
          if (workflowActivity?.type === MANUFACTURING) {
            jewellerInvoiceNumber = workflowActivity?.invoiceNumber;
            settingCostPrice = workflowActivity?.cost;
            if (workflowActivity.hasOwnProperty("workflowMelesModels")) {
              melesCost = workflowActivity.workflowMelesModels[0]?.melesCost;
              melesReference =
                workflowActivity.workflowMelesModels[0]?.melesReference;
            }
          }
          return null;
        });
      }
      return null;
    });

    this.setState({
      jewellerInvoiceNumber: jewellerInvoiceNumber,
      settingCostPrice: settingCostPrice,
      melesCost: melesCost,
      melesReference: melesReference,
    });
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default SaleDetailCosts;
