import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import FormQualityControl from "./FormQualityControl";
import ConfirmSaveForm from "../../../../ConfirmSaveForm";
import { useDispatch, useSelector } from "react-redux";
import { setActiveChange } from "../../../../../store/slices";

const EditQualityControlCheck = ({
  qualityControlCheck,
  showPanelInformation,
  onResponse,
  activity,
}) => {
  const { activeChange, dataQualityControlCheck } = useSelector(
    (state) => state.qualityControlCheck
  );
  const dispatch = useDispatch();

  const { type } = activity;
  const [showModal, setShowModal] = useState(true);
  const [showChangeActive, setShowChangeActive] = useState(false);
  const [updateQualityControlCheck, setUpdatequalityControlCheck] =
    useState(qualityControlCheck);

  const closeModal = () => {
    if (activeChange) {
      setShowChangeActive(true);

      return;
    }

    setShowModal(false);
    onResponse(false);
  };

  const cancelSave = (value) => {
    if (!value) {
      dispatch(setActiveChange(false));
      setShowChangeActive(false);

      setShowModal(false);
      onResponse(false);

      return;
    }

    setShowChangeActive(false);
  };

  useEffect(() => {
    if (
      dataQualityControlCheck &&
      JSON.stringify(dataQualityControlCheck) !==
        JSON.stringify(updateQualityControlCheck)
    ) {
      setUpdatequalityControlCheck(dataQualityControlCheck);
    }
  }, [dataQualityControlCheck, updateQualityControlCheck]);

  return (
    <>
      {showChangeActive && (
        <ConfirmSaveForm
          message={
            "You still have pending changes. Are you sure you want to close?"
          }
          labelCancel="Discard Changes"
          labelSave="Continue Editing"
          onResponse={cancelSave}
        />
      )}

      <Modal
        show={showModal}
        onHide={closeModal}
        size="md"
        centered
        backdrop="static"
        backdropClassName={
          showPanelInformation ? "modal-black-show-informacion-panel" : ""
        }
        className={showPanelInformation ? "modal-show-informacion-panel" : ""}
      >
        <Modal.Header closeButton>
          {type} - Edit Quality Control Check
        </Modal.Header>
        <Modal.Body>
          <FormQualityControl
            qualityControlCheck={updateQualityControlCheck ?? null}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

EditQualityControlCheck.propTypes = {
  qualityControlCheck: PropTypes.object.isRequired,
  onResponse: PropTypes.func.isRequired,
  showPanelInformation: PropTypes.bool.isRequired,
};

EditQualityControlCheck.defaultProps = {
  showPanelInformation: false,
};

export default EditQualityControlCheck;
