import moment from "moment";
import Util from "../../../../Util";

const FORMAT_DATE_MOMENT = "MM/DD/YYYY";
const VALUE_DIVISOR = 100;
const VALUE_DECIMAL = 2;

export const getPriceExtGst = (sale) => {
  const { pricesIncludeGst, shippingCountry, totalIncGst, taxPercentage } =
    sale;

  const noCalculateTax = parseInt(
    Util.getStorageParameter("REACT_APP_CALCULATE_COMMISSION_INCLUDING_TAX")
  );

  if (noCalculateTax) {
    return totalIncGst;
  }

  if (pricesIncludeGst || taxPercentage) {
    const countries = JSON.parse(Util.getStorageParameter("REACT_APP_TAX_FOR_COUNTRYS"));

    let tax = 0;

    if (shippingCountry) {
      tax = countries[shippingCountry] ?? 0;
    } else if (shippingCountry === null || shippingCountry === "") {
      tax = countries["local"];
    } else {
      tax = countries["default"];
    }

    if (taxPercentage) {
      tax = parseInt(taxPercentage);
    }

    return parseFloat(
      (totalIncGst / (1 + tax / VALUE_DIVISOR))
        .toFixed(VALUE_DECIMAL)
        .replace(",", ".")
    );
  }

  return totalIncGst;
};

export const calculateCommissionValue = (rate, total) => {
  try {
    rate = rate.toString();
    rate = rate.replace(/,/g, ".").replace(/[^0-9.]/g, "");

    if (isNaN(rate)) {
      return 0;
    }

    total = total.toString();

    const totalFloat = parseFloat(total ? total.replace(/,/g, "") : 0);
    const result = (rate * totalFloat) / VALUE_DIVISOR;

    return parseFloat(result.toFixed(VALUE_DECIMAL));
  } catch (error) {
    console.log(error);
    return 0;
  }
};

export const getSaleCommissionRateValue = (value) => {
  try {
    if (!value) {
      return 0;
    }

    const item = JSON.parse(value);

    if (!item) {
      return;
    }

    const { rate, id } = item;

    return { rate, id };
  } catch (error) {
    return 0;
  }
};

export const getSaleCommissionPaidDate = (date) => {
  if (!date) {
    return null;
  }

  return moment(date).format(FORMAT_DATE_MOMENT);
};
