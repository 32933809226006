import React from "react";
import { Button, Card, Table } from "react-bootstrap";
import Util from "../../../Util";
import moment from "moment-timezone";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { setDownloadExcel } from "../../../store/slices";
import ShowSaleConsecutive from "../../sales/Acctions/ShowSaleConsecutive";

const TotalCommissionsReportDetails = ({ jsonData }) => {
  const dispatch = useDispatch();
  const handleDownloadExcel = () => {
    // get the selected list type
    dispatch(setDownloadExcel(true));
  };

  let grandTotalIncGst = 0,
    grandCommissionValue = 0;
  return (
    <Card>
      <Card.Body>
        <Card.Title className="d-flex justify-content-between align-items-center">
          <h2>
            Commissions Sales Efficiency (
            {jsonData && jsonData.length > 0 ? jsonData.length : 0})
          </h2>
          <Button
            disabled={jsonData && jsonData.length > 0 ? false : true}
            className="btn-icon-pink"
            onClick={handleDownloadExcel}
          >
            <FontAwesomeIcon icon={faDownload} />
          </Button>
        </Card.Title>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Date of Sale</th>
              <th>Sales Rep.</th>
              <th>Order number</th>
              <th>Customer's Name</th>
              <th>Type of Commission</th>
              <th>Total Invoice</th>
              <th>Commission Percentage</th>
              <th>Commission Value</th>
              <th>Paid Date</th>
            </tr>
          </thead>
          <tbody>
            {jsonData.length > 0 &&
              jsonData.map((commission, index) => {
                const saleDate = moment(
                  commission.sale.saleDate,
                  "YYYY-MM-DD"
                ).format("DD MMM, YYYY");
                const paidDate = commission.paidDate
                  ? moment(commission.paidDate, "YYYY-MM-DD").format(
                      "DD MMM, YYYY"
                    )
                  : "";
                const totalIncGst =
                  !isNaN(commission.sale.totalIncGst) &&
                  commission.sale.totalIncGst
                    ? commission.sale.totalIncGst
                    : 0;
                grandTotalIncGst += parseFloat(totalIncGst);
                grandCommissionValue += parseFloat(commission.commissionValue);
                return (
                  <tr key={index}>
                    <td>{saleDate}</td>
                    <td>{commission.employee.name}</td>
                    <td>
                      {commission.sale.onlineOrderNumber}
                      <ShowSaleConsecutive sale={commission.sale} />
                    </td>
                    <td>{commission.sale.name}</td>
                    <td>{commission.employeeCommissionRate?.name ?? ""}</td>
                    <td>{Util.formatCurrency(totalIncGst, false)}</td>
                    <td>{commission.commissionPercentage}%</td>
                    <td>
                      {Util.formatCurrency(
                        commission.commissionValue ?? 0,
                        false
                      )}
                    </td>
                    <td>{paidDate}</td>
                  </tr>
                );
              })}
            {jsonData.length > 0 && (
              <tr>
                <th colSpan={5}></th>
                <th>{Util.formatCurrency(grandTotalIncGst ?? 0, false)}</th>
                <th></th>
                <th>{Util.formatCurrency(grandCommissionValue ?? 0, false)}</th>
                <th></th>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default TotalCommissionsReportDetails;
