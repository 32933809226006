import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  faPlus,
  faEraser,
  faDownload,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { setDownloadCSV } from "../../../store/slices";
import { useDispatch, useSelector } from "react-redux";
import FormCustomerDialog from "../edit";
import Auth from "../../../Auth";
import MergeCustomerDialog from "../merge_customer";
import { TOTAL_PERSONAL_TABS } from "../../../global/const";

export default function CustomersToolbar({
  handleCreateList,
  handleDeleteList,
  onFilterChange,
  onListTypeChanged,
  showSearch,
  onSelected,
  searchGeneralLoad,
  tabs,
}) {
  const accessReport = Auth.canAccessRolesAndLeader("rolesAccessCRMList", true);

  const { filterColumns } = useSelector((state) => state.customers);

  const inputRef = useRef(null);

  useEffect(() => {
    if (searchGeneralLoad) {
      inputRef.current.value = searchGeneralLoad;
    }
  }, [searchGeneralLoad]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = useCallback(
    debounce((text) => {
      onFilterChange(text, "generalSearch");
    }, 300),
    [filterColumns]
  );

  const [showFormCustomer, setShowFormCustomer] = useState(false);
  const [showMergeCustomer, setShowMergeCustomer] = useState(false);

  const dispatch = useDispatch();
  const [listName, setListName] = useState("");
  const [showConfirmationDelete, setShowConfirmationDelete] = useState(false);

  const [selectedOption, setSelectedOption] = useState("");

  const handleShowMergeCustomer = (value) => {
    setShowMergeCustomer(value);
  };

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleDownloadCSV = () => {
    if (selectedOption === "export_csv") {
      dispatch(setDownloadCSV(true));
    } else {
      alert("Please select a report type");
    }
  };

  const accesEdit = () => {
    const access = Auth.canAccessRolesAndLeader("rolesAccessCRMCreate");

    const accessOperation = Auth.canAccessRolesAndLeader(
      "rolesAccessCRMOperation",
      true
    );

    if (access || accessOperation) {
      return true;
    }

    return false;
  };

  return (
    <div>
      <div className="row sales_control">
        <div className="fixed-height-div">
          <div className="search-filter">
            <div className="row general_search mb-2">
              <Row>
                <Col
                  xs="12"
                  lg="4"
                  className="text-left add_new_customer customised-sales-controls"
                >
                  <div className="input-group add-new-tab">
                    <input
                      type="text"
                      placeholder="Add new tab"
                      className="form-control box-bg-input"
                      value={listName}
                      onChange={(e) => {
                        setListName(e.target.value);
                      }}
                    />
                  </div>
                  <button
                    onClick={(e) => {
                      handleCreateList(listName);
                      setListName("");
                    }}
                    className="btn btn-default"
                    disabled={tabs.length >= TOTAL_PERSONAL_TABS ? true : false}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                  <button
                    onClick={(e) => {
                      setShowConfirmationDelete(true);
                    }}
                    className="btn btn-default"
                  >
                    <FontAwesomeIcon icon={faEraser} />
                  </button>
                </Col>
                <Col xs="12" lg="4">
                  {showSearch && (
                    <Row className="text-right sheetType_selection">
                      <Col>
                        <Form.Label className="box-label">
                          List type:
                        </Form.Label>
                      </Col>
                      <Col>
                        <Form.Select
                          onChange={(e) => {
                            onListTypeChanged(e.target.value);
                          }}
                        >
                          <option value="all">All</option>
                          <option value="leads">Leads</option>
                          <option value="customers">Customers</option>
                        </Form.Select>
                      </Col>
                    </Row>
                  )}
                  <Row className="mt-2 text-right merge-customer">
                    <Col>
                      <Button
                        className=""
                        onClick={() => setShowMergeCustomer(true)}
                      >
                        Merge Customers
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" lg="4">
                  {showSearch && (
                    <Row>
                      <Col xs={10} lg={10}>
                        <InputGroup className="input-group-pink">
                          <Form.Control
                            className="advanced_filter_customerModel_general_search"
                            placeholder="Search..."
                            name="generalSearch"
                            ref={inputRef}
                            onChange={(e) => handler(e)}
                          />
                          <InputGroup.Text id="basic-addon2">
                            <FontAwesomeIcon icon={faSearch} />
                          </InputGroup.Text>
                        </InputGroup>
                      </Col>
                      <Col xs={2} lg={2}>
                        {accesEdit() && (
                          <Button
                            className="btn-icon-pink"
                            onClick={() => setShowFormCustomer(true)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  )}

                  {accessReport && (
                    <Row className={showSearch ? " mt-2" : ""}>
                      <Col sm="10" lg="10">
                        <Form.Group>
                          <Form.Select
                            value={selectedOption}
                            onChange={handleSelectChange}
                          >
                            <option value=""></option>
                            <option value="export_csv">Export Customers</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col sm="2" lg="2">
                        <Button
                          disabled={!selectedOption}
                          className="btn-icon-pink"
                          onClick={handleDownloadCSV}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showConfirmationDelete}
        onHide={() => {
          setShowConfirmationDelete(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this list?</p>
          <div className="flex-row">
            <Button
              className="mt-2 float-right"
              variant="secondary"
              onClick={() => {
                setShowConfirmationDelete(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleDeleteList(listName);
                setShowConfirmationDelete(false);
              }}
              className="ms-2 mt-2 float-right"
              variant="primary"
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {showFormCustomer && (
        <FormCustomerDialog
          customer={{}}
          show={showFormCustomer}
          onHide={() => {
            setShowFormCustomer(false);
          }}
        />
      )}
      {showMergeCustomer && (
        <MergeCustomerDialog
          show={showMergeCustomer}
          onHide={() => {
            setShowMergeCustomer(false);
          }}
          handleShowMergeCustomer={handleShowMergeCustomer}
          onSelected={onSelected}
        />
      )}
    </div>
  );
}
