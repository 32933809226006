import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";
import FormSelectCustomer from "../../Form/FormSelectCustomer";
import { LIST_TYPES_SETTINGS } from "../../../global/const";
import FormInputText from "../../Form/FormInputText";
import { Typeahead } from "react-bootstrap-typeahead";
import Util from "../../../Util";

const TYPE_FIELD_TEXT = "text";
const TYPE_FIELD_NUMBER = "number";
const TYPE_FIELD_SELECT = "select";
const FIELD_CENTER_STONE = "Center Stone";

const AddElementTag = ({ tags, responseTags, disabled }) => {
  const tagsArray = Util.changeStringToArray(tags);

  const [dataTags, setDataTags] = useState(tagsArray);
  const [setting, setSetting] = useState(null);
  const [other, setOther] = useState(null);
  const [addItem, setAddItem] = useState(false);
  const [typeFieldsValue, setTypeFieldsValue] = useState(TYPE_FIELD_TEXT);
  const [itemsSelectField, setItemsSelectField] = useState([]);

  const onChangeSubSelect = (value) => {
    if (!value) {
      return;
    }

    const data = JSON.parse(value);

    setOther(data?.slug ?? "");
  };

  const onChangeSelect = (value) => {
    if (!value) {
      return;
    }

    const data = JSON.parse(value);

    if (data.value === setting) {
      return;
    }

    setTypeFieldsValue(data?.type ?? TYPE_FIELD_TEXT);

    if (data?.type === TYPE_FIELD_SELECT) {
      setItemsSelectField(data?.items);
    }

    setOther("");
    setSetting(data.value);
  };

  const onChangeInput = (value) => {
    if (value === other) {
      return "";
    }

    setOther(value);
  };

  const itemLists = LIST_TYPES_SETTINGS;

  const addElement = () => {
    setAddItem(true);
  };

  useEffect(() => {
    if (addItem) {
      const data = {
        value: `${setting}: ${other}`,
      };

      dataTags.push(data);

      setDataTags(dataTags);
      setSetting(null);
      setOther("");
      setAddItem(false);

      responseTags(dataTags);
    }
  }, [addItem, dataTags, other, responseTags, setting]);

  const handleResponse = (selected) => {
    setDataTags(selected);

    responseTags(selected);
  };

  const findValueBySlug = (value) => {
    if (!value.includes(FIELD_CENTER_STONE)) {
      return value;
    }

    const typeSettings = itemLists
      .map((itemList) => {
        if (itemList.value === FIELD_CENTER_STONE) {
          return itemList;
        }
        return null;
      })
      .filter((itemList) => itemList != null);

    const items = typeSettings[0]?.items;

    const chunks = value.split(":");

    if (chunks.length <= 1) {
      return value;
    }

    const option = items
      .map((item) => {
        if (item.slug === chunks[1].trim()) {
          return item;
        }

        return null;
      })
      .filter((item) => item != null);

    if (option.length <= 0) {
      return value;
    }

    return `${chunks[0]}: ${option[0].value}`;
  };

  const renderTags = () => {
    return (
      <Row>
        {dataTags.length > 0 && (
          <Typeahead
            id={"tags"}
            labelKey={"value"}
            multiple
            onChange={handleResponse}
            options={[]}
            selected={dataTags}
            renderToken={(option, props, idx) => {
              let value = findValueBySlug(option.value);

              return (
                <div
                  className="rbt-input-wrapper"
                  key={idx}
                  style={{ marginBottom: "1px" }}
                >
                  <div className="rbt-token rbt-token-removeable">
                    <div
                      className="rbt-token-label"
                      dangerouslySetInnerHTML={{
                        __html: Util.textIsUrl(value),
                      }}
                    ></div>
                    <button
                      aria-label="Remove"
                      className="close btn-close rbt-close rbt-token-remove-button"
                      type="button"
                      onClick={() => props.onRemove(option)}
                      disabled={disabled}
                    >
                      <span aria-hidden="true" className="rbt-close-content">
                        ×
                      </span>
                    </button>
                  </div>
                </div>
              );
            }}
          />
        )}
      </Row>
    );
  };

  return (
    <>
      <Row>
        <Col xs={4} lg={4}>
          {!addItem && (
            <FormSelectCustomer
              label=""
              nameInput="value"
              items={itemLists}
              keyValue={"id"}
              value={setting ?? ""}
              onResponse={onChangeSelect}
              columnsSize={12}
              disabled={disabled}
            />
          )}
        </Col>
        <Col xs={4} lg={4}>
          {(typeFieldsValue === TYPE_FIELD_TEXT ||
            typeFieldsValue === TYPE_FIELD_NUMBER) && (
            <FormInputText
              label=""
              nameInput="text"
              value={other ?? ""}
              onResponse={onChangeInput}
              columnsSize={12}
              disabled={disabled}
              onlyNumber={typeFieldsValue === TYPE_FIELD_NUMBER ? true : false}
            />
          )}

          {typeFieldsValue === TYPE_FIELD_SELECT && (
            <FormSelectCustomer
              label=""
              nameInput="value"
              items={itemsSelectField}
              keyValue={"slug"}
              value={""}
              onResponse={onChangeSubSelect}
              columnsSize={12}
              disabled={disabled}
            />
          )}
        </Col>
        <Col xs={4} lg={4}>
          <br />
          <Button
            variant="primary"
            onClick={addElement}
            disabled={!setting || !other}
          >
            Add
          </Button>
        </Col>
      </Row>

      {renderTags()}
    </>
  );
};

AddElementTag.propTypes = {
  tags: PropTypes.string.isRequired,
  responseTags: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
};

AddElementTag.defaultProps = {
  tags: [],
  disabled: false,
};

export default AddElementTag;
