import axios from "axios";
import Auth from "../Auth";
import Util from "../Util";

//axios.defaults.headers.common.Authorization = `Bearer ${Auth.getToken()}`;
const axiosServices = axios.create({
  baseURL: process.env.REACT_APP_NOVITAERP_API_DOMAIN,
});

axiosServices.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${Auth.getToken()}`;
  return config;
});

export default axiosServices;
