import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Auth from "./Auth";

import SalesList from "./components/sales/SalesList";
import OrdersToList from "./components/sales/orderToList";
import SaleDetail from "./components/sales/detail/SaleDetail";
import CRMView from "./components/customers";
import SingleOrderDetails from "./components/sales/SingleOrderDetails";
import Dashboard from "./components/dashboard/Dashboard";
import Login from "./components/login/Login";
import LoadInvoices from "./components/costs/LoadInvoices";
import CostsList from "./components/costs/CostsList";
import PostSaleServices from "./components/post_sale_services/PostSaleServices";
import Accounting from "./components/accounting/Accounting";
import PostSaleServicesDetail from "./components/post_sale_services/detail/PostSaleServicesDetail";
import Documentation from "./components/documentation/index";
import LoadCommissions from "./components/accounting/LoadCommissions/LoadCommissions";
import { Reports } from "./components/reports";
import AppointmentsView from "./components/appointments";

const PrivateRoute = ({ element, ...rest }) => {
  return Auth.getIsAuth() ? element : <Navigate to="/" />;
};

const Router = () => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route
      path="/dashboard"
      element={<PrivateRoute element={<Dashboard />} />}
    />
    <Route path="/sales" element={<PrivateRoute element={<SalesList />} />} />
    <Route
      path="/ordersto"
      element={<PrivateRoute element={<OrdersToList />} />}
    />
    <Route
      path="/sale/:id"
      element={<PrivateRoute element={<SaleDetail />} />}
    />
    <Route path="/customers" element={<PrivateRoute element={<CRMView />} />} />
    <Route
      path="/orderDetails"
      element={<PrivateRoute element={<SingleOrderDetails />} />}
    />
    <Route
      path="/costs/load_invoices"
      element={<PrivateRoute element={<LoadInvoices />} />}
    />
    <Route
      path="/costs/show_costs"
      element={<PrivateRoute element={<CostsList />} />}
    />
    <Route
      path="/post_sale_services"
      element={<PrivateRoute element={<PostSaleServices />} />}
    />
    <Route
      path="/accounting"
      element={<PrivateRoute element={<Accounting />} />}
    />
    <Route
      path="/accounting/load-commissions"
      element={<PrivateRoute element={<LoadCommissions />} />}
    />
    <Route
      path="/post_sale_services/:id"
      element={<PrivateRoute element={<PostSaleServicesDetail />} />}
    />
    <Route
      path="/documentation"
      element={<PrivateRoute element={<Documentation />} />}
    />
    <Route path="/reports" element={<PrivateRoute element={<Reports />} />} />
    <Route
      path="/customer-appointments"
      element={<PrivateRoute element={<AppointmentsView />} />}
    />

    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
);

export default Router;
