import React, { Component } from "react";
import Router from "./Router";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

// SCSS
import "./css/main.scss";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { NavigationProvider } from "./Router/NavigationContext";

ChartJS.register(
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
class App extends Component {
  render() {
    return (
      <div className="App">
        <NavigationProvider>
          <Router />
        </NavigationProvider>
      </div>
    );
  }
}

export default App;
