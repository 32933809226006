// SCSS
import "../../../../css/sales/sale_create.scss";
import React, { Component } from "react";
import { Modal, Button, Form, FormLabel } from "react-bootstrap";
import axios from "axios";
import FlashMessages from "../../../FlashMessages";
import * as globalConst from "../../../../global/const";
import LoadingLayer from "../../../LoadingLayer";
import Auth from "../../../../Auth";
import { MultimediaUploadFile } from "../../../Multimedia/Action/UploadFile";
import Util from "../../../../Util";

class MediaCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filename: "",
      description: "",
      creating_media: false,
      postSaleServiceId: props.postSaleServiceId,
    };
    this.closeModal = this.closeModal.bind(this);
    this.createMedia = this.createMedia.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFileInput = this.handleFileInput.bind(this);
    this.validateFieldsAndSave = this.validateFieldsAndSave.bind(this);
  }
  newFileName = null;

  closeModal() {
    this.props.closeCreateComponent();
  }

  validateFieldsAndSave(event) {
    event.preventDefault();
    let error_messages = [];
    if (this.state.filename.length === 0) {
      error_messages.push("Attachment Required");
    } else {
      // Allowing file type
      var allowedExtensions = /(\.jpg|\.mp4|\.png|\.jpeg|\.pdf)$/i;
      if (!allowedExtensions.exec(this.state.filename.name)) {
        error_messages.push("Invalid file type");
      }
    }
    if (this.state.description.length < 5) {
      error_messages.push("Description should have at least 5 characters");
    }

    if (this.state.description.length > 255) {
      error_messages.push("Description should have less than 255 characters");
    }

    if (error_messages.length > 0) {
      this.setState({
        error_messages: error_messages,
      });
    } else {
      this.uploadFile();
    }
  }

  axioRequest = null;
  createMedia(filename) {
    this.setState({
      creating_media: true,
      error_messages: [],
    });
    const user = Auth.getAuthenticatedUser();
    if (
      user === null ||
      user === undefined ||
      user.employeeModel === null ||
      user.employeeModel === undefined
    ) {
      this.setState({
        error_messages: [
          "Authentication error. Reload the page and try again.",
        ],
      });
    } else {
      let url =
        Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
        globalConst.REACT_APP_NOVITAERP_API_POST_SALE_SERVICE_MULTIMEDIA_CREATE;
      const params = new URLSearchParams();
      params.append("filename", filename);
      params.append("description", this.state.description);
      params.append("postSaleServiceId", this.state.postSaleServiceId);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
      };

      axios
        .post(url, params, config)
        .then((response) => {
          const data = response.data;
          if (data !== undefined && data.code === 200) {
            this.setState({
              creating_media: false,
              filename: "",
              description: "",
              success_messages: ["Media created"],
              error_messages: [],
            });
            this.newFileName = "";
            var created_sale_media = data.result;
            this.generateCreationLog(created_sale_media);
            document.getElementById("uploadFile").value = null;
            this.props.updateSaleMultimediaEditRequirementsList();
          } else {
            this.setState({
              creating_media: false,
              error_messages: [
                "There was a problem processing the information. Please reload this page and try again.",
              ],
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error_messages = [];
            if ("status" in err.response && err.response.status === 401) {
              error_messages = ["Not authorised."];
            } else {
              error_messages = [
                "There was a problem processing the information. Please reload this page and try again.",
              ];
            }
            this.setState({
              creating_media: false,
              error_messages: error_messages,
            });
          } else if (err.request) {
            const error_messages = [
              "There was a problem processing the information. Please reload this page and try again.",
            ];
            this.setState({
              creating_media: false,
              error_messages: error_messages,
            });
          } else {
            //error not detected. Request could have been cancelled and it's waiting for second
            //request to finish
            this.setState({
              creating_media: true,
            });
          }
          //console.log((err);
          return null;
        });
    }
  }

  uploadFile = async () => {
    this.setState({
      creating_media: true,
      error_messages: [],
    });

    const file = await MultimediaUploadFile(
      this.state.filename,
      globalConst.FOLDER_POST_SALE_SERVICE
    );

    if (file) {
      const { filename } = file;
      this.createMedia(filename);
    }

    this.setState({
      creating_media: false,
    });
  };

  //Generate log after editing element
  generateCreationLog(created_sale_media) {
    //AJAX CONTROLS
    //cancel previous ajax request if exists
    if (this.axioRequest !== null) {
      this.axioRequest.cancel();
    }
    // creates a new token for upcomming ajax (overwrite the previous one)
    this.axioRequest = axios.CancelToken.source();

    const url =
      Util.getStorageParameter("REACT_APP_NOVITAERP_API_DOMAIN") +
      globalConst.REACT_APP_NOVITAERP_API_POST_SALE_LOG_NEW;
    const params = new URLSearchParams();

    const user = Auth.getAuthenticatedUser();
    delete created_sale_media["salesModel"];

    params.append("postSaleServiceId", created_sale_media.postSaleServiceId);
    params.append("employeeId", user.employeeModel.id);
    params.append("oldInformation", JSON.stringify(created_sale_media));
    params.append("newInformation", JSON.stringify(created_sale_media));
    params.append("action", "CREATE_MEDIA");

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${Auth.getToken()}`,
      },
    };
    axios
      .post(url, params, config)
      .then((response) => {
        const data = response.data;
        if (data !== undefined && data.code === 200) {
          //window.location.reload(false);
        } else {
        }
        this.axioRequest = null;
      })
      .catch((err) => {
        console.log("Error storing post_sales_multimedia_log.");
        if (err.response) {
          if ("status" in err.response && err.response.status === 401) {
            console.log("Not authorised.");
          } else {
            console.log(
              "There was a problem processing the information. Please reload this page and try again."
            );
          }
        } else if (err.request) {
          console.log(
            "There was a problem processing the information. Please reload this page and try again."
          );
        } else {
          //error not detected. Request could have been cancelled and it's waiting for second
          //request to finish
        }
        //console.log((err);
        return null;
      });
  }

  //*****************************************
  //RENDER
  //*****************************************
  render() {
    return (
      <div id="SaleCreate_component">
        <Modal show={true} onHide={this.closeModal}>
          <Modal.Header closeButton>NEW MEDIA</Modal.Header>
          <Modal.Body>
            {this.state.creating_media === true ? <LoadingLayer /> : ""}
            {
              <FlashMessages
                error_messages={this.state.error_messages}
                warning_messages={this.state.warning_messages}
                success_messages={this.state.success_messages}
              />
            }
            <Form>
              <div className="row">
                <div className="col-12">
                  <Form.Group className="form-group">
                    <FormLabel>File</FormLabel>
                    <Form.Control
                      required={true}
                      type="file"
                      name="filename"
                      id="uploadFile"
                      accept="image/png, image/jpeg, video/mp4, application/pdf"
                      onChange={this.handleFileInput}
                    />
                  </Form.Group>
                </div>
                <div className="col-12">
                  <Form.Group className="form-group">
                    <FormLabel>Description</FormLabel>
                    <Form.Control
                      required={true}
                      type="text"
                      name="description"
                      value={
                        this.state.description === null
                          ? ""
                          : this.state.description
                      }
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </div>
              </div>
              <Button
                className="btn-default save-information-btn"
                type="submit"
                onClick={this.validateFieldsAndSave}
                disabled={this.state.creating_media}
              >
                Save Media
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  handleChange(event) {
    const field_name = event.target.name;
    let state = {};
    state[field_name] = event.target.value;
    this.setState(state);
  }

  handleFileInput(event) {
    const field_name = event.target.name;
    let state = {};
    if (event.target.files.length > 0) {
      state[field_name] = event.target.files[0];
    } else {
      state[field_name] = "";
    }
    this.setState(state);
  }
}
export default MediaCreate;
