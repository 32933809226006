import React, { Component } from "react";

// SCSS
import "../../../css/sales/sale_information.scss";
import Util from "../../../Util";

class SaleInformationAccounts extends Component {
  constructor(props) {
    super();
    this.state = {
      sale: props.sale,
      isAccounting: props.isAccounting ? true : false,
      isCosts: props.isCosts ? true : false,
    };
  }

  render() {
    const sale = this.state.sale;

    let paysByDeposit;
    if ("paysByDeposit" in sale) {
      if (sale.paysByDeposit === true) {
        paysByDeposit = "Yes";
      } else if (sale.paysByDeposit === false) {
        paysByDeposit = "No";
      } else if (sale.paysByDeposit === null) {
        paysByDeposit = "Not specified";
      }
    }

    let pricesIncludeGst = null;
    let labelIncGst;
    let labelWOGST;
    if ("pricesIncludeGst" in sale) {
      if (sale.pricesIncludeGst === true) {
        pricesIncludeGst = "Yes";
      } else if (sale.pricesIncludeGst === false) {
        pricesIncludeGst = "No";
      } else {
        labelIncGst = ` (Inc ${Util.getStorageParameter("REACT_APP_TAX_NAME")})`;
        labelWOGST = " (WOGST)";
      }
    }

    return (
      <div className="sale_information_subcontainer">
        <div className="container">
          {!this.state.isAccounting ? (
            <div className="row">
              <div className="col-sm">
                <span className="table_title">Xero Code</span>
              </div>
              <div className="col-sm">{sale.xeroCode}</div>
            </div>
          ) : null}
          <div className="row">
            <div className="col-sm">
              <span className="table_title">Customer pays by deposit</span>
            </div>
            <div className="col-sm">{paysByDeposit}</div>
          </div>
          <div className="row">
            <div className="col-sm">
              <span className="table_title">Billing Address</span>
            </div>
            <div className="col-sm">
              <div
                dangerouslySetInnerHTML={{ __html: sale.billingInformation }}
              ></div>
            </div>
          </div>
          {this.state.isAccounting || this.state.isCosts ? (
            pricesIncludeGst !== null ? (
              <div className="row">
                <div className="col-sm">
                  <span className="table_title">
                    The following prices include{" "}
                    {Util.getStorageParameter("REACT_APP_TAX_NAME")}
                  </span>
                </div>
                <div className="col-sm">{pricesIncludeGst}</div>
              </div>
            ) : null
          ) : null}
          <div className="row">
            <div className="col-sm">
              <span className="table_title">Total Invoice{labelIncGst}</span>
            </div>
            <div className="col-sm">
              {Util.formatCurrency(sale.totalIncGst)}
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <span className="table_title">Balance</span>
            </div>
            <div className="col-sm">{Util.formatCurrency(sale.balance)}</div>
          </div>
          {this.state.isAccounting || this.state.isCosts ? (
            <div>
              <div className="row">
                <div className="col-sm">
                  <span className="table_title">
                    Total Diamond(s){labelWOGST}
                  </span>
                </div>
                <div className="col-sm">
                  {Util.formatCurrency(sale.totalDiamondWogst)}
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <span className="table_title">
                    Total Jewellery{labelWOGST}
                  </span>
                </div>
                <div className="col-sm">
                  {Util.formatCurrency(sale.totalSettingWogst)}
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <span className="table_title">
                    Express Manufacturing{labelWOGST}
                  </span>
                </div>
                <div className="col-sm">
                  {Util.formatCurrency(sale.expressManufacturingWogst)}
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <span className="table_title">Engraving{labelWOGST}</span>
                </div>
                <div className="col-sm">
                  {Util.formatCurrency(sale.engravingWogst)}
                </div>
              </div>
              <div className="row">
                <div className="col-sm">
                  <span className="table_title">CAD Payment</span>
                </div>
                <div className="col-sm">
                  {Util.formatCurrency(sale.cadPayment)}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  //********************************
  //REWRITING LIFECYCLE METHODS
  //********************************

  static getDerivedStateFromProps(props, state) {
    return {
      sale: props.sale,
    };
  }

  //********************************
  //REWRITING LIFECYCLE METHODS END
  //********************************
}

export default SaleInformationAccounts;
