import React, { Component } from 'react';
import { Form, FormLabel, Card, Button } from 'react-bootstrap';

// SCSS
import '../../../../css/sales/sale_edit.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RequestCustomisation from './Customisation';
import WorkflowCadRequests from './Customisation/list';
import FollowupRequest from './Customisation/followupRequest';
import Auth from '../../../../Auth';
import ChangeCostreadyActivity from './ChangeCostreadyActivity';
import Util from '../../../../Util';

class SaleWorkflowEditCAD extends Component {

    constructor(props) {
        super();
        this.state = {
            activity: props.activity,
            cost: props.activity.cost,
            invoiceNumber: props.activity.invoiceNumber,
            invoiceTo: props.activity.invoiceTo ?? '',
            readyToPayToProvider: props.activity.readyToPayToProvider,
            showModalCostready: false,
            showRequestCADModel: false,
            saleWorkflow: props.saleWorkflow,
            showFollowUpRequestModel: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this)
        this.handleChangeSelect = this.handleChangeSelect.bind(this)

        this.listInvoicesTo = Util.getStorageParameter("REACT_APP_PROVIDERS").split(',')
    }

    handleChangeNumber = (event) => {
        let state = {}
        let { value } = event.target
        const { name } = event.target

        value = value.replace(/[^\d.]/g, '')

        state[name] = value

        this.setState(state)
    }

    handleChange(event) {
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
        this.setState(state)
    }

    handleChangeCheckbox(event) {
        const field_name = event.target.name
        let state = {}

        if (field_name === 'readyToPayToProvider' && event.target.checked) {
            this.showModalCostready(true)
        } else {
            state[field_name] = event.target.checked
            this.setState(state)
        }
    }

    handleChangeSelect(event) {
        const field_name = event.target.name
        let state = {}
        state[field_name] = event.target.value
        this.setState(state)
    }

    openRequestCADModel = (event) => {
        event.preventDefault();
        this.setState({
            showRequestCADModel: true,
        });
    };

    closeRequestCADModel = () => {
        this.setState({
            showRequestCADModel: false
        });
    };

    openFollowUpRequestModel = (event) => {
        event.preventDefault();
        this.setState({
            showFollowUpRequestModel: true,
        });
    };

    closeFollowUpRequestModel = () => {
        this.setState({
            showFollowUpRequestModel: false,
        });
    };

    render() {
        const hasCADListAccess = Auth.canAccessRolesAndLeader("rolesAccessCADList");
        const hasCADCreateAccess = Auth.canAccessRolesAndLeader("rolesAccessCADCreate");
        return (
            <div id="SaleWorkflowEditCADList_component" className='sale_information_subcontainer'>
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <Form.Group className="form-group">
                                <FormLabel>Cost</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="cost"
                                    value={this.state.cost === null ? '' : this.state.cost}
                                    onChange={this.handleChangeNumber}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-6">
                            <Form.Group className="form-group">
                                <FormLabel>Invoice number</FormLabel>
                                <Form.Control
                                    type="text"
                                    name="invoiceNumber"
                                    value={this.state.invoiceNumber === null ? '' : this.state.invoiceNumber}
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                        </div>
                        <div className="col-12">
                            <Form.Group>
                                <FormLabel className="d-block">Invoice to</FormLabel>
                                <Form.Select
                                    name="invoiceTo"
                                    value={this.state.invoiceTo}
                                    onChange={(e) => this.handleChangeSelect(e)}
                                >
                                    <option value=""></option>
                                    {
                                        this.listInvoicesTo.map(invoiceTo => {
                                            return (
                                                <option
                                                    key={invoiceTo}
                                                    value={invoiceTo}>
                                                    {invoiceTo}
                                                </option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="col-12">
                            <Form.Check
                                type="checkbox"
                                name="readyToPayToProvider"
                                label="Cost ready"
                                disabled={this.state.readyToPayToProvider}
                                checked={this.state.readyToPayToProvider === true ? true : false}
                                onChange={this.handleChangeCheckbox}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <Card className="mt-2">
                            <Card.Header>CAD REQUESTS
                                {hasCADCreateAccess &&
                                    <Button
                                        variant=""
                                        className="btn btn-default float-right p-0"
                                        onClick={(e) => this.openRequestCADModel(e)}
                                    >
                                        <FontAwesomeIcon icon={faPlus} />
                                    </Button>}
                            </Card.Header>
                            <Card.Body>
                                {hasCADListAccess &&
                                    <WorkflowCadRequests
                                        activity={this.state.activity}
                                        openRequestCADModel={this.openRequestCADModel.bind(this)}
                                        saleWorkflow={this.state.saleWorkflow}
                                        openFollowUpRequestModel={this.openFollowUpRequestModel.bind(this)}
                                    />}
                            </Card.Body>
                        </Card>
                    </div>
                </div>

                {this.state.showModalCostready &&
                    <ChangeCostreadyActivity
                        showModalCostready={this.state.showModalCostready}
                        parentResponseCostReady={this.responseCostReady}
                        cost={this.state.cost}
                    />
                }
                {this.state.showRequestCADModel &&
                    <RequestCustomisation
                        onClose={this.closeRequestCADModel.bind(this)}
                        activity={this.state.activity}
                    />
                }
                {this.state.showFollowUpRequestModel &&
                    <FollowupRequest
                        onClose={this.closeFollowUpRequestModel.bind(this)}
                    />
                }
            </div>
        )
    }

    showModalCostready = (value) => {
        this.setState({
            showModalCostready: value
        })
    }

    responseCostReady = (value) => {
        this.setState({
            readyToPayToProvider: value
        })

        this.showModalCostready(false)
    }
}

export default SaleWorkflowEditCAD;
